import { GlobalService } from './../../../../services/global.service';
import { ExamModel } from './../../../../model/ExamModel';
import { ClinicalService } from './../../../../services/clinical.service';
import { OMR_EXAMModel } from './../../../../model/OMR_EXAMModel';
import { PatientModel } from './../../../../model/patientModel';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.scss']
})
export class ExamsComponent implements OnInit {
  columnExam:any;
  rowExam:ExamModel[] = [];
  examId !: string;
  patientModel : PatientModel = new PatientModel();
  examGrid : any ;
  patientId: string = '';
  deleteExamList : ExamModel[] = [];
  constructor(private route: ActivatedRoute,private _clinicalService : ClinicalService,
    private toastr : ToastrService,private _globalService : GlobalService) {
      GlobalService.componentName = "clinical";
  }

  ngOnInit() {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id;
    this.columnExam = [
      {
        headerName: 'Date',
        field: 'exam_Date', sortable: true, sort: 'desc',resizable: true, rowDrag: true,width:150,
        cellRenderer: (data:any) => {
          return moment(data.value).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Chief Complaint',
        field: 'cheif_Complaint', sortable: true, filter:true, resizable: true, width:900
      },
    ];
    this.getExam();
  }

  getId(){
    this.deleteExamList = this.examGrid.getSelectedRows();
    // this.examId = row.data.exam_ID;
  }

  deleteExam(){
    this._clinicalService.deleteExam(this.deleteExamList).subscribe((res:any)=>{
      this.getExam();
      this.toastr.success('Record Deleted Succesfully');
    },(error)=>{
      this.toastr.error(error.error);
    })
  }

  getExam(){
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.rowExam = Object.assign([],res);
    })
  }

  columnWidth(id:string, percentage:number){
    var div = this.examGrid.nativeElement;  // HTML ID of Grid
    var parent = div.parent();
    var width = parent.width() * percentage/100;  // Pixel calculation
    return width;
}

convertRemToPixel(){}

onGridReady(params:any){
  this.examGrid = params.api;
}

navigate(event?:any){
  var backLocation = localStorage.getItem("backLocation");
  if(!backLocation){
    localStorage.setItem("backLocation", "/patient/create");
  }
  if(event){
    this._clinicalService.navigateToExam(this.patientId,event.data);
  }
  else{
    this._clinicalService.navigateToExam(this.patientId,new ExamModel());
  }
  // this._globalService.examBroadcaster('');
}

}
