import { UserComponent } from './user/user.component';
import { GeneralComponent } from './general/general.component';
import { AdminComponent } from './admin/admin.component';
import { InsurancesComponent } from './insurances/insurances.component';
import { ProvidersComponent } from './providers/providers.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManufactureComponent } from '../patient/manufacture/manufacture.component';
import { ProductManufacturerComponent } from './product-manufacturer/product-manufacturer.component';
import { ProductsComponent } from './products/products.component';
const routes: Routes = [
    {
        path: 'providers',
        component: ProvidersComponent
    },
    {
        path: 'insurances',
        component: InsurancesComponent
    },
    {
        path: 'admin',
        component: AdminComponent
    },
    {
        path: 'general',
        component: GeneralComponent
    },
    {
        path: 'user',
        component: UserComponent
    },
    {
        path: 'products',
        component: ProductsComponent
    },
    {
        path: 'manufacture',
        component: ProductManufacturerComponent
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AdministrationRouting { }
