import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResourceModel } from '../model/ResourceModel';
import { ResourceTimeModel } from '../model/ResourceTimeModel';

@Injectable({
  providedIn: 'root'
})
export class ShedulingService {

constructor(private http: HttpClient) { }

getResources(): Observable<ResourceModel[]> {
  return this.http.get<ResourceModel[]>(environment.server + 'api/scheduling/getall');
}

getResourceById(id:string): Observable<ResourceModel> {
  return this.http.get<ResourceModel>(environment.server + 'api/scheduling/getById?id='+id);
}

getResourceTime(): Observable<ResourceTimeModel[]> {
  return this.http.get<ResourceTimeModel[]>(environment.server + 'api/scheduling/resourcetime/getall');
}

getResourceTimeByResourceId(resource_Id:string): Observable<ResourceTimeModel[]> {
  return this.http.get<ResourceTimeModel[]>(environment.server + 'api/scheduling/resourcetime/getallbyresource?resource_Id=' + resource_Id);
}

saveResources(resources : ResourceModel[]) : Observable<ResourceModel>{
  return this.http.post<ResourceModel>(environment.server + 'api/scheduling/add',resources);
}

updateResources(resources : ResourceModel) {
  return this.http.post<ResourceModel>(environment.server + 'api/scheduling/update',resources);
}

saveResourcetime(resourceTime : ResourceTimeModel[]): Observable<ResourceTimeModel[]>{
  return this.http.post<ResourceTimeModel[]>(environment.server + 'api/scheduling/resourcetime/add',resourceTime);
}

}