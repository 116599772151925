import { OMR_ContactRXModel } from './../model/OMR_ContactRXModel';
import { OMR_ProgressNotesModel } from './../model/OMR_ProgressNotesModel';
import { GlobalService } from 'src/app/services/global.service';
import { AttachmentModel } from './../model/AttachmentModel';
import { OMR_HCFAFormModel } from './../model/OMR_HCFAFormModel';
import { ExamModel } from './../model/ExamModel';
import { Exam_AssessmentsModel } from './../model/Exam_AssessmentsModel';
import { Observable } from 'rxjs';
import { AppointmentModel } from './../model/AppointmentModel';
import { OMR_EXAMModel } from './../model/OMR_EXAMModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InsuranceModel } from '../model/insuranceModel';
import { environment } from 'src/environments/environment';
import { AllergyModel } from '../model/AllergyModel';
import { OMR_ContactOrderFormModel } from '../model/OMR_ContactOrderFormModel';
import { RXModel } from '../model/RXModel';
import { Router, ActivatedRoute } from '@angular/router';
import { OMR_SpectacleOrderFormModel } from '../model/OMR_SpectacleOrderFormModel';
import { Exam_MedRXModel } from '../model/Exam_MedRXModel';

@Injectable({
  providedIn: 'root'
})
export class ClinicalService {
  patientId : number = 0;
  distSubjOD : string = '';
  distSubjOS : string = '';
  nearSubjOD : string = '';
  nearSubjOS : string = '';
  constructor(private http: HttpClient, private router: Router,private _globalService : GlobalService,private activateRoute : ActivatedRoute) {
    this.activateRoute.queryParams.subscribe((params: any) => {
      if(params['patientId']){
        this.patientId = parseInt(params['patientId'],10);
      }
    });
  }

  getExam(patientId: string): Observable<ExamModel> {
    return this.http.get<ExamModel>(environment.server + 'api/exam/getall?Patient_Id=' + patientId);
  }
  getLastOmrExam(patientId: string): Observable<OMR_EXAMModel> {
    return this.http.get<OMR_EXAMModel>(environment.server + 'api/exam/getLastomrexam?Patient_Id=' + patientId);
  }

  getByExamId(examId: string) {
    return this.http.get<ExamModel>(environment.server + 'api/exam/getbyid?id=' + examId);
  }

  deleteExam(exam: ExamModel[]): Observable<ExamModel> {
    return this.http.post<ExamModel>( environment.server + 'api/exam/delete', exam );
  }

  saveExam(exam: ExamModel): Observable<ExamModel> {
    const formData: FormData = new FormData();
    if (exam.omR_Exam.odEyePict) {
      let EXT = Object.assign(exam.omR_Exam.odEyePict);
     // formData.append('EXT', EXT, EXT.name);
      exam.omR_Exam.odEyePict = [];
    }
    if (exam.omR_Exam.osEyePict) {
      let INT = Object.assign(exam.omR_Exam.osEyePict);
      //formData.append('INT', INT, INT.name);
      exam.omR_Exam.osEyePict = [];
    }
    formData.append('examModel', JSON.stringify(exam));
    return this.http.post<ExamModel>(environment.server + 'api/exam/add', formData);
  }

  updateExam(exam: ExamModel): Observable<ExamModel> {
    const formData: FormData = new FormData();
    if (exam.omR_Exam.odEyePict) {
      let EXT = Object.assign(exam.omR_Exam.odEyePict);
   //   formData.append('EXT', EXT, EXT.name);
      exam.omR_Exam.odEyePict = [];
    }
    if (exam.omR_Exam.osEyePict) {
      let INT = Object.assign(exam.omR_Exam.osEyePict);
     // formData.append('INT', INT, INT.name);
      exam.omR_Exam.osEyePict = [];
    }
    formData.append('examModel', JSON.stringify(exam));
    return this.http.post<ExamModel>(environment.server + 'api/exam/update', formData);
  }
  PatchExam(exam: ExamModel): Observable<ExamModel> {
    const formData: FormData = new FormData();
    if (exam.omR_Exam.odEyePict) {
      let EXT = Object.assign(exam.omR_Exam.odEyePict);
   //   formData.append('EXT', EXT, EXT.name);
      exam.omR_Exam.odEyePict = [];
    }
    if (exam.omR_Exam.osEyePict) {
      let INT = Object.assign(exam.omR_Exam.osEyePict);
     // formData.append('INT', INT, INT.name);
      exam.omR_Exam.osEyePict = [];
    }
    formData.append('examModel', JSON.stringify(exam));
    return this.http.post<ExamModel>(environment.server + 'api/exam/patch', formData);
  }

  getProgressNotes(examId : string) : Observable<OMR_ProgressNotesModel> {
    return this.http.get<OMR_ProgressNotesModel>(environment.server + 'api/exam/getallprogressnote?ExamId=' + examId);
   }

   saveProgressNote(omr_ProgressNotesModel: OMR_ProgressNotesModel): Observable<OMR_ProgressNotesModel> {
    return this.http.post<OMR_ProgressNotesModel>(environment.server + 'api/exam/addProgressNote', omr_ProgressNotesModel);
  }

  updateProgressNote(omr_ProgressNotesModel: OMR_ProgressNotesModel): Observable<OMR_ProgressNotesModel> {
    return this.http.post<OMR_ProgressNotesModel>(environment.server + 'api/exam/updateProgressNote', omr_ProgressNotesModel);
  }
  deleteProgressNote(pregressNoteId: string): Observable<string> {
    return this.http.get<string>(environment.server + 'api/exam/deleteprogressnote?progressNoteId=' + pregressNoteId);
  }

  updateContactOrderForm(omr_ContactOrderFormModel: OMR_ContactOrderFormModel): Observable<OMR_ContactOrderFormModel> {
    return this.http.post<OMR_ContactOrderFormModel>(environment.server + 'api/exam/updateContactOrderForm', omr_ContactOrderFormModel);
  }
  // Appointment Start

  getAppointment(patientId:string): Observable<AppointmentModel> {
    return this.http.get<AppointmentModel>(environment.server + 'api/Appointment/getall?patientId=' + patientId);
  }

  getAllAppointment(): Observable<AppointmentModel[]> {
    return this.http.get<AppointmentModel[]>(environment.server + 'api/Appointment/getdetails');
  }
  getAllAppointments(days: number): Observable<AppointmentModel[]> {
    return this.http.get<AppointmentModel[]>(environment.server + 'api/Appointment/getdetails?days=' + days);
  }

  saveAppointment(appintment: AppointmentModel): Observable<AppointmentModel> {
    return this.http.post<AppointmentModel>(environment.server + 'api/Appointment/add', appintment);
  }

  updateAppointment(appintment: AppointmentModel) {
    return this.http.post<AppointmentModel>(environment.server + 'api/Appointment/update', appintment);
  }

  deleteAppointment(id: string) {
    return this.http.get<string>(environment.server + 'api/Appointment/delete?id=' + id);
  }

  // Appointment End

  // Allergy Start

  getAllergy(patientId: string): Observable<AllergyModel> {
    return this.http.get<AllergyModel>(environment.server + 'api/allergy/getall?patientId=' + patientId);
  }

  saveAllergy(allergy: AllergyModel[]): Observable<AllergyModel[]> {
    return this.http.post<AllergyModel[]>(environment.server + 'api/allergy/add', allergy);
  }
  // Allergy End

  // contact order start

  getContactOrder(): Observable<OMR_ContactOrderFormModel> {
    return this.http.get<OMR_ContactOrderFormModel>(environment.server + 'api/OMR_ContactOrderForm/getall');
  }

  saveContactOrder(OMR_ContactOrderFormModel: OMR_ContactOrderFormModel): Observable<OMR_ContactOrderFormModel> {
    return this.http.post<OMR_ContactOrderFormModel>(environment.server + 'api/OMR_ContactOrderForm/add', OMR_ContactOrderFormModel);
  }

  updateContactOrder(OMR_ContactOrderFormModel: OMR_ContactOrderFormModel): Observable<OMR_ContactOrderFormModel> {
    return this.http.post<OMR_ContactOrderFormModel>(environment.server + 'api/OMR_ContactOrderForm/update', OMR_ContactOrderFormModel);
  }

  getContactOrderByExamId(examId: string) {
    return this.http.get<OMR_ContactOrderFormModel>(environment.server + 'api/OMR_ContactOrderForm/?Id' + examId);
  }

  // contact order end

  // rx start

  getRx(patientId: string): Observable<RXModel> {
    return this.http.get<RXModel>(environment.server + 'api/rx/getall?patientId=' + patientId);
  }
  getdataRx(Examid:string,patientId: string): Observable<RXModel> {
    return this.http.get<RXModel>(environment.server + 'api/rx/getalldata?patientId='+patientId+'&examId='+Examid);
  }


  saveRx(rx: RXModel[]): Observable<RXModel[]> {
    return this.http.post<RXModel[]>(environment.server + 'api/rx/add', rx);
  }
  deleterx(id: string) {
    return this.http.get<string>(environment.server + 'api/rx/delete?id=' + id);
  }
  // rx end

  // diagnosis start

  getDiagnosis(): Observable<Exam_AssessmentsModel> {
    return this.http.get<Exam_AssessmentsModel>(environment.server + 'api/exam_Assessments/getall');
  }

  getByPatientDiagnosis(patientId:string): Observable<Exam_AssessmentsModel> {
    return this.http.get<Exam_AssessmentsModel>(environment.server + 'api/exam_Assessments/getbypatientid?patientId='+patientId);
  }

  saveDiagnosis(exam_AssessmentsModel: Exam_AssessmentsModel[]): Observable<Exam_AssessmentsModel> {
    return this.http.post<Exam_AssessmentsModel>(environment.server + 'api/exam_Assessments/add', exam_AssessmentsModel);
  }

  updateDiagnosis(exam_AssessmentsModel: Exam_AssessmentsModel[]): Observable<Exam_AssessmentsModel> {
    return this.http.post<Exam_AssessmentsModel>(environment.server + 'api/exam_Assessments/update', exam_AssessmentsModel);
  }

  // diagnosis end

  navigateToExam( patientId: string,exam:ExamModel) {
    this._globalService.examBroadcaster(exam.exam_ID);
    this.router.navigate(['/patient/add-exam'], { queryParams: { patient_Id: patientId,examId:exam.exam_ID } })
  }

  getHCFA(examId : string,patientId : string): Observable<OMR_HCFAFormModel> {
    return this.http.get<OMR_HCFAFormModel>(environment.server + 'api/OMR_HCFAForm/getbyid?examId='+examId+'&patientId='+patientId);
  }

  saveHCFA(OMR_HCFAFormModel:OMR_HCFAFormModel){
    return this.http.post<OMR_HCFAFormModel>(environment.server + 'api/OMR_HCFAForm/add',OMR_HCFAFormModel);
  }

  updateHCFA(OMR_HCFAFormModel:OMR_HCFAFormModel){
    return this.http.post<OMR_HCFAFormModel>(environment.server + 'api/OMR_HCFAForm/update',OMR_HCFAFormModel);
  }

  getAttachment(patientId : string){
    return this.http.get<AttachmentModel>(environment.server + 'api/attachment/getall?patientId='+patientId);
  }

  saveAttachment(AttachmentModel : AttachmentModel[]){
    const formData: FormData = new FormData();
    AttachmentModel.forEach(element => {
      if (element.file_dat) {
        let temp = Object.assign(element.file_dat);
        formData.append('file', temp, temp.name);
        element.file_dat = null;
      }
    });

    formData.append('AttachmentModel', JSON.stringify(AttachmentModel));
    return this.http.post<AttachmentModel>(environment.server + 'api/attachment/add',formData);
  }

  deleteAttachment(AttachmentModel : AttachmentModel[]){
    return this.http.post<AttachmentModel>(environment.server + 'api/attachment/delete',AttachmentModel);
  }

  updateAttachment(AttachmentModel : AttachmentModel[]){
    return this.http.post<AttachmentModel>(environment.server + 'api/attachment/update',AttachmentModel);
  }

  //spectacle

  getSpectacle():Observable<OMR_SpectacleOrderFormModel>{
    return this.http.get<OMR_SpectacleOrderFormModel>(environment.server + 'api/OMR_SpectacleOrderForm/getall');
  }

  getSpectacleByExamId(examId : string):Observable<OMR_SpectacleOrderFormModel>{
    return this.http.get<OMR_SpectacleOrderFormModel>(environment.server + 'api/OMR_SpectacleOrderForm/getbyid?id='+examId);
  }

  saveSpectacle(OMR_SpectacleOrderFormModel : OMR_SpectacleOrderFormModel):Observable<OMR_SpectacleOrderFormModel>{
    return this.http.post<OMR_SpectacleOrderFormModel>(environment.server + 'api/OMR_SpectacleOrderForm/add',OMR_SpectacleOrderFormModel);
  }

  updateSpectacle(OMR_SpectacleOrderFormModel : OMR_SpectacleOrderFormModel):Observable<OMR_SpectacleOrderFormModel>{
    return this.http.post<OMR_SpectacleOrderFormModel>(environment.server + 'api/OMR_SpectacleOrderForm/update',OMR_SpectacleOrderFormModel);
  }

  getMedRx():Observable<Exam_MedRXModel>{
    return this.http.get<Exam_MedRXModel>(environment.server + 'api/exam_medrx/getall');
  }

  getByMedRxId(patientId : number):Observable<Exam_MedRXModel[]>{
    return this.http.get<Exam_MedRXModel[]>(environment.server + 'api/exam_medrx/getall?patientID='+patientId);
  }

  getByMedRxexamID(patientId: number,Examid:string): Observable<Exam_MedRXModel[]> {
    return this.http.get<Exam_MedRXModel[]>(environment.server + 'api/exam_medrx/getalldata?patientId='+patientId+'&examId='+Examid);
  }

  saveMedRx(exam_MedRXModel : Exam_MedRXModel):Observable<Exam_MedRXModel>{
    return this.http.post<Exam_MedRXModel>(environment.server + 'api/exam_medrx/add',exam_MedRXModel);
  }

  updateMedRx(exam_MedRXModel : Exam_MedRXModel):Observable<Exam_MedRXModel>{
    return this.http.post<Exam_MedRXModel>(environment.server + 'api/exam_medrx/update',exam_MedRXModel);
  }

  navigateToClinic(){
    this.router.navigate(['/patient/clinical/exams'],{queryParams:{patientId : this.patientId}})
  }

  downloadFile(data: any) {
      return this.http.post(environment.server + 'api/attachment/getfile',data);
  }

}
