export class PracticeNotificationModel{
    notification_id:string='';
    note_type_txt:string='';
    note_txt:string='';
    practice_id:string='';
    active_ind:boolean=false;
    publish_ind:boolean=false;
    expire_dt:Date = new Date();
    created_dt:Date = new Date();
    created_by:string ='';
    updated_dt:Date = new Date();
    updated_by:string='';
}