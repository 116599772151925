
<form class="form" style="height:130%; width: 100% !important;">
    <div  id="content" style="text-align:center !important;">
        <div>
            <div class="col-md-12" style="padding: 0%;">
                <h3><b>Insurances</b></h3>

            </div>
        </div>
    </div>

        <div id="content" style="height:500px !important;">
            <div class="titlebox">Manage Insurances</div>
            <br>
            <div class="row">
                <div class="col-md-3">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3 p-0">
                                <label style="font-size: 20px !important">Insurance Search:</label>
                            </div>
                            <div class="col-md-9 justify-content-right">
                                <button class="btn btn-primary mx-2" style="margin-right:-15px !important;" (click)="addNew()">Add New</button>
                            </div>
                        </div>
                    </div>
                    <div class="pt-1">
                        <input type="text" class="form-control" placeholder="Search here..." (input)="findInsurance($event)">
                    </div>
                    <div id="content" class="mt-2" style="height: 300px; overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black;">
                        <div class="titlebox"></div>
                        <div class="row mx-2" *ngFor="let insurance of insuranceList">
                            <div class="col-md-12" [ngStyle]="{'background-color': selectedInsurance == insurance.insurance_id ? '#5A5AE5': 'white'}">
                                <label style="cursor: pointer;" class="text-center" (click)="setForm(insurance)">{{insurance.display_txt}}</label>

                            </div>
                        </div>
                    </div>
                    <div>
                        <label><input type="checkbox" #showInactiveCheckbox id="showInactiveCheckbox" ([ngModel])="showActive" (change)="filterInsurance(showInactiveCheckbox.checked)" class="mx-2">Show Inactive</label>
                    </div>
                </div>
                <div class="col-md-9" style="width:80%;">
                    <div>
                        <label style="font-size: 20px !important; text-align:center !important; margin-left: 200px !important;">Insurance Information:</label>
                    </div>
                    <form [formGroup]="insuranceFormGroup">
                        <div class="col-md-11">
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>Display Name:</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="text" hidden formControlName="insurance_id" [(ngModel)]="insuranceModel.insurance_id" class="form-control">
                                    <input type="text"  formControlName="display_txt" [ngClass]="{'red':insuranceFormGroup.controls.display_txt.invalid}" [(ngModel)]="insuranceModel.display_txt" class="form-control">
                                </div>
                                <div class="col-md-2 justify-content-right">
                                    <label>Type:</label>
                                </div>
                                <div class="col-md-3">
                                    <select class="form-control"  formControlName="type_val" [(ngModel)]="insuranceModel.type_val">
                                        <option [value]="1">Medical</option>
                                        <option [value]="2">Vision</option>
                                    </select>
                                </div>
                                <div class="col-md-1 justify-content-right">
                                    <input type="checkbox" formControlName="active_ind" [(ngModel)]="insuranceModel.active_ind">
                                    <label class="mx-2">Active</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>Address 1:</label>

                                </div>

                                <div class="col-md-4">

                                    <input type="text" formControlName="address1_txt" [(ngModel)]="insuranceModel.address1_txt" class="form-control">
                                </div>

                                <div class="col-md-2 justify-content-right">
                                    <label>Address 2:</label>

                                </div>


                                <div class="col-md-4">

                                    <input type="text" formControlName="address2_txt" [(ngModel)]="insuranceModel.address2_txt" class="form-control">
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>City:</label>
                                </div>

                                <div class="col-md-4">

                                    <input type="text" formControlName="city_txt" [(ngModel)]="insuranceModel.city_txt" class="form-control">
                                </div>

                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4 justify-content-right">
                                            <label>State:</label>
                                        </div>

                                        <div class="col-md-3">
                                            <select   style="width:70px ;" formControlName="state_txt" [(ngModel)]="insuranceModel.state_txt">
                                                <option *ngFor="let state of states" [value]="state.name_txt">{{state.name_txt}}</option>
                                                 </select>
                                            <!-- <ng-select [clearable]="true" style="width:70px ;" [items]="states" bindLabel="name_txt" formControlName="state_txt" bindValue="name_txt" [(ngModel)]="insuranceModel.state_txt">
                                            </ng-select> -->
                                        </div>

                                        <div class="col-md-2 justify-content-right">
                                            <label>Zip:</label>

                                        </div>

                                        <div class="col-md-3 p-0">
                                            <input type="text" formControlName="zip_txt" [(ngModel)]="insuranceModel.zip_txt" class="form-control">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>Phone:</label>
                                </div>

                                <div class="col-md-4">
                                    <input type="text" formControlName="phone_txt" [(ngModel)]="insuranceModel.phone_txt" class="form-control">
                                </div>

                                <div class="col-md-6 justify-content-right">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label>Fax:</label>
                                        </div>

                                        <div class="col-md-8">

                                            <input type="text" formControlName="fax_txt" [(ngModel)]="insuranceModel.fax_txt" class="form-control">
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>Rep Name:</label>
                                </div>

                                <div class="col-md-4">
                                    <input type="text" formControlName="rep_name_txt" [(ngModel)]="insuranceModel.rep_name_txt" class="form-control">
                                </div>

                                <div class="col-md-2 justify-content-right">
                                    <label>Rep Phone:</label>

                                </div>

                                <div class="col-md-4">
                                    <input type="text" formControlName="rep_phone_txt" [(ngModel)]="insuranceModel.rep_phone_txt" class="form-control">
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>Rep Email:</label>

                                </div>


                                <div class="col-md-4">
                                    <input type="text" formControlName="rep_email_txt" [ngClass]="{'red':insuranceFormGroup.controls.rep_email_txt.invalid}" [(ngModel)]="insuranceModel.rep_email_txt" class="form-control">
                                </div>

                                <div class="col-md-2 justify-content-right">
                                    <label>Website:</label>

                                </div>

                                <div class="col-md-4">
                                    <input type="text" formControlName="rep_website_txt" [(ngModel)]="insuranceModel.rep_website_txt" class="form-control">
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-2 justify-content-right">
                                    <label>User ID:</label>

                                </div>

                                <div class="col-md-4">

                                    <input type="text" formControlName="user_id_txt" [(ngModel)]="insuranceModel.user_id_txt" class="form-control">
                                </div>

                                <div class="col-md-2 justify-content-right">
                                    <label>Password:</label>
                                </div>

                                <div class="col-md-4">
                                    <input type="text" formControlName="password_txt" [(ngModel)]="insuranceModel.password_txt" class="form-control">
                                </div>

                            </div>
                            <div class="pt-2 col-md-12 text-center">
                                <button class="btn btn-primary px-4" (click)="edit()" *ngIf="!showButton" [disabled]="!enablEditButton">Edit</button>
                                <button class="btn btn-primary px-4 mx-2" [disabled]="insuranceFormGroup.invalid" *ngIf="showButton" (click)="save()">Save</button>
                                <button class="btn btn-primary px-4" (click)="cancel()" *ngIf="showButton">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</form>
