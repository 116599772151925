import { ExamModel } from 'src/app/model/ExamModel';
export class OMR_ExtraTestsModel{
public exam_ID : string = '';
public patient_ID : string = '';
public colorVisionOD : boolean = false;
public colorVisionOS : boolean = false;
public colorVisionOther : string = '';
public stereoFusion : boolean = false;
public stereoFusionOther : string = '';
public amslerGrid : boolean = false;
public amslerGridOther : string = '';
public distPhoriaLAT : string = '';
public distPhoriaVERT : string = '';
public nearPhoriaLAT : string = '';
public nearPhoriaVERT : string = '';
public pinholeVAOD : string = '';
public pinholeVAOS : string = '';
public bldPressSYS : string = '';
public bldPressDIAS : string = '';
public pupilDistDIST : string = '';
public pupilDistNEAR : string = '';
public segHeightOD : string = '';
public segHeightOS : string = '';
public notes : string = '';
public creationDate : Date = new Date();
public lastUpdateDate : Date = new Date();
public height : string = '';
public weight : string = '';
public temperature : string = '';
public pulse : string = '';
public bmi : string = '';
public worthTest : string = '';
public aniseikoniaDistVert : string = '';
public aniseikoniaDistHoriz : string = '';
public nearPtWorkingDist : string = '';
public dominantEye : string = '';
public npaod : string = '';
public npaos : string = '';
public npaou : string = '';
public hbac:string = '';
// public exam : ExamModel = new ExamModel();
}