<body class="alternative" id='print-section'>
    <div id="content" style="height: 1600px;">
        <div class="row">
            <div class="col-md-4">
                <div id="content">
                    <div class="text-center">
                        <button routerLink="/administration/general" class="contentButton">
                            <i class="fa fa-cog icon"></i>
                            <h5><b>General</b></h5>
                            <label>Manage General Practice Settings and Facilities.</label>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div id="content">
                    <div class="text-center" >
                        <button routerLink="/administration/providers" class="contentButton">
                            <i class="fa fa-user icon"></i>
                            <h5><b>Providers</b></h5>
                            <label>Manage Providers</label>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div id="content">
                    <div class="text-center">
                        <button routerLink="/administration/user" class="contentButton">
                            <i class="fa fa-users icon"></i>
                            <h5><b>Users</b></h5>
                            <label>Manage Users</label>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div id="content">
                    <div class="text-center">
                        <button routerLink="/administration/insurances" class="contentButton">
                            <i class="fa fa-bank icon"></i>
                            <h5><b>Insurances</b></h5>
                            <label>Manage Insurances Company Information</label>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
              <div id="content">
                  <div class="text-center">
                      <button routerLink="/administration/products" class="contentButton">
                          <i class="fa fa-cubes icon"></i>
                          <h5><b>Products</b></h5>
                          <label>Manage Products</label>
                      </button>
                  </div>
              </div>
            </div>
            <div class="col-md-4">
              <div id="content">
                  <div class="text-center">
                      <button routerLink="/administration/manufacture" class="contentButton">
                          <i class="fa fa-object-group icon"></i>
                          <h5><b>Manufacturers</b></h5>
                          <label>Manage Manufacturers</label>
                      </button>
                  </div>
              </div>
            </div>
        </div>
    </div>
</body>
<style type="text/css">
    .contentButton
    {
        background: linear-gradient(0deg, rgb(1 66 239), rgb(40 89 217 / 54%)), conic-gradient(from 180deg at 50% 50%, #214fcb 0deg, #F8F8F8 56.25deg, #5775c5 105deg, #FEFEFE 183.75deg, #527ced 275.62deg, #E5E5E5 318.75deg, #2a50b3 360deg) !important;
        box-shadow: 0px 2px 0px #1076cade !important;
        /* background: linear-gradient(0deg, rgba(41, 41, 41, 0.54), rgba(41, 41, 41, 0.54)), conic-gradient(from 180deg at 50% 50%, #75797D 0deg, #F8F8F8 56.25deg, #777B7C 105deg, #FEFEFE 183.75deg, #868A8B 275.62deg, #E5E5E5 318.75deg, #75797D 360deg) !important;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.81) !important; */
        border-radius: 4px !important;
        color: white !important;
        border-color: transparent !important;
        width: 100%;
    }
    #content
    {
        border: solid 0px;
        margin-left: 15px !important;
    }
</style>
