export class UserModel {
    public user_id: string = '';
    public username_txt: string = '';
    public password_txt: string = '';
    public display_txt: string = '';
    public first_name_txt: string = '';
    public last_name_txt: string = '';
    public license_txt: string = '';
    public npi_txt: string = '';
    public signature_img: any;
    public address_1_txt: string = '';
    public address_2_txt: string = '';
    public city_txt: string = '';
    public state_txt: string = '';
    public zip_txt: string = '';
    public phone_1_txt: string = '';
    public phone_2_txt: string = '';
    public phone_3_txt: string = '';
    public active_ind: boolean = false;
    public locked_ind: boolean = false;
    public bad_login_cnt: number = 0;
    public last_login_dt: Date = new Date();
    public created_dt : Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public email_address_txt: string = '';
    public temp_password_ind: boolean = false;
    public qeUserID: number = 0;
    public practice_id: string = '';
    public can_sign: boolean = false;
    public federal_tax_id_type_txt: string = '';
}