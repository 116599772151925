<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <ag-grid-angular #examGrid style="height: 250px; width: 100%;" class="ag-theme-alpine" (gridReady)="onGridReady($event)" [rowData]="mainItem" [columnDefs]="columnLookup">
    </ag-grid-angular> -->
    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 350px; width: 100%;" [tooltipShowDelay]="0" [enableBrowserTooltips]="true" class="ag-theme-alpine" [rowData]="mainItem" [rowDragManaged]="true" (gridReady)="onGridReady($event)" [animateRows]="true"
    [columnDefs]="columnLookup">
</ag-grid-angular>
<!-- <button (click)="Addlookup()" class="btn btn-primary" style="margin: 5px;">Add</button> -->


</div>
<div class="modal-footer">
<label class="mx-1"><input type="checkbox" class="mx-1" (change)="filter()" [(ngModel)]="isShowInactive">Show Inactive</label>
    <button type="button" class="btn btn-default" (click)="save()">Ok</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
</div>