import { PatientReviewModel } from "./PatientReviewModel";

export class ROModel{
public rosId : string = '';
public patient_review_id  : string = '';
public secName: string = '';
public otherTxt : string = '';
public otherHad : number = 2;
public created_dt : Date = new Date();
public created_by : string = '';
public patientReview : PatientReviewModel = new PatientReviewModel();
}