<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" routerLink="/patient/create"
                [queryParams]="{patientId: patientDetails.patientID}">

                <!-- <img src="../../../../assets/img/close.png" style="height: 30px;width: 30px;justify-content: center;"> -->
                Cancel History
            </button>&nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save()" [ngClass]="{ 'disableCls': isDisabled }" [tabindex]="isDisabled ? -1 : null" >
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;text-align: center;"> -->
                Save History
            </button>&nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="returnToCurrent()">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;text-align: center;"> -->
                Return to Current
            </button>&nbsp;&nbsp;
            <button class="button" style="height: 68px;" [useExistingCss]="true" printSectionId="print-section"
                ngxPrint>
                <!-- <img src="../../../../assets/img/print_printer.png" style="height: 30px;width: 30px;"> -->

                Print
            </button> &nbsp;&nbsp;
            <hr>
        </div>
    </div>
</div>
<br>
<br>

<fieldset class="form p-1" style="background-color: white;">
    <!-- <div id="pdiv" #mainContent> -->
    <div [ngClass]="{ 'disableCls': isDisabled }" [tabindex]="isDisabled ? -1 : null">
        <br>
         <br>
          <h4 class="text-center"><b>Vision and Medical History</b></h4>
            <div>
                <router-outlet  (activate)="onActivate($event)"></router-outlet>
                <div class="row">
                  <div class="col-sm-2">
                        <label>Reason for today's exam?</label>
                    </div>
                    <div class="col-sm-8">
                        <input type="text" [(ngModel)]="patientReviewModel.reasonForVisit" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2">
                        <label>Date of last eye exam?</label>
                    </div>
                    <div class="col-sm-3">
                        <input (ngModelChange)="patientReviewModel.dateOfLastExam = $event"
                            [ngModel]="patientReviewModel.dateOfLastExam | date:'MM-dd-yyyy'" type="text"
                            [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                    </div>
                    <div class="col-sm-1">
                        <label>By whom?</label>
                    </div>
                    <div class="col-sm-4">
                        <input type="text" [(ngModel)]="patientReviewModel.lastExaminer" class="form-control">
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-2">
                        <label>Do you presently wear?</label>
                    </div>
                    <!-- <div class="col-md-8">
                        <label><input type="radio" name="patientReviewModel.currentlyWear"
                                (change)="checkArrayRadio(1,patientReviewModel,'currentlyWear')"
                                [checked]="patientReviewModel.currentlyWear == 1" class="mx-2"><span
                                [ngClass]="patientReviewModel.currentlyWear == 1 ? 'backGround' : ''">Glasses</span></label>
                        <label><input type="radio" name="patientReviewModel.currentlyWear"
                                (change)="checkArrayRadio(2,patientReviewModel,'currentlyWear')"
                                [checked]="patientReviewModel.currentlyWear == 2" class="mx-2"><span
                                [ngClass]="patientReviewModel.currentlyWear == 2 ? 'backGround' : ''">Contacts</span></label>
                        <label><input type="radio" name="patientReviewModel.currentlyWear"
                                (change)="checkArrayRadio(3,patientReviewModel,'currentlyWear')"
                                [checked]="patientReviewModel.currentlyWear == 3" class="mx-2"><span
                                [ngClass]="patientReviewModel.currentlyWear == 3 ? 'backGround' : ''">Both</span></label>
                        <label><input type="radio" name="patientReviewModel.currentlyWear"
                                (change)="checkArrayRadio(4,patientReviewModel,'currentlyWear')"
                                [checked]="patientReviewModel.currentlyWear == 4" class="mx-2"><span
                                [ngClass]="patientReviewModel.currentlyWear == 4 ? 'backGround' : ''">Neither</span></label>
                    </div> -->
                    <div class="col-md-8">
                        <label>
                            <input type="radio"
                                [(ngModel)]="patientReviewModel.currentlyWear"
                                [value]="1"
                                (click)="checkArrayRadio(1,patientReviewModel,'currentlyWear')"
                                class="mx-2">
                            <span (click)="checkArrayRadio(1,patientReviewModel,'currentlyWear')" [ngClass]="patientReviewModel.currentlyWear == 1 ? 'backGround' : ''">Glasses</span>
                        </label>
                        <label>
                            <input type="radio"
                                (click)="checkArrayRadio(2,patientReviewModel,'currentlyWear')"
                                [(ngModel)]="patientReviewModel.currentlyWear"
                                [value]="2"
                                class="mx-2">
                            <span (click)="checkArrayRadio(2,patientReviewModel,'currentlyWear')" [ngClass]="patientReviewModel.currentlyWear == 2 ? 'backGround' : ''">Contacts</span>
                        </label>
                        <label>
                            <input type="radio"
                                (click)="checkArrayRadio(3,patientReviewModel,'currentlyWear')"
                                [(ngModel)]="patientReviewModel.currentlyWear"
                                [value]="3"
                                class="mx-2">
                            <span (click)="checkArrayRadio(3,patientReviewModel,'currentlyWear')" [ngClass]="patientReviewModel.currentlyWear == 3 ? 'backGround' : ''">Both</span></label>
                        <label>
                            <input type="radio"
                                (click)="checkArrayRadio(4,patientReviewModel,'currentlyWear')"
                                [(ngModel)]="patientReviewModel.currentlyWear"
                                [value]="4"
                                class="mx-2">
                            <span (click)="checkArrayRadio(4,patientReviewModel,'currentlyWear')" [ngClass]="patientReviewModel.currentlyWear == 4 ? 'backGround' : ''">Neither</span></label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>If not currently wearing contacts, are you interested in trying them today?</label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'interestedInContacts')"
                                [checked]="patientReviewModel.interestedInContacts" class="mx-2"><span
                                [ngClass]="patientReviewModel.interestedInContacts == true ? 'backGround' : ''">Yes</span>
                        </label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'interestedInContacts')"
                                [checked]="!patientReviewModel.interestedInContacts" class="mx-2">No
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <label>Are your contacts comfortable?</label>
                    </div>
                    <div class="col-md-10">
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'areContactsComfortable')"
                                [checked]="patientReviewModel.areContactsComfortable" class="mx-2"><span
                                [ngClass]="patientReviewModel.areContactsComfortable == true ? 'backGround' : ''">Yes</span></label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'areContactsComfortable')"
                                [checked]="patientReviewModel.areContactsComfortable == false" class="mx-2">No</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <label>If you wear contact lenses, do you know what type or brand?</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" [(ngModel)]="patientReviewModel.contactBrand" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2">
                        <label>How old are your glasses?</label>
                    </div>
                    <div class="col-sm-3">
                        <input class="form-control" [(ngModel)]="patientReviewModel.ageOfGlasses" type="text">
                    </div>
                    <div class="col-sm-2">
                        <label>How old are your contacts</label>
                    </div>
                    <div class="col-sm-3">
                        <input class="form-control" [(ngModel)]="patientReviewModel.ageOfContacts" type="text">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2">
                        <label>Name of your medical doctor:</label>
                    </div>
                    <div class="col-sm-3">
                        <input class="form-control" [(ngModel)]="patientReviewModel.medicalDoctorName" type="text">
                    </div>
                    <div class="col-sm-2">
                        <label>Dr's Phone:</label>
                    </div>
                    <div class="col-sm-3">
                        <input class="form-control" phone minlength=14 maxlength=14
                            [(ngModel)]="patientReviewModel.medicalDoctorPhone" type="text">
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-3">
                        <label>Are you currently taking any medications?</label>
                    </div>
                    <div class="col-md-8">
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'currentlyTakingMedications')"
                                [checked]="patientReviewModel.currentlyTakingMedications" class="mx-2"><span
                                [ngClass]="patientReviewModel.currentlyTakingMedications == true ? 'backGround' : ''">Yes</span></label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'currentlyTakingMedications')"
                                [checked]="!patientReviewModel.currentlyTakingMedications" class="mx-2">No</label>
                    </div>
                    <div class="col-md-12">
                        <label>(including oral contraceptives,aspirin,otc and/or herbal meds)<br>If so please
                            list:</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <table class="table-bordered" style="font-size: 14px !important; width:120%">
                            <tr>
                                <th>Brand Name:</th>
                                <th>Generic Name:</th>
                                <th>Strength:</th>
                                <th>Dose:</th>
                                <th>Route:</th>
                                <th>Frequency:</th>
                                <th>#:</th>
                                <th>Refill:</th>
                                <th>Started Date:</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let item of medicationList;let i = index">
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'brand_name_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.brand_name_txt,'Brand Name',BrandLookup,'medicationname',item,'brand_name_txt')"
                                                        [items]="BrandLookup"  bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="item.brand_name_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(BrandLookup,BrandLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'generic_name_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:100%;"
                                                        (keydown.tab)="saveDropdown(item.generic_name_txt,'Generic Name',genericLookup,'medicationname',item,'generic_name_txt')"
                                                        [items]="genericLookup" bindLabel="name_txt"
                                                        bindValue="name_txt" [(ngModel)]="item.generic_name_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(BrandLookup,BrandLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'strength_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.strength_txt,'Strength',strengthLookup,'rxstrength',item,'strength_txt')"
                                                        [items]="strengthLookup" bindLabel="name_txt"
                                                        bindValue="name_txt" [(ngModel)]="item.strength_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(strengthLookup,strengthLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px;"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'dose_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.dose_txt,'Dose',doseLookup,'dose',item,'dose_txt')"
                                                        [items]="doseLookup" bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="item.dose_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(doseLookup,doseLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'route_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.route_txt,'Route',rxRouteLookup,'rxroute',item,'route_txt')"
                                                        [items]="rxRouteLookup" bindLabel="name_txt"
                                                        bindValue="name_txt" [(ngModel)]="item.route_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(rxRouteLookup,rxRouteLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'frequency_txt')" addTagText="Add Item" [addTag]="CreateNew"style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.frequency_txt,'Frequency',rxfrequency,'medicationname',item,'frequency_txt')"
                                                        [items]="rxfrequency" bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="item.frequency_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(rxfrequency,rxfrequencyCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'detail_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.detail_txt,'#',rxNumberLookup,'medicationname',item,'detail_txt')"
                                                        [items]="rxNumberLookup" bindLabel="name_txt"
                                                        bindValue="name_txt" [(ngModel)]="item.detail_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(rxNumberLookup,rxNumberLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-12">
                                            <div class="form-row">
                                                <div class="col-md-10">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'refill_times_txt')" addTagText="Add Item" [addTag]="CreateNew" style="width:110%;"
                                                        (keydown.tab)="saveDropdown(item.refill_times_txt,'Refill',refilLookup,'refill',item,'refill_times_txt')"
                                                        [items]="refilLookup" bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="item.refill_times_txt">
                                                    </ng-select>
                                                </div>
                                                <div class="col-md-1">
                                                    <a style="margin-right:30px;" href="javascript:void(0)"
                                                        (click)="editLookup(refilLookup,refilLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png" height="10px"
                                                            style="margin-left:-2px ;"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-md-11 p-3">
                                            <div class="form-row">
                                                <input (ngModelChange)="item.date_started_dt = $event"
                                                    [ngModel]="item.date_started_dt | date:'MM-dd-yyyy'" type="text"
                                                    [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="form-row" style="width:100px ;">
                                        <div class="col-md-12 p-2">
                                            <ng-select [clearable]="true" style="width:105% !important;"
                                                [items]="statusLookup" bindLabel="key"
                                                bindValue="value" [(ngModel)]="item.status_txt">
                                            </ng-select>

                                        </div>
                                    </div>
                                </td>

                                <td><button class="btn btn-danger" (click)="romoveMedication(i)">Remove</button></td>
                            </tr>
                        </table>
                        <button class="btn btn-primary px-4" (click)="addMedication()">Add</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Do you have allergies to any medications?</label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'allergicToMedications')"
                                [checked]="patientReviewModel.allergicToMedications" class="mx-2"><span
                                [ngClass]="patientReviewModel.allergicToMedications == true ? 'backGround' : ''">Yes</span></label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'allergicToMedications')"
                                [checked]="!patientReviewModel.allergicToMedications" class="mx-2">No</label><br>
                        <label>If so please list:</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table-bordered" style="font-size: 14px !important; width:100%">
                            <tr>
                                <th>Allergy-Medication Name:</th>
                                <th>Reaction Details:</th>
                                <th>Type:</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let item of allergyList;let i = index">
                                <td>
                                    <div class="row">
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'name_txt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(item.name_txt,'Allergy-Medication Name',medication,'medicationname',item,'name_txt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="medication" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="item.name_txt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(medication,medicationCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'reaction_detail_txt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(item.reaction_detail_txt,'Reaction Details',reaction,'phallergyreaction',item,'reaction_detail_txt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="reaction" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="item.reaction_detail_txt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(reaction,reactionCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, item, 'type_txt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(item.type_txt,'Type',type,'phallergytype',item,'type_txt',dbEnum.QE_Dev_Config)"
                                                [items]="type" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="item.type_txt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(type,typeCopy,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td><button class="btn btn-danger" (click)="removeAllergy(i)">Remove</button></td>
                            </tr>
                        </table>
                        <button class="btn btn-primary px-4" (click)="addAllergy()">Add</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.medReconciliation"
                                class="mx-2">Reconciliation
                            of Medication Completed</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <label>List all surgeries and/or hospitalizations you had:</label>
                        <textarea class="form-control" [(ngModel)]="patientReviewModel.surgeriesAndHospitalizations"
                            rows="4" cols="20"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Are you pregnant or nursing?</label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'isPregnantOrNursing')"
                                [checked]="patientReviewModel.isPregnantOrNursing" class="mx-2"><span
                                [ngClass]="patientReviewModel.isPregnantOrNursing == true? 'backGround' : ''">Yes</span></label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'isPregnantOrNursing')"
                                [checked]="!patientReviewModel.isPregnantOrNursing" class="mx-2">No</label>
                    </div>
                </div>
                <div class="row pb-2">
                    <div class="col-sm-2">
                        <label>Please List: Your Height in inches:</label>
                    </div>
                    <div class="col-sm-3">
                        <input class="form-control" type="text" [(ngModel)]="patientReviewModel.height">
                    </div>
                    <div class="col-sm-2">
                        <label>Please List: Your Weight in lbs:</label>
                    </div>
                    <div class="col-sm-3">
                        <input class="form-control" type="text" [(ngModel)]="patientReviewModel.weight">
                    </div>
                </div>
                <h4 class="text-center"><b>Ocular History</b></h4>
                <div class="row">
                    <div class="col-md-12">
                        <label class="">Check any of following that you have had:</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10">
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadCrossedEyes"
                                class="mx-2">Crossed
                            Eyes</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadRetinalDisease"
                                class="mx-2">Retinal Disease</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyePain" class="mx-2">Eye
                            Pain</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadFloaters"
                                class="mx-2">Floaters</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadLazyEye" class="mx-2">Lazy
                            Eyes</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadCataracts"
                                class="mx-2">Cataracts</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadItching"
                                class="mx-2">Itching</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadFlashes"
                                class="mx-2">Flashes</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadGlaucoma"
                                class="mx-2">Glaucoma</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadDroopingEyelid"
                                class="mx-2">Drooping
                            Eyelid</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyeInfections" class="mx-2">Eye
                            Infections</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadTearing"
                                class="mx-2">Tearing</label><br>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadHalos" class="mx-2">Halos
                            around
                            lights</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadProminentEyes"
                                class="mx-2">Prominent
                            Eyes</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyeInjury" class="mx-2">Eye
                            Injury</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadBurning"
                                class="mx-2">Burning</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadDoubleVision"
                                class="mx-2">Double
                            Vision</label>
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadRedness"
                                class="mx-2">Redness</label>
                    </div>
                    <!-- <div class="col-md-2">
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadCrossedEyes" class="mx-2">Crossed
                            Eyes</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadRetinalDisease" class="mx-2">Retinal
                            Disease</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyePain" class="mx-2">Eye Pain</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadFloaters" class="mx-2">Floaters</label>
                        </div>
                        <div class="col-md-2">
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadLazyEye" class="mx-2">Lazy Eyes</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadCataracts"
                                class="mx-2">Cataracts</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadItching" class="mx-2">Itching</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadFlashes" class="mx-2">Flashes</label>
                        </div>
                        <div class="col-md-3">
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadDroopingEyelid" class="mx-2">Drooping
                            Eyelid</label><br>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyeInfections" class="mx-2">Eye
                            Infections</label><br>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadTearing"
                                class="mx-2">Tearing</label><br>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadHalos" class="mx-2">Halos around
                            lights</label><br>
                        </div>
                        <div class="col-md-2">
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadProminentEyes" class="mx-2">Prominent
                            Eyes</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyeInjury" class="mx-2">Eye
                            Injury</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadBurning" class="mx-2">Burning</label>
                        </div>
                        <div class="col-md-2">
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadGlaucoma" class="mx-2">Glaucoma</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadDoubleVision" class="mx-2">Double
                            Vision</label>
                            <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadRedness" class="mx-2">Redness</label>
                        </div>-->
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Are you interested in laser vision correction?</label>
                    </div>
                    <div class="col-md-7">
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'interestedInLaserVision')"
                                [checked]="patientReviewModel.interestedInLaserVision" class="mx-2"><span
                                [ngClass]="patientReviewModel.interestedInLaserVision == true ? 'backGround' : ''">Yes</span></label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'interestedInLaserVision')"
                                [checked]="!patientReviewModel.interestedInLaserVision"
                                class="mx-2"><span>No</span></label><br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label>Are you interested in hearing about treatments for dry eyes?</label>
                    </div>
                    <div class="col-md-7" style="margin-left: 50px;">
                        <label><input type="radio"
                                (change)="checkArrayRadio(true,patientReviewModel,'interestedInDryEyeTreatment')"
                                [checked]="patientReviewModel.interestedInDryEyeTreatment" class="mx-2"><span
                                [ngClass]="patientReviewModel.interestedInDryEyeTreatment == true ? 'backGround' : ''">Yes</span></label>
                        <label><input type="radio"
                                (change)="checkArrayRadio(false,patientReviewModel,'interestedInDryEyeTreatment')"
                                [checked]="!patientReviewModel.interestedInDryEyeTreatment"
                                class="mx-2"><span>No</span></label>
                    </div>
                </div>
                <h4 class="text-center"><b>Family Medical History</b></h4>
                <div class="row">
                    <div class="col-md-10">
                        <label>Please Note any family history (Parents,Grandparents,Children,Siblings,Living or
                            Deceased)for
                            the
                            following:</label><br>
                        <table class="table-bordered" style="font-size: 14px !important; width:100%">
                            <tr>
                                <th>DISEASE/CONDITION</th>
                                <th>Yes / No / ?</th>
                                <th>RELATIONSHIP TO YOU</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>Blindness (275118009)<br><a  href="javascript:void(0)" (click)="Add_Blindness()">Add>></a></th>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'blindnessInFamily')"
                                            [checked]="patientReviewModel.blindnessInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.blindnessInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'blindnessInFamily')"
                                            [checked]="patientReviewModel.blindnessInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'blindnessInFamily')"
                                            [checked]="patientReviewModel.blindnessInFamily == 3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-md-11">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'blindnessRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                                (keydown.tab)="saveDropdown(patientReviewModel.blindnessRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'blindnessRelation',dbEnum.QE_Dev_Config)"
                                                placeholder="Select Relation"
                                                [(ngModel)]="patientReviewModel.blindnessRelation"
                                                [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                                    {{r.name_txt}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                    <!-- <input type="text" [(ngModel)]="patientReviewModel.blindnessRelation" class="form-control"> -->

                                </td>
                            </tr>
                            <tr *ngFor="let Blindness of Blindness_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2">Blindness</label>

                                    </div>
                                </td>
                                <td>
                                    <!-- <label>
                                        <input type="radio"  name="Blindness_array{{i}}.condition_yes_no"
                                        [checked]="Blindness_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Blindness_array[i],'condition_yes_no',i)"
                                        class="mx-2">
                                        <span  [ngClass]="Blindness_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="Blindness_array{{i}}.condition_yes_no"
                                            [checked]="Blindness_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Blindness_array[i],'condition_yes_no',i)"
                                            class="mx-2">
                                        <span>No</span>
                                    </label> -->
                                    <label>
                                        <input type="radio"  name="Blindness_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Blindness_array[i].condition_yes_no"
                                        [value]="true"
                                        (click)="checkArrayRadio(true,Blindness_array[i],'condition_yes_no',i)"
                                        class="mx-2">
                                        <span (click)="checkArrayRadio(true,Blindness_array[i],'condition_yes_no',i)" [ngClass]="Blindness_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="Blindness_array{{i}}.condition_yes_no"
                                            [(ngModel)]="Blindness_array[i].condition_yes_no"
                                            [value]="false"
                                            (click)="checkArrayRadio(false,Blindness_array[i],'condition_yes_no',i)"
                                            class="mx-2">
                                        <span>No</span>
                                    </label>
                                </td>
                                <td>

                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" style="border-radius: 5px;" (keyup)="keyFunc($event, Blindness, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew"
                                       (keydown.tab)="saveDropdown(Blindness.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Blindness,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Blindness.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deleteBlindness(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th>Cataract (193570009)<br><a href="javascript:void(0)" (click)="Add_Cataract()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'cataractInFamily')"
                                            [checked]="patientReviewModel.cataractInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.cataractInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'cataractInFamily')"
                                            [checked]="patientReviewModel.cataractInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'cataractInFamily')"
                                            [checked]="patientReviewModel.cataractInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label>
                                        <input type="radio" name="patientReviewModel.cataractInFamily"
                                            [(ngModel)]="patientReviewModel.cataractInFamily"
                                            [value]="1"
                                            (click)="checkArrayRadio(1,patientReviewModel,'cataractInFamily')"
                                            class="mx-2">
                                        <span
                                            [ngClass]="patientReviewModel.cataractInFamily == 1 ? 'backGround' : ''">Yes</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="patientReviewModel.cataractInFamily"
                                        [(ngModel)]="patientReviewModel.cataractInFamily"
                                        [value]="2"
                                        (click)="checkArrayRadio(2,patientReviewModel,'cataractInFamily')"
                                        class="mx-2">
                                        <span>No</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="patientReviewModel.cataractInFamily"
                                        [(ngModel)]="patientReviewModel.cataractInFamily"
                                        [value]="3"
                                        (click)="checkArrayRadio(3,patientReviewModel,'cataractInFamily')"
                                        class="mx-2">
                                        <span>?</span>
                                    </label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col-md-11">
                                            <!-- <input type="text" [(ngModel)]="patientReviewModel.cataractRelation" class="form-control"> -->
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'cataractRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                                (keydown.tab)="saveDropdown(patientReviewModel.cataractRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'cataractRelation',dbEnum.QE_Dev_Config)"
                                                placeholder="Select Relation"
                                                [(ngModel)]="patientReviewModel.cataractRelation"
                                                [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                                    {{r.name_txt}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let Cataract of Cataract_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Cataract"  [(ngModel)]="Cataract.condition_txt">Cataract</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label>
                                        <input type="radio"  name="Cataract_array{{i}}.condition_yes_no"
                                        [checked]="Cataract_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Cataract_array[i],'condition_yes_no',i)"
                                        class="mx-2">
                                        <span  [ngClass]="Cataract_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="Cataract_array{{i}}.condition_yes_no"
                                            [checked]="Cataract_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Cataract_array[i],'condition_yes_no',i)"
                                            class="mx-2">
                                        <span>No</span>
                                    </label> -->
                                    <label>
                                        <input type="radio"  name="Cataract_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Cataract_array[i].condition_yes_no"
                                        [value]="true"
                                        (click)="checkArrayRadio(true,Cataract_array[i],'condition_yes_no',i)"
                                        class="mx-2">
                                        <span (click)="checkArrayRadio(true,Cataract_array[i],'condition_yes_no',i)" [ngClass]="Cataract_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                    </label>
                                    <label>
                                        <input type="radio" name="Cataract_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Cataract_array[i].condition_yes_no"
                                        [value]="false"
                                        (click)="checkArrayRadio(false,Cataract_array[i],'condition_yes_no',i)"
                                        class="mx-2">
                                        <span>No</span>
                                    </label>

                                </td>
                                <td>
                                    <!-- <input type="text" class="f-control" [(ngModel)]="familyHistory.otherRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Cataract, 'relationship_to_patient_txt')"  addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Cataract.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Cataract,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Cataract.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deleteCataract(i)">Remove</button>
                                </td>
                            </tr>

                            <tr>
                                <th>Glaucoma (23986001)<br><a href="javascript:void(0)" (click)="Add_Glaucoma()">Add>></a></th>
                                <td>
                                    <!-- <label>
                                        <input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'glaucomaInFamily')"
                                            [checked]="patientReviewModel.glaucomaInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.glaucomaInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'glaucomaInFamily')"
                                            [checked]="patientReviewModel.glaucomaInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'glaucomaInFamily')"
                                            [checked]="patientReviewModel.glaucomaInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label>
                                        <input type="radio" name="patientReviewModel.glaucomaInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'glaucomaInFamily')"
                                            [(ngModel)]="patientReviewModel.glaucomaInFamily"
                                            [value]="1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.glaucomaInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"  name="patientReviewModel.glaucomaInFamily"
                                            (click)="checkArrayRadio(2,patientReviewModel,'glaucomaInFamily')"
                                            [(ngModel)]="patientReviewModel.glaucomaInFamily"
                                            [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"  name="patientReviewModel.glaucomaInFamily"
                                            (click)="checkArrayRadio(3,patientReviewModel,'glaucomaInFamily')"
                                            [(ngModel)]="patientReviewModel.glaucomaInFamily"
                                            [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.glaucomaRelation"> -->
                                    <div class="form-row">
                                        <div class="col-md-11">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'glaucomaRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                                (keydown.tab)="saveDropdown(patientReviewModel.glaucomaRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'glaucomaRelation',dbEnum.QE_Dev_Config)"
                                                placeholder="Select Relation"
                                                [(ngModel)]="patientReviewModel.glaucomaRelation"
                                                [ngModelOptions]="{standalone: true}">
                                                <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                                    {{r.name_txt}}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let Glaucoma of Glaucoma_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Glaucoma"  [(ngModel)]="Glaucoma.condition_txt">Glaucoma</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="Glaucoma_array{{i}}.condition_yes_no"
                                        [checked]="Glaucoma_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Glaucoma_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="Glaucoma_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Glaucoma_array{{i}}.condition_yes_no"
                                            [checked]="Glaucoma_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Glaucoma_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="Glaucoma_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Glaucoma_array[i].condition_yes_no"
                                        [value]="true"
                                        (click)="checkArrayRadio(true,Glaucoma_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,Glaucoma_array[i],'condition_yes_no',i)"
                                        [ngClass]="Glaucoma_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="Glaucoma_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Glaucoma_array[i].condition_yes_no"
                                        [value]="false"
                                        (click)="checkArrayRadio(false,Glaucoma_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span>No</span></label>

                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Glaucoma, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Glaucoma.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Glaucoma,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Glaucoma.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deleteGlaucoma(i)">Remove</button>
                                </td>
                            </tr>


                            <tr>
                                <th>Lazy Eyes (246623008)<br><a href="javascript:void(0)" (click)="Add_LazyEyes()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'lazyCrossedEyesInFamily')"
                                            [checked]="patientReviewModel.lazyCrossedEyesInFamily == 1"
                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.lazyCrossedEyesInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'lazyCrossedEyesInFamily')"
                                            [checked]="patientReviewModel.lazyCrossedEyesInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'lazyCrossedEyesInFamily')"
                                            [checked]="patientReviewModel.lazyCrossedEyesInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.lazyCrossedEyesInFamily"
                                        (click)="checkArrayRadio(1,patientReviewModel,'lazyCrossedEyesInFamily')"
                                        [(ngModel)]="patientReviewModel.lazyCrossedEyesInFamily"
                                        [value]="1"
                                        class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'lazyCrossedEyesInFamily')"
                                        [ngClass]="patientReviewModel.lazyCrossedEyesInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.lazyCrossedEyesInFamily"
                                            (click)="checkArrayRadio(2,patientReviewModel,'lazyCrossedEyesInFamily')"
                                            [(ngModel)]="patientReviewModel.lazyCrossedEyesInFamily"
                                            [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.lazyCrossedEyesInFamily"
                                            (click)="checkArrayRadio(3,patientReviewModel,'lazyCrossedEyesInFamily')"
                                            [(ngModel)]="patientReviewModel.lazyCrossedEyesInFamily"
                                            [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.lazyCrossedEyesRelation"> -->
                                    <div class="form-row">
                                        <div class="col-md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'lazyCrossedEyesRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                        (keydown.tab)="saveDropdown(patientReviewModel.lazyCrossedEyesRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'lazyCrossedEyesRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.lazyCrossedEyesRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>

                            <tr *ngFor="let LazyEyes of LazyEyes_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="LazyEyes"  [(ngModel)]="LazyEyes.condition_txt">Lazy Eyes</label>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio"  name="LazyEyes_array{{i}}.condition_yes_no "
                                        [(ngModel)]="LazyEyes_array[i].condition_yes_no"
                                        [value]="true"
                                        (change)="checkArrayRadio(true,LazyEyes_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,LazyEyes_array[i],'condition_yes_no',i)"
                                        [ngClass]="LazyEyes_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="LazyEyes_array{{i}}.condition_yes_no "
                                            [(ngModel)]="LazyEyes_array[i].condition_yes_no"
                                            [value]="false"
                                            (change)="checkArrayRadio(false,LazyEyes_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, LazyEyes, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(LazyEyes.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',LazyEyes,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="LazyEyes.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deleteLazyEyes(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th>Macular Degeneration (26771800)<br><a href="javascript:void(0)" (click)="Add_MacularDegeneration()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'macularDegenerationInFamily')"
                                            [checked]="patientReviewModel.macularDegenerationInFamily == 1"
                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.macularDegenerationInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'macularDegenerationInFamily')"
                                            [checked]="patientReviewModel.macularDegenerationInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'macularDegenerationInFamily')"
                                            [checked]="patientReviewModel.macularDegenerationInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.macularDegenerationInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'macularDegenerationInFamily')"
                                            [(ngModel)]="patientReviewModel.macularDegenerationInFamily"
                                            [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'macularDegenerationInFamily')"
                                            [ngClass]="patientReviewModel.macularDegenerationInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.macularDegenerationInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'macularDegenerationInFamily')"
                                            [(ngModel)]="patientReviewModel.macularDegenerationInFamily"
                                            [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.macularDegenerationInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'macularDegenerationInFamily')"
                                            [(ngModel)]="patientReviewModel.macularDegenerationInFamily"
                                            [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.lazyCrossedEyesRelation"> -->
                                    <div class="form-row">
                                        <div class="col-md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'macularDegenerationRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.macularDegenerationRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'macularDegenerationRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.macularDegenerationRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let MacularDegeneration of MacularDegeneration_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="MacularDegeneration"  [(ngModel)]="MacularDegeneration.condition_txt">Macular Degeneration</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="MacularDegeneration_array{{i}}.condition_yes_no"
                                        [checked]="MacularDegeneration_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,MacularDegeneration_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="MacularDegeneration_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="MacularDegeneration_array{{i}}.condition_yes_no"
                                            [checked]="MacularDegeneration_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,MacularDegeneration_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                            <label><input type="radio"  name="MacularDegeneration_array{{i}}.condition_yes_no"
                                                [(ngModel)]="MacularDegeneration_array[i].condition_yes_no"
                                                [value]="true"
                                                (change)="checkArrayRadio(true,MacularDegeneration_array[i],'condition_yes_no',i)"
                                                class="mx-2"><span (click)="checkArrayRadio(true,MacularDegeneration_array[i],'condition_yes_no',i)"
                                                [ngClass]="MacularDegeneration_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                                <label><input type="radio" name="MacularDegeneration_array{{i}}.condition_yes_no"
                                                    [(ngModel)]="MacularDegeneration_array[i].condition_yes_no"
                                                    [value]="false"
                                                    (change)="checkArrayRadio(false,MacularDegeneration_array[i],'condition_yes_no',i)"
                                                    class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, MacularDegeneration, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(MacularDegeneration.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',MacularDegeneration,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="MacularDegeneration.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deleteMacularDegeneration(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th>Retinal Detachment (42059000)<br><a href="javascript:void(0)" (click)="Add_RetinalDetachment()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'retinalDetachmentInFamily')"
                                            [checked]="patientReviewModel.retinalDetachmentInFamily == 1"
                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.retinalDetachmentInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'retinalDetachmentInFamily')"
                                            [checked]="patientReviewModel.retinalDetachmentInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'retinalDetachmentInFamily')"
                                            [checked]="patientReviewModel.retinalDetachmentInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.retinalDetachmentInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'retinalDetachmentInFamily')"
                                            [(ngModel)]="patientReviewModel.retinalDetachmentInFamily"
                                            [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'retinalDetachmentInFamily')"
                                            [ngClass]="patientReviewModel.retinalDetachmentInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.retinalDetachmentInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'retinalDetachmentInFamily')"
                                            [(ngModel)]="patientReviewModel.retinalDetachmentInFamily"
                                            [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.retinalDetachmentInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'retinalDetachmentInFamily')"
                                            [(ngModel)]="patientReviewModel.retinalDetachmentInFamily"
                                            [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.retinalDetachmentRelation"> -->
                                    <div class="form-row">
                                        <div class="col-md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'retinalDetachmentRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                        (keydown.tab)="saveDropdown(patientReviewModel.retinalDetachmentRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'retinalDetachmentRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.retinalDetachmentRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let RetinalDetachment of RetinalDetachment_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="RetinalDetachment"  [(ngModel)]="RetinalDetachment.condition_txt">Retinal Detachment</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="RetinalDetachment_array{{i}}.condition_yes_no"
                                        [checked]="RetinalDetachment_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,RetinalDetachment_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="RetinalDetachment_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="RetinalDetachment_array{{i}}.condition_yes_no"
                                            [checked]="RetinalDetachment_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,RetinalDetachment_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="RetinalDetachment_array{{i}}.condition_yes_no"
                                        [(ngModel)]="RetinalDetachment_array[i].condition_yes_no"
                                        [value]="true"
                                        (change)="checkArrayRadio(true,RetinalDetachment_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,RetinalDetachment_array[i],'condition_yes_no',i)"
                                        [ngClass]="RetinalDetachment_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="RetinalDetachment_array{{i}}.condition_yes_no"
                                            [(ngModel)]="RetinalDetachment_array[i].condition_yes_no"
                                            [value]="false"
                                            (change)="checkArrayRadio(false,RetinalDetachment_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>

                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, RetinalDetachment, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(RetinalDetachment.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',RetinalDetachment,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="RetinalDetachment.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletRetinalDetachment(i)">Remove</button>
                                </td>
                            </tr>

                            <tr>
                                <th>Retinal Disease (29555009)<br><a href="javascript:void(0)" (click)="Add_RetinalDisease()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'retinalDiseaseInFamily')"
                                            [checked]="patientReviewModel.retinalDiseaseInFamily == 1"
                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.retinalDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'retinalDiseaseInFamily')"
                                            [checked]="patientReviewModel.retinalDiseaseInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'retinalDiseaseInFamily')"
                                            [checked]="patientReviewModel.retinalDiseaseInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.retinalDiseaseInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'retinalDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.retinalDiseaseInFamily" [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'retinalDiseaseInFamily')"
                                            [ngClass]="patientReviewModel.retinalDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.retinalDiseaseInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'retinalDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.retinalDiseaseInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.retinalDiseaseInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'retinalDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.retinalDiseaseInFamily" [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.retinalDiseaseRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'retinalDiseaseRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.retinalDiseaseRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'retinalDiseaseRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.retinalDiseaseRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let RetinalDisease of RetinalDisease_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="RetinalDisease"  [(ngModel)]="RetinalDisease.condition_txt">Retinal Disease</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="RetinalDisease_array{{i}}.condition_yes_no"
                                        [checked]="RetinalDisease_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,RetinalDisease_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="RetinalDisease_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="RetinalDisease_array{{i}}.condition_yes_no"
                                            [checked]="RetinalDisease_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,RetinalDisease_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="RetinalDisease_array{{i}}.condition_yes_no"
                                        [(ngModel)]="RetinalDisease_array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,RetinalDisease_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,RetinalDisease_array[i],'condition_yes_no',i)"
                                        [ngClass]="RetinalDisease_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="RetinalDisease_array{{i}}.condition_yes_no"
                                            [(ngModel)]="RetinalDisease_array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,RetinalDisease_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, RetinalDisease, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(RetinalDisease.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',RetinalDisease,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="RetinalDisease.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletRetinalDisease(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th style="font-weight: bold !important">Arthritis (3723001)<br><a href="javascript:void(0)" (click)="Add_Arthrits()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'arthritisInFamily')"
                                            [checked]="patientReviewModel.arthritisInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.arthritisInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'arthritisInFamily')"
                                            [checked]="patientReviewModel.arthritisInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'arthritisInFamily')"
                                            [checked]="patientReviewModel.arthritisInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.arthritisInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'arthritisInFamily')"
                                            [(ngModel)]="patientReviewModel.arthritisInFamily" [value]="1"
                                            class="mx-2">
                                            <span (click)="checkArrayRadio(1,patientReviewModel,'arthritisInFamily')"
                                            [ngClass]="patientReviewModel.arthritisInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.arthritisInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'arthritisInFamily')"
                                            [(ngModel)]="patientReviewModel.arthritisInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.arthritisInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'arthritisInFamily')"
                                            [(ngModel)]="patientReviewModel.arthritisInFamily" [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.arthritisRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'arthritisRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.arthritisRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'arthritisRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="patientReviewModel.arthritisRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let Arthrits of Arthrits_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Arthrits"  [(ngModel)]="Arthrits.condition_txt">Arthritis</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="Arthrits_array{{i}}.condition_yes_no"
                                        [checked]="Arthrits_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Arthrits_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="Arthrits_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Arthrits_array{{i}}.condition_yes_no"
                                            [checked]="Arthrits_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Arthrits_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="Arthrits_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Arthrits_array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,Arthrits_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,Arthrits_array[i],'condition_yes_no',i)"
                                        [ngClass]="Arthrits_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Arthrits_array{{i}}.condition_yes_no"
                                            [(ngModel)]="Arthrits_array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,Arthrits_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Arthrits, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Arthrits.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Arthrits,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Arthrits.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletArthrits(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th>Cancer (275937001)<br><a href="javascript:void(0)" (click)="Add_Cancer()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'cancerInFamily')"
                                            [checked]="patientReviewModel.cancerInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.cancerInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'cancerInFamily')"
                                            [checked]="patientReviewModel.cancerInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'cancerInFamily')"
                                            [checked]="patientReviewModel.cancerInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.cancerInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'cancerInFamily')"
                                            [(ngModel)]="patientReviewModel.cancerInFamily" [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'cancerInFamily')"
                                            [ngClass]="patientReviewModel.cancerInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.cancerInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'cancerInFamily')"
                                            [(ngModel)]="patientReviewModel.cancerInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.cancerInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'cancerInFamily')"
                                            [(ngModel)]="patientReviewModel.cancerInFamily" [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.cancerRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'cancerRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                        (keydown.tab)="saveDropdown(patientReviewModel.cancerRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'cancerRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="patientReviewModel.cancerRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let Cancer of Cancer_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Cancer"  [(ngModel)]="Cancer.condition_txt">Cancer</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="Cancer_array{{i}}.condition_yes_no"
                                        [checked]="Cancer_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Cancer_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="Cancer_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Cancer_array{{i}}.condition_yes_no"
                                            [checked]="Cancer_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Cancer_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="Cancer_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Cancer_array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,Cancer_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,Cancer_array[i],'condition_yes_no',i)"
                                        [ngClass]="Cancer_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Cancer_array{{i}}.condition_yes_no"
                                            [(ngModel)]="Cancer_array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,Cancer_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Cancer, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Cancer.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Cancer,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Cancer.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletCancer(i)">Remove</button>
                                </td>
                            </tr>

                            <tr>
                                <th>Diabetes (466635009)<br><a href="javascript:void(0)" (click)="Add_Diabetes()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'diabetesInFamily')"
                                            [checked]="patientReviewModel.diabetesInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.diabetesInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'diabetesInFamily')"
                                            [checked]="patientReviewModel.diabetesInFamily == 2"
                                            class="mx-2"><span>No</span>
                                    </label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'diabetesInFamily')"
                                            [checked]="patientReviewModel.diabetesInFamily == 3"
                                            class="mx-2"><span>?</span>
                                    </label> -->
                                    <label><input type="radio" name="patientReviewModel.diabetesInFamily"
                                        (change)="checkArrayRadio(1,patientReviewModel,'diabetesInFamily')"
                                        [(ngModel)]="patientReviewModel.diabetesInFamily" [value]="1"
                                        class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'diabetesInFamily')"
                                        [ngClass]="patientReviewModel.diabetesInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.diabetesInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'diabetesInFamily')"
                                            [(ngModel)]="patientReviewModel.diabetesInFamily" [value]="2"
                                            class="mx-2"><span>No</span>
                                    </label>
                                    <label><input type="radio" name="patientReviewModel.diabetesInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'diabetesInFamily')"
                                            [(ngModel)]="patientReviewModel.diabetesInFamily" [value]="3"
                                            class="mx-2"><span>?</span>
                                    </label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.diabetesRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'diabetesRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.diabetesRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'diabetesRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="patientReviewModel.diabetesRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>

                                </td>
                            </tr>
                            <tr *ngFor="let Diabetes of Diabetes_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Diabetes"  [(ngModel)]="Diabetes.condition_txt">Diabetes</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="Diabetes_array{{i}}.condition_yes_no"
                                        [checked]="Diabetes_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Diabetes_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="Diabetes_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Diabetes_array{{i}}.condition_yes_no"
                                            [checked]="Diabetes_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Diabetes_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="Diabetes_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Diabetes_array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,Diabetes_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,Diabetes_array[i],'condition_yes_no',i)"
                                        [ngClass]="Diabetes_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Diabetes_array{{i}}.condition_yes_no"
                                            [(ngModel)]="Diabetes_array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,Diabetes_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Diabetes, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Diabetes.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Diabetes,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Diabetes.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletDiabetes(i)">Remove</button>
                                </td>
                            </tr>

                            <tr>
                                <th>Heart Disease (266894000)<br><a href="javascript:void(0)" (click)="Add_HeartDisease()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'heartDiseaseInFamily')"
                                            [checked]="patientReviewModel.heartDiseaseInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.heartDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'heartDiseaseInFamily')"
                                            [checked]="patientReviewModel.heartDiseaseInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'heartDiseaseInFamily')"
                                            [checked]="patientReviewModel.heartDiseaseInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.heartDiseaseInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'heartDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.heartDiseaseInFamily" [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'heartDiseaseInFamily')"
                                            [ngClass]="patientReviewModel.heartDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.heartDiseaseInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'heartDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.heartDiseaseInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.heartDiseaseInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'heartDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.heartDiseaseInFamily" [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.heartDiseaseRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'heartDiseaseRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.heartDiseaseRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'heartDiseaseRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.heartDiseaseRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let  HeartDisease of HeartDisease_array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="HeartDisease"  [(ngModel)]="HeartDisease.condition_txt">Heart Disease</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="HeartDisease_array{{i}}.condition_yes_no"
                                        [checked]="HeartDisease_array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,HeartDisease_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="HeartDisease_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="HeartDisease_array{{i}}.condition_yes_no"
                                            [checked]="HeartDisease_array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,HeartDisease_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="HeartDisease_array{{i}}.condition_yes_no"
                                        [(ngModel)]="HeartDisease_array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,HeartDisease_array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,HeartDisease_array[i],'condition_yes_no',i)"
                                        [ngClass]="HeartDisease_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="HeartDisease_array{{i}}.condition_yes_no"
                                            [(ngModel)]="HeartDisease_array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,HeartDisease_array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, HeartDisease, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(HeartDisease.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',HeartDisease,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="HeartDisease.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletHeartDisease(i)">Remove</button>
                                </td>
                            </tr>

                            <tr>
                                <th>High Blood Pressure (38936003)<br><a href="javascript:void(0)" (click)="Add_HighBlood_Pressure()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'highBloodPressureInFamily')"
                                            [checked]="patientReviewModel.highBloodPressureInFamily == 1"
                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.highBloodPressureInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'highBloodPressureInFamily')"
                                            [checked]="patientReviewModel.highBloodPressureInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'highBloodPressureInFamily')"
                                            [checked]="patientReviewModel.highBloodPressureInFamily == 3"
                                            class="mx-2"><Span>?</Span>
                                    </label> -->
                                    <label><input type="radio" name="patientReviewModel.highBloodPressureInFamily"
                                        (change)="checkArrayRadio(1,patientReviewModel,'highBloodPressureInFamily')"
                                        [(ngModel)]="patientReviewModel.highBloodPressureInFamily" [value]="1"
                                        class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'highBloodPressureInFamily')"
                                        [ngClass]="patientReviewModel.highBloodPressureInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.highBloodPressureInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'highBloodPressureInFamily')"
                                            [(ngModel)]="patientReviewModel.highBloodPressureInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.highBloodPressureInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'highBloodPressureInFamily')"
                                            [(ngModel)]="patientReviewModel.highBloodPressureInFamily" [value]="3"
                                            class="mx-2"><Span>?</Span>
                                    </label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.highBloodPressureRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'highBloodPressureRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.highBloodPressureRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'highBloodPressureRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.highBloodPressureRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let HighBlood_Pressure of HighBlood_Pressure_Array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="HighBlood_Pressure"  [(ngModel)]="HighBlood_Pressure.condition_txt">High Blood Pressure</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="HighBlood_Pressure_Array{{i}}.condition_yes_no"
                                        [checked]="HighBlood_Pressure_Array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,HighBlood_Pressure_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="HighBlood_Pressure_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                    </label>
                                    <label><input type="radio" name="HighBlood_Pressure_Array{{i}}.condition_yes_no"
                                            [checked]="HighBlood_Pressure_Array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,HighBlood_Pressure_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span>
                                    </label> -->
                                    <label><input type="radio"  name="HighBlood_Pressure_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="HighBlood_Pressure_Array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,HighBlood_Pressure_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,HighBlood_Pressure_Array[i],'condition_yes_no',i)"
                                        [ngClass]="HighBlood_Pressure_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                    </label>
                                    <label><input type="radio" name="HighBlood_Pressure_Array{{i}}.condition_yes_no"
                                            [(ngModel)]="HighBlood_Pressure_Array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,HighBlood_Pressure_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span>
                                    </label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, HighBlood_Pressure, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(HighBlood_Pressure.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',HighBlood_Pressure,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="HighBlood_Pressure.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletHighBlood_Pressure(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th>Kidney Disease (433146000)<br><a href="javascript:void(0)" (click)="Add_Kidney_Pressure()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [checked]="patientReviewModel.kidneyDiseaseInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.kidneyDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [checked]="patientReviewModel.kidneyDiseaseInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [checked]="patientReviewModel.kidneyDiseaseInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.kidneyDiseaseInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.kidneyDiseaseInFamily" [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [ngClass]="patientReviewModel.kidneyDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.kidneyDiseaseInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.kidneyDiseaseInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.kidneyDiseaseInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'kidneyDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.kidneyDiseaseInFamily" [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.kidneyDiseaseRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'kidneyDiseaseRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.kidneyDiseaseRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'kidneyDiseaseRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.kidneyDiseaseRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>

                            <tr *ngFor="let Kidney of Kidney_Pressure_Array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Kidney"  [(ngModel)]="Kidney.condition_txt">Kidney Disease </label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="Kidney_Pressure_Array{{i}}.condition_yes_no"
                                        [checked]="Kidney_Pressure_Array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Kidney_Pressure_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="Kidney_Pressure_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Kidney_Pressure_Array{{i}}.condition_yes_no"
                                            [checked]="Kidney_Pressure_Array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Kidney_Pressure_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="Kidney_Pressure_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="Kidney_Pressure_Array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,Kidney_Pressure_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,Kidney_Pressure_Array[i],'condition_yes_no',i)"
                                        [ngClass]="Kidney_Pressure_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Kidney_Pressure_Array{{i}}.condition_yes_no"
                                            [(ngModel)]="Kidney_Pressure_Array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,Kidney_Pressure_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Kidney, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Kidney.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Kidney,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Kidney.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="deletKidney_Pressure(i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <th>Lupus (55464009)<br><a href="javascript:void(0)" (click)="Add_Lapus()">Add>></a></th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'lupusInFamily')"
                                            [checked]="patientReviewModel.lupusInFamily == 1" class="mx-2"><span
                                            [ngClass]="patientReviewModel.lupusInFamily == 1 ? 'backGround' : ''">Yes</span>
                                    </label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'lupusInFamily')"
                                            [checked]="patientReviewModel.lupusInFamily == 2"
                                            class="mx-2"><span>No</span>
                                    </label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'lupusInFamily')"
                                            [checked]="patientReviewModel.lupusInFamily == 3"
                                            class="mx-2"><span>?</span>
                                    </label> -->
                                    <label><input type="radio" name="patientReviewModel.lupusInFamily"
                                        (change)="checkArrayRadio(1,patientReviewModel,'lupusInFamily')"
                                        [(ngModel)]="patientReviewModel.lupusInFamily" [value]="1"
                                        class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'lupusInFamily')"
                                        [ngClass]="patientReviewModel.lupusInFamily == 1 ? 'backGround' : ''">Yes</span>
                                    </label>
                                    <label><input type="radio" name="patientReviewModel.lupusInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'lupusInFamily')"
                                            [(ngModel)]="patientReviewModel.lupusInFamily" [value]="2"
                                            class="mx-2"><span>No</span>
                                    </label>
                                    <label><input type="radio" name="patientReviewModel.lupusInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'lupusInFamily')"
                                            [(ngModel)]="patientReviewModel.lupusInFamily" [value]="3"
                                            class="mx-2"><span>?</span>
                                    </label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.lupusRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'lupusRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                        (keydown.tab)="saveDropdown(patientReviewModel.lupusRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'lupusRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="patientReviewModel.lupusRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>

                            <tr *ngFor="let Lupus of Lupus_Array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="Lupus"  [(ngModel)]="Lupus.condition_txt">Lupus</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="Lupus_Array{{i}}.condition_yes_no"
                                        [checked]="Lupus_Array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,Lupus_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="Lupus_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Lupus_Array{{i}}.condition_yes_no"
                                            [checked]="Lupus_Array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,Lupus_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="Lupus_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="Lupus_Array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,Lupus_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,Lupus_Array[i],'condition_yes_no',i)"
                                        [ngClass]="Lupus_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="Lupus_Array{{i}}.condition_yes_no"
                                            [(ngModel)]="Lupus_Array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,Lupus_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, Lupus, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(Lupus.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',Lupus,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="Lupus.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="delet_Lapus(i)">Remove</button>
                                </td>
                            </tr>

                            <tr>
                                <th>Thyroid Disease (160302006)<br><a href="javascript:void(0)" (click)="Add_ThyroidDisease()">Add>></a> </th>
                                <td>
                                    <!-- <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [checked]="patientReviewModel.thyroidDiseaseInFamily == 1"
                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.thyroidDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [checked]="patientReviewModel.thyroidDiseaseInFamily == 2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [checked]="patientReviewModel.thyroidDiseaseInFamily == 3"
                                            class="mx-2"><span>?</span></label> -->
                                    <label><input type="radio" name="patientReviewModel.thyroidDiseaseInFamily"
                                            (change)="checkArrayRadio(1,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.thyroidDiseaseInFamily" [value]="1"
                                            class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [ngClass]="patientReviewModel.thyroidDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.thyroidDiseaseInFamily"
                                            (change)="checkArrayRadio(2,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.thyroidDiseaseInFamily" [value]="2"
                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.thyroidDiseaseInFamily"
                                            (change)="checkArrayRadio(3,patientReviewModel,'thyroidDiseaseInFamily')"
                                            [(ngModel)]="patientReviewModel.thyroidDiseaseInFamily" [value]="3"
                                            class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="patientReviewModel.thyroidDiseaseRelation"> -->
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'thyroidDiseaseRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                        (keydown.tab)="saveDropdown(patientReviewModel.thyroidDiseaseRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel,'thyroidDiseaseRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation"
                                        [(ngModel)]="patientReviewModel.thyroidDiseaseRelation"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>0
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                            </tr>
                            <tr *ngFor="let ThyroidDisease of ThyroidDisease_Array;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1 px-2" value="ThyroidDisease"  [(ngModel)]="ThyroidDisease.condition_txt">Thyroid Disease</label>
                                    </div>
                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="ThyroidDisease_Array{{i}}.condition_yes_no"
                                        [checked]="ThyroidDisease_Array[i].condition_yes_no == true"
                                        (change)="checkArrayRadio(true,ThyroidDisease_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span  [ngClass]="ThyroidDisease_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="ThyroidDisease_Array{{i}}.condition_yes_no"
                                            [checked]="ThyroidDisease_Array[i].condition_yes_no == false"
                                            (change)="checkArrayRadio(false,ThyroidDisease_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label> -->
                                    <label><input type="radio"  name="ThyroidDisease_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="ThyroidDisease_Array[i].condition_yes_no" [value]="true"
                                        (change)="checkArrayRadio(true,ThyroidDisease_Array[i],'condition_yes_no',i)"
                                        class="mx-2"><span (click)="checkArrayRadio(true,ThyroidDisease_Array[i],'condition_yes_no',i)"
                                        [ngClass]="ThyroidDisease_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="ThyroidDisease_Array{{i}}.condition_yes_no"
                                            [(ngModel)]="ThyroidDisease_Array[i].condition_yes_no" [value]="false"
                                            (change)="checkArrayRadio(false,ThyroidDisease_Array[i],'condition_yes_no',i)"
                                            class="mx-2"><span>No</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-11">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, ThyroidDisease, 'relationship_to_patient_txt')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(ThyroidDisease.relationship_to_patient_txt,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',ThyroidDisease,'relationship_to_patient_txt',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="ThyroidDisease.relationship_to_patient_txt"
                                        [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="delet_ThyroidDisease(i)">Remove</button>
                                </td>
                            </tr>


                            <tr *ngFor="let familyHistory of patientReviewModel.familyHistories;let i = index">
                                <td>
                                    <div class="form-row">
                                        <div class="col-md-2">
                                            <label class="pt-1 px-2">Other</label>
                                        </div>
                                        <div class="col-md-6">
                                            <ng-select class="col-md-11 ng-select-style" [clearable]="true" (keyup)="keyFunc($event, patientReviewModel.familyHistories[i], 'familyhistory_other_txt')"  addTagText="Add New" [addTag]="CreateNew"
                                            [items]="familyHistoryLookup"
                                            (keydown.tab)="saveDropdown(patientReviewModel.familyHistories[i].familyhistory_other_txt,'Other',familyHistoryLookup,'familyhistoryother',patientReviewModel.familyHistories[i],'familyhistory_other_txt')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="patientReviewModel.familyHistories[i].familyhistory_other_txt">
                                        </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                            (click)="editLookup(familyHistoryLookup,familyHistoryCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>

                                    </div>

                                </td>
                                <td>
                                    <!-- <label><input type="radio"  name="patientReviewModel.familyHistories{{i}}.otherInFamily"
                                        [checked]="patientReviewModel.familyHistories[i].otherInFamily == 1"
                                        (change)="checkArrayRadio(true,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                        class="mx-2"><span  [ngClass]="patientReviewModel.familyHistories[i].otherInFamily == 1 ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="patientReviewModel.familyHistories{{i}}.otherInFamily"
                                            [checked]="patientReviewModel.familyHistories[i].otherInFamily == 2"
                                            (change)="checkArrayRadio(false,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                            class="mx-2"><span>No</span></label>
                                        <label><input type="radio" name="patientReviewModel.familyHistories{{i}}.otherInFamily"
                                            [checked]="patientReviewModel.familyHistories[i].otherInFamily == 3"
                                            (change)="checkArrayRadio(false,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                            class="mx-2"><span>?</span></label>                              -->

                                        <label><input type="radio" name="patientReviewModel.familyHistories{{i}}"
                                                [(ngModel)]="patientReviewModel.familyHistories[i].otherInFamily" [value]="1"
                                                (change)="checkArrayRadio(1,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                                class="mx-2"><span (click)="checkArrayRadio(1,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                                [ngClass]="patientReviewModel.familyHistories[i].otherInFamily == 1 ? 'backGround' : ''" >Yes</span></label>
                                        <label><input type="radio" name="patientReviewModel.familyHistories{{i}}"
                                                [(ngModel)]="patientReviewModel.familyHistories[i].otherInFamily" [value]="2"
                                                (change)="checkArrayRadio(2,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                                class="mx-2"><span>No</span></label>
                                        <label><input type="radio" name="patientReviewModel.familyHistories{{i}}"
                                                [(ngModel)]="patientReviewModel.familyHistories[i].otherInFamily" [value]="3"
                                                (change)="checkArrayRadio(3,patientReviewModel.familyHistories[i],'otherInFamily',i)"
                                                class="mx-2"><span>?</span></label>
                                </td>
                                <td>
                                    <div class="form-row">
                                        <div class="col md-10">

                                    <ng-select [clearable]="true" (keyup)="keyFunc($event, patientReviewModel.familyHistories[i], 'otherRelation')" addTagText="Add Item" [addTag]="CreateNew" style="border-radius: 5px;"
                                       (keydown.tab)="saveDropdown(patientReviewModel.familyHistories[i].otherRelation,'RELATIONSHIP TO YOU',relationLookup,'Family_Relationship',patientReviewModel.familyHistories[i],'otherRelation',dbEnum.QE_Dev_Config)"
                                        placeholder="Select Relation" [(ngModel)]="patientReviewModel.familyHistories[i].otherRelation"
                                        [ngModelOptions]="{standalone: true}    ">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                    <div class="col-md-1">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(relationLookup,relationcopyLookup,dbEnum.QE_Dev_Config)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeFamilyHistoryAddtional(i)">Remove</button>
                                </td>
                            </tr>
                        </table>
                        <button class="btn btn-primary px-4" (click)="addFamilyHistory()">Add</button>
                    </div>
                </div>
                <h4 class="text-center"><b>Immunization History</b></h4>
                <div class="row">
                    <div class="col-md-10">
                        <label>Please Note History for the following:</label>
                        <br>
                        <table class="table-bordered" style="font-size: 14px !important; width:100%">
                            <tr>
                                <th>Vaccine Name:</th>
                                <th>Date:</th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let Immunization of immunizationList;let i = index;">

                                <td>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Immunization, 'vaccine_name_txt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Immunization.vaccine_name_txt,'Vaccine Name',vaccineLookUp,'vaccinename',Immunization,'vaccine_name_txt')"
                                                [items]="vaccineLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Immunization.vaccine_name_txt">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(vaccineLookUp,vaccineLookUpCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                    <!-- <select class="form-control">
                                        <option>yadgf</option>
                                    </select> -->
                                </td>
                                <td>
                                    <input (ngModelChange)="Immunization.immunized_dt = $event"
                                    [ngModel]="Immunization.immunized_dt | date:'MM-dd-yyyy'" type="text"
                                    [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">

                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="removeImmunization(i)">Remove</button>
                                </td>
                            </tr>
                        </table>
                        <button class="btn btn-primary px-4" (click)="addImmunization()">Add</button>
                    </div>
                </div>
                <h4 class="text-center"><b>Social History</b></h4>
                <div class="row">
                    <div class="col-md-12">
                        <label>(This is information is kept strictly confidential. However, you may discuss this portion
                            directly
                            with the doctor if you prefer.)</label><br>
                        <label><input [(ngModel)]="patientReviewModel.preferDiscussWithDoctor" type="checkbox"
                                class="mx-2">Yes, I would prefer to discuss my Social History with the doctor
                        </label><br>
                        <div class="form-row">
                            <div class="col-md-2">
                                <label class="pt-1 px-2">Smoking Status:</label>
                            </div>
                            <div class="col-md-10">
                                <ng-select [clearable]="true"  addTagText="Add New" [addTag]="CreateNew"  [items]="smokingStatusDropDown"
                                    [(ngModel)]="patientReviewModel.smokeTypeAmount" bindLabel="name_txt"
                                    bindValue="name_txt">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-md-2">
                                <label class="pt-1">Do you use tobacco products?</label>
                            </div>
                            <div class="col-md-8">
                                <div class="form-row">
                                    <label class="mx-2 pt-1"><input type="radio"
                                            (change)="checkArrayRadio(true,patientReviewModel,'usesTobacco')"
                                            [checked]="patientReviewModel.usesTobacco" class="mx-2"><span
                                            [ngClass]="patientReviewModel.usesTobacco == true ? 'backGround' : ''">Yes</span></label>
                                    <label class="mx-2 pt-1"><input type="radio"
                                            (change)="checkArrayRadio(false,patientReviewModel,'usesTobacco')"
                                            [checked]="!patientReviewModel.usesTobacco"
                                            class="mx-2"><span>No</span></label>
                                    <label class="mx-2 pt-1">If yes,type/amount/how long:</label>
                                    <input class="form-control col-md-4"
                                        [(ngModel)]="patientReviewModel.tobaccoTypeAmount" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label class="pt-1">Do you use illegal drugs?</label>
                            </div>
                            <div class="col-md-8">
                                <div class="form-row">
                                    <label class="mx-2 pt-1"><input type="radio"
                                            (change)="checkArrayRadio(true,patientReviewModel,'usesIllegalDrugs')"
                                            [checked]="patientReviewModel.usesIllegalDrugs" class="mx-2"><span
                                            [ngClass]="patientReviewModel.usesIllegalDrugs == true ? 'backGround' : ''">Yes</span>
                                    </label>
                                    <label class="mx-2 pt-1"><input type="radio"
                                            (change)="checkArrayRadio(false,patientReviewModel,'usesIllegalDrugs')"
                                            [checked]="!patientReviewModel.usesIllegalDrugs"
                                            class="mx-2"><span>No</span>
                                    </label>
                                    <label class="mx-2 pt-1">If yes,type/amount/how long:</label>
                                    <input class="form-control col-md-4"
                                        [(ngModel)]="patientReviewModel.illegalDrugsTypeAmount" type="text">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label class="pt-1">Have you ever been exposed to or infected with:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="form-row">
                                            <ng-select [clearable]="true" class="col-md-4"
                                            [items]="socialHistoryInfectionLookup" bindLabel="key"
                                            bindValue="value" [(ngModel)]="patientReviewModel.socialHistoryInfection">
                                        </ng-select>
                                            <!-- <ng-select [clearable]="true" class="col-md-4" [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="patientReviewModel.socialHistoryInfection">
                                                <ng-option [value]="0">Gonorrhea</ng-option>
                                                <ng-option [value]="1">Hepatitis</ng-option>
                                                <ng-option [value]="2">HIV</ng-option>
                                                <ng-option [value]="3">Syphilis</ng-option>
                                            </ng-select> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label class="px-2 pt-1">Other:</label>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="form-row">
                                            <ng-select class="col-md-11 ng-select-style" [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'socialHistory_Other')"  addTagText="Add New" [addTag]="CreateNew"
                                                [items]="socialHistoryLookup"
                                                (keydown.tab)="saveDropdown(patientReviewModel.socialHistory_Other,'Other',socialHistoryLookup,'socialhistoryother',patientReviewModel,'socialHistory_Other')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="patientReviewModel.socialHistory_Other">
                                            </ng-select>
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(socialHistoryLookup,socialHistoryLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 class="text-center"><b>Review of Systems</b></h4>
                <div class="row">
                    <div class="col-md-12">
                        <label>Do you currently, or have you ever had any problems in the following areas:</label>
                    </div>
                    <div class="col-md-5">
                        <b class="boldtext">Constitutional</b>
                        <table class="" style="font-size: 14px !important; width:100%">
                            <tr>
                                <td width="50%">
                                    <span>Fever</span>
                                </td>
                                <td width="33%">
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'feverWeight')"
                                            [checked]="patientReviewModel.feverWeight == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.feverWeight == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'feverWeight')"
                                            [checked]="patientReviewModel.feverWeight == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'feverWeight')"
                                            [checked]="patientReviewModel.feverWeight == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Weight Loss/Gain</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'weightLoss')"
                                            [checked]="patientReviewModel.weightLoss == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.weightLoss == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'weightLoss')"
                                            [checked]="patientReviewModel.weightLoss == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'weightLoss')"
                                            [checked]="patientReviewModel.weightLoss == 3"
                                            class="mx-1"><span>?</span></label>


                                </td>
                            </tr>
                            <tr *ngFor="let Constitutional of ConstitutionalRoModel;let i = index">
                                <td>
                                    <div class="form-row">
                                        <span class="pt-1"><b>Other:</b></span>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Constitutional, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Constitutional.otherTxt,'Other',rosConstitutionalLookUp,'rosConstitutional',Constitutional,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosConstitutionalLookUp" bindLabel="name_txt"
                                                bindValue="name_txt" [(ngModel)]="Constitutional.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosConstitutionalLookUp,rosConstitutionalCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Constitutional,'otherHad')"
                                            [checked]="Constitutional.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Constitutional.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Constitutional,'otherHad')"
                                            [checked]="Constitutional.otherHad == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Constitutional,'otherHad')"
                                            [checked]="Constitutional.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(ConstitutionalRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td><button class="btn px-4 btn-primary"
                                        (click)="addRos('Constitutional',ConstitutionalRoModel)">Add</button></td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Integumentary(Skin)</b>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'integumetary')"
                                            [checked]="patientReviewModel.integumetary == 1" class="mx-1">
                                        <span
                                            [ngClass]="patientReviewModel.integumetary == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'integumetary')"
                                            [checked]="patientReviewModel.integumetary == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'integumetary')"
                                            [checked]="patientReviewModel.integumetary == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Integumentary of IntegumentaryRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <span class="pt-1"><b>Other:</b></span>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Integumentary, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Integumentary.otherTxt,'Other',rosSkinLookUp,'rosSkin',Integumentary,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosSkinLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Integumentary.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosSkinLookUp,rosSkinCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Integumentary,'otherHad')"
                                            [checked]="Integumentary.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Integumentary.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Integumentary,'otherHad')"
                                            [checked]="Integumentary.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Integumentary,'otherHad')"
                                            [checked]="Integumentary.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(IntegumentaryRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td><button class="btn px-4 btn-primary"
                                        (click)="addRos('Integumentary',IntegumentaryRoModel)">Add</button></td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Neurological</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Headaches</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'headaches')"
                                            [checked]="patientReviewModel.headaches == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.headaches == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'headaches')"
                                            [checked]="patientReviewModel.headaches == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'headaches')"
                                            [checked]="patientReviewModel.headaches == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Migraines</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'migraines')"
                                            [checked]="patientReviewModel.migraines == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.migraines == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'migraines')"
                                            [checked]="patientReviewModel.migraines == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'migraines')"
                                            [checked]="patientReviewModel.migraines == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Seizures</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'seizures')"
                                            [checked]="patientReviewModel.seizures == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.seizures == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'seizures')"
                                            [checked]="patientReviewModel.seizures == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'seizures')"
                                            [checked]="patientReviewModel.seizures == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Neurological of NeurologicalRoModel;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1">Other:</label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Neurological, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Neurological.otherTxt,'Other',rosNeurologicalLookUp,'rosNeurological',Neurological,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosNeurologicalLookUp" bindLabel="name_txt"
                                                bindValue="name_txt" [(ngModel)]="Neurological.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosNeurologicalLookUp,rosNeurologicalCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Neurological,'otherHad')"
                                            [checked]="Neurological.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Neurological.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Neurological,'otherHad')"
                                            [checked]="Neurological.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Neurological,'otherHad')"
                                            [checked]="Neurological.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(NeurologicalRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Neurological',NeurologicalRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Eyes</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Loss of Vision</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'lossOfVision')"
                                            [checked]="patientReviewModel.lossOfVision == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.lossOfVision == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'lossOfVision')"
                                            [checked]="patientReviewModel.lossOfVision == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'lossOfVision')"
                                            [checked]="patientReviewModel.lossOfVision == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Blurred Vision</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'blurredVision')"
                                            [checked]="patientReviewModel.blurredVision == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.blurredVision == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'blurredVision')"
                                            [checked]="patientReviewModel.blurredVision == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'blurredVision')"
                                            [checked]="patientReviewModel.blurredVision == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Distorted Vision/Halos</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'distortedVision')"
                                            [checked]="patientReviewModel.distortedVision == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.distortedVision == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'distortedVision')"
                                            [checked]="patientReviewModel.distortedVision == 2"
                                            class="mx-1"><span>No</span>
                                    </label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'distortedVision')"
                                            [checked]="patientReviewModel.distortedVision == 3"
                                            class="mx-1"><span>?</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Loss of Side Vision</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'lossOfSideVision')"
                                            [checked]="patientReviewModel.lossOfSideVision == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.lossOfSideVision == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'lossOfSideVision')"
                                            [checked]="patientReviewModel.lossOfSideVision == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'lossOfSideVision')"
                                            [checked]="patientReviewModel.lossOfSideVision == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Dryness</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'dryness')"
                                            [checked]="patientReviewModel.dryness == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.dryness == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'dryness')"
                                            [checked]="patientReviewModel.dryness == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'dryness')"
                                            [checked]="patientReviewModel.dryness == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Mucous Discharge</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'mucousDischarge')"
                                            [checked]="patientReviewModel.mucousDischarge == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.mucousDischarge == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'mucousDischarge')"
                                            [checked]="patientReviewModel.mucousDischarge == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'mucousDischarge')"
                                            [checked]="patientReviewModel.mucousDischarge == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Sandy or Gritty Feeling</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'sandyFeeling')"
                                            [checked]="patientReviewModel.sandyFeeling == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.sandyFeeling == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'sandyFeeling')"
                                            [checked]="patientReviewModel.sandyFeeling == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'sandyFeeling')"
                                            [checked]="patientReviewModel.sandyFeeling == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Foreign Body Sens.</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'foreignBodySens')"
                                            [checked]="patientReviewModel.foreignBodySens == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.foreignBodySens == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'foreignBodySens')"
                                            [checked]="patientReviewModel.foreignBodySens == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'foreignBodySens')"
                                            [checked]="patientReviewModel.foreignBodySens == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Light Sensitive</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'lightSensitive')"
                                            [checked]="patientReviewModel.lightSensitive == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.lightSensitive == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'lightSensitive')"
                                            [checked]="patientReviewModel.lightSensitive == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'lightSensitive')"
                                            [checked]="patientReviewModel.lightSensitive == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Chronic Eye/Lid Infection</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'chronicEyeInfection')"
                                            [checked]="patientReviewModel.chronicEyeInfection == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.chronicEyeInfection == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'chronicEyeInfection')"
                                            [checked]="patientReviewModel.chronicEyeInfection == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'chronicEyeInfection')"
                                            [checked]="patientReviewModel.chronicEyeInfection == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Styes or Chalazia</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'styesChalazia')"
                                            [checked]="patientReviewModel.styesChalazia == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.styesChalazia == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'styesChalazia')"
                                            [checked]="patientReviewModel.styesChalazia == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'styesChalazia')"
                                            [checked]="patientReviewModel.styesChalazia == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Flashes/Floaters</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'flashesFloaters')"
                                            [checked]="patientReviewModel.flashesFloaters == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.flashesFloaters == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'flashesFloaters')"
                                            [checked]="patientReviewModel.flashesFloaters == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'flashesFloaters')"
                                            [checked]="patientReviewModel.flashesFloaters == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Tired Eyes</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'tiredEye')"
                                            [checked]="patientReviewModel.tiredEye == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.tiredEye == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'tiredEye')"
                                            [checked]="patientReviewModel.tiredEye == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'tiredEye')"
                                            [checked]="patientReviewModel.tiredEye == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Itching</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'itching')"
                                            [checked]="patientReviewModel.itching == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.itching == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'itching')"
                                            [checked]="patientReviewModel.itching == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'itching')"
                                            [checked]="patientReviewModel.itching == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Excess Tearing/Watery</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'excessTearingWatery')"
                                            [checked]="patientReviewModel.excessTearingWatery == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.excessTearingWatery == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'excessTearingWatery')"
                                            [checked]="patientReviewModel.excessTearingWatery == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'excessTearingWatery')"
                                            [checked]="patientReviewModel.excessTearingWatery == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let eye of EyesRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, eye, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(eye.otherTxt,'Other',rosEyesLookUp,'rosEyes',eye,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosEyesLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="eye.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosEyesLookUp,rosEyesCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,eye,'otherHad')"
                                            [checked]="eye.otherHad == 1" class="mx-1"><span
                                            [ngClass]="eye.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,eye,'otherHad')"
                                            [checked]="eye.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,eye,'otherHad')"
                                            [checked]="eye.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="removeRos(EyesRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('eye',EyesRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td  class="boldtext">
                                    <b>Psychiatric</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Anxiety</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'anxiety')"
                                            [checked]="patientReviewModel.anxiety == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.anxiety == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'anxiety')"
                                            [checked]="patientReviewModel.anxiety == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'anxiety')"
                                            [checked]="patientReviewModel.anxiety == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>ADD</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'addPsychiatric')"
                                            [checked]="patientReviewModel.addPsychiatric == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.addPsychiatric == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'addPsychiatric')"
                                            [checked]="patientReviewModel.addPsychiatric == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'addPsychiatric')"
                                            [checked]="patientReviewModel.addPsychiatric == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>ADHD</span>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,patientReviewModel,'adhd')"
                                            [checked]="patientReviewModel.adhd == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.adhd == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,patientReviewModel,'adhd')"
                                            [checked]="patientReviewModel.adhd == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,patientReviewModel,'adhd')"
                                            [checked]="patientReviewModel.adhd == 3" class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Psychiatric of PsychiatricRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Psychiatric, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Psychiatric.otherTxt,'Other',rosPsychiatricLookUp,'rosPsychiatric',Psychiatric,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosPsychiatricLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Psychiatric.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosPsychiatricLookUp,rosPsychiatricCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Psychiatric,'otherHad')"
                                            [checked]="Psychiatric.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Psychiatric.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Psychiatric,'otherHad')"
                                            [checked]="Psychiatric.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Psychiatric,'otherHad')"
                                            [checked]="Psychiatric.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(PsychiatricRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Psychiatric',PsychiatricRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Allergic/Immunologic</b>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'immunologic')"
                                            [checked]="patientReviewModel.immunologic == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.immunologic == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'immunologic')"
                                            [checked]="patientReviewModel.immunologic == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'immunologic')"
                                            [checked]="patientReviewModel.immunologic == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Allergic of AllergicRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1">Other:</label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Allergic, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Allergic.otherTxt,'Other',rosAllergicLookUp,'rosAllergic',Allergic,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosAllergicLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Allergic.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosAllergicLookUp,rosAllergicCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Allergic,'otherHad')"
                                            [checked]="Allergic.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Allergic.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Allergic,'otherHad')"
                                            [checked]="Allergic.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Allergic,'otherHad')"
                                            [checked]="Allergic.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(AllergicRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Allergic',AllergicRoModel)">Add</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-5">
                        <b  class="boldtext">Ears,Nose,Mouth,Throat</b>
                        <table class="" style="font-size: 14px !important; width:100%">
                            <tr>
                                <td width="50%">
                                    <span>Allergies/Hay Fever</span>
                                </td>
                                <td width="33%">
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'allergiesHayFever')"
                                            [checked]="patientReviewModel.allergiesHayFever == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.allergiesHayFever == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'allergiesHayFever')"
                                            [checked]="patientReviewModel.allergiesHayFever == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'allergiesHayFever')"
                                            [checked]="patientReviewModel.allergiesHayFever == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Sinus Congestion</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'sinusCongestion')"
                                            [checked]="patientReviewModel.sinusCongestion == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.sinusCongestion == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'sinusCongestion')"
                                            [checked]="patientReviewModel.sinusCongestion == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'sinusCongestion')"
                                            [checked]="patientReviewModel.sinusCongestion == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Runny Nose</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'runnyNose')"
                                            [checked]="patientReviewModel.runnyNose == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.runnyNose == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'runnyNose')"
                                            [checked]="patientReviewModel.runnyNose == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'runnyNose')"
                                            [checked]="patientReviewModel.runnyNose == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Post-Nasal Drip</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'postNasalDrip')"
                                            [checked]="patientReviewModel.postNasalDrip == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.postNasalDrip == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'postNasalDrip')"
                                            [checked]="patientReviewModel.postNasalDrip == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'postNasalDrip')"
                                            [checked]="patientReviewModel.postNasalDrip == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Chronic Cough</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'chronicCough')"
                                            [checked]="patientReviewModel.chronicCough == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.chronicCough == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'chronicCough')"
                                            [checked]="patientReviewModel.chronicCough == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'chronicCough')"
                                            [checked]="patientReviewModel.chronicCough == 3" class="mx-1"><span
                                            [ngClass]="patientReviewModel.chronicCough == 3 ? 'backGround' : ''">?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Dry Throat/Mouth</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'dryMouth')"
                                            [checked]="patientReviewModel.dryMouth == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.dryMouth == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'dryMouth')"
                                            [checked]="patientReviewModel.dryMouth == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'dryMouth')"
                                            [checked]="patientReviewModel.dryMouth == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Ears of EarsRoModel;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><span><b>Other:</b></span></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Ears, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Ears.otherTxt,'Other',rosEarsNoseMouthThroatLookUp,'rosEarsNoseMouthThroat',Ears,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosEarsNoseMouthThroatLookUp" bindLabel="name_txt"
                                                bindValue="name_txt" [(ngModel)]="Ears.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosEarsNoseMouthThroatLookUp,rosEarsNoseMouthThroatCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Ears,'otherHad')"
                                            [checked]="Ears.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Ears.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Ears,'otherHad')"
                                            [checked]="Ears.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Ears,'otherHad')"
                                            [checked]="Ears.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="removeRos(EarsRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Ears',EarsRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td  class="boldtext">
                                    <b>Respiratory</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Asthma</span>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,patientReviewModel,'asthma')"
                                            [checked]="patientReviewModel.asthma == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.asthma == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,patientReviewModel,'asthma')"
                                            [checked]="patientReviewModel.asthma == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,patientReviewModel,'asthma')"
                                            [checked]="patientReviewModel.asthma == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Chronic Bronchitis</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'chronicBronchitis')"
                                            [checked]="patientReviewModel.chronicBronchitis == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.chronicBronchitis == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'chronicBronchitis')"
                                            [checked]="patientReviewModel.chronicBronchitis == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'chronicBronchitis')"
                                            [checked]="patientReviewModel.chronicBronchitis == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Emphysema</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'emphysema')"
                                            [checked]="patientReviewModel.emphysema == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.emphysema == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'emphysema')"
                                            [checked]="patientReviewModel.emphysema == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'emphysema')"
                                            [checked]="patientReviewModel.emphysema == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Respiratory of RespiratoryRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Respiratory, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Respiratory.otherTxt,'Other',rosRespiratoryLookUp,'rosRespiratory',Respiratory,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosRespiratoryLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Respiratory.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosRespiratoryLookUp,rosRespiratoryCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Respiratory,'otherHad')"
                                            [checked]="Respiratory.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Respiratory.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Respiratory,'otherHad')"
                                            [checked]="Respiratory.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Respiratory,'otherHad')"
                                            [checked]="Respiratory.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(RespiratoryRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Respiratory',RespiratoryRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext"><b>Vascular/Cardiovascular</b></td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Diabetes</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'diabetes')"
                                            [checked]="patientReviewModel.diabetes == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.diabetes == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'diabetes')"
                                            [checked]="patientReviewModel.diabetes == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'diabetes')"
                                            [checked]="patientReviewModel.diabetes == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Heart Disease</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'heartPain')"
                                            [checked]="patientReviewModel.heartPain == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.heartPain == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'heartPain')"
                                            [checked]="patientReviewModel.heartPain == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'heartPain')"
                                            [checked]="patientReviewModel.heartPain == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>High Blood Pressure</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'highBloodPressure')"
                                            [checked]="patientReviewModel.highBloodPressure == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.highBloodPressure == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'highBloodPressure')"
                                            [checked]="patientReviewModel.highBloodPressure == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'highBloodPressure')"
                                            [checked]="patientReviewModel.highBloodPressure == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>High Cholesterol</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'highCholesterol')"
                                            [checked]="patientReviewModel.highCholesterol == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.highCholesterol == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'highCholesterol')"
                                            [checked]="patientReviewModel.highCholesterol == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'highCholesterol')"
                                            [checked]="patientReviewModel.highCholesterol == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Vascular Disease</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'vascularDisease')"
                                            [checked]="patientReviewModel.vascularDisease == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.vascularDisease == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'vascularDisease')"
                                            [checked]="patientReviewModel.vascularDisease == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'vascularDisease')"
                                            [checked]="patientReviewModel.vascularDisease == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Vascular of VascularRoModel;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Vascular, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Vascular.otherTxt,'Other',rosCardiovascularLookUp,'rosCardiovascular',Vascular,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosCardiovascularLookUp" bindLabel="name_txt"
                                                bindValue="name_txt" [(ngModel)]="Vascular.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosCardiovascularLookUp,rosCardiovascularCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Vascular,'otherHad')"
                                            [checked]="Vascular.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Vascular.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Vascular,'otherHad')"
                                            [checked]="Vascular.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Vascular,'otherHad')"
                                            [checked]="Vascular.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(VascularRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Vascular',VascularRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td  class="boldtext">
                                    <b>Gastrointestinal</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Diarrhea</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'gastrointestinalDiarrhea')"
                                            [checked]="patientReviewModel.gastrointestinalDiarrhea == 1"
                                            class="mx-1"><span
                                            [ngClass]="patientReviewModel.gastrointestinalDiarrhea == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'gastrointestinalDiarrhea')"
                                            [checked]="patientReviewModel.gastrointestinalDiarrhea == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'gastrointestinalDiarrhea')"
                                            [checked]="patientReviewModel.gastrointestinalDiarrhea == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Constipation</span>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'constipation')"
                                            [checked]="patientReviewModel.constipation == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.constipation == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'constipation')"
                                            [checked]="patientReviewModel.constipation == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'constipation')"
                                            [checked]="patientReviewModel.constipation == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Gastrointestinal of GastrointestinalRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Gastrointestinal, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Gastrointestinal.otherTxt,'Other',rosGastrointestinalLookUp,'rosGastrointestinal',Gastrointestinal,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosGastrointestinalLookUp" bindLabel="name_txt"
                                                bindValue="name_txt" [(ngModel)]="Gastrointestinal.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosGastrointestinalLookUp,rosGastrointestinalCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Gastrointestinal,'otherHad')"
                                            [checked]="Gastrointestinal.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Gastrointestinal.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Gastrointestinal,'otherHad')"
                                            [checked]="Gastrointestinal.otherHad == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Gastrointestinal,'otherHad')"
                                            [checked]="Gastrointestinal.otherHad == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(GastrointestinalRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Gastrointestinal',GastrointestinalRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Genitourinary</b>
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'genitourinaryDiarrhea')"
                                            [checked]="patientReviewModel.genitourinaryDiarrhea == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.genitourinaryDiarrhea == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'genitourinaryDiarrhea')"
                                            [checked]="patientReviewModel.genitourinaryDiarrhea == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'genitourinaryDiarrhea')"
                                            [checked]="patientReviewModel.genitourinaryDiarrhea == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Genitourinary of GenitourinaryRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Genitourinary, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Genitourinary.otherTxt,'Other',rosGenitourinaryLookUp,'rosGenitourinary',Genitourinary,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosGenitourinaryLookUp" bindLabel="name_txt"
                                                bindValue="name_txt" [(ngModel)]="Genitourinary.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosGenitourinaryLookUp,rosGenitourinaryCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Genitourinary,'otherHad')"
                                            [checked]="Genitourinary.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Genitourinary.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Genitourinary,'otherHad')"
                                            [checked]="Genitourinary.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Genitourinary,'otherHad')"
                                            [checked]="Genitourinary.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(GenitourinaryRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Genitourinary',GenitourinaryRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Bones/Joints/Muscles</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Arthritis
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'rheumatoidArthritis')"
                                            [checked]="patientReviewModel.rheumatoidArthritis == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.rheumatoidArthritis == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'rheumatoidArthritis')"
                                            [checked]="patientReviewModel.rheumatoidArthritis == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'rheumatoidArthritis')"
                                            [checked]="patientReviewModel.rheumatoidArthritis == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Muscle Pain
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'musclePain')"
                                            [checked]="patientReviewModel.musclePain == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.musclePain == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'musclePain')"
                                            [checked]="patientReviewModel.musclePain == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'musclePain')"
                                            [checked]="patientReviewModel.musclePain == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Joint Pain
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'jointPain')"
                                            [checked]="patientReviewModel.jointPain == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.jointPain == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'jointPain')"
                                            [checked]="patientReviewModel.jointPain == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'jointPain')"
                                            [checked]="patientReviewModel.jointPain == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Bones of BonesRoModel;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Bones, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Bones.otherTxt,'Other',rosBonesLookUp,'rosBones',Bones,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosBonesLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Bones.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosBonesLookUp,rosBonesCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Bones,'otherHad')"
                                            [checked]="Bones.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Bones.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Bones,'otherHad')"
                                            [checked]="Bones.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Bones,'otherHad')"
                                            [checked]="Bones.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger" (click)="removeRos(BonesRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Bones',BonesRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Lymphatic/Hematologic</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Anemia
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,patientReviewModel,'anemia')"
                                            [checked]="patientReviewModel.anemia == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.anemia == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,patientReviewModel,'anemia')"
                                            [checked]="patientReviewModel.anemia == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,patientReviewModel,'anemia')"
                                            [checked]="patientReviewModel.anemia == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Lymphatic of LymphaticRoModel;let i = index;">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Lymphatic, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Lymphatic.otherTxt,'Other',rosLymphaticLookUp,'rosLymphatic',Lymphatic,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosLymphaticLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Lymphatic.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosLymphaticLookUp,rosLymphaticCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Lymphatic,'otherHad')"
                                            [checked]="Lymphatic.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Lymphatic.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Lymphatic,'otherHad')"
                                            [checked]="Lymphatic.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Lymphatic,'otherHad')"
                                            [checked]="Lymphatic.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(LymphaticRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Lymphatic',LymphaticRoModel)">Add</button>
                                </td>
                            </tr>
                            <tr>
                                <td class="boldtext">
                                    <b>Endocrine</b>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">
                                    Thyroid/Other Glands
                                </td>
                                <td>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(1,patientReviewModel,'thyroidOtherGlands')"
                                            [checked]="patientReviewModel.thyroidOtherGlands == 1" class="mx-1"><span
                                            [ngClass]="patientReviewModel.thyroidOtherGlands == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(2,patientReviewModel,'thyroidOtherGlands')"
                                            [checked]="patientReviewModel.thyroidOtherGlands == 2"
                                            class="mx-1"><span>No</span></label>
                                    <label><input type="radio"
                                            (change)="checkArrayRadio(3,patientReviewModel,'thyroidOtherGlands')"
                                            [checked]="patientReviewModel.thyroidOtherGlands == 3"
                                            class="mx-1"><span>?</span></label>
                                </td>
                            </tr>
                            <tr *ngFor="let Endocrine of EndocrineRoModel;let i = index">
                                <td>
                                    <div class="form-row">
                                        <label class="pt-1"><b>Other:</b></label>
                                        <div class="col-8">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event, Endocrine, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                                (keydown.tab)="saveDropdown(Endocrine.otherTxt,'Other',rosEndocrineLookUp,'rosEndocrine',Endocrine,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                                [items]="rosEndocrineLookUp" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="Endocrine.otherTxt">

                                            </ng-select>
                                        </div>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(rosEndocrineLookUp,rosEndocrineCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </td>
                                <td>
                                    <label><input type="radio" (change)="checkArrayRadio(1,Endocrine,'otherHad')"
                                            [checked]="Endocrine.otherHad == 1" class="mx-1"><span
                                            [ngClass]="Endocrine.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(2,Endocrine,'otherHad')"
                                            [checked]="Endocrine.otherHad == 2" class="mx-1"><span>No</span></label>
                                    <label><input type="radio" (change)="checkArrayRadio(3,Endocrine,'otherHad')"
                                            [checked]="Endocrine.otherHad == 3" class="mx-1"><span>?</span></label>
                                </td>
                                <td>
                                    <button class="btn btn-danger"
                                        (click)="removeRos(EndocrineRoModel,i)">Remove</button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button class="btn px-4 btn-primary"
                                        (click)="addRos('Endocrine',EndocrineRoModel)">Add</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <label style="font-weight:normal;">
                            If you answered Yes to any of the above or have a condition not listed, please explain:
                        </label>
                        <textarea rows="4" [(ngModel)]="patientReviewModel.explinationOfConditions" cols="70"
                            class="form-control"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <label style="font-weight:normal;">
                            If you answered question (?) to any of the above,please explain:
                        </label>
                        <textarea rows="4" [(ngModel)]="patientReviewModel.systemReviewQuestion" cols="70"
                            class="form-control"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>
                            I, the patient/guardian/responsible party, have accurately and truthfully completed the
                            information
                            listed on this form. I agree that all fees incurred are my responsibility regardless of
                            insurance
                            coverage.
                            <br>
                            I acknowledge that I have received a "Notice of Privacy Practices" regarding the use and
                            disclosure
                            of my health information(Form is available at front desk or printable from our website)
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>By clicking "Yes" below you will have electronically signed this form</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <label><input type="checkbox" [(ngModel)]="patientReviewModel.signed" class="mx-1">Yes</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col-md-4">
                                <label class="pt-1">Your Name:</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="patientReviewModel.signedName" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col-md-4">
                                <label class="pt-1">Date:</label>
                            </div>
                            <div class="col-md-8">
                                <input (ngModelChange)="patientReviewModel.signedDate = $event"
                                    [ngModel]="patientReviewModel.signedDate | date:'MM-dd-yyyy'" type="text"
                                    [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                    class="form-control ml-5 col-md-12">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-1">
                    <div class="col-md-2">
                        <label><input type="checkbox" #value  (ngModelChange)="getcheckbox_value($event)" [(ngModel)]="patientReviewModel.doctorReviewed"
                                class="mx-1">Doctor Reviewed</label>
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <div class="col-md-4">
                                <label class="pt-1">Date:</label>
                            </div>
                            <div class="col-md-8">
                                <input (ngModelChange)="patientReviewModel.doctorReviewedDate = $event"
                                    [ngModel]="patientReviewModel.doctorReviewedDate | date:'MM-dd-yyyy'" type="text"
                                    [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <ng-select [clearable]="true" placeholder=" --- Select ---"
                            [(ngModel)]="patientReviewModel.doctorID" #myUserSelect
                            (change)="onSelectUser(patientReviewModel.doctorID)">
                            <ng-option *ngFor="let userInfo of userList" [value]="userInfo.qeUserID">
                                {{userInfo.display_txt}}
                            </ng-option>
                        </ng-select>

                        <div class="col-md-12">
                            <img [src]="imgURL" height="120px" *ngIf="imgURL">
                        </div>


                    </div>
                </div>
            </div>
    </div>
    <hr>
    <br>
    <div id="content">

        <div class="titlebox"><b>Patient Review History</b></div>
        <br>
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" #examGrid
            style="height: 200px; width: 100%; background-color: yellow;" rowSelection='single' (rowDoubleClicked)="setdata($event)"
            class="ag-theme-alpine" [rowData]="rowPatientHistory" [columnDefs]="columnPatientHistory">
        </ag-grid-angular>

    </div>

    <div id="print-section" hidden class="">
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officename"> {{officename}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info">NPI # {{office_npi_txt}}</span>
        </div>

        <!-- <div class="col-12 text-center doc-info">
            <span class="doc-info">NPI # {{License}}</span>
        </div> -->
        <br>
        <h4 class="text-center"><b>Patient History</b></h4><br>
        <div class="row col-12">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0">
                        <label class="patient-info">Patient Name:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <div class="patient-reg-info">{{patientDetails.firstName}} {{patientDetails.lastName}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0">
                        <label class="patient-info">Birthdate:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <div class="patient-reg-info">{{patientDetails.dateOfBirth | date:'MM-dd-yyyy'}}</div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <div class="patient-reg-info">{{currentDate | date:'MM-dd-yyyy'}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date of Service:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <div class="patient-reg-info">{{doctoredate | date:'MM-dd-yyyy'}}</div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12" style="border: 1px solid black;"></div><br>
        <h4 class="text-center"><b>Vision and Medical History</b></h4><br>
        <div>
            <router-outlet  (activate)="onActivate($event)"></router-outlet>
            <div class="row">
              <div class="col-sm-2">
                    <label>Reason for today's exam?</label>
                </div>
                <div class="col-sm-8">
                    <input type="text" [(ngModel)]="patientReviewModel.reasonForVisit" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <label>Date of last eye exam?</label>
                </div>
                <div class="col-sm-3">
                    <input (ngModelChange)="patientReviewModel.dateOfLastExam = $event"
                        [ngModel]="patientReviewModel.dateOfLastExam | date:'MM-dd-yyyy'" type="text"
                        [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                </div>
                <div class="col-sm-1">
                    <label>By whom?</label>
                </div>
                <div class="col-sm-4">
                    <input type="text" [(ngModel)]="patientReviewModel.lastExaminer" class="form-control">
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-4">
                    <label>Do you presently wear?</label>
                </div>
                <div class="col-8">
                    <label>
                        <input type="radio" name="patientReviewModel.currentlyWear"
                            [(ngModel)]="patientReviewModel.currentlyWear"
                            [value]="1"
                            class="mx-2">
                        <span  [ngClass]="patientReviewModel.currentlyWear == 1 ? 'backGround' : ''">Glasses</span>
                    </label>
                    <label>
                        <input type="radio" name="patientReviewModel.currentlyWear"
                            [(ngModel)]="patientReviewModel.currentlyWear"
                            [value]="2"
                            class="mx-2">
                        <span [ngClass]="patientReviewModel.currentlyWear == 2 ? 'backGround' : ''">Contacts</span>
                    </label>
                    <label>
                        <input type="radio" name="patientReviewModel.currentlyWear"
                            [(ngModel)]="patientReviewModel.currentlyWear"
                            [value]="3"
                            class="mx-2">
                        <span [ngClass]="patientReviewModel.currentlyWear == 3 ? 'backGround' : ''">Both</span></label>
                    <label>
                        <input type="radio" name="patientReviewModel.currentlyWear"
                            [(ngModel)]="patientReviewModel.currentlyWear"
                            [value]="4"
                            class="mx-2">
                        <span [ngClass]="patientReviewModel.currentlyWear == 4 ? 'backGround' : ''">Neither</span></label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>If not currently wearing contacts, are you interested in trying them today?</label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'interestedInContacts')"
                            [checked]="patientReviewModel.interestedInContacts" class="mx-2"><span
                            [ngClass]="patientReviewModel.interestedInContacts == true ? 'backGround' : ''">Yes</span>
                    </label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'interestedInContacts')"
                            [checked]="!patientReviewModel.interestedInContacts" class="mx-2">No
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label>Are your contacts comfortable?</label>
                </div>
                <div class="col-7">
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'areContactsComfortable')"
                            [checked]="patientReviewModel.areContactsComfortable" class="mx-2"><span
                            [ngClass]="patientReviewModel.areContactsComfortable == true ? 'backGround' : ''">Yes</span></label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'areContactsComfortable')"
                            [checked]="patientReviewModel.areContactsComfortable == false" class="mx-2">No</label>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label>If you wear contact lenses, do you know what type or brand?</label>
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="patientReviewModel.contactBrand" class="form-control">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label>How old are your glasses?</label>
                </div>
                <div class="col-3">
                    <input class="form-control" [(ngModel)]="patientReviewModel.ageOfGlasses" type="text">
                </div>
                <div class="col-3">
                    <label>How old are your contacts</label>
                </div>
                <div class="col-3">
                    <input class="form-control" [(ngModel)]="patientReviewModel.ageOfContacts" type="text">
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label>Name of your medical doctor:</label>
                </div>
                <div class="col-3">
                    <input class="form-control" [(ngModel)]="patientReviewModel.medicalDoctorName" type="text">
                </div>
                <div class="col-3">
                    <label>Dr's Phone:</label>
                </div>
                <div class="col-3">
                    <input class="form-control" phone minlength=14 maxlength=14
                        [(ngModel)]="patientReviewModel.medicalDoctorPhone" type="text">
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-5">
                    <label>Are you currently taking any medications?</label>
                </div>
                <div class="col-7">
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'currentlyTakingMedications')"
                            [checked]="patientReviewModel.currentlyTakingMedications" class="mx-2"><span
                            [ngClass]="patientReviewModel.currentlyTakingMedications == true ? 'backGround' : ''">Yes</span></label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'currentlyTakingMedications')"
                            [checked]="!patientReviewModel.currentlyTakingMedications" class="mx-2">No</label>
                </div>
                <div class="col-12">
                    <label>(including oral contraceptives,aspirin,otc and/or herbal meds)<br>If so please
                        list:</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table-bordered" style="font-size: 14px !important; width:100%">
                        <tr>
                            <th>Brand Name:</th>
                            <th>Generic Name:</th>
                            <th>Strength:</th>
                            <th>Dose:</th>
                            <th>Route:</th>
                            <th>Frequency:</th>
                            <th>#:</th>
                            <th>Refill:</th>
                            <th>Started Date:</th>
                            <th>Status</th>
                            <!-- <th></th> -->
                        </tr>
                        <tr *ngFor="let item of medicationList;let i = index">
                            <td>
                                <div class="col-12 px-1">
                                    {{item.brand_name_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.generic_name_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.strength_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.dose_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.route_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.frequency_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.detail_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.refill_times_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.date_started_dt | date:'MM-dd-yyyy'}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.status_txt}}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Do you have allergies to any medications?</label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'allergicToMedications')"
                            [checked]="patientReviewModel.allergicToMedications" class="mx-2"><span
                            [ngClass]="patientReviewModel.allergicToMedications == true ? 'backGround' : ''">Yes</span></label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'allergicToMedications')"
                            [checked]="!patientReviewModel.allergicToMedications" class="mx-2">No</label><br>
                    <label>If so please list:</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table-bordered" style="font-size: 14px !important; width:100%">
                        <tr>
                            <th>Allergy-Medication Name:</th>
                            <th>Reaction Details:</th>
                            <th>Type:</th>
                            <!-- <th></th> -->
                        </tr>
                        <tr *ngFor="let item of allergyList;let i = index">
                            <td>
                                <div class="col-12 px-1">
                                    {{item.name_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.reaction_detail_txt}}
                                </div>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{item.type_txt}}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.medReconciliation"
                            class="mx-2">Reconciliation
                        of Medication Completed</label>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <label>List all surgeries and/or hospitalizations you had:</label>
                    <textarea class="form-control" [(ngModel)]="patientReviewModel.surgeriesAndHospitalizations"
                        rows="4" cols="20"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>Are you pregnant or nursing?</label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'isPregnantOrNursing')"
                            [checked]="patientReviewModel.isPregnantOrNursing" class="mx-2"><span
                            [ngClass]="patientReviewModel.isPregnantOrNursing == true? 'backGround' : ''">Yes</span></label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'isPregnantOrNursing')"
                            [checked]="!patientReviewModel.isPregnantOrNursing" class="mx-2">No</label>
                </div>
            </div>
            <div class="row pb-2">
                <div class="col-4">
                    <label>Please List: Your Height in inches:</label>
                </div>
                <div class="col-2">
                    <input class="form-control" type="text" [(ngModel)]="patientReviewModel.height">
                </div>
                <div class="col-4">
                    <label>Please List: Your Weight in lbs:</label>
                </div>
                <div class="col-2">
                    <input class="form-control" type="text" [(ngModel)]="patientReviewModel.weight">
                </div>
            </div>
            <h4 class="text-center"><b>Ocular History</b></h4>
            <div class="row">
                <div class="col-12">
                    <label class="">Check any of following that you have had:</label>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadCrossedEyes"
                            class="mx-2">Crossed
                        Eyes</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadRetinalDisease"
                            class="mx-2">Retinal Disease</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyePain" class="mx-2">Eye
                        Pain</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadFloaters"
                            class="mx-2">Floaters</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadLazyEye" class="mx-2">Lazy
                        Eyes</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadCataracts"
                            class="mx-2">Cataracts</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadItching"
                            class="mx-2">Itching</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadFlashes"
                            class="mx-2">Flashes</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadGlaucoma"
                            class="mx-2">Glaucoma</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadDroopingEyelid"
                            class="mx-2">Drooping
                        Eyelid</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyeInfections" class="mx-2">Eye
                        Infections</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadTearing"
                            class="mx-2">Tearing</label><br>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadHalos" class="mx-2">Halos
                        around
                        lights</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadProminentEyes"
                            class="mx-2">Prominent
                        Eyes</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadEyeInjury" class="mx-2">Eye
                        Injury</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadBurning"
                            class="mx-2">Burning</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadDoubleVision"
                            class="mx-2">Double
                        Vision</label>
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.hadRedness"
                            class="mx-2">Redness</label>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label>Are you interested in laser vision correction?</label>
                </div>
                <div class="col-6">
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'interestedInLaserVision')"
                            [checked]="patientReviewModel.interestedInLaserVision" class="mx-2"><span
                            [ngClass]="patientReviewModel.interestedInLaserVision == true ? 'backGround' : ''">Yes</span></label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'interestedInLaserVision')"
                            [checked]="!patientReviewModel.interestedInLaserVision"
                            class="mx-2"><span>No</span></label><br>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label>Are you interested in hearing about treatments for dry eyes?</label>
                </div>
                <div class="col-6">
                    <label><input type="radio"
                            (change)="checkArrayRadio(true,patientReviewModel,'interestedInDryEyeTreatment')"
                            [checked]="patientReviewModel.interestedInDryEyeTreatment" class="mx-2"><span
                            [ngClass]="patientReviewModel.interestedInDryEyeTreatment == true ? 'backGround' : ''">Yes</span></label>
                    <label><input type="radio"
                            (change)="checkArrayRadio(false,patientReviewModel,'interestedInDryEyeTreatment')"
                            [checked]="!patientReviewModel.interestedInDryEyeTreatment"
                            class="mx-2"><span>No</span></label>
                </div>
            </div>
            <h4 class="text-center"><b>Family Medical History</b></h4>
            <div class="row">
                <div class="col-12">
                    <label>Please Note any family history (Parents,Grandparents,Children,Siblings,Living or
                        Deceased)for
                        the
                        following:</label><br>
                    <table class="table-bordered" style="font-size: 14px !important; width:100%">
                        <tr>
                            <th>DISEASE/CONDITION</th>
                            <th>Yes / No / ?</th>
                            <th>RELATIONSHIP TO YOU</th>
                            <!-- <th></th> -->
                        </tr>
                        <tr>
                            <th>Blindness (275118009)<br><a  href="javascript:void(0)" (click)="Add_Blindness()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'blindnessInFamily')"
                                        [checked]="patientReviewModel.blindnessInFamily == 1" class="mx-2"><span
                                        [ngClass]="patientReviewModel.blindnessInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'blindnessInFamily')"
                                        [checked]="patientReviewModel.blindnessInFamily == 2"
                                        class="mx-2"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'blindnessInFamily')"
                                        [checked]="patientReviewModel.blindnessInFamily == 3"
                                        class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.blindnessRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let Blindness of Blindness_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2">Blindness</label>

                                </div>
                            </td>
                            <td>
                                <label>
                                    <input type="radio"  name="Blindness_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Blindness_array[i].condition_yes_no"
                                    [value]="true"
                                    (click)="checkArrayRadio(true,Blindness_array[i],'condition_yes_no',i)"
                                    class="mx-2">
                                    <span (click)="checkArrayRadio(true,Blindness_array[i],'condition_yes_no',i)" [ngClass]="Blindness_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                </label>
                                <label>
                                    <input type="radio" name="Blindness_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Blindness_array[i].condition_yes_no"
                                        [value]="false"
                                        (click)="checkArrayRadio(false,Blindness_array[i],'condition_yes_no',i)"
                                        class="mx-2">
                                    <span>No</span>
                                </label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{Blindness.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Cataract (193570009)<br><a href="javascript:void(0)" (click)="Add_Cataract()">Add>></a></th>
                            <td>
                                <label>
                                    <input type="radio"
                                        [(ngModel)]="patientReviewModel.cataractInFamily"
                                        [value]="1"
                                        class="mx-2">
                                    <span
                                        [ngClass]="patientReviewModel.cataractInFamily == 1 ? 'backGround' : ''">Yes</span>
                                </label>
                                <label>
                                    <input type="radio"
                                    [(ngModel)]="patientReviewModel.cataractInFamily"
                                    [value]="2"
                                    class="mx-2">
                                    <span>No</span>
                                </label>
                                <label>
                                    <input type="radio"
                                    [(ngModel)]="patientReviewModel.cataractInFamily"
                                    [value]="3"
                                    class="mx-2">
                                    <span>?</span>
                                </label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.cataractRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let Cataract of Cataract_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Cataract"  [(ngModel)]="Cataract.condition_txt">Cataract</label>
                                </div>
                            </td>
                            <td>
                                <label>
                                    <input type="radio"  name="Cataract_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Cataract_array[i].condition_yes_no"
                                    [value]="true"
                                    (click)="checkArrayRadio(true,Cataract_array[i],'condition_yes_no',i)"
                                    class="mx-2">
                                    <span (click)="checkArrayRadio(true,Cataract_array[i],'condition_yes_no',i)" [ngClass]="Cataract_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                </label>
                                <label>
                                    <input type="radio" name="Cataract_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Cataract_array[i].condition_yes_no"
                                    [value]="false"
                                    (click)="checkArrayRadio(false,Cataract_array[i],'condition_yes_no',i)"
                                    class="mx-2">
                                    <span>No</span>
                                </label>

                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{Cataract.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th>Glaucoma (23986001)<br><a href="javascript:void(0)" (click)="Add_Glaucoma()">Add>></a></th>
                            <td>
                                <label>
                                    <input type="radio"
                                        [(ngModel)]="patientReviewModel.glaucomaInFamily"
                                        [value]="1" class="mx-2"><span
                                        [ngClass]="patientReviewModel.glaucomaInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.glaucomaInFamily"
                                        [value]="2"
                                        class="mx-2"><span>No</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.glaucomaInFamily"
                                        [value]="3"
                                        class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.glaucomaRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let Glaucoma of Glaucoma_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Glaucoma"  [(ngModel)]="Glaucoma.condition_txt">Glaucoma</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="Glaucoma_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Glaucoma_array[i].condition_yes_no"
                                    [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="Glaucoma_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                <label><input type="radio" name="Glaucoma_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Glaucoma_array[i].condition_yes_no"
                                    [value]="false"
                                    class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{Glaucoma.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Lazy Eyes (246623008)<br><a href="javascript:void(0)" (click)="Add_LazyEyes()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.lazyCrossedEyesInFamily"
                                    [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.lazyCrossedEyesInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.lazyCrossedEyesInFamily"
                                        [value]="2"
                                        class="mx-2"><span>No</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.lazyCrossedEyesInFamily"
                                        [value]="3"
                                        class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.lazyCrossedEyesRelation}}
                            </div>

                            </td>
                        </tr>

                        <tr *ngFor="let LazyEyes of LazyEyes_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="LazyEyes"  [(ngModel)]="LazyEyes.condition_txt">Lazy Eyes</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="LazyEyes_array{{i}}.condition_yes_no "
                                    [(ngModel)]="LazyEyes_array[i].condition_yes_no"
                                    [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="LazyEyes_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="LazyEyes_array{{i}}.condition_yes_no "
                                        [(ngModel)]="LazyEyes_array[i].condition_yes_no"
                                        [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{LazyEyes.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Macular Degeneration (26771800)<br><a href="javascript:void(0)" (click)="Add_MacularDegeneration()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.macularDegenerationInFamily"
                                    [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.macularDegenerationInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.macularDegenerationInFamily"
                                    [value]="2"
                                    class="mx-2"><span>No</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.macularDegenerationInFamily"
                                    [value]="3"
                                    class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.macularDegenerationRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let MacularDegeneration of MacularDegeneration_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="MacularDegeneration"  [(ngModel)]="MacularDegeneration.condition_txt">Macular Degeneration</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="MacularDegeneration_array{{i}}.condition_yes_no"
                                    [(ngModel)]="MacularDegeneration_array[i].condition_yes_no"
                                    [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="MacularDegeneration_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="MacularDegeneration_array{{i}}.condition_yes_no"
                                        [(ngModel)]="MacularDegeneration_array[i].condition_yes_no"
                                        [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{MacularDegeneration.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Retinal Detachment (42059000)<br><a href="javascript:void(0)" (click)="Add_RetinalDetachment()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.retinalDetachmentInFamily"
                                    [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.retinalDetachmentInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.retinalDetachmentInFamily"
                                    [value]="2"
                                    class="mx-2"><span>No</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.retinalDetachmentInFamily"
                                    [value]="3"
                                    class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.retinalDetachmentRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let RetinalDetachment of RetinalDetachment_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="RetinalDetachment"  [(ngModel)]="RetinalDetachment.condition_txt">Retinal Detachment</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="RetinalDetachment_array{{i}}.condition_yes_no"
                                    [(ngModel)]="RetinalDetachment_array[i].condition_yes_no"
                                    [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="RetinalDetachment_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="RetinalDetachment_array{{i}}.condition_yes_no"
                                        [(ngModel)]="RetinalDetachment_array[i].condition_yes_no"
                                        [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{RetinalDetachment.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th>Retinal Disease (29555009)<br><a href="javascript:void(0)" (click)="Add_RetinalDisease()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.retinalDiseaseInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.retinalDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.retinalDiseaseInFamily" [value]="2"
                                    class="mx-2"><span>No</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.retinalDiseaseInFamily" [value]="3"
                                    class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.retinalDiseaseRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let RetinalDisease of RetinalDisease_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="RetinalDisease"  [(ngModel)]="RetinalDisease.condition_txt">Retinal Disease</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="RetinalDisease_array{{i}}.condition_yes_no"
                                    [(ngModel)]="RetinalDisease_array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="RetinalDisease_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="RetinalDisease_array{{i}}.condition_yes_no"
                                        [(ngModel)]="RetinalDisease_array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{RetinalDisease.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th style="font-weight: bold !important">Arthritis (3723001)<br><a href="javascript:void(0)" (click)="Add_Arthrits()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.arthritisInFamily" [value]="1"
                                    class="mx-2">
                                    <span
                                    [ngClass]="patientReviewModel.arthritisInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.arthritisInFamily" [value]="2"
                                    class="mx-2"><span>No</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.arthritisInFamily" [value]="3"
                                    class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.arthritisRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let Arthrits of Arthrits_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Arthrits"  [(ngModel)]="Arthrits.condition_txt">Arthritis</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="Arthrits_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Arthrits_array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="Arthrits_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="Arthrits_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Arthrits_array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{Arthrits.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Cancer (275937001)<br><a href="javascript:void(0)" (click)="Add_Cancer()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.cancerInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.cancerInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.cancerInFamily" [value]="2"
                                    class="mx-2"><span>No</span></label>
                            <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.cancerInFamily" [value]="3"
                                    class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{patientReviewModel.cancerRelation}}
                                </div>
                            </td>
                        </tr>
                        <tr *ngFor="let Cancer of Cancer_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Cancer"  [(ngModel)]="Cancer.condition_txt">Cancer</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="Cancer_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Cancer_array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="Cancer_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="Cancer_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Cancer_array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{Cancer.relationship_to_patient_txt}}
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th>Diabetes (466635009)<br><a href="javascript:void(0)" (click)="Add_Diabetes()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.diabetesInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.diabetesInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.diabetesInFamily" [value]="2"
                                        class="mx-2"><span>No</span>
                                </label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.diabetesInFamily" [value]="3"
                                        class="mx-2"><span>?</span>
                                </label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.diabetesRelation}}
                            </div>
                            </td>
                        </tr>
                        <tr *ngFor="let Diabetes of Diabetes_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Diabetes"  [(ngModel)]="Diabetes.condition_txt">Diabetes</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="Diabetes_array{{i}}.condition_yes_no"
                                    [(ngModel)]="Diabetes_array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="Diabetes_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="Diabetes_array{{i}}.condition_yes_no"
                                        [(ngModel)]="Diabetes_array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{Diabetes.relationship_to_patient_txt}}
                            </div>
                            </td>
                        </tr>

                        <tr>
                            <th>Heart Disease (266894000)<br><a href="javascript:void(0)" (click)="Add_HeartDisease()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.heartDiseaseInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.heartDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.heartDiseaseInFamily" [value]="2"
                                        class="mx-2"><span>No</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.heartDiseaseInFamily" [value]="3"
                                        class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.heartDiseaseRelation}}
                            </div>
                            </td>
                        </tr>
                        <tr *ngFor="let  HeartDisease of HeartDisease_array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="HeartDisease"  [(ngModel)]="HeartDisease.condition_txt">Heart Disease</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="HeartDisease_array{{i}}.condition_yes_no"
                                    [(ngModel)]="HeartDisease_array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="HeartDisease_array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="HeartDisease_array{{i}}.condition_yes_no"
                                        [(ngModel)]="HeartDisease_array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{HeartDisease.relationship_to_patient_txt}}
                            </div>
                            </td>
                        </tr>

                        <tr>
                            <th>High Blood Pressure (38936003)<br><a href="javascript:void(0)" (click)="Add_HighBlood_Pressure()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.highBloodPressureInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.highBloodPressureInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.highBloodPressureInFamily" [value]="2"
                                        class="mx-2"><span>No</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.highBloodPressureInFamily" [value]="3"
                                        class="mx-2"><Span>?</Span>
                                </label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.highBloodPressureRelation}}
                            </div>
                            </td>
                        </tr>
                        <tr *ngFor="let HighBlood_Pressure of HighBlood_Pressure_Array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="HighBlood_Pressure"  [(ngModel)]="HighBlood_Pressure.condition_txt">High Blood Pressure</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="HighBlood_Pressure_Array{{i}}.condition_yes_no"
                                    [(ngModel)]="HighBlood_Pressure_Array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="HighBlood_Pressure_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span>
                                </label>
                                <label><input type="radio" name="HighBlood_Pressure_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="HighBlood_Pressure_Array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span>
                                </label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{HighBlood_Pressure.relationship_to_patient_txt}}
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Kidney Disease (433146000)<br><a href="javascript:void(0)" (click)="Add_Kidney_Pressure()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.kidneyDiseaseInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.kidneyDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.kidneyDiseaseInFamily" [value]="2"
                                        class="mx-2"><span>No</span></label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.kidneyDiseaseInFamily" [value]="3"
                                        class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.kidneyDiseaseRelation}}
                            </div>
                            </td>
                        </tr>

                        <tr *ngFor="let Kidney of Kidney_Pressure_Array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Kidney"  [(ngModel)]="Kidney.condition_txt">Kidney Disease </label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="Kidney_Pressure_Array{{i}}.condition_yes_no"
                                    [(ngModel)]="Kidney_Pressure_Array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="Kidney_Pressure_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="Kidney_Pressure_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="Kidney_Pressure_Array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{Kidney.relationship_to_patient_txt}}
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <th>Lupus (55464009)<br><a href="javascript:void(0)" (click)="Add_Lapus()">Add>></a></th>
                            <td>
                                <label><input type="radio"
                                    [(ngModel)]="patientReviewModel.lupusInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.lupusInFamily == 1 ? 'backGround' : ''">Yes</span>
                                </label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.lupusInFamily" [value]="2"
                                        class="mx-2"><span>No</span>
                                </label>
                                <label><input type="radio"
                                        [(ngModel)]="patientReviewModel.lupusInFamily" [value]="3"
                                        class="mx-2"><span>?</span>
                                </label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.lupusRelation}}
                            </div>
                            </td>
                        </tr>

                        <tr *ngFor="let Lupus of Lupus_Array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="Lupus"  [(ngModel)]="Lupus.condition_txt">Lupus</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="Lupus_Array{{i}}.condition_yes_no"
                                    [(ngModel)]="Lupus_Array[i].condition_yes_no" [value]="true"
                                    class="mx-2"><span
                                    [ngClass]="Lupus_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="Lupus_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="Lupus_Array[i].condition_yes_no" [value]="false"
                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{Lupus.relationship_to_patient_txt}}
                            </div>
                            </td>
                        </tr>

                        <tr>
                            <th>Thyroid Disease (160302006)<br><a href="javascript:void(0)" (click)="Add_ThyroidDisease()">Add>></a> </th>
                            <td>
                                <label><input type="radio"

                                    [(ngModel)]="patientReviewModel.thyroidDiseaseInFamily" [value]="1"
                                    class="mx-2"><span
                                    [ngClass]="patientReviewModel.thyroidDiseaseInFamily == 1 ? 'backGround' : ''">Yes</span></label>
                            <label><input type="radio"

                                    [(ngModel)]="patientReviewModel.thyroidDiseaseInFamily" [value]="2"
                                    class="mx-2"><span>No</span></label>
                            <label><input type="radio"

                                    [(ngModel)]="patientReviewModel.thyroidDiseaseInFamily" [value]="3"
                                    class="mx-2"><span>?</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{patientReviewModel.thyroidDiseaseRelation}}
                            </div>
                            </td>
                        </tr>
                        <tr *ngFor="let ThyroidDisease of ThyroidDisease_Array;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1 px-2" value="ThyroidDisease"  [(ngModel)]="ThyroidDisease.condition_txt">Thyroid Disease</label>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio"  name="ThyroidDisease_Array{{i}}.condition_yes_no"
                                    [(ngModel)]="ThyroidDisease_Array[i].condition_yes_no" [value]="true"

                                    class="mx-2"><span
                                    [ngClass]="ThyroidDisease_Array[i].condition_yes_no == true ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="ThyroidDisease_Array{{i}}.condition_yes_no"
                                        [(ngModel)]="ThyroidDisease_Array[i].condition_yes_no" [value]="false"

                                        class="mx-2"><span>No</span></label>
                            </td>
                            <td>
                            <div class="col-12 px-1">
                                {{ThyroidDisease.relationship_to_patient_txt}}
                            </div>
                            </td>
                        </tr>


                        <tr *ngFor="let familyHistory of patientReviewModel.familyHistories;let i = index">
                            <td>
                                <div class="form-row">
                                    <div class="col-2">
                                        <label class="pt-1 px-2">Other</label>
                                    </div>
                                    <div class="col-10">
                                        <ng-select class="col-11 ng-select-style" [clearable]="true" (keyup)="keyFunc($event, patientReviewModel.familyHistories[i], 'familyhistory_other_txt')"  addTagText="Add New" [addTag]="CreateNew"
                                        [items]="familyHistoryLookup"
                                        (keydown.tab)="saveDropdown(patientReviewModel.familyHistories[i].familyhistory_other_txt,'Other',familyHistoryLookup,'familyhistoryother',patientReviewModel.familyHistories[i],'familyhistory_other_txt')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="patientReviewModel.familyHistories[i].familyhistory_other_txt">
                                    </ng-select>
                                    </div>

                                </div>

                            </td>
                            <td>

                                        <label><input type="radio" name="patientReviewModel.familyHistories{{i}}"
                                            [(ngModel)]="patientReviewModel.familyHistories[i].otherInFamily" [value]="1"

                                            class="mx-2"><span
                                            [ngClass]="patientReviewModel.familyHistories[i].otherInFamily == 1 ? 'backGround' : ''" >Yes</span></label>
                                    <label><input type="radio" name="patientReviewModel.familyHistories{{i}}"
                                            [(ngModel)]="patientReviewModel.familyHistories[i].otherInFamily" [value]="2"

                                            class="mx-2"><span>No</span></label>
                                    <label><input type="radio" name="patientReviewModel.familyHistories{{i}}"
                                            [(ngModel)]="patientReviewModel.familyHistories[i].otherInFamily" [value]="3"

                                            class="mx-2"><span>?</span></label>
                            </td>
                            <td>

                            <div class="col-12 px-1">
                                {{patientReviewModel.familyHistories[i].otherRelation}}
                            </div>
                            </td>

                        </tr>
                    </table>

                </div>
            </div>
            <h4 class="text-center"><b>Immunization History</b></h4>
            <div class="row">
                <div class="col-12">
                    <label>Please Note History for the following:</label>
                    <br>
                    <table class="table-bordered" style="font-size: 14px !important; width:100%">
                        <tr>
                            <th>Vaccine Name:</th>
                            <th>Date:</th>
                            <!-- <th></th> -->
                        </tr>
                        <tr *ngFor="let Immunization of immunizationList;let i = index;">

                            <td>
                                <div class="col-12 px-1">
                                    {{Immunization.vaccine_name_txt}}
                                </div>

                            </td>
                            <td>
                                <div class="col-12 px-1">
                                    {{Immunization.immunized_dt | date:'MM-dd-yyyy'}}
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <h4 class="text-center"><b>Social History</b></h4>
            <div class="row">
                <div class="col-12">
                    <label>(This is information is kept strictly confidential. However, you may discuss this portion
                        directly
                        with the doctor if you prefer.)</label><br>
                    <label><input [(ngModel)]="patientReviewModel.preferDiscussWithDoctor" type="checkbox"
                            class="mx-2">Yes, I would prefer to discuss my Social History with the doctor
                    </label><br>
                    <div class="form-row">
                        <div class="col-2">
                            <label class="pt-1 px-2">Smoking Status:</label>
                        </div>
                        <div class="col-10">
                            <ng-select [clearable]="true"  addTagText="Add New" [addTag]="CreateNew"  [items]="smokingStatusDropDown"
                                [(ngModel)]="patientReviewModel.smokeTypeAmount" bindLabel="name_txt"
                                bindValue="name_txt">
                            </ng-select>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-4">
                            <label class="pt-1">Do you use tobacco products?</label>
                        </div>
                        <div class="col-8">
                            <div class="form-row">
                                <label class="mx-2 pt-1"><input type="radio"
                                        (change)="checkArrayRadio(true,patientReviewModel,'usesTobacco')"
                                        [checked]="patientReviewModel.usesTobacco" class="mx-2"><span
                                        [ngClass]="patientReviewModel.usesTobacco == true ? 'backGround' : ''">Yes</span></label>
                                <label class="mx-2 pt-1"><input type="radio"
                                        (change)="checkArrayRadio(false,patientReviewModel,'usesTobacco')"
                                        [checked]="!patientReviewModel.usesTobacco"
                                        class="mx-2"><span>No</span></label>
                                <label class="mx-2 pt-1">If yes,type/amount/how long:</label>
                                <input class="form-control col-4"
                                    [(ngModel)]="patientReviewModel.tobaccoTypeAmount" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label class="pt-1">Do you use illegal drugs?</label>
                        </div>
                        <div class="col-8">
                            <div class="form-row">
                                <label class="mx-2 pt-1"><input type="radio"
                                        (change)="checkArrayRadio(true,patientReviewModel,'usesIllegalDrugs')"
                                        [checked]="patientReviewModel.usesIllegalDrugs" class="mx-2"><span
                                        [ngClass]="patientReviewModel.usesIllegalDrugs == true ? 'backGround' : ''">Yes</span>
                                </label>
                                <label class="mx-2 pt-1"><input type="radio"
                                        (change)="checkArrayRadio(false,patientReviewModel,'usesIllegalDrugs')"
                                        [checked]="!patientReviewModel.usesIllegalDrugs"
                                        class="mx-2"><span>No</span>
                                </label>
                                <label class="mx-2 pt-1">If yes,type/amount/how long:</label>
                                <input class="form-control col-4"
                                    [(ngModel)]="patientReviewModel.illegalDrugsTypeAmount" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-6">
                                    <label class="pt-1">Have you ever been exposed to or infected with:</label>
                                </div>
                                <div class="col-6">
                                    <div class="form-row">
                                        <ng-select [clearable]="true" class="col-4"
                                        [items]="socialHistoryInfectionLookup" bindLabel="key"
                                        bindValue="value" [(ngModel)]="patientReviewModel.socialHistoryInfection">
                                    </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <label class="px-2 pt-1">Other:</label>
                                </div>
                                <div class="col-10">
                                    <div class="form-row">
                                        <ng-select class="col-11 ng-select-style" [clearable]="true" (keyup)="keyFunc($event, patientReviewModel, 'socialHistory_Other')"  addTagText="Add New" [addTag]="CreateNew"
                                            [items]="socialHistoryLookup"
                                            (keydown.tab)="saveDropdown(patientReviewModel.socialHistory_Other,'Other',socialHistoryLookup,'socialhistoryother',patientReviewModel,'socialHistory_Other')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="patientReviewModel.socialHistory_Other">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 class="text-center"><b>Review of Systems</b></h4>
            <div class="row">
                <div class="col-12">
                    <label>Do you currently, or have you ever had any problems in the following areas:</label>
                </div>
                <div class="col-6">
                    <b class="boldtext">Constitutional</b>
                    <table class="" style="font-size: 14px !important; width:100%">
                        <tr>
                            <td width="50%">
                                <span>Fever</span>
                            </td>
                            <td width="33%">
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'feverWeight')"
                                        [checked]="patientReviewModel.feverWeight == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.feverWeight == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'feverWeight')"
                                        [checked]="patientReviewModel.feverWeight == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'feverWeight')"
                                        [checked]="patientReviewModel.feverWeight == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td></td> -->
                        </tr>
                        <tr>
                            <td>
                                <span>Weight Loss/Gain</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'weightLoss')"
                                        [checked]="patientReviewModel.weightLoss == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.weightLoss == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'weightLoss')"
                                        [checked]="patientReviewModel.weightLoss == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'weightLoss')"
                                        [checked]="patientReviewModel.weightLoss == 3"
                                        class="mx-1"><span>?</span></label>


                            </td>
                        </tr>
                        <tr *ngFor="let Constitutional of ConstitutionalRoModel;let i = index">
                            <td>
                                <div class="form-row">
                                    <span class="pt-1"><b>Other:</b></span>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Constitutional, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Constitutional.otherTxt,'Other',rosConstitutionalLookUp,'rosConstitutional',Constitutional,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosConstitutionalLookUp" bindLabel="name_txt"
                                            bindValue="name_txt" [(ngModel)]="Constitutional.otherTxt">

                                        </ng-select>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Constitutional,'otherHad')"
                                        [checked]="Constitutional.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Constitutional.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Constitutional,'otherHad')"
                                        [checked]="Constitutional.otherHad == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Constitutional,'otherHad')"
                                        [checked]="Constitutional.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td class="boldtext">
                                <b>Integumentary(Skin)</b>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'integumetary')"
                                        [checked]="patientReviewModel.integumetary == 1" class="mx-1">
                                    <span
                                        [ngClass]="patientReviewModel.integumetary == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'integumetary')"
                                        [checked]="patientReviewModel.integumetary == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'integumetary')"
                                        [checked]="patientReviewModel.integumetary == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Integumentary of IntegumentaryRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <span class="pt-1"><b>Other:</b></span>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Integumentary, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Integumentary.otherTxt,'Other',rosSkinLookUp,'rosSkin',Integumentary,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosSkinLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Integumentary.otherTxt">

                                        </ng-select>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Integumentary,'otherHad')"
                                        [checked]="Integumentary.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Integumentary.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Integumentary,'otherHad')"
                                        [checked]="Integumentary.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Integumentary,'otherHad')"
                                        [checked]="Integumentary.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td class="boldtext">
                                <b>Neurological</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Headaches</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'headaches')"
                                        [checked]="patientReviewModel.headaches == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.headaches == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'headaches')"
                                        [checked]="patientReviewModel.headaches == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'headaches')"
                                        [checked]="patientReviewModel.headaches == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Migraines</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'migraines')"
                                        [checked]="patientReviewModel.migraines == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.migraines == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'migraines')"
                                        [checked]="patientReviewModel.migraines == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'migraines')"
                                        [checked]="patientReviewModel.migraines == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Seizures</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'seizures')"
                                        [checked]="patientReviewModel.seizures == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.seizures == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'seizures')"
                                        [checked]="patientReviewModel.seizures == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'seizures')"
                                        [checked]="patientReviewModel.seizures == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Neurological of NeurologicalRoModel;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1">Other:</label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Neurological, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Neurological.otherTxt,'Other',rosNeurologicalLookUp,'rosNeurological',Neurological,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosNeurologicalLookUp" bindLabel="name_txt"
                                            bindValue="name_txt" [(ngModel)]="Neurological.otherTxt">

                                        </ng-select>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Neurological,'otherHad')"
                                        [checked]="Neurological.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Neurological.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Neurological,'otherHad')"
                                        [checked]="Neurological.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Neurological,'otherHad')"
                                        [checked]="Neurological.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(NeurologicalRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Neurological',NeurologicalRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext">
                                <b>Eyes</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Loss of Vision</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'lossOfVision')"
                                        [checked]="patientReviewModel.lossOfVision == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.lossOfVision == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'lossOfVision')"
                                        [checked]="patientReviewModel.lossOfVision == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'lossOfVision')"
                                        [checked]="patientReviewModel.lossOfVision == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Blurred Vision</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'blurredVision')"
                                        [checked]="patientReviewModel.blurredVision == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.blurredVision == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'blurredVision')"
                                        [checked]="patientReviewModel.blurredVision == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'blurredVision')"
                                        [checked]="patientReviewModel.blurredVision == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Distorted Vision/Halos</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'distortedVision')"
                                        [checked]="patientReviewModel.distortedVision == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.distortedVision == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'distortedVision')"
                                        [checked]="patientReviewModel.distortedVision == 2"
                                        class="mx-1"><span>No</span>
                                </label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'distortedVision')"
                                        [checked]="patientReviewModel.distortedVision == 3"
                                        class="mx-1"><span>?</span>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Loss of Side Vision</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'lossOfSideVision')"
                                        [checked]="patientReviewModel.lossOfSideVision == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.lossOfSideVision == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'lossOfSideVision')"
                                        [checked]="patientReviewModel.lossOfSideVision == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'lossOfSideVision')"
                                        [checked]="patientReviewModel.lossOfSideVision == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Dryness</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'dryness')"
                                        [checked]="patientReviewModel.dryness == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.dryness == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'dryness')"
                                        [checked]="patientReviewModel.dryness == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'dryness')"
                                        [checked]="patientReviewModel.dryness == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Mucous Discharge</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'mucousDischarge')"
                                        [checked]="patientReviewModel.mucousDischarge == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.mucousDischarge == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'mucousDischarge')"
                                        [checked]="patientReviewModel.mucousDischarge == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'mucousDischarge')"
                                        [checked]="patientReviewModel.mucousDischarge == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Sandy or Gritty Feeling</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'sandyFeeling')"
                                        [checked]="patientReviewModel.sandyFeeling == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.sandyFeeling == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'sandyFeeling')"
                                        [checked]="patientReviewModel.sandyFeeling == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'sandyFeeling')"
                                        [checked]="patientReviewModel.sandyFeeling == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Foreign Body Sens.</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'foreignBodySens')"
                                        [checked]="patientReviewModel.foreignBodySens == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.foreignBodySens == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'foreignBodySens')"
                                        [checked]="patientReviewModel.foreignBodySens == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'foreignBodySens')"
                                        [checked]="patientReviewModel.foreignBodySens == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Light Sensitive</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'lightSensitive')"
                                        [checked]="patientReviewModel.lightSensitive == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.lightSensitive == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'lightSensitive')"
                                        [checked]="patientReviewModel.lightSensitive == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'lightSensitive')"
                                        [checked]="patientReviewModel.lightSensitive == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Chronic Eye/Lid Infection</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'chronicEyeInfection')"
                                        [checked]="patientReviewModel.chronicEyeInfection == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.chronicEyeInfection == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'chronicEyeInfection')"
                                        [checked]="patientReviewModel.chronicEyeInfection == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'chronicEyeInfection')"
                                        [checked]="patientReviewModel.chronicEyeInfection == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Styes or Chalazia</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'styesChalazia')"
                                        [checked]="patientReviewModel.styesChalazia == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.styesChalazia == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'styesChalazia')"
                                        [checked]="patientReviewModel.styesChalazia == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'styesChalazia')"
                                        [checked]="patientReviewModel.styesChalazia == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Flashes/Floaters</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'flashesFloaters')"
                                        [checked]="patientReviewModel.flashesFloaters == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.flashesFloaters == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'flashesFloaters')"
                                        [checked]="patientReviewModel.flashesFloaters == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'flashesFloaters')"
                                        [checked]="patientReviewModel.flashesFloaters == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Tired Eyes</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'tiredEye')"
                                        [checked]="patientReviewModel.tiredEye == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.tiredEye == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'tiredEye')"
                                        [checked]="patientReviewModel.tiredEye == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'tiredEye')"
                                        [checked]="patientReviewModel.tiredEye == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Itching</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'itching')"
                                        [checked]="patientReviewModel.itching == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.itching == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'itching')"
                                        [checked]="patientReviewModel.itching == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'itching')"
                                        [checked]="patientReviewModel.itching == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Excess Tearing/Watery</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'excessTearingWatery')"
                                        [checked]="patientReviewModel.excessTearingWatery == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.excessTearingWatery == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'excessTearingWatery')"
                                        [checked]="patientReviewModel.excessTearingWatery == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'excessTearingWatery')"
                                        [checked]="patientReviewModel.excessTearingWatery == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let eye of EyesRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, eye, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(eye.otherTxt,'Other',rosEyesLookUp,'rosEyes',eye,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosEyesLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="eye.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosEyesLookUp,rosEyesCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,eye,'otherHad')"
                                        [checked]="eye.otherHad == 1" class="mx-1"><span
                                        [ngClass]="eye.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,eye,'otherHad')"
                                        [checked]="eye.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,eye,'otherHad')"
                                        [checked]="eye.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger" (click)="removeRos(EyesRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('eye',EyesRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td  class="boldtext">
                                <b>Psychiatric</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Anxiety</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'anxiety')"
                                        [checked]="patientReviewModel.anxiety == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.anxiety == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'anxiety')"
                                        [checked]="patientReviewModel.anxiety == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'anxiety')"
                                        [checked]="patientReviewModel.anxiety == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>ADD</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'addPsychiatric')"
                                        [checked]="patientReviewModel.addPsychiatric == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.addPsychiatric == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'addPsychiatric')"
                                        [checked]="patientReviewModel.addPsychiatric == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'addPsychiatric')"
                                        [checked]="patientReviewModel.addPsychiatric == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>ADHD</span>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,patientReviewModel,'adhd')"
                                        [checked]="patientReviewModel.adhd == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.adhd == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,patientReviewModel,'adhd')"
                                        [checked]="patientReviewModel.adhd == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,patientReviewModel,'adhd')"
                                        [checked]="patientReviewModel.adhd == 3" class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Psychiatric of PsychiatricRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Psychiatric, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Psychiatric.otherTxt,'Other',rosPsychiatricLookUp,'rosPsychiatric',Psychiatric,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosPsychiatricLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Psychiatric.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosPsychiatricLookUp,rosPsychiatricCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Psychiatric,'otherHad')"
                                        [checked]="Psychiatric.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Psychiatric.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Psychiatric,'otherHad')"
                                        [checked]="Psychiatric.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Psychiatric,'otherHad')"
                                        [checked]="Psychiatric.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(PsychiatricRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Psychiatric',PsychiatricRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext">
                                <b>Allergic/Immunologic</b>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'immunologic')"
                                        [checked]="patientReviewModel.immunologic == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.immunologic == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'immunologic')"
                                        [checked]="patientReviewModel.immunologic == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'immunologic')"
                                        [checked]="patientReviewModel.immunologic == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Allergic of AllergicRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1">Other:</label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Allergic, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Allergic.otherTxt,'Other',rosAllergicLookUp,'rosAllergic',Allergic,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosAllergicLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Allergic.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosAllergicLookUp,rosAllergicCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Allergic,'otherHad')"
                                        [checked]="Allergic.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Allergic.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Allergic,'otherHad')"
                                        [checked]="Allergic.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Allergic,'otherHad')"
                                        [checked]="Allergic.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(AllergicRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Allergic',AllergicRoModel)">Add</button>
                            </td>
                        </tr> -->
                    </table>
                </div>
                <div class="col-6">
                    <b  class="boldtext">Ears,Nose,Mouth,Throat</b>
                    <table class="" style="font-size: 14px !important; width:100%">
                        <tr>
                            <td width="50%">
                                <span>Allergies/Hay Fever</span>
                            </td>
                            <td width="33%">
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'allergiesHayFever')"
                                        [checked]="patientReviewModel.allergiesHayFever == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.allergiesHayFever == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'allergiesHayFever')"
                                        [checked]="patientReviewModel.allergiesHayFever == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'allergiesHayFever')"
                                        [checked]="patientReviewModel.allergiesHayFever == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td></td> -->
                        </tr>
                        <tr>
                            <td>
                                <span>Sinus Congestion</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'sinusCongestion')"
                                        [checked]="patientReviewModel.sinusCongestion == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.sinusCongestion == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'sinusCongestion')"
                                        [checked]="patientReviewModel.sinusCongestion == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'sinusCongestion')"
                                        [checked]="patientReviewModel.sinusCongestion == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Runny Nose</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'runnyNose')"
                                        [checked]="patientReviewModel.runnyNose == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.runnyNose == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'runnyNose')"
                                        [checked]="patientReviewModel.runnyNose == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'runnyNose')"
                                        [checked]="patientReviewModel.runnyNose == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Post-Nasal Drip</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'postNasalDrip')"
                                        [checked]="patientReviewModel.postNasalDrip == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.postNasalDrip == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'postNasalDrip')"
                                        [checked]="patientReviewModel.postNasalDrip == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'postNasalDrip')"
                                        [checked]="patientReviewModel.postNasalDrip == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Chronic Cough</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'chronicCough')"
                                        [checked]="patientReviewModel.chronicCough == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.chronicCough == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'chronicCough')"
                                        [checked]="patientReviewModel.chronicCough == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'chronicCough')"
                                        [checked]="patientReviewModel.chronicCough == 3" class="mx-1"><span
                                        [ngClass]="patientReviewModel.chronicCough == 3 ? 'backGround' : ''">?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Dry Throat/Mouth</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'dryMouth')"
                                        [checked]="patientReviewModel.dryMouth == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.dryMouth == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'dryMouth')"
                                        [checked]="patientReviewModel.dryMouth == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'dryMouth')"
                                        [checked]="patientReviewModel.dryMouth == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Ears of EarsRoModel;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><span><b>Other:</b></span></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Ears, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Ears.otherTxt,'Other',rosEarsNoseMouthThroatLookUp,'rosEarsNoseMouthThroat',Ears,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosEarsNoseMouthThroatLookUp" bindLabel="name_txt"
                                            bindValue="name_txt" [(ngModel)]="Ears.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosEarsNoseMouthThroatLookUp,rosEarsNoseMouthThroatCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Ears,'otherHad')"
                                        [checked]="Ears.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Ears.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Ears,'otherHad')"
                                        [checked]="Ears.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Ears,'otherHad')"
                                        [checked]="Ears.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger" (click)="removeRos(EarsRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Ears',EarsRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td  class="boldtext">
                                <b>Respiratory</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Asthma</span>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,patientReviewModel,'asthma')"
                                        [checked]="patientReviewModel.asthma == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.asthma == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,patientReviewModel,'asthma')"
                                        [checked]="patientReviewModel.asthma == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,patientReviewModel,'asthma')"
                                        [checked]="patientReviewModel.asthma == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Chronic Bronchitis</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'chronicBronchitis')"
                                        [checked]="patientReviewModel.chronicBronchitis == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.chronicBronchitis == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'chronicBronchitis')"
                                        [checked]="patientReviewModel.chronicBronchitis == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'chronicBronchitis')"
                                        [checked]="patientReviewModel.chronicBronchitis == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Emphysema</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'emphysema')"
                                        [checked]="patientReviewModel.emphysema == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.emphysema == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'emphysema')"
                                        [checked]="patientReviewModel.emphysema == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'emphysema')"
                                        [checked]="patientReviewModel.emphysema == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Respiratory of RespiratoryRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Respiratory, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Respiratory.otherTxt,'Other',rosRespiratoryLookUp,'rosRespiratory',Respiratory,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosRespiratoryLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Respiratory.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosRespiratoryLookUp,rosRespiratoryCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Respiratory,'otherHad')"
                                        [checked]="Respiratory.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Respiratory.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Respiratory,'otherHad')"
                                        [checked]="Respiratory.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Respiratory,'otherHad')"
                                        [checked]="Respiratory.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(RespiratoryRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Respiratory',RespiratoryRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext"><b>Vascular/Cardiovascular</b></td>
                        </tr>
                        <tr>
                            <td>
                                <span>Diabetes</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'diabetes')"
                                        [checked]="patientReviewModel.diabetes == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.diabetes == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'diabetes')"
                                        [checked]="patientReviewModel.diabetes == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'diabetes')"
                                        [checked]="patientReviewModel.diabetes == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Heart Disease</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'heartPain')"
                                        [checked]="patientReviewModel.heartPain == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.heartPain == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'heartPain')"
                                        [checked]="patientReviewModel.heartPain == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'heartPain')"
                                        [checked]="patientReviewModel.heartPain == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>High Blood Pressure</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'highBloodPressure')"
                                        [checked]="patientReviewModel.highBloodPressure == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.highBloodPressure == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'highBloodPressure')"
                                        [checked]="patientReviewModel.highBloodPressure == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'highBloodPressure')"
                                        [checked]="patientReviewModel.highBloodPressure == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>High Cholesterol</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'highCholesterol')"
                                        [checked]="patientReviewModel.highCholesterol == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.highCholesterol == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'highCholesterol')"
                                        [checked]="patientReviewModel.highCholesterol == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'highCholesterol')"
                                        [checked]="patientReviewModel.highCholesterol == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Vascular Disease</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'vascularDisease')"
                                        [checked]="patientReviewModel.vascularDisease == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.vascularDisease == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'vascularDisease')"
                                        [checked]="patientReviewModel.vascularDisease == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'vascularDisease')"
                                        [checked]="patientReviewModel.vascularDisease == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Vascular of VascularRoModel;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Vascular, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Vascular.otherTxt,'Other',rosCardiovascularLookUp,'rosCardiovascular',Vascular,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosCardiovascularLookUp" bindLabel="name_txt"
                                            bindValue="name_txt" [(ngModel)]="Vascular.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosCardiovascularLookUp,rosCardiovascularCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Vascular,'otherHad')"
                                        [checked]="Vascular.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Vascular.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Vascular,'otherHad')"
                                        [checked]="Vascular.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Vascular,'otherHad')"
                                        [checked]="Vascular.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(VascularRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Vascular',VascularRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td  class="boldtext">
                                <b>Gastrointestinal</b>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Diarrhea</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'gastrointestinalDiarrhea')"
                                        [checked]="patientReviewModel.gastrointestinalDiarrhea == 1"
                                        class="mx-1"><span
                                        [ngClass]="patientReviewModel.gastrointestinalDiarrhea == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'gastrointestinalDiarrhea')"
                                        [checked]="patientReviewModel.gastrointestinalDiarrhea == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'gastrointestinalDiarrhea')"
                                        [checked]="patientReviewModel.gastrointestinalDiarrhea == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>Constipation</span>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'constipation')"
                                        [checked]="patientReviewModel.constipation == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.constipation == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'constipation')"
                                        [checked]="patientReviewModel.constipation == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'constipation')"
                                        [checked]="patientReviewModel.constipation == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Gastrointestinal of GastrointestinalRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Gastrointestinal, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Gastrointestinal.otherTxt,'Other',rosGastrointestinalLookUp,'rosGastrointestinal',Gastrointestinal,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosGastrointestinalLookUp" bindLabel="name_txt"
                                            bindValue="name_txt" [(ngModel)]="Gastrointestinal.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosGastrointestinalLookUp,rosGastrointestinalCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Gastrointestinal,'otherHad')"
                                        [checked]="Gastrointestinal.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Gastrointestinal.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Gastrointestinal,'otherHad')"
                                        [checked]="Gastrointestinal.otherHad == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Gastrointestinal,'otherHad')"
                                        [checked]="Gastrointestinal.otherHad == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(GastrointestinalRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Gastrointestinal',GastrointestinalRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext">
                                <b>Genitourinary</b>
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'genitourinaryDiarrhea')"
                                        [checked]="patientReviewModel.genitourinaryDiarrhea == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.genitourinaryDiarrhea == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'genitourinaryDiarrhea')"
                                        [checked]="patientReviewModel.genitourinaryDiarrhea == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'genitourinaryDiarrhea')"
                                        [checked]="patientReviewModel.genitourinaryDiarrhea == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Genitourinary of GenitourinaryRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Genitourinary, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Genitourinary.otherTxt,'Other',rosGenitourinaryLookUp,'rosGenitourinary',Genitourinary,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosGenitourinaryLookUp" bindLabel="name_txt"
                                            bindValue="name_txt" [(ngModel)]="Genitourinary.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosGenitourinaryLookUp,rosGenitourinaryCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Genitourinary,'otherHad')"
                                        [checked]="Genitourinary.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Genitourinary.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Genitourinary,'otherHad')"
                                        [checked]="Genitourinary.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Genitourinary,'otherHad')"
                                        [checked]="Genitourinary.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(GenitourinaryRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Genitourinary',GenitourinaryRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext">
                                <b>Bones/Joints/Muscles</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">
                                Arthritis
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'rheumatoidArthritis')"
                                        [checked]="patientReviewModel.rheumatoidArthritis == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.rheumatoidArthritis == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'rheumatoidArthritis')"
                                        [checked]="patientReviewModel.rheumatoidArthritis == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'rheumatoidArthritis')"
                                        [checked]="patientReviewModel.rheumatoidArthritis == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">
                                Muscle Pain
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'musclePain')"
                                        [checked]="patientReviewModel.musclePain == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.musclePain == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'musclePain')"
                                        [checked]="patientReviewModel.musclePain == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'musclePain')"
                                        [checked]="patientReviewModel.musclePain == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">
                                Joint Pain
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'jointPain')"
                                        [checked]="patientReviewModel.jointPain == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.jointPain == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'jointPain')"
                                        [checked]="patientReviewModel.jointPain == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'jointPain')"
                                        [checked]="patientReviewModel.jointPain == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Bones of BonesRoModel;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Bones, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Bones.otherTxt,'Other',rosBonesLookUp,'rosBones',Bones,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosBonesLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Bones.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosBonesLookUp,rosBonesCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Bones,'otherHad')"
                                        [checked]="Bones.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Bones.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Bones,'otherHad')"
                                        [checked]="Bones.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Bones,'otherHad')"
                                        [checked]="Bones.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger" (click)="removeRos(BonesRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Bones',BonesRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext">
                                <b>Lymphatic/Hematologic</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">
                                Anemia
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,patientReviewModel,'anemia')"
                                        [checked]="patientReviewModel.anemia == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.anemia == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,patientReviewModel,'anemia')"
                                        [checked]="patientReviewModel.anemia == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,patientReviewModel,'anemia')"
                                        [checked]="patientReviewModel.anemia == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Lymphatic of LymphaticRoModel;let i = index;">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Lymphatic, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Lymphatic.otherTxt,'Other',rosLymphaticLookUp,'rosLymphatic',Lymphatic,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosLymphaticLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Lymphatic.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosLymphaticLookUp,rosLymphaticCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Lymphatic,'otherHad')"
                                        [checked]="Lymphatic.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Lymphatic.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Lymphatic,'otherHad')"
                                        [checked]="Lymphatic.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Lymphatic,'otherHad')"
                                        [checked]="Lymphatic.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(LymphaticRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Lymphatic',LymphaticRoModel)">Add</button>
                            </td>
                        </tr> -->
                        <tr>
                            <td class="boldtext">
                                <b>Endocrine</b>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-weight: bold;">
                                Thyroid/Other Glands
                            </td>
                            <td>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(1,patientReviewModel,'thyroidOtherGlands')"
                                        [checked]="patientReviewModel.thyroidOtherGlands == 1" class="mx-1"><span
                                        [ngClass]="patientReviewModel.thyroidOtherGlands == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(2,patientReviewModel,'thyroidOtherGlands')"
                                        [checked]="patientReviewModel.thyroidOtherGlands == 2"
                                        class="mx-1"><span>No</span></label>
                                <label><input type="radio"
                                        (change)="checkArrayRadio(3,patientReviewModel,'thyroidOtherGlands')"
                                        [checked]="patientReviewModel.thyroidOtherGlands == 3"
                                        class="mx-1"><span>?</span></label>
                            </td>
                        </tr>
                        <tr *ngFor="let Endocrine of EndocrineRoModel;let i = index">
                            <td>
                                <div class="form-row">
                                    <label class="pt-1"><b>Other:</b></label>
                                    <div class="col-10">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event, Endocrine, 'otherTxt')" addTagText="Add Item" [addTag]="CreateNew"
                                            (keydown.tab)="saveDropdown(Endocrine.otherTxt,'Other',rosEndocrineLookUp,'rosEndocrine',Endocrine,'otherTxt',dbEnum.QE_DEV_SQL_DB)"
                                            [items]="rosEndocrineLookUp" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="Endocrine.otherTxt">

                                        </ng-select>
                                    </div>
                                    <!-- <a href="javascript:void(0)"
                                        (click)="editLookup(rosEndocrineLookUp,rosEndocrineCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                                </div>
                            </td>
                            <td>
                                <label><input type="radio" (change)="checkArrayRadio(1,Endocrine,'otherHad')"
                                        [checked]="Endocrine.otherHad == 1" class="mx-1"><span
                                        [ngClass]="Endocrine.otherHad == 1 ? 'backGround' : ''">Yes</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(2,Endocrine,'otherHad')"
                                        [checked]="Endocrine.otherHad == 2" class="mx-1"><span>No</span></label>
                                <label><input type="radio" (change)="checkArrayRadio(3,Endocrine,'otherHad')"
                                        [checked]="Endocrine.otherHad == 3" class="mx-1"><span>?</span></label>
                            </td>
                            <!-- <td>
                                <button class="btn btn-danger"
                                    (click)="removeRos(EndocrineRoModel,i)">Remove</button>
                            </td> -->
                        </tr>
                        <!-- <tr>
                            <td>
                                <button class="btn px-4 btn-primary"
                                    (click)="addRos('Endocrine',EndocrineRoModel)">Add</button>
                            </td>
                        </tr> -->
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <label style="font-weight:normal;">
                        If you answered Yes to any of the above or have a condition not listed, please explain:
                    </label>
                    <textarea rows="4" [(ngModel)]="patientReviewModel.explinationOfConditions" cols="70"
                        class="form-control"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <label style="font-weight:normal;">
                        If you answered question (?) to any of the above,please explain:
                    </label>
                    <textarea rows="4" [(ngModel)]="patientReviewModel.systemReviewQuestion" cols="70"
                        class="form-control"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>
                        I, the patient/guardian/responsible party, have accurately and truthfully completed the
                        information
                        listed on this form. I agree that all fees incurred are my responsibility regardless of
                        insurance
                        coverage.
                        <br>
                        I acknowledge that I have received a "Notice of Privacy Practices" regarding the use and
                        disclosure
                        of my health information(Form is available at front desk or printable from our website)
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label>By clicking "Yes" below you will have electronically signed this form</label>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <label><input type="checkbox" [(ngModel)]="patientReviewModel.signed" class="mx-1">Yes</label>
                </div>
                <div class="col-5">
                    <div class="form-row">
                        <div class="col-4">
                            <label class="pt-1">Your Name:</label>
                        </div>
                        <div class="col-8">
                            <input type="text" [(ngModel)]="patientReviewModel.signedName" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="form-row">
                        <div class="col-3">
                            <label class="pt-1">Date:</label>
                        </div>
                        <div class="col-8">
                            <input (ngModelChange)="patientReviewModel.signedDate = $event"
                                [ngModel]="patientReviewModel.signedDate | date:'MM-dd-yyyy'" type="text"
                                [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                class="form-control ml-5 col-12">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-1">
                <div class="col-2">
                    <label><input type="checkbox" #value  (ngModelChange)="getcheckbox_value($event)" [(ngModel)]="patientReviewModel.doctorReviewed"
                            class="mx-1">Doctor Reviewed</label>
                </div>
                <div class="col-3">
                    <div class="form-row">
                        <div class="col-4">
                            <label class="pt-1">Date:</label>
                        </div>
                        <div class="col-8">
                            <input (ngModelChange)="patientReviewModel.doctorReviewedDate = $event"
                                [ngModel]="patientReviewModel.doctorReviewedDate | date:'MM-dd-yyyy'" type="text"
                                [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <ng-select [clearable]="true" placeholder=" --- Select ---"
                        [(ngModel)]="patientReviewModel.doctorID" #myUserSelect
                        (change)="onSelectUser(patientReviewModel.doctorID)">
                        <ng-option *ngFor="let userInfo of userList" [value]="userInfo.qeUserID">
                            {{userInfo.display_txt}}
                        </ng-option>
                    </ng-select>

                    <div class="col-12">
                        <img [src]="imgURL" height="120px" *ngIf="imgURL">
                    </div>


                </div>
            </div>
        </div>
        <!-- </div> -->
        <hr>

        <br>
        <div id="content">

            <div class="titlebox"><b>Patient Review History</b></div>
            <br>
            <div class="col-12">
                <table class="table-bordered" style="font-size: 14px !important; width:100%">
                    <tr>
                        <th>Date</th>
                        <th>Signed</th>
                        <th>Dr Reviewed</th>
                        <th>Dr Reviewed Date</th>
                    </tr>
                    <tr *ngFor="let item of rowPatientHistory;let i = index">
                        <td>
                            <div class="col-12 px-1">
                                {{item.created_dt | date:'MM-dd-yyyy'}}
                            </div>
                        </td>
                        <td>
                            <div class="col-12 px-1">
                                {{item.signedValue}}
                            </div>
                        </td>
                        <td>
                            <div class="col-12 px-1">
                                {{item.doctorReviewedValue}}
                            </div>
                        </td>
                        <td>
                            <div class="col-12 px-1">
                                {{item.doctorReviewedDate | date:'MM-dd-yyyy'}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</fieldset>
