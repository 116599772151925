import { ROModel } from './../../../model/ROModel';
import { FamilyHistoryModel } from './../../../model/FamilyHistoryModel';
import { LookupModel } from './../../../model/lookupModel';
import { element } from 'protractor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from './../../../services/user.service';
import { LookupService } from 'src/app/services/lookup.service';
import { PatientReviewModel } from './../../../model/PatientReviewModel';
import { ImmunizationModel } from './../../../model/ImmunizationModel';
import { Component, OnInit, HostListener } from '@angular/core';
import { AllergyModel } from 'src/app/model/AllergyModel';
import { FamilyHistoryAdditionalModel } from 'src/app/model/FamilyHistoryAdditionalModel';
import { PatientModel } from 'src/app/model/patientModel';
import { PatientHistoryService } from 'src/app/services/patient-history.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { RXModel } from 'src/app/model/RXModel';
import { UserModel } from 'src/app/model/userModel';
import { OfficeModel } from 'src/app/model/officeModel';
import { GlobalService } from 'src/app/services/global.service';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { LookupModalComponent } from 'src/app/shared/lookup-modal/lookup-modal.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ArrayFiltrerPipe } from 'src/app/core/array-filtrer.pipe';
import { ProviderModel } from 'src/app/model/providerModel';
import { RowType } from 'ag-grid-community';
import { isThisSecond } from 'date-fns';
import { BooleanLiteral, ElementFlags } from 'typescript';
import { ThrowStmt } from '@angular/compiler';
import { PhoneMask } from 'src/app/core/directives/phone.directive';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { ViewportScroller } from '@angular/common';



@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.scss']
})
export class PatientHistoryComponent implements OnInit {
  isDisabled = false;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  // Function to enable/disable the component
  disablePage(): void {
    this.isDisabled = true;
  }


  medicationList: RXModel[] = [];
  patientDetails: PatientModel = new PatientModel();
  allergyList: AllergyModel[] = [];
  FamilyHistorylist: FamilyHistoryModel[] = [];
  // familyHistoryAddition: FamilyHistoryAdditionalModel[] = [];
  immunizationList: ImmunizationModel[] = [];
  patientReviewModel: PatientReviewModel = new PatientReviewModel();
  currentPatientReviewModel: any;
  rowPatientHistory: PatientReviewModel[] = [];
  allLookupData: LookupModel[] = [];
  relationLookup: LookupModel[] = [];
  relationcopyLookup: LookupModel[] = [];
  someObject: { [key: string]: any } = {}
  needToFind: any;
  columnPatientHistory: any;
  smokingStatusDropDown: LookupModel[] = [];
  office: OfficeModel[] = [];
  officename: string = '';
  officeaddress: string = '';
  officeCitySZ:string='';
  officephone: string = '';
  officeemail: string = '';
  office_npi_txt: string = '';
  licence:string='';
  currentDate: Date = new Date();
  userList: UserModel[] = [];
  user1: any = new UserModel();
  user: any = new UserModel();
  Userid: number = 0;
  imgURL: any;
  imageEXTSRC: any;
  selectpatient: any;
  doctoredate: Date = new Date;;
  License:string='';
  rowStyle: any;
  imageINTSRC: any;
  vaccineLookUp: LookupModel[] = [];
  vaccineLookUpCopy: LookupModel[] = [];
  socialHistoryLookup: LookupModel[] = [];
  familyHistoryLookup: LookupModel[] = [];
  familyHistoryCopyLookup: LookupModel[] = [];
  bsModalRef !: BsModalRef;
  showComponent: boolean = false;
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  genericLookup: LookupModel[] = [];
  genericLookupCopy: LookupModel[] = [];
  BrandLookup: LookupModel[] = [];
  phrxdetailLookUp: LookupModel[] = [];
  phrxdetailCopyLookUp: LookupModel[] = [];
  phrxtypeLookUp: LookupModel[] = [];
  phrxtypeCopyLookUp: LookupModel[] = [];
  medication: LookupModel[] = [];
  medicationCopy: LookupModel[] = [];
  reaction: LookupModel[] = [];
  reactionCopy: LookupModel[] = [];
  type: LookupModel[] = [];
  typeCopy: LookupModel[] = [];
  // disease : object = {};
  ConstitutionalRoModel: ROModel[] = [];
  IntegumentaryRoModel: ROModel[] = [];
  NeurologicalRoModel: ROModel[] = [];
  EyesRoModel: ROModel[] = [];
  PsychiatricRoModel: ROModel[] = [];
  AllergicRoModel: ROModel[] = [];
  EarsRoModel: ROModel[] = [];
  RespiratoryRoModel: ROModel[] = [];
  VascularRoModel: ROModel[] = [];
  GastrointestinalRoModel: ROModel[] = [];
  GenitourinaryRoModel: ROModel[] = [];
  BonesRoModel: ROModel[] = [];
  LymphaticRoModel: ROModel[] = [];
  EndocrineRoModel: ROModel[] = [];
  rosConstitutionalLookUp: LookupModel[] = [];
  rosConstitutionalCopyLookUp: LookupModel[] = [];
  rosSkinLookUp: LookupModel[] = [];
  rosSkinCopyLookUp: LookupModel[] = [];
  rosNeurologicalLookUp: LookupModel[] = [];
  rosNeurologicalCopyLookUp: LookupModel[] = [];
  rosEyesLookUp: LookupModel[] = [];
  rosEyesCopyLookUp: LookupModel[] = [];
  rosPsychiatricLookUp: LookupModel[] = [];
  rosPsychiatricCopyLookUp: LookupModel[] = [];
  rosAllergicLookUp: LookupModel[] = [];
  rosAllergicCopyLookUp: LookupModel[] = [];
  rosEarsNoseMouthThroatLookUp: LookupModel[] = [];
  rosEarsNoseMouthThroatCopyLookUp: LookupModel[] = [];
  rosRespiratoryLookUp: LookupModel[] = [];
  rosRespiratoryCopyLookUp: LookupModel[] = [];
  rosCardiovascularLookUp: LookupModel[] = [];
  rosCardiovascularCopyLookUp: LookupModel[] = [];
  rosGastrointestinalLookUp: LookupModel[] = [];
  rosGastrointestinalCopyLookUp: LookupModel[] = [];
  rosGenitourinaryLookUp: LookupModel[] = [];
  rosGenitourinaryCopyLookUp: LookupModel[] = [];
  rosBonesLookUp: LookupModel[] = [];
  rosBonesCopyLookUp: LookupModel[] = [];
  rosLymphaticLookUp: LookupModel[] = [];
  rosLymphaticCopyLookUp: LookupModel[] = [];
  rosEndocrineLookUp: LookupModel[] = [];
  rosEndocrineCopyLookUp: LookupModel[] = [];
  strengthLookup: LookupModel[] = [];
  strengthLookupCopy: LookupModel[] = [];
  doseLookup: LookupModel[] = [];
  doseLookupCopy: LookupModel[] = [];
  rxRouteLookup: LookupModel[] = [];
  rxRouteLookupCopy: LookupModel[] = [];
  rxfrequency: LookupModel[] = [];
  rxfrequencyCopy: LookupModel[] = [];
  rxNumberLookup: LookupModel[] = [];
  rxNumberLookupCopy: LookupModel[] = [];
  refilLookup: LookupModel[] = [];
  check_value: string = '';
  refilLookupCopy: LookupModel[] = [];
  selectedCity = [];
  statusLookup: any =
    [{
      "value": "All",
      "key": "-All-"
    },
    {
      "value": "Active",
      "key": "Active"
    },
    {
      "value": "InActive",
      "key": "InActive"
    },
    {
      "value": "Discontinued",
      "key": "Discontinued"
    },
    {
      "value": "Administered",
      "key": "Administered"
    }]

    socialHistoryInfectionLookup: any =
    [{
      "value": "Gonorrhea",
      "key": "Gonorrhea"
    },
    {
      "value": "Hepatitis",
      "key": "Hepatitis"
    },
    {
      "value": "HIV",
      "key": "HIV"
    },
    {
      "value": "Syphilis",
      "key": "Syphilis"
    }]

  condition_yes_no_val: boolean = false;
  relationship_to_patient_txt_val: string = '';
  condition_txt: string = '';
  familyhistoryadditional_id: string = ''
  patient_review_id: string = '';
  patient_id: string = '';
  created_dt: Date = new Date();
  created_by: string = '';
  updated_dt: Date = new Date();
  updated_by: string = '';
  newBlindness: any = {
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  };


  public Blindness_array: Array<FamilyHistoryAdditionalModel> = [{

    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public Cataract_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];
  public Glaucoma_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];
  public LazyEyes_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public MacularDegeneration_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public RetinalDetachment_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public RetinalDisease_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public Arthrits_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public Cancer_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public Diabetes_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public HeartDisease_array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public HighBlood_Pressure_Array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public Kidney_Pressure_Array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public Lupus_Array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  public ThyroidDisease_Array: Array<FamilyHistoryAdditionalModel> = [{
    familyhistoryadditional_id: this.familyhistoryadditional_id,
    patient_review_id: this.patient_review_id,
    patient_id: this.patient_id,
    condition_txt: this.condition_txt,
    condition_yes_no: this.condition_yes_no_val,
    relationship_to_patient_txt: this.relationship_to_patient_txt_val,
    created_dt: this.created_dt,
    created_by: this.created_by,
    updated_dt: this.updated_dt,
    updated_by: this.updated_by
  }];

  constructor(private _patientHistoryService: PatientHistoryService,
    private toastr: ToastrService, private _lookupService: LookupService,
    private _userService: UserService, private globalService: GlobalService,
    private modalService: BsModalService,private viewportScroller: ViewportScroller) {
    this.patientDetails = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.allLookupData = JSON.parse(localStorage.getItem('AllLookupData')!);
  }

  ngOnInit() {

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.Blindness_array = [];
    this.Cataract_array = [];
    this.Glaucoma_array = [];
    this.LazyEyes_array = [];
    this.Cancer_array = [];
    this.RetinalDetachment_array = [];
    this.MacularDegeneration_array = [];
    this.RetinalDisease_array = [];
    this.Arthrits_array = [];
    this.Cancer_array = [];
    this.Diabetes_array = [];
    this.HeartDisease_array = [];
    this.HighBlood_Pressure_Array = [];
    this.Kidney_Pressure_Array = [];
    this.Lupus_Array = [];
    this.ThyroidDisease_Array = [];
    this.allergyList.push(new AllergyModel());
    var ConstitutionalroModel = new ROModel();
    ConstitutionalroModel.secName = 'Constitutional';
    this.ConstitutionalRoModel.push(ConstitutionalroModel);
    var IntegumentaryroModel = new ROModel();
    IntegumentaryroModel.secName = 'Integumentary';
    this.IntegumentaryRoModel.push(IntegumentaryroModel);
    var NeurologicalroModel = new ROModel();
    NeurologicalroModel.secName = 'Neurological';
    this.NeurologicalRoModel.push(NeurologicalroModel);
    var eyeroModel = new ROModel();
    eyeroModel.secName = 'eye';
    this.EyesRoModel.push(eyeroModel);
    var PsychiatricroModel = new ROModel();
    PsychiatricroModel.secName = 'Psychiatric';
    this.PsychiatricRoModel.push(PsychiatricroModel);
    var AllergicroModel = new ROModel();
    AllergicroModel.secName = 'Allergic';
    this.AllergicRoModel.push(AllergicroModel);
    var EarsroModel = new ROModel();
    EarsroModel.secName = 'Ears';
    this.EarsRoModel.push(EarsroModel);
    var RespiratoryroModel = new ROModel();
    RespiratoryroModel.secName = 'Respiratory';
    this.RespiratoryRoModel.push(RespiratoryroModel);
    var VascularroModel = new ROModel();
    VascularroModel.secName = 'Vascular';
    this.VascularRoModel.push(VascularroModel);
    var GastrointestinalroModel = new ROModel();
    GastrointestinalroModel.secName = 'Gastrointestinal';
    this.GastrointestinalRoModel.push(GastrointestinalroModel);
    var GenitourinaryroModel = new ROModel();
    GenitourinaryroModel.secName = 'Genitourinary';
    this.GenitourinaryRoModel.push(GenitourinaryroModel);
    var BonesroModel = new ROModel();
    BonesroModel.secName = 'Bones';
    this.BonesRoModel.push(BonesroModel);
    var LymphaticroModel = new ROModel();
    LymphaticroModel.secName = 'Lymphatic';
    this.LymphaticRoModel.push(LymphaticroModel);
    var EndocrineroModel = new ROModel();
    EndocrineroModel.secName = 'Endocrine';
    this.EndocrineRoModel.push(EndocrineroModel);

    var FamilyHistorymodel = new FamilyHistoryModel();
    this.FamilyHistorylist.push(FamilyHistorymodel);

    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    this.patientReviewModel.patient_id = this.patientDetails.patient_id;
    this.medicationList.push(new RXModel());


    this.patientReviewModel.familyHistories.push(new FamilyHistoryModel());
    this.patientReviewModel.familyHistoryAdditionals.push(new FamilyHistoryAdditionalModel());
    this.immunizationList.push(new ImmunizationModel());
    this.someObject = this.patientReviewModel;
    // const filterParams = {
    //   comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    //     const dateAsString = cellValue;
    //     const dateParts = dateAsString.split('/');
    //     const cellDate = new Date(
    //       Number(dateParts[2]),
    //       Number(dateParts[1]) - 1,
    //       Number(dateParts[0])
    //     );
    //     if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    //       return 0;
    //     }
    //     if (cellDate < filterLocalDateAtMidnight) {
    //       return -1;
    //     }
    //     if (cellDate > filterLocalDateAtMidnight) {
    //       return 1;
    //     }
    //   },
    // };
    this.columnPatientHistory = [
      {
        headerName: 'Date',
        field: 'created_dt', sortable: true, sortingOrder: ['desc'], resizable: true, rowDrag: true,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Signed',
        field: 'signedValue', sortable: true, filter: true, resizable: true,
      },
      {
        headerName: 'Dr Reviewed',
        field: 'doctorReviewedValue', sortable: true, filter: true, resizable: true,
      },
      {
        headerName: 'Dr Reviewed Date',
        field: 'doctorReviewedDate', sortable: true, resizable: true, filter: false, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      }



    ];


    this.getAllLookup();
    this.getOffice();
    this.getPatientHistory();
    this.getUser();
    // if(this.vaccineLookUp.length < 1){
    //
    //   this.getdataAllLookup();
    // }




  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  romoveMedication(index: number) {
    this.medicationList.splice(index, 1);
  }

  addMedication() {
    var medication = new RXModel();
    // medication.patient_id = this.patientDetails.patient_id;
    this.medicationList.push(medication);
  }

  addAllergy() {
    var allergy = new AllergyModel();
    this.allergyList.push(new AllergyModel());
  }

  removeAllergy(index: number) {
    this.allergyList.splice(index, 1);
  }

  addFamilyHistory() {
    this.patientReviewModel.familyHistories.push(new FamilyHistoryModel());
  }
  addFamilyHistoryAddtional() {
    this.patientReviewModel.familyHistoryAdditionals.push(new FamilyHistoryAdditionalModel());
  }

  Add_Blindness() {
    this.Blindness_array.push(new FamilyHistoryAdditionalModel());
    this.Blindness_array[this.Blindness_array.length - 1].condition_txt = 'Blindness';
  }
  deleteBlindness(index: any) {
    this.Blindness_array.splice(index, 1);
  }

  Add_Cataract() {
    this.Cataract_array.push(new FamilyHistoryAdditionalModel())
    this.Cataract_array[this.Cataract_array.length - 1].condition_txt = 'Cataract';
  }
  deleteCataract(index: any) {
    this.Cataract_array.splice(index, 1);
  }

  Add_Glaucoma() {
    this.Glaucoma_array.push(new FamilyHistoryAdditionalModel())
    this.Glaucoma_array[this.Glaucoma_array.length - 1].condition_txt = 'Glaucoma';
  }
  deleteGlaucoma(index: any) {
    this.Glaucoma_array.splice(index, 1);
  }
  Add_LazyEyes() {
    this.LazyEyes_array.push(new FamilyHistoryAdditionalModel())
    this.LazyEyes_array[this.LazyEyes_array.length - 1].condition_txt = 'Lazy Eyes';
  }
  deleteLazyEyes(index: any) {
    this.LazyEyes_array.splice(index, 1);
  }

  Add_MacularDegeneration() {
    this.MacularDegeneration_array.push(new FamilyHistoryAdditionalModel())
    this.MacularDegeneration_array[this.MacularDegeneration_array.length - 1].condition_txt = 'Macular Degeneration';
  }
  deleteMacularDegeneration(index: any) {
    this.MacularDegeneration_array.splice(index, 1);
  }

  Add_RetinalDetachment() {
    this.RetinalDetachment_array.push(new FamilyHistoryAdditionalModel())
    this.RetinalDetachment_array[this.RetinalDetachment_array.length - 1].condition_txt = 'Retinal Detachment';
  }
  deletRetinalDetachment(index: any) {
    this.RetinalDetachment_array.splice(index, 1);
  }

  Add_RetinalDisease() {
    this.RetinalDisease_array.push(new FamilyHistoryAdditionalModel())
    this.RetinalDisease_array[this.RetinalDisease_array.length - 1].condition_txt = 'Retinal Disease';
  }
  deletRetinalDisease(index: any) {
    this.RetinalDisease_array.splice(index, 1);
  }

  Add_Arthrits() {
    this.Arthrits_array.push(new FamilyHistoryAdditionalModel())
    this.Arthrits_array[this.Arthrits_array.length - 1].condition_txt = 'Arthrits';
  }
  deletArthrits(index: any) {
    this.Arthrits_array.splice(index, 1);
  }

  Add_Cancer() {
    this.Cancer_array.push(new FamilyHistoryAdditionalModel())
    this.Cancer_array[this.Cancer_array.length - 1].condition_txt = 'Cancer';
  }
  deletCancer(index: any) {
    this.Cancer_array.splice(index, 1);
  }

  Add_Diabetes() {
    this.Diabetes_array.push(new FamilyHistoryAdditionalModel())
    this.Diabetes_array[this.Diabetes_array.length - 1].condition_txt = 'Diabetes';
  }
  deletDiabetes(index: any) {
    this.Diabetes_array.splice(index, 1);
  }
  Add_HeartDisease() {
    this.HeartDisease_array.push(new FamilyHistoryAdditionalModel())
    this.HeartDisease_array[this.HeartDisease_array.length - 1].condition_txt = 'Heart Disease';
  }
  deletHeartDisease(index: any) {
    this.HeartDisease_array.splice(index, 1);
  }

  Add_HighBlood_Pressure() {
    this.HighBlood_Pressure_Array.push(new FamilyHistoryAdditionalModel())
    this.HighBlood_Pressure_Array[this.HighBlood_Pressure_Array.length - 1].condition_txt = 'High Blood Pressure';
  }
  deletHighBlood_Pressure(index: any) {
    this.HighBlood_Pressure_Array.splice(index, 1);
  }
  Add_Kidney_Pressure() {
    this.Kidney_Pressure_Array.push(new FamilyHistoryAdditionalModel())
    this.Kidney_Pressure_Array[this.Kidney_Pressure_Array.length - 1].condition_txt = 'Kidney Blood Pressure';
  }
  deletKidney_Pressure(index: any) {
    this.Kidney_Pressure_Array.splice(index, 1);
  }

  Add_Lapus() {
    this.newBlindness = { condition_txt: 'Lupus' };
    this.Lupus_Array.push(new FamilyHistoryAdditionalModel())
    this.Lupus_Array[this.Lupus_Array.length - 1].condition_txt = 'Lupus';
  }
  delet_Lapus(index: any) {
    this.Lupus_Array.splice(index, 1);
  }
  Add_ThyroidDisease() {
    this.ThyroidDisease_Array.push(new FamilyHistoryAdditionalModel())
    this.ThyroidDisease_Array[this.ThyroidDisease_Array.length - 1].condition_txt = 'Thyroid Disease';
  }
  delet_ThyroidDisease(index: any) {
    this.ThyroidDisease_Array.splice(index, 1);
  }

  removeFamilyHistoryAddtional(index: number) {
    this.patientReviewModel.familyHistories.splice(index, 1);
  }
  removeFamilyHistory(index: number) {
    this.patientReviewModel.familyHistoryAdditionals.splice(index, 1);
  }

  addImmunization() {
    var immunization = new ImmunizationModel()
    // immunization.patient_id = this.patientDetails.patient_id;
    this.immunizationList.push(immunization);
  }

  removeImmunization(index: number) {
    this.immunizationList.splice(index, 1);
  }

  getPatientHistory() {
    this.Blindness_array = [];
    this.Cataract_array = [];
    this.Glaucoma_array = [];
    this.LazyEyes_array = [];
    this.Cancer_array = [];
    this.RetinalDetachment_array = [];
    this.MacularDegeneration_array = [];
    this.RetinalDisease_array = [];
    this.Arthrits_array = [];
    this.Cancer_array = [];
    this.Diabetes_array = [];
    this.HeartDisease_array = [];
    this.HighBlood_Pressure_Array = [];
    this.Kidney_Pressure_Array = [];
    this.Lupus_Array = [];
    this.ThyroidDisease_Array = [];

    this.ConstitutionalRoModel = [];
    this.IntegumentaryRoModel = [];
    this.NeurologicalRoModel = [];
    this.EyesRoModel = [];
    this.PsychiatricRoModel = [];
    this.AllergicRoModel = [];
    this.EarsRoModel = [];
    this.VascularRoModel = [];
    this.RespiratoryRoModel = [];
    this.GastrointestinalRoModel = [];
    this.GenitourinaryRoModel = [];
    this.BonesRoModel = [];
    this.LymphaticRoModel = [];
    this.EndocrineRoModel = [];


    this._patientHistoryService.getPatientHistory(this.patientDetails.patient_id).subscribe(res => {

      res.forEach(element => {
        element.signedValue = element.signed ? "Yes" : "No";
        element.doctorReviewedValue = element.doctorReviewed ? "Yes" : "No";
        // element.doctorReviewedDate = element.dateOfLastExam;
        //element.doctorReviewedDate = element.doctorReviewedDate;
      });
      this.rowPatientHistory = res.sort((a, b) => {
        if (a.created_dt > b.created_dt) {
          return -1
        }
        if (a.created_dt < b.created_dt) {
          return 1
        }
        return 0
      });


      if (
        this.rowPatientHistory != undefined && this.rowPatientHistory != null && this.rowPatientHistory.length > 0) {
        // this.patientReviewModel = this.rowPatientHistory[this.rowPatientHistory.length - 1];
        // this.currentPatientReviewModel = this.rowPatientHistory[0];
        // this.currentPatientReviewModel.signedDate = new Date();
        // this.currentPatientReviewModel.doctorReviewed = false;
        // this.currentPatientReviewModel.doctorReviewedDate = new Date();
        // this.currentPatientReviewModel.doctorID = 0;
        // this.patientReviewModel = this.currentPatientReviewModel;

        this.patientReviewModel = this.rowPatientHistory[0];
        //this.patientReviewModel.dateOfLastExam=this.patientDetails.dateOfEntry;
        this.doctoredate = moment(this.patientReviewModel.dateOfLastExam).toDate();
        this.allergyList = this.patientReviewModel.allergy;
        debugger;
        this.medicationList = this.patientReviewModel.medicalrx;
        this.immunizationList = this.patientReviewModel.immunization;
        this.patientReviewModel.familyHistoryAdditionals.forEach(element => {
          if (element.condition_txt == 'Blindness') {
            this.Blindness_array.push(element)
          }
          else if (element.condition_txt == 'Cataract') {
            this.Cataract_array.push(element)
          }
          else if (element.condition_txt == 'Glaucoma') {
            this.Glaucoma_array.push(element)
          }
          else if (element.condition_txt == 'Lazy Eyes') {
            this.LazyEyes_array.push(element)
          }
          else if (element.condition_txt == 'Macular Degeneration') {
            this.MacularDegeneration_array.push(element)
          }
          else if (element.condition_txt == 'Retinal Detachment') {
            this.RetinalDetachment_array.push(element)
          }
          else if (element.condition_txt == 'Retinal Disease') {
            this.RetinalDisease_array.push(element)
          }
          else if (element.condition_txt == 'Arthrits') {
            this.Arthrits_array.push(element)
          }
          else if (element.condition_txt == 'Cancer') {
            this.Cancer_array.push(element)
          }
          else if (element.condition_txt == 'Diabetes') {
            this.Diabetes_array.push(element)
          }
          else if (element.condition_txt == 'Heart Disease') {
            this.HeartDisease_array.push(element)
          }
          else if (element.condition_txt == 'High Blood Pressure') {
            this.HighBlood_Pressure_Array.push(element)
          }
          else if (element.condition_txt == 'Kidney Blood Pressure') {
            this.Kidney_Pressure_Array.push(element)
          }
          else if (element.condition_txt == 'Lupus') {
            this.Lupus_Array.push(element)
          }
          else if (element.condition_txt == 'Thyroid Disease') {
            this.ThyroidDisease_Array.push(element)
          }
        });

        this.patientReviewModel.ros.forEach(element => {
          if (element.secName == 'Constitutional') {
            this.ConstitutionalRoModel.push(element)
          }
          else if (element.secName == 'Integumentary') {
            this.IntegumentaryRoModel.push(element)
          }
          else if (element.secName == 'Neurological') {
            this.NeurologicalRoModel.push(element)
          }
          else if (element.secName == 'eye') {
            this.EyesRoModel.push(element)
          }
          else if (element.secName == 'Psychiatric') {
            this.PsychiatricRoModel.push(element)
          }
          else if (element.secName == 'Allergic') {
            this.AllergicRoModel.push(element)
          }
          else if (element.secName == 'Ears') {
            this.EarsRoModel.push(element)
          }
          else if (element.secName == 'Respiratory') {
            this.RespiratoryRoModel.push(element)
          }
          else if (element.secName == 'Vascular') {
            this.VascularRoModel.push(element)
          }
          else if (element.secName == 'Gastrointestinal') {
            this.GastrointestinalRoModel.push(element)
          }
          else if (element.secName == 'Genitourinary') {
            this.GenitourinaryRoModel.push(element)
          }
          else if (element.secName == 'Bones') {
            this.BonesRoModel.push(element)
          }
          else if (element.secName == 'Lymphatic') {
            this.LymphaticRoModel.push(element)
          }
          else if (element.secName == 'Endocrine') {
            this.EndocrineRoModel.push(element)
          }

        });

        this.currentPatientReviewModel = Object.assign({}, this.patientReviewModel);
        this.currentPatientReviewModel.signedDate = new Date();
        this.currentPatientReviewModel.doctorReviewed = false;
        this.currentPatientReviewModel.doctorReviewedDate = new Date();
        this.currentPatientReviewModel.doctorID = null;
        this.imgURL = '';
        this.patientReviewModel = Object.assign({}, this.currentPatientReviewModel);//this.currentPatientReviewModel;

        this.getUser()
      }
      else
      {
        this.patientReviewModel.dateOfLastExam = typeof this.patientDetails.dateOfEntry == 'string' ? this.patientDetails.dateOfEntry : this.patientDetails.dateOfEntry.toDateString();
      }
    }, (error) => {
    });
  }

  getcheckbox_value(value: boolean) {
    if (value == true) {
      this.patientReviewModel.doctorReviewedDate = new Date();
    }
    else {
      this.patientReviewModel.doctorReviewedDate = this.doctoredate;
    }

  }

  deletePatientHistory(row: any) {
    this._patientHistoryService.deletePatientHistory(row.data).subscribe(res => {
      this.toastr.success('Record Deleted Successfully.');
    });
  }

  save() {

    this.patientReviewModel.ros = [];
    this.ConstitutionalRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.IntegumentaryRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.NeurologicalRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.EyesRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.PsychiatricRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.AllergicRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.EarsRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.RespiratoryRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.VascularRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.GastrointestinalRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.GenitourinaryRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.BonesRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.LymphaticRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.EndocrineRoModel.forEach(element => {
      this.patientReviewModel.ros.push(element)
    });
    this.patientReviewModel.immunization = this.immunizationList;
    this.patientReviewModel.allergy = this.allergyList;
    this.patientReviewModel.medicalrx = this.medicationList;

    this.Blindness_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Cataract_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Glaucoma_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.LazyEyes_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Cancer_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.RetinalDisease_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.RetinalDetachment_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.MacularDegeneration_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Arthrits_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Cancer_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Diabetes_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.HeartDisease_array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.HighBlood_Pressure_Array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Kidney_Pressure_Array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.Lupus_Array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.ThyroidDisease_Array.forEach(element => {
      this.patientReviewModel.familyHistoryAdditionals.push(element)
    });
    this.patientReviewModel.doctorID = this.Userid;
    if (this.patientReviewModel.patient_review_id == '') {
      this._patientHistoryService.insertPatientHistory(this.patientReviewModel).subscribe(res => {
        this.patientReviewModel = new PatientReviewModel();
        this.toastr.success('Record Saved Successfully.');
        this.getPatientHistory();
      }, (error) => {

            });
    }
    else {
      this._patientHistoryService.insertPatientHistory(this.patientReviewModel).subscribe(res => {
        this.patientReviewModel = new PatientReviewModel();
        this.toastr.success('Record Updated Successfully.');
        this.getPatientHistory();
      }, (error) => {

      });
    }
    //this.getPatientHistory();
  }

  checkRadio(event: any, item: any, item2?: any, index?: number, index1?: number) {

    if (index != undefined && item2) {
      this.someObject[item][index][item2] = event;
    }
    else {
      this.someObject[item] = event;
    }
  }
  checkArrayRadio(event: any, item: any, property: string, index?: number) {

    item[property] = event;
  }
  newCheckArrayRadio(event: any, val: number, item: any, property: string, index?: number) {

    item[property] = val;
  }

  changeDropdown(event: any, item: any) {

    // item = event;
    this.someObject[item] = event;
  }


  saveClose() {

  }
  compare(array: LookupModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }
  getSpecificLookup(txt: string){
    let listData = this.allLookupData.filter(x => x.group_txt.toLowerCase() == txt.toLowerCase() && x.active_ind);
    return listData;
  }
  getAllLookup() {

    // this._lookupService.getGlobalLookup('smokingstatus').subscribe(res => {
    //   this.smokingStatusDropDown = res.filter(x => x.active_ind == true);
    //   this.smokingStatusDropDown = this.smokingStatusDropDown.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    // this.rxRouteLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase() && x.active_ind == true);
    //   this.rxRouteLookup = this.rxRouteLookup.sort((a, b) => {
    //     if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
    //       return -1
    //     }
    //     if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
    //       return 1
    //     }
    //     return 0
    //   });
    this.rxRouteLookup = this.getSpecificLookup('rxroute');
    this.rxRouteLookup = this.compare(this.rxRouteLookup);

    this.smokingStatusDropDown = this.getSpecificLookup('smokingstatus');
    this.smokingStatusDropDown = this.compare(this.smokingStatusDropDown);

      // this._lookupService.getGlobalLookup('medicationname').subscribe((res: LookupModel[]) => {
      //   this.medication = res.filter(x => x.active_ind == true);
      //   this.medication = this.medication.sort((a, b) => {
      //     if (a.name_txt < b.name_txt) {
      //       return -1
      //     }
      //     if (a.name_txt > b.name_txt) {
      //       return 1
      //     }
      //     return 0
      //   });
    // this.medication = this.getSpecificLookup('medicationname');
    // this.medication = this.compare(this.medication);
    // this.medicationCopy = this.medication;
        // this._lookupService.getGlobalLookup('phallergyreaction').subscribe((res: LookupModel[]) => {
        //   this.reaction = res.filter(x => x.active_ind == true);
        //   this.reaction = this.reaction.sort((a, b) => {
        //     if (a.name_txt < b.name_txt) {
        //       return -1
        //     }
        //     if (a.name_txt > b.name_txt) {
        //       return 1
        //     }
        //     return 0
        //   });
    // this.reaction = this.getSpecificLookup('phallergyreaction');
    // this.reaction = this.compare(this.reaction);
    // this.reactionCopy = this.reaction;
          // this._lookupService.getGlobalLookup('phallergytype').subscribe((res: LookupModel[]) => {
          //   this.type = res.filter(x => x.active_ind == true);
          //   this.type = this.type.sort((a, b) => {
          //     if (a.name_txt < b.name_txt) {
          //       return -1
          //     }
          //     if (a.name_txt > b.name_txt) {
          //       return 1
          //     }
          //     return 0
          //   });
    this.type = this.getSpecificLookup('phallergytype');
    this.type = this.compare(this.type);
    this.typeCopy = this.type;

            // this._lookupService.getGlobalLookup('rosConstitutional').subscribe((res: LookupModel[]) => {
            //   this.rosConstitutionalLookUp = res.filter(x => x.active_ind == true);
            //   this.rosConstitutionalLookUp = this.rosConstitutionalLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosConstitutionalCopyLookUp = res;
            // });
    // this.rosConstitutionalLookUp = this.getSpecificLookup('rosConstitutional');
    // this.rosConstitutionalLookUp = this.compare(this.rosConstitutionalLookUp);
    // this.rosConstitutionalCopyLookUp = this.rosConstitutionalLookUp;
            // this._lookupService.getGlobalLookup('rosSkin').subscribe((res: LookupModel[]) => {
            //   this.rosSkinLookUp = res.filter(x => x.active_ind == true);
            //   this.rosSkinLookUp = this.rosSkinLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosSkinCopyLookUp = res;
            // });
    this.rosSkinLookUp = this.getSpecificLookup('rosSkin');
    this.rosSkinLookUp = this.compare(this.rosSkinLookUp);
    this.rosSkinCopyLookUp = this.rosSkinLookUp;
            // this._lookupService.getGlobalLookup('rosNeurological').subscribe((res: LookupModel[]) => {
            //   this.rosNeurologicalLookUp = res.filter(x => x.active_ind == true);
            //   this.rosNeurologicalLookUp = this.rosNeurologicalLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosNeurologicalCopyLookUp = res;
            // });
    // this.rosNeurologicalLookUp = this.getSpecificLookup('rosNeurological');
    // this.rosNeurologicalLookUp = this.compare(this.rosNeurologicalLookUp);
    // this.rosNeurologicalCopyLookUp = this.rosNeurologicalLookUp;

            // this._lookupService.getGlobalLookup('rosEyes').subscribe((res: LookupModel[]) => {
            //   this.rosEyesLookUp = res.filter(x => x.active_ind == true);
            //   this.rosEyesLookUp = this.rosEyesLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosEyesCopyLookUp = res;
            // });

    // this.rosEyesLookUp = this.getSpecificLookup('rosEyes');
    // this.rosEyesLookUp = this.compare(this.rosEyesLookUp);
    // this.rosEyesCopyLookUp = this.rosEyesLookUp;

            // this._lookupService.getGlobalLookup('rosPsychiatric').subscribe((res: LookupModel[]) => {
            //   this.rosPsychiatricLookUp = res.filter(x => x.active_ind == true);
            //   this.rosPsychiatricLookUp = this.rosPsychiatricLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosPsychiatricCopyLookUp = res;
            // });

    // this.rosPsychiatricLookUp = this.getSpecificLookup('rosPsychiatric');
    // this.rosPsychiatricLookUp = this.compare(this.rosPsychiatricLookUp);
    // this.rosPsychiatricCopyLookUp = this.rosPsychiatricLookUp;

            // this._lookupService.getGlobalLookup('rosAllergic').subscribe((res: LookupModel[]) => {
            //   this.rosAllergicLookUp = res.filter(x => x.active_ind == true);
            //   this.rosAllergicLookUp = this.rosAllergicLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosAllergicCopyLookUp = res;
            // });

    // this.rosAllergicLookUp = this.getSpecificLookup('rosAllergic');
    // this.rosAllergicLookUp = this.compare(this.rosAllergicLookUp);
    // this.rosAllergicCopyLookUp = this.rosAllergicLookUp;

            // this._lookupService.getGlobalLookup('rosEarsNoseMouthThroat').subscribe((res: LookupModel[]) => {
            //   this.rosEarsNoseMouthThroatLookUp = res.filter(x => x.active_ind == true);
            //   this.rosEarsNoseMouthThroatLookUp = this.rosEarsNoseMouthThroatLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosEarsNoseMouthThroatCopyLookUp = res;
            // });

    // this.rosEarsNoseMouthThroatLookUp = this.getSpecificLookup('rosEarsNoseMouthThroat');
    // this.rosEarsNoseMouthThroatLookUp = this.compare(this.rosEarsNoseMouthThroatLookUp);
    // this.rosEarsNoseMouthThroatCopyLookUp = this.rosEarsNoseMouthThroatLookUp;

            // this._lookupService.getGlobalLookup('rosRespiratory').subscribe((res: LookupModel[]) => {
            //   this.rosRespiratoryLookUp = res.filter(x => x.active_ind == true);
            //   this.rosRespiratoryLookUp = this.rosRespiratoryLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosRespiratoryCopyLookUp = res;
            // });

    // this.rosRespiratoryLookUp = this.getSpecificLookup('rosRespiratory');
    // this.rosRespiratoryLookUp = this.compare(this.rosRespiratoryLookUp);
    // this.rosRespiratoryCopyLookUp = this.rosRespiratoryLookUp;

            // this._lookupService.getGlobalLookup('rosCardiovascular').subscribe((res: LookupModel[]) => {
            //   this.rosCardiovascularLookUp = res.filter(x => x.active_ind == true);
            //   this.rosCardiovascularLookUp = this.rosCardiovascularLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosCardiovascularCopyLookUp = res;
            // });


    // this.rosCardiovascularLookUp = this.getSpecificLookup('rosCardiovascular');
    // this.rosCardiovascularLookUp = this.compare(this.rosCardiovascularLookUp);
    // this.rosCardiovascularCopyLookUp = this.rosCardiovascularLookUp;

            // this._lookupService.getGlobalLookup('rosGenitourinary').subscribe((res: LookupModel[]) => {
            //   this.rosGastrointestinalLookUp = res.filter(x => x.active_ind == true);
            //   this.rosGastrointestinalLookUp = this.rosGastrointestinalLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosGastrointestinalCopyLookUp = res;
            // });

    // this.rosGastrointestinalLookUp = this.getSpecificLookup('rosGenitourinary');
    // this.rosGastrointestinalLookUp = this.compare(this.rosGastrointestinalLookUp);
    // this.rosGastrointestinalCopyLookUp = this.rosGastrointestinalLookUp;

            // this._lookupService.getGlobalLookup('rosBones').subscribe((res: LookupModel[]) => {
            //   this.rosBonesLookUp = res.filter(x => x.active_ind == true);
            //   this.rosBonesLookUp = this.rosBonesLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosBonesCopyLookUp = res;
            // });
    // this.rosBonesLookUp = this.getSpecificLookup('rosBones');
    // this.rosBonesLookUp = this.compare(this.rosBonesLookUp);
    // this.rosBonesCopyLookUp = this.rosBonesLookUp;

            // this._lookupService.getGlobalLookup('rosGenitourinary').subscribe((res: LookupModel[]) => {
            //   this.rosGenitourinaryLookUp = res.filter(x => x.active_ind == true);
            //   this.rosGenitourinaryLookUp = this.rosGenitourinaryLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosGenitourinaryCopyLookUp = res;
            // });
    // this.rosGenitourinaryLookUp = this.getSpecificLookup('rosGenitourinary');
    // this.rosGenitourinaryLookUp = this.compare(this.rosGenitourinaryLookUp);
    // this.rosGenitourinaryCopyLookUp = this.rosGenitourinaryLookUp;

            // this._lookupService.getGlobalLookup('rosLymphatic').subscribe((res: LookupModel[]) => {
            //   this.rosLymphaticLookUp = res.filter(x => x.active_ind == true);
            //   this.rosLymphaticLookUp = this.rosLymphaticLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosLymphaticCopyLookUp = res;
            // });

    // this.rosLymphaticLookUp = this.getSpecificLookup('rosLymphatic');
    // this.rosLymphaticLookUp = this.compare(this.rosLymphaticLookUp);
    // this.rosLymphaticCopyLookUp = this.rosLymphaticLookUp;

            // this._lookupService.getGlobalLookup('rosEndocrine').subscribe((res: LookupModel[]) => {
            //   this.rosEndocrineLookUp = res.filter(x => x.active_ind == true);
            //   this.rosEndocrineLookUp = this.rosEndocrineLookUp.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });
            //   this.rosEndocrineCopyLookUp = res;
            // });
    // this.rosEndocrineLookUp = this.getSpecificLookup('rosEndocrine');
    // this.rosEndocrineLookUp = this.compare(this.rosEndocrineLookUp);
    // this.rosEndocrineCopyLookUp = this.rosEndocrineLookUp;


            // this._lookupService.getGlobalLookup('Family_Relationship').subscribe((res: any) => {
            //   this.relationLookup = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
            //   this.relationLookup = this.relationLookup.sort((a, b) => {
            //     if (a.name_txt < b.name_txt) {
            //       return -1
            //     }
            //     if (a.name_txt > b.name_txt) {
            //       return 1
            //     }
            //     return 0
            //   });

            // }, (error) => {
            //   this.toastr.error(error.error);
            // });

    this.relationLookup = this.getSpecificLookup('patientrelation');
    this.relationLookup = this.compare(this.relationLookup);
    this.showComponent = true;
          //})
        //})
      //})

    //})
  }
  returnToCurrent(): void {
    this.isDisabled = false;
    this.imgURL = '';
    this.patientReviewModel = this.currentPatientReviewModel;
  }
  // disableContent(el: any) {
  //   this.toggleDisable();
  //   // $(el).attr("tabindex", "-1");
  //   // $(el).attr("disabled", "disabled").off('click');
	// 	// var x1=$(el).hasClass("disableCls");

	// 	// (x1==true)?$(el).removeClass("disableCls"):$(el).addClass("disableCls");
  //   // try {
  //   //     el.disabled = el.disabled ? false : true;
  //   // } catch (E) {}
  //   // if (el.childNodes && el.childNodes.length > 0) {
  //   //     for (var x = 0; x < el.childNodes.length; x++) {
  //   //         this.disableContent(el.childNodes[x]);
  //   //     }
  //   // }
  // }
  getUser() {

    let loadingProvider = new UserModel();
    loadingProvider.active_ind = true;
    loadingProvider.first_name_txt = '';
    loadingProvider.last_name_txt = '';
    loadingProvider.display_txt = 'Loading Records.....';
    this.userList.push(loadingProvider);
    //let practiceId = localStorage.getItem('practice')?.toString();

    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    this.user1 = this.userList.filter(x => x.user_id == this.patientDetails.referring_provider_id);
    this.user = this.user1[0];
    if (this.user1[0]?.npi_txt != null) {
      this.License = this.user1[0]?.npi_txt
    }
    // if (this.user1[0]?.email_address_txt) {
    //   this.officeemail = this.user1[0]?.email_address_txt;
    // }
    this.onSelectUser(this.patientReviewModel.doctorID);


    if(this.vaccineLookUp.length < 1){

      this.getdataAllLookup();
    }

    // this._lookupService.getRenderingProvider(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
    //   this.userList = res;
    //   this.user1 = this.userList.filter(x => x.user_id == this.patientDetails.referring_provider_id);
    //   this.user = this.user1;
    //   if (this.user1[0]?.license_txt != null) {
    //     this.License = this.user1[0]?.license_txt
    //   }
    //   this.onSelectUser(this.patientReviewModel.doctorID);
    //   this.getdataAllLookup();
    // }, (error) => {

    // });
  }

  getOffice() {
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;
      this.office = this.office.filter(x => x.office_id == this.patientDetails.defaultOfficeID);
      // if (this.office[0]?.name_txt != null) {
      //   this.officename = this.office[0]?.name_txt
      // }
      // if (this.office[0]?.address_1_txt != null && this.office[0].address_2_txt) {
      //   this.officeaddress = this.office[0]?.address_1_txt + " " + this.office[0]?.address_2_txt;
      // }
      // if (this.office[0]?.phone_1_txt != null) {
      //   this.officephone = this.office[0]?.phone_1_txt;
      // }
      if (this.office[0]?.name_txt != null) {
        this.officename = this.office[0]?.name_txt
      }
      debugger;
      if (this.office[0]?.address_1_txt) {
        this.officeaddress = `${this.office[0]?.address_1_txt}`;
      }
      if(this.office[0]?.address_2_txt){
        this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
      }
      if(this.office[0]?.city_txt){
        this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
      }
      if (this.office[0]?.phone_1_txt) {
        this.officephone = this.office[0]?.phone_1_txt;
      }
      if(this.office[0]?.npi_txt){
        this.office_npi_txt = this.office[0]?.npi_txt;
      }
      if(this.office[0]?.email_address_txt){
        this.officeemail = this.office[0]?.email_address_txt;
      }
    }, (error) => {
      this.toastr.error(error.error);
    });
  }



  onSelectUser(id: any) {
    this.user = this.userList.find(a => a.qeUserID == id);
    this.Userid = this.user?.qeUserID;
    if (this.user) {
      this.imgURL = this.user?.signature_img ? 'data:image/png;base64,' + this.user.signature_img : '';
    } else {
      this.imgURL = '';
      this.user = new UserModel();
    }
    if (this.user?.npi_txt != null) {
      this.License = this.user?.npi_txt
    }
    return true;
  }

  getdataAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: Array<LookupModel>) => {
    //this._lookupService.getAllGlobalLookup().subscribe((res: Array<LookupModel>) => {
      // res = res.filter(x=>x.active_ind == true);

      this.vaccineLookUp = res.filter(x => x.group_txt.toLowerCase() == 'vaccinename'.toLowerCase() && x.active_ind == true);
      this.vaccineLookUp = this.vaccineLookUp.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.vaccineLookUpCopy = Object.assign([], this.vaccineLookUp);
      this.socialHistoryLookup = res.filter(x => x.group_txt.toLowerCase() == 'socialhistoryother'.toLowerCase() && x.active_ind == true);
      this.socialHistoryLookup = this.socialHistoryLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.familyHistoryLookup = res.filter(x => x.group_txt.toLowerCase() == 'familyhistoryother'.toLowerCase() && x.active_ind == true);
      this.familyHistoryLookup = this.familyHistoryLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.familyHistoryCopyLookup = Object.assign([], this.familyHistoryLookup);
      this.genericLookup = res.filter(x => x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname' && x.active_ind == true);
      this.genericLookup = this.genericLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });

      this.BrandLookup = res.filter(x => x.group_txt == 'medicationname' && x.sub_group_txt == 'brandname' && x.active_ind == true);
      this.BrandLookup = this.BrandLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });

      this.medication = res.filter(x => x.group_txt == 'phallergyname' && x.active_ind == true);
      this.medication = this.compare(this.medication);
      this.medicationCopy = this.medication;

      this.reaction = res.filter(x => x.group_txt == 'phallergyreaction' && x.active_ind == true);
      this.reaction = this.compare(this.reaction);
      this.reactionCopy = this.reaction;

      this.rosConstitutionalLookUp = res.filter(x => x.group_txt == 'rosConstitutional' && x.active_ind == true);
      this.rosConstitutionalLookUp = this.compare(this.rosConstitutionalLookUp);
      this.rosConstitutionalCopyLookUp = this.rosConstitutionalLookUp;

      this.rosEarsNoseMouthThroatLookUp = res.filter(x => x.group_txt == 'rosEarsNoseMouthThroat' && x.active_ind == true);
      this.rosEarsNoseMouthThroatLookUp = this.compare(this.rosEarsNoseMouthThroatLookUp);
      this.rosEarsNoseMouthThroatCopyLookUp = this.rosEarsNoseMouthThroatLookUp;

      this.rosNeurologicalLookUp = res.filter(x => x.group_txt == 'rosNeurological' && x.active_ind == true);
      this.rosNeurologicalLookUp = this.compare(this.rosNeurologicalLookUp);
      this.rosNeurologicalCopyLookUp = this.rosNeurologicalLookUp;

      this.rosRespiratoryLookUp = res.filter(x => x.group_txt == 'rosRespiratory' && x.active_ind == true);
      this.rosRespiratoryLookUp = this.compare(this.rosRespiratoryLookUp);
      this.rosRespiratoryCopyLookUp = this.rosRespiratoryLookUp;

      this.rosCardiovascularLookUp = res.filter(x => x.group_txt == 'rosCardiovascular' && x.active_ind == true);
      this.rosCardiovascularLookUp = this.compare(this.rosCardiovascularLookUp);
      this.rosCardiovascularCopyLookUp = this.rosCardiovascularLookUp;

      this.rosGastrointestinalLookUp = res.filter(x => x.group_txt == 'rosGastrointestinal' && x.active_ind == true);
      this.rosGastrointestinalLookUp = this.compare(this.rosGastrointestinalLookUp);
      this.rosGastrointestinalCopyLookUp = this.rosGastrointestinalLookUp;

      this.rosEyesLookUp = res.filter(x => x.group_txt == 'rosEyes' && x.active_ind == true);
      this.rosEyesLookUp = this.compare(this.rosEyesLookUp);
      this.rosEyesCopyLookUp = this.rosEyesLookUp;

      this.rosGenitourinaryLookUp = res.filter(x => x.group_txt == 'rosGenitourinary' && x.active_ind == true);
      this.rosGenitourinaryLookUp = this.compare(this.rosGenitourinaryLookUp);
      this.rosGenitourinaryCopyLookUp = this.rosGenitourinaryLookUp;

      this.rosPsychiatricLookUp = res.filter(x => x.group_txt == 'rosPsychiatric' && x.active_ind == true);
      this.rosPsychiatricLookUp = this.compare(this.rosPsychiatricLookUp);
      this.rosPsychiatricCopyLookUp = this.rosPsychiatricLookUp;

      this.rosBonesLookUp = res.filter(x => x.group_txt == 'rosBones' && x.active_ind == true);
      this.rosBonesLookUp = this.compare(this.rosBonesLookUp);
      this.rosBonesCopyLookUp = this.rosBonesLookUp;

      this.rosAllergicLookUp = res.filter(x => x.group_txt == 'rosAllergic' && x.active_ind == true);
      this.rosAllergicLookUp = this.compare(this.rosAllergicLookUp);
      this.rosAllergicCopyLookUp = this.rosAllergicLookUp;

      this.rosLymphaticLookUp = res.filter(x => x.group_txt == 'rosLymphatic' && x.active_ind == true);
      this.rosLymphaticLookUp = this.compare(this.rosLymphaticLookUp);
      this.rosLymphaticCopyLookUp = this.rosLymphaticLookUp;

      this.rosEndocrineLookUp = res.filter(x => x.group_txt == 'rosEndocrine' && x.active_ind == true);
      this.rosEndocrineLookUp = this.compare(this.rosEndocrineLookUp);
      this.rosEndocrineCopyLookUp = this.rosEndocrineLookUp;



      this.genericLookupCopy = res.filter(x => x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname');
      this.phrxdetailLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxdetail'.toLowerCase() && x.active_ind == true);
      this.phrxdetailLookUp = this.phrxdetailLookUp.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.phrxdetailCopyLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxdetail'.toLowerCase());
      this.phrxtypeLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxtype'.toLowerCase() && x.active_ind == true);
      this.phrxtypeLookUp = this.phrxtypeLookUp.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.phrxtypeCopyLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxtype'.toLowerCase());
      this.phrxtypeLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phallergyname'.toLowerCase() && x.active_ind == true);
      this.phrxtypeLookUp = this.phrxtypeLookUp.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.phrxtypeCopyLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phallergyname'.toLowerCase());
      this.strengthLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxstrength'.toLowerCase() && x.active_ind == true);
      this.strengthLookup = this.strengthLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.strengthLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxstrength'.toLowerCase());
      this.doseLookup = res.filter(x => x.group_txt.toLowerCase() == 'dose'.toLowerCase() && x.active_ind == true);
      this.doseLookup = this.doseLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.doseLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'dose'.toLowerCase());
      // this.rxRouteLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase() && x.active_ind == true);
      // this.rxRouteLookup = this.rxRouteLookup.sort((a, b) => {
      //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
      //     return -1
      //   }
      //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
      //     return 1
      //   }
      //   return 0
      // });
      this.rxRouteLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase());
      this.rxfrequency = res.filter(x => x.group_txt.toLowerCase() == 'rxfrequency'.toLowerCase() && x.active_ind == true);
      this.rxfrequency = this.rxfrequency.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.rxfrequencyCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxfrequency'.toLowerCase());
      this.rxNumberLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxnumber'.toLowerCase() && x.active_ind == true);
      this.rxNumberLookup = this.rxNumberLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.rxNumberLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxnumber'.toLowerCase());
      this.refilLookup = res.filter(x => x.group_txt.toLowerCase() == 'refill'.toLowerCase() && x.active_ind == true);
      this.refilLookup = this.refilLookup.sort((a, b) => {
        if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1
        }
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1
        }
        return 0
      });
      this.refilLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'refill'.toLowerCase());
      this.showComponent = true;
    })
  }

  keyFunc(event: any,item1?: any, property?: any) {
    if(event.key != "Tab"){
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any, name_txt: string, item: LookupModel[], group_txt?: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {

    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = 'All';
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }
      if(dbType != 1 ){
        var content = 'Would you like to save this ' + name_txt + ' list?'
        this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
          if (res.res) {
            // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
            if (dbType == DbEnum.QE_DEV_SQL_DB) {
              this._lookupService.insertLookup(lookupModel).subscribe(res => {

                this.getdataAllLookup();
             //   this.getAllLookup();
                this.toastr.success('Record Saved Successfully.');
                item1[property]= val;//this.check_value;

              })
            }
            //else {
              // this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
              //   this.toastr.success('Record Saved Successfully.');
                //item1[property]=this.check_value;
                // this.getAllLookup();
               // this.getdataAllLookup();

              // })
            //}
          }
        })
      }
      item1[property]= val;
    } else {
      item1[property] = item[index]?.name_txt;
    }
  }
  this.check_value = "";
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {

    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      if (dbType == DbEnum.QE_DEV_SQL_DB) {

        this.getdataAllLookup();
      }
      else {
        this.getAllLookup();
      }
      // this.sortArray(item)
    })
  }

  sortArray(item: LookupModel[]) {
    item.sort((a, b) => {
      if (a.index < b.index) {
        return -1
      }
      if (a.index > b.index) {
        return 1
      }
      return 0
    })
  }
  public rowClasses = {
    highlightedRow: (row: RowType) => this.selectpatient === this.selectpatient
  };

  setdata(params: any) {
    this.Blindness_array = [];
    this.Cataract_array = [];
    this.Glaucoma_array = [];
    this.LazyEyes_array = [];
    this.Cancer_array = [];
    this.RetinalDetachment_array = [];
    this.MacularDegeneration_array = [];
    this.RetinalDisease_array = [];
    this.Arthrits_array = [];
    this.Cancer_array = [];
    this.Diabetes_array = [];
    this.HeartDisease_array = [];
    this.HighBlood_Pressure_Array = [];
    this.Kidney_Pressure_Array = [];
    this.Lupus_Array = [];
    this.ThyroidDisease_Array = [];

    this.ConstitutionalRoModel = [];
    this.IntegumentaryRoModel = [];
    this.NeurologicalRoModel = [];
    this.EyesRoModel = [];
    this.PsychiatricRoModel = [];
    this.AllergicRoModel = [];
    this.EarsRoModel = [];
    this.VascularRoModel = [];
    this.RespiratoryRoModel = [];
    this.GastrointestinalRoModel = [];
    this.GenitourinaryRoModel = [];
    this.BonesRoModel = [];
    this.LymphaticRoModel = [];
    this.EndocrineRoModel = [];
    this.patientReviewModel = params.data;
    this.selectpatient = params.data.patient_review_id;
    this.doctoredate = params.data.doctorReviewedDate;
    this.allergyList = params.data.allergy;
    debugger;
    this.medicationList = this.patientReviewModel.medicalrx;
    this.immunizationList = this.patientReviewModel.immunization;
    // this.Other_Array=this.patientReviewModel.familyHistories;
    //this.disableContent(this.mainContent.nativeElement);
    this.disablePage();



    if (this.patientReviewModel.familyHistoryAdditionals != null) {
      this.patientReviewModel.familyHistoryAdditionals.forEach((element: FamilyHistoryAdditionalModel) => {
        if (element.condition_txt == 'Blindness') {
          this.Blindness_array.push(element)
        }
        else if (element.condition_txt == 'Glaucoma') {
          this.Glaucoma_array.push(element)
        }
        else if (element.condition_txt == 'Lazy Eyes') {
          this.LazyEyes_array.push(element)
        }
        else if (element.condition_txt == 'Macular Degeneration') {
          this.MacularDegeneration_array.push(element)
        }
        else if (element.condition_txt == 'Retinal Detachment') {
          this.RetinalDetachment_array.push(element)
        }
        else if (element.condition_txt == 'Retinal Disease') {
          this.RetinalDisease_array.push(element)
        }
        else if (element.condition_txt == 'Arthrits') {
          this.Arthrits_array.push(element)
        }
        else if (element.condition_txt == 'Cancer') {
          this.Cancer_array.push(element)
        }
        else if (element.condition_txt == 'Diabetes') {
          this.Diabetes_array.push(element)
        }
        else if (element.condition_txt == 'Heart Disease') {
          this.HeartDisease_array.push(element)
        }
        else if (element.condition_txt == 'High Blood Pressure') {
          this.HighBlood_Pressure_Array.push(element)
        }
        else if (element.condition_txt == 'Kidney Blood Pressure') {
          this.Kidney_Pressure_Array.push(element)
        }
        else if (element.condition_txt == 'Lupus') {
          this.Lupus_Array.push(element)
        }
        else if (element.condition_txt == 'Thyroid Disease') {
          this.ThyroidDisease_Array.push(element)
        }
        else if (element.condition_txt == 'Cataract') {
          this.Cataract_array.push(element)
        }
      });
      this.patientReviewModel.ros.forEach(element => {
        if (element.secName == 'Constitutional') {
          this.ConstitutionalRoModel.push(element)
        }
        else if (element.secName == 'Integumentary') {
          this.IntegumentaryRoModel.push(element)
        }
        else if (element.secName == 'Neurological') {
          this.NeurologicalRoModel.push(element)
        }
        else if (element.secName == 'eye') {
          this.EyesRoModel.push(element)
        }
        else if (element.secName == 'Psychiatric') {
          this.PsychiatricRoModel.push(element)
        }
        else if (element.secName == 'Allergic') {
          this.AllergicRoModel.push(element)
        }
        else if (element.secName == 'Ears') {
          this.EarsRoModel = [];
          this.EarsRoModel.push(element)
        }
        else if (element.secName == 'Respiratory') {
          this.RespiratoryRoModel = [];
          this.RespiratoryRoModel.push(element)
        }
        else if (element.secName == 'Vascular') {
          this.VascularRoModel.push(element)
        }
        else if (element.secName == 'Gastrointestinal') {
          this.GastrointestinalRoModel = [];
          this.GastrointestinalRoModel.push(element)
        }
        else if (element.secName == 'Genitourinary') {
          this.GenitourinaryRoModel.push(element)
        }
        else if (element.secName == 'Bones') {
          this.BonesRoModel.push(element)
        }
        else if (element.secName == 'Lymphatic') {
          this.LymphaticRoModel.push(element)
        }
        else if (element.secName == 'Endocrine') {
          this.EndocrineRoModel.push(element)
        }

      });
      this.onSelectUser(this.patientReviewModel.doctorID);

    }
  }

  CreateNew(city:string){
  }

  addRos(type: string, item: ROModel[]) {
    var roModel: ROModel = new ROModel();
    roModel.secName = type;
    item.push(roModel);
  }

  removeRos(item: ROModel[], index: number) {
    // item.filter(x=>x.secName == secName).splice(index,1);
    item.splice(index, 1);
  }

  onActivate(event:any) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
}

}
