import { PatientService } from 'src/app/services/patient.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { PatientModel } from 'src/app/model/patientModel';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-clinical',
  templateUrl: './clinical.component.html',
  styleUrls: ['./clinical.component.scss']
})
export class ClinicalComponent implements OnInit {
  patientId!: number;
  patient: PatientModel = new PatientModel();
  skipLocationChange:boolean = true;
  age: number = 0;
  constructor(private route: ActivatedRoute, private _patientService: PatientService,private viewportScroller: ViewportScroller) {
    this.route.queryParams.subscribe((params: any) => {
      this.patientId = Number(params['patientId']);
    });
  }

  ngOnInit() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.getPatientData();
  }

  getPatientData() {
    this._patientService.getPatientByPatientId(this.patientId).subscribe((res: any) => {
      this.patient = Object.assign({}, res);
      this.calculateAge(new Date(this.patient.dateOfBirth));
    })
  }

  calculateAge(birthdate: Date) {
    let timeDiff = Math.abs(Date.now() - birthdate.getTime());
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
  }

}
