import { LookupService } from './../../../../services/lookup.service';
import { LookupModel } from './../../../../model/lookupModel';
import { PatientModel } from './../../../../model/patientModel';
import { ExamModel } from './../../../../model/ExamModel';
import { ToastrService } from 'ngx-toastr';
import { Exam_AssessmentsModel } from './../../../../model/Exam_AssessmentsModel';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ClinicalService } from 'src/app/services/clinical.service';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-add-diagnosis',
  templateUrl: './add-diagnosis.component.html',
  styleUrls: ['./add-diagnosis.component.scss']
})
export class AddDiagnosisComponent implements OnInit {

  title: string = '';
  closeBtnName : string = '';
  exam_AssessmentsModel : Exam_AssessmentsModel[] = [];
  examModel : ExamModel[] = [];
  patientDetail : PatientModel = new PatientModel();
  patient_Id : string = '';
  type : LookupModel[] = [];
  assessment : LookupModel[] = [];
  typeCopy : LookupModel[] = [];
  assessmentCopy : LookupModel[] = [];
  showComponent : boolean = false;
  check_value: string = '';

  constructor(public bsModalRef: BsModalRef,private _clinicalService : ClinicalService,private globalService: GlobalService,
    private toastr : ToastrService,private _lookupService : LookupService) {
    this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.patient_Id = this.patientDetail.patient_id;
  }

  ngOnInit() {
    this.exam_AssessmentsModel.push(new Exam_AssessmentsModel());
    this.getExam();
    this.getLookup();
  }

  save(){    
    for(var i=0;i<this.exam_AssessmentsModel.length; i++)
    {
      this.exam_AssessmentsModel[i].patient_id = this.patient_Id;
    }
    this._clinicalService.saveDiagnosis(this.exam_AssessmentsModel).subscribe(res=>{
      this.toastr.success('Record Saved Successfully.');
      this.bsModalRef.hide();
    },(error)=>{
      this.toastr.error(error.error);
    })
  }

  getExam(){
    this._clinicalService.getExam(this.patient_Id).subscribe((res:any)=>{
      this.examModel = res;
      
    })
  }

  getLookup(){
    this._lookupService.getAllLookupData().subscribe((res:LookupModel[])=>{
      this.assessment = res.filter(x=>x.group_txt.toLowerCase() == 'assessment' && x.active_ind == true);
      this.assessment = this.assessment.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.assessmentCopy = res.filter(x=>x.group_txt.toLowerCase() == 'assessment');;
        this.type = res.filter(x=>x.group_txt.toLowerCase() == 'assessmenttype' && x.active_ind == true );
        this.type = this.type.sort((a, b) => {
          if (a.name_txt < b.name_txt) {
            return -1
          }
          if (a.name_txt > b.name_txt) {
            return 1
          }
          return 0
        });
        this.typeCopy = res.filter(x=>x.group_txt.toLowerCase() == 'assessmenttype');;
      this.showComponent = true;
    })
    

    
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   console.log(event);
  // }

  CreateNew(city:string){          
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getLookup();
      // this.sortArray(item)
    }) 
  }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any,  name_txt: string, item: LookupModel[], group_txt?: string,sub_group_txt:string = 'All',item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = sub_group_txt; 
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }

      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {

            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getLookup();
              this.toastr.success('Record Saved Successfully.');
              item1[0][property]= val;//this.check_value;
              
            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toastr.success('Record Saved Successfully.');
          //     item1[0][property]=this.check_value;
          //     this.getLookup();
          //   })
          // }
        }
      })
    }
    item1[0][property] = val;
  } else {
    item1[0][property] = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }

}
