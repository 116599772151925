import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ItemModel } from 'src/app/model/ItemModel';
import { Item_ContactsDataModel } from 'src/app/model/Item_ContactsDataModel';
import { ManufacturerModel } from 'src/app/model/ManufacturerModel';
import { ProcedureModel } from 'src/app/model/ProcedureModel';
import { ChargeModel } from 'src/app/model/chargeModel';
import { LookupModel } from 'src/app/model/lookupModel';
import { ManufacturerService } from 'src/app/services/Manufacturer.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-add-charges',
  templateUrl: './add-charges.component.html',
  styleUrls: ['./add-charges.component.scss']
})
export class AddChargesComponent implements OnInit {

  chargeFormGroup: FormGroup;
  productFormGroup: FormGroup;
  showButton = false;
  enablEditButton = false;
  showProductButton = false;
  enablProductEditButton = false;

  @Input() isProductModel: boolean = false;

  // chargeModel: ChargeModel = new ChargeModel();
  // chargeList: ChargeModel[] = [];
  // charge: ChargeModel[] = [];
  chargeModel: ProcedureModel = new ProcedureModel();
  chargeList: ProcedureModel[] = [];
  charge: ProcedureModel[] = [];
  showInactive : boolean = false;

  modelType: string = "";

  isShowProductModel: boolean = false;
  isShowChargeModel: boolean = false;

  diamLookup: LookupModel[] = [];
  bcLookup: LookupModel[] = [];
  brand: LookupModel = new LookupModel();

  productModel: ItemModel = new ItemModel();
  productList: ItemModel[] = [];
  product: ItemModel[] = [];
  ItemContact: Item_ContactsDataModel = new Item_ContactsDataModel();
  itemContacts: Item_ContactsDataModel[] = [];

  manufacture: ManufacturerModel[] = [];
  manufacture_copy: ManufacturerModel[] = [];

  showInactiveProduct : boolean = false;
  productCharge_amt: number = 0;
  setProduct:string='';
  product_Type = [
    {
    "typeId": 0,
    "typeName": 'Frames'
    },
    {
      "typeId": 1,
      "typeName": 'Contacts'
    },
    {
      "typeId": 2,
      "typeName": 'Spectacle Lenses'
    },
    {
      "typeId": 3,
      "typeName": 'Other'
    }
  ]

  type = [{
    "name": "CONTACT"
  }, {
    "name": "SPECTACLE"
  }, {
    "name": "OTHER"
  }, {
    "name": "ALL"
  }]
  productType: any = [{
    "value": "Reg. Price",
    "key": "Reg. Price"
  }, {
    "value": "Sale. Price",
    "key": "Sale. Price"
  }];

  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private toaster: ToastrService, private _lookupService: LookupService, private _manufactureService: ManufacturerService) {
    this.chargeFormGroup = new FormGroup({});
    this.productFormGroup = new FormGroup({});
   }

  ngOnInit() {

    if(this.isProductModel){
      this.createProductFormGroup();
      this.getAllLookup();
      this.getManufacture();
      this.getAllProducts();
      this.productFormGroup.disable();
    }else{
      this.createFormGroup();
      this.getAllCharge();
      this.chargeFormGroup.disable();
    }

  }

  createFormGroup() {
    this.chargeFormGroup = this.formBuilder.group({
      procedure_id: [''],
      name_txt: ['', Validators.required],
      description_txt: [''],
      default_place_of_service_txt: [''],
      lab_procedure_ind: [],
      taxable_ind: [false],
      charge_amt: [],
      patient_charge_amt: [],
      active_ind: [false],
      code_txt: [],
      global_period_day_cnt: [],
      version_txt: [],
      practice_specific_ind: [false],
      commission_pct: [],
      created_dt: [new Date()],
      created_by: [],
      updated_dt: [new Date()],
      updated_by: []
    })
  }
  createProductFormGroup() {
    this.productFormGroup = this.formBuilder.group({
      item_id: [''],
      type_id: [],
      name_txt: [''],
      description_txt: [''],
      active_ind: [false],
      reg_price_amt: [],
      sales_price_amt: [],
      created_dt: [new Date()],
      created_by: [''],
      updated_dt: [new Date()],
      updated_by: [''],
      taxable_ind: [false],
      code_txt: [''],
      selected_price_txt: [''],
      productCharge_amt: [],
      diameter_txt: [''],
      basecurve_txt: [''],
      manufacturer_id: []
    })
  }

  create() {
    this.showButton = true;
  }
  createProduct() {
    this.showProductButton = true;
  }

  cancel() {
    this.showButton = false;
    if (this.chargeModel.procedure_id == '') {
      this.chargeFormGroup.reset();
    }
    this.chargeFormGroup.disable();
  }
  cancelProduct() {
    this.showProductButton = false;
    if (this.productModel.item_id == '') {
      this.productFormGroup.reset();
    }
    this.productFormGroup.disable();
  }

  edit() {
    this.showButton = true;
    this.chargeFormGroup.enable();
  }
  editProduct() {
    this.showProductButton = true;
    this.productFormGroup.enable();
  }
  getManufacture() {
    debugger;
    this._manufactureService.getAll().subscribe(res => {
      this.manufacture = res;
      this.manufacture = this.manufacture.filter(x => x.active_ind == true);
      this.manufacture = this.compare(this.manufacture);
    })
  }

  getAllCharge() {
    this._lookupService.getProcedure().subscribe((res)=>{
      this.charge = res;
      this.chargeList = Object.assign({}, this.charge);
      this.filter(false);
      this.chargeList = this.compare(this.chargeList);
    })
  }
  getAllProducts() {
    // this._lookupService.getItems().subscribe((res)=>{
    //   this.product = res;
    //   this.productList = Object.assign({}, this.product);
    //   this.filterProduct(false);
    //   this.productList = this.compare(this.productList);
    // })
    this._lookupService.getItemsWithContact().subscribe((res: any) => {
      this.product = res.item1;
      this.productList = Object.assign({}, this.product);
      this.filterProduct(false);
      this.productList = this.compare(this.productList);
      //this.productList = res.item1;
      this.itemContacts = res.item2;
    });
  }

  setForm(charge: ProcedureModel) {
    this.chargeModel = Object.assign({}, charge)
    this.enablEditButton = true;
  }
  setProductForm(product: ItemModel) {
    this.setProduct = product.item_id;
    this.productCharge_amt = product.selected_price_txt == "Reg. Price" ? product.reg_price_amt :  product.sales_price_amt;
    this.productModel = Object.assign({}, product);
    var contact = this.itemContacts.filter((x) => x.item_id == product.item_id);
    var diam = this.diamLookup.filter(x => x.name_txt !== null && x.name_txt.trim().toLocaleLowerCase() == contact[0].diameter_txt.trim().toLocaleLowerCase());
    var bc = this.bcLookup.filter(x => x.name_txt !== null && x.name_txt.trim().toLocaleLowerCase() == contact[0].basecurve_txt.trim().toLocaleLowerCase());
    this.ItemContact.item_id = contact[0].item_id;
    this.ItemContact.diameter_txt = diam.length > 0 ? diam[0].name_txt : '';
    this.ItemContact.basecurve_txt = bc.length > 0 ? bc[0].name_txt : '';
    this.enablProductEditButton = true;
  }

  addNew() {
    debugger;
    this.chargeFormGroup.enable();
    this.chargeFormGroup.reset();
    this.chargeFormGroup.patchValue({
      active_ind: true
    });
    this.chargeModel = new ProcedureModel();
    this.chargeModel.active_ind = true;
    this.showButton = true;
  }
  addNewProduct() {
    debugger;
    this.setProduct = '';
    this.productFormGroup.enable();
    this.productFormGroup.reset();
    this.productFormGroup.patchValue({
      active_ind: true
    });
    this.productModel = new ItemModel();
    this.productModel.active_ind = true;
    this.ItemContact = new Item_ContactsDataModel();
    this.showProductButton = true;
  }

  save() {
    if (this.chargeModel.procedure_id == '') {
      if (this.chargeFormGroup.valid) {
        this._lookupService.insertProcedure(this.chargeModel).subscribe(res => {
          this.getAllCharge();
          this.showButton = false;
          this.enablEditButton = false;
          this.toaster.success('Record Saved Successfully.');
          this.chargeFormGroup.disable();
        }, (error) => {
          this.toaster.error(error.error);
        });
      }

    }
    else {
      if (this.chargeFormGroup.valid) {
        this._lookupService.updateProcedure(this.chargeModel).subscribe(res => {
          this.getAllCharge();
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
        this.showButton = false;
      }
    }
  }
  saveProduct() {
    debugger;
    this.productModel.selected_price_txt == "Reg. Price" ? this.productModel.reg_price_amt = this.productCharge_amt :  this.productModel.sales_price_amt = this.productCharge_amt;
    this.productModel.item_ContactsData = this.ItemContact;
    if (this.productModel.item_id == '') {
      if (this.productFormGroup.valid) {
        this._lookupService.insertItem(this.productModel).subscribe(res => {
          this.mapBrand();
          this._lookupService.insertLookup(this.brand).subscribe((res) => {
            console.log(res);
          });
          this.getAllProducts();
          this.showProductButton = false;
          this.enablProductEditButton = false;
          this.toaster.success('Record Saved Successfully.');
          this.productFormGroup.disable();
        }, (error) => {
          this.toaster.error(error.error);
        });
      }

    }
    else {
      if (this.productFormGroup.valid) {
        this._lookupService.updateItem(this.productModel).subscribe(res => {
          this.getAllProducts();
          this.showProductButton = false;
          this.toaster.success('Record Updated Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
        this.showProductButton = false;
      }
    }
  }
  mapBrand(){
    this.brand.active_ind = true;
    this.brand.name_txt = this.productModel.name_txt;
    this.brand.group_txt = 'brand';
    this.brand.sub_group_txt = 'All';
  }

  filter(active:boolean){
    this.showInactive = active;
    if(!this.showInactive)
    {
      this.chargeList = this.charge.filter(x => x.active_ind == true);
    } else {
      this.chargeList = this.charge.filter(x => x.active_ind == false);
    }
    this.chargeList = this.compare(this.chargeList);
  }
  filterProduct(active:boolean){
    this.showInactiveProduct = active;
    if(!this.showInactiveProduct)
    {
      this.productList = this.product.filter(x => x.active_ind == true);
    } else {
      this.productList = this.product.filter(x => x.active_ind == false);
    }
    this.productList = this.compare(this.productList);
  }

  compare(array: any){
    array.sort((a: { name_txt: { toLowerCase: () => number; }; },b: { name_txt: { toLowerCase: () => number; }; }) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }
  findCharges(event: any){
    var searchString = event.target.value;
    if(searchString == ''){
      if (this.showInactive){
        this.chargeList = this.charge;
      }else{
        this.filter(false);
      }
    }
    else{
      if (this.showInactive){
        this.chargeList = this.charge.filter((item) => {
          if (item.code_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
            return item;
          }
          if (item.name_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
            return item;
          }
          return;
        });
      }else{
        this.chargeList = this.charge.filter(x=> (x.code_txt.toLowerCase().includes(searchString.toLowerCase()) || x.name_txt.toLowerCase().includes(searchString.toLowerCase())) && x.active_ind == true)
      }
    }
    this.chargeList = this.compare(this.chargeList);
  }
  findProducts(event: any){
    var searchString = event.target.value;
    if(searchString == ''){
      if (this.showInactiveProduct){
        this.productList = this.product;
      }else{
        this.filterProduct(false);
      }
    }
    else{
      if (this.showInactiveProduct){
        this.productList = this.product.filter((item) => {
          if (item.code_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
            return item;
          }
          if (item.name_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
            return item;
          }
          return;
        });
      }else{
        this.productList = this.product.filter(x=> (x.code_txt.toLowerCase().includes(searchString.toLowerCase()) || x.name_txt.toLowerCase().includes(searchString.toLowerCase())) && x.active_ind == true)
      }
    }
    this.productList = this.compare(this.productList);
  }
  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.bcLookup = res.filter((x) => x.group_txt.toLowerCase() == 'bc'.toLowerCase() && x.active_ind == true);
      this.bcLookup = this.compare(this.bcLookup);

      this.diamLookup = res.filter((x) => x.group_txt.toLowerCase() == 'diam'.toLowerCase() && x.active_ind == true);
      this.diamLookup = this.compare(this.diamLookup);
    });
  }
}
