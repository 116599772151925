<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button style="height: 68px;"  class="button" routerLink= "/patient/add-exam" [queryParams]="{patientId:patientModel.patientID,examId : examId}">
                <!-- <img src="../../../../assets/img/close.png" style="height: 30px;width: 30px;"><br> -->
                Cancel Exam
            </button> &nbsp;&nbsp;
            <button style="height: 68px;"  class="button" (click)="save(false)">
                 Save Exam
            </button> &nbsp;&nbsp;
            <button style="height: 68px;"  class="button" (click)="save(true)">
                 Save & Close
            </button> &nbsp;&nbsp;
            <button style="height: 68px;"  class="button" (click)="Updatefees()">
                 UpdateFees
            </button> &nbsp;&nbsp;
            <button style="height: 68px;" class="button" [disabled]="examId == ''" [useExistingCss]="true" class="button" printSectionId="print-section-for-hcfa" ngxPrint>
                Print
            </button> &nbsp;&nbsp;
            <hr>
        </div>
    </div>
</div>
<br>
<br>
<br>
<fieldset style="background-color: white;padding:5px !important; font-size: 11px !important;">
    <br>
    <div>
        <h5 class=""><b>HEALTH INSURANCE CLAIM FORM</b></h5>
        <h6>APPROVED BY NATIONAL UNIFORM CLAIM COMITTEE 08/05</h6>
        <div class="row">
            <div class="col-md-1 col-sm-4">
                <label class="p-1">PICA</label>
            </div>

            <div class="col-md-2 col-sm-8">
                <input type="text" [(ngModel)]="omr_HCFAFormModel.picaleft" class="col-md-9 form-control">
            </div>

            <div class="col-md-4"></div>
            <div class="form-row col-md-4 mr-2 float-right">
                <label class="p-1">PICA</label>&nbsp; &nbsp;
                <input type="text" [(ngModel)]="omr_HCFAFormModel.picaright" class="col-md-5 form-control">
            </div>
        </div>
        <table class="table table-bordered mt-4">
            <tr>
                <td colspan="2" class="" style="width:66% !important">
                    <!-- <label class="mx-2">1.</label>
                    <label class="mx-2">MEDICAID</label>
                    <label class="mx-2">TRICARE</label>
                    <label class="mx-2">CHAMPVA</label>
                    <label class="mx-3">GROUP <br> HEALTH PLAN</label>
                    <label class="mx-3">FECA <br> BLK LUNG</label>
                    <label class="mx-3">OTHER</label>
                    <br>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.medicare" class="mx-1" tabindex="1">(Medicare#)</label>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.medicaid" class="mx-1" tabindex="2">(Medicaid#)</label>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.tricarechampus"
                            class="mx-1" tabindex="3">(ID#/DoD#)</label>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.champva" class="mx-1" tabindex="4">(Member ID#)</label>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.healthplan" class="mx-1" tabindex="5">(ID#)</label>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.fecablclung" class="mx-1" tabindex="6">(ID#)</label>
                    <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.other" class="mx-1" tabindex="7">(ID#)</label> -->
                    <table class='table1 table-borderless'>
                        <tr>
                            <td><label class="mx-2">1.MEDICARE</label></td>
                            <td><label class="mx-2">MEDICAID</label></td>
                            <td><label class="mx-2">TRICARE</label></td>
                            <td><label class="mx-2">CHAMPVA</label></td>
                            <td><label class="mx-1">GROUP <br> HEALTH PLAN</label></td>
                            <td><label class="mx-1">FECA <br> BLK LUNG</label></td>
                            <td><label class="mx-1">OTHER</label></td>
                        </tr>
                        <tr>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.medicare" class="mx-1" tabindex="1">(Medicare#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.medicaid" class="mx-1" tabindex="2">(Medicaid#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.tricarechampus" class="mx-1" tabindex="3">(ID#/DoD#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.champva" class="mx-1" tabindex="4">(Member ID#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.healthplan" class="mx-1" tabindex="5">(ID#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.fecablclung" class="mx-1" tabindex="6">(ID#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.other" class="mx-1" tabindex="7">(ID#)</label></td>
                        </tr>
                    </table>

                </td>
                <td style="width:33% !important">
                    1a. INSURED'S I.D. NUMBER
                    <p class="float-right">(For Program in item 1)</p>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insurednumber" class="form-control formControl mt-2" tabindex="14">
                </td>
            </tr>
            <tr>
                <td>
                    2.PATIENT'S NAME(Last Name,First Name,
                    <br>Middle Initial)
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.patientname" class="form-control formControl" tabindex="8">
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <label class="">3. PATIENT'S BIRTH DATE</label>
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3"  [(ngModel)]="month" (keyup)="keytab($event)" tabindex="9">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3"  [(ngModel)]="day"  (keyup)="keytab($event)" tabindex="10">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4"  [(ngModel)]="year" (keyup)="keytab($event)"  tabindex="11">
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <label class="">SEX</label>
                            <div class="pt-3">
                                <label>M<input class="mx-2" type="checkbox" (change)="chkCheckbox('M','PATIENT')"
                                        [(ngModel)]="omr_HCFAFormModel.sexm" tabindex="12"></label>
                                <label>F<input class="mx-2" type="checkbox" (change)="chkCheckbox('F','PATIENT')"
                                        [(ngModel)]="omr_HCFAFormModel.sexf" tabindex="13"></label>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    4.INSURED'S NAME(Last Name,First Name,
                    <br>Middle Initial)
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insuredname" class="form-control formControl" tabindex="15">
                </td>
            </tr>
            <tr>
                <td>
                    <label>5. PATIENT'S ADDRESS(No.,Street)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.patientaddress" class="form-control formControl mt-2" tabindex="16">
                </td>
                <td>
                    <label>6. PATIENT RELATIONSHIP TO INSURED</label>
                    <div class="row justify-content-center pt-2.3">
                        <label class="mx-2">Self<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipself" tabindex="17"></label>
                        <label class="mx-2">Spouse<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipspouse" tabindex="18"></label>
                        <label class="mx-2">Child<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipchild" tabindex="19"></label>
                        <label class="mx-2">Other<input class="mx-2"
                                type="checkbox"  [(ngModel)]="omr_HCFAFormModel.relationshipother" tabindex="20"></label>
                    </div>
                </td>
                <td>
                    <label>7. INSURED'S ADDRESS(No.,Street)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insuredaddress" class="form-control formControl mt-2" tabindex="21">
                </td>
            </tr>
            <tr>
                <td>
                    <div class="row">
                        <div class="col-md-9">
                            <label>CITY</label>
                            <input [(ngModel)]="omr_HCFAFormModel.city" type="text" class="form-control formControl" tabindex="22">
                        </div>
                        <div class="col-md-3 border-left text-center">
                            <label class="">STATE</label>
                            <input [(ngModel)]="omr_HCFAFormModel.state" type="text" class="form-control formControl" tabindex="23">
                        </div>
                    </div>
                </td>
                <td rowspan="2">
                    8. RESERVED FOR NUCC USE
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-9">
                            <label>CITY</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.citY2" class="form-control formControl" tabindex="24">
                        </div>
                        <div class="col-md-3 border-left text-center">
                            <label class="">STATE</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.statE2" class="form-control formControl" tabindex="25">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="row">
                        <div class="col-md-4">
                            <label>ZIP CODE</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.zip"  #zipcode class="form-control formControl mt-4" tabindex="26">
                        </div>
                        <div class="col-md-8 border-left">
                            <label class="">TELEPHONE<br>(Include Area Code)</label>
                            <div class="form-row">
                                <label class="form-row">
                                    <!-- (<input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.telephoneArea"
                                        class="form-control formControl col-md-1 mx-2 " tabindex="27">) -->
                                        <input  style="width: 250px;" class="form-control formControl col-md-6 mx-2" maxlength="14" [(ngModel)]="omr_HCFAFormModel.telephone" tabindex="28"  type="text" phone  ngModel="phone" id="phone-number">
                                </label>

                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-4">
                            <label>ZIP CODE</label>
                            <input type="text" class="form-control formControl mt-4" [(ngModel)]="omr_HCFAFormModel.ziP2" tabindex="29">
                        </div>
                        <div class="col-md-8 border-left">
                            <label class="">TELEPHONE<br>(Include Area Code)</label>
                            <div class="form-row">
                                <label class="form-row">
                                    <!-- (<input type="text"  maxlength="3"  [(ngModel)]="omr_HCFAFormModel.telephoneArea2"
                                        class="form-control formControl col-md-1 mx-2" tabindex="30">) -->

                                    <input  style="width: 250px;" class="form-control formControl col-md-6 mx-2" maxlength="14" [(ngModel)]="omr_HCFAFormModel.telephonE2" tabindex="31"  type="text" phone  ngModel="phone" id="phone-number1">
                                </label>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>9. OTHER INSURED'S NAME (Last Name,<br>First Name,Middle Initial)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.otherinsuredname" class="form-control formControl mt-2" tabindex="32">
                </td>
                <td rowspan="4">
                    <label>10. IS PATIENT'S CONDITION RELATED TO:</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.conditionrelated" class="form-control formControl mt-2" tabindex="33">
                    <label>a. EMPLOYMENT?(Current of Previous)</label>
                    <div class="">
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.employmentyes" class="mx-2" tabindex="36">Yes</label>
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.employmentno" class="mx-2" checked="item.checked" tabindex="37">No</label>
                    </div>
                    <div class="row">
                        <div class="col-md-4 p-0" >
                            <label>b. AUTO ACCIDENT?</label>
                            <div>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.autoaccidentyes"
                                        class="mx-2" tabindex="43">Yes</label>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.autoaccidentnot"
                                        class="mx-2" checked="item.checked" tabindex="44">No</label>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3">
                            <label>PLACE (State)</label>
                            <div class="col-md-6 text-center">
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.placestate" maxlength="2" class="col-md-6 text-center mt-2 form-control formControl" tabindex="45"
                                style="border: 1px solid #000; border-top: none;">
                            </div>
                        </div>
                    </div>
                    <label >c. OTHER ACCIDENT?</label>
                    <div class="">
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.otheraccidentyes"
                                class="mx-2" tabindex="48">Yes</label>
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.otheraccidentnot"
                                class="mx-2" checked="item.checked" tabindex="49">No</label>
                    </div>
                </td>
                <td>
                    <label>11. INSURED'S POLICY GROUP OR FECA NUMBER</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insuredpolicygroup" class="form-control formControl mt-2" tabindex="34">
                </td>

            </tr>
            <tr>
                <td>
                    <label>a. OTHER INSURED'S GROUP OR FECA NUMBER</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.otherinsuredgroup" class="form-control formControl mt-2" tabindex="35">
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <label>a. INSURED'S DATE OF BIRTH</label>
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3" [(ngModel)]="Insuremonth" (keyup)="keytab($event)" tabindex="38">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3"  [(ngModel)]="Insureday" (keyup)="keytab($event)" tabindex="39">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4"  [(ngModel)]="Insureyear" (keyup)="keytab($event)" tabindex="40">
                            </div>
                        </div>
                        <div class="col-md-4 text-center">
                            <label class="">SEX</label>
                            <div class="pt-3">
                                <label>M<input class="mx-2" (change)="chkCheckbox('M','INSURED')" [(ngModel)]="omr_HCFAFormModel.othersexm"
                                        type="checkbox" tabindex="41"></label>
                                <label>F<input class="mx-2" (change)="chkCheckbox('F','INSURED')" [(ngModel)]="omr_HCFAFormModel.othersexf"
                                        type="checkbox" tabindex="42"></label>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>b. RESERVED FOR NUCC USE</label>
                </td>
                <td>
                    <label>b. OTHER CLAIM ID(Designated by NUCC)</label>
                    <div class="form-row form-control formControl ">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.emplnameorschoolname" class="form-control formControl col-md-2" tabindex="46">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.emplnameorschoolnamE2" class="form-control formControl border-left col-md-10" tabindex="47">
                    </div>
                </td>
            </tr>
            <tr>
                <td>c. RESERVED FOR NUCC USE</td>
                <td>
                    <label>c. INSURANCE PLAN NAME OR PROGRAM NAME</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.programname" class="form-control formControl mt-2" tabindex="50">
                </td>
            </tr>
            <tr>
                <td>
                    <label>d. INSURANCE PLAN NAME OR PROGRAM NAME</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.programnamE2" class="form-control formControl mt-2" tabindex="51">
                </td>
                <td>
                    <label>10d. CLAIM CODES(Designated by NUCC)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.reservedlocal" class="form-control formControl mt-2" tabindex="52">
                </td>
                <td>
                    <label>d. IS THERE ANOTHER HEALTH BENEFIT PLAN?</label><!-- <tabindex="53"> -->
                    <div class="form-row">
                        <div class="">
                            <label><input type="checkbox"  [(ngModel)]="omr_HCFAFormModel.isbenefitplanyes"
                                    class="mx-2">Yes</label>
                            <label><input type="checkbox"  [(ngModel)]="omr_HCFAFormModel.isbenefitplanno"
                                    class="mx-2" checked="item.checked">No</label>
                        </div>
                        &nbsp;&nbsp;&nbsp;<div class="">
                            <label>If Yes, complete <br>items 9,9a and 9d.</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="text-center">
                        <label><b>READ BACK OF FORM BEFORE COMPLETING & SIGNING THIS FORM.</b></label>
                    </div>
                    <div>
                        <p>12. PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE I authorize the release of any medical or <br> other information necessary to process this claim. I also request payment of government <br> benefits either to myself or to the party who accept assignment
                            below.
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-md-7 form-row">
                            <label class="">SIGNED</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.signeD1" class="form-control formControl border-bottom col-md-10 mx-1" tabindex="54" value="Signature on File" placeholder="Signature on File">
                        </div>
                        <div class="col-md-5 form-row">
                            <label class="">DATE</label>
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="omr_HCFAFormModel.datesigneD1 = $event" [ngModel]="omr_HCFAFormModel.datesigneD1 | date:'MM-dd-yyyy'" class="form-control formControl border-bottom col-md-10 mx-1" tabindex="55">
                        </div>
                    </div>
                </td>
                <td>
                    <div>
                        <p>
                            13. INSURED'S OR AUTHORIZED PERSON'S SIGNATURE I authorize payment of medical <br> benefits to the undersigned physical or supplier for services described below.
                        </p>
                    </div>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-md-7 form-row">
                            <label class="">SIGNED</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.signeD2" class="form-control formControl border-bottom col-md-8 mx-1" tabindex="56" value="Signature on File" placeholder="Signature on File">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>14. Date OF CURRENT ILLNESS,INJURY, or <br>PREGNANCY(LMP)</label>
                    <div class="row">
                        <div class="col-md-7 p-0">
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3" [(ngModel)]="PREGNANCYmonth" (keyup)="keytab($event)" tabindex="58">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3" [(ngModel)]="PREGNANCYday" (keyup)="keytab($event)" tabindex="59">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4" [(ngModel)]="PREGNANCYyear" (keyup)="keytab($event)" tabindex="60">
                            </div>
                        </div>
                        <div class="col-md-5 p-0">
                            <div class="col-md-6"></div>
                            <div>
                                <label>QUAL.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.iquaL1" class="form-control formControl col-md-8 border-left" tabindex="57">
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <label>15. OTHER DATE</label>
                    <div class="row mt-4">
                        <div class="col-md-4 mt-3 pr-0 form-row">
                            <label>QUAL.</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.iquaL2" class="form-control formControl border-left col-md-5 " tabindex="61">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.iquaL3" class="form-control formControl border-left col-md-4" tabindex="62">
                        </div>
                        <div class="col-md-7 p-0">
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3"  [(ngModel)]="otherinsuredmonth" (keyup)="keytab($event)" tabindex="63">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3"  [(ngModel)]="otherinsuredday" (keyup)="keytab($event)" tabindex="64">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4"  [(ngModel)]="otherinsuredyear" (keyup)="keytab($event)" tabindex="65">
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <label>16. DATES PATIENT UNABLE TO WORK IN <br>CURRENT OCCUPATION</label>
                    <div class="row">
                        <div class="col-md-6 px-3">
                            <label class="ml-5">FROM</label>
                        </div>
                        <div class="col-md-6 px-3">
                            <label class="ml-5">TO</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 p-0">
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0"  [(ngModel)]="workdatefrommonth" (keyup)="keytab($event)" tabindex="66">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0"  [(ngModel)]="workdatefromday"(keyup)="keytab($event)" tabindex="67">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4 p-0"  [(ngModel)]="workdatefromyear"(keyup)="keytab($event)" tabindex="68">
                            </div>
                        </div>
                        <div class="col-md-6 p-0">
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0"  [(ngModel)]="workdatetomonth" (keyup)="keytab($event)" tabindex="69">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0"  [(ngModel)]="workdatetoday" (keyup)="keytab($event)" tabindex="70">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4 p-0"  [(ngModel)]="workdatetoyear" (keyup)="keytab($event)" tabindex="71">
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <p>17. NAME OF REFERRING PROVIDER OR OTHER
                        <br> SOURCE</p>
                    <div class="form-row form-control formControl">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.nameofreferring" class="form-control formControl col-md-3" tabindex="72">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.nameofreferrinG2" class="form-control formControl border-left col-md-8" tabindex="73">
                    </div>
                </td>
                <td>
                    <div class="form-row">
                        <label class="mx-3">17a.</label>
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.fielD17ASHOT" class="form-control formControl col-md-2 border-left" tabindex="74">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.fielD17ALONG" class="form-control formControl col-md-8 border-left" tabindex="75">
                    </div>
                    <hr style="border-top: dotted 1px;" />
                    <div class="form-row">
                        <label class="mx-3">17b.</label>
                        <label class="border-left col-md-2 text-center">NPI</label>
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.fielD17BNPI" class="mr-1 form-control formControl col-md-8 border-left" tabindex="76">
                    </div>
                </td>
                <td>
                    <label>18. HOSPITALIZATION DATES RELATED TO CURRENT OCCUPATION</label>
                    <div class="row">
                        <div class="col-md-6 px-3">
                            <label class="ml-5">FROM</label>
                        </div>
                        <div class="col-md-6 px-3">
                            <label class="ml-5">TO</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 p-0">
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="hospitalworkdatefrommonth" (keyup)="keytab($event)" tabindex="77">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="hospitalworkdatefromday" (keyup)="keytab($event)" tabindex="78">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4 p-0" [(ngModel)]="hospitalworkdatefromyear" (keyup)="keytab($event)" tabindex="79">
                            </div>
                        </div>
                        <div class="col-md-6 p-0">
                            <div class="col-md-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-md-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="hospitalworkdatetomonth" (keyup)="keytab($event)" tabindex="80">
                                <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="hospitalworkdatetoday"  (keyup)="keytab($event)" tabindex="81">
                                <input type="text" maxlength="4" class="form-control formControl col-md-4 p-0" [(ngModel)]="hospitalworkdatetoyear" (keyup)="keytab($event)" tabindex="82">
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <label>19. ADDITIONAL CLAIM INFORMATION(Designated by NUCC)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.reservedlocaL2" class="form-control formControl" tabindex="83">
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-6">
                            <label>20.OUTSIDE LAB?</label>
                        </div>
                        <div class="col-md-6 text-center">
                            <label class="">$CHARGES</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 pt-2">
                            <label><input [(ngModel)]="omr_HCFAFormModel.outsidelabyes" type="checkbox"
                                    class="mx-2" tabindex="84">YES</label>
                            <label><input [(ngModel)]="omr_HCFAFormModel.outsidelabno" type="checkbox"
                                    class="mx-2" checked="item.checked" tabindex="85">NO</label>
                        </div>
                        <div class="col-md-6 form-row">
                            <!-- <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirst" class="form-control formControl col-md-6 border-left" tabindex="86">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargessecond" class="form-control formControl col-md-6 border-left" tabindex="87"> -->

                            <div class="col-md-6 p-0 border-left" style="text-align-last: end;">
                                <input [(ngModel)]="omr_HCFAFormModel.chargesfirst" placeholder="00" type="text"  class="form-control formControl col-md-12"  tabindex="86">
                            </div>
                            <div class="col-md-6 border-left p-0">
                                <input [(ngModel)]="omr_HCFAFormModel.chargessecond" placeholder="00" type="text" class="form-control formControl col-md-12"  tabindex="87">
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" rowspan="2">
                    <div class="row">
                        <div class="col-md-8 p-0">
                            <label>21. DIAGNOSIS OR NATURE OF ILLNESS OR INJURY Relate A-L to service line below (24E)
                            </label>
                        </div>
                        <div class="col-md-4">
                            <div class="form-row">
                                <label>ICD Ind.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.icD1" style="text-align: end;" class="form-control formControl col-md-3" tabindex="88">
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.icD2" style="text-align: end;"  class="form-control formControl col-md-4 border-left" tabindex="89">
                            </div>
                        </div>
                    </div>
                    <div class="row pt-4 label-width">
                        <div class="col-md-3">
                            <div class="form-row">
                                <label>A.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE1" class="form-control formControl col-md-10 inputBoder" tabindex="90">
                            </div>
                            <div class="form-row">
                                <label>E.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE5" class="form-control formControl col-md-10 inputBoder" tabindex="94">
                            </div>
                            <div class="form-row">
                                <label>I.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE9" class="form-control formControl col-md-10 inputBoder" tabindex="98">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-row">
                                <label>B.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE2" class="form-control formControl col-md-10 inputBoder" tabindex="91">
                            </div>
                            <div class="form-row">
                                <label>F.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE6" class="form-control formControl col-md-10 inputBoder" tabindex="95">
                            </div>
                            <div class="form-row">
                                <label>J.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE10" class="form-control formControl col-md-10 inputBoder" tabindex="99">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-row">
                                <label>C.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE3" class="form-control formControl col-md-10 inputBoder" tabindex="92">
                            </div>
                            <div class="form-row">
                                <label>G.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE7" class="form-control formControl col-md-10 inputBoder" tabindex="96">
                            </div>
                            <div class="form-row">
                                <label>K.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE11" class="form-control formControl col-md-10 inputBoder" tabindex="100">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-row">
                                <label>D.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE4" class="form-control formControl col-md-10 inputBoder" tabindex="93">
                            </div>
                            <div class="form-row">
                                <label>H.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE8" class="form-control formControl col-md-10 inputBoder" tabindex="97">
                            </div>
                            <div class="form-row">
                                <label>L.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE12" class="form-control formControl col-md-10 inputBoder" tabindex="101">
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <label>22. RESUBMISSION</label>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="justify-content-center">CODE</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.medicaidcode" class="form-control formControl" tabindex="102">
                        </div>
                        <div class="col-md-6">
                            <label class="justify-content-center">ORIGINAL REF.NO</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.medicaidoroginalno" class="border-left form-control formControl" tabindex="103">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>23. PRIOR AUTHORIZATION NUMBER</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.priornumber" class="form-control formControl" tabindex="104">
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <label>24. A. DATE(S) OF SERVICE</label>
                            </div>
                            <div class="row pt-4">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                        <label class="pt-1">FROM</label>
                                    </div>
                                    <div>
                                        <label>MM</label>
                                        <label class="mx-1">DD</label>
                                        <label class="mx-1">YY</label>
                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                        <label class="justify-content-center">TO</label>
                                    </div>
                                    <div>
                                        <label>MM</label>
                                        <label class="mx-1">DD</label>
                                        <label class="mx-1">YY</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <label>B. place of <br> service</label>
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <label>C. EMG</label>
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <label>D. PROCEDURES,SERVICES OR SUPPLIES <br> (explain unusual circumstances) </label>
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <label  class="text-center"  style="margin-right: 18px;">CPT/HCPCS</label>
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <label class="mx-2">Modifier</label>
                                    <!-- <label><label style="padding-left:25px !important;">Modifier</label></label>                                    -->
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <label class="mx-2">DIAGNOSIS  POINTER</label>
                                    <!-- <label class="mx-2">
                                        <label>DIAGNOSIS  POINTER</label>
                                    </label>                                    -->
                                </div>
                            </div>
                            <!-- <div class="col-md-2 p-0">
                                <label  class="text-center"  style="margin-right: 18px;">CPT/HCPCS</label>
                                <label class="mx-2 solid-border-left"><label style="padding-left:25px !important;">Modifier</label></label>
                                <label class="mx-3 solid-border-left"><label style="padding-left:25px !important;">DIAGNOSIS  POINTER</label></label>
                            </div> -->
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <label>F. <br>$CHARGES</label>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <label>G. <br>Days <br> OR<br> UNITS</label>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <label>H. <br> EPSDT <br>Family <br> Plan</label>
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <label style="font-size: 10px !important;">I. <br> ID.<br>QUAL.</label>
                        </div>
                        <div class="col-md-4 solid-border-left p-0">
                            <label>J. <br> RENDERING <br> PROVIDER ID.#</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="MonthA" (keyup)="keytab($event)" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0"  [(ngModel)]="DayA"  (keyup)="keytab($event)" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0"   [(ngModel)]="YearA" (keyup)="keytab($event)" tabindex="107">

                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="MonthATo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="DayATo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="YearATo" tabindex="110">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE1" style="margin-left: 5px !important;" class="form-control formControl col-md-10" tabindex="111">
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG1" class="form-control formControl col-md-12" tabindex="112">
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS1" class="form-control formControl col-md-12" tabindex="113">
                                </div>
                                <div class="row col-md-4 p-0 solid-border-left">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.modifieR1" maxlength="2" class="form-control formControl col-md-3 p-0" tabindex="114">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="115">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="116">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="117">
                                    <!-- <label><label style="padding-left:25px !important;">Modifier</label></label>                                    -->
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR1" class="form-control formControl col-md-12" style="margin-left: 10px !important;" tabindex="118">
                                    <!-- <label class="mx-2">
                                        <label>DIAGNOSIS  POINTER</label>
                                    </label>                                    -->
                                </div>
                            </div>
                            <!-- <div class="col-md-2 p-0">
                                <label  class="text-center"  style="margin-right: 18px;">CPT/HCPCS</label>
                                <label class="mx-2 solid-border-left"><label style="padding-left:25px !important;">Modifier</label></label>
                                <label class="mx-3 solid-border-left"><label style="padding-left:25px !important;">DIAGNOSIS  POINTER</label></label>
                            </div> -->
                        </div>

                        <!-- <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2  p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthA" (keyup)="keytab($event)" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0"  [(ngModel)]="DayA"  (keyup)="keytab($event)" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0"   [(ngModel)]="YearA" (keyup)="keytab($event)" tabindex="107">
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 solid-border-left">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="MonthATo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="DayATo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="YearATo" tabindex="110">
                                    </div>
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE1" class="form-control formControl col-md-12" tabindex="111">
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG1" class="form-control formControl col-md-12" tabindex="112">
                                </div>
                                <div class="col-md-4 solid-border-left">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS1" class="form-control formControl col-md-12" tabindex="113">
                                        </div>
                                        <div class="col-md-4 col-12 p-0 m-0 solid-border-left  form-row">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.modifieR1" maxlength="2" class="form-control formControl col-md-3 p-0" tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="117">
                                        </div>
                                        <div class="col-md-4 solid-border-left">
                                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR1" class="form-control formControl col-md-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-4 solid-border-left p-0">
                            <div class="row p-0">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-6 p-0 " style="margin-left: -14px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT1" placeholder="00" (change)="onChargeAdded();" type="text"  class="form-control formControl col-md-12"  tabindex="119">
                                        </div>
                                        <div class="col-md-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD1" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-md-12"  tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 solid-border-left p-0" style="margin-left: -26px;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS1" class="form-control formControl col-md-12" tabindex="121">
                                </div>
                                <div class="col-md-2 solid-border-left p-0">
                                    <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT1" class="form-control formControl col-md-12" tabindex="122">
                                </div>
                                <div class="col-md-1 solid-border-left p-0">
                                    <div>
                                        <input type="text" maxlength="3"[(ngModel)]="omr_HCFAFormModel.quaL1" class="form-control formControl col-md-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center pt-2">
                                        <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                                    </div>
                                </div>
                                <div class="col-md-4 solid-border-left my-0 p-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup"
                                                class="formControl"
                                                (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG1')"
                                                (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG1,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG1')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_HCFAFormModel.renderinG1">
                                                </ng-select>
                                            </div>
                                            <div class="col-2">
                                                <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px ;"></a>
                                            </div>
                                    </div>
                                    <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup"
                                            class="formControl  asadraza"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI1')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI1,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI1')" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_HCFAFormModel.renderingnpI1">
                                            </ng-select>
                                        </div>

                                        <div class="col-lg-2 b-3">
                                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px;" height="10px"></a>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-md-6 p-0" style="text-align-last: end;">
                                    <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT1" placeholder="00" (change)="onChargeAdded();" type="text"  class="form-control formControl col-md-12"  tabindex="119">
                                </div>
                                <div class="col-md-6 border-left p-0">
                                    <input [(ngModel)]="omr_HCFAFormModel.chargesseconD1" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-md-12"  tabindex="120">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS1" class="form-control formControl col-md-12" tabindex="121">
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT1" class="form-control formControl col-md-12" tabindex="122">
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <div>
                                <input type="text" maxlength="3"[(ngModel)]="omr_HCFAFormModel.quaL1" class="form-control formControl col-md-12" tabindex="123">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left my-0 p-0">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup"
                                        class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG1')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG1,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG1')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_HCFAFormModel.renderinG1">
                                        </ng-select>
                                    </div>
                                    <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px ;"></a>
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup"
                                    class="formControl  asadraza"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI1')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI1,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI1')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI1">
                                    </ng-select>
                                </div>

                                <div class="col-lg-2 b-3">
                                    <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px;" height="10px"></a>
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <!-- <td colspan="3" class="py-0">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthB" (keyup)="keytab($event)" tabindex="126">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="DayB" (keyup)="keytab($event)" tabindex="127">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0"  [(ngModel)]="YearB" (keyup)="keytab($event)" tabindex="128">
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 solid-border-left">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthBTo" (focus)='onFocusB()'  (keyup)="keytab($event)" tabindex="129">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="DayBTo" (focus)='onFocusB()'  (keyup)="keytab($event)" tabindex="130">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" [(ngModel)]="YearBTo" (focus)='onFocusB()'   (keyup)="keytab($event)" tabindex="131">
                                    </div>
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE2" class="form-control formControl col-md-12" tabindex="132">
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG2" class="form-control formControl col-md-12" tabindex="133">
                                </div>
                                <div class="col-md-4 solid-border-left">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS2" class="form-control formControl col-md-12" tabindex="134">
                                        </div>
                                        <div class="col-md-4 col-12 p-0 m-0 solid-border-left  form-row">
                                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR2" class="form-control formControl col-md-3 p-0" tabindex="135">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="136">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="137">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="138">
                                        </div>
                                        <div class="col-md-4 solid-border-left">
                                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR2" class="form-control formControl col-md-12" tabindex="139">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left p-0">
                            <div class="row p-0">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-6 p-0" style="margin-left: -14px; text-align-last: end;">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT2" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="140">
                                        </div>
                                        <div class="col-md-6 border-left p-0">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD2" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="141">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 solid-border-left p-0" style="margin-left: -26px;">
                                    <input type="text" class="form-control formControl col-md-12" [(ngModel)]="omr_HCFAFormModel.unitS2" tabindex="142">
                                </div>
                                <div class="col-md-2 solid-border-left p-0">
                                    <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT2" class="form-control formControl col-md-12" tabindex="143">
                                </div>
                                <div class="col-md-1 solid-border-left p-0">
                                    <div>
                                        <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.quaL2" class="form-control formControl col-md-12" tabindex="144">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center pt-2">
                                        <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                                    </div>
                                </div>
                                <div class="col-md-4 solid-border-left my-0 p-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                                (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG2')"
                                                (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG2,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG2')" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_HCFAFormModel.renderinG2">
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-2 b-3">
                                                <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px ;" ></a>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI2')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI2,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI2')" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_HCFAFormModel.renderingnpI2">
                                            </ng-select>
                                        </div>
                                        <div class="col-lg-2">
                                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"   style="margin-left:15px ;"  height="10px"></a>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td> -->
                <td colspan="2" class="py-0">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthB" (keyup)="keytab($event)" tabindex="126">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="DayB" (keyup)="keytab($event)" tabindex="127">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0"  [(ngModel)]="YearB" (keyup)="keytab($event)" tabindex="128">
                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthBTo" (focus)='onFocusB()'  (keyup)="keytab($event)" tabindex="129">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="DayBTo" (focus)='onFocusB()'  (keyup)="keytab($event)" tabindex="130">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" [(ngModel)]="YearBTo" (focus)='onFocusB()'   (keyup)="keytab($event)" tabindex="131">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE2" style="margin-left: 5px !important;" class="form-control formControl col-md-12" tabindex="132">
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG2" class="form-control formControl col-md-12" tabindex="133">
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS2" class="form-control formControl col-md-12" tabindex="134">
                                </div>
                                <div class="row col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR2" class="form-control formControl col-md-3 p-0" tabindex="135">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="136">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="137">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="138">
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR2" style="margin-left: 10px !important;" class="form-control formControl col-md-12" tabindex="139">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-md-6 p-0 " style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT2" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="140">
                                </div>
                                <div class="col-md-6 border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD2" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="141">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" class="form-control formControl col-md-12" [(ngModel)]="omr_HCFAFormModel.unitS2" tabindex="142">
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT2" class="form-control formControl col-md-12" tabindex="143">
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <div>
                                <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.quaL2" class="form-control formControl col-md-12" tabindex="144">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left my-0 p-0">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG2')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG2,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG2')" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_HCFAFormModel.renderinG2">
                                            </ng-select>
                                    </div>
                                    <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px ;" ></a>
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI2')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI2,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI2')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI2">
                                    </ng-select>
                                </div>

                                <div class="col-lg-2 b-3">
                                    <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"   style="margin-left:15px ;"  height="10px"></a>
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <!-- <td colspan="3" class="py-0">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthC"  tabindex="147">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayC"  tabindex="148">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearC"  tabindex="149">
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 solid-border-left">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthCTo" (focus)='onFocusC()'  (keyup)="keytab($event)" tabindex="150">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayCTo" (focus)='onFocusC()' tabindex="151">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearCTo" (focus)='onFocusC()' tabindex="152">
                                    </div>
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE3" class="form-control formControl col-md-12" tabindex="153">
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG3" class="form-control formControl col-md-12" tabindex="154">
                                </div>
                                <div class="col-md-4 solid-border-left">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS3" class="form-control formControl col-md-12" tabindex="155">
                                        </div>
                                        <div class="col-md-4 col-12 p-0 m-0 solid-border-left  form-row">
                                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR3" class="form-control formControl col-md-3 p-0" tabindex="156">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="157">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="158">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="159">
                                        </div>
                                        <div class="col-md-4 solid-border-left">
                                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR3"class="form-control formControl col-md-12" tabindex="160">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left p-0">
                            <div class="row p-0">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-6 p-0 " style="margin-left: -14px; text-align-last: end;">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT3" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="161">
                                        </div>
                                        <div class="col-md-6 border-left p-0">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD3" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="162">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 solid-border-left p-0" style="margin-left: -26px;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS3" class="form-control formControl col-md-12" tabindex="163">
                                </div>
                                <div class="col-md-2 solid-border-left p-0">
                                    <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT3" class="form-control formControl col-md-12" tabindex="164">
                                </div>
                                <div class="col-md-1 solid-border-left p-0">
                                    <div>
                                        <input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.quaL3" class="form-control formControl col-md-12" tabindex="165">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-1" />
                                    <div class="text-center pt-2">
                                        <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                                    </div>
                                </div>
                                <div class="col-md-4 solid-border-left my-0 p-0">
                                    <div class="form-row">
                                            <div class="col-10">
                                                <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                                (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG3')"
                                                (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG3,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG3')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderinG3">
                                                </ng-select>
                                            </div>
                                            <div class="col-1">
                                                <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px ;"></a>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 4px; width:100%;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI3')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI3,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI3')" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_HCFAFormModel.renderingnpI3">
                                            </ng-select>
                                        </div>
                                        <div class="col-lg-2 b-3">
                                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px ;" height="10px"></a>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td> -->
                <td colspan="2" class="py-0">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthC"  tabindex="147">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayC"  tabindex="148">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearC"  tabindex="149">
                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthCTo" (focus)='onFocusC()'  (keyup)="keytab($event)" tabindex="150">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayCTo" (focus)='onFocusC()' tabindex="151">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearCTo" (focus)='onFocusC()' tabindex="152">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE3" style="margin-left: 5px !important;" class="form-control formControl col-md-12" tabindex="153">
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG3" class="form-control formControl col-md-12" tabindex="154">
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS3" class="form-control formControl col-md-12" tabindex="155">
                                </div>
                                <div class="row col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR3" class="form-control formControl col-md-3 p-0" tabindex="156">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="157">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="158">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="159">
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR3" style="margin-left: 10px !important;" class="form-control formControl col-md-12" tabindex="160">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-md-6 p-0 " style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT3" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="161">
                                </div>
                                <div class="col-md-6 border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD3" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="162">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS3" class="form-control formControl col-md-12" tabindex="163">
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT3" class="form-control formControl col-md-12" tabindex="164">
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <div>
                                <input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.quaL3" class="form-control formControl col-md-12" tabindex="165">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left my-0 p-0">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG3')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG3,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG3')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderinG3">
                                        </ng-select>
                                    </div>
                                    <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px ;"></a>
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI3')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI3,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI3')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI3">
                                    </ng-select>
                                </div>

                                <div class="col-lg-2 b-3">
                                    <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px ;" height="10px"></a>
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <!-- <td colspan="3" class="py-0">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthD" (keyup)="keytab($event)" tabindex="168">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="DayD" (keyup)="keytab($event)" tabindex="169">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" [(ngModel)]="YearD" (keyup)="keytab($event)" tabindex="170">
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 solid-border-left">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="MonthDTo" tabindex="171">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="DayDTo" tabindex="172">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="YearDTo" tabindex="173">
                                    </div>
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.placeservicE4" class="form-control formControl col-md-12" tabindex="174">
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.emG4" class="form-control formControl col-md-12" tabindex="175">
                                </div>
                                <div class="col-md-4 solid-border-left">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text"  [(ngModel)]="omr_HCFAFormModel.cpthcpcS4" class="form-control formControl col-md-12" tabindex="176">
                                        </div>
                                        <div class="col-md-4 col-12 p-0 m-0 solid-border-left  form-row">
                                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.modifieR4" class="form-control formControl col-md-3 p-0" tabindex="177">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="178">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="179">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="180">
                                        </div>
                                        <div class="col-md-4 solid-border-left">
                                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR4" class="form-control formControl col-md-12" tabindex="181">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left p-0">
                            <div class="row p-0">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-6 p-0 " style="margin-left: -14px; text-align-last: end;">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT4" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="182">
                                        </div>
                                        <div class="col-md-6 border-left p-0">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD4" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="183">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 solid-border-left p-0" style="margin-left: -26px;">
                                    <input type="text"  [(ngModel)]="omr_HCFAFormModel.unitS4" class="form-control formControl col-md-12" tabindex="184">
                                </div>
                                <div class="col-md-2 solid-border-left p-0">
                                    <input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.epsdT4" class="form-control formControl col-md-12" tabindex="185">
                                </div>
                                <div class="col-md-1 solid-border-left p-0">
                                    <div>
                                        <input type="text"  [(ngModel)]="omr_HCFAFormModel.quaL4" maxlength="3" class="form-control formControl col-md-12" tabindex="186">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center pt-2">
                                        <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                                    </div>
                                </div>
                                <div class="col-md-4 solid-border-left my-0 p-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                                (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG4')"
                                                (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG4,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG4')" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_HCFAFormModel.renderinG4">
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px;"></a>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI4')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI4,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI4')" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_HCFAFormModel.renderingnpI4">
                                            </ng-select>
                                        </div>
                                        <div class="col-lg-2 b-3">
                                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px;" height="10px"></a>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td> -->
                <td colspan="2" class="py-0">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" [(ngModel)]="MonthD" (keyup)="keytab($event)" tabindex="168">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" [(ngModel)]="DayD" (keyup)="keytab($event)" tabindex="169">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" [(ngModel)]="YearD" (keyup)="keytab($event)" tabindex="170">
                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="MonthDTo" tabindex="171">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="DayDTo" tabindex="172">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="YearDTo" tabindex="173">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.placeservicE4" style="margin-left: 5px !important;" class="form-control formControl col-md-12" tabindex="174">
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.emG4" class="form-control formControl col-md-12" tabindex="175">
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <input type="text"  [(ngModel)]="omr_HCFAFormModel.cpthcpcS4" class="form-control formControl col-md-12" tabindex="176">
                                </div>
                                <div class="row col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.modifieR4" class="form-control formControl col-md-3 p-0" tabindex="177">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="178">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="179">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="180">
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR4" style="margin-left: 10px !important;" class="form-control formControl col-md-12" tabindex="181">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-md-6 p-0 " style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT4" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="182">
                                </div>
                                <div class="col-md-6 border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD4" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="183">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text"  [(ngModel)]="omr_HCFAFormModel.unitS4" class="form-control formControl col-md-12" tabindex="184">
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.epsdT4" class="form-control formControl col-md-12" tabindex="185">
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <div>
                                <input type="text"  [(ngModel)]="omr_HCFAFormModel.quaL4" maxlength="3" class="form-control formControl col-md-12" tabindex="186">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left my-0 p-0">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG4')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG4,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG4')" bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_HCFAFormModel.renderinG4">
                                        </ng-select>
                                    </div>
                                    <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px;"></a>
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI4')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI4,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI4')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI4">
                                    </ng-select>
                                </div>

                                <div class="col-lg-2 b-3">
                                    <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px;" height="10px"></a>
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <!-- <td colspan="3" class="py-0">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthE" tabindex="189">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayE" tabindex="190">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearE" tabindex="191">
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 solid-border-left">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="MonthETo" tabindex="192">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="DayETo" tabindex="193">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="YearETo" tabindex="194">
                                    </div>
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE5" class="form-control formControl col-md-12" tabindex="195">
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG5" class="form-control formControl col-md-12" tabindex="196">
                                </div>
                                <div class="col-md-4 solid-border-left">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS5" class="form-control formControl col-md-12" tabindex="197">
                                        </div>
                                        <div class="col-md-4 col-12 p-0 m-0 solid-border-left  form-row">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.modifieR5" maxlength="2" class="form-control formControl col-md-3 p-0" tabindex="198">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="199">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="200">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="201">
                                        </div>
                                        <div class="col-md-4 solid-border-left">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR5" maxlength="2" class="form-control formControl col-md-12" tabindex="202">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left p-0">
                            <div class="row p-0">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-6 p-0 " style="margin-left: -14px; text-align-last: end;">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT5" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="203">
                                        </div>
                                        <div class="col-md-6 border-left p-0">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD5" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="204">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 solid-border-left p-0" style="margin-left: -26px;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS5" class="form-control formControl col-md-12" tabindex="205">
                                </div>
                                <div class="col-md-2 solid-border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.epsdT5" maxlength="3"  class="form-control formControl col-md-12" tabindex="206">
                                </div>
                                <div class="col-md-1 solid-border-left p-0">
                                    <div>
                                        <input type="text" [(ngModel)]="omr_HCFAFormModel.quaL5" maxlength="3" class="form-control formControl col-md-12" tabindex="207">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center pt-2">
                                        <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                                    </div>
                                </div>
                                <div class="col-md-4 solid-border-left my-0 p-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                                (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG5')"
                                                (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG5,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG5')" bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_HCFAFormModel.renderinG5">
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-2">
                                                <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px;"></a>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI5')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI5,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI5')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderingnpI5">
                                            </ng-select>
                                        </div>
                                        <div class="col-lg-2">
                                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px;" height="10px"></a>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td> -->
                <td colspan="2" class="py-0">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthE" tabindex="189">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayE" tabindex="190">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearE" tabindex="191">
                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="MonthETo" tabindex="192">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="DayETo" tabindex="193">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="YearETo" tabindex="194">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE5" style="margin-left: 5px !important;" class="form-control formControl col-md-12" tabindex="195">
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG5" class="form-control formControl col-md-12" tabindex="196">
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS5" class="form-control formControl col-md-12" tabindex="197">
                                </div>
                                <div class="row col-md-4 p-0 solid-border-left">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.modifieR5" maxlength="2" class="form-control formControl col-md-3 p-0" tabindex="198">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="199">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="200">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="201">
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR5" maxlength="2" style="margin-left: 10px !important;" class="form-control formControl col-md-12" tabindex="202">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-md-6 p-0 " style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT5" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="203">
                                </div>
                                <div class="col-md-6 border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD5" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="204">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS5" class="form-control formControl col-md-12" tabindex="205">
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.epsdT5" maxlength="3"  class="form-control formControl col-md-12" tabindex="206">
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <div>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.quaL5" maxlength="3" class="form-control formControl col-md-12" tabindex="207">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left my-0 p-0">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG5')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG5,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG5')" bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_HCFAFormModel.renderinG5">
                                        </ng-select>
                                    </div>
                                    <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px;"></a>
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI5')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI5,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI5')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderingnpI5">
                                    </ng-select>
                                </div>

                                <div class="col-lg-2 b-3">
                                    <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png"  style="margin-left:15px;" height="10px"></a>
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <!-- <td colspan="3" class="py-0">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthF" tabindex="210">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayF" tabindex="211">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearF" tabindex="212">
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 solid-border-left">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="MonthFTo"  tabindex="213">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="DayFTo"  tabindex="214">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="YearFTo"  tabindex="215">
                                    </div>
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text"  maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE6" class="form-control formControl col-md-12" tabindex="216">
                                </div>
                                <div class="col-md-1 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG6" class="form-control formControl col-md-12" tabindex="217">
                                </div>
                                <div class="col-md-4 solid-border-left">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS6" class="form-control formControl col-md-12" tabindex="218">
                                        </div>
                                        <div class="col-md-4 col-12 p-0 m-0 solid-border-left form-row">
                                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR6" class="form-control formControl col-md-3 p-0" tabindex="219">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="220">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="221">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="222">
                                        </div>
                                        <div class="col-md-4 solid-border-left">
                                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR6" class="form-control formControl col-md-12" tabindex="223">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left p-0">
                            <div class="row p-0">
                                <div class="col-md-3">
                                    <div class="row">
                                        <div class="col-md-6 p-0 " style="margin-left: -14px; text-align-last: end;">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT6" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="224">
                                        </div>
                                        <div class="col-md-6 border-left p-0">
                                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD6" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="225">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 solid-border-left p-0" style="margin-left: -26px;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS6" class="form-control formControl col-md-12" tabindex="226">
                                </div>
                                <div class="col-md-2 solid-border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.epsdT6" maxlength="3"  class="form-control formControl col-md-12" tabindex="227">
                                </div>
                                <div class="col-md-1 solid-border-left p-0">
                                    <div>
                                        <input type="text" [(ngModel)]="omr_HCFAFormModel.quaL6" maxlength="3" class="form-control formControl col-md-12" tabindex="228">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center pt-2">
                                        <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                                    </div>
                                </div>
                                <div class="col-md-4 solid-border-left my-0 p-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                                (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG6')"
                                                (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG6,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG6')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderinG6">
                                                </ng-select>
                                            </div>
                                            <div class="col-lg-2 b-3">
                                                <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px;"></a>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI6')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI6,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI6')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderingnpI6">
                                            </ng-select>
                                        </div>
                                        <div class="col-lg-2 b-3">
                                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" style="margin-left:15px;" height="10px"></a>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td> -->
                <td colspan="2" class="py-0">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-md-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthF" tabindex="210">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayF" tabindex="211">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="YearF" tabindex="212">
                                    </div>
                                </div>
                                <div class="col-md-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="MonthFTo"  tabindex="213">
                                        <input type="text" maxlength="2" class="form-control formControl col-md-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="DayFTo"  tabindex="214">
                                        <input type="text" maxlength="4" class="form-control formControl col-md-6 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="YearFTo"  tabindex="215">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text"  maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE6" style="margin-left: 5px !important;" class="form-control formControl col-md-12" tabindex="216">
                        </div>
                        <div class="col-md-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG6" class="form-control formControl col-md-12" tabindex="217">
                        </div>
                        <div class="col-md-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-md-12 row p-0">
                                <div class="col-md-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS6" class="form-control formControl col-md-12" tabindex="218">
                                </div>
                                <div class="row col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR6" class="form-control formControl col-md-3 p-0" tabindex="219">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-md-3 p-0" tabindex="220">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="221">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-md-3 p-0" tabindex="222">
                                </div>
                                <div class="col-md-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR6" style="margin-left: 10px !important;" class="form-control formControl col-md-12" tabindex="223">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-md-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-md-6 p-0 " style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT6" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="224">
                                </div>
                                <div class="col-md-6 border-left p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD6" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-md-12" tabindex="225">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS6" class="form-control formControl col-md-12" tabindex="226">
                        </div>
                        <div class="col-md-2 solid-border-left p-0">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.epsdT6" maxlength="3"  class="form-control formControl col-md-12" tabindex="227">
                        </div>
                        <div class="col-md-1 solid-border-left p-0">
                            <div>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.quaL6" maxlength="3" class="form-control formControl col-md-12" tabindex="228">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-md-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left my-0 p-0">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG6')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG6,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG6')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderinG6">
                                        </ng-select>
                                    </div>
                                    <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(Renderinglookup,Renderinglookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left:15px;"></a>
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI6')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI6,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI6')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderingnpI6">
                                    </ng-select>
                                </div>
                                <div class="col-lg-2 b-3">
                                    <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" style="margin-left:15px;" height="10px"></a>
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width: 33% !important;">
                    <div class="row">
                        <div class="col-md-8">
                            <label>25. FEDERAL TAX I.D. NUMBER</label>
                        </div>
                        <div class="col-md-2">
                            <label>SSN</label>
                        </div>
                        <div class="col-md-2">
                            <label>EIN</label>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="col-md-6">
                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" class="col-md-12" style="margin-right: 10%;"   [items]="Texilookup"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'federaltaxnumber')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.federaltaxnumber,'FEDERAL TAX I.D. NUMBER',Texilookup,'rxnumbera',omr_HCFAFormModel,'federaltaxnumber')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.federaltaxnumber" >
                            </ng-select>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)" (click)="editLookup(Texilookup,Texilookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" style="margin-left: -35px;"  height="10px"></a>
                        </div>
                        <div class="col-md-2 pt-1">
                            <input type="checkbox" [(ngModel)]="omr_HCFAFormModel.ssncheck" tabindex="232">
                        </div>
                        <div class="col-md-2 pt-1">
                            <input type="checkbox" [(ngModel)]="omr_HCFAFormModel.eincheck" tabindex="234">
                        </div>
                    </div>
                </td>
                <td style="width: 33% !important;">
                    <div class="row">
                        <div class="col-md-5 mx-3">
                            <label>26. PATIENT'S ACCOUNT NO.</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.patientaccountno" class="form-control formControl mt-2" tabindex="235">
                        </div>
                        <div class="col-md-6 border-left">
                            <div>
                                <label>27. ACCEPT ASSIGNMENT?</label>
                            </div>
                            <div>
                                <label><small>(For govt. claims,see back)</small></label>
                            </div>
                            <div>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.acceptassignmentyes" checked class="mx-3" tabindex="236">YES</label>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.acceptassignmentno"  class="mx-3" tabindex="237">NO</label>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-4 ">
                            <label>28. TOTAL CHARGE</label>
                            <div class="form-row">
                                <label class="mt-0">$</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.totalchargefirst" style="text-align-last:end;" class="form-control formControl col-md-7 " tabindex="238">
                                <input type="text"  [(ngModel)]="omr_HCFAFormModel.totalchargesecond" class="form-control formControl col-md-4 border-left" tabindex="239">
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left">
                            <label>29. AMOUNT PAID</label>
                            <div class="form-row">
                                <label class="mt-0">$</label>
                                <input type="text" class="form-control formControl col-md-7" [(ngModel)]="omr_HCFAFormModel.amountpaidfirst" placeholder="0" style="text-align-last:end;" tabindex="240">
                                <input type="text" class="form-control formControl col-md-4 border-left" [(ngModel)]="omr_HCFAFormModel.amountpaidsecond" placeholder="0" tabindex="241">
                            </div>
                        </div>
                        <div class="col-md-4 solid-border-left">
                            <label>30. Rsvd for NUCC Use</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td style="width: 33% !important;">
                    <label>
                        31. SIGNATURE OF PHYSICIAN OR SUPPLIER INCUDING DEGREES <br> OR CREDENTIALS(I certify that the
                        reverse apply to this bill and <br> are made a part there of.)
                    </label>
                    <div class="row mt-2">
                        <div class="col-md-7 form-row p-0 ml-2">
                            <label class="">SIGNED</label>
                            <input type="text" class="form-control formControl border-bottom col-md-7 mx-1" [(ngModel)]="omr_HCFAFormModel.signeD3" tabindex="242" value="(Sig. On File)"  placeholder="(Sig. On File)">
                        </div>
                        <div class="col-md-5 form-row p-0">
                            <label class="">DATE</label>
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="omr_HCFAFormModel.datesigneD3 = $event" [ngModel]="omr_HCFAFormModel.datesigneD3 | date:'MM-dd-yyyy'" class="form-control formControl border-bottom col-md-7 mx-1" tabindex="243">
                        </div>
                    </div>
                </td>
                <td style="width: 33% !important;">
                    <label>32. SERVICE FACILITY LOCATION
                        <br>INFORMATION</label>

                          <div class="col-md-12">
                            <textarea rows="4" *ngIf="officename" [value]="omr_HCFAFormModel.serviceinfo" class="form-control" tabindex="244" style="margin-left:-15px;padding: 0;" (input)="onValueChange($event)"></textarea>
                        </div>
                    <hr style="border-top: solid 1px;" />
                    <div class="row" style="margin-top: -10px;">
                        <div class="col-md-6 form-row" style="padding-left: 0;align-items: flex-end;">
                            <label class="mx-1">a.</label>
                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoAlookup" class="col-md-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'serviceinfoa')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.serviceinfoa,'a',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'serviceinfoa')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.serviceinfoa">
                            </ng-select>
                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                        <div class="col-md-6 form-row solid-border-left" style="padding-left: 0; align-items: flex-end;padding-right: 0;">
                            <label class="mx-1">b.</label>
                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoBlookup" class="col-md-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'serviceinfob')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.serviceinfob,'b',ServiceInfoBlookup,'ServiceInfoB',omr_HCFAFormModel,'serviceinfob')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.serviceinfob">
                            </ng-select>
                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoBlookup,ServiceInfoBlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </td>
                <td style="width: 33% !important;">
                    <label>33. BILLING PROVIDER INFO</label>
                    <div class="">
                        <label class="form-row"><span class="mx-1">& PH #</span>
                            <!-- <span class=""></span> -->
                            <input type="text" class="form-control formControl col-md-1" tabindex="247" [(ngModel)]="provider_phone_area" style="text-align: right;">
                            <input type="text" class="form-control formControl col-md-6" tabindex="248" [(ngModel)]="provider_phone_number"> </label>
                    </div>

                        <div class="col-md-12">
                          <textarea rows="4" class="form-control" tabindex="249" style="margin-left:-15px;padding: 0;" [value]="omr_HCFAFormModel.providerinfo" (input)="onProviderValueChange($event)"></textarea>
                      </div>
                    <hr style="border-top: solid 1px;" />
                    <div class="row" style="margin-top: -10px;">
                        <div class="col-md-6 form-row" style="align-items: flex-end;">
                            <label class="mx-1">a.</label>
                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoAlookup" class="col-md-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'providerinfoa')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.providerinfoa,'a',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'providerinfoa')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.providerinfoa">
                            </ng-select>
                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoAlookup,ServiceInfoAlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                        <div class="col-md-6 form-row solid-border-left" style="padding-right: 0;align-items: flex-end;">
                            <label class="mx-1">b.</label>
                            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoBlookup" class="col-md-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'providerinfob')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.providerinfob,'b',ServiceInfoBlookup,'ServiceInfoB',omr_HCFAFormModel,'providerinfob')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.providerinfob">
                            </ng-select>
                            <a href="javascript:void(0)" (click)="editLookup(ServiceInfoBlookup,ServiceInfoBlookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="row">
        <div class="col-md-5 float-left">
            <label>
                NUCC Instruction Manual available at: www.nucc.org
            </label>
        </div>
        <div class="col-md-3">
            <label>
                <b>PLEASE PRINT OR TYPE</b>
            </label>
        </div>
        <div class="col-md-4 float-right">
            <label>
                APPROVED OMB 0938-1197 FORM 1500 (02-12)
            </label>
        </div>
    </div>
</fieldset>


<!-- Print Section -->
<div style="font-size: 9px !important;display: none;" class="printclass">
    <div id="print-section-for-hcfa" style="font-size: 6px !important">

        <!-- <div class="col-md-12 text-center">
            <label *ngIf="officename"> {{officename}}</label><br>
            <label *ngIf="officeaddress">{{officeaddress}}</label><br>
            <label *ngIf="officeCitySZ">{{officeCitySZ}}</label><br>
            <label *ngIf="officephone"> {{officephone}}</label>
        </div>

        <div class="col-md-12 text-center">
            License # : {{License}}
        </div>

        <div class="col-md-12 text-center">
            NPI # : {{user?.npi_txt}}
        </div>
        <br>
        <h4 class="text-center"><b>HCFA/eClaims</b></h4>
        <div class="row">
            <div class="col-md-2">
                <label style="font-weight: bold;">Date:</label>
            </div>
            <div class="col-md-4">
                <label>{{currentDate | date}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label style="font-weight: bold;">Patient Name:</label>
            </div>
            <div class="col-md-4">
                <label>{{patientDetail.firstName}} {{patientDetail.lastName}}</label>
            </div>
            <div class="col-md-2">
                <label style="font-weight: bold;">Date of Service:</label>
            </div>
            <div class="col-md-4">
                <label>{{exam.exam_Date | date:'MM-dd-yyyy'}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label style="font-weight: bold;">Birthdate:</label>
            </div>
            <div class="col-md-4">
                <label>{{patientDetail.dateOfBirth | date:'MM-dd-yyyy'}}</label>
            </div>
        </div>


        <br>-->
        <div class="row">
            <div class="col-8">
                <b>HEALTH INSURANCE CLAIM FORM</b><br>
                APPROVED BY NATIONAL UNIFORM CLAIM COMITTEE 08/05
            </div>
            <div class="col-4">
                <input type="text" class="form-control formControl col-md-4 border" style="border: 1px solid #ced4da;border-radius:5px; height: 30px!important;margin-bottom: 5px!important;">
                <input type="text" class="form-control formControl col-md-4 border" style="border: 1px solid #ced4da;border-radius:5px; height: 30px!important;margin-bottom: 5px!important;">
                <input type="text" class="form-control formControl col-md-4 border" style="border: 1px solid #ced4da;border-radius:5px; height: 30px!important;">
            </div>
        </div>
        <br>
        <div class="row" style="font-size: 2px !important">

            <div class="col-1">
                <label class="p-1">PICA</label>
            </div>

            <div class="col-2">
                {{omr_HCFAFormModel.picaleft}}
            </div>

            <div class="col-4"></div>
            <div class="form-row col-4 mr-2 float-right">
                <label class="p-1">PICA</label>&nbsp; &nbsp;
                {{omr_HCFAFormModel.picaright}}
            </div>
        </div>
        <table class="table table-bordered"  style="font-size: 11px !important;margin-top:none!important">
            <tr>
                <td colspan="2" class="" style="width:15% !important">
                    <table class='table1 table-borderless'>
                        <tr>
                            <td><label class="mx-2">1.MEDICARE</label></td>
                            <td><label class="mx-2">MEDICAID</label></td>
                            <td><label class="mx-2">TRICARE</label></td>
                            <td><label class="mx-2">CHAMPVA</label></td>
                            <td><label class="mx-1">GROUP <br> HEALTH PLAN</label></td>
                            <td><label class="mx-1">FECA <br> BLK LUNG</label></td>
                            <td><label class="mx-1">OTHER</label></td>
                        </tr>
                        <tr>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.medicare" class="mx-1" tabindex="1">(Medicare#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.medicaid" class="mx-1" tabindex="2">(Medicaid#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.tricarechampus" class="mx-1" tabindex="3">(ID#/DoD#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.champva" class="mx-1" tabindex="4">(Member ID#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.healthplan" class="mx-1" tabindex="5">(ID#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.fecablclung" class="mx-1" tabindex="6">(ID#)</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.other" class="mx-1" tabindex="7">(ID#)</label></td>
                        </tr>
                    </table>
                </td>
                <td style="width:8% !important">
                    1a. INSURED'S I.D. NUMBER
                    <p class="float-right">(For Program in item 1)</p><br>
                    <p class="mx-2 pt-3">{{omr_HCFAFormModel.insurednumber}}</p>
                </td>
            </tr>
            <tr>
                <td>
                    2.PATIENT'S NAME (Last Name, First Name, Middle Initial)
                    <br><p class="mx-2 pt-3">{{omr_HCFAFormModel.patientname}}</p>
                </td>
                <td>
                    <div class="row" style="font-size: 11px !important">
                        <div class="col-8">
                            <label class="">3. PATIENT'S BIRTH DATE</label>
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="" style="margin-left: 1px !important;margin-right: 1px !important;">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" [(ngModel)]="month" class="form-control formControl col-3" tabindex="9">
                                <input type="text" maxlength="2" style="margin-left: 1px !important;margin-right: 1px !important;" [(ngModel)]="day" class="form-control formControl col-3" tabindex="10">
                                <input type="text" maxlength="4" [(ngModel)]="year" class="form-control formControl col-4" tabindex="11">
                            </div>
                        </div>
                        <div class="col-4 text-center">
                            <label class="">SEX</label>
                            <div class="pt-3">
                                <label>M<input class="mx-2" type="checkbox" (change)="chkCheckbox('M','PATIENT')"
                                        [(ngModel)]="omr_HCFAFormModel.sexm" tabindex="12"></label>
                                <label>F<input class="mx-2" type="checkbox" (change)="chkCheckbox('F','PATIENT')"
                                        [(ngModel)]="omr_HCFAFormModel.sexf" tabindex="13"></label>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    4.INSURED'S NAME(Last Name,First Name,
                    <br>Middle Initial)
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insuredname" class="form-control formControl" tabindex="15">
                </td>
            </tr>
            <tr>
                <td>
                    <label>5. PATIENT'S ADDRESS(No.,Street)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.patientaddress" class="form-control formControl mt-2" tabindex="16">
                </td>
                <td>
                    <label>6. PATIENT RELATIONSHIP TO INSURED</label>
                    <div class="row justify-content-center pt-2.3" style="font-size: 3px !important">
                        <label class="mx-2">Self<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipself" tabindex="17"></label>
                        <label class="mx-2" >Spouse<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipspouse" tabindex="18"></label>
                        <label class="mx-2" >Child<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipchild" tabindex="19"></label>
                        <label class="mx-2" >Other<input class="mx-2"
                                type="checkbox" [(ngModel)]="omr_HCFAFormModel.relationshipother" tabindex="20"></label>
                    </div>
                </td>
                <td>
                    <label>7. INSURED'S ADDRESS(No.,Street)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insuredaddress" class="form-control formControl mt-2" tabindex="21">
                </td>
            </tr>
            <tr>
                <td>
                    <div class="row">
                        <div class="col-9">
                            <label>CITY</label>
                            <input [(ngModel)]="omr_HCFAFormModel.city" type="text" class="form-control formControl" tabindex="22">
                        </div>
                        <div class="col-3 border-left text-center">
                            <label class="">STATE</label>
                            <input [(ngModel)]="omr_HCFAFormModel.state" type="text" class="form-control formControl" tabindex="23">
                        </div>
                    </div>
                </td>
                <td rowspan="2">
                    8. RESERVED FOR NUCC USE
                </td>
                <td>
                    <div class="row">
                        <div class="col-9">
                            <label>CITY</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.citY2" class="form-control formControl" tabindex="24">
                        </div>
                        <div class="col-3 border-left text-center">
                            <label class="">STATE</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.statE2" class="form-control formControl" tabindex="25">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="row">
                        <div class="col-4">
                            <label>ZIP CODE</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.zip"  #zipcode (keyup)="copyvalue(zipcode.value)" class="form-control formControl mt-4" tabindex="26">
                        </div>
                        <div class="col-8 ">
                            <label class="">TELEPHONE<br>(Include Area Code)</label>
                            <div class="form-row">
                                <label class="form-row"><input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.telephone"
                                        class="form-control formControl col-3 mx-2 " tabindex="27" style="width: 250px;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.ziP2"
                                    maxlength="3" class="form-control formControl col-6 mx-2" tabindex="28"></label>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-4">
                            <label>ZIP CODE</label>
                            <input type="text" class="form-control formControl mt-4" [(ngModel)]="omr_HCFAFormModel.ziP2" tabindex="29">
                        </div>
                        <div class="col-8 border-left">
                            <label class="">TELEPHONE<br>(Include Area Code)</label>
                            <div class="form-row">
                                <label class="form-row">(<input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.telephonE2"
                                        class="form-control formControl col-3 mx-2" tabindex="30">)
                                    <input type="text" maxlength="3" class="form-control formControl col-6 mx-2" tabindex="31"></label>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>9. OTHER INSURED'S NAME (Last Name,<br>First Name,Middle Initial)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.otherinsuredname" class="form-control formControl mt-2" tabindex="32">
                </td>
                <td rowspan="4">
                    <label>10. IS PATIENT'S CONDITION RELATED TO:</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.conditionrelated" class="form-control formControl mt-2" tabindex="33">
                    <label>a. EMPLOYMENT?(Current of Previous)</label>
                    <div class="">
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.employmentyes" class="mx-2" tabindex="36">Yes</label>
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.employmentno" class="mx-2" checked="item.checked" tabindex="37">No</label>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label class="">b. AUTO ACCIDENT?</label>
                            <div class="">
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.autoaccidentyes"
                                        class="mx-2" tabindex="43">Yes</label>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.autoaccidentnot"
                                        class="mx-2" checked="item.checked" tabindex="44">No</label>
                            </div>
                        </div>
                        <div class="col-6 mt-3">
                            <label>PLACE (State)</label>
                            <div class="col-6 text-center">
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.placestate" class="col-6 text-center mt-2 form-control formControl" tabindex="45"
                                style="border: 1px solid #000; border-top: none;">
                            </div>
                        </div>
                    </div>
                    <label class="mt-2">c. OTHER ACCIDENT?</label>
                    <div class="">
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.otheraccidentyes"
                                class="mx-2" tabindex="48">Yes</label>
                        <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.otheraccidentnot"
                                class="mx-2" checked="item.checked" tabindex="49">No</label>
                    </div>
                </td>
                <td>
                    <label>11. INSURED'S POLICY GROUP OR FECA NUMBER</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.insuredpolicygroup" class="form-control formControl mt-2" tabindex="34">
                </td>

            </tr>
            <tr>
                <td>
                    <label>a. OTHER INSURED'S GROUP OR FECA NUMBER</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.otherinsuredgroup" class="form-control formControl mt-2" tabindex="35">
                </td>
                <td>
                    <div class="row">
                        <div class="col-8" style="padding-bottom: 1px !important;">
                            <label>a. INSURED'S DATE OF BIRTH</label>
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="Insuremonth" tabindex="38">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="Insureday" tabindex="39">
                                <input type="text" maxlength="4" class="form-control formControl col-4" [(ngModel)]="Insureyear" tabindex="40">
                            </div>
                        </div>
                        <div class="col-4 text-center">
                            <label class="">SEX</label>
                            <div class="pt-3">
                                <label>M<input class="mx-2" (change)="chkCheckbox('M','INSURED')" [(ngModel)]="omr_HCFAFormModel.othersexm"
                                        type="checkbox" tabindex="41"></label>
                                <label>F<input class="mx-2" (change)="chkCheckbox('F','INSURED')" [(ngModel)]="omr_HCFAFormModel.othersexf"
                                        type="checkbox" tabindex="42"></label>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>b. RESERVED FOR NUCC USE</label>
                </td>
                <td style="padding-bottom: 1px solid;">
                    <label>b. OTHER CLAIM ID(Designated by NUCC)</label>
                    <div class="form-row">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.emplnameorschoolname" style="margin-left: 5px !important;" class="form-control formControl col-2" tabindex="46">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.emplnameorschoolnamE2" class="form-control formControl border-left col-9" tabindex="47">
                    </div>
                </td>
            </tr>
            <tr>
                <td>c. RESERVED FOR NUCC USE</td>
                <td>
                    <label>c. INSURENCE PLAN NAME OR PROGRAM NAME</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.programname" class="form-control formControl mt-2" tabindex="50">
                </td>
            </tr>
            <tr>
                <td>
                    <label>d. INSURANCE PLAN NAME OR PROGRAM NAME</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.programnamE2" class="form-control formControl mt-2" tabindex="51">
                </td>
                <td>
                    <label>10d. CLAIM CODES(Designated by NUCC)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.reservedlocal" class="form-control formControl mt-2" tabindex="52">
                </td>
                <td>
                    <label>d. IS THERE ANOTHER HEALTH BENEFIT PLAN?</label>
                    <div class="form-row">
                        <div class="">
                            <label><input type="checkbox"  [(ngModel)]="omr_HCFAFormModel.isbenefitplanyes"
                                    class="mx-2">Yes</label>
                            <label><input type="checkbox"  [(ngModel)]="omr_HCFAFormModel.isbenefitplanno"
                                    class="mx-2" checked="item.checked">No</label>
                        </div>
                        &nbsp;&nbsp;&nbsp;<div class="">
                            <label>If Yes, complete <br>items 9,9a and 9d.</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" >
                    <div class="text-center">
                        <label><b>READ BACK OF FORM BEFORE COMPLETING & SIGNING THIS FORM.</b></label>
                    </div>
                    <div>
                        <p>12. PATIENT'S OR AUTHORIZED PERSON'S SIGNATURE I authorize the release of any medical or other information necessary to process this claim. I also request payment of government benefits either to myself or to the party who accept assignment
                            below.
                        </p>
                    </div>
                    <div class="row" >
                        <div class="col-7 form-row" style="margin-top:-7px;">
                            <label class="">SIGNED</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.signeD1" style="margin-top: -20px;" class="form-control formControl border-bottom col-10 " tabindex="54" value="Signature on File">
                        </div>
                        <div class="col-5 form-row" style="margin-top: -3px;">
                            <label class="">DATE</label>
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" style="margin-top: -20px;" (ngModelChange)="omr_HCFAFormModel.datesigneD1 = $event" [ngModel]="omr_HCFAFormModel.datesigneD1 | date:'MM-dd-yyyy'" class="form-control formControl border-bottom col-10 mx-1" tabindex="55">
                        </div>
                    </div>
                </td>
                <td>
                    <div>
                        <p>
                            13. INSURED'S OR AUTHORIZED PERSON'S SIGNATURE I authorize payment of medical benefits to the undersigned physical or supplier for services described below.
                        </p>
                    </div>

                    <div class="row">
                        <div class="col-7 form-row">
                            <label class="">SIGNED</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.signeD2" style="margin-top: -20px;"  class="form-control formControl border-bottom col-8 mx-1" tabindex="56" value="Signature on File">
                        </div>
                    </div>
                </td>
            </tr>
            <tr style="font-size: 0em !important; width: 5% !important;">
                <td>
                    <label>14.DateOFCURRENTILLNESS,INJURY,PREGNANCY(LMP)</label>
                    <div class="row">
                        <div class="col-7 ">
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="" style="margin-left: 1px !important;margin-right: 1px !important;">DD</label>
                                <label class="">YYYY</label>
                            </div>
                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="PREGNANCYmonth" (keyup)="keytab($event)" tabindex="58">
                                <input type="text" maxlength="2" class="form-control formControl col-3" style="margin-left: 1px !important;margin-right: 1px !important;" [(ngModel)]="PREGNANCYday" (keyup)="keytab($event)" tabindex="59">
                                <input type="text" maxlength="4" class="form-control formControl col-4" [(ngModel)]="PREGNANCYyear" (keyup)="keytab($event)" tabindex="60">
                            </div>
                        </div>
                        <div class="col-5 ">
                            <div class="col-6"></div>
                            <div>
                                <label>QUAL.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.iquaL1" class="form-control formControl col-8 border-left" tabindex="57">
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <label>15. OTHER DATE</label>
                    <div class="row ">
                        <div class="col-5 form-row">
                            <label>QUAL.</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.iquaL2" class="form-control formControl border-left col-4" tabindex="61">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.iquaL3" class="form-control formControl border-left col-4" tabindex="62">
                        </div>
                        <div class="col-7 ">
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="" style="margin-left: 1px !important;margin-right: 1px !important;">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="otherinsuredmonth"  tabindex="63">
                                <input type="text" maxlength="2" class="form-control formControl col-3" style="margin-left: 1px !important;margin-right: 1px !important;" [(ngModel)]="otherinsuredday"   tabindex="64">
                                <input type="text" maxlength="4" class="form-control formControl col-4" [(ngModel)]="otherinsuredyear" tabindex="65">
                            </div>
                        </div>
                    </div>
                </td>
                <td style="padding-bottom: 1px !important;">
                    <label>16.DATESPATIENTUNABLETOWORKINCURRENTOCCUPATION</label>
                    <div class="row">
                        <div class="col-6 ">
                            <label class="">FROM</label>
                        </div>
                        <div class="col-6 ">
                            <label class="">TO</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 ">
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="" style="margin-left: 1px !important;margin-right: 1px !important;">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3"  [(ngModel)]="workdatefrommonth" tabindex="66">
                                <input type="text" maxlength="2" class="form-control formControl col-3" style="margin-left: 1px !important;margin-right: 1px !important;"  [(ngModel)]="workdatefromday" tabindex="67">
                                <input type="text" maxlength="4" class="form-control formControl col-4" [(ngModel)]="workdatefromyear" tabindex="68">
                            </div>
                        </div>
                        <div class="col-6 ">
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="" style="margin-left: 1px !important;margin-right: 1px !important;">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="workdatetomonth" tabindex="69">
                                <input type="text" maxlength="2" class="form-control formControl col-3" style="margin-left: 1px !important;margin-right: 1px !important;" [(ngModel)]="workdatetoday" tabindex="70">
                                <input type="text" maxlength="4" class="form-control formControl col-4" [(ngModel)]="workdatetoyear" tabindex="71">
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr style="width: 5% !important;">
                <td>
                    <p>17. NAMEOFREFERRINGPROVIDEROROTHERSOURCE</p>
                    <div class="form-row form-control formControl">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.nameofreferring" class="form-control formControl col-3" tabindex="72">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.nameofreferrinG2" class="form-control formControl border-left col-8" tabindex="73">
                    </div>
                </td>
                <td style="width: 5% !important;padding-top: 0.5px !important; ">
                    <div class="form-row" style="margin-right: 2px;">
                        <label class="mx-3">17a.</label>
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.fielD17ASHOT" class="form-control formControl col-2 border-left" tabindex="74">
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.fielD17ALONG" class="form-control formControl col-8 border-left" tabindex="75">
                    </div>
                    <hr style="border-top: dotted 1px;" />
                    <div class="form-row" style="margin-right: 2px;">
                        <label class="mx-3">17b.</label>
                        <label class="border-left col-2 text-center">NPI</label>
                        <input type="text" [(ngModel)]="omr_HCFAFormModel.fielD17BNPI" class="form-control formControl col-8 border-left" tabindex="76">
                    </div>
                </td>
                <td style="padding-left: 1px !important;padding-bottom: 0.5px !important;">
                    <label>18.HOSPITALIZATIONDATESRELATEDTOCURRENTOCCUPATION</label>
                    <div class="row">
                        <div class="col-6 ">
                            <label class="">FROM</label>
                        </div>
                        <div class="col-6 ">
                            <label class="">TO</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 ">
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="" style="margin-left: 1px !important;margin-right: 1px !important;">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="hospitalworkdatefrommonth"  tabindex="77">
                                <input type="text" maxlength="2" class="form-control formControl col-3" style="margin-left: 1px !important;margin-right: 1px !important;" [(ngModel)]="hospitalworkdatefromday" tabindex="78">
                                <input type="text" maxlength="4" class="form-control formControl col-4" [(ngModel)]="hospitalworkdatefromyear" tabindex="79">
                            </div>
                        </div>
                        <div class="col-6 ">
                            <div class="col-6 date">
                                <label class="">MM</label>
                                <label class="">DD</label>
                                <label class="">YYYY</label>
                            </div>

                            <div class="col-6 col-12 date">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="hospitalworkdatetomonth" tabindex="80">
                                <input type="text" maxlength="2" class="form-control formControl col-3" [(ngModel)]="hospitalworkdatetoday" tabindex="81">
                                <input type="text" maxlength="4" class="form-control formControl col-4" style="margin-left: 1px !important;" [(ngModel)]="hospitalworkdatetoyear" tabindex="82">
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr style="font-size: 0em !important; width: 5% !important;">
                <td colspan="2" style="width: 5% !important; ">
                    <label>19. ADDITIONAL CLAIM INFORMATION(Designated by NUCC)</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.reservedlocaL2" class="form-control formControl" tabindex="83">
                </td>
                <td style="width: 5% !important; padding-bottom: 1px !important; ">
                    <div class="row">
                        <div class="col-6">
                            <label>20.OUTSIDE LAB?</label>
                        </div>
                        <div class="col-6 text-center">
                            <label class="">$CHARGES</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 ">
                            <label><input [(ngModel)]="omr_HCFAFormModel.outsidelabyes" type="checkbox"
                                    class="mx-1" tabindex="84">YES</label>
                            <label><input [(ngModel)]="omr_HCFAFormModel.outsidelabno" type="checkbox"
                                    class="mx-1" checked="item.checked" tabindex="85">NO</label>
                        </div>
                        <div class="col-6 form-row">
                            <!-- <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirst" class="form-control formControl col-6 border-left" tabindex="86">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.chargessecond" class="form-control formControl col-6 border-left" tabindex="87"> -->
                            <div class="col-6 p-0 border-left" style="text-align-last: end;">
                                <input [(ngModel)]="omr_HCFAFormModel.chargesfirst" placeholder="00" type="text"  class="form-control formControl col-12"  tabindex="86">
                            </div>
                            <div class="col-6 border-left p-0">
                                <input [(ngModel)]="omr_HCFAFormModel.chargessecond" placeholder="00" type="text" class="form-control formControl col-12"  tabindex="87">
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr style="font-size: 0em !important; width: 5% !important;">
                <td colspan="2" rowspan="2" style="padding-top: 0.5px !important;">
                    <div class="row">
                        <div class="col-8 p-0">
                            <label>21. DIAGNOSIS OR NATURE OF ILLNESS OR INJURY Relate A-L to service line below (24E)
                            </label>
                        </div>
                        <div class="col-4">
                            <div class="form-row">
                                <label>ICD Ind.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.icD1" style="text-align: end;" class="form-control formControl col-3" tabindex="88">
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.icD2" style="text-align: end;"  class="form-control formControl col-4 border-left" tabindex="89">
                            </div>
                        </div>
                    </div>
                    <div class="row label-width">
                        <div class="col-3">
                            <div class="form-row">
                                <label>A.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE1" class="form-control formControl col-10 inputBoder" tabindex="90">
                            </div>
                            <div class="form-row">
                                <label>E.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE5" class="form-control formControl col-10 inputBoder" tabindex="94">
                            </div>
                            <div class="form-row">
                                <label>I.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE9" class="form-control formControl col-10 inputBoder" tabindex="98">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-row">
                                <label>B.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE2" class="form-control formControl col-10 inputBoder" tabindex="91">
                            </div>
                            <div class="form-row">
                                <label>F.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE6" class="form-control formControl col-10 inputBoder" tabindex="95">
                            </div>
                            <div class="form-row">
                                <label>J.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE10" class="form-control formControl col-10 inputBoder" tabindex="99">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-row">
                                <label>C.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE3" class="form-control formControl col-10 inputBoder" tabindex="92">
                            </div>
                            <div class="form-row">
                                <label>G.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE7" class="form-control formControl col-10 inputBoder" tabindex="96">
                            </div>
                            <div class="form-row">
                                <label>K.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE11" class="form-control formControl col-10 inputBoder" tabindex="100">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-row">
                                <label>D.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE4" class="form-control formControl col-10 inputBoder" tabindex="93">
                            </div>
                            <div class="form-row">
                                <label>H.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE8" class="form-control formControl col-10 inputBoder" tabindex="97">
                            </div>
                            <div class="form-row">
                                <label>L.</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.relatE12" class="form-control formControl col-10 inputBoder" tabindex="101">
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <label>22. RESUBMISSION</label>
                    <div class="row">
                        <div class="col-6">
                            <label class="justify-content-center">CODE</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.medicaidcode" class="form-control formControl" tabindex="102">
                        </div>
                        <div class="col-6">
                            <label class="justify-content-center">ORIGINAL REF.NO</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.medicaidoroginalno" class="border-left form-control formControl" tabindex="103">
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <label>23. PRIOR AUTHORIZATION NUMBER</label>
                    <input type="text" [(ngModel)]="omr_HCFAFormModel.priornumber" class="form-control formControl" tabindex="104">
                </td>
            </tr>
            <!-- <tr style="font-size: 0em !important;">
                <td colspan="2" class="" >
                    <div class="row">
                        <div class="col-4">
                            <div>
                                <label>24. A. DATE(S) OF SERVICE</label>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="text-center">
                                        <label class="">FROM</label>
                                    </div>
                                    <div>
                                        <label class="">MM</label>
                                        <label class="">DD</label>
                                        <label class="">YY</label>
                                    </div>
                                </div>
                                <div class="col-4 solid-border-left">
                                    <div class="text-center">
                                        <label class="justify-content-center">TO</label>
                                    </div>
                                    <div>
                                        <label>MM&nbsp;DD&nbsp;YY </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 solid-border-left">
                            <label>B. place of service</label>
                        </div>
                        <div class="col-1 solid-border-left">
                            <label>C.EMG</label>
                        </div>
                        <div class="col-5  solid-border-left">
                            <label>D. PROCEDURES,SERVICES OR SUPPLIES(explain unusual circumstances) </label>
                            <div class="col-12 p-0">
                                <label>CPT/HCPCS</label>
                                <label class="mx-2 solid-border-left"><label style="padding-left:25px !important;">Modifier</label></label>
                                <label class="mx-3 solid-border-left"><label style="padding-left:25px !important;">DIAGNOSIS  POINTER</label></label>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="py-0">
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <label>F. <br>$CHARGES</label>
                        </div>
                        <div class="col-2 solid-border-left p-0">
                            <label>G. <br>Days OR<br> UNITS</label>
                        </div>
                        <div class="col-2 solid-border-left p-0">
                            <label>H. <br> EPSDT <br>Family Plan</label>
                        </div>
                        <div class="col-2 solid-border-left p-0">
                            <label>I. <br> ID.<br>QUAL.</label>
                        </div>
                        <div class="col-3 solid-border-left p-0">
                            <label>J. <br> RENDERING <br> PROVIDER ID.#</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <div class="row ">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-2 ">
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthA" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayA" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6"  [(ngModel)]="YearA"  tabindex="107">
                                    </div>
                                </div>
                                <div class="col-2  solid-border-left p-0">
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthATo"  tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayATo"  tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 " [(ngModel)]="YearATo" tabindex="110">
                                    </div>
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="111">
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="112">
                                </div>
                                <div class="col-5 solid-border-left p-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="text" class="form-control formControl col-12" tabindex="113">
                                        </div>
                                        <div class="col-4 col-12  solid-border-left p-0 form-row">
                                            <input type="text" maxlength="2" class="form-control formControl col-3 " tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-3 " tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="117">
                                        </div>
                                        <div class="col-4 solid-border-left p-0">
                                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR1" class="form-control formControl col-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 solid-border-left ">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6 p-0" style="margin-left: -15px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT1"  placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="119">
                                        </div>
                                        <div class="col-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD1" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 solid-border-left p-0" style="margin-left: -20px;">
                                    <input type="text" class="form-control formControl col-12" tabindex="121">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="122">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <div>
                                        <input type="text" class="form-control formControl col-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center ">
                                        <label type="text" class="col-12">NPI</label>
                                    </div>
                                </div>
                                <div class="col-3 solid-border-left my-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <label>{{omr_HCFAFormModel.renderinG1}}</label>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <label>{{omr_HCFAFormModel.renderingnpI1}}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <div class="row ">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-2 ">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthB" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayB" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6"  [(ngModel)]="YearB" tabindex="107">
                                    </div>
                                </div>
                                <div class="col-2  solid-border-left p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthBTo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayBTo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 " [(ngModel)]="YearBTo" tabindex="110">
                                    </div>
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="111">
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="112">
                                </div>
                                <div class="col-5 solid-border-left p-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="text" class="form-control formControl col-12" tabindex="113">
                                        </div>
                                        <div class="col-4 col-12  solid-border-left  form-row">
                                            <input type="text" maxlength="2" class="form-control formControl col-3 " tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-3 " tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="117">
                                        </div>
                                        <div class="col-4 solid-border-left">
                                            <input type="text" maxlength="2"  class="form-control formControl col-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 solid-border-left ">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6 p-0" style="margin-left: -15px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT2"  placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="119">
                                        </div>
                                        <div class="col-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD2" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 solid-border-left p-0" style="margin-left: -20px;">
                                    <input type="text" class="form-control formControl col-12" tabindex="121">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="122">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <div>
                                        <input type="text" class="form-control formControl col-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center">
                                        <label type="text" class="col-12">NPI</label>
                                    </div>
                                </div>
                                <div class="col-3 solid-border-left my-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <label>{{omr_HCFAFormModel.renderinG2}}</label>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <label>{{omr_HCFAFormModel.renderingnpI2}}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <div class="row ">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-2 ">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthC" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayC" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6" [(ngModel)]="YearC" tabindex="107">
                                    </div>
                                </div>
                                <div class="col-2  solid-border-left p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthCTo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayCTo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 " [(ngModel)]="YearCTo" tabindex="110">
                                    </div>
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="111">
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="112">
                                </div>
                                <div class="col-5 solid-border-left p-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="text" class="form-control formControl col-12" tabindex="113">
                                        </div>
                                        <div class="col-4 col-12  solid-border-left  form-row">
                                            <input type="text" maxlength="2" class="form-control formControl col-3 " tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-3 " tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="117">
                                        </div>
                                        <div class="col-4 solid-border-left p-0">
                                            <input type="text" maxlength="2" class="form-control formControl col-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 solid-border-left ">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6 p-0" style="margin-left: -15px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT3"  placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="119">
                                        </div>
                                        <div class="col-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD3" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 solid-border-left p-0" style="margin-left: -20px;">
                                    <input type="text" class="form-control formControl col-12" tabindex="121">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="122">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <div>
                                        <input type="text" class="form-control formControl col-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center">
                                        <label type="text" class="col-12">NPI</label>
                                    </div>
                                </div>
                                <div class="col-3 solid-border-left my-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <label>{{omr_HCFAFormModel.renderinG3}}</label>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <label>{{omr_HCFAFormModel.renderingnpI3}}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <div class="row ">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-2 ">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthD" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayD" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6" [(ngModel)]="YearD" tabindex="107">
                                    </div>
                                </div>
                                <div class="col-2  solid-border-left p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthDTo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayDTo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 " [(ngModel)]="YearDTo" tabindex="110">
                                    </div>
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="111">
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="112">
                                </div>
                                <div class="col-5 solid-border-left p-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="text" class="form-control formControl col-12" tabindex="113">
                                        </div>
                                        <div class="col-4 col-12  solid-border-left p-0 form-row">
                                            <input type="text" maxlength="2" class="form-control formControl col-3 " tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-3 " tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="117">
                                        </div>
                                        <div class="col-4 solid-border-left p-0">
                                            <input type="text" maxlength="2" class="form-control formControl col-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 solid-border-left ">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6 p-0" style="margin-left: -15px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT4"  placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="119">
                                        </div>
                                        <div class="col-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD4" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 solid-border-left p-0" style="margin-left: -20px;">
                                    <input type="text" class="form-control formControl col-12" tabindex="121">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="122">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <div>
                                        <input type="text" class="form-control formControl col-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center">
                                        <label type="text" class="col-12">NPI</label>
                                    </div>
                                </div>
                                <div class="col-3 solid-border-left my-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <label>{{omr_HCFAFormModel.renderinG4}}</label>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <label>{{omr_HCFAFormModel.renderingnpI4}}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <div class="row ">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-2 ">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthE" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayE" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6" [(ngModel)]="YearE" tabindex="107">
                                    </div>
                                </div>
                                <div class="col-2  solid-border-left p-0">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthETo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayETo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 " [(ngModel)]="YearETo"  tabindex="110">
                                    </div>
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="111">
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="112">
                                </div>
                                <div class="col-5 solid-border-left p-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="text" class="form-control formControl col-12" tabindex="113">
                                        </div>
                                        <div class="col-4 col-12  solid-border-left p-0 form-row">
                                            <input type="text" maxlength="2" class="form-control formControl col-3 " tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-3 " tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="117">
                                        </div>
                                        <div class="col-4 solid-border-left p-0">
                                            <input type="text" maxlength="2" class="form-control formControl col-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 solid-border-left ">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6 p-0" style="margin-left: -15px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT5"  placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="119">
                                        </div>
                                        <div class="col-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD5" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 solid-border-left p-0" style="margin-left: -20px;">
                                    <input type="text" class="form-control formControl col-12" tabindex="121">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="122">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <div>
                                        <input type="text" class="form-control formControl col-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="">
                                        <label type="text" class="col-12">NPI</label>
                                    </div>
                                </div>
                                <div class="col-3 solid-border-left my-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <label>{{omr_HCFAFormModel.renderinG5}}</label>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <label>{{omr_HCFAFormModel.renderingnpI5}}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3" class="py-0">
                    <div class="row ">
                        <div class="col-8">
                            <div class="row">
                                <div class="col-2 ">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthF" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayF" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6" [(ngModel)]="YearF" tabindex="107">
                                    </div>
                                </div>
                                <div class="col-2  solid-border-left p-0 ">
                                    <div class="col-12 date">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="MonthFTo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 " [(ngModel)]="DayFTo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 " [(ngModel)]="YearFTo" tabindex="110">
                                    </div>
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="111">
                                </div>
                                <div class="col-1 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="112">
                                </div>
                                <div class="col-5 solid-border-left p-0">
                                    <div class="row">
                                        <div class="col-4">
                                            <input type="text" class="form-control formControl col-12" tabindex="113">
                                        </div>
                                        <div class="col-4 col-12  solid-border-left p-0 form-row">
                                            <input type="text" maxlength="2" class="form-control formControl col-3 " tabindex="114">
                                            <input type="text" maxlength="2" class="form-control formControl border-left col-3 " tabindex="115">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="116">
                                            <input type="text" maxlength="4" class="form-control formControl border-left col-3 " tabindex="117">
                                        </div>
                                        <div class="col-4 solid-border-left p-0">
                                            <input type="text" maxlength="2" class="form-control formControl col-12" tabindex="118">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 solid-border-left ">
                            <div class="row ">
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6 p-0" style="margin-left: -15px; text-align-last: end;">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT6"  placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="119">
                                        </div>
                                        <div class="col-6 border-left p-0">
                                            <input [(ngModel)]="omr_HCFAFormModel.chargesseconD6" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12" tabindex="120">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 solid-border-left p-0" style="margin-left: -20px;">
                                    <input type="text" class="form-control formControl col-12" tabindex="121">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <input type="text" class="form-control formControl col-12" tabindex="122">
                                </div>
                                <div class="col-2 solid-border-left p-0">
                                    <div>
                                        <input type="text" class="form-control formControl col-12" tabindex="123">
                                    </div>
                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="text-center">
                                        <label type="text" class="col-12">NPI</label>
                                    </div>
                                </div>
                                <div class="col-3 solid-border-left my-0">
                                    <div class="form-row">
                                            <div class="col-lg-10">
                                                <label>{{omr_HCFAFormModel.renderinG6}}</label>
                                            </div>
                                    </div>

                                    <hr style="border-top: dotted 1px;" class="my-0" />
                                    <div class="form-row">
                                        <div class="col-lg-10">
                                            <label>{{omr_HCFAFormModel.renderingnpI6}}</label>
                                        </div>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr> -->

            <tr>
                <td colspan="2">
                    <div class="row">
                        <div class="col-4">
                            <div>
                                <label>24. A. DATE(S) OF SERVICE</label>
                            </div>
                            <div class="row pt-4">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                        <label class="pt-1">FROM</label>
                                    </div>
                                    <div style="width: 86px; margin-left: -5px;">
                                        <label>MM</label>
                                        <label class="mx-1">DD</label>
                                        <label class="mx-1">YY</label>
                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                        <label class="justify-content-center">TO</label>
                                    </div>
                                    <div style="width: 86px; margin-left: -5px;">
                                        <label>MM</label>
                                        <label class="mx-1">DD</label>
                                        <label class="mx-1">YY</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <label>B. place of <br> service</label>
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <label>C. EMG</label>
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <label>D. PROCEDURES,SERVICES OR SUPPLIES <br> (explain unusual circumstances) </label>
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <label  class="text-center"  style="margin-right: 18px;">CPT/HCPCS</label>
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <label class="mx-2">Modifier</label>
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <label class="mx-2">DIAGNOSIS  POINTER</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <label>F. <br>$CHARGES</label>
                        </div>
                        <div class="col-2 solid-border-left p-0">
                            <label>G. <br>Days <br> OR<br> UNITS</label>
                        </div>
                        <div class="col-2 solid-border-left p-0">
                            <label>H. <br> EPSDT <br>Family <br> Plan</label>
                        </div>
                        <div class="col-2 solid-border-left p-0">
                            <label style="font-size: 10px !important;">I. <br> ID.<br>QUAL.</label>
                        </div>
                        <div class="col-3 solid-border-left p-0">
                            <label>J. <br> RENDERING <br> PROVIDER ID.#</label>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0" style="padding-top: 1px !important;">
                    <div class="row">
                        <div class="col-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" [(ngModel)]="MonthA" (keyup)="keytab($event)" tabindex="105">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0"  [(ngModel)]="DayA"  (keyup)="keytab($event)" tabindex="106">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" [(ngModel)]="YearA" (keyup)="keytab($event)" tabindex="107">

                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="MonthATo" tabindex="108">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="DayATo" tabindex="109">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" (focus)='onFocusA()' [(ngModel)]="YearATo" tabindex="110">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE1" style="margin-left: 5px !important;" class="form-control formControl col-10" tabindex="111">
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG1" class="form-control formControl col-12" tabindex="112">
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS1" class="form-control formControl col-12" tabindex="113">
                                </div>
                                <div class="row col-4 p-0 solid-border-left">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.modifieR1" maxlength="2" class="form-control formControl col-3 p-0" tabindex="114">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-3 p-0" tabindex="115">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="116">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="117">
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR1" class="form-control formControl col-12" style="margin-left: 10px !important;" tabindex="118">

                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-6 p-0 pt-1 pl-1" style="text-align-last: end;">
                                    <input [(ngModel)]="omr_HCFAFormModel.chargesfirsT1" placeholder="00" (change)="onChargeAdded();" type="text"  class="form-control formControl col-12"  tabindex="119">
                                </div>
                                <div class="col-6 border-left p-0 pt-1">
                                    <input [(ngModel)]="omr_HCFAFormModel.chargesseconD1" placeholder="00" (change)="onChargeAdded();" type="text" class="form-control formControl col-12"  tabindex="120">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS1" class="form-control formControl col-12" tabindex="121">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT1" class="form-control formControl col-12" tabindex="122">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <div>
                                <input type="text" maxlength="3"[(ngModel)]="omr_HCFAFormModel.quaL1" class="form-control formControl col-12" tabindex="123">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-3 solid-border-left my-0 p-0 pt-1 px-1">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup"
                                        class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG1')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG1,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG1')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_HCFAFormModel.renderinG1">
                                        </ng-select> -->
                                        {{omr_HCFAFormModel.renderinG1}}
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup"
                                    class="formControl  asadraza"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI1')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI1,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI1')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI1">
                                    </ng-select> -->
                                    {{omr_HCFAFormModel.renderingnpI1}}
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0" style="padding-top: 1px !important;">
                    <div class="row">
                        <div class="col-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" [(ngModel)]="MonthB" (keyup)="keytab($event)" tabindex="126">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" [(ngModel)]="DayB" (keyup)="keytab($event)" tabindex="127">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;"  [(ngModel)]="YearB" (keyup)="keytab($event)" tabindex="128">
                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" [(ngModel)]="MonthBTo" (focus)='onFocusB()'  (keyup)="keytab($event)" tabindex="129">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" style="margin-left: 1px !important;" [(ngModel)]="DayBTo" (focus)='onFocusB()'  (keyup)="keytab($event)" tabindex="130">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" [(ngModel)]="YearBTo" (focus)='onFocusB()'   (keyup)="keytab($event)" tabindex="131">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE2" style="margin-left: 5px !important;" class="form-control formControl col-12" tabindex="132">
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG2" class="form-control formControl col-12" tabindex="133">
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS2" class="form-control formControl col-12" tabindex="134">
                                </div>
                                <div class="row col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR2" class="form-control formControl col-3 p-0" tabindex="135">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-3 p-0" tabindex="136">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="137">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="138">
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR2" style="margin-left: 10px !important;" class="form-control formControl col-12" tabindex="139">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-6 p-0 pt-1 pl-1" style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT2" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="140">
                                </div>
                                <div class="col-6 border-left p-0 pt-1">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD2" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="141">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" class="form-control formControl col-12" [(ngModel)]="omr_HCFAFormModel.unitS2" tabindex="142">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT2" class="form-control formControl col-12" tabindex="143">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <div>
                                <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.quaL2" class="form-control formControl col-12" tabindex="144">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-3 solid-border-left my-0 p-0 pt-1 px-1">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                            (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG2')"
                                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG2,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG2')" bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_HCFAFormModel.renderinG2">
                                            </ng-select> -->
                                            {{omr_HCFAFormModel.renderinG2}}
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI2')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI2,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI2')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI2">
                                    </ng-select> -->
                                    {{omr_HCFAFormModel.renderingnpI2}}
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0" style="padding-top: 1px !important;">
                    <div class="row">
                        <div class="col-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthC"  tabindex="147">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayC"  tabindex="148">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" [(ngModel)]="YearC"  tabindex="149">
                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" [(ngModel)]="MonthCTo" (focus)='onFocusC()'  (keyup)="keytab($event)" tabindex="150">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" [(ngModel)]="DayCTo" (focus)='onFocusC()' tabindex="151">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" [(ngModel)]="YearCTo" (focus)='onFocusC()' tabindex="152">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE3" style="margin-left: 5px !important;" class="form-control formControl col-12" tabindex="153">
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG3" class="form-control formControl col-12" tabindex="154">
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS3" class="form-control formControl col-12" tabindex="155">
                                </div>
                                <div class="row col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR3" class="form-control formControl col-3 p-0" tabindex="156">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-3 p-0" tabindex="157">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="158">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="159">
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR3" style="margin-left: 10px !important;" class="form-control formControl col-12" tabindex="160">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-6 p-0 pt-1 pl-1" style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT3" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="161">
                                </div>
                                <div class="col-6 border-left p-0 pt-1">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD3" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="162">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS3" class="form-control formControl col-12" tabindex="163">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" maxlength="3" [(ngModel)]="omr_HCFAFormModel.epsdT3" class="form-control formControl col-12" tabindex="164">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <div>
                                <input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.quaL3" class="form-control formControl col-12" tabindex="165">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-3 solid-border-left my-0 p-0 pt-1 px-1">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG3')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG3,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG3')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderinG3">
                                        </ng-select> -->
                                        {{omr_HCFAFormModel.renderinG3}}
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI3')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI3,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI3')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI3">
                                    </ng-select> -->
                                    {{omr_HCFAFormModel.renderingnpI3}}
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0" style="padding-top: 1px !important;">
                    <div class="row">
                        <div class="col-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" [(ngModel)]="MonthD" (keyup)="keytab($event)" tabindex="168">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" [(ngModel)]="DayD" (keyup)="keytab($event)" tabindex="169">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" [(ngModel)]="YearD" (keyup)="keytab($event)" tabindex="170">
                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="MonthDTo" tabindex="171">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="DayDTo" tabindex="172">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" (focus)='onFocusD()' [(ngModel)]="YearDTo" tabindex="173">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.placeservicE4" style="margin-left: 5px !important;" class="form-control formControl col-12" tabindex="174">
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.emG4" class="form-control formControl col-12" tabindex="175">
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <input type="text"  [(ngModel)]="omr_HCFAFormModel.cpthcpcS4" class="form-control formControl col-12" tabindex="176">
                                </div>
                                <div class="row col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.modifieR4" class="form-control formControl col-3 p-0" tabindex="177">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-3 p-0" tabindex="178">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="179">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="180">
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2"  [(ngModel)]="omr_HCFAFormModel.diagnosispointeR4" style="margin-left: 10px !important;" class="form-control formControl col-12" tabindex="181">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-6 p-0 pt-1 pl-1" style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT4" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="182">
                                </div>
                                <div class="col-6 border-left p-0 pt-1">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD4" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="183">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text"  [(ngModel)]="omr_HCFAFormModel.unitS4" class="form-control formControl col-12" tabindex="184">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" maxlength="3"  [(ngModel)]="omr_HCFAFormModel.epsdT4" class="form-control formControl col-12" tabindex="185">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <div>
                                <input type="text"  [(ngModel)]="omr_HCFAFormModel.quaL4" maxlength="3" class="form-control formControl col-12" tabindex="186">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-3 solid-border-left my-0 p-0 pt-1 px-1">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG4')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG4,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG4')" bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_HCFAFormModel.renderinG4">
                                        </ng-select> -->
                                        {{omr_HCFAFormModel.renderinG4}}
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI4')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI4,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI4')" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_HCFAFormModel.renderingnpI4">
                                    </ng-select> -->
                                    {{omr_HCFAFormModel.renderingnpI4}}
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0" style="padding-top: 1px !important;">
                    <div class="row">
                        <div class="col-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthE" tabindex="189">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayE" tabindex="190">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" [(ngModel)]="YearE" tabindex="191">
                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="MonthETo" tabindex="192">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="DayETo" tabindex="193">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" style="margin-left: 1px !important;" (keyup)="keytab($event)" (focus)='onFocusE()' [(ngModel)]="YearETo" tabindex="194">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE5" style="margin-left: 5px !important;" class="form-control formControl col-12" tabindex="195">
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG5" class="form-control formControl col-12" tabindex="196">
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS5" class="form-control formControl col-12" tabindex="197">
                                </div>
                                <div class="row col-4 p-0 solid-border-left">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.modifieR5" maxlength="2" class="form-control formControl col-3 p-0" tabindex="198">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-3 p-0" tabindex="199">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="200">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="201">
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR5" maxlength="2" style="margin-left: 10px !important;" class="form-control formControl col-12" tabindex="202">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-6 p-0 pt-1 pl-1" style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT5" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="203">
                                </div>
                                <div class="col-6 border-left p-0 pt-1">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD5" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="204">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS5" class="form-control formControl col-12" tabindex="205">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.epsdT5" maxlength="3"  class="form-control formControl col-12" tabindex="206">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <div>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.quaL5" maxlength="3" class="form-control formControl col-12" tabindex="207">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-3 solid-border-left my-0 p-0 pt-1 px-1">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG5')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG5,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG5')" bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_HCFAFormModel.renderinG5">
                                        </ng-select> -->
                                        {{omr_HCFAFormModel.renderinG5}}
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI5')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI5,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI5')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderingnpI5">
                                    </ng-select> -->
                                    {{omr_HCFAFormModel.renderingnpI5}}
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="py-0" style="padding-top: 1px !important;">
                    <div class="row">
                        <div class="col-4">
                            <div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="margin-left:3px ;">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" (keyup)="keytab($event)" [(ngModel)]="MonthF" tabindex="210">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" (keyup)="keytab($event)" [(ngModel)]="DayF" tabindex="211">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" (keyup)="keytab($event)" style="margin-left: 1px !important;" [(ngModel)]="YearF" tabindex="212">
                                    </div>
                                </div>
                                <div class="col-4 mx-2 solid-border-left">
                                    <div class="text-center">
                                    </div>
                                    <div class="date" style="width: 86px">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 p-0" (keyup)="keytab($event)" (focus)='onFocusF()' [(ngModel)]="MonthFTo"  tabindex="213">
                                        <input type="text" maxlength="2" class="form-control formControl col-3 mx-1 p-0" (keyup)="keytab($event)" style="margin-left: 1px !important;" (focus)='onFocusF()' [(ngModel)]="DayFTo"  tabindex="214">
                                        <input type="text" maxlength="4" class="form-control formControl col-6 mx-1 p-0" (keyup)="keytab($event)" style="margin-left: 1px !important;" (focus)='onFocusF()' [(ngModel)]="YearFTo"  tabindex="215">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-1 p-0 solid-border-left" style="margin-left:6px;">
                            <input type="text"  maxlength="2" [(ngModel)]="omr_HCFAFormModel.placeservicE6" style="margin-left: 5px !important;" class="form-control formControl col-12" tabindex="216">
                        </div>
                        <div class="col-1 solid-border-left" style="margin-left:-4px ; ">
                            <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.emG6" class="form-control formControl col-12" tabindex="217">
                        </div>
                        <div class="col-4 col-pt-4 solid-border-left" style="margin-left: -2px;">
                            <div class="col-12 row p-0">
                                <div class="col-4 p-0">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.cpthcpcS6" class="form-control formControl col-12" tabindex="218">
                                </div>
                                <div class="row col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.modifieR6" class="form-control formControl col-3 p-0" tabindex="219">
                                    <input type="text" maxlength="2" class="form-control formControl border-left col-3 p-0" tabindex="220">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="221">
                                    <input type="text" maxlength="4" class="form-control formControl border-left col-3 p-0" tabindex="222">
                                </div>
                                <div class="col-4 p-0 solid-border-left">
                                    <input type="text" maxlength="2" [(ngModel)]="omr_HCFAFormModel.diagnosispointeR6" style="margin-left: 10px !important;" class="form-control formControl col-12" tabindex="223">
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row text-center">
                        <div class="col-2 p-0 text-center" style="margin-right:10px !important ;">
                            <div class="row">
                                <div class="col-6 p-0 pt-1 pl-1" style="text-align-last: end;">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesfirsT6" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="224">
                                </div>
                                <div class="col-6 border-left p-0 pt-1">
                                    <input type="text" [(ngModel)]="omr_HCFAFormModel.chargesseconD6" placeholder="00" (change)="onChargeAdded();" class="form-control formControl col-12" tabindex="225">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.unitS6" class="form-control formControl col-12" tabindex="226">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.epsdT6" maxlength="3"  class="form-control formControl col-12" tabindex="227">
                        </div>
                        <div class="col-2 solid-border-left p-0 pt-1 px-1">
                            <div>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.quaL6" maxlength="3" class="form-control formControl col-12" tabindex="228">
                            </div>
                            <hr style="border-top: dotted 1px;" class="my-0" />
                            <div class="text-center pt-2">
                                <label type="text" class="col-12" style="padding: 0;">NPI</label>
                            </div>
                        </div>
                        <div class="col-3 solid-border-left my-0 p-0 pt-1 px-1">
                            <div class="form-row">
                                    <div class="col-lg-10">
                                        <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="Renderinglookup" class="formControl"
                                        (keyup)="keyFunc($event,omr_HCFAFormModel,'renderinG6')"
                                        (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderinG6,'NPI',Renderinglookup,'Rendering',omr_HCFAFormModel,'renderinG6')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderinG6">
                                        </ng-select> -->
                                        {{omr_HCFAFormModel.renderinG6}}
                                    </div>
                            </div>
                            <hr style="border-top: dotted 1px; width:100%;" class="my-0" />
                            <div class="form-row">
                                <div class="col-lg-10">
                                    {{omr_HCFAFormModel.renderingnpI6}}
                                    <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" style="width:123px;" [items]="ServiceInfoAlookup" class="formControl"
                                    (keyup)="keyFunc($event,omr_HCFAFormModel,'renderingnpI6')"
                                    (keydown.tab)="saveDropdown(omr_HCFAFormModel.renderingnpI6,'NPI',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'renderingnpI6')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.renderingnpI6">
                                    </ng-select> -->
                                </div>
                             </div>
                        </div>
                    </div>
                </td>
            </tr>

            <tr style="font-size: 0em  !important;">
                <td style="width: 5% !important; ">
                    <div class="row">
                        <div class="col-8">
                            <label>25. FEDERAL TAX I.D. NUMBER</label>
                        </div>
                        <div class="col-2">
                            <label>SSN</label>
                        </div>
                        <div class="col-2">
                            <label>EIN</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <select class="form-control formControl" tabindex="231">
                            </select>
                        </div>
                        <div class="col-2 pt-1">
                            <input type="checkbox" [(ngModel)]="omr_HCFAFormModel.ssncheck" tabindex="232">
                        </div>
                        <div class="col-2 pt-1">
                            <input type="checkbox" [(ngModel)]="omr_HCFAFormModel.eincheck" checked tabindex="234">
                        </div>
                    </div>
                </td>
                <td style="width: 5% !important;">
                    <div class="row">
                        <div class="col-6">
                            <label>26. PATIENT'S ACCOUNT NO.</label>
                            <input type="text" [(ngModel)]="omr_HCFAFormModel.patientaccountno" class="form-control formControl mt-2" tabindex="235">
                        </div>
                        <div class="col-6 border-left">
                            <div>
                                <label>27.ACCEPTASSIGNMENT?</label>
                            </div>
                            <div>
                                <label><small>(For govt. claims,see back)</small></label>
                            </div>
                            <div>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.acceptassignmentyes" checked class="mx-3" tabindex="236">YES</label>
                                <label><input type="checkbox" [(ngModel)]="omr_HCFAFormModel.acceptassignmentno" class="mx-3" tabindex="237">NO</label>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-4 ">
                            <label>28. TOTAL CHARGE</label>
                            <div class="form-row">
                                <label class="mt-0">$</label>
                                <input type="text" [(ngModel)]="omr_HCFAFormModel.totalchargefirst" style="text-align-last:end;" class="form-control formControl col-7 " tabindex="238">
                                <input type="text"  [(ngModel)]="omr_HCFAFormModel.totalchargesecond" class="form-control formControl col-4 border-left" tabindex="239">
                            </div>
                        </div>
                        <div class="col-4 solid-border-left">
                            <label>29. AMOUNT PAID</label>
                            <div class="form-row">
                                <label class="mt-0">$</label>
                                <input type="text" class="form-control formControl col-7" [(ngModel)]="omr_HCFAFormModel.amountpaidfirst" placeholder="0" style="text-align-last:end;" tabindex="240">
                                <input type="text" class="form-control formControl col-4 border-left" [(ngModel)]="omr_HCFAFormModel.amountpaidsecond" placeholder="0" tabindex="241">
                            </div>
                        </div>
                        <div class="col-4 solid-border-left">
                            <label>30. Rsvd for NUCC Use</label>
                        </div>
                    </div>
                </td>
            </tr>
            <!-- <tr style="font-size: 3px !important; width: 5% !important;">
                <td style="width: 5% !important; ">
                    <label>
                        31. SIGNATURE OF PHYSICIAN OR SUPPLIER INCUDING DEGREES OR CREDENTIALS(I certify that the
                        reverse apply to this bill and  are made a part there of.)
                    </label>
                    <div class="row ">
                        <div class="col-7 form-row ">
                            <label class="">SIGNED</label>
                            <input type="text" class="form-control formControl border-bottom col-7 " tabindex="242" [(ngModel)]="omr_HCFAFormModel.signeD3" value= "(Sig. On File)">
                        </div>
                        <div class="col-5 form-row ">
                            <label class="">DATE</label>
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="omr_HCFAFormModel.datesigneD3 = $event" [ngModel]="omr_HCFAFormModel.datesigneD3 | date:'MM-dd-yyyy'" class="form-control formControl border-bottom col-7 mx-1" tabindex="243">
                        </div>
                    </div>
                </td>
                <td style="width: 5% !important; ">
                    <label>32. SERVICE FACILITY LOCATION INFORMATION</label>
                    <textarea class="form-control formControl textarea " tabindex="244">Default office</textarea>
                    <div class="row" style="border-top: solid 1px;">
                        <div class="col-6 form-row" >
                            <label class="">a.</label>
                            <label>{{omr_HCFAFormModel.serviceinfoa}}</label>
                        </div>
                        <div class="col-6 form-row solid-border-left">
                            <label class="">b.</label>
                            <label>{{omr_HCFAFormModel.serviceinfob}}</label>
                        </div>
                    </div>
                </td>
                <td style="width: 5% !important; ">
                    <label>33. BILLING PROVIDER INFO & PH #(     )</label>
                    <textarea class="form-control formControl textarea" tabindex="249">Default office</textarea>
                    <div class="row" style="border-top: solid 1px;">
                        <div class="col-6 form-row">
                            <label class="">a.</label>
                            <label>{{omr_HCFAFormModel.providerinfoa}}</label>
                        </div>
                        <div class="col-6 form-row solid-border-left">
                            <label class="">b.</label>
                            <label>{{omr_HCFAFormModel.providerinfob}}</label>
                        </div>
                    </div>
                </td>
            </tr> -->
            <tr>
                <td style="width: 33% !important;">
                    <label>
                        31. SIGNATURE OF PHYSICIAN OR SUPPLIER INCUDING DEGREES <br> OR CREDENTIALS(I certify that the
                        reverse apply to this bill and <br> are made a part there of.)
                    </label>
                    <div class="row mt-2">
                        <div class="col-7 form-row p-0 ml-2">
                            <label class="">SIGNED</label>
                            <input type="text" class="form-control formControl border-bottom col-7 mx-1" [(ngModel)]="omr_HCFAFormModel.signeD3" tabindex="242" value="(Sig. On File)"  placeholder="(Sig. On File)">
                        </div>
                        <div class="col-5 form-row p-0">
                            <label class="">DATE</label>
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="omr_HCFAFormModel.datesigneD3 = $event" [ngModel]="omr_HCFAFormModel.datesigneD3 | date:'MM-dd-yyyy'" class="form-control formControl border-bottom col-7 mx-1" tabindex="243">
                        </div>
                    </div>
                </td>
                <td style="width: 33% !important;">
                    <label>32. SERVICE FACILITY LOCATION
                        <br>INFORMATION</label>

                          <div class="col-12">
                            <textarea rows="4" *ngIf="officename" [value]="omr_HCFAFormModel.serviceinfo" class="form-control" tabindex="244" style="margin-left:-15px;padding: 0;" (input)="onValueChange($event)"></textarea>
                        </div>
                    <hr style="border-top: solid 1px;" />
                    <div class="row" style="margin-top: -10px;">
                        <div class="col-6 form-row" style="padding-left: 0;align-items: flex-end;">
                            <label class="mx-1">a.</label>
                            <input type="text" class="form-control formControl col-10" [(ngModel)]="omr_HCFAFormModel.serviceinfoa">
                            <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoAlookup" class="col-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'serviceinfoa')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.serviceinfoa,'a',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'serviceinfoa')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.serviceinfoa">
                            </ng-select> -->

                        </div>
                        <div class="col-6 form-row solid-border-left" style="padding-left: 0; align-items: flex-end;padding-right: 0;">
                            <label class="mx-1">b.</label>
                            <input type="text" class="form-control formControl col-10" [(ngModel)]="omr_HCFAFormModel.serviceinfob">
                            <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoBlookup" class="col-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'serviceinfob')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.serviceinfob,'b',ServiceInfoBlookup,'ServiceInfoB',omr_HCFAFormModel,'serviceinfob')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.serviceinfob">
                            </ng-select> -->

                        </div>
                    </div>
                </td>
                <td style="width: 33% !important;">
                    <label>33. BILLING PROVIDER INFO</label>
                    <div class="">
                        <label class="form-row"><span class="mx-1">& PH #</span>
                            <!-- <span class=""></span> -->
                            <input type="text" class="form-control formControl col-1" tabindex="247" [(ngModel)]="provider_phone_area" style="text-align: right;">
                            <input type="text" class="form-control formControl col-6" tabindex="248" [(ngModel)]="provider_phone_number"> </label>
                    </div>

                        <div class="col-12">
                          <textarea rows="4" class="form-control" tabindex="249" style="margin-left:-15px;padding: 0;" [value]="omr_HCFAFormModel.providerinfo" (input)="onProviderValueChange($event)"></textarea>
                      </div>
                    <hr style="border-top: solid 1px;" />
                    <div class="row" style="margin-top: -10px; padding-bottom: 1px !important;">
                        <div class="col-6 form-row" style="align-items: flex-end;">
                            <label class="mx-1">a.</label>
                            <input type="text" class="form-control formControl col-10" [(ngModel)]="omr_HCFAFormModel.providerinfoa">
                            <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoAlookup" class="col-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'providerinfoa')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.providerinfoa,'a',ServiceInfoAlookup,'ServiceInfoA',omr_HCFAFormModel,'providerinfoa')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.providerinfoa">
                            </ng-select> -->

                        </div>
                        <div class="col-6 form-row solid-border-left" style="padding-right: 0;align-items: flex-end;">
                            <label class="mx-1">b.</label>
                            <input type="text" class="form-control formControl col-10" [(ngModel)]="omr_HCFAFormModel.providerinfob">
                            <!-- <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="ServiceInfoBlookup" class="col-10"
                            (keyup)="keyFunc($event,omr_HCFAFormModel,'providerinfob')"
                            (keydown.tab)="saveDropdown(omr_HCFAFormModel.providerinfob,'b',ServiceInfoBlookup,'ServiceInfoB',omr_HCFAFormModel,'providerinfob')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="omr_HCFAFormModel.providerinfob">
                            </ng-select> -->

                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <div class="row">
            <div class="col-5 float-left">
                <label>
                    NUCC Instruction Manual available at: www.nucc.org
                </label>
            </div>
            <div class="col-3">
                <label>
                    <b>PLEASE PRINT OR TYPE</b>
                </label>
            </div>
            <div class="col-4 float-right">
                <label>
                    APPROVED OMB 0938-1197 FORM 1500 (02-12)
                </label>
            </div>
        </div>
    </div>
</div>
