import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  title: string = '';
  content : string = '';
  saveButton : string = '';
  closeBtnName : string = '';
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  save(){
    this.triggerEvent();
    this.bsModalRef.hide();
  }

  triggerEvent() {
    this.event.emit({ res:true });
  }

}
