import { ExamModel } from "./ExamModel";

export class OMR_ProgressNotesModel {
    public progressNotesID: string = '';
    public exam_ID: string = '';
    public progressDate: Date = new Date();
    public vaodNear: string = '';
    public vaosNear: string = '';
    public vaouNear: string = '';
    public vaodFar: string = '';
    public vaosFar: string = '';
    public vaouFar: string = '';
    public assessmentSelect: string = '';
    public assessment: string = '';
    public planSelect: string = '';
    public plans: string = '';
    public slitLampExaminationSelect: string = '';
    public slitLampExamination: string = '';
    public signature: string = '';
    public signatureCB: boolean = false;
    public patientComments: string = '';
    public creationDate: Date = new Date();
    public lastUpdateDate: Date = new Date();
    public overOD2: string = '';
    public overOS2: string = '';
    public overOU2: string = '';
    public odvaFar2: string = '';
    public osvaFar2: string = '';
    public ouvaFar2: string = '';
    public odvaNear2: string = '';
    public osvaNear2: string = '';
    public ouvaNear2: string = '';
    public gaveCLRX: boolean = false;
    public cleanQHS: boolean = false;
    public cleanQHSText: string = '';
    public orderContacts: boolean = false;
    public osPower: string = '';
    public odPower: string = '';
    public odbc: string = '';
    public osbc: string = '';
    public oddiam: string = '';
    public osdiam: string = '';
    public odBrand: string = '';
    public osBrand: string = '';
    public planNumber: string = '';
    public otherAssessment: string = '';
    public otherPlan: string = '';
    public isArchived: boolean = false;
    public lastUpdatedBy: string = '';
    public doctorID: number = 0;
    public assessment1: string = '';
    public assessment2: string = '';
    public assessment3: string = '';
    public planNumber1: string = '';
    public planNumber2: string = '';
    public planNumber3: string = '';
    public plan1: string = '';
    public plan2: string = '';
    public plan3: string = '';
    public provider_ID: string = '';
    // public exam : ExamModel = new ExamModel();
}