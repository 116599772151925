import { PatientModel } from './../../../../model/patientModel';
import { ToastrService } from 'ngx-toastr';
import { Exam_AssessmentsModel } from './../../../../model/Exam_AssessmentsModel';
import { ProvideResourcesComponent } from './../provide-resources/provide-resources.component';
import { AddDiagnosisComponent } from './../add-diagnosis/add-diagnosis.component';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ClinicalService } from 'src/app/services/clinical.service';
import * as moment from 'moment';

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss']
})
export class DiagnosisComponent implements OnInit {

  columnDiagnosis: any;
  rowDiagnosis: Exam_AssessmentsModel[] = [];
  diagnosisList: Exam_AssessmentsModel[] = [];
  bsModalRef !: BsModalRef;
  filterString: string = 'All';
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  constructor(private modalService: BsModalService, private _clinicalService: ClinicalService,
    private toastr : ToastrService) { 
      this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!);
      this.patientId = this.patientModel.patient_id;
    }

  ngOnInit() {
    // sort: 'desc', 
    this.columnDiagnosis = [
      {
        headerName: 'Date Diagnosed',
        field: 'created_dt', sortable: true, resizable: true, sort: 'desc', rowDrag: true, filter: true, floatingFilter: false,width:150, cellStyle: {'font-size': '11px'},
        cellRenderer: (data:any) => {          
          return moment(data.data.created_dt).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Exam',
        field: 'exam.cheif_Complaint', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
      },
      {
        headerName: 'Type',
        field: 'type_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
      },
      {
        headerName: 'Patient Problem',
        field: 'assessment', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
      },
      {
        headerName: 'Code',
        field: 'ccda_code', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
      },
      {
        headerName: 'Start Date',
        field: 'start_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
        cellRenderer: (data:any) => {
          return moment(data.data.start_dt).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'End Date',
        field: 'end_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
        cellRenderer: (data:any) => {
          return moment(data.data.end_dt).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Source',
        field: 'source_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
      },
      {
        headerName: 'Status',
        field: 'status_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
      },
      {
        headerName: 'Date Modified',
        field: 'updated_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200,
        cellRenderer: (data:any) => {
          return moment(data.data.updated_dt).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Reconcilation',
        field: 'reconciled_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:150,
      },
      {
        headerName: 'More Info',
        field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:100,
      },
    ];
    //this.rowDiagnosis = [];
    this.getDiagnosis();
  }

  addDiagnosis() {
    const initialState = {
      title: 'Create New Exam-Assessment'
    }
    this.bsModalRef = this.modalService.show(AddDiagnosisComponent, { initialState });
    // this.bsModalRef.setClass('modal-xl');
    // this.bsModalRef.setClass('center')
    this.bsModalRef.content.closeBtnName = 'Close';
    this.getDiagnosis();
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getDiagnosis();
    })
  }

  provideResources() {
    const initialState = {
      title: 'Provide Resource'
    }
    this.bsModalRef = this.modalService.show(ProvideResourcesComponent, { initialState });
    this.bsModalRef.setClass('modal-lg');
    // this.bsModalRef.setClass('center')
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  getDiagnosis() {   
    
    this._clinicalService.getByPatientDiagnosis(this.patientId).subscribe((res: any) => {      
      this.diagnosisList = res;
      this.filter();
    });
  }

  filter() {
    
    if (this.filterString != 'All')
      this.rowDiagnosis = this.diagnosisList.filter(x => x.status_txt == this.filterString);
    else
      this.rowDiagnosis = this.diagnosisList;
  }

  save(){
    this._clinicalService.updateDiagnosis(this.rowDiagnosis).subscribe(res=>{
      this.toastr.success('Record Saved Successfully.');
      this.getDiagnosis();
    },(error)=>{
      this.toastr.error(error.error);
    });
  }

}
