<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" *ngIf="showComponent">
    <div id="content" class="scroll">
        <div class="titlebox"></div>
        <table class="table table-bordered">
            <tr>
                <th style="width: 20%;" >Allergy-Medication <br> Name:</th>
                 <th >Vocabulary:</th> 
                <th >Code:</th>
                <th >Reaction<br>Details:</th>
                <th >Severity:</th>
                <th >Type:</th>
                <th >Status:</th>
                <th ></th>
            </tr>
            <tr *ngFor="let item of allergy;let i = index">
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="medication" 
                            (keyup)="keyFunc($event,item,'name_txt')"
                            (keydown.tab)="saveDropdown(item.name_txt,'Allergy-Medication Name',medication,'medicationname',item,'name_txt')" bindLabel="name_txt" bindValue="name_txt" 
                            [(ngModel)]="item.name_txt">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" (click)="editLookup(medication,medicationCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="Vocabulary" style="width:80px ;" 
                            (keyup)="keyFunc($event,item,'vocabulary_txt')"
                            (keydown.tab)="saveDropdown(item.vocabulary_txt,'Vocabulary',Vocabulary,'vocabulary',item,'vocabulary_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.vocabulary_txt">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" (click)="editLookup(Vocabulary,Vocabularycopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="Codelookup" style="width:70px ;" 
                            (keyup)="keyFunc($event,item,'rx_norm_code_txt')"
                            (keydown.tab)="saveDropdown(item.rx_norm_code_txt,'Code',Codelookup,'rxnormcode',item,'rx_norm_code_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.rx_norm_code_txt">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" (click)="editLookup(Codelookup,Codelookupcopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </td>

                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="Severity" style="width:80px ;" 
                            (keyup)="keyFunc($event,item,'severity_txt')"
                            (keydown.tab)="saveDropdown(item.severity_txt,'Severity',Severity,'Severity',item,'severity_txt')" bindLabel="name_txt" bindValue="name_txt" 
                            [(ngModel)]="item.severity_txt">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" (click)="editLookup(Severity,Severitycopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="reaction" style="width:80px ;" 
                            (keyup)="keyFunc($event,item,'reaction_detail_txt')" 
                            (keydown.tab)="saveDropdown(item.reaction_detail_txt,'ReactionDetails',reaction,'phallergyreaction',item,'reaction_detail_txt')" bindLabel="name_txt" bindValue="name_txt" 
                            [(ngModel)]="item.reaction_detail_txt">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" (click)="editLookup(reaction,reactionCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a> </div>
                    </div>
                </td>
                <td>
                    <div class="row">
                        <div class="col-md-8">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="type" style="width:80px ;" 
                            (keyup)="keyFunc($event,item,'type_txt')"
                            (keydown.tab)="saveDropdown(item.type_txt,'Type',type,'phallergytype',item,'type_txt')" bindLabel="name_txt" bindValue="name_txt" 
                            [(ngModel)]="item.type_txt">
                            </ng-select>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" (click)="editLookup(type,typeCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a> </div>
                    </div>
                </td>

                
                <td>
                    <ng-select  [clearable]="true"  [items]="statusLookup" style="width:80px ;" bindLabel="key"  bindValue="value" [(ngModel)]="item.status_txt">
                    </ng-select>
                </td>

                <td><button class="btn btn-danger" (click)="removeAllergy(i)">Remove</button></td>
            </tr>
        </table>
    </div>
    <div class="pt-2">
        <button class="btn btn-primary px-4" (click)="addAllergy()">Add</button>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" [disabled]="allergy.length==0" (click)="save()">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>
