export class OMR_FeeStatementDiagnosisModel {
    public fee_statement_diagnosis_id: string = '';
    public fee_statement_id: string = '';
    public dx_number_txt : string = '';
    public assessment: string = '';
    public created_dt: string = '';
    public created_by: string = '';
    public updated_dt: string = '';
    public updated_by: string = '';
}
