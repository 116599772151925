import { PatientInsuranceComponent } from './patient-insurance/patient-insurance.component';
import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { ExtraTestsComponent } from './extra-tests/extra-tests.component';
import { AddDiagnosisComponent } from './clinical/add-diagnosis/add-diagnosis.component';
import { ProvideResourcesComponent } from './clinical/provide-resources/provide-resources.component';
import { AddAllergyComponent } from './clinical/add-allergy/add-allergy.component';
import { AddAttachmentComponent } from './clinical/add-attachment/add-attachment.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { SpectacleComponent } from './spectacle/spectacle.component';
import { ContactOrderComponent } from './contact-order/contact-order.component';
import { OnReturnDirective } from './../../core/directives/OnReturn.directive';
import { TwoDigitDecimaNumberDirective } from 'src/app/core/directives/TwoDigitDecimalNumber.directive';
import { HcfaComponent } from './hcfa/hcfa.component';
import { CanvasComponent } from './../../shared/Canvas/Canvas.component';
import { CpoeComponent } from './cpoe/cpoe.component';
import { AddExamComponent } from './add-exam/add-exam.component';
import { AllergyComponent } from './clinical/allergy/allergy.component';
import { MedRxComponent } from './clinical/MedRx/MedRx.component';
import { DiagnosisComponent } from './clinical/diagnosis/diagnosis.component';
import { PreSpecrxComponent } from './clinical/pre-specrx/pre-specrx.component';
import { PreSpecorderComponent } from './clinical/pre-specorder/pre-specorder.component';
import { PreIopComponent } from './clinical/pre-iop/pre-iop.component';
import { PreContactrxComponent } from './clinical/pre-contactrx/pre-contactrx.component';
import { PreContactorderComponent } from './clinical/pre-contactorder/pre-contactorder.component';
import { DocumentsComponent } from './clinical/documents/documents.component';
import { AppointmentsComponent } from './clinical/appointments/appointments.component';
import { ClinicalComponent } from './clinical/clinical.component';
import { GlobalService } from './../../services/global.service';
import { MiscFreeStatementComponent } from './misc-free-statement/misc-free-statement.component';
import { PrescribeNowComponent } from './prescribe-now/prescribe-now.component';
import { PhoneMask } from '../../core/directives/phone.directive';
import { AdvancePatientSearchComponent } from './advance-patient-search/advance-patient-search.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CreatePatientComponent } from './create-patient/create-patient.component';
import { ViewPatientComponent } from './view-patient/view-patient.component';
import { PatientRoutingModule } from './patient.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';
import { ExamsComponent } from './clinical/exams/exams.component';
import { AddAppointmentComponent } from './clinical/add-appointment/add-appointment.component';
import { AddMedrxComponent } from './clinical/add-medrx/add-medrx.component';
import { NgxPrintModule } from 'ngx-print';
import { AddMedicationHistoryComponent } from './clinical/add-medication-history/add-medication-history.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PatientAccountComponent } from './patient-account/patient-account.component';
import { ArrayFiltrerPipe } from 'src/app/core/array-filtrer.pipe';
import { ManufactureComponent } from './manufacture/manufacture.component';
import { SharedModule } from 'src/app/shared/shared/shared.module';
import { AddChargesComponent } from './add-charges/add-charges.component';
import { PrintHeaderModule } from 'src/app/shared/print-header/print-header/print-header.module';
import { AddIdentifierComponent } from './add-identifier/add-identifier.component';
import { AddBrandComponent } from './add-brand/add-brand.component';
import { AddProductComponent } from './add-product/add-product.component';


@NgModule({
  imports: [
    CommonModule,
    PatientRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    NgMultiSelectDropDownModule.forRoot(),
    NgbModule,
    NgxPrintModule,
    NgSelectModule,
    // PrintHeaderModule,
    BsDatepickerModule.forRoot()    
  ],
  declarations: [CreatePatientComponent, ViewPatientComponent, AdvancePatientSearchComponent, PhoneMask, PrescribeNowComponent, MiscFreeStatementComponent, ExamsComponent, ClinicalComponent, AppointmentsComponent,
    DocumentsComponent, PreContactorderComponent, PreContactrxComponent, PreIopComponent, PreSpecorderComponent, PreSpecrxComponent, DiagnosisComponent, MedRxComponent, 
    AllergyComponent, AddExamComponent, CpoeComponent, CanvasComponent,HcfaComponent,OnReturnDirective,TwoDigitDecimaNumberDirective,ContactOrderComponent,SpectacleComponent,AddAppointmentComponent,AddPatientComponent,AddAttachmentComponent,
    AddAllergyComponent,ProvideResourcesComponent,AddMedrxComponent,AddDiagnosisComponent,ExtraTestsComponent,AddMedicationHistoryComponent,PatientHistoryComponent,PatientInsuranceComponent,PatientAccountComponent,ArrayFiltrerPipe,
    ManufactureComponent,AddChargesComponent, AddIdentifierComponent, AddBrandComponent, AddProductComponent
  ],
  exports: [ViewPatientComponent,ManufactureComponent,AddChargesComponent, AddIdentifierComponent, AddProductComponent],
  providers: [DatePipe, ModalService]
})
export class PatientModule { }
