import { AttachmentModel } from './../../../model/AttachmentModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { PatientInsuranceComponent } from './../patient-insurance/patient-insurance.component';
import { ModalComponent } from './../../../shared/modal/modal.component';
import { ProviderModel } from './../../../model/providerModel';
import { OfficeModel } from './../../../model/officeModel';
import { GlobalService } from './../../../services/global.service';
import { IdentifierModel } from './../../../model/identiferModel';
import { LookupService } from './../../../services/lookup.service';
import { PatientInsuranceModel } from './../../../model/patientInsuranceModel';
import { PatientIdentifierModel } from './../../../model/patientIdentifierModel';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from './../../../services/patient.service';
import { PatientModel } from './../../../model/patientModel';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupModel } from 'src/app/model/lookupModel';
import { InsuranceModel } from 'src/app/model/insuranceModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Renderer2 } from '@angular/core'
import { ComponentModalComponent } from 'src/app/shared/component-modal/component-modal.component';
import { AddAttachmentComponent } from '../clinical/add-attachment/add-attachment.component';
import { unescapeIdentifier } from '@angular/compiler';
import { toUpper } from 'lodash';
import * as FileSaver from 'file-saver';
import { Observable, Observer, Subject } from "rxjs";
import { PracticeService } from 'src/app/services/practice.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AddIdentifierComponent } from '../add-identifier/add-identifier.component';

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.scss'],
  providers: [DatePipe],
})
export class CreatePatientComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  addPatientFormGroup: FormGroup;
  date: any;
  patient: PatientModel = new PatientModel();
  patientCopy: PatientModel = new PatientModel();
  patientModel: PatientModel = new PatientModel();
  patientInsurances: PatientInsuranceModel[] = [];
  bsModalRef!: BsModalRef;
  identifier: Array<IdentifierModel> = new Array<IdentifierModel>();
  dropdownSettings: IDropdownSettings = {};
  Languagesdropdown: IDropdownSettings = {};
  patientId: number = 0;
  mobilePattern: RegExp = new RegExp(/^\(\d{3}\)\s\d{3}-\d{4}$/);
  isValidFlg: boolean = true;
  selectedIdentifier: Array<PatientIdentifierModel> =
    new Array<PatientIdentifierModel>();
  showComponent: boolean = false;
  allLookupData: LookupModel[] = [];
  raceLookup: LookupModel[] = [];
  ethnicityLookup: LookupModel[] = [];
  stateLookup: LookupModel[] = [];
  countryLookup: LookupModel[] = [];
  languageLookup: LookupModel[] = [];
  addresstypeLookup: LookupModel[] = [];
  contactmethodLookup: LookupModel[] = [];
  mobileproviderLookup: LookupModel[] = [];
  office: OfficeModel[] = [];
  providerModel: ProviderModel[] = [];
  relationLookup: LookupModel[] = [];
  referringDoctor: ProviderModel[] = [];
  base64Image: any;
  somePlaceholder: string = 'new value';
  refferedBy: Array<any> = new Array<any>();
  student: any = [
    {
      value: 1,
      key: 'Not Student',
    },
    {
      value: 2,
      key: 'Full Time Student',
    },
    {
      value: 3,
      key: 'Part Time student',
    },
  ];
  marriedStatus: any = [
    {
      value: 1,
      key: 'Single',
    },
    {
      value: 2,
      key: 'Married',
    },
    {
      value: 3,
      key: 'Other',
    },
  ];
  sexLookup: any = [
    {
      value: 'M',
      key: 'Male',
    },
    {
      value: 'F',
      key: 'Female',
    },
    {
      value: 'U',
      key: 'Unknown',
    },
  ];
  columnInsurance: any;
  rowInsurance: InsuranceModel[] = [];
  insuranceData: InsuranceModel[] = [];
  rowStyle: any;
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  attachmentList: AttachmentModel[] = [];
  responsiblePartyNone: boolean = false;
  responsiblePartyPatient: boolean = false;
  responsiblePartyGuest: boolean = false;
  responsiblePartySpouse: boolean = false;
  responsiblePartyGrandparent: boolean = false;
  responsiblePartyparent: boolean = false;
  responsiblePartyChild: boolean = false;
  responsiblePartyother: boolean = false;
  itemForInsurance: PatientInsuranceModel = new PatientInsuranceModel();
  check_value: string = '';
  islockaccountnumber: boolean = false;
  practiceId: string = '';

  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private _patientService: PatientService,
    private toaster: ToastrService,
    private router: Router,
    private _lookupService: LookupService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    public globalService: GlobalService,
    private modalService: BsModalService,
    private _clinicalService: ClinicalService,
    private _practiceService: PracticeService,
    private ngxService: NgxUiLoaderService
  ) {
    this.route.queryParams.subscribe((params: any) => {
      let practiceId = localStorage.getItem('practice')?.toString();
      this.practiceId = practiceId != undefined ? practiceId : '';
      let isEditable = localStorage.getItem('islockaccountnumber')?.toString();
      switch (isEditable) {
        case 'true':
          //this.addPatientFormGroup.controls['patientAcct'].disable();
          this.islockaccountnumber = true;
          break;
        case 'false':
          //this.addPatientFormGroup.controls['patientAcct'].enable();
          this.islockaccountnumber = false;
          break;
        default:
          this._practiceService.Getbyid(this.practiceId, 'lockaccountnumber').subscribe((res) => {
                if (res.value_txt == 'true') {
                  this.addPatientFormGroup.controls['patientAcct'].disable();
                  this.islockaccountnumber = true;
                } else {
                  this.addPatientFormGroup.controls['patientAcct'].enable();
                  this.islockaccountnumber = false;
                }
                localStorage.setItem('islockaccountnumber', res.value_txt);
              },
              (error) => {
                this.toaster.error(error.error);
              }
            );
          break;
      }
      if (params['patientId']) {
        this.patientId = Number(params['patientId']);
      } else {
        localStorage.setItem('ActivePatient', JSON.stringify(null));
        this._patientService.GetNewPatientAccountNumber(this.practiceId).subscribe((res) => {
              this.patient = new PatientModel();
              this.patient.patientAcctNo = res;
              this.patientCopy = this.patient;
              localStorage.setItem('ActivePatient', JSON.stringify(this.patient));
            },
            (error) => {
              this.toaster.error(error.error);
            }
          );
      }
    });
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-dark-blue', showWeekNumbers: false }
    );
    //this.date = new Date();
    this.addPatientFormGroup = fb.group({
      dateOfEntry: ['', Validators.required],
      patientAcct: { value: null, disabled: this.islockaccountnumber },
      billPatient: new FormControl(),
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      middleInitial: new FormControl(),
      socialSecurity: new FormControl(),
      patientIdentifiers: new FormControl([]),
      dateOfBirth: new FormControl(),
      sex: new FormControl(),
      race: new FormControl(),
      language: new FormControl(),
      ethnicity: new FormControl(),
      address1: new FormControl(),
      address2: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      zip: new FormControl(),
      addressType: new FormControl(),
      country: new FormControl(),
      homePhone: new FormControl([Validators.maxLength(16)]),
      appointments: new FormControl(),
      cellPhone: new FormControl([Validators.maxLength(16)]),
      // cellPhone: new FormControl([Validators.maxLength(16), Validators.minLength(16)]),
      examNotification: new FormControl(),
      cellPhoneProvider: new FormControl(),
      marketing: new FormControl(),
      email: new FormControl('', Validators.email),
      other: new FormControl(),
      regularOffice: new FormControl(),
      regularProvider: new FormControl(),
      employed: new FormControl(),
      studentStatus: new FormControl(),
      school: new FormControl(),
      occupation: new FormControl(),
      workPhone: new FormControl(),
      marriageStatus: new FormControl(),
      spouseName: new FormControl(),
      spouseEmployer: new FormControl(),
      drReferred: new FormControl(),
      referingDoctor: new FormControl(),
      referedBy: new FormControl(),
      referedByName: new FormControl(),
      patientInsurances: this.fb.array([]),
      selfPay: new FormControl(),
      responsibleName: new FormControl(),
      responsibleBirthdate: new FormControl(),
      responsibleAddress: new FormControl(),
      responsibleCity: new FormControl(),
      responsibleState: new FormControl(),
      responsibleZip: new FormControl(),
      responsibleCountry: new FormControl(),
      responsiblePhone: new FormControl([
        Validators.maxLength(16),
        Validators.minLength(16),
      ]),
      emergencyContactName: new FormControl(),
      emergencyContactRelation: new FormControl(),
      emergencyContactHomePhone: new FormControl([
        Validators.maxLength(16),
        Validators.minLength(16),
      ]),
      emergencyContactWorkPhone: new FormControl([
        Validators.maxLength(16),
        Validators.minLength(16),
      ]),

      isArchived: new FormControl(),
    });
  }
  ngOnInit() {
    localStorage.setItem("backLocation", "");
    this.refferedBy = [
      {
        value: 'Relative',
      },
      {
        value: 'Friend',
      },
      {
        value: 'Current',
      },
      {
        value: 'Patient',
      },
      {
        value: 'Yellow Page Ad',
      },
      {
        value: 'Internet Ad',
      },
      {
        value: 'Insurance Plan',
      },
      {
        value: 'Drive By',
      },
      {
        value: 'Other',
      },
    ];

    // this.addPatientFormGroup.reset();
    // this.patient = new PatientModel();
    this.addPatientFormGroup.controls.dateOfEntry.setValue(this.date);
    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: window.innerWidth < 1550 ? 1 : 2,
      enableCheckAll: true,
    };
    this.Languagesdropdown = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'name_txt',
      textField: 'name_txt',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit: 2,
      enableCheckAll: true,
    };
    this.getAllLookup();
    this.getGlobalLookup().then(() => {
      if (this.patientId > 0) {
        this.setPatientData();
      } else {
        this.showComponent = true;
        // localStorage.setItem('ActivePatient', JSON.stringify(null));
      }
    });
    this.columnInsurance = [
      {
        headerName: 'Name',
        field: 'display_txt',
        sortable: true,
        resizable: true,
        width: 100,
      },
      {
        headerName: 'Address',
        field: 'address1_txt',
        sortable: true,
        resizable: true,
        width: 200,
        cellRenderer: (params: any) => {
          if (
            params.data.address1_txt != null &&
            params.data.address2_txt != null
          ) {
            return params.data.address1_txt + ' - ' + params.data.address2_txt;
          } else if (
            params.data.address1_txt != null &&
            params.data.address2_txt == null
          ) {
            return params.data.address1_txt;
          } else if (
            params.data.address1_txt == null &&
            params.data.address2_txt != null
          ) {
            return params.data.address2_txt;
          } else {
            return '';
          }
        },
        // valueGetter: function (params: any) {
        //   return params.data.address1_txt + " - " + params.data.address2_txt
        // }
      },
    ];
    this.rowStyle = { cursor: 'pointer' };

    this.getOffice();

    if (this.patientId < 1) {
      this.getAttachment();
    }
    // this.globalService.initTimer(1, 1);
  }

  deletePatientInsurance(index: number) {
    return this.patient.patientInsurances.splice(index, 1);
  }
  initialPatientInsurance() {
    this.patient.patientInsurances.push(new PatientInsuranceModel());
  }

  setPatientData() {

    this._patientService.getPatientByPatientId(this.patientId).subscribe(
      (res: any) => {
        this.patient = Object.assign({}, res);
        this.patientCopy = this.patient;
        localStorage.setItem('ActivePatient', JSON.stringify(null));
        localStorage.setItem('ActivePatient', JSON.stringify(this.patient));

        this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!);
        this.attachmentList.forEach((element) => {
          element.patient_id = this.patientModel.patient_id;
        });

        this._clinicalService.updateAttachment(this.attachmentList).subscribe(
          (res) => {
            // this.toaster.success('Record Saved Successfully.');

            this.getAttachment();
            if (this.bsModalRef) {
              this.bsModalRef.hide();
            }
          },
          (error) => {
            this.toaster.error(error.error.message);
          }
        );

        setTimeout(() => {
          this.globalService.changeCommonLayout(true);
        }, 100);

        this.patient.patientIdentifiers = [];
        for (let i = 0; i < res.patientIdentifiers.length; i++) {
          this.patient.patientIdentifiers.push(
            res.patientIdentifiers[i].identifier
          );
          // this.addPatientFormGroup.controls.patientIdentifiers.setValue(this.selectedIdentifier)
        }
        this.showComponent = true;
        //   this.patient.patientInsurances.push(new PatientInsuranceModel());
        // this.patient.patientInsurances[0].insuranceType = 1;

        if (this.patient.responsibleParty == 1) {
          this.responsiblePartyNone = true;
        } else if (this.patient.responsibleParty == 2) {
          this.responsiblePartyPatient = true;
        } else if (this.patient.responsibleParty == 3) {
          this.responsiblePartySpouse = true;
        } else if (this.patient.responsibleParty == 4) {
          this.responsiblePartyGrandparent = true;
        } else if (this.patient.responsibleParty == 5) {
          this.responsiblePartyparent = true;
        } else if (this.patient.responsibleParty == 6) {
          this.responsiblePartyGuest = true;
        } else if (this.patient.responsibleParty == 7) {
          this.responsiblePartyChild = true;
        } else if (this.patient.responsibleParty == 8) {
          this.responsiblePartyother = true;
        }

        //this.getAttachment();
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }
  insertIdenfiers(event: any) {
    if (this.identifier.findIndex((x) => x.name == event.target.value) == -1) {
      let identifierModel = new IdentifierModel();
      identifierModel.name = event.target.value;
      this._lookupService.insertIdentifier(identifierModel).subscribe(
        (res) => {
          this.getIdentifier();
        },
        (error) => {
          this.toaster.error(error.error);
        }
      );
    } else {
      this.toaster.error('Identifier Already Exists.');
    }
  }
  insertlanguages(event: any) {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.languageLookup = res.filter(
        (x) => x.name_txt.toLowerCase() == event.target.value
      );
    });
  }

  getAllLookup() {
    this.getIdentifier();
    // this.getGlobalLookup();
    this.getInsurance();
    this.ngAfterViewInit();
  }

  getIdentifier() {

    this._lookupService.getIdentifierLookup().subscribe(
      (res: any) => {
        this.identifier = res;
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }

  getInsurance() {
    this._lookupService.getInsuarance().subscribe((res: any) => {
      this.rowInsurance = res;
      this.insuranceData = res;
    });
  }

  getSpecificLookup(txt: string) {
    let listData = this.allLookupData.filter(
      (x) => x.group_txt.toLowerCase() == txt.toLowerCase()
    );
    return listData;
  }

  getGlobalLookup(): Promise<any> {
    this.allLookupData = JSON.parse(localStorage.getItem('AllLookupData')!);
    var promise = new Promise<void>((resolve, reject) => {
      // this._lookupService.getGlobalLookup('race').subscribe((res: any) => {
      //   this.raceLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.raceLookup = this.getSpecificLookup('race');
      // this._lookupService.getGlobalLookup('ethnicity').subscribe((res: any) => {
      //   this.ethnicityLookup = res;
      //   if (this.patientId == 0) {
      //     this.patient.ethnicity = (this.ethnicityLookup.find(x => x.name_txt == 'Hispanic or Latino')?.name_txt!)
      //     if (!this.patient.ethnicity) {
      //       this.patient.ethnicity = (this.ethnicityLookup.find(x => x.name_txt == 'Not Hispanic or Latino')?.name_txt!)
      //     }
      //   }

      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.ethnicityLookup = this.getSpecificLookup('ethnicity');
      if (this.patientId == 0) {
        this.patient.ethnicity = this.ethnicityLookup.find(
          (x) => x.name_txt == 'Hispanic or Latino'
        )?.name_txt!;
        if (!this.patient.ethnicity) {
          this.patient.ethnicity = this.ethnicityLookup.find(
            (x) => x.name_txt == 'Not Hispanic or Latino'
          )?.name_txt!;
        }
      }

      // this._lookupService.getGlobalLookup('language').subscribe((res: any) => {
      //   this.languageLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.languageLookup = this.getSpecificLookup('language');
      // this._lookupService.getGlobalLookup('states').subscribe((res: any) => {
      //   this.stateLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.stateLookup = this.getSpecificLookup('states');

      // this._lookupService.getGlobalLookup('countries').subscribe((res: any) => {
      //   this.countryLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.countryLookup = this.getSpecificLookup('countries');

      // this._lookupService.getGlobalLookup('addresstype').subscribe((res: any) => {
      //   this.addresstypeLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.addresstypeLookup = this.getSpecificLookup('addresstype');
      // this._lookupService.getGlobalLookup('contactmethod').subscribe((res: any) => {
      //   this.contactmethodLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.contactmethodLookup = this.getSpecificLookup('contactmethod');
      // this._lookupService.getGlobalLookup('mobileprovider').subscribe((res: any) => {
      //   this.mobileproviderLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.mobileproviderLookup = this.getSpecificLookup('mobileprovider');
      let practiceId = localStorage.getItem('practice')?.toString();
      practiceId = practiceId != undefined ? practiceId : '';
      let loadingProvider = new ProviderModel();
      loadingProvider.active_ind = true;
      loadingProvider.provider_id = '-1';
      loadingProvider.first_name_txt = '';
      loadingProvider.last_name_txt = '';
      loadingProvider.display_txt = 'Loading Records.....';
      this.providerModel.push(loadingProvider);
      this.referringDoctor.push(loadingProvider);

      this.providerModel = JSON.parse(localStorage.getItem('Provider')!);
      this.referringDoctor = JSON.parse(
        localStorage.getItem('ReferingProvider')!
      );

      if (this.providerModel.length < 1) {
        this._lookupService.getRenderingProvider(practiceId).subscribe(
          (res: any) => {
            this.providerModel = res;
            localStorage.setItem(
              'Provider',
              JSON.stringify(this.providerModel)
            );
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
      }
      if (this.referringDoctor.length < 1) {
        this._lookupService.getReferingProvider(practiceId).subscribe(
          (res: any) => {
            this.referringDoctor = res;
            localStorage.setItem(
              'ReferingProvider',
              JSON.stringify(this.referringDoctor)
            );
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
      }

      // this._lookupService.getGlobalLookup('relation').subscribe((res: any) => {
      //   this.relationLookup = res;
      // }, (error) => {
      //   this.toaster.error(error.error);
      // });
      this.relationLookup = this.getSpecificLookup('relation');
      resolve();
    });
    return promise;
  }

  save() {
    if (this.responsiblePartyNone) {
      this.patient.responsibleParty = 1;
    } else if (this.responsiblePartyPatient) {
      this.patient.responsibleParty = 2;
    } else if (this.responsiblePartySpouse) {
      this.patient.responsibleParty = 3;
    } else if (this.responsiblePartyGrandparent) {
      this.patient.responsibleParty = 4;
    } else if (this.responsiblePartyparent) {
      this.patient.responsibleParty = 5;
    } else if (this.responsiblePartyGuest) {
      this.patient.responsibleParty = 6;
    } else if (this.responsiblePartyChild) {
      this.patient.responsibleParty = 7;
    } else if (this.responsiblePartyother) {
      this.patient.responsibleParty = 8;
    } else {
      this.responsiblePartyNone = true;
    }
    if (this.patientId > 0) {
      if (this.addPatientFormGroup.valid) {
        this._patientService.updatePatient(this.patient).subscribe(
          () => {
            this.toaster.success('Record Saved Succesfully.');
            this.setPatientData();
            setTimeout(() => {
              this.globalService.changeCommonLayout(true);
            }, 5000);
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
      }
    } else {
      if (this.addPatientFormGroup.valid) {
        let practiceId = localStorage.getItem('practice')?.toString();
        practiceId = practiceId != undefined ? practiceId : '';
        this._patientService.addPatient(this.patient, practiceId).subscribe(
          (res: any) => {
            this.patientId = res.patientID;
            this.toaster.success('Record Saved Succesfully.');
            this.setPatientData();
            location.href = location.href + '?patientId=' + this.patientId;
            //this.router.navigate(['/patient/create'], { queryParams: { patientId: this.patientId } });
            //this.ngOnInit();
            // setTimeout(() => {
            //   this.globalService.changeCommonLayout(true);
            // }, 3000);
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
      }
    }
  }

  saveClose() {
    if (this.patientId > 0) {
      this._patientService.updatePatient(this.patient).subscribe(
        () => {
          this.toaster.success('Record Saved Succesfully.');
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          this.toaster.error(error.error);
        }
      );
    } else {
      let practiceId = localStorage.getItem('practice')?.toString();
      practiceId = practiceId != undefined ? practiceId : '';
      this._patientService.addPatient(this.patient, practiceId).subscribe(
        (res: any) => {
          this.toaster.success('Record Saved Succesfully.');
          this.router.navigate(['/dashboard']);
        },
        (error) => {
          this.toaster.error(error.error);
        }
      );
    }
  }

  validatePhoneNo(event: any) {
    if (event.target.value) {
      const x = event.target.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      event.target.value =
        +x[1] + (x[2] ? `-${x[2]}` : '') + (x[3] ? `-${x[3]}` : '');
    }
  }

  getOffice() {
    this._lookupService.getOffice().subscribe(
      (res: any) => {
        this.office = res;
        if (this.patientId < 1) {
          let ind = this.office.findIndex((x) => x.default_ind);
          if (ind != -1) {
            this.patient.defaultOfficeID = this.office[ind].office_id;
          }
        }
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  insuranceRowClicked(event: any, item: PatientInsuranceModel) {
    this.itemForInsurance.insuranceName =
      event.api.getSelectedRows()[0].display_txt;
    this.itemForInsurance.insuranceModel = event.api.getSelectedRows()[0];
    this.itemForInsurance.groupID = event.api.getSelectedRows()[0].groupID;
    this.itemForInsurance.memberID = event.api.getSelectedRows()[0].memberID;
    this.itemForInsurance.insuredRelation =
      event.api.getSelectedRows()[0].insuredRelation;
    this.itemForInsurance.isShow = false;
    item.insuranceName = event.api.getSelectedRows()[0].display_txt;
    item.insuranceModel = event.api.getSelectedRows()[0];
    item.isShow = false;
    this.patient.patientInsurances[0].insurance_id =
      event.api.getSelectedRows()[0].insurance_id;
  }

  copyPhone() {
    this.patient.cellPhone = this.patient.homePhone;
  }

  searchInsurance(event?: any) {
    if (event.target.value.toString().length > 0) {
      this.rowInsurance = this.insuranceData.filter((x) =>
        x.display_txt.toLowerCase().includes(event.target.value.toLowerCase())
      );
    } else {
      this.rowInsurance = this.insuranceData;
    }
  }

  GetInsurancedata(item: PatientModel, item1: PatientInsuranceModel) {
    if (this.patient.patientInsurances[0]) {
      this.rowInsurance = this.insuranceData.filter((x) =>
        x.display_txt
          .toLowerCase()
          .includes(
            this.patient.patientInsurances[0].insuranceName.toLowerCase()
          )
      );
    } else {
      this.rowInsurance = this.insuranceData;
    }
    this.itemForInsurance.insuranceName = this.rowInsurance[0].display_txt;
    this.itemForInsurance.insuranceModel = this.rowInsurance[0];
    this.itemForInsurance.groupID = this.patient.patientInsurances[0].groupID;
    this.itemForInsurance.memberID = this.patient.patientInsurances[0].memberID;
    this.itemForInsurance.insuredRelation =
      this.patient.patientInsurances[0].insuredRelation;

    this.itemForInsurance.isShow = false;
    item1.insuranceName = this.rowInsurance[0].display_txt;
    item1.insuranceModel = this.rowInsurance[0];
    item1.isShow = false;
  }

  openInsurance(patientInsuranceModel: PatientInsuranceModel) {
    this.GetInsurancedata(this.patient, this.itemForInsurance);
    const initialState = {
      item: this.itemForInsurance,
    };
    this.bsModalRef = this.modalService.show(PatientInsuranceComponent, {
      initialState,
    });
    this.bsModalRef.setClass('modal-lg');
    this.bsModalRef.onHide.subscribe((res: any) => {});
  }

  // editOffice(componentName: string) {
  //   const initialState = {
  //     componentName: componentName,
  //     title: 'Edit Facilities'
  //   };
  //   this.bsModalRef = this.modalService.show(ComponentModalComponent, { initialState });
  //   this.bsModalRef.setClass('modal-xl')
  //   this.bsModalRef.onHide.subscribe((res: any) => {
  //     if (componentName == 'General') {
  //       this.getOffice()
  //     }
  //   })
  // }
  editOffice(
    componentName: string,
    title: string,
    isProductModel: boolean,
    isShowSaveButton: boolean
  ) {

    const initialState = {
      componentName: componentName,
      title: title,
      isProductModel: isProductModel,
      isShowSaveButton: isShowSaveButton,
    };
    //AddIdentifierComponent
    if (componentName == 'add-identifier') {
      this.bsModalRef = this.modalService.show(AddIdentifierComponent, {
        initialState,
      });
    } else {
      this.bsModalRef = this.modalService.show(ComponentModalComponent, {
        initialState,
      });
    }
    this.bsModalRef.setClass('modal-xl');
    // componentName == 'add-identifier' ? this.bsModalRef.setClass('modal-lg') : this.bsModalRef.setClass('modal-xl');
    //this.bsModalRef.setClass("modal-dialog modal-dialog-centered modal-lg");
    this.bsModalRef.onHide.subscribe((res: any) => {

      if (componentName == 'General') {
        this.getOffice();
      } else if (componentName == 'add-identifier') {
        this.getIdentifier();
      }
    });
  }

  copyData() {
    this.patient.responsibleName =
      this.patient.firstName + ' ' + this.patient.lastName;
    this.patient.responsibleBirthdate = this.patient.dateOfBirth;
    this.patient.responsibleAddress = this.patient.address;
    this.patient.responsibleCity = this.patient.city;
    this.patient.responsibleState = this.patient.state;
    this.patient.responsibleZip = this.patient.zip;
    this.patient.responsiblePhone = this.patient.homePhone;
    this.responsiblePartyPatient = true;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = false;
  }

  onNone() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = true;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = false;
  }

  onchild() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = true;
  }

  onparent() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = true;
    this.responsiblePartyChild = false;
  }

  onSpouse() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = true;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = false;
  }
  onGrandParent() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = true;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = false;
  }

  onother() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = false;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = true;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = false;
  }

  onGuest() {
    this.responsiblePartyPatient = false;
    this.responsiblePartyGuest = true;
    this.responsiblePartyNone = false;
    this.responsiblePartySpouse = false;
    this.responsiblePartyother = false;
    this.responsiblePartyGrandparent = false;
    this.responsiblePartyparent = false;
    this.responsiblePartyChild = false;
  }

  addAttachment() {
    const initialState = {
      title: 'Attachment',
      callingComponent: 'CreatePatient',
    };
    this.bsModalRef = this.modalService.show(AddAttachmentComponent, {
      initialState,
    });
    this.bsModalRef.content.closeBtnName = 'Close';

    this.getAttachment();
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getAttachment();
    });
  }

  getAttachment() {
    // if(this.attachmentList.length <= 0 ){
    //   if (this.patientId == 0 || this.patientId ==undefined) {
    //     this._clinicalService.getAttachment('0000000-0000-0000-0000-000000000000').subscribe((res: any) => {
    //       this.attachmentList = res;
    //       //this.attachmentList = this.attachmentList.filter(x=>x.type_txt.toLowerCase() == "Insurance Cards".toLowerCase());
    //     })
    //   }
    //   else{
    //     let loadingAttachment = new AttachmentModel();
    //     loadingAttachment.active_ind = true;
    //     loadingAttachment.attachment_id = '-1';
    //     loadingAttachment.type_txt = '';
    //     loadingAttachment.description_txt = 'Please wait loading Records.....';
    //     this.attachmentList.push(loadingAttachment);

    //     this._clinicalService.getAttachment(this.patient.patient_id).subscribe((res: any) => {

    //       this.attachmentList = res;
    //       if(res.length == 1){
    //         this.patient.patientInsurances[0].attachmentModel = res[0];
    //       }
    //     })
    //   }
    // }

    if (this.attachmentList.length <= 0) {
      if (this.patientId != 0 || this.patientId != undefined) {
        let loadingAttachment = new AttachmentModel();
        loadingAttachment.active_ind = true;
        loadingAttachment.attachment_id = '-1';
        loadingAttachment.type_txt = '';
        loadingAttachment.description_txt = 'Please wait loading Records.....';
        this.attachmentList.push(loadingAttachment);

        this._clinicalService
          .getAttachment(this.patient.patient_id)
          .subscribe((res: any) => {
            this.attachmentList = res;//.filter(x => x.type_txt.toLowerCase() == 'Insurance Card'.toLowerCase());
            this.attachmentList = this.attachmentList.filter(x=>x.type_txt.trim() == 'Insurance Card');
            if (res.length == 1) {
              if (this.patient.patientInsurances.length > 0) {
                this.patient.patientInsurances[0].attachmentModel = res[0];
              } else {
                this.initialPatientInsurance();
                this.patient.patientInsurances[0].attachmentModel = res[0];
                this.patient.patientInsurances[0].attachment_id =
                  this.attachmentList[0].attachment_id;
              }
            }
          });
      }
    }
  }

  setAttachment(event: any, item: PatientInsuranceModel) {
    item.attachmentModel = event;
  }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }
  saveDropdown(val: any, item: LookupModel[], item1?: any, property?: any) {
    this.check_value = val;
    if (this.check_value != '') {
      var index = item.findIndex(
        (x) =>
          x.name_txt.trim().toLowerCase() ==
          this.check_value.trim().toLowerCase()
      );
      if (index > -1) {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }
  addLokupItem(event: any, grp: string): boolean {
    event = this.patient.responsibleCountry;
    this.globalService.saveGlobalDropdown(
      this.patient.responsibleCountry,
      grp,
      this.getGlobalLookup
    );
    return true;
  }

  addLokupItemForCountry(event: any, grp: string): boolean {
    event = this.patient.country;
    this.globalService.saveGlobalDropdown(
      this.patient.responsibleCountry,
      grp,
      this.getGlobalLookup
    );
    return true;
  }

  downloadFile(item: PatientInsuranceModel) {
    if (item.attachmentModel.record_type_txt != 'pdf') {
      this.base64Image =
        'data:' +
        item.attachmentModel.record_type_txt +
        ';base64,' +
        item.attachmentModel.file_dat;
      // save image to disk
      var link = document.createElement('a');
      document.body.appendChild(link); // for Firefox
      link.setAttribute('href', this.base64Image);
      link.setAttribute('download', item.attachmentModel.path_resource_txt);
      link.click();
    } else {
      this.downloadPdf(
        item.attachmentModel.file_dat,
        item.attachmentModel.path_resource_txt
      );
    }
  }
  downloadPdf(base64String: string, fileName: string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${fileName}.pdf`;
    link.click();
  }
  ngOnDestroy() {

    // NgIdleService.runTimer = false;
    // NgIdleService.runSecondTimer = false;
  }
}



