<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta http-equiv="x-ua-compatible" content="ie=edge">

    <title>QuikEyes | Dashboard</title>

    <!-- Font Awesome Icons -->
    <!-- IonIcons -->
    <!-- <link rel="stylesheet" type="text/css"
    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" /> -->

    <link rel="stylesheet" href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css">
    <!-- Theme style -->
    <!--<link rel="stylesheet" href="../../../assets/css/adminlte.min.css">-->
    <!-- Google Font: Source Sans Pro -->
    <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet">
</head>
<header style="margin:0 0 0 0;">
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand navbar-white navbar-light" style="position: fixed;width: 88%;left: 250px;z-index: 999;height:60px;">
        <!-- Sidebar user panel (optional) -->
        <div *ngIf="isPatientDetail" class="user-panel d-flex">
            <div class="image">
                <!-- <img src="../../../assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image"> -->
            </div>
            <div class="info">
                <p class="d-block mb-0" style="font-size:16px;">{{patientDetail?.lastName}}, {{patientDetail?.firstName}}
                </p>
                <p class="d-block mb-0">Age: {{age}} Sex: {{patientDetail?.sex | uppercase}}</p>
            </div>
        </div>
        <!-- Left navbar links -->
        <ul *ngIf="isPatientDetail" class="navbar-nav">
            <li class="nav-item">Patient #: {{patientDetail?.patientAcctNo}}</li>
        </ul>
        <!-- Right navbar links -->
        <ul style="margin-left: 610px !important" class="navbar-nav ml-auto">
            <!-- Messages Dropdown Menu -->
            <li class="nav-item dropdown">
                <a class="nav-link" data-toggle="dropdown" href="#">
                    <p (click)="logout()">Log out</p>
                </a>
            </li>
            <!-- Notifications Dropdown Menu -->
        </ul>
    </nav>
    <!-- /.navbar -->
</header>

<body class="hold-transition sidebar-mini" style="padding-top: 60px;">
    <div class="wrapper" class="candy-carousel">



        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary">
            <!-- Brand Logo -->
            <a routerLink="#" class="brand-link" style="position: fixed;width: 258px;z-index: 9999; top:0px;left:0px;height:60px !important;">
                <!-- <img src="../../../assets//img/logo_short.png" alt="Vision Today Logo" class="brand-image img-circle elevation-3" style="opacity: .8"> -->
                <img [src]="imgURL" alt="Vision Today Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text" style="color:white">QuikEyes</span>
            </a>

            <!-- Sidebar -->
            <div class="sidebar" style="position: fixed;overflow:hidden;">
                <!-- Sidebar Menu -->
                <nav class="mt-2" style="padding-top: 35px;">
                    <ul *ngIf="!isPatientDetail" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                        <li class="nav-item">
                            <a routerLink="/dashboard" class="nav-link" routerLinkActive="active" class="nav-link">
                                <p>
                                    <i class="fa fa-tachometer icon"></i>
                                    Dashboard
                                </p>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/patient/create" routerLinkActive="active" class="nav-link">
                                <p>
                                    Add New Patient
                                </p>
                            </a>
                        </li> -->
                        <li class="nav-item">
                            <a routerLink="/patient/patient-search" routerLinkActive="active" class="nav-link">
                                <p>
                                    <i class="fa fa-search icon"></i>
                                    Advanced Patient Search
                                </p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/scheduling/scheduling" routerLinkActive="active" class="nav-link">
                                <p>
                                    <i class="fa fa-calendar icon"></i>
                                    Scheduling
                                </p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/reports/report" routerLinkActive="active" class="nav-link">
                                <p>
                                    <i class="fa fa-bar-chart icon"></i>
                                    Reports
                                </p>
                            </a>
                        </li>
                        <li class="nav-item">
                          <a (click)="showAdministration()" routerLink="#" routerLinkActive="active" class="nav-link">
                              <p>
                                <i class="fa fa-cog icon"></i>
                                  Administration
                              </p>
                          </a>
                      </li>
                      <ul *ngIf="isShowAdministration" class="nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                          <li class="nav-item">
                              <a routerLink="/administration/general" routerLinkActive="active" class="nav-link">
                                  <p>
                                      General
                                  </p>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a routerLink="/administration/providers" routerLinkActive="active" class="nav-link">
                                  <p>
                                      Providers
                                  </p>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a routerLink="/administration/user" routerLinkActive="active" class="nav-link">
                                  <p>
                                      Users
                                  </p>
                              </a>
                          </li>
                          <li class="nav-item">
                              <a routerLink="/administration/insurances" routerLinkActive="active" class="nav-link">
                                  <p>
                                      Insurances
                                  </p>
                              </a>
                          </li>
                          <li class="nav-item">
                            <a routerLink="/administration/products" routerLinkActive="active" class="nav-link">
                                <p>
                                    Products
                                </p>
                            </a>
                        </li>
                          <li class="nav-item">
                            <a routerLink="/administration/manufacture" routerLinkActive="active" class="nav-link">
                                <p>
                                    Manufacturers
                                </p>
                            </a>
                        </li>
                      </ul>
                    </ul>
                    <ul *ngIf="isnewPatient" class="nav nav-pills nav-sidebar flex-column active" data-widget="treeview" role="menu" data-accordion="false" style="font-size: 18px;width:100% !important">
                      <li class="nav-item">
                        <a class="nav-link active">
                            <p>
                                New Patient
                            </p>
                        </a>
                    </li>
                    </ul>
                    <ul *ngIf="isPatientDetail && isLoad" class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" style="font-size: 18px">

                        <li class="nav-item">
                            <a (click)="patientDemographicsClick()" routerLink="/patient/create" [queryParams]="{patientId:patientDetail.patientID}" class="nav-link" routerLinkActive="active">
                                <!--  -->
                                <p>
                                    Patient Demographics
                                </p>
                            </a>
                        </li>
                        <ul class="nav-pills nav-sidebar flex-column" data-widget="treeview">
                            <li class="nav-item">
                                <a (click)="patientHistoryClick()" routerLink="/patient/patient-history" [queryParams]="{patientId:patientDetail.patientID}" class="nav-link" routerLinkActive="active">
                                    <!--   -->
                                    <p>
                                        Patient History
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a (click)="clinicalClick()" routerLink="/patient/clinical/exams" [queryParams]="{patientId:patientDetail.patientID}" routerLinkActive="active" class="nav-link">
                                    <!--  routerLink="/patient/clinical/exams" [queryParams]="{patientId:patientDetail.patientID}" -->
                                    <p>
                                        Clinical
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item" *ngIf="isShowSubMenuExam">
                                <a routerLink="/patient/add-exam" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active" class="nav-link active">
                                    <p>
                                        Exam
                                    </p>
                                </a>
                            </li>
                            <ul *ngIf="isShowSubMenuExam" style="list-style-type:none;" class="nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li class="nav-item">
                                    <a (click)="extraTestsClick()" routerLink="/patient/extra-test" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active" class="nav-link">
                                        <!-- -->
                                        <p>
                                            Extra Tests/Notes
                                        </p>
                                    </a>
                                </li>
                                <!-- <li class="nav-item" [ngClass]="!isDisabled?'disabled':''"> -->
                                <li class="nav-item">
                                    <a (click)="spectacleClick()" routerLink="/patient/spectacle" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active" class="nav-link">
                                        <p>
                                            Spectacle/Contact RX
                                        </p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="medRxClick()" routerLink="/patient/cpoe" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active" class="nav-link">
                                        <p>
                                            MedRx
                                        </p>
                                    </a>
                                </li>
                                <li class="nav-item" [class.disabled]="!isDisabled">
                                    <a (click)="contactOrderFormClick()" routerLink="/patient/contact-order" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active" class="nav-link">
                                        <p>
                                            Contact Order Form
                                        </p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="feeStatementClick()" routerLink="/patient/misc-free-statement" [queryParams]="{patientId:patientDetail.patientID,examId : examId,isExamTab:false}" routerLinkActive="active" [routerLinkActiveOptions]={exact:true} class="nav-link">
                                        <p>
                                            Fee Statement
                                        </p>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a (click)="hCFAClick()" routerLink="/patient/hcfa" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active" class="nav-link">
                                        <p>
                                            HCFA/eClaims
                                        </p>
                                    </a>
                                </li>
                            </ul>
                            <li class="nav-item">
                                <a (click)="miscFeeStatementClick()" routerLink="/patient/misc-free-statement" [queryParams]="{patientId:patientDetail.patientID,feestatementId : feestatementId}" routerLinkActive="active" [routerLinkActiveOptions]={exact:true} class="nav-link">
                                    <p>
                                        Misc Fee Statement
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a (click)="patientAccountClick()" routerLink="/patient/patientAccount" [queryParams]="{patientId:patientDetail.patientID}" routerLinkActive="active" class="nav-link">
                                    <p>
                                        Patient Account
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a (click)="ePrescribeClick()" routerLink="/patient/e-prescribe-now" [queryParams]="{patientId:patientDetail.patientID}" routerLinkActive="active" class="nav-link">
                                    <p>
                                        ePrescribe
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </ul>
                </nav>
                <!-- /.sidebar-menu -->
            </div>
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <router-outlet></router-outlet>
                            <!--<h1 class="m-0 text-dark">Dashboard v3</h1>-->
                        </div>
                        <!-- /.col
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Dashboard v3</li>
            </ol>
          </div>.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </div>
        </div>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <!--<h1 class="m-0 text-dark">Dashboard v3</h1>-->
                        </div>
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->

    </div>
    <!-- ./wrapper -->

    <!-- REQUIRED SCRIPTS -->

    <!-- jQuery -->
    <script src="plugins/jquery/jquery.min.js"></script>
    <!-- Bootstrap -->
    <script src="plugins/bootstrap/js/bootstrap.bundle.min.js"></script>
    <!-- AdminLTE -->
    <script src="dist/js/adminlte.js"></script>

    <!-- OPTIONAL SCRIPTS -->
    <script src="plugins/chart.js/Chart.min.js"></script>
    <script src="dist/js/demo.js"></script>
    <script src="dist/js/pages/dashboard3.js"></script>
</body>

</html>
