import { element } from 'protractor';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalService } from './../../../services/global.service';
import { UserService } from './../../../services/user.service';
import { LookupService } from 'src/app/services/lookup.service';
import { PracticeService } from 'src/app/services/practice.service';
import { ExamModel } from './../../../model/ExamModel';
import { OMR_EXAMModel } from '../../../model/OMR_EXAMModel';
import { Component, DoCheck, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Exam_AssessmentsModel } from 'src/app/model/Exam_AssessmentsModel';
import { Exam_Assessment_PlansModel } from 'src/app/model/Exam_Assessment_PlansModel';
import { ToastrService } from 'ngx-toastr';
import { OMR_ProgressNotesModel } from 'src/app/model/OMR_ProgressNotesModel';
import { PatientModel } from 'src/app/model/patientModel';
import { LookupModel } from 'src/app/model/lookupModel';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserModel } from 'src/app/model/userModel';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as moment from 'moment';
import { ComponentModalComponent } from 'src/app/shared/component-modal/component-modal.component';
import { OfficeModel } from 'src/app/model/officeModel';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ExamsComponent } from '../clinical/exams/exams.component';
import * as _ from 'lodash';
import { AddBrandComponent } from '../add-brand/add-brand.component';
import { ItemModel } from 'src/app/model/ItemModel';
import { Item_ContactsDataModel } from 'src/app/model/Item_ContactsDataModel';
import { async } from '@angular/core/testing';
import { Console } from 'console';
import { OMR_ContactOrderFormModel } from 'src/app/model/OMR_ContactOrderFormModel';
import { ContactOrderComponent } from '../contact-order/contact-order.component';
import { tap } from 'rxjs/operators';
import { ManufacturerModel } from 'src/app/model/ManufacturerModel';
import { ManufacturerService } from 'src/app/services/Manufacturer.service';

@Component({
  selector: 'app-add-exam',
  templateUrl: './add-exam.component.html',
  styleUrls: ['./add-exam.component.scss'],
})
export class AddExamComponent implements OnInit, DoCheck, OnDestroy {
  patientId: number = 0;
  patient_Id: string = '';
  examId: string = '';
  rowNote: OMR_ProgressNotesModel[] = [];
  columnNote: any;
  exam: ExamModel = new ExamModel();
  examCopy: ExamModel = new ExamModel();
  omr_ProgressNotes: OMR_ProgressNotesModel = new OMR_ProgressNotesModel();
  omr_ProgressNotesList: OMR_ProgressNotesModel[] = [];
  omR_ContactOrderForm: OMR_ContactOrderFormModel = new OMR_ContactOrderFormModel();
  patientDetail: PatientModel = new PatientModel();
  productModel: ItemModel = new ItemModel();
  showComponent: boolean = false;
  progressNoteSearchSting: string = '';
  locationLookUp: LookupModel[] = [];
  qualityLookup: LookupModel[] = [];
  severityLookup: LookupModel[] = [];
  durationLookup: LookupModel[] = [];
  timingLookup: LookupModel[] = [];
  contextLookup: LookupModel[] = [];
  factorLookup: LookupModel[] = [];
  factorCopyLookup: LookupModel[] = [];
  symptomsLook: LookupModel[] = [];
  psychLookup: LookupModel[] = [];
  farNearLookup: LookupModel[] = [];
  pupils: LookupModel[] = [];
  coverTest: LookupModel[] = [];
  npcLookup: LookupModel[] = [];
  eomLookup: LookupModel[] = [];
  headFace: LookupModel[] = [];
  confrontationsLookup: LookupModel[] = [];
  angleEstLookup: LookupModel[] = [];
  internalLookup: LookupModel[] = [];
  internalODOS: LookupModel[] = [];
  tonometry: LookupModel[] = [];
  tropicLookup: LookupModel[] = [];
  phenylLookup: LookupModel[] = [];
  ParedLookup: LookupModel[] = [];
  cycloLookup: LookupModel[] = [];
  slitLampExamLookup: LookupModel[] = [];
  tcPlan: LookupModel[] = [];
  tcAssessment: LookupModel[] = [];
  userList: UserModel[] = [];
  user: any = new UserModel();
  user1: any = new UserModel();
  examuser: any = new UserModel();
  imgURL: any;
  examimgURL: any;
  needToFind: any;
  imageEXTSRC: any;
  imageINTSRC: any;
  defaultImageEXTSRC: any;
  defaultImageINTSRC: any;
  imageBackSlash: any;
  imageForwardSlash: any;
  imageCross: any;
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  bsModalRef!: BsModalRef;
  office: OfficeModel[] = [];
  locationCopyLookUp: LookupModel[] = [];
  qualityCopyLookup: LookupModel[] = [];
  severityCopyLookup: LookupModel[] = [];
  durationCopyLookup: LookupModel[] = [];
  timingCopyLookup: LookupModel[] = [];
  psychLookupCopy: LookupModel[] = [];
  farNearCopyLookup: LookupModel[] = [];
  pupilsCopy: LookupModel[] = [];
  coverTestCopy: LookupModel[] = [];
  npcCopyLookup: LookupModel[] = [];
  eomCopyLookup: LookupModel[] = [];
  confrontationsCopyLookup: LookupModel[] = [];
  angleEstCopyLookup: LookupModel[] = [];
  internalCopyLookup: LookupModel[] = [];
  internalODOSCopy: LookupModel[] = [];
  tonometryCopy: LookupModel[] = [];
  tropicLookupCopy: LookupModel[] = [];
  phenylLookupCopy: LookupModel[] = [];
  ParedLookupCopy: LookupModel[] = [];
  cycloLookupCopy: LookupModel[] = [];
  tcPlanCopy: LookupModel[] = [];
  tcAssessmentCopy: LookupModel[] = [];
  contextCopyLookup: LookupModel[] = [];
  symptomsCopyLook: LookupModel[] = [];
  headFaceCopy: LookupModel[] = [];
  ampLookup: LookupModel[] = [];
  ampLookupCopy: LookupModel[] = [];
  praLookup: LookupModel[] = [];
  praLookupCopy: LookupModel[] = [];
  nraLookup: LookupModel[] = [];
  nraLookupCopy: LookupModel[] = [];
  tonometryosod: LookupModel[] = [];
  tonometryosodCopy: LookupModel[] = [];
  assessment: LookupModel[] = [];
  assessmentCopy: LookupModel[] = [];
  plan: LookupModel[] = [];
  planCopy: LookupModel[] = [];
  bcLookupCopy: LookupModel[] = [];
  bcLookup: LookupModel[] = [];
  diamLookup: LookupModel[] = [];
  diamLookupCopy: LookupModel[] = [];
  brandLookup: LookupModel[] = [];
  brandLookupCopy: LookupModel[] = [];
  tcplanLookup: LookupModel[] = [];
  tcplanLookupCopy: LookupModel[] = [];
  NearSubjLookup: LookupModel[] = [];
  NearSubjLookupCopy: LookupModel[] = [];
  examVisitTypeLookup: LookupModel[] = [];
  examVisitTypeCopyLookup: LookupModel[] = [];
  cleanQHSLookup: LookupModel[] = [];
  cleanQHSCopyLookup: LookupModel[] = [];
  slitLampOtherDisable: boolean = true;
  oldPatientComments: string = '';
  editProgressNote: boolean = false;
  @ViewChild('Selecter') ngselect: NgSelectComponent | undefined;
  term: any;
  check_value: string = '';
  officename: string = '';
  officeaddress: string = '';
  officeCitySZ: string = '';
  officephone: string = '';
  officeemail: string = '';
  office_npi_txt: string = '';
  License: string = '';
  currentDate: Date = new Date();
  ProductList: ItemModel[] = [];
  ItemContacts: Item_ContactsDataModel[] = [];
  manufacture: ManufacturerModel[] = [];
  allLookupData: LookupModel[] = [];

  constructor(
    private sanitized: DomSanitizer,
    private toaster: ToastrService,
    private activateRoute: ActivatedRoute,
    private _patientService: PatientService,
    private _clinicalService: ClinicalService,
    private router: Router,
    private _lookupService: LookupService,
    private _globalService: GlobalService,
    private _userService: UserService,
    private globalService: GlobalService,
    private modalService: BsModalService,
    private _manufactureService: ManufacturerService
  ) {
    this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.patient_Id = this.patientDetail.patient_id;
    this.allLookupData = JSON.parse(localStorage.getItem('AllLookupData')!);
    this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['examId']) {
        this.examId = params['examId'];
      }
    });
  }

  ngOnInit() {
  debugger;
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-dark-blue',
        adaptivePosition: true,
        showWeekNumbers: false,
      }
    );
    this.getProducts();
    let examOffice = new OfficeModel();
    examOffice.name_txt = 'Loading Records...';
    examOffice.office_id = '00000000-0000-0000-0000-000000000000';
    this.exam.omR_Exam.cdod = '.3/.3';
    this.exam.omR_Exam.cdos = '.3/.3';
    this.exam.omR_Exam.angleEstOD = '4';
    this.exam.omR_Exam.angleEstOS = '4';
    this.office.push(examOffice);
    this.columnNote = [
      {
        headerName: 'Created Date',
        field: 'creationDate',
        sortable: true,
        resizable: true,
        rowDrag: true,
        filter: true,
        floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY');
        },
      },
      {
        headerName: 'Patient Comment',
        field: 'patientComments',
        sortable: true,
        filter: 'agTextColumnFilter',
        resizable: true,
        floatingFilter: false,
        width: 600,
      },
    ];
    this.getAllLookup();
    setTimeout(() => {
      this.loadExamData();
    }, 2000);
  }

  loadExamData() {
    this.defaultImageEXTSRC = '../../../assets/img/EXT.jpg';
    this.defaultImageINTSRC = '../../../assets/img/INT.jpg';
    if (this.examId == '') {
      this.imageEXTSRC = '../../../assets/img/EXT.jpg';
      this.imageINTSRC = '../../../assets/img/INT.jpg';
      this.imageBackSlash = '../../../assets/img/backslash.png';
      this.imageForwardSlash = '../../../assets/img/forwardslash.png';
      this.imageCross = '../../../assets/img/crossslash.png';
      this.exam = new ExamModel();
      this.exam.omR_ProgressNotes.push(new OMR_ProgressNotesModel());
      this.exam.patient_ID = this.patient_Id;
      this.exam.provider_ID = this.patientDetail.referring_provider_id;
      this.omr_ProgressNotes.provider_ID =
        this.patientDetail.referring_provider_id;
      this.exam.omR_Exam.tonometryTime = moment().format('LT');
      this.exam.omR_Exam.dpaTime = moment().format('LT');
      this.exam.omR_Exam.headFaceNormal = true;
      this.exam.omR_Exam.pptOriented = true;
      this.exam.omR_Exam.pupilsPERRLA = true;
      this.exam.omR_Exam.coverOrtho_DNN = true;
      this.exam.omR_Exam.npcttn = true;
      this.exam.omR_Exam.eomNormal = true;
      this.exam.omR_Exam.fieldScreenConfrontationWNL = true;
      //this.exam.omR_Exam.odEyePict. = "";

      this.showComponent = true;
      this._clinicalService.getLastOmrExam(this.patient_Id).subscribe((res) => {
        debugger;
        if (res != null) {
          this.exam.omR_Exam.curRXODSphere = res.distSubjectODSphere;
          this.exam.omR_Exam.curRXOSSphere = res.distSubjectOSSphere;
          this.exam.omR_Exam.curRXAdd = res.nearSubjectOD; //exam.omR_Exam.nearSubjectOD
          this.examCopy = Object.assign({}, this.exam);
        }
        //Object.assign({}, res);
        // if(this.exam.omR_ProgressNotes.length == 0){
        //   this.exam.omR_ProgressNotes.push(new OMR_ProgressNotesModel());
        // }
      });

      this.addAssessment();
      this.addAssessment();
      // this.addAssessment();

      this.getUser();
      this.onExamSelectUser(this.exam.provider_ID);
      this.onSelectUser(this.exam.provider_ID);
      this.getOffice();
      // for (let i = 0; i < 3; i++) {
      //   this.exam.exam_Assessments.push(new Exam_AssessmentsModel);
      // }
    } else {
      debugger;
      this._clinicalService.getByExamId(this.examId).subscribe((res) => {
        // this.exam = new ExamModel();
        this.imageEXTSRC = res.omR_Exam.oDEyePictString
          ? res.omR_Exam.oDEyePictString
          : '../../../assets/img/EXT.jpg';
        this.imageINTSRC = res.omR_Exam.oSEyePictString
          ? res.omR_Exam.oSEyePictString
          : '../../../assets/img/INT.jpg';
        this.exam = Object.assign({}, res);
        if (this.exam.omR_ProgressNotes.length == 0) {
          // this.exam.omR_ProgressNotes.push(new OMR_ProgressNotesModel());
          this.omr_ProgressNotes.provider_ID = this.exam.provider_ID;
        }
        this.exam.exam_Assessments = this.exam.exam_Assessments.sort((a, b) => {
          if (a.ccda_code < b.ccda_code) {
            return -1;
          }
          if (a.ccda_code > b.ccda_code) {
            return 1;
          }
          return 0;
        });
        this.getUser();
        this.getAllProgressNote();
        this.onExamSelectUser(this.exam.provider_ID);
        // this.onSelectUser(this.omr_ProgressNotes.provider_ID);
        this.getOffice();
        this.showComponent = true;
        this.slitLampOtherEnableDisable();
      });
    }

    this.exam.omR_Exam.distSubjectODSphere =
      this._clinicalService.distSubjOD != ''
        ? '+' + this._clinicalService.distSubjOD
        : '';
    this.exam.omR_Exam.distSubjectOSSphere =
      this._clinicalService.distSubjOS != ''
        ? '+' + this._clinicalService.distSubjOS
        : '';
    this.exam.omR_Exam.nearSubjectOD = this._clinicalService.nearSubjOD;
    this.exam.omR_Exam.nearSubjectOS = this._clinicalService.nearSubjOS;
    if (this.exam.omR_Exam.cdod == '') {
      this.exam.omR_Exam.cdod = '.3/.3';
    }
    if (this.exam.omR_Exam.cdos == '') {
      this.exam.omR_Exam.cdos = '.3/.3';
    }
    if (this.exam.omR_Exam.angleEstOD == '') {
      this.exam.omR_Exam.angleEstOD = '4';
    }
    if (this.exam.omR_Exam.angleEstOS == '') {
      this.exam.omR_Exam.angleEstOS = '4';
    }

    // this.rowNote = [];
  }

  addAssessment() {
    var length = this.exam.exam_Assessments.length;
    var exam_AssessmentsModel = new Exam_AssessmentsModel();
    exam_AssessmentsModel.ccda_code = String.fromCharCode(length + 65);
    this.exam.exam_Assessments.push(exam_AssessmentsModel);
  }

  addChildAssessment(item: Exam_AssessmentsModel) {
    item.exam_Assessment_Plans.push(new Exam_Assessment_PlansModel());
  }

  setTime() {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? 0 + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  saveExam(isNavigate: boolean) {
    debugger;
    if (this.exam.exam_ID == '') {
      if (this.omr_ProgressNotes.slitLampExaminationSelect != '') {
        this.exam.omR_ProgressNotes[0] = this.omr_ProgressNotes;
      }
      this._clinicalService.saveExam(this.exam).subscribe(async(res: ExamModel) => {
          this.exam = res;
          debugger;
          if (this.oldPatientComments != this.omr_ProgressNotes.patientComments)
          {
            this.saveProgressNote();
            await this.delay(4000);
            if(res.omR_ProgressNotes.length > 0){
              this.rowNote = res.omR_ProgressNotes;
              this.loadLatestProgressNotes();
            }
            this.oldPatientComments = this.omr_ProgressNotes.patientComments;
          }else{
            this.toaster.success('Record Inserted Successfully.');
          }
          this.router.navigate(['/patient/add-exam'], {
            queryParams: {
              patientId: this.patientDetail.patientID,
              examId: this.exam.exam_ID,
            },
          });
        },
        (error) => {
          this.toaster.error('Error occurs while insert exam form.');
        }
      );
    } else {
      this._clinicalService.updateExam(this.exam).subscribe(async (res: ExamModel) => {

          this.exam = res;
          if (this.oldPatientComments != this.omr_ProgressNotes.patientComments) {
            this.omr_ProgressNotes.progressNotesID = '';
            this.saveProgressNote();
            await this.delay(4000);
            //this.toaster.success('Record Updated Successfully.');
            if (isNavigate) {
              var domain = new URL(location.href).hostname;
              var port = new URL(location.href).port;
              var finalURL = 'https://';
              finalURL = finalURL + domain;
              if (port != undefined && port != '') {
                finalURL = finalURL + ':' + port;
              }
              var patient = JSON.parse(
                localStorage.getItem('ActivePatient')!
              ).patientID.toString();
              finalURL = finalURL + '/patient/create' + '?patientId=' + patient;
              location.href = finalURL;
              window.location.reload();
            } else {
              window.location.reload();
            }
          } else {
            // this.UpdateProgressNote();
            // await this.delay(3000);
            this.toaster.success('Record Updated Successfully.');
            if (isNavigate) {
              domain = new URL(location.href).hostname;
              port = new URL(location.href).port;
              finalURL = 'https://';
              finalURL = finalURL + domain;
              if (port != undefined && port != '') {
                finalURL = finalURL + ':' + port;
              }
              patient = JSON.parse(
                localStorage.getItem('ActivePatient')!
              ).patientID.toString();
              finalURL = finalURL + '/patient/create' + '?patientId=' + patient;
              location.href = finalURL;
            } else {
              window.location.reload();
            }
          }
        },
        (error) => {
          this.toaster.error('Error occurs while update exam form.');
        }
      );
    }
  }
  saveandCloseExam() {
    if (this.exam.exam_ID == '') {
      if (this.omr_ProgressNotes.slitLampExaminationSelect != '') {
        this.exam.omR_ProgressNotes[0] = this.omr_ProgressNotes;
      }
      this._clinicalService
        .saveExam(this.exam)
        .subscribe(async (res: ExamModel) => {
          this.exam = res;
          if (this.oldPatientComments != this.omr_ProgressNotes.patientComments) {
            this.omr_ProgressNotes.progressNotesID = '';
            this.saveandCloseProgressNote();
          }

          switch (GlobalService.componentName) {
            case 'extra-test':
              this.router.navigate(['/patient/extra-test'], {
                queryParams: {
                  patientId: this.patientDetail.patientID,
                  examId: this.exam.exam_ID,
                },
              });
              break;
            case 'spectacle':
              this.router.navigate(['/patient/spectacle'], {
                queryParams: {
                  patientId: this.patientDetail.patientID,
                  examId: this.exam.exam_ID,
                },
              });
              break;
            case 'cpoe':
              this.router.navigate(['/patient/cpoe'], {
                queryParams: {
                  patientId: this.patientDetail.patientID,
                  examId: this.exam.exam_ID,
                },
              });
              break;
            case 'contact-order':
              this.router.navigate(['/patient/contact-order'], {
                queryParams: {
                  patientId: this.patientDetail.patientID,
                  examId: this.exam.exam_ID,
                },
              });
              break;
            case 'free-statement':
              this.router.navigate(['/patient/misc-free-statement'], {
                queryParams: {
                  patientId: this.patientDetail.patientID,
                  examId: this.exam.exam_ID,
                  isExamTab: false,
                },
              });
              break;
            default:
              this.router.navigate(['/patient/hcfa'], {
                queryParams: {
                  patientId: this.patientDetail.patientID,
                  examId: this.exam.exam_ID,
                },
              });
              break;
          }
        });
    } else {
      this._clinicalService
        .updateExam(this.exam)
        .subscribe(async (res: ExamModel) => {
          this.exam = res;
          if (
            this.oldPatientComments != this.omr_ProgressNotes.patientComments
          ) {
            this.omr_ProgressNotes.progressNotesID = '';
            this.saveandCloseProgressNote();
          }
        });
    }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  getAllProgressNote() {
    debugger;
    if(this.examId == ""){
      this.examId = this.exam.exam_ID;
    }
    this._clinicalService
      .getProgressNotes(this.examId)
      .subscribe((res: any) => {
        if (res.length <= 1) {
          if (this.oldPatientComments != res.patientComments) {
            this.rowNote = res;
            this.loadLatestProgressNotes();
            this.oldPatientComments = this.omr_ProgressNotes.patientComments;
          }
        } else {
          this.rowNote = res;
          this.loadLatestProgressNotes();
          this.oldPatientComments = this.omr_ProgressNotes.patientComments;
        }
      });
  }
  CreateNew(city: string) {}

  UpdateProgressNote() {

    if (this.exam.exam_ID == '') {
      this.toaster.warning('Save Exam First.');
    } else {
      this.omr_ProgressNotes.exam_ID = this.exam.exam_ID;
      this.omr_ProgressNotes.creationDate = new Date();
      let last = this.rowNote[0];
      var isTCPNChanged = this.isChangedTCPN();
      this._clinicalService.updateProgressNote(this.omr_ProgressNotes).subscribe((res) => {
        debugger;
        if(isTCPNChanged){
          this._clinicalService.updateContactOrderForm(this.omR_ContactOrderForm).subscribe(res => {
            this.exam.omR_ContactOrderForm = res;
          })
        }
          this.getAllProgressNote();
          this.toaster.success('Record Updated Successfully.');
          this.editProgressNote = false;
          this.omr_ProgressNotes = new OMR_ProgressNotesModel();
      });
    }
  }
  saveandCloseProgressNote() {
    this.omr_ProgressNotes.exam_ID = this.exam.exam_ID;
    this.omr_ProgressNotes.creationDate = new Date();
    if (this.oldPatientComments != this.omr_ProgressNotes.patientComments) {
      this.omr_ProgressNotes.progressNotesID = '';
    }
    if (this.omr_ProgressNotes.progressNotesID == '') {
      if (this.oldPatientComments != this.omr_ProgressNotes.patientComments) {
        this._clinicalService
          .saveProgressNote(this.omr_ProgressNotes)
          .subscribe((res) => {});
      }
    }
  }

  saveProgressNote() {
    debugger;
    if (this.exam.exam_ID == '') {
      this.toaster.warning('Save Exam First.');
    } else {
      this.omr_ProgressNotes.exam_ID = this.exam.exam_ID;
      this.omr_ProgressNotes.creationDate = new Date();
      if (this.oldPatientComments != this.omr_ProgressNotes.patientComments) {
        this.omr_ProgressNotes.progressNotesID = '';
      }
      if (this.omr_ProgressNotes.progressNotesID == '') {
        if (this.oldPatientComments != this.omr_ProgressNotes.patientComments) {
          var isTCPNChanged = this.isChangedTCPN();
          this._clinicalService.saveProgressNote(this.omr_ProgressNotes).subscribe((res) => {
            if(isTCPNChanged){
              this._clinicalService.updateContactOrderForm(this.omR_ContactOrderForm).subscribe(res => {
                this.exam.omR_ContactOrderForm = res;
              })
            }
            this.getAllProgressNote();
            this.toaster.success('Record Saved Successfully.');
            this.omr_ProgressNotes = new OMR_ProgressNotesModel();
            this.editProgressNote = false;
          });
        }
      }
    }
  }

  isChangedTCPN(){
    debugger;
    var oldRow = this.rowNote[0];
    if(oldRow){
      if(oldRow.odPower != this.omr_ProgressNotes.odPower || oldRow.odbc != this.omr_ProgressNotes.odbc || oldRow.oddiam != this.omr_ProgressNotes.oddiam || oldRow.odBrand != this.omr_ProgressNotes.odBrand ){
        this.assignContactOrderValues();
        return true;
      }
      if(oldRow.osPower != this.omr_ProgressNotes.osPower || oldRow.osbc != this.omr_ProgressNotes.osbc || oldRow.osdiam != this.omr_ProgressNotes.osdiam || oldRow.osBrand != this.omr_ProgressNotes.osBrand){
        this.assignContactOrderValues();
        return true;
      }
      return false;
    }else{
      this.assignContactOrderValues();
        return true;
    }
  }
  assignContactOrderValues(){
    debugger;
    this.omR_ContactOrderForm = this.exam.omR_ContactOrderForm;
    this.omR_ContactOrderForm.notification_ind = false;
    this.omR_ContactOrderForm.odPower = this.omr_ProgressNotes.odPower;
    this.omR_ContactOrderForm.odbc = this.omr_ProgressNotes.odbc;
    this.omR_ContactOrderForm.odDiam = this.omr_ProgressNotes.oddiam;
    this.omR_ContactOrderForm.odLens = this.omr_ProgressNotes.odBrand;
    if(this.omr_ProgressNotes.odBrand){
      this.omR_ContactOrderForm.odManufacturer = this.selectBrandManufacture(this.omr_ProgressNotes.odBrand);
    }
    this.omR_ContactOrderForm.osPower = this.omr_ProgressNotes.osPower;
    this.omR_ContactOrderForm.osbc = this.omr_ProgressNotes.osbc;
    this.omR_ContactOrderForm.osDiam = this.omr_ProgressNotes.osdiam;
    this.omR_ContactOrderForm.osLens = this.omr_ProgressNotes.osBrand;
    if(this.omr_ProgressNotes.osBrand){
      this.omR_ContactOrderForm.osManufacturer = this.selectBrandManufacture(this.omr_ProgressNotes.osBrand);
    }

  }

  deleteProgressNote() {
    if (
      confirm('Are you sure you want to delete this Progress Note?') == true
    ) {
      this._clinicalService
        .deleteProgressNote(this.omr_ProgressNotes.progressNotesID)
        .subscribe((res) => {
          this.getAllProgressNote();
          this.toaster.success('Record Deleted Successfully.');
          this.omr_ProgressNotes = new OMR_ProgressNotesModel();
          this.editProgressNote = false;
        });
    }
  }

  printProgressNote() {}

  // serachProgressNote() {
  //   this.rowNote = this.omr_ProgressNotesList.filter(x => x.patientComments.toLowerCase().includes(this.progressNoteSearchSting.trim().toLowerCase()));
  // }

  setEXTImage(img: any) {
    const imageFile = new File([img], 'EXT', { type: 'image/jpg' });
    this.exam.omR_Exam.odEyePictFile = imageFile;
    this.exam.omR_Exam.oDEyePictString = img;
  }
  populatePrintValues() {
    this.office = this.office.filter(
      (x) => x.office_id == this.patientDetail.defaultOfficeID
    );
    // if (this.officelist[0]?.name_txt != null) {
    //   this.officename = this.officelist[0]?.name_txt
    // }
    // if (this.officelist[0]?.address_1_txt != null && this.officelist[0].address_2_txt) {
    //   this.officeaddress = this.officelist[0]?.address_1_txt + " " + this.officelist[0]?.address_2_txt;
    // }
    // if (this.officelist[0]?.phone_1_txt != null) {
    //   this.officephone = this.officelist[0]?.phone_1_txt;
    // }
    if (this.office[0]?.name_txt != null) {
      this.officename = this.office[0]?.name_txt;
    }
    // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
    //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
    // }
    if (this.office[0]?.address_1_txt) {
      this.officeaddress = `${this.office[0]?.address_1_txt}`;
    }
    if(this.office[0]?.address_2_txt){
      this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
    }
    if (this.office[0]?.city_txt) {
      this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
    }
    if (this.office[0]?.phone_1_txt) {
      this.officephone = this.office[0]?.phone_1_txt;
    }
    if(this.office[0]?.npi_txt){
      this.office_npi_txt = this.office[0]?.npi_txt;
    }
    if(this.office[0]?.email_address_txt){
      this.officeemail = this.office[0]?.email_address_txt;
    }
    // let officeObj = this.office.filter(x => x.office_id == this.exam.office_ID);
    //   if (officeObj[0]?.name_txt) {
    //     this.officename = officeObj[0]?.name_txt
    //   }
    //   if (officeObj[0]?.address_1_txt != null && officeObj[0].address_2_txt) {
    //     this.officeaddress = officeObj[0]?.address_1_txt + " " + officeObj[0]?.address_2_txt;
    //   }
    //   if (officeObj[0]?.phone_1_txt != null) {
    //     this.officephone = officeObj[0]?.phone_1_txt;
    //   }
  }
  setINTImage(img: any) {
    const imageFile = new File([img], 'INT', { type: 'image/jpg' });
    this.exam.omR_Exam.osEyePictFile = imageFile;
    this.exam.omR_Exam.oSEyePictString = img;
  }

  compare(array: LookupModel[]) {
    array.sort((a, b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))) {
        if (Number(a.name_txt) > Number(b.name_txt)) {
          return 1;
        } else if (Number(a.name_txt) < Number(b.name_txt)) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1;
        } else if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }

  getAllLookup() {
    this._lookupService
      .getAllLookupData()
      .subscribe((res: Array<LookupModel>) => {
        // res = res.filter(x=>x.active_ind == true);
        this.examVisitTypeLookup = res.filter((x) =>
            x.group_txt.toLowerCase() == 'examvisittype'.toLowerCase() &&
            x.active_ind == true
        );
        this.examVisitTypeLookup = this.compare(this.examVisitTypeLookup);
        this.examVisitTypeCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'examvisittype'.toLowerCase()
        );

        this.locationLookUp = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Location'.toLowerCase() &&
            x.active_ind == true
        );
        this.locationLookUp = this.compare(this.locationLookUp);
        // this.locationLookUp = this.locationLookUp.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });
        this.locationCopyLookUp = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Location'.toLowerCase()
        );
        this.qualityLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Quality'.toLowerCase() &&
            x.active_ind == true
        );
        this.qualityLookup = this.compare(this.qualityLookup);
        // this.qualityLookup = this.qualityLookup.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });
        this.qualityCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Quality'.toLowerCase()
        );
        this.severityLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Severity'.toLowerCase() &&
            x.active_ind == true
        );
        this.severityLookup = this.compare(this.severityLookup);
        // this.severityLookup = this.severityLookup.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });
        this.severityCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Severity'.toLowerCase()
        );
        this.durationLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Duration'.toLowerCase() &&
            x.active_ind == true
        );
        this.durationLookup = this.compare(this.durationLookup);
        // this.durationLookup = this.durationLookup.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });

        this.durationCopyLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Duration'.toLowerCase() &&
            x.active_ind == true
        );
        this.timingLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Timing'.toLowerCase() &&
            x.active_ind == true
        );
        this.timingLookup = this.compare(this.timingLookup);
        // this.timingLookup = this.timingLookup.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });
        this.timingCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Timing'.toLowerCase()
        );

        this.contextLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'context'.toLowerCase() &&
            x.active_ind == true
        );
        this.contextLookup = this.compare(this.contextLookup);
        this.contextCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'context'.toLowerCase()
        );

        this.factorLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'modifyingfactors'.toLowerCase() &&
            x.active_ind == true
        );
        this.factorLookup = this.compare(this.factorLookup);
        this.factorCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'modifyingfactors'.toLowerCase()
        );

        this.symptomsLook = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'signssymptoms'.toLowerCase() &&
            x.active_ind == true
        );
        this.symptomsLook = this.compare(this.symptomsLook);
        this.symptomsCopyLook = res.filter(
          (x) => x.group_txt.toLowerCase() == 'signssymptoms'.toLowerCase()
        );

        this.bcLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'bc'.toLowerCase() &&
            x.active_ind == true
        );
        this.bcLookup = this.compare(this.bcLookup);
        this.bcLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'bc'.toLowerCase()
        );

        this.diamLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'diam'.toLowerCase() &&
            x.active_ind == true
        );
        this.diamLookup = this.compare(this.diamLookup);
        this.diamLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'diam'.toLowerCase()
        );

        this.brandLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'brand'.toLowerCase() &&
            x.active_ind == true
        );
        this.brandLookup = this.compare(this.brandLookup);
        this.brandLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'brand'.toLowerCase()
        );

        this.tonometryosod = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'tonometryosod'.toLowerCase() &&
            x.active_ind == true
        );
        this.tonometryosod = this.compare(this.tonometryosod);
        this.tonometryosodCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'tonometryosod'.toLowerCase()
        );
        this.slitLampExamLookup = res.filter((x) => x.group_txt.toLowerCase() == 'slitlampexam'.toLowerCase() && x.active_ind == true);
        this.slitLampExamLookup = this.compare(this.slitLampExamLookup);

        this.assessment = res.filter((x) => x.group_txt.toLowerCase() == 'assessment'.toLowerCase() && x.active_ind == true);
        this.assessment = this.compare(this.assessment);
        this.assessmentCopy = res.filter((x) => x.group_txt.toLowerCase() == 'assessment'.toLowerCase());

        this.plan = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'plan'.toLowerCase() &&
            x.active_ind == true
        );
        this.plan = this.compare(this.plan);
        this.planCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'plan'.toLowerCase()
        );

        this.NearSubjLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'nearsubj'.toLowerCase() &&
            x.active_ind == true
        );
        this.NearSubjLookup = this.compare(this.NearSubjLookup);
        this.NearSubjLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'nearsubj'.toLowerCase()
        );

        this.headFace = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'HeadFace'.toLowerCase() &&
            x.active_ind == true
        );
        this.headFace = this.compare(this.headFace);
        // this.headFace = this.headFace.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });
        this.headFaceCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'HeadFace'.toLowerCase()
        );
        this.psychLookup = res.filter((x) =>
            x.group_txt.toLowerCase() == 'PhychMoodAffect'.toLowerCase() &&
            x.active_ind == true
        );
        this.psychLookup = this.compare(this.psychLookup);
        // this.psychLookup = this.psychLookup.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1
        //   }
        //   return 0
        // });

        this.psychLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'PhychMoodAffect'.toLowerCase()
        );
        this.farNearLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'VisualAcuities'.toLowerCase() &&
            x.active_ind == true
        );
        this.farNearLookup = this.compare(this.farNearLookup);
        // this.farNearLookup = this.farNearLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.farNearCopyLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'VisualAcuities'.toLowerCase() &&
            x.active_ind == true
        );
        this.pupils = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Pupils'.toLowerCase() &&
            x.active_ind == true
        );
        this.pupils = this.compare(this.pupils);
        // this.pupils = this.pupils.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.pupilsCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Pupils'.toLowerCase()
        );
        this.coverTest = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'CoverTest'.toLowerCase() &&
            x.active_ind == true
        );
        this.coverTest = this.compare(this.coverTest);
        // this.coverTest = this.coverTest.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.coverTestCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'CoverTest'.toLowerCase()
        );
        this.npcLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'NPC'.toLowerCase() &&
            x.active_ind == true
        );
        this.npcLookup = this.compare(this.npcLookup);
        // this.npcLookup = this.npcLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.npcCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'NPC'.toLowerCase()
        );
        this.eomLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'EOM'.toLowerCase() &&
            x.active_ind == true
        );
        this.eomLookup = this.compare(this.eomLookup);
        // this.eomLookup = this.eomLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.eomCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'EOM'.toLowerCase()
        );
        this.confrontationsLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Confrontations'.toLowerCase() &&
            x.active_ind == true
        );
        this.confrontationsLookup = this.compare(this.confrontationsLookup);
        // this.confrontationsLookup = this.confrontationsLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.confrontationsCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Confrontations'.toLowerCase()
        );
        this.angleEstLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'AngleEst'.toLowerCase() &&
            x.active_ind == true
        );
        this.angleEstLookup = this.compare(this.angleEstLookup);
        // this.angleEstLookup = this.angleEstLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.angleEstCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'AngleEst'.toLowerCase()
        );
        this.internalLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'IO'.toLowerCase() &&
            x.active_ind == true
        );
        this.internalLookup = this.compare(this.internalLookup);
        // this.internalLookup = this.internalLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.internalCopyLookup = res.filter(
          (x) => x.group_txt.toLowerCase() == 'IO'.toLowerCase()
        );
        this.internalODOS = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'InternalODOS'.toLowerCase() &&
            x.active_ind == true
        );
        this.internalODOS = this.compare(this.internalODOS);
        // this.internalODOS = this.internalODOS.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.internalODOSCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'InternalODOS'.toLowerCase()
        );
        this.tonometry = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Tonometry'.toLowerCase() &&
            x.active_ind == true
        );
        this.tonometry = this.compare(this.tonometry);
        // this.tonometry = this.tonometry.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.tonometryCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Tonometry'.toLowerCase()
        );
        this.tropicLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Tropic'.toLowerCase() &&
            x.active_ind == true
        );
        this.tropicLookup = this.compare(this.tropicLookup);
        // this.tropicLookup = this.tropicLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.tropicLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Tropic'.toLowerCase()
        );
        this.phenylLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Phenyl'.toLowerCase() &&
            x.active_ind == true
        );
        this.phenylLookup = this.compare(this.phenylLookup);
        // this.phenylLookup = this.phenylLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.phenylLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Phenyl'.toLowerCase()
        );
        this.ParedLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Pared'.toLowerCase() &&
            x.active_ind == true
        );
        this.ParedLookup = this.compare(this.ParedLookup);
        // this.ParedLookup = this.ParedLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.ParedLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Pared'.toLowerCase()
        );
        this.cycloLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'Cyclo'.toLowerCase() &&
            x.active_ind == true
        );
        this.cycloLookup = this.compare(this.cycloLookup);
        // this.cycloLookup = this.cycloLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.cycloLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'Cyclo'.toLowerCase()
        );
        this.tcPlan = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'TCPlan'.toLowerCase() &&
            x.active_ind == true
        );
        this.tcPlan = this.compare(this.tcPlan);
        // this.tcPlan = this.tcPlan.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.tcPlanCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'TCPlan'.toLowerCase()
        );
        this.tcAssessment = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'TCAssessment'.toLowerCase() &&
            x.active_ind == true
        );
        this.tcAssessment = this.compare(this.tcAssessment);
        // this.tcAssessment = this.tcAssessment.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.tcAssessmentCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'TCAssessment'.toLowerCase()
        );
        this.ampLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'amp'.toLowerCase() &&
            x.active_ind == true
        );
        this.ampLookup = this.compare(this.ampLookup);
        // this.ampLookup = this.ampLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.ampLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'amp'.toLowerCase()
        );
        this.praLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'pra'.toLowerCase() &&
            x.active_ind == true
        );
        this.praLookup = this.compare(this.praLookup);
        // this.praLookup = this.praLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.praLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'pra'.toLowerCase()
        );
        this.nraLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'nra'.toLowerCase() &&
            x.active_ind == true
        );
        this.nraLookup = this.compare(this.nraLookup);
        // this.nraLookup = this.nraLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.nraLookupCopy = res.filter(
          (x) => x.group_txt.toLowerCase() == 'nra'.toLowerCase()
        );
        this.tcplanLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'tcplan'.toLowerCase() &&
            x.active_ind == true
        );
        this.tcplanLookup = this.compare(this.tcplanLookup);
        // this.tcplanLookup = this.tcplanLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.tcplanLookupCopy = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'tcplan'.toLowerCase() &&
            x.active_ind == true
        );
        this.cleanQHSLookup = res.filter(
          (x) =>
            x.group_txt.toLowerCase() == 'CleanQHS'.toLowerCase() &&
            x.active_ind == true
        );
        this.cleanQHSLookup = this.compare(this.cleanQHSLookup);
        // this.cleanQHSLookup = this.cleanQHSLookup.sort((a, b) => {
        //   if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
        //     return -1
        //   }
        //   if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
        //     return 1
        //   }
        //   return 0
        // });
        this.cleanQHSCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'CleanQHS'.toLowerCase());
        this.getConfigLookup();
      });
  }

  addLokupItem(event: any, grp: string): boolean {
    this.globalService.saveGlobalDropdown(
      event.target.value,
      grp,
      this.getAllLookup
    );
    return true;
  }

  checkBox(event: any) {
    debugger;
    if(event.target){
      this._globalService.checkBoxBroadcaster(event.target.checked);
    } else {
      this._globalService.checkBoxBroadcaster(event);
    }

  }

  getUser() {
    // let practiceId = localStorage.getItem('practice')?.toString();
    // this._lookupService.getRenderingProvider(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
    //   this.userList = res;
    //   this.onExamSelectUser(this.exam.provider_ID)
    //   this.onSelectUser(this.omr_ProgressNotes.provider_ID);
    //   // this.onExamSelectUser(this.exam.omR_ProgressNotes[0].provider_ID)
    //   this.getOffice();
    // }, (error) => {
    //   this.toaster.error(error.error);
    // });

    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    this.user1 = this.userList.filter(
      (x) => x.user_id == this.exam.provider_ID
    );

    this.user1 = this.user1[0];
    if (this.user1?.npi_txt) {
      this.License = this.user1?.npi_txt;
    }
    // if (this.user1?.email_address_txt) {
    //   this.officeemail = this.user1?.email_address_txt;
    // }
    // this.onExamSelectUser(this.exam.provider_ID);
    // this.onSelectUser(this.omr_ProgressNotes.provider_ID);
    // this.getOffice();
  }

  onSelectUser(id: any) {
    this.user = this.userList.find((a) => a.user_id == id);
    if (this.user) {
      if (this.user.npi_txt) {
        this.License = this.user.npi_txt;
      }
      this.imgURL = this.user?.signature_img
        ? 'data:image/png;base64,' + this.user.signature_img
        : '';
      this.License = this.user?.license_txt;
      this.user1 = this.user;
    } else {
      this.imgURL = '';
      this.user = new UserModel();
    }
    return true;
  }

  onExamSelectUser(id: any) {
    this.examuser = this.userList.find((a) => a.user_id == id);
    if (this.examuser) {
      this.examimgURL = this.examuser?.signature_img
        ? 'data:image/png;base64,' + this.examuser.signature_img
        : '';
    } else {
      this.examimgURL = '';
      this.examuser = new UserModel();
    }
    return true;
  }
  getProducts() {
    debugger;
    this._lookupService.getItemsWithContact().subscribe((res: any) => {
      this.ProductList = res.item1;
      this.ItemContacts = res.item2;
    });
  }
  selectBrand(event: any, bod: string) {
  debugger;
    if(event){
      var val = event.name_txt.trim();
      var item = this.ProductList.filter((x) => x.brand !== null && x.brand.trim().toLocaleLowerCase() == val.toLocaleLowerCase());
      if (item.length > 0) {
        var contact = this.ItemContacts.filter((x) => x.item_id == item[0].item_id);
        if (bod == 'od') {
          this.omr_ProgressNotes.oddiam = contact[0].diameter_txt;
          this.omr_ProgressNotes.odbc = contact[0].basecurve_txt;
        } else {
          this.omr_ProgressNotes.osdiam = contact[0].diameter_txt;
          this.omr_ProgressNotes.osbc = contact[0].basecurve_txt;
        }
        return;
      }
      if (bod == 'od') {
        this.omr_ProgressNotes.oddiam = '';
        this.omr_ProgressNotes.odbc = '';
      } else {
        this.omr_ProgressNotes.osdiam = '';
        this.omr_ProgressNotes.osbc = '';
      }
    }
  }
  getManufacture() {
    this._manufactureService.getAll().subscribe(res => {
      this.manufacture = res;
      this.manufacture = this.manufacture.filter(x => x.active_ind == true);
    })
  }
  selectBrandManufacture(brand: string): string {
    var item = this.ProductList.filter((x) => x.brand !== null && x.brand.trim().toLocaleLowerCase() == brand.trim().toLocaleLowerCase());
    if (item.length > 0) {
      if(item[0].manufacturer_id){
        var manufactureItem = this.manufacture.filter(x => x.manufacturer_id == item[0].manufacturer_id);
        if(manufactureItem.length > 0){
          return manufactureItem[0].name_txt;
        }
      }
    }
    return "";
  }
  clearBrand(bod: string){
    if (bod == 'od') {
      this.omr_ProgressNotes.oddiam = '';
      this.omr_ProgressNotes.odbc = '';
    } else {
      this.omr_ProgressNotes.osdiam = '';
      this.omr_ProgressNotes.osbc = '';
    }
  }

  setValue() {
    this._clinicalService.distSubjOD = this.exam.omR_Exam.distSubjectODSphere;
    this._clinicalService.distSubjOS = this.exam.omR_Exam.distSubjectOSSphere;
    this._clinicalService.nearSubjOD = this.exam.omR_Exam.nearSubjectOD;
    this._clinicalService.nearSubjOS = this.exam.omR_Exam.nearSubjectOS;
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService
      .updateLookup(itemCopy, dbType, item)
      .subscribe((res: LookupModel[]) => {
        if (dbType == this.dbEnum.QE_DEV_SQL_DB) {
          this.getAllLookup();
        } else {
          this.getConfigLookup();
        }
      });
  }
  editBrand(item: LookupModel[],
    itemCopy: LookupModel[],
    dbType: number,
    componentName: string
  ) {
    this.bsModalRef = new BsModalRef();
    const initialState = {
      item: itemCopy,
      diamLookup: this.diamLookupCopy,
      bcLookup: this.bcLookupCopy,
      productList: this.ProductList,
      itemContacts: this.ItemContacts,
      title: 'Edit Lookup',
      dbType: dbType,
    };
    this.bsModalRef = this.modalService.show(AddBrandComponent, {
      initialState,
    });
    this.bsModalRef.setClass('modal-xl');
    this.bsModalRef.onHide.subscribe((res: any) => {

      if (componentName == 'add-brand') {
        this.getAllLookup();
        this.getProducts();
      }
    });
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }
  keyFunc(event: any, item1?: any, property?: any) {

    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(
    val: any,
    name_txt: string,
    item: LookupModel[],
    group_txt: string,
    item1?: any,
    property?: any,
    dbType: number = DbEnum.QE_DEV_SQL_DB
  ) {
    //if(this.check_value == ""){
    this.check_value = val;
    //}
    if (this.check_value != '') {
      var index = item.findIndex(
        (x) =>
          x.name_txt.trim().toLowerCase() ==
          this.check_value.trim().toLowerCase()
      );
      //var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      // if (index != index1 || index == index1) {
      if (index < 0) {
        var lookupModel = new LookupModel();
        if (item[0]?.group_txt) {
          lookupModel.group_txt = item[0]?.group_txt;
          lookupModel.sub_group_txt = item[0]?.sub_group_txt;
        } else {
          lookupModel.group_txt = group_txt!;
          lookupModel.sub_group_txt = 'All';
        }
        lookupModel.active_ind = true;
        lookupModel.name_txt = val;

        if (this.check_value != lookupModel.name_txt) {
          lookupModel.name_txt = this.check_value;
        }
        if (dbType != 1) {
          var content = 'Would you like to save this ' + name_txt + ' list?';
          this.globalService
            .modal(content, 'New Item?', 'OK', 'Cancel')
            .subscribe((res) => {
              if (res.res) {
                // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
                if (dbType == DbEnum.QE_DEV_SQL_DB) {
                  debugger;
                  this._lookupService
                    .insertLookup(lookupModel)
                    .subscribe((res) => {
                      if(lookupModel.group_txt == "brand"){
                        this.mapProduct(lookupModel);
                        this._lookupService.insertItem(this.productModel).subscribe(res => {
                          this.getProducts();
                        });
                      }
                      this.getAllLookup();
                      this.toaster.success('Record Saved Successfully.');
                      item1[property] = val; //this.check_value;
                    });
                }
                // else {
                //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
                //     this.toaster.success('Record Saved Successfully.');
                //     item1[property] = this.check_value;
                //     this.getConfigLookup();
                //   })
                // }
              }
            });
        }
        item1[property] = val;
      } else {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }
  mapProduct(brandModel: LookupModel){
    this.productModel.active_ind = true;
    this.productModel.name_txt = brandModel.name_txt;
    this.productModel.description_txt = brandModel.name_txt;
    this.productModel.brand = brandModel.name_txt;
    this.productModel.type_id = 1;
  }
  setProgressData(params: any) {
    this.omr_ProgressNotes = Object.assign({}, params.data);
    this.oldPatientComments = this.omr_ProgressNotes.patientComments;
    this.onSelectUser(this.omr_ProgressNotes.provider_ID);
    this.editProgressNote = true;
  }

  loadLatestProgressNotes() {
    if (this.rowNote != undefined && this.rowNote != null && this.rowNote.length > 0) {
      this.omr_ProgressNotes = Object.assign({}, this.rowNote[0]);
      debugger;
      localStorage.setItem("isContactOrder", `${this.omr_ProgressNotes.orderContacts}`);
      this.checkBox(this.omr_ProgressNotes.orderContacts);
      this.omr_ProgressNotes.progressDate = new Date();
    }
    this.onSelectUser(this.omr_ProgressNotes.provider_ID);
    this.editProgressNote = false;
  }

  cancelProgressNote() {
    this.loadLatestProgressNotes();
  }
  editOffice(componentName: string) {
    const initialState = {
      componentName: componentName,
      title: 'Edit Facilities',
    };
    this.bsModalRef = this.modalService.show(ComponentModalComponent, {
      initialState,
    });
    this.bsModalRef.setClass('modal-xl');
    this.bsModalRef.onHide.subscribe((res: any) => {
      if (componentName == 'General') {
        this.getOffice();
      }
    });
  }

  getOffice() {
    this._lookupService.getOffice().subscribe(
      (res: any) => {
        this.office = res;
        if (!this.exam.office_ID) {
          this.exam.office_ID = this.patientDetail.defaultOfficeID;
        }

        this.examCopy = Object.assign({}, this.exam);
        this.populatePrintValues();
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }
  getSpecificLookup(txt: string){
    let listData = this.allLookupData.filter(x => x.group_txt.toLowerCase() == txt.toLowerCase() && x.active_ind);
    return listData;
  }

  getConfigLookup() {
    this.examVisitTypeLookup = this.getSpecificLookup('examvisittype');
    this.examVisitTypeLookup = this.compare(this.examVisitTypeLookup);
    this.examVisitTypeCopyLookup = this.examVisitTypeLookup;

    var assessment = this.getSpecificLookup('assessment');
    var combineAssments = this.assessment.concat(assessment);
    this.assessment = combineAssments;
    this.assessment = this.compare(this.assessment);
    this.assessmentCopy = this.assessment;


    // this._lookupService.getGlobalLookup('assessment').subscribe(res => {
    //   this.assessment = res.filter(x => x.active_ind == true);
    //   this.assessment = res;
    //   this.assessmentCopy = res;
    // })
    // this._lookupService.getGlobalLookup('plan').subscribe(res => {
    //   this.plan = res.filter(x => x.active_ind == true);
    //   this.plan = this.plan.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.planCopy = res;
    // })
    // this._lookupService.getGlobalLookup('nearsubj').subscribe(res => {
    //   this.NearSubjLookup = res.filter(x => x.active_ind == true);
    //   this.NearSubjLookupCopy = res;
    //   this.NearSubjLookup = res;
    // })
    // this._lookupService.getGlobalLookup('examvisittype').subscribe((res: LookupModel[]) => {
    // this.examVisitTypeLookup = res.filter(x => x.active_ind == true);
    // this.examVisitTypeLookup = this.examVisitTypeLookup.sort((a, b) => {
    //   if (a.name_txt < b.name_txt) {
    //     return -1
    //   }
    //   if (a.name_txt > b.name_txt) {
    //     return 1
    //   }
    //   return 0
    // });
    // this.examVisitTypeCopyLookup = res;
    // this._lookupService.getGlobalLookup('context').subscribe(res => {
    //   this.contextLookup = res.filter(x => x.active_ind == true);
    //   this.contextLookup = this.contextLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.contextCopyLookup = res
    // })
    // this._lookupService.getGlobalLookup('modifyingfactors').subscribe(res => {
    //   this.factorLookup = res.filter(x => x.active_ind == true);
    //   this.factorLookup = this.factorLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.factorCopyLookup = res
    // })
    // this._lookupService.getGlobalLookup('signssymptoms').subscribe(res => {
    //   this.symptomsLook = res.filter(x => x.active_ind == true);
    //   this.symptomsLook = this.symptomsLook.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.symptomsCopyLook = res
    // })
    // this._lookupService.getGlobalLookup('bc').subscribe(res => {
    //   this.bcLookup = res.filter(x => x.active_ind == true);
    //   this.bcLookup = this.bcLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.bcLookupCopy = res
    // })
    // this._lookupService.getGlobalLookup('diam').subscribe(res => {
    //   this.diamLookup = res.filter(x => x.active_ind == true);
    //   this.diamLookup = this.diamLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.diamLookupCopy = res
    // })
    // this._lookupService.getGlobalLookup('brand').subscribe(res => {
    //   this.brandLookup = res.filter(x => x.active_ind == true);
    //   this.brandLookup = this.brandLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.brandLookupCopy = res
    // })
    // this._lookupService.getGlobalLookup('tonometryosod').subscribe(res => {
    //   this.tonometryosod = res.filter(x => x.active_ind == true);
    //   this.tonometryosod = this.tonometryosod.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.tonometryosodCopy = res
    // })
    // this._lookupService.getGlobalLookup('slitlampexam').subscribe(res => {
    //   this.slitLampExamLookup = res.filter(x => x.active_ind == true);
    //   this.slitLampExamLookup = this.slitLampExamLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    // });
    //})
  }

  public ngDoCheck(): void {}

  slitLampOtherEnableDisable() {
    if (this.omr_ProgressNotes.slitLampExaminationSelect.toLocaleLowerCase() == 'other') {
      this.slitLampOtherDisable = false;
    } else {
      this.slitLampOtherDisable = true;
      this.omr_ProgressNotes.slitLampExamination = '';
    }
  }

  copyDist() {
    this.exam.omR_Exam.distSubjectODSphere = this.exam.omR_Exam.curRXODSphere;
    this.exam.omR_Exam.distSubjectOSSphere = this.exam.omR_Exam.curRXOSSphere;
  }

  blockSpecialChars(event: any) {
    var k;
    k = event.charCode;
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }

  numberOnly(event: { target: any; which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (event.target.value.length < 3) {
      return true;
    }
    return false;
  }

  copyNear() {
    //  this.saveDropdown(this.exam.omR_Exam.curRXAdd,this.NearSubjLookup,'NearSubj',this.dbEnum.QE_Dev_Config)
    this.exam.omR_Exam.nearSubjectOD = this.exam.omR_Exam.curRXAdd;
    this.exam.omR_Exam.nearSubjectOS = this.exam.omR_Exam.curRXAdd;
  }
  copyDistObject() {
    this.omr_ProgressNotes.odPower = this.exam.omR_Exam.distSubjectODSphere;
    this.omr_ProgressNotes.osPower = this.exam.omR_Exam.distSubjectOSSphere;
  }
  cancelExam() {
    GlobalService.componentName = 'clinical';
    this.router.navigate(['/patient/clinical/exams'], {
      queryParams: { patientId: this.patientDetail.patientID },
    });
  }
  ngOnDestroy(): void {

    switch (GlobalService.componentName) {
      case 'misc-free-statement':
        break;
      case 'patientAccount':
        break;
      case 'e-prescribe-now':
        break;
      case 'clinical':
        break;
      case 'patient-history':
        break;
      case 'create':
        break;
      default:
        var result = this._globalService.isFormEdited(this.exam, this.examCopy);
        if (!result) {
          this.saveandCloseExam();
        }
        break;
    }
  }
}
