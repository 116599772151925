import { ExamModel } from "./ExamModel";

export class OMR_PrescriptionsModel{
public exam_ID : string = '';
public patient_ID : string = '';
public expirationDate : Date = new Date(0);
public sphereOD : string = '';
public sphereOS : string = '';
public cylinderOD : string = '';
public cylinderOS : string = '';
public axisOD : string = '';
public axisOS : string = '';
public prismOD : string = '';
public prismOS : string = '';
public addOD : string = '';
public addOS : string = '';
public sphereTop : string = '';
public sphereBottom : string = '';
public cylinderTop : string = '';
public cylinderBottom : string = '';
public axisTop : string = '';
public axisBottom : string = '';
public prismTop : string = '';
public prismBottom: string = '';
public addTop : string = '';
public addBottom : string = '';
public fullTime1 : boolean = false;
public distOnly1 : boolean = false;
public interOnly1 : boolean = false;
public readOnly1 : boolean = false;
public asNeeded1 : boolean = false;
public singleVision1 : boolean = false;
public biFocal1 : boolean = false;
public triFocal1 : boolean = false;
public progressive1 : boolean = false;
public uvCoat1 : boolean = false;
public tint1 : boolean = false;
public safety1 : boolean = false;
public antiReflective1 : boolean = false;
public cR391 : boolean = false;
public scratchResistant1 : boolean = false;
public photocromatic1 : boolean = false; 
public polycarbonate1 : boolean = false;
public asphericPoly1 : boolean = false;
public other1 : string = '';
public materialsCoatsOther1 : string = '';
public matchOldBC : boolean = false;
public matchOldOC  : boolean = false;
public matchOldFarPD : boolean = false;
public matchOldNearPD : boolean = false;
public fullTime2 : boolean = false;
public distOnly2 : boolean = false;
public interOnly2 : boolean = false;
public readOnly2 : boolean = false;
public asNeeded2 : boolean = false;
public singleVision2 : boolean = false;
public biFocal2 : boolean = false;
public triFocal2 : boolean = false;
public progressive2 : boolean = false;
public uvCoat2 : boolean = false;
public tint2 : boolean = false;
public safety2 : boolean = false;
public antiReflective2 : boolean = false;
public cR392 : boolean = false; 
public scratchResistant2 : boolean = false;
public photocromatic2 : boolean = false;
public polycarbonate2 : boolean = false; 
public asphericPoly2 : boolean = false;
public other2 : string = '';
public materialsCoatsOther2 : string = '';
public remarks : string = '';
public doctorsSignature : string = '';
public license : string = '';
public doctorName : string = '';
public creationDate : Date = new Date();
public lastUpdateDate : Date = new Date();
public doctorID : number = 0;
public primaryDistPD : string = '';
public primaryNearPD : string = '';
public secondaryDistPD : string = '';
public secondaryNearPD : string = '';
public provider_ID : string = '';
// public exam : ExamModel = new ExamModel();
}