import { GlobalService } from './../../../services/global.service';
import { LookupService } from './../../../services/lookup.service';
import { ActivatedRoute } from '@angular/router';
import { ExamModel } from 'src/app/model/ExamModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Component, OnInit } from '@angular/core';
import { OMR_ContactOrderFormModel } from 'src/app/model/OMR_ContactOrderFormModel';
import { PatientModel } from 'src/app/model/patientModel';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LookupModel } from 'src/app/model/lookupModel';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ComponentModalComponent } from 'src/app/shared/component-modal/component-modal.component';
import { ManufacturerService } from 'src/app/services/Manufacturer.service';
import { ManufacturerModel } from 'src/app/model/ManufacturerModel';
import { InsuranceModel } from 'src/app/model/insuranceModel';
import { PatientService } from './../../../services/patient.service';
import { OfficeModel } from 'src/app/model/officeModel';
import { UserModel } from 'src/app/model/userModel';
import * as ts from "typescript";
import { ViewportScroller } from '@angular/common';
import { PracticeNotificationModel } from 'src/app/model/practiceNotificationModel';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ItemModel } from 'src/app/model/ItemModel';
import { Item_ContactsDataModel } from 'src/app/model/Item_ContactsDataModel';

@Component({
  selector: 'app-contact-order',
  templateUrl: './contact-order.component.html',
  styleUrls: ['./contact-order.component.scss']
})
export class ContactOrderComponent implements OnInit {

  patientDetails: PatientModel = new PatientModel();
  patientModel !: PatientModel;
  patientId: string = '';
  // contactOrderForm : OMR_ContactOrderFormModel = new OMR_ContactOrderFormModel();
  examModel: ExamModel = new ExamModel();
  showComponent: boolean = false;
  examId: string = '';
  License:string='';
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  productModel: ItemModel = new ItemModel();
  brandLookup: LookupModel[] = [];
  brandCopyLookup: LookupModel[] = [];
  orderByLookup: LookupModel[] = [];
  orderByCopyLookup: LookupModel[] = [];
  receivedByLookup: LookupModel[] = [];
  receivedByCopyLookup: LookupModel[] = [];
  dispensedbyLookup: LookupModel[] = [];
  dispensedbyCopyLookup: LookupModel[] = [];
  manufacture: ManufacturerModel[] = [];
  manufacture_copy: ManufacturerModel[] = [];
  ProductList: ItemModel[] = [];
  ItemContacts: Item_ContactsDataModel[] = [];
  bsModalRef !: BsModalRef;
  patient: PatientModel = new PatientModel();
  practiceNotificationModel: PracticeNotificationModel = new PracticeNotificationModel();
  user1: any = new UserModel();
  insurancePatientId: number = 0;
  insuranceName: string = '';
  office: OfficeModel[] = [];
  officename: string = '';
  officeaddress: string = '';
  officeCitySZ:string='';
  officephone: string = '';
  officeemail: string = '';
  office_npi_txt: string = '';
  userList: UserModel[] = [];
  user: any = new UserModel();
  check_value: string = '';
  currentDate: Date = new Date();
  isNotificationDone: boolean = false;
  socialSecurityNum: string = '';

  constructor(private _clinicalService: ClinicalService, private activateRoute: ActivatedRoute,
    private _patientService: PatientService,
    private toaster: ToastrService, private _lookupService: LookupService,
    private globalService: GlobalService, private modalService: BsModalService,
    private _manufactureService: ManufacturerService,private viewportScroller: ViewportScroller, private _dashboardService: DashboardService) {
      this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['examId']) {
        this.examId = params['examId'];
      }
    });

    this.patientDetails = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.patientId = this.patientDetails.patient_id;
    this.socialSecurityNum = this.patientDetails.patientInsurances[0]?.insuredSSN == '' ? this.patientDetails.socialSecurityNum : this.patientDetails.patientInsurances[0]?.insuredSSN;

    this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['patientId']) {
        this.insurancePatientId = Number(params['patientId']);
      }
    });
  }

  ngOnInit() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.getAllLookup();
    this.getManufacture();
    //await this.delay(3000);
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    this.getProducts();
    // if (this.examId != '') {
    //   this.getContactOrder();
    // }
    this.setPatientData();
    this.getOffice();
    // this.getUser();
  }
  getProducts() {
    debugger;
    this._lookupService.getItemsWithContact().subscribe((res: any) => {
      this.ProductList = res.item1;
      this.ItemContacts = res.item2;
      if (this.examId != '') {
        this.getContactOrder();
      }
    });
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  getContactOrder() {

    this._clinicalService.getByExamId(this.examId).subscribe((res: any) => {
      debugger;
      this.examModel = res;
      this.getUser();
      if (!this.examModel.omR_ContactOrderForm.date) {
        this.examModel.omR_ContactOrderForm.date = this.examModel.exam_Date;
      }
      this.isNotificationDone = this.examModel.omR_ContactOrderForm.notification_ind;
      //if (this.examModel.omR_ContactOrderForm.odLens == "" || this.examModel.omR_ContactOrderForm.odLens == undefined || this.examModel.omR_ContactOrderForm.odLens == null) {
        //this.examModel.omR_ContactOrderForm.odLens = this.examModel.omR_ContactRX.odBrandT;
      //}
      //if (this.examModel.omR_ContactOrderForm.odManufacturer == "" || this.examModel.omR_ContactOrderForm.odManufacturer == undefined || this.examModel.omR_ContactOrderForm.odManufacturer == null) {

      // if(this.examModel.omR_ContactRX.odManufacturerT){
      //   this.examModel.omR_ContactOrderForm.odManufacturer = this.examModel.omR_ContactRX.odManufacturerT;
      // } else {
      //   this.examModel.omR_ContactOrderForm.odManufacturer = this.selectBrandManufacture(this.examModel.omR_ContactRX.odBrandT);
      // }
      if(!this.examModel.omR_ContactOrderForm.odManufacturer){
        this.examModel.omR_ContactOrderForm.odManufacturer = this.selectBrandManufacture(this.examModel.omR_ContactOrderForm.odLens);
      }

      //this.examModel.omR_ContactOrderForm.odManufacturer = this.examModel.omR_ContactRX.odManufacturerT;
        // if(!this.examModel.omR_ContactOrderForm.odAccount){
        //   this.changevalueod(this.examModel.omR_ContactOrderForm.odManufacturer);
        // }
        this.changevalueod(this.examModel.omR_ContactOrderForm.odManufacturer);
      //}

      //if (this.examModel.omR_ContactOrderForm.osLens == "" || this.examModel.omR_ContactOrderForm.osLens == undefined || this.examModel.omR_ContactOrderForm.osLens == null) {
        //this.examModel.omR_ContactOrderForm.osLens = this.examModel.omR_ContactRX.osBrandT;
      //}
      //if (this.examModel.omR_ContactOrderForm.osManufacturer == "" || this.examModel.omR_ContactOrderForm.osManufacturer == undefined || this.examModel.omR_ContactOrderForm.osManufacturer == null) {
        // if(this.examModel.omR_ContactRX.osManufacturerT){
        //   this.examModel.omR_ContactOrderForm.osManufacturer = this.examModel.omR_ContactRX.osManufacturerT;
        // } else {
        //   this.examModel.omR_ContactOrderForm.osManufacturer = this.selectBrandManufacture(this.examModel.omR_ContactRX.osBrandT);
        // }
        if(!this.examModel.omR_ContactOrderForm.osManufacturer){
          this.examModel.omR_ContactOrderForm.osManufacturer = this.selectBrandManufacture(this.examModel.omR_ContactOrderForm.osLens);
        }
        //this.examModel.omR_ContactOrderForm.osManufacturer = this.examModel.omR_ContactRX.osManufacturerT;
        // if(!this.examModel.omR_ContactOrderForm.osAccount){
        //   this.changevalueos(this.examModel.omR_ContactOrderForm.osManufacturer);
        // }
        this.changevalueos(this.examModel.omR_ContactOrderForm.osManufacturer);
      //}
      // if(this.examModel.omR_ProgressNotes.length > 0){
      //   //if (this.examModel.omR_ContactOrderForm.odDiam == "" || this.examModel.omR_ContactOrderForm.odDiam == undefined || this.examModel.omR_ContactOrderForm.odDiam == null) {
      //     this.examModel.omR_ContactOrderForm.odDiam = this.examModel.omR_ContactRX.odDiameterT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.osDiam == "" || this.examModel.omR_ContactOrderForm.osDiam == undefined || this.examModel.omR_ContactOrderForm.osDiam == null) {
      //     this.examModel.omR_ContactOrderForm.osDiam = this.examModel.omR_ContactRX.osDiameterT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.odPower == "" || this.examModel.omR_ContactOrderForm.odPower == undefined || this.examModel.omR_ContactOrderForm.odPower == null) {
      //     this.examModel.omR_ContactOrderForm.odPower = this.examModel.omR_ContactRX.odPowerT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.osPower == "" || this.examModel.omR_ContactOrderForm.osPower == undefined || this.examModel.omR_ContactOrderForm.osPower == null) {
      //     this.examModel.omR_ContactOrderForm.osPower = this.examModel.omR_ContactRX.osPowerT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.odbc == "" || this.examModel.omR_ContactOrderForm.odbc == undefined || this.examModel.omR_ContactOrderForm.odbc == null) {
      //     this.examModel.omR_ContactOrderForm.odbc = this.examModel.omR_ContactRX.odBaseCurveT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.osbc == "" || this.examModel.omR_ContactOrderForm.osbc == undefined || this.examModel.omR_ContactOrderForm.osbc == null) {
      //     this.examModel.omR_ContactOrderForm.osbc = this.examModel.omR_ContactRX.osBaseCurveT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.odLens == "" || this.examModel.omR_ContactOrderForm.odLens == undefined || this.examModel.omR_ContactOrderForm.odLens == null) {
      //     this.examModel.omR_ContactOrderForm.odLens = this.examModel.omR_ContactRX.odBrandT;
      //   //}
      //   //if (this.examModel.omR_ContactOrderForm.osLens == "" || this.examModel.omR_ContactOrderForm.osLens == undefined || this.examModel.omR_ContactOrderForm.osLens == null) {
      //     this.examModel.omR_ContactOrderForm.osLens = this.examModel.omR_ContactRX.osBrandT;
      //   //}
      // }


      // this.examModel.omR_ContactOrderForm.trials = false;
      // this.examModel.omR_ContactOrderForm.supply = false;
    })
  }
  selectBrandManufacture(brand: string): string {
    var item = this.ProductList.filter((x) => x.brand !== null && x.brand.trim().toLocaleLowerCase() == brand.trim().toLocaleLowerCase());
    if (item.length > 0) {
      if(item[0].manufacturer_id){
        var manufactureItem = this.manufacture.filter(x => x.manufacturer_id == item[0].manufacturer_id);
        if(manufactureItem.length > 0){
          return manufactureItem[0].name_txt;
        }
      }
    }
    return "";
  }

  getOffice() {
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;
      this.office = this.office.filter(x => x.office_id == this.patientDetails.defaultOfficeID);
      // if (this.office[0]?.name_txt != null) {
      //   this.officename = this.office[0]?.name_txt
      // }
      // if (this.office[0]?.address_1_txt != null && this.office[0].address_2_txt) {
      //   this.officeaddress = this.office[0]?.address_1_txt + " " + this.office[0]?.address_2_txt;
      // }
      // if (this.office[0]?.phone_1_txt != null) {
      //   this.officephone = this.office[0]?.phone_1_txt;
      // }
      if (this.office[0]?.name_txt != null) {
        this.officename = this.office[0]?.name_txt
      }
      // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
      //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
      // }
      if (this.office[0]?.address_1_txt) {
        this.officeaddress = `${this.office[0]?.address_1_txt}`;
      }
      if(this.office[0]?.address_2_txt){
        this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
      }
      if(this.office[0]?.city_txt){
        this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
      }
      if (this.office[0]?.phone_1_txt) {
        this.officephone = this.office[0]?.phone_1_txt;
      }
      if(this.office[0]?.npi_txt){
        this.office_npi_txt = this.office[0]?.npi_txt;
      }
      if(this.office[0]?.email_address_txt){
        this.officeemail = this.office[0]?.email_address_txt;
      }
    }, (error) => {
      this.toaster.error(error.error);
    });
  }




  setPatientData() {
    this._patientService.getPatientByPatientId(this.insurancePatientId).subscribe((res: any) => {
      this.patient = Object.assign({}, res);
      if (this.patient.patientInsurances.length > 0) {
        this.insuranceName = this.patient.patientInsurances[0].insuranceName;
      }
    }, (error) => {
      this.toaster.error(error.error);
    }
    );
  }
  getUser() {

    let loadingUser = new UserModel();
    loadingUser.display_txt = "Loading Records...";
    this.userList.push(loadingUser);
    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    this.user1 = this.userList.filter(x => x.user_id == this.examModel.provider_ID);
    this.user = this.user1[0];
    if (this.user?.npi_txt) {
      this.License = this.user?.npi_txt
    }
    // if (this.user?.email_address_txt) {
    //   this.officeemail = this.user?.email_address_txt;
    // }


    // let practiceId = localStorage.getItem('practice')?.toString();
    // this._lookupService.getRenderingProvider(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
    //   this.userList = res;
    //   this.user1 = this.userList.filter(x => x.user_id == this.examModel.provider_ID);
    //   this.user = this.user1[0];
    //   if (this.user1[0]?.license_txt) {
    //     this.License = this.user1[0]?.license_txt
    //   }
    // }, (error) => {
    //   this.toaster.error(error.error);
    // });
  }



  saveContactOrder() {
    this._clinicalService.saveExam(this.examModel).subscribe((res: any) => {
      this.toaster.success('Record Saved Successfully.');
      this.examModel = res;
    }, (error) => {
      this.toaster.error(error.error.message);
    })
  }

  updateContactOrder() {
    this._clinicalService.updateExam(this.examModel).subscribe((res: any) => {
      this.toaster.success('Record Saved Successfully.');

      //this.examModel = res;
      location.reload();
    }, (error) => {
      this.toaster.error(error.error.message);
    })
  }

  save(isNavigate: boolean) {
    debugger;
    var isNotified = this.examModel.omR_ContactOrderForm.notification_ind;
    if(isNotified){
      if(!this.isNotificationDone){
        this.practiceNotificationModel.active_ind = true;
        this.practiceNotificationModel.note_type_txt = 'Order Contacts';
        this.practiceNotificationModel.note_txt = `Exam addendum – Order Contacts for ${this.patientDetails.lastName}, ${this.patientDetails.firstName}`;
        var current = new Date();
        var expdate = new Date(current);
        expdate.setDate(expdate.getDate() + 14);
        this.practiceNotificationModel.expire_dt = expdate;
        this._dashboardService.saveNotification(this.practiceNotificationModel).subscribe((res) => {
        });
      }
    }
    if (this.examModel.exam_ID == '') {
      this.saveContactOrder();
    }
    else {
      this.updateContactOrder();
    }
    if (isNavigate) {
      this._clinicalService.navigateToClinic();
    }
  }

  getAllLookup() {
    this._lookupService.getGlobalLookupForData('brand').subscribe(res => {
      this.brandLookup = res.filter(x => x.active_ind == true);
      this.brandLookup = this.brandLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.brandCopyLookup = res

      this._lookupService.getGlobalLookupForData('dispensedby').subscribe(res => {
        this.dispensedbyLookup = res.filter(x => x.active_ind == true);
        this.dispensedbyLookup = this.dispensedbyLookup.sort((a, b) => {
          if (a.name_txt < b.name_txt) {
            return -1
          }
          if (a.name_txt > b.name_txt) {
            return 1
          }
          return 0
        });
        this.dispensedbyCopyLookup = res
      })
      this._lookupService.getGlobalLookupForData('receivedby').subscribe(res => {
        this.receivedByLookup = res.filter(x => x.active_ind == true);
        this.receivedByLookup = this.receivedByLookup.sort((a, b) => {
          if (a.name_txt < b.name_txt) {
            return -1
          }
          if (a.name_txt > b.name_txt) {
            return 1
          }
          return 0
        });
        this.receivedByCopyLookup = res
      })
      this._lookupService.getGlobalLookupForData('orderedby').subscribe(res => {
        this.orderByLookup = res.filter(x => x.active_ind == true);
        this.orderByLookup = this.orderByLookup.sort((a, b) => {
          if (a.name_txt < b.name_txt) {
            return -1
          }
          if (a.name_txt > b.name_txt) {
            return 1
          }
          return 0
        });
        this.orderByCopyLookup = res
      })
    })
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getAllLookup()
      // this.sortArray(item)
    })
  }

  changevalueod(event: any) {
    debugger;
    this.manufacture_copy = this.manufacture.filter(x => x.name_txt == event);
    if(this.manufacture_copy.length > 0){
      this.examModel.omR_ContactOrderForm.odAccount = this.manufacture_copy[0].account_txt;
      this.examModel.omR_ContactOrderForm.odPhone = this.manufacture_copy[0].phone_txt;
    }else{
      this.examModel.omR_ContactOrderForm.odAccount = "";
      this.examModel.omR_ContactOrderForm.odPhone = "";
    }
  }

  changevalueos(event: any) {
    debugger;
    this.manufacture_copy = this.manufacture.filter(x => x.name_txt == event);
    if(this.manufacture_copy.length > 0){
      this.examModel.omR_ContactOrderForm.osAccount = this.manufacture_copy[0].account_txt;
      this.examModel.omR_ContactOrderForm.osPhone = this.manufacture_copy[0].phone_txt;
    }
    else{
      this.examModel.omR_ContactOrderForm.osAccount = "";
      this.examModel.omR_ContactOrderForm.osPhone = "";
    }
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any, item: LookupModel[], group_txt: string, name_txt: string, sub_group_txt?: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {

    //if(this.check_value == ""){
    this.check_value = val;
    //}
    if (this.check_value != '') {
      var index = item.findIndex(
        (x) =>
          x.name_txt.trim().toLowerCase() ==
          this.check_value.trim().toLowerCase()
      );
      // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
      // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      if (index < 0) {
        var lookupModel = new LookupModel();
        if (item[0]?.group_txt) {
          lookupModel.group_txt = item[0]?.group_txt;
          lookupModel.sub_group_txt = item[0]?.sub_group_txt;
        } else {
          lookupModel.group_txt = group_txt!;
          lookupModel.sub_group_txt = 'All';
        }
        lookupModel.active_ind = true;
        lookupModel.name_txt = val;

        if (this.check_value != lookupModel.name_txt) {
          lookupModel.name_txt = this.check_value;
        }
        if (dbType != 1) {
          var content = 'Would you like to save this ' + name_txt + ' list?';
          this.globalService
            .modal(content, 'New Item?', 'OK', 'Cancel')
            .subscribe((res) => {
              if (res.res) {
                // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
                if (dbType == DbEnum.QE_DEV_SQL_DB) {
                  this._lookupService
                    .insertLookup(lookupModel)
                    .subscribe((res) => {
                      if(lookupModel.group_txt == "brand"){
                        this.mapProduct(lookupModel);
                        this._lookupService.insertItem(this.productModel).subscribe(res => {
                          console.log(res);
                        });
                      }
                      this.getAllLookup();
                      this.toaster.success('Record Saved Successfully.');
                      item1[property] = val; //this.check_value;
                    });
                }
                // else {
                //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
                //     this.toaster.success('Record Saved Successfully.');
                //     item1[property]=this.check_value;
                //     this.getAllLookup();
                //   })
                // }
              }
            });
        }
        item1[property] = val;
      } else {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }
  mapProduct(brandModel: LookupModel){
    this.productModel.active_ind = true;
    this.productModel.name_txt = brandModel.name_txt;
    this.productModel.description_txt = brandModel.name_txt;
    this.productModel.brand = brandModel.name_txt;
    this.productModel.type_id = 1;
  }

  getManufacture() {

    this._manufactureService.getAll().subscribe(res => {
      this.manufacture = res;
      this.manufacture = this.manufacture.filter(x => x.active_ind == true);
      this.manufacture = this.compare(this.manufacture);
    })
  }
  CreateNew(city:string){
  }

  editManufacture() {
    const initialState = {
      componentName: 'Manufacture',
      title: 'Manufacturer Window'
    };
    this.bsModalRef = this.modalService.show(ComponentModalComponent, { initialState });
    this.bsModalRef.setClass('modal-xl')
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getManufacture()
    })
  }
  compare(array: ManufacturerModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }

}
