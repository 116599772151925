export class Item_ContactsDataModel
{
    public item_id: string = '';
    public color_txt: string = '';
    public colorcode_txt: string = '';
    public power_txt: string = '';
    public basecurve_txt: string = '';
    public diameter_txt: string = '';
    public secondcurve_txt: string = '';
    public tertcurve_txt: string = '';
    public oz_txt: string = '';
    public material_txt: string = '';
    public ct_txt: string = '';
    public replacement_txt: string = '';
    public shcedule_txt: string = '';
    public soln_txt: string = '';
    public supply_txt: string = '';
    public supply1_cnt: number = 0;
    public supply2_cnt: number = 0;
    public packs_cnt: number = 0;
}