<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" routerLink="/patient/add-exam"
                [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active">
                Cancel Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(false)">
                Save Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(true)">
                Save & Close
            </button> &nbsp;&nbsp;
            <button style="height: 68px;" class="button" printSectionId="print-section" [disabled]="patientId == ''"
                [useExistingCss]="true" ngxPrint> Print</button>
            <hr>
        </div>

    </div>
</div>
<br>
<div class="pt-5 mt-2">
    <div class="field">
        <div class="titlebox"></div>
        <div class="row">
            <div class="col-md-1">
                <label style="font-weight: bold;">Patient Name:</label>
            </div>
            <div class="col-md-4">
                <label>{{patientModel.firstName}} {{patientModel.lastName}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <label style="font-weight: bold;">Date:</label>
            </div>
            <div class="col-md-4">
              <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                    (ngModelChange)="changeDate($event)"
                    [ngModel]="examModel.exam_Date | date:'MM-dd-yyyy'"
                    class="form-control col-md-4">
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <label style="font-weight: bold;">Age:</label>
            </div>
            <div class="col-md-4">
                <label>{{age}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <label style="font-weight: bold;">Birthdate:</label>
            </div>
            <div class="col-md-4">
                <label>{{datepipe.transform(patientModel.dateOfBirth, 'MM/dd/yyyy')}}</label>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-2" style="font-size:21px !important;">
                <b>Spectacle Rx:</b>
            </div>
            <div class="col-md-1" style="margin-left:-75px;">
                <label>Expiration:</label>
            </div>
            <div class="col-md-2 pt-2">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                    (ngModelChange)="examModel.omR_Prescriptions.expirationDate = $event"
                    [ngModel]="examModel.omR_Prescriptions.expirationDate | date:'MM-dd-yyyy'" class="form-control">
            </div>
        </div>
        <div class="row pt-1">
            <div class="col-md-6">
                <div id="content" style="height:80%;">
                    <div class="titlebox"><b>Primary Pair</b></div>
                    <table class="table table-bordered" style="font-size: 12px !important">
                        <tr class="col-md-2 text-center">
                            <th></th>
                            <th>Sphere</th>
                            <th>Cylinder</th>
                            <th>Axis</th>
                            <th>Prism</th>
                            <th>Add</th>
                        </tr>
                        <tr>
                            <td><b>OD</b></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereOD"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderOD"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisOD"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" #prism [(ngModel)]="examModel.omR_Prescriptions.prismOD"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.addOD"
                                    class="formcontrol form-control"></td>
                        </tr>
                        <tr>
                            <td><b>OS</b></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereOS"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderOS"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisOS"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.prismOS"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.addOS"
                                    class="formcontrol form-control"></td>
                        </tr>
                    </table>
                    <table class="table table-bordered">
                        <tr>
                            <td style="width: 25%;" class="p-0">
                                <div class="mx-3">
                                    <b>Wear Type</b>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.fullTime1"
                                            class="mx-1">Full-Time</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.distOnly1"
                                            class="mx-1">Dist. Only</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.interOnly1"
                                            class="mx-1">Inter. Only</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.readOnly1"
                                            class="mx-1">Read only</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.asNeeded1"
                                            class="mx-1">As Needed</label>
                                </div>
                            </td>
                            <td style="width: 25%;" class="p-0">
                                <div class="mx-3">
                                    <b>Lens Design</b>
                                </div>
                                <div>
                                    <label><input type="checkbox"
                                            [(ngModel)]="examModel.omR_Prescriptions.singleVision1" class="mx-1">Single
                                        Vision</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.biFocal1"
                                            class="mx-1">Bi-Focal</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.triFocal1"
                                            class="mx-1">Tri-Focal</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.progressive1"
                                            class="mx-1">Progressive</label>
                                </div>
                            </td>
                            <td style="width: 50%;" class="p-0">
                                <div class="mx-3">
                                    <b>Materials/Coats</b>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.uvCoat1" class="mx-1">UV
                                                Coat</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.tint1"
                                                    class="mx-1">Tint</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.safety1"
                                                    class="mx-1">Safety</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.antiReflective1"
                                                    class="mx-1">Anti-Reflective</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.cR391"
                                                    class="mx-1">CR39</label>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.scratchResistant1"
                                                    class="mx-1">Scratch Resistance</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.photocromatic1"
                                                    class="mx-1">Photocromatic</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.polycarbonate1"
                                                    class="mx-1">Polycarbonate</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.asphericPoly1"
                                                    class="mx-1">Aspheric Poly</label>
                                        </div>
                                        <div class="form-row">
                                            <label class="pt-1">Other</label>
                                            <input type="text" [(ngModel)]="examModel.omR_Prescriptions.other1"
                                                class="form-control col-md-7 ml-3">
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>

                </div>
                <br>
                <div class="row pt-0">
                    <div class="col-md-1">
                        <label>Dist PD:</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" [(ngModel)]="examModel.omR_Prescriptions.primaryDistPD"
                            class="form-control col-sm-10">
                    </div>
                    <div class="col-md-1">
                        <label>Near PD:</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" [(ngModel)]="examModel.omR_Prescriptions.primaryNearPD"
                            class="form-control col-md-12">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div id="content" style="height:80% ;">
                    <div class="titlebox"><b>Secondary Pair</b></div>
                    <table class="table table-bordered">
                        <tr class="col-md-2 text-center">
                            <th></th>
                            <th>Sphere</th>
                            <th>Cylinder</th>
                            <th>Axis</th>
                            <th>Prism</th>
                            <th>Add</th>
                        </tr>
                        <tr>
                            <td><b>OD</b></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereTop"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderTop"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisTop"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.prismTop"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.addTop"
                                    class="formcontrol form-control"></td>
                        </tr>
                        <tr>
                            <td><b>OS</b></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereBottom"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderBottom"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisBottom"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.prismBottom"
                                    class="formcontrol form-control"></td>
                            <td><input type="text" [(ngModel)]="examModel.omR_Prescriptions.addBottom"
                                    class="formcontrol form-control"></td>
                        </tr>
                    </table>
                    <table class="table table-bordered">
                        <tr>
                            <td style="width: 25%;" class="p-0">
                                <div class="mx-3">
                                    <b>Wear Type</b>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.fullTime2"
                                            class="mx-1">Full-Time</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.distOnly2"
                                            class="mx-1">Dist. Only</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.interOnly2"
                                            class="mx-1">Inter. Only</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.readOnly2"
                                            class="mx-1">Read only</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.asNeeded2"
                                            class="mx-1">As Needed</label>
                                </div>
                            </td>
                            <td style="width: 25%;" class="p-0">
                                <div class="mx-3">
                                    <b>Lens Design</b>
                                </div>
                                <div>
                                    <label><input type="checkbox"
                                            [(ngModel)]="examModel.omR_Prescriptions.singleVision2" class="mx-1">Single
                                        Vision</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.biFocal2"
                                            class="mx-1">Bi-Focal</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.triFocal2"
                                            class="mx-1">Tri-Focal</label>
                                </div>
                                <div>
                                    <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.progressive2"
                                            class="mx-1">Progressive</label>
                                </div>
                            </td>
                            <td style="width: 50%;" class="p-0">
                                <div class="mx-3">
                                    <b>Materials/Coats</b>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.uvCoat2" class="mx-1">UV
                                                Coat</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.tint2"
                                                    class="mx-1">Tint</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.safety2"
                                                    class="mx-1">Safety</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.antiReflective2"
                                                    class="mx-1">Anti-Reflective</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.cR392"
                                                    class="mx-1">CR39</label>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.scratchResistant2"
                                                    class="mx-1">Scratch Resistance</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.photocromatic2"
                                                    class="mx-1">Photocromatic</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.polycarbonate2"
                                                    class="mx-1">Polycarbonate</label>
                                        </div>
                                        <div>
                                            <label><input type="checkbox"
                                                    [(ngModel)]="examModel.omR_Prescriptions.asphericPoly2"
                                                    class="mx-1">Aspheric Poly</label>
                                        </div>
                                        <div class="form-row">
                                            <label class="pt-1">Other</label>
                                            <input type="text" [(ngModel)]="examModel.omR_Prescriptions.other2"
                                                class="form-control col-md-7 ml-3">
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-1">
                        <label>Dist PD:</label>
                    </div>
                    <div class="col-md-3 ">
                        <input type="text" [(ngModel)]="examModel.omR_Prescriptions.secondaryDistPD"
                            class="form-control col-md-12">
                    </div>
                    <div class="col-md-1 ">
                        <label>Near PD:</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" [(ngModel)]="examModel.omR_Prescriptions.secondaryNearPD"
                            class="form-control col-md-12">
                    </div>
                    <div class="col-md-3">
                        <!-- <div class="form-row"> -->
                            <button class="btn btn-primary form-control col-md-8" style="margin-top: -20px;" (click)="copyRX()">Copy Rx</button>
                        <!-- </div> -->
                    </div>
                </div>

            </div>
        </div>
        <div class="row mt-1">
            <div class="col-md-1">
                <div>
                    <label>Remarks:</label>
                </div>
            </div>
            <div class="col-md-9">
                <table style="width:100%">
                    <tr>
                        <td style="width:97%">
                            <ng-select addTagText="Add Item"
                                [addTag]="CreateNew" class="dropdown" style="width: 99%;" [items]="RemarksCopyLookup"
                                [(ngModel)]="examModel.omR_Prescriptions.remarks"
                                (keyup)="keyFunc($event,examModel.omR_Prescriptions,'remarks')"
                                (keydown.tab)="saveDropdown(examModel.omR_Prescriptions.remarks,'Remarks',RemarksCopyLookup,'Remarks',examModel.omR_Prescriptions,'remarks')"
                                bindLabel="name_txt" bindValue="name_txt">
                            </ng-select>
                        </td>
                        <td style="width:3%">
                            <a href="javascript:void(0)"
                                (click)="editLookup(RemarksCopyLookup,RemarksCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </td>
                    </tr>
                </table>

                <!-- <textarea style="width: 100%;" [(ngModel)]="examModel.omR_Prescriptions.remarks">
                </textarea> -->
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <b>*UV Coat is recommended in your lenses to prevent UV related eye damage.</b>
            </div>
        </div>
        <hr>
        <!-- <div *ngIf="examModel.omR_ProgressNotes[examModel.omR_ProgressNotes.length-1].gaveCLRX==true"> -->
        <div *ngIf="gaveCLRX">
            <div class="row">
                <div class="col-md-2" style="font-size:23px !important;">
                    <b>Contact Rx:</b>
                </div>
                <div class="col-md-1" style="margin-left:-75px;">
                    <label>Expiration:</label>
                </div>
                <div class="col-md-2 pt-2">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                        (ngModelChange)="examModel.omR_ContactRX.expirationDate = $event"
                        [ngModel]="examModel.omR_ContactRX.expirationDate | date:'MM-dd-yyyy'" class="form-control">
                </div>
            </div>

            <div class="row pt-2">
                <div class="col-md-8 pr-0">
                    <table class="table table-bordered">
                        <tr class="text-center">
                            <th style="width: 5%;"></th>
                            <th style="width: 17%;">Power</th>
                            <th style="width: 19%;">Base Curve</th>
                            <th style="width: 19%;">Diam</th>
                            <th style="width: 30%;">Brand</th>
                            <th style="width: 23%;">Manufacturer</th>
                        </tr>
                        <tr>
                            <td class="pt-3 p-1"><b>OD</b></td>
                            <td class="row-td">
                                <input type="text" [(ngModel)]="examModel.omR_ContactRX.odPowerT"
                                    class="form-control formcontrol">
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="bcLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'odBaseCurveT')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.odBaseCurveT,'Base Curve',bcLookup,'bc',examModel.omR_ContactRX,'odBaseCurveT', dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odBaseCurveT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(bcLookup,bcLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="diamLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'odDiameterT')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.odDiameterT,'Diam',diamLookup,'diam',examModel.omR_ContactRX,'odDiameterT',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odDiameterT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(diamLookup,diamCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select class="pt-1 p-1"
                                                    addTagText="Add Item" [addTag]="CreateNew"
                                                    [items]="brandLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'odBrandT')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.odBrandT,'Brand',brandLookup,'brand',examModel.omR_ContactRX,'odBrandT',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odBrandT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(brandLookup,brandLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select class="pt-1 p-1" [items]="manufacture"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odManufacturerT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0" style="margin-left: -3px;">
                                                <a href="javascript:void(0)" (click)="editManufacture()"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="pt-3 p-1"><b>OS</b></td>
                            <td class="row-td">
                                <input type="text" class="form-control formcontrol"
                                    [(ngModel)]="examModel.omR_ContactRX.osPowerT">
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="bcLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'osBaseCurveT')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.osBaseCurveT,'Base Curve',bcLookup,'bc',examModel.omR_ContactRX,'osBaseCurveT', dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osBaseCurveT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(bcLookup,bcLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="diamLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'osDiameterT')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.osDiameterT,'Diam',diamLookup,'diam',examModel.omR_ContactRX,'osDiameterT',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osDiameterT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(diamLookup,diamLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="brandLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'osBrandT')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.osBrandT,'Brand',brandLookup,'brand',examModel.omR_ContactRX,'osBrandT',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osBrandT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(brandLookup,brandLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select class="pt-1 p-1" [items]="manufacture"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osManufacturerT">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0" style="margin-left: -3px;">
                                                <a href="javascript:void(0)" (click)="editManufacture()"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-4">
                    <div id="content">
                        <div class="titlebox"></div>
                        <div class="form-row">
                            <label class="mx-1 pt-0">Wear Type:</label>
                            <label class="mx-2"><input class="mx-1" [(ngModel)]="examModel.omR_ContactRX.wearTypeDWT"
                                    type="checkbox">DW</label>
                            <label class="mx-2"><input class="mx-1" [(ngModel)]="examModel.omR_ContactRX.wearTypeEWT"
                                    type="checkbox">EW</label>
                            <label class="mx-2"><input class="mx-1" [(ngModel)]="examModel.omR_ContactRX.wearTypeCONVT"
                                    type="checkbox">CONV</label>
                        </div>
                        <diV class="form-row">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <label class="mx-1 pt-2">Good For:</label>
                                </div>
                                <div class="col-md-4 pr-0">
                                    <div class="form-row">
                                        <div class="col-md-9">
                                            <ng-select
                                                addTagText="Add Item" [addTag]="CreateNew" style="width: 80px;"
                                                [items]="_6PackLookup"
                                                (keyup)="keyFunc($event,examModel.omR_ContactRX,'goodForT')"
                                                (keydown.tab)="saveDropdown(examModel.omR_ContactRX.goodForT,'Good For',_6PackLookup,'6pack',examModel.omR_ContactRX,'goodForT')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="examModel.omR_ContactRX.goodForT">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(_6PackLookup,_6PackLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-0">
                                    <div class="form-row">
                                        <div class="col-md-9">
                                            <ng-select
                                                addTagText="Add Item" [addTag]="CreateNew" style="width: 80px;"
                                                [items]="_6PackLookup"
                                                (keyup)="keyFunc($event,examModel.omR_ContactRX,'packForT')"
                                                (keydown.tab)="saveDropdown(examModel.omR_ContactRX.packForT,'-packs',_6PackLookup,'6pack')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="examModel.omR_ContactRX.packForT">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(_6PackLookup,_6PackLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label class="pt-1" style="margin-left:-10px !important;">-packs</label>
                                </div>
                            </div>
                        </diV>
                        <div class="form-row pt-1">
                            <!-- <div class="row"> -->
                                <div class="col-sm-4 p-0">
                                    <label class="mx-1 pt-1">Cleaning Solution:</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-row">
                                        <div class="col-md-11">
                                            <ng-select
                                                addTagText="Add Item" [addTag]="CreateNew"
                                                [items]="cleaningSolutionLookup"
                                                (keyup)="keyFunc($event,examModel.omR_ContactRX,'cleaningSolnT')"
                                                (keydown.tab)="saveDropdown(examModel.omR_ContactRX.cleaningSolnT,'Cleaning Solution',cleaningSolutionLookup,'cleaningsolution',examModel.omR_ContactRX,'cleaningSolnT')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="examModel.omR_ContactRX.cleaningSolnT">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(cleaningSolutionLookup,cleaningSolutionLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->

                        </div>
                        <div class="row pt-1">
                            <label class="pt-1">Replace Every:</label>
                            <label class="pt-1 mx-2"><input type="checkbox"
                                    [(ngModel)]="examModel.omR_ContactRX.replaceDailyT">
                                    DAILY
                            </label>
                            <input type="text" class="form-control col-md-2"
                                [(ngModel)]="examModel.omR_ContactRX.replaceWeekT">
                            <label class="pt-1 mx-1">WKS</label>
                            <input type="text" class="form-control col-md-2"
                                [(ngModel)]="examModel.omR_ContactRX.replaceMonthT">
                            <label class="pt-1 ml-1">MOS</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-md-8">
                    <button class="btn btn-primary" style="margin-top: -35px;" (click)="copyPower()">Copy Power</button>
                </div>
            </div>
            <div class="row pt-0">

                <div class="col-md-8 pr-0">
                    <table class="table table-bordered">
                        <tr class="text-center">
                            <th style="width: 5%;"></th>
                            <th style="width: 17%;">Power</th>
                            <th style="width: 19%;">Base Curve</th>
                            <th style="width: 19%;">Diam</th>
                            <th style="width: 30%;">Brand</th>
                            <th style="width: 23%;">Manufacturer</th>
                        </tr>
                        <tr>
                            <td class="pt-3 p-1"><b>OD</b></td>
                            <td class="row-td">
                                <input type="text" class="form-control formcontrol"
                                    [(ngModel)]="examModel.omR_ContactRX.odPowerB">
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="bcLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'odBaseCurveB')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.odBaseCurveB,'Base Curve',bcLookup,'bc',examModel.omR_ContactRX,'odBaseCurveB',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odBaseCurveB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(bcLookup,bcLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="diamLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'odDiameterB')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.odDiameterB,'Diam',diamLookup,'diam',examModel.omR_ContactRX,'odDiameterB',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odDiameterB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(diamLookup,diamLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="brandLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'odBrandB')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.odBrandB,'Brand',brandLookup,'brand',examModel.omR_ContactRX,'odBrandB',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odBrandB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(brandLookup,brandLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select class="pt-1 p-1" [items]="manufacture"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.odManufacturerB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0" style="margin-left: -3px;">
                                                <a href="javascript:void(0)" (click)="editManufacture()"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="pt-3 p-1"><b>OS</b></td>
                            <td class="row-td">
                                <input type="text" class="form-control formcontrol"
                                    [(ngModel)]="examModel.omR_ContactRX.osPowerB">
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="bcLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'osBaseCurveB')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.osBaseCurveB,'Base Curve',bcLookup,'Base Curve',examModel.omR_ContactRX,'osBaseCurveB',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osBaseCurveB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(bcLookup,bcLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-10">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="diamLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'osDiameterB')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.osDiameterB,'Diam',diamLookup,'Diam',examModel.omR_ContactRX,'osDiameterB',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osDiameterB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-2 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(diamLookup,diamLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select
                                                    addTagText="Add Item" [addTag]="CreateNew" class="pt-1 p-1"
                                                    [items]="brandLookup"
                                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'osBrandB')"
                                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.osBrandB,'Brand',brandLookup,'brand',examModel.omR_ContactRX,'osBrandB',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osBrandB">

                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0">
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(brandLookup,brandLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-12 p-0">
                                        <div class="form-row">
                                            <div class="col-md-11">
                                                <ng-select class="pt-1 p-1" [items]="manufacture"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="examModel.omR_ContactRX.osManufacturerB">
                                                </ng-select>
                                            </div>
                                            <div class="col-md-1 pl-0" style="margin-left: -3px;">
                                                <a href="javascript:void(0)" (click)="editManufacture()"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-md-4">
                    <div id="content">
                        <div class="titlebox"></div>
                        <div class="form-row ">
                            <label class="mx-1">Wear Type:</label>
                            <label class="mx-2"><input class="mx-1" [(ngModel)]="examModel.omR_ContactRX.wearTypeDWB"
                                    type="checkbox">DW</label>
                            <label class="mx-2"><input class="mx-1" [(ngModel)]="examModel.omR_ContactRX.wearTypeEWB"
                                    type="checkbox">EW</label>
                            <label class="mx-2"><input class="mx-1" [(ngModel)]="examModel.omR_ContactRX.wearTypeCONVB"
                                    type="checkbox">CONV</label>
                        </div>
                        <diV class="form-row">
                            <div class="row">
                                <div class="col-md-2 p-0">
                                    <label class="mx-1 pt-1">Good For:</label>
                                </div>
                                <div class="col-md-4 pr-0">
                                    <div class="form-row">
                                        <div class="col-md-9">
                                            <ng-select
                                                addTagText="Add Item" [addTag]="CreateNew" style="width: 80px;"
                                                [items]="_6PackLookup"
                                                (keyup)="keyFunc($event,examModel.omR_ContactRX,'goodForB')"
                                                (keydown.tab)="saveDropdown(examModel.omR_ContactRX.goodForB,'Good For',_6PackLookup,'6pack',examModel.omR_ContactRX,'goodForB')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="examModel.omR_ContactRX.goodForB">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(_6PackLookup,_6PackLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-0">
                                    <div class="form-row">
                                        <div class="col-md-9">
                                            <ng-select
                                                addTagText="Add Item" [addTag]="CreateNew" style="width: 80px;"
                                                [items]="_6PackLookup"
                                                (keyup)="keyFunc($event,examModel.omR_ContactRX,'packForB')"
                                                (keydown.tab)="saveDropdown(examModel.omR_ContactRX.packForB,'-packs',_6PackLookup,'6pack')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="examModel.omR_ContactRX.packForB">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-3">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(_6PackLookup,_6PackLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <label class="pt-1" style="margin-left:-10px !important;">-packs</label>
                                </div>
                            </div>
                        </diV>
                        <div class="form-row pt-1">
                            <!-- <div class="row"> -->
                                <div class="col-sm-4 p-0">
                                    <label class="mx-1 pt-1">Cleaning Solution:</label>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-row">
                                        <div class="col-md-11">
                                            <ng-select
                                                addTagText="Add Item" [addTag]="CreateNew"
                                                [items]="cleaningSolutionLookup"
                                                (keyup)="keyFunc($event,examModel.omR_ContactRX,'cleaningSolnB')"
                                                (keydown.tab)="saveDropdown(examModel.omR_ContactRX.cleaningSolnB,'Cleaning Solution',cleaningSolutionLookup,'cleaningsolution',examModel.omR_ContactRX,'cleaningSolnB')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="examModel.omR_ContactRX.cleaningSolnB">
                                            </ng-select>
                                        </div>
                                        <div class="col-md-1">
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(cleaningSolutionLookup,cleaningSolutionLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                <!-- </div> -->

                            </div>
                        </div>
                        <div class="row pt-1">
                            <label class="pt-1">Replace Every:</label>
                            <label class="pt-1 mx-2"><input type="checkbox"
                                    [(ngModel)]="examModel.omR_ContactRX.replaceDailyB">
                                    DAILY
                            </label>
                            <input type="text" class="form-control col-md-2"
                                [(ngModel)]="examModel.omR_ContactRX.replaceWeekB">
                            <label class="pt-1 mx-1">WKS</label>
                            <input type="text" class="form-control col-md-2"
                                [(ngModel)]="examModel.omR_ContactRX.replaceMonthB">
                            <label class="pt-1 ml-1">MOS</label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-1 col-sm-4">
                    <div>
                        <label>Remarks:</label>
                    </div>
                </div>
                <div class="col-md-9 col-sm-4">
                    <table style="width:100%">
                        <tr>
                            <td style="width:97%">
                                <ng-select addTagText="Add Item"
                                    [addTag]="CreateNew" class="dropdown" style="width: 99%;"
                                    [items]="RemarksCopyLookup"
                                    (keyup)="keyFunc($event,examModel.omR_ContactRX,'remarks')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ContactRX.remarks,'Remarks',RemarksCopyLookup,'Remarks',examModel.omR_ContactRX,'remarks')"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="examModel.omR_ContactRX.remarks">
                                </ng-select>
                            </td>
                            <td style="width:3%">
                                <a href="javascript:void(0)"
                                    (click)="editLookup(RemarksCopyLookup,RemarksCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-md-10 text-wrap" style="font-size: 14px !important">
                    <b>You have been counseled that contact lenses are medical devices and must be worn as prescribed by
                        your doctor. Misuse of these devices can cause permanent damage to your eyes and vision. Call
                        our office as soon as possible if you notice any redness, discomfort, discharge and/or decreased
                        vision.</b>
                </div>
            </div>
        </div>

        <!-- <div class="row pt-2" *ngIf="gaveCLRX">
            <div class="col-md-10 text-wrap" style="font-size: 14px !important">
                <b>You have been counseled that contact lenses are medical devices and must be worn as prescribed by
                    your doctor. Misuse of these devices can cause permanent damage to your eyes and vision. Call
                    our office as soon as possible if you notice any redness, discomfort, discharge and/or decreased
                    vision.</b>
            </div>
        </div> -->

        <hr>
        <div class="row">
          <div class="col-md-4 float-right">
            <div class="col-md-12">
              <ng-select [clearable]="true" placeholder="Select photo" [(ngModel)]="examModel.omR_Prescriptions.provider_ID"
                #myUserSelect (change)="onSelectUser(examModel.omR_Prescriptions.provider_ID)">
                <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                  {{userInfo.display_txt}}
                </ng-option>
              </ng-select>
              <div class="col-md-12 mt-3">
                <img [src]="imgURL" height="120px" *ngIf="imgURL">
              </div>
            </div>
            <!-- <div class="col-md-12">
                            <select class="form-control m-1" #myUserSelect (change)="onSelectUser(examModel.omR_Prescriptions.provider_ID)"
                                [ngModel]="examModel.omR_Prescriptions.provider_ID">
                                <option value="">
                                    --- Select ---
                                </option>
                                <option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                    {{userInfo.display_txt}}
                                </option>
                            </select>
                        </div> -->

            <div class="col-md-12 mt-3">
              Doctor Name : <label>{{user.display_txt}}</label>
            </div>
            <div class="col-md-12">
              License : <label>{{user.license_txt}}</label>
            </div>
          </div>
        </div>

    </div>

    <div id="print-section" hidden class="pt-5 mt-2">
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officename"> {{officename}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
        </div>
        <!-- <div class="col-12 text-center">
            <span class="doc-info">License # {{user?.license_txt}}</span>
        </div> -->
        <div class="col-12 text-center">
          <span class="doc-info">NPI # {{office_npi_txt}}</span>
        </div>

        <!-- <div class="col-12 text-center doc-info">
            <span class="doc-info">NPI # {{License}}</span>
        </div> -->
        <br>
        <h4 class="text-center"><b><img src="../../../../assets/img/Rx.png"></b></h4><br>
        <!-- <h4 class="text-center"><b>Rx</b></h4><br> -->
        <div class="row col-12">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Patient Name:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <label class="patient-reg-info">{{patientModel.firstName}} {{patientModel.lastName}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Birthdate:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <label class="patient-reg-info">{{datepipe.transform(patientModel.dateOfBirth, 'MM-dd-yyyy')}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <label class="patient-reg-info">{{currentDate | date:'MM-dd-yyyy'}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date of Service:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <label class="patient-reg-info">{{examModel.exam_Date | date:'MM-dd-yyyy'}}</label>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12" style="border: 1px solid black;"></div><br>
        <!-- <div class="row" *ngIf="gaveCLRX"> -->
        <div class="row">
            <div class="col-3" style="font-size:18px !important; margin-top: -15px;">
              <h4 class="text-center"><b>Spectacle Rx:</b></h4>
                <!-- <h4 class="text-center"><b><img src="../../../../assets/img/Rx.png"></b></h4><br> -->
                <!-- <b>Spectacle <img src="../../../../assets/img/Rx.png">:</b> -->
                <!-- <b>Spectacle Rx:</b> -->
            </div>
          </div>
          <div class="row">
            <div class="col-3"></div>
            <div class="col-1" style="margin-left:-70px;">
                <label class="patient-info">Expiration:</label>
            </div>
            <div class="col-2">
                <label class="patient-info">{{examModel.omR_ContactRX.expirationDate | date:'MM-dd-yyyy'}}</label>
            </div>
        </div>
        <div class="row px-0 pt-3">
            <div class="col-6 px-0">

                <table class="table table-bordered" style="font-size: 16px !important">
                    <tr class="text-center">
                        <th class="heading-background" style="width: 10%;"></th>
                        <th class="heading-background" style="width: 18%;">Sphere</th>
                        <th class="heading-background" style="width: 18%;">Cylinder</th>
                        <th class="heading-background" style="width: 18%;">Axis</th>
                        <th class="heading-background" style="width: 18%;">Prism</th>
                        <th class="heading-background" style="width: 18%;">Add</th>
                    </tr>
                    <tr class="text-center table-row">
                        <td><b>OD</b></td>
                        <td class="patient-info">{{examModel.omR_Prescriptions.sphereOD}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereOD"
                                class="formcontrol form-control patient-info"> -->
                            </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.cylinderOD}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderOD"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.axisOD}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisOD"
                                class="formcontrol form-control patient-info"> -->
                            </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.prismOD}}
                            <!-- <input type="text" #prism [(ngModel)]="examModel.omR_Prescriptions.prismOD"
                                class="formcontrol form-control patient-info"> -->
                            </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.addOD}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.addOD"
                                class="formcontrol form-control patient-info" > -->
                            </td>
                    </tr>
                    <tr class="text-center table-row">
                        <td><b>OS</b></td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.sphereOS}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereOS" class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.cylinderOS}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderOS"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.axisOS}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisOS" class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.prismOS}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.prismOS" class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.addOS}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.addOS" class="formcontrol form-control patient-info"> -->
                        </td>
                    </tr>
                </table>
                <div class="title-row">
                  <div class="text-center"><p><b>Primary pair</b></p></div>
                    <table class="">
                        <tr>
                            <th class="mx-5" style="width:23%;">Wear Type</th>
                            <th class="mx-5" style="width:23% ;">Lens Design</th>
                            <th class="mx-5" style="width:25% ;">Materials/Coats</th>
                            <th class="mx-5" style="width:45% ;"></th>
                        </tr>
                        <tr>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.fullTime1"
                                        class="mx-1">Full-Time</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.singleVision1"
                                        class="mx-1">Single
                                    Vision</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.uvCoat1"
                                        class="mx-1">UV
                                    Coat</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.scratchResistant1"
                                        class="mx-1">Scratch
                                    Resistance</label></td>
                        </tr>
                        <tr>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.distOnly1"
                                        class="mx-1">Dist.
                                    Only</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.biFocal1"
                                        class="mx-1">Bi-Focal</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.tint1"
                                        class="mx-1">Tint</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.photocromatic1"
                                        class="mx-1">Photocromatic</label></td>
                        </tr>
                        <tr>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.interOnly1"
                                        class="mx-1">Inter.
                                    Only</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.triFocal1"
                                        class="mx-1">Tri-Focal</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.safety1"
                                        class="mx-1">Safety</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.polycarbonate1"
                                        class="mx-1">Polycarbonate</label></td>
                        </tr>
                        <tr>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.readOnly1"
                                        class="mx-1">Read
                                    only</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.progressive1"
                                        class="mx-1">Progressive</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.antiReflective1"
                                        class="mx-1">Anti-Reflective</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.asphericPoly1"
                                        class="mx-1">Aspheric Poly</label></td>
                        </tr>
                        <tr>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.asNeeded1"
                                        class="mx-1">As
                                    Needed</label></td>
                            <td></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.cR391"
                                        class="mx-1">CR39</label></td>
                            <td>Other: {{examModel.omR_Prescriptions.other1}}</td>
                        </tr>
                    </table>
                </div>
                <br>

            </div>
            <div class="col-6 pr-0">
                <table class="table table-bordered">
                    <tr class="text-center heading-background">
                        <th class="heading-background">Sphere</th>
                        <th class="heading-background">Cylinder</th>
                        <th class="heading-background">Axis</th>
                        <th class="heading-background">Prism</th>
                        <th class="heading-background">Add</th>
                    </tr>
                    <tr class="text-center table-row">
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.sphereTop}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereTop" class="formcontrol form-control"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.cylinderTop}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderTop"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.axisTop}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisTop" class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.prismTop}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.prismTop" class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.addTop}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.addTop" class="formcontrol form-control patient-info"> -->
                        </td>
                    </tr>
                    <tr class="text-center table-row">
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.sphereBottom}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.sphereBottom"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.cylinderBottom}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.cylinderBottom"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.axisBottom}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.axisBottom"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.prismBottom}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.prismBottom"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                        <td class="patient-info">
                            {{examModel.omR_Prescriptions.addBottom}}
                            <!-- <input type="text" [(ngModel)]="examModel.omR_Prescriptions.addBottom"
                                class="formcontrol form-control patient-info"> -->
                        </td>
                    </tr>
                </table>

                <div class="title-row">
                  <div class="text-center"><p><b>Secondary Pair</b></p></div>
                    <table class="">
                        <tr>
                            <th class="mx-5" style="width:23% ;">Wear Type</th>
                            <th class="mx-5" style="width:23% ;">Lens Design</th>
                            <th class="mx-5" style="width:25% ;">Materials/Coats</th>
                            <th class="mx-5" style="width:45% ;"></th>
                        </tr>
                        <tr>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.fullTime2"
                                        class="mx-1">Full-Time</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.singleVision2"
                                        class="mx-1">Single
                                    Vision</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.uvCoat2"
                                        class="mx-1">UV
                                    Coat</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.scratchResistant2"
                                        class="mx-1">Scratch
                                    Resistance</label></td>
                        </tr>
                        <tr>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.distOnly2"
                                        class="mx-1">Dist.
                                    Only</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.biFocal2"
                                        class="mx-1">Bi-Focal</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.tint2"
                                        class="mx-1">Tint</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.photocromatic2"
                                        class="mx-1">Photocromatic</label></td>
                        </tr>
                        <tr>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.interOnly2"
                                        class="mx-1">Inter.
                                    Only</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.triFocal2"
                                        class="mx-1">Tri-Focal</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.safety2"
                                        class="mx-1">Safety</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.polycarbonate2"
                                        class="mx-1">Polycarbonate</label></td>
                        </tr>
                        <tr>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.readOnly2"
                                        class="mx-1">Read
                                    only</label></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.progressive2"
                                        class="mx-1">Progressive</label>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.antiReflective2"
                                        class="mx-1">Anti-Reflective</label></td>
                            <td><label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.asphericPoly2"
                                        class="mx-1">Aspheric Poly</label></td>
                        </tr>
                        <tr>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.asNeeded2"
                                        class="mx-1">As
                                    Needed</label></td>
                            <td></td>
                            <td> <label><input type="checkbox" [(ngModel)]="examModel.omR_Prescriptions.cR392"
                                        class="mx-1">CR39</label></td>
                            <td> <label>Other: {{examModel.omR_Prescriptions.other2}}</label></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-1">
                <label>Dist PD:</label>
            </div>
            <div class="col-2">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_Prescriptions.primaryDistPD"
                        class="form-control col-sm-10 font-weight-bold">
                </div>
            </div>
            <div class="col-1">
                <label>Near PD:</label>
            </div>
            <div class="col-2">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_Prescriptions.primaryNearPD" style="width:20px;"
                        class="form-control col-12 font-weight-bold">
                </div>
            </div>
            <div class="col-1">
                <label>Dist PD:</label>
            </div>
            <div class="col-2 ">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_Prescriptions.secondaryDistPD" style="width:20px;"
                        class="form-control col-12 bold font-weight-bold">
                </div>
            </div>
            <div class="col-1">
                <label>Near PD:</label>
            </div>
            <div class="col-2">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_Prescriptions.secondaryNearPD" style="width: 20px;"
                        class="form-control col-12 font-weight-bold">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-1">
                <label>Remarks:</label>
            </div>
            <input type="text" [(ngModel)]="examModel.omR_Prescriptions.remarks"
                class="formControl  border-bottom  border-dark col-10 mx-1 ">
        </div>
        <div class="row">
            <div class="col-12" style="margin-top: -40x;">
                *UV Coat is recommended in your lenses to prevent UV related eye damage.
            </div>
        </div>
        <hr>
        <!-- <div *ngIf="examModel.omR_ProgressNotes[examModel.omR_ProgressNotes.length-1].gaveCLRX==true"> -->
        <div *ngIf="gaveCLRX" class="pt-3">
            <div class="row">
                <div class="col-3" style="font-size:18px !important; margin-top: -23px;">
                    <!-- <b>Contact <img src="../../../../assets/img/Rx.png">:</b> -->
                    <!-- <b>Contact Rx:</b> -->
                    <h4 class="text-center"><b>Contact Rx:</b></h4><br>
                </div>
                <div class="col-1" style="margin-left:-75px;">
                    <label class="patient-info">Expiration:</label>
                </div>
                <div class="col-2">
                    <label class="patient-info">{{examModel.omR_ContactRX.expirationDate | date:'MM-dd-yyyy'}}</label>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-8 px-0">
                    <table class="table table-bordered">
                        <tr class="text-center">
                            <th class="heading-background" style="width: 5%;"></th>
                            <th class="heading-background" style="width: 22%;">Power</th>
                            <th class="heading-background" style="width: 15%;">BC</th>
                            <th class="heading-background" style="width: 15%;">Diam</th>
                            <th class="heading-background" style="width: 35%;">Brand</th>
                            <th class="heading-background" style="width:30%;">Manufacturer</th>
                        </tr>
                        <tr class="text-center table-row">
                            <td class="pt-3 p-1"><b>OD</b></td>
                            <td class="patient-info">
                                {{examModel.omR_ContactRX.odPowerT}}
                                <!-- <input type="text" [(ngModel)]="examModel.omR_ContactRX.odPowerT"
                                    class="form-control formcontrol patient-info"> -->
                            </td>
                            <td class="patient-info text-center">
                              {{examModel.omR_ContactRX.odBaseCurveT}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-12 pt-3 p-1 patient-info">
                                                {{examModel.omR_ContactRX.odBaseCurveT}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info text-center">
                              {{examModel.omR_ContactRX.odDiameterT}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-11 pt-3 p-1 patient-info">
                                                {{examModel.omR_ContactRX.odDiameterT}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.odBrandT}}
                                <!-- <div class="row">
                                     <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.odBrandT}}
                                     </div>
                                 </div> -->
                             </td>
                             <td class="patient-info">
                              {{examModel.omR_ContactRX.odManufacturerT}}
                                 <!-- <div class="row">
                                     <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.odManufacturerT}}
                                     </div>
                                 </div> -->
                             </td>
                        </tr>
                        <tr class="text-center table-row">
                            <td class="pt-3 p-1"><b>OS</b></td>
                            <td class="patient-info">
                                {{examModel.omR_ContactRX.osPowerT}}
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osBaseCurveT}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-11 pt-3 p-1 patient-info">
                                                {{examModel.omR_ContactRX.osBaseCurveT}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osDiameterT}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-12 pt-3 p-1 patient-info">
                                                {{examModel.omR_ContactRX.osDiameterT}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osBrandT}}
                                <!-- <div class="row">
                                    <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.osBrandT}}
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osManufacturerT}}
                                <!-- <div class="row">
                                    <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.osManufacturerT}}
                                    </div>
                                </div> -->
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-4 pr-0">
                    <div class="titlebox"></div>
                    <div class="form-row">
                        <label>Wear Type:</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label class="mx-2"><input class="mx-1 font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.wearTypeDWT"
                                type="checkbox">DW</label>
                        <label class="mx-1"><input class="mx-1 font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.wearTypeCONVT"
                                type="checkbox">CONV</label>
                        <label class="mx-1"><input class="mx-1 font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.wearTypeEWT"
                                type="checkbox">EW</label>
                    </div>
                    <diV class="form-row">
                        <div class="col-3">
                            <label class="p-0">Good For:</label>
                        </div>
                        <div class="col-4">
                            <label class="mx-2"><input type="text" [(ngModel)]="examModel.omR_ContactRX.goodForT"
                                    class="form-control mx-2 font-weight-bold" style="width: 60px;">
                            </label>
                            <label style=" margin-right:-57px; font-weight: bold;">{{examModel.omR_ContactRX.packForT}}-Packs</label>
                        </div>

                    </diV>
                    <div class="form-row">
                        <div class="form-row">
                            <label class="mx-1">Replace Every:</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="pt-1 mx-2"><input type="checkbox"
                              [(ngModel)]="examModel.omR_ContactRX.replaceDailyT">
                              DAILY
                        </label>
                            &nbsp;<input type="text" class="form-control col-1 font-weight-bold"
                                [(ngModel)]="examModel.omR_ContactRX.replaceWeekT">
                            &nbsp;<label class="pt-1">Wks</label>
                            &nbsp;<input type="text" class="form-control col-1 font-weight-bold"
                                [(ngModel)]="examModel.omR_ContactRX.replaceMonthT">
                            &nbsp;<label class="pt-1">Mths</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 p-0">
                            <label>Cleaning Soln:</label>
                        </div>
                        <div class="col-4">
                            <div class="form-row" style="margin-left: -36px;">
                                <input type="text" class="form-control font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.cleaningSolnT">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-0">
                <div class="col-8 px-0">
                    <table class="table table-bordered">
                        <tr class="text-center">
                            <!-- <th class="heading-background" style="width: 5%;"></th>
                            <th class="heading-background" style="width: 20%;">Power</th>
                            <th class="heading-background" style="width: 15%;">BC</th>
                            <th class="heading-background" style="width: 15%;">Diam</th>
                            <th class="heading-background" style="width: 35%;">Brand</th>
                            <th class="heading-background" style="width:30%;">Manufacturer</th> -->
                            <th class="heading-background" style="width: 5%;"></th>
                            <th class="heading-background" style="width: 22%;">Power</th>
                            <th class="heading-background" style="width: 15%;">BC</th>
                            <th class="heading-background" style="width: 15%;">Diam</th>
                            <th class="heading-background" style="width: 35%;">Brand</th>
                            <th class="heading-background" style="width:30%;">Manufacturer</th>
                        </tr>
                        <tr class="text-center table-row">
                            <td class="pt-3 p-1"><b>OD</b></td>
                            <td class="patient-info">
                                {{examModel.omR_ContactRX.odPowerB}}
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.odBaseCurveB}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-10 patient-info">
                                                {{examModel.omR_ContactRX.odBaseCurveB}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.odDiameterB}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-10 patient-info">
                                                {{examModel.omR_ContactRX.odDiameterB}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.odBrandB}}
                                <!-- <div class="row">
                                    <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.odBrandB}}
                                    </div>
                                </div> -->

                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.odManufacturerB}}
                                <!-- <div class="row">
                                    <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.odManufacturerB}}
                                    </div>
                                </div> -->
                            </td>
                        </tr>
                        <tr class="text-center table-row">
                            <td class="pt-3 p-1"><b>OS</b></td>
                            <td class="patient-info">
                                {{examModel.omR_ContactRX.osPowerB}}
                                <!-- <input type="text" class="form-control formcontrol patient-info"
                                    [(ngModel)]="examModel.omR_ContactRX.osPowerB"> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osBaseCurveB}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-10 patient-info">
                                                {{examModel.omR_ContactRX.osBaseCurveB}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osDiameterB}}
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <div class="form-row">
                                            <div class="col-10 patient-info">
                                                {{examModel.omR_ContactRX.osDiameterB}}
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osBrandB}}
                                <!-- <div class="row">
                                    <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.osBrandB}}
                                    </div>
                                </div> -->

                            </td>
                            <td class="patient-info">
                              {{examModel.omR_ContactRX.osManufacturerB}}
                               <!-- <div class="row">
                                    <div class="col-12 patient-info">
                                        {{examModel.omR_ContactRX.osManufacturerB}}
                                    </div>
                                </div> -->
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-4 pr-0">
                    <div class="titlebox"></div>
                    <div class="row">
                        <div class="form-row">
                            <label>Wear Type:</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="mx-2"><input class="mx-1 font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.wearTypeDWB"
                                    type="checkbox">DW</label>
                            <label class="mx-1"><input class="mx-1 font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.wearTypeCONVB"
                                    type="checkbox">CONV</label>
                            <label class="mx-1"><input class="mx-1 font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.wearTypeEWB"
                                    type="checkbox">EW</label>
                        </div>
                    </div>
                    <diV class="form-row">
                        <div class="col-3">
                            <label class="p-0">Good For:</label>
                        </div>
                        <div class="col-4">
                            <label class="mx-2">
                                <input type="text" class="form-control mx-2 font-weight-bold" style="width: 60px;"
                                    [(ngModel)]="examModel.omR_ContactRX.goodForB">
                            </label>
                            <label style=" margin-right:-57px; font-weight: bold;">{{examModel.omR_ContactRX.packForB}}-Packs</label>
                        </div>

                    </diV>
                    <div class="form-row">
                        <div class="form-row">
                            <label class="mx-1">Replace Every:</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label class="pt-1 mx-2"><input type="checkbox"
                              [(ngModel)]="examModel.omR_ContactRX.replaceDailyB">
                              DAILY
                      </label>
                            &nbsp;<input type="text" class="form-control col-1 font-weight-bold"
                                [(ngModel)]="examModel.omR_ContactRX.replaceWeekB">
                            &nbsp;<label>Wks</label>
                            &nbsp;<input type="text" class="form-control col-1 font-weight-bold"
                                [(ngModel)]="examModel.omR_ContactRX.replaceMonthB">
                            &nbsp;<label>Mths</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label style="margin-left: -18px;">Cleaning Soln:</label>
                        </div>
                        <div class="col-4">
                            <div class="form-row" style="margin-left: -36px;">
                                <input type="text" class="form-control font-weight-bold" [(ngModel)]="examModel.omR_ContactRX.cleaningSolnB">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1">
                    <label>Remarks:</label>
                </div>
                <input type="text" [(ngModel)]="examModel.omR_ContactRX.remarks"
                    class="formControl  border-bottom  border-dark col-10 mx-1 ">
            </div>
            <div class="row pt-2">
                <div class="col-10 text-wrap" style="font-size: 16px !important">
                    *You have been counseled that contact lenses are medical devices and must be worn as
                    prescribed by your doctor. Misuse of these devices can cause permanent damage to your eyes
                    and vision. Call our office as soon as possible if you notice any redness, discomfort,
                    discharge and/or decreased vision.
                </div>
            </div>
            <b><hr></b>
        </div>


        <!-- <div class="row">

            <div class="col-2">
            </div>
            <div class="col-2">
            </div>
            <div class="col-1">
                <label>Doctor Name:</label>
            </div>
            <div class="col-5">
                <input type="text" [(ngModel)]="user.display_txt"
                    class="form-control col-sm-12">
            </div>
        </div>

        <div class="row">

            <div class="col-2">
                <label>Doctor's Signature:</label>
            </div>
            <div class="formControl  border-bottom  border-dark col-2 mx-1 ">
                <img [src]="imgURL" height="80px" *ngIf="imgURL">
            </div>
            <div class="col-1">
                <label>License#:</label>
            </div>
            <div class="col-2">
                <div class="form-row">
                    <input type="text" [(ngModel)]="user.license_txt" class="form-control col-sm-12">
                </div>
            </div>
            <div class="col-1">
                <label>NPI#:</label>
            </div>
            <div class="col-2">
                <div class="form-row">
                    <input type="text" [(ngModel)]="user.npi_txt" class="form-control col-sm-12">
                </div>
            </div>
        </div> -->


      <div class="row col-12">
        <div class="col-6">
          <div class="row">
            <div class="col-4"><b>Doctor's Signature:</b></div>
            <div class="col-8"> <img [src]="imgURL" height="80px" *ngIf="imgURL"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-4"><b>Doctor Name:</b></div>
            <div class="col-8"> {{user.display_txt}}</div>
          </div>
          <div class="row">
            <div class="col-4"><b>License#:</b></div>
            <div class="col-8">{{user.license_txt}}</div>
          </div>
          <div class="row">
            <div class="col-4"><b>NPI#:</b></div>
            <div class="col-8">{{user.npi_txt}}</div>
          </div>
        </div>
      </div>
    </div>
