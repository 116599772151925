export class AppointmentModel{
public appointment_id : string = '';
public patient_id : string = '';
public subject_txt : string = '';
public reason_txt : string = '';
public duration_val : number = 0;
public note_txt : string = '';
public resource_ids : string = '';
public exam_id : number = 0;
public status_txt : string = '';
public template_ind : boolean = false;
public created_dt : Date = new Date();
public created_by : number = 0;
public updated_dt : Date = new Date();
public updated_by : number = 0;
public patient_txt : string = '';
public appointment_type_txt : string = '';
public original_starttime_dt : Date = new Date();
public original_endtime_dt : Date = new Date();
public starttime_txt : string = '';
public endtime_txt : string = '';
public master_appointment_id : string = '';
public time_zone: number = 0;
}

export class SecheduleReportModel{
    public resource : string = '';
    public dateResource: string = '';
    public timeStart : string = '';
    public timeEnd : string = '';
    public firstName : string = '';
    public lastName : string = '';
    public dob : string = '';
    public phone : string = '';
    public email : string = '';
    public reason : string = '';    
    public note : string = '';    
}