export class FamilyHistoryAdditionalModel{
public familyhistoryadditional_id : string = '';
public patient_review_id : string = '';
public patient_id : string = '';
public condition_txt : string = ''; 
public condition_yes_no : boolean = false;
public relationship_to_patient_txt: string = '';
public created_dt : Date = new Date();
public created_by : string = '';
public updated_dt : Date = new Date();
public updated_by : string = '';
}