import { ToastrService } from 'ngx-toastr';
import { AddAllergyComponent } from './../add-allergy/add-allergy.component';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AllergyModel } from 'src/app/model/AllergyModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import * as moment from 'moment';
import { PatientModel } from 'src/app/model/patientModel';

@Component({
  selector: 'app-allergy',
  templateUrl: './allergy.component.html',
  styleUrls: ['./allergy.component.scss']
})
export class AllergyComponent implements OnInit {

  columnAllergy: any;
  rowAllergy: AllergyModel[] = [];
  bsModalRef!: BsModalRef;
  allergyList: AllergyModel[] = [];
  filterString: string = 'All';
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  allergyGridApi: any;
  constructor(private modalService: BsModalService,
     private _clinicalService: ClinicalService,private toaster : ToastrService) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
   }

  ngOnInit() {
    this.columnAllergy = [
      {
        headerName: 'Date Recorded',
        field: 'created_dt', sortable: true, sort: 'desc', resizable: true, rowDrag: true, filter: true, floatingFilter: false,
        cellRenderer: (data:any) => {
          return moment(data.data.created_dt).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Type',
        field: 'type_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, editable: true, floatingFilter: false
      },
      {
        headerName: 'Medication Name',
        field: 'name_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Vocabulary',
        field: 'vocabulary_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Code',
        field: 'rx_norm_code_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Allergy Reaction',
        field: 'reaction_detail_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Severity',
        field: 'severity_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Source',
        field: 'source_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Status',
        field: 'status_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
        ,editable : true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['Active', 'Inactive', 'Discontinued'],
        },
      },
      {
        headerName: 'Date Modified',
        field: 'created_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: (data:any) => {
          return moment(data.data.updated_dt).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Reconcilation',
        field: 'reconciled_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      }
    ];
    // this.rowAllergy = [];
    this.getAllergy();
    // this.rowAllergy = Object.assign([], this.allergyList);
  }

  edit() {
    const initialState = {
      list: this.rowAllergy,
      title: 'Patient Review - Medication'
    };
    this.bsModalRef = this.modalService.show(AddAllergyComponent, { initialState });
    this.bsModalRef.setClass('modal-xl');
    // this.bsModalRef.setClass('center')
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getAllergy();
    })
  }

  // save() {
  //   this._clinicalService.saveAllergy(this.rowAllergy).subscribe(res => {
  //     this.getAllergy();
  //     this.rowAllergy = Object.assign([], this.allergyList);
  //   })
  // }

  getAllergy() {    
    this._clinicalService.getAllergy(this.patientId).subscribe(res => {
      this.allergyList = Object.assign([], res);
      this.filterAllergy();
    })
  }

  filterAllergy() {
    if (this.filterString != 'All')
      this.rowAllergy = this.allergyList.filter(x => x.status_txt == this.filterString);
    else
      this.rowAllergy = Object.assign([],this.allergyList);
  }

  save(){
    this._clinicalService.saveAllergy(this.rowAllergy).subscribe(res=>{
      this.toaster.success('Record Saved Successfully.');
      // this.bsModalRef.hide();
      this.getAllergy()
    },(error)=>{
      this.toaster.error(error.error);
    });
  }

  onAllergyGridReady(params:any){
    this.allergyGridApi = params.api;
  }

  onCellValueChanged(params:any){
    this.rowAllergy[params.rowIndex] = params.data;
  }

}
