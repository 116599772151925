<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div id="content">
        <div class="titlebox">Resources</div>
        <div class="pt-2">
            <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 300px; width: 100%;" class="ag-theme-alpine" [rowData]="rowResources"
                [columnDefs]="columnResources">
            </ag-grid-angular>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="text-center">
        <button type="button" class="btn btn-default" (click)="save()">Save</button>
        <button type="button" class="btn btn-default" disabled>Send Email</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
    </div>
</div>