import { GlobalService } from './../../../../services/global.service';
import { LookupService } from './../../../../services/lookup.service';
import { LookupModel } from 'src/app/model/lookupModel';
import { HourModel } from 'src/app/model/HourModel';
import { MinuteModel } from 'src/app/model/MinuteModel';
import { TimeModel } from 'src/app/model/TimeModel';
import { ResourceModel } from 'src/app/model/ResourceModel';
import { PatientService } from 'src/app/services/patient.service';
import { ToastrService } from 'ngx-toastr';
import { AddPatientComponent } from './../../add-patient/add-patient.component';
import { PatientModel, PatientSearchModel } from 'src/app/model/patientModel';
import { AppointmentModel } from './../../../../model/AppointmentModel';
import { ClinicalService } from './../../../../services/clinical.service';
import {ShedulingService} from './../../../../services/sheduling.service';
import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { CalenderComponent } from 'src/app/components/scheduling/calender/calender.component';
import * as moment from 'moment';


@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss']
})
export class AddAppointmentComponent implements OnInit {
  title: string = '';
  @Input()
  list: AppointmentModel = new AppointmentModel();
  @Input()
  isFromScheduling: boolean = false;
  @Input()
  isForUnavailability: boolean = false;
  isEdit: boolean = false;
  appointmentModel: AppointmentModel = new AppointmentModel();
  closeBtnName: string = '';
  bsModalRef1!: BsModalRef;
  patientModel: PatientModel = new PatientModel();
  hour: number = 0;
  endHour: number = 0;
  durationList: HourModel[] = [{name_txt: "5"}, {name_txt: "10"}, {name_txt: "15"}, {name_txt: "20"}, {name_txt: "30"},{name_txt: "45"}, {name_txt: "60"}, {name_txt: "90"}, {name_txt: "120"}];
  hourList: HourModel[] = [{name_txt: "1"}, {name_txt: "2"},{name_txt: "3"},{name_txt: "4"},{name_txt: "5"},{name_txt: "6"},{name_txt: "7"},{name_txt: "8"}, {name_txt: "9"},{name_txt: "10"},{name_txt: "11"},{name_txt: "12"}];
  minuteList: MinuteModel[] = [{name_txt: "00"}, {name_txt: "05"}, {name_txt: "10"}, {name_txt: "15"}, {name_txt: "20"}, {name_txt: "25"}, {name_txt: "30"}, {name_txt: "35"}, {name_txt: "40"}, {name_txt: "45"}, {name_txt: "50"}, {name_txt: "55"}];
  timeList: TimeModel[] = [{name_txt: "AM"}, {name_txt: "PM"}];
   minute: string = '00';
   endMinute: string = '00';
   time: string = '';
   endTime: string = '';
  patientName: string = '';
  patientDetail: PatientModel = new PatientModel();
  rowPatient : PatientModel[] = [];
  public rowSearchPatient: PatientModel[] = [];
  patientQuery : string = '';
  isShowPatientDetail : boolean = false;
  columnPatient : any
  rowStyle : any;
  bsConfig : Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  reasonLookup : LookupModel[] = [];
  reasonLookupCopy : LookupModel[] = [];
  unavailablereasonLookup : LookupModel[] = [];
  unavailablereasonLookupCopy : LookupModel[] = [];
  statusLookup : LookupModel[] = [];
  statusLookupCopy : LookupModel[] = [];
  resourceList: ResourceModel[] = [];
  check_value: string = '';
  timeout: any = null;
  rowSize: number = 5;
  Firstname: string = '';
  Lastname: string = '';
  pageSize: number = 1



  constructor(public bsModalRef: BsModalRef, private _clinicalService: ClinicalService,
     private modalService: BsModalService,private toastr:ToastrService,
     private _patientService : PatientService,private lookupService : LookupService,
     private _schedulingService: ShedulingService,
     private toaster : ToastrService,private globalService : GlobalService) { }

  ngOnInit() {

    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    let date = new Date();
    if(this.list.original_starttime_dt != null)
    {
      date = new Date(this.list.original_starttime_dt);
    }
    const hour = date.getHours();
    let minute = date.getMinutes();
    if (hour > 12) {
      this.hour = hour - 12;
      this.time = 'PM';
    }
    else if (hour == 12) {
      this.hour = hour;
      this.time = 'PM';
    }
    else {
      this.hour = hour;
      this.time = 'AM';
    }
    for (let i = 0; i <= 60; i = i + 5) {

      if (minute <= i) {
        if (minute > 57) {
          this.hour = this.hour + 1;
          this.minute = '00';
          if (this.hour == 12) {
            this.time = this.time == 'PM' ? 'AM' : 'PM';
          }
        }
        else if (minute % 5 < 3 && minute != i) {
          this.minute = `${i - 5}`;
        }
        else {
          this.minute = i == 0 ? "00" : `${i}`;
        }
        break;
      }
    }
    debugger;
    if (this.isForUnavailability) {
      let endDate = new Date();
      if(this.list.original_endtime_dt){
        endDate = new Date(this.list.original_endtime_dt);
      }
      const hour = endDate.getHours();
      let minute = endDate.getMinutes();
      if (hour > 12) {
        this.endHour = hour - 12;
        this.endTime = 'PM';
      } else if (hour == 12) {
        this.endHour = hour;
        this.endTime = 'PM';
      } else {
        this.endHour = hour;
        this.endTime = 'AM';
      }
      for (let i = 0; i <= 60; i = i + 5) {
        if (minute <= i) {
          if (minute > 57) {
            this.endHour = this.endHour + 1;
            this.endMinute = '00';
            if (this.endHour == 12) {
              this.endTime = this.endTime == 'PM' ? 'AM' : 'PM';
            }
          } else if (minute % 5 < 3 && minute != i) {
            this.endMinute = `${i - 5}`;
          } else {
            this.endMinute = i == 0 ? '00' : `${i}`;
          }
          break;
        }
      }
    }

    if(this.list.appointment_id == '' || !this.list.appointment_id){
      if(this.isFromScheduling){
        this.patientModel = new PatientModel();
      }else{
        this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
        this.patientModel = this.patientDetail;
      }
      if(this.patientModel == undefined || this.patientModel == null)
      {
        this.patientModel = new PatientModel();
      }
      if(this.patientDetail.firstName != "" || this.patientDetail.lastName != "")
      {
        this.list.subject_txt = this.patientDetail.lastName + ', ' + this.patientDetail.firstName;
        this.isEdit = false;
      }
    }
    else{
      this.getPatientDetail();
      this.isEdit = true;
    }
    this.columnPatient = [
      {
        headerName: 'Last Name',
        field: 'lastName', sortable: true,  resizable: true, rowDrag: true
      },
      { headerName: 'First Name', field: 'firstName', sortable: true,  resizable: true },
      { headerName: 'MI', field: 'middleInitial', sortable: true,  resizable: true },
      { headerName: 'Pat Acct #', field: 'patientAcctNo', sortable: true,  resizable: true },
      { headerName: 'City', field: 'city', sortable: true,  resizable: true },
      { headerName: 'State', field: 'state', sortable: true,  resizable: true },
      { headerName: 'Zip Code', field: 'zip', sortable: true,  resizable: true },
      {
        headerName: 'Birthdate', field: 'dateOfBirth', sortable: true, resizable: true, filter: 'agDateColumnFilter'
      },
      { headerName: 'SSN', field: 'insuredSSN', sortable: true,  resizable: true },
      { headerName: 'Email', field: 'emailaddress', sortable: true,  resizable: true },
      { headerName: 'Phone', field: 'homePhone', sortable: true,  resizable: true }

    ];
    this.rowStyle = { height: '200px' };

    this.getAllLookup();
    this.getAllResources();
    if(this.list.duration_val == 0){
      this.list.duration_val=15;
    }

  }

  save() {
    let sDtime = moment(this.list.original_starttime_dt);
    let eDtime = moment(new Date());
    let difDtime = eDtime.diff(sDtime, "days");
    if(difDtime < 0){
      this.toaster.warning("End Date/Time cannot be before Today's Date/Time");
      return;
    }
    this.list.original_starttime_dt = new Date((new Date(this.list.original_starttime_dt)).getUTCFullYear() + '-' + ((new Date(this.list.original_starttime_dt)).getUTCMonth() + 1) + '-' + (new Date(this.list.original_starttime_dt)).getDate() + ' ' + this.hour + ':' + this.minute + ' ' + this.time);
    this.list.starttime_txt = new Date((new Date(this.list.original_starttime_dt)).getUTCFullYear() + '-' + ((new Date(this.list.original_starttime_dt)).getUTCMonth() + 1) + '-' + (new Date(this.list.original_starttime_dt)).getDate() + ' ' + this.hour + ':' + this.minute + ' ' + this.time).toString();
    this.list.endtime_txt = '';
    this.list.appointment_type_txt = "Patient Appointment";
    this.list.time_zone = -(new Date().getTimezoneOffset() / 60);
    if(this.isEdit){
      this._clinicalService.updateAppointment(this.list).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.bsModalRef.hide();
        location.reload();
      },(error)=>{
        this.toastr.success(error.error);
      });
    }
    else{

      this._clinicalService.saveAppointment(this.list).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.bsModalRef.hide();
        location.reload();
      },(error)=>{
        this.toastr.success(error.error);
      });
    }
  }
  saveUnavailibility() {
    debugger;
    let sDtime = moment(this.list.original_starttime_dt);
    let eDtime = moment(this.list.original_endtime_dt);
    let difDtime = eDtime.diff(sDtime, "days");
    if(difDtime < 0){
      this.toaster.warning("End Date/Time cannot be before Start Date/Time");
      return;
    }
    this.list.original_starttime_dt = new Date((new Date(this.list.original_starttime_dt)).getUTCFullYear() + '-' + ((new Date(this.list.original_starttime_dt)).getUTCMonth() + 1) + '-' + (new Date(this.list.original_starttime_dt)).getDate() + ' ' + this.hour + ':' + this.minute + ' ' + this.time);
    this.list.starttime_txt = new Date((new Date(this.list.original_starttime_dt)).getUTCFullYear() + '-' + ((new Date(this.list.original_starttime_dt)).getUTCMonth() + 1) + '-' + (new Date(this.list.original_starttime_dt)).getDate() + ' ' + this.hour + ':' + this.minute + ' ' + this.time).toString();
    this.list.original_endtime_dt = new Date((new Date(this.list.original_endtime_dt)).getUTCFullYear() + '-' + ((new Date(this.list.original_endtime_dt)).getUTCMonth() + 1) + '-' + (new Date(this.list.original_endtime_dt)).getDate() + ' ' + this.endHour + ':' + this.endMinute + ' ' + this.endTime);
    this.list.endtime_txt = new Date((new Date(this.list.original_endtime_dt)).getUTCFullYear() + '-' + ((new Date(this.list.original_endtime_dt)).getUTCMonth() + 1) + '-' + (new Date(this.list.original_endtime_dt)).getDate() + ' ' + this.endHour + ':' + this.endMinute + ' ' + this.endTime).toString();
    this.list.appointment_type_txt = "Resource Unavailable";
    this.list.time_zone = -(new Date().getTimezoneOffset() / 60);
    var stime = moment(this.list.starttime_txt);
    var etime = moment(this.list.endtime_txt);
    this.list.duration_val = -(stime.diff(etime, 'minutes'));
    if(this.list.duration_val < 5){
      this.toaster.warning("End Date/Time cannot be before Start Date/Time");
      return;
    }
    if(this.isEdit){
      this._clinicalService.updateAppointment(this.list).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.bsModalRef.hide();
        location.reload();
      },(error)=>{
        this.toastr.success(error.error);
      });
    }
    else{

      this._clinicalService.saveAppointment(this.list).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.bsModalRef.hide();
        location.reload();
      },(error)=>{
        this.toastr.success(error.error);
      });
    }
  }

  addPatient() {
    const initialState = {
      title: 'New Patient'
    };
    this.bsModalRef1 = this.modalService.show(AddPatientComponent, { initialState });
    this.bsModalRef1.content.closeBtnName = 'Close';
    // this.bsModalRef1.hide

    this.bsModalRef1.content.event.subscribe((res: PatientModel) => {
      this.patientModel.homePhone = res.homePhone;
      this.patientModel.dateOfBirth = res.dateOfBirth;
      this.patientModel.emailaddress = res.emailaddress;
      this.patientName = res.lastName + ', ' + res.firstName;
      this.list.subject_txt = this.patientName;
      this.list.patient_id = res.patient_id;
    })

  }

  getPatientDetail(){
    this._patientService.getPatientPatientId(this.list.patient_id).subscribe(res=>{
      this.patientDetail = res;
      this.patientModel = res;
    })
  }
  filterPatientList(patientList: PatientModel[], searchString: string[]){
    let searchKeys = new PatientSearchModel();
    searchString.forEach((word) => {
      patientList = patientList.filter((obj: any)=>{
        return Object.keys(searchKeys).reduce((acc, curr)=>{
              return acc || obj[curr]?.toLowerCase().includes(word.toLowerCase());
        }, false);
      });
    })
    return patientList;
  }
  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.getPatientList();
      }
    }, 1000);
  }

  getPatientList() {

    this.rowPatient = this.rowSearchPatient;
    if (this.list.subject_txt.toString().trim().length > 0) {
      this.isShowPatientDetail = true;
      if (this.list.subject_txt.indexOf(" ") !== -1) {
        var words = this.list.subject_txt.split(" ");
        if(this.rowPatient.length > 0){
          this.rowPatient = this.filterPatientList(this.rowPatient, words);
        }
        if(this.rowPatient.length == 0){
          this.Firstname = words[0];
          this.Lastname = words[1];
          this._patientService.getPatientListSearch(this.Firstname.trim(), this.Lastname.trim()).subscribe((res: any) => {
            this.rowPatient = [];
            this.rowPatient = res;
            this.rowSearchPatient = this.rowPatient;
          }, (error) => {
            this.toaster.error(error.error);
          });
        }
      }
      else {
        // if(this.rowPatient.length > 0){
        //   let searchWords: string[] = [];
        //   searchWords.push(this.patientQuery.trim());
        //   this.rowPatient = this.filterPatientList(this.rowPatient, searchWords);
        // }
        //if(this.rowPatient.length == 0){
          this._patientService.getPatientList(this.list.subject_txt.trim()).subscribe((res: any) => {
            this.rowPatient = [];
            this.rowPatient = res;
            this.rowSearchPatient = this.rowPatient;
          },
          (error) => {
            this.toaster.error(error.error);
          });
        //}
      }

    }else{
      this.isShowPatientDetail = false;
      this.rowPatient = [];
    }
    // else {
    //   this._patientService.getPatientList('', this.rowSize,this.pageSize).subscribe((res: any) => {
    //     this.rowPatient = res;
    //   }, (error) => {
    //     this.toaster.error(error.error);
    //   });
    // }
    // this.dataSource = {
    //   getRows: (params: IGetRowsParams) => {
    //     params.successCallback(
    //       this.rowPatient,
    //       101
    //     );
    //   }
    // }
  }

  // getPatientList() {
  //   this.rowPatient = [];
  //   if (this.list.subject_txt.toString().trim().length>0) {
  //     this.isShowPatientDetail = true;
  //     this._patientService.getPatientList(this.list.subject_txt.trim()).subscribe((res: any) => {
  //       this.rowPatient = res;
  //     })
  //   }else{
  //     this.isShowPatientDetail = false;
  //   }
  // }

  changeSelection(event : any){

    this.patientDetail = event.api.getSelectedRows()[0];
    this.patientModel = event.api.getSelectedRows()[0];
    this.list.patient_id = event.api.getSelectedRows()[0].patient_id;
    this.list.subject_txt = event.api.getSelectedRows()[0].lastName + ', ' + event.api.getSelectedRows()[0].firstName;
    this.isShowPatientDetail = false;
  }

  getAllLookup(){
    this.lookupService.getAllLookupData().subscribe(res=>{
      this.reasonLookup = (res.filter(x=>x.group_txt == 'appointmentreason' && (x.sub_group_txt == '' || x.sub_group_txt == 'Patient Appointment' ) && x.active_ind == true)!)
      this.reasonLookup = this.reasonLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.reasonLookupCopy = (res.filter(x=>x.group_txt == 'appointmentreason' && (x.sub_group_txt == '' || x.sub_group_txt == 'Patient Appointment' ))!)

      this.unavailablereasonLookup = (res.filter(x=>x.group_txt == 'appointmentreason' && x.sub_group_txt == 'Resource Unavailable' && x.active_ind == true)!)
      this.unavailablereasonLookup = this.unavailablereasonLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.unavailablereasonLookupCopy = (res.filter(x=>x.group_txt == 'appointmentreason' && x.sub_group_txt == 'Resource Unavailable')!)

      this.statusLookup = (res.filter(x=>x.group_txt == 'appointmentstatus' && x.active_ind == true)!)
      this.statusLookup = this.statusLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      //this.list.status_txt = this.statusLookup[1].name_txt;
      this.statusLookupCopy = (res.filter(x=>x.group_txt == 'appointmentstatus')!)
    })
  }

  getAllResources(){

    this._schedulingService.getResources().subscribe(res=>{
      this.resourceList = (res.filter(x=>x.active_ind == true)!);
      if(this.patientDetail != undefined && this.patientDetail != null)
      {
        this.list.patient_id = this.patientDetail.patient_id;
      }
    })
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this.lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getAllLookup()
      // this.sortArray(item)0
    })
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   console.log(event);
  // }

  CreateNew(city:string){
  }

  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any, name_txt: string, item: LookupModel[], group_txt?: string,item1?: any, property?: any,dbType: number = DbEnum.QE_DEV_SQL_DB) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = 'All';
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }

      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {
            this.lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getAllLookup();
              this.toaster.success('Record Saved Successfully.');
              item1[property]= val;//this.check_value;

            })
          }
          // else {
          //   this.lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toaster.success('Record Saved Successfully.');
          //     item1[property]=this.check_value;
          //     this.getAllLookup();
          //   })
          // }
        }
      })
    }
    item1[property] = val;
  } else {
    item1[property] = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }
}
