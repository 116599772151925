<div class="form col-md-12" *ngIf="isProductModel">
    <div class="row">
        <div class="col-md-4">
            <!-- <label>Selected Product:</label> -->
            <h5 class="float-left"><b>Selected Product:</b></h5>
            <button class="btn btn-primary float-right" (click)="addNewProduct()">Add New</button>
            <br>
            <div class="mt-2">
                <input type="text" class="form-control" placeholder="Search here..." (input)="findProducts($event)">
            </div>
            <div id="content"
                style="height: 666px; width: 100%; margin-top: 5px;overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black; padding: 5px !important;"
                [ngClass]="{'disableDiv': showProductButton}"> <!-- width: 250px; -->
                <div class="titlebox"></div>
                <div class="scroll">
                    <div class="row mx-2">
                        <div class="fixTableHead">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let product of productList" [ngStyle]="{'background-color': setProduct == product.item_id ? '#a9d2fd' : 'white'}">
                                        <td><label style="cursor: pointer;" class="text-center"
                                                (click)="setProductForm(product)">
                                                {{product.code_txt}}
                                            </label></td>
                                        <td><label style="cursor: pointer;" class="text-center"
                                                (click)="setProductForm(product)">
                                                {{product.name_txt}}
                                            </label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label><input type="checkbox" class="mx-2" #showInactiveProductCheckbox id="showInactiveProductCheckbox" (change)="filterProduct(showInactiveProductCheckbox.checked)"
                        [(ngModel)]="showInactiveProduct">Show
                    Inactive</label>
            </div>
        </div>
        <div class="col-md-8 pl-0 pt-2">
            <!-- <label>Product Information:</label> -->
            <h5><b>Product Information:</b></h5>
            <div id="content">
                <div class="titlebox"></div>
                <form [formGroup]="productFormGroup">
                    <div class="row">
                        <div class="col-md-8">
                            <label>Code:</label>
                            <input type="text" class="form-control" formControlName="code_txt"
                                [(ngModel)]="productModel.code_txt">
                        </div>
                        <div class="col-md-4">
                            <label><input type="checkbox" formControlName="active_ind"
                                    [(ngModel)]="productModel.active_ind" class="mx-2">Active</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Name:</label>
                            <input type="text" class="form-control" formControlName="name_txt"
                                [(ngModel)]="productModel.name_txt">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Type:</label>
                            <ng-select [clearable]="true" formControlName="type_id"
                                [items]="product_Type" [(ngModel)]="productModel.type_id"
                                bindLabel="typeName" bindValue="typeId" >
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Description:</label>
                        <textarea class="form-control" formControlName="description_txt"
                            [(ngModel)]="productModel.description_txt" >
                              </textarea>
                        </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                          <label>Manufacturer:</label>
                          <!-- <ng-select [clearable]="true" formControlName="diameter_txt"
                              [items]="diamLookup" [(ngModel)]="ItemContact.diameter_txt"
                              bindLabel="name_txt" bindValue="name_txt" >
                          </ng-select> -->
                          <ng-select [clearable]="true"  formControlName="manufacturer_id"
                            [items]="manufacture"
                            bindLabel="name_txt"
                            bindValue="manufacturer_id"
                            [(ngModel)]="productModel.manufacturer_id">
                        </ng-select>
                      </div>
                  </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Diam:</label>
                            <ng-select [clearable]="true" formControlName="diameter_txt"
                                [items]="diamLookup" [(ngModel)]="ItemContact.diameter_txt"
                                bindLabel="name_txt" bindValue="name_txt" >
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>BC:</label>
                            <ng-select [clearable]="true" formControlName="basecurve_txt"
                                [items]="bcLookup" [(ngModel)]="ItemContact.basecurve_txt"
                                bindLabel="name_txt" bindValue="name_txt" >
                            </ng-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Charge:</label>
                            <input type="text" class="form-control" formControlName="productCharge_amt"
                                [(ngModel)]="productCharge_amt" >
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <span style="font-size: 14px !important;"><b>Product Price Type:</b></span>
                        </div>
                        &nbsp;&nbsp;
                        <!-- <div class="col-md-2" *ngFor="let pt of productType">
                            <div class="form-group input-group-sm">
                                <input type="radio" formContolName="productType" class="m-2" name="productType" [id]="pt.value"
                                    [(ngModel)]="productModel.selected_price_txt" [value]="pt.value" [checked]="productModel.selected_price_txt === pt.value"><span
                                    style="font-size: 12px !important;">{{pt.key}}</span>
                            </div>
                        </div> -->
                        <div class="col-md-2">
                            <div class="form-group input-group-sm">
                                <input type="radio" formContolName="productType" class="m-2" id="RegPrice"
                                    [(ngModel)]="productModel.selected_price_txt" [value]="'Reg. Price'" [checked]="productModel.selected_price_txt == 'Reg. Price'"><span
                                    style="font-size: 12px !important;">Reg. Price</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group-sm">
                                <input type="radio" formContolName="productType" class="m-2" id="SalePrice"
                                    [(ngModel)]="productModel.selected_price_txt" [value]="'Sale. Price'" [checked]="productModel.selected_price_txt == 'Sale. Price'"><span
                                    style="font-size: 12px !important;">Sale. Price</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label><input type="checkbox" formControlName="taxable_ind"
                                [(ngModel)]="productModel.taxable_ind" class="mx-2" > Taxable</label>
                    </div>
                    <div class="pt-2 col-md-12 text-center">
                        <button class="btn btn-primary px-4" (click)="editProduct()" *ngIf="!showProductButton"
                            [disabled]="!enablProductEditButton">Edit</button>
                        <button class="btn btn-primary px-4 mx-2" [disabled]="productFormGroup.invalid"
                            *ngIf="showProductButton" (click)="saveProduct()">Save</button>
                        <button class="btn btn-primary px-4" (click)="cancelProduct()"
                            *ngIf="showProductButton">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<div class="form col-md-12" *ngIf="!isProductModel">
    <div class="row">
        <div class="col-md-4">
            <!-- <label><b>Selected Service:</b></label> -->
            <h5 class="float-left">Selected Service:</h5>
            <button class="btn btn-primary float-right" (click)="addNew()">Add New</button>
            <br>
            <div class="mt-2">
                <input type="text" class="form-control" placeholder="Search here..." (input)="findCharges($event)">
            </div>
            <div id="content"
                style="height: 423px; width: 100%; margin-top: 5px;overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black; padding: 5px !important;"
                [ngClass]="{'disableDiv': showButton}"> <!-- width: 250px; -->
                <div class="titlebox"></div>
                <div class="scroll">
                    <div class="row mx-2">
                        <div class="fixTableHead">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let charge of chargeList">
                                        <td><label style="cursor: pointer;" class="text-center"
                                                (click)="setForm(charge)">
                                                {{charge.code_txt}}
                                            </label></td>
                                        <td><label style="cursor: pointer;" class="text-center"
                                                (click)="setForm(charge)">
                                                {{charge.name_txt}}
                                            </label></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <label><input type="checkbox" class="mx-2" #showInactiveCharges id="showInactiveCharges" (change)="filter(showInactiveCharges.checked)" [(ngModel)]="showInactive">Show
                    Inactive</label>
            </div>
        </div>
        <div class="col-md-8 pl-0 pt-2">
            <!-- <label><b>Service Information:</b></label> -->
            <h5>Service Information:</h5>
            <div id="content">
                <div class="titlebox"></div>
                <form [formGroup]="chargeFormGroup">
                    <div class="row">
                        <div class="col-md-8">
                            <label>Code:</label>
                            <input type="text" class="form-control" formControlName="code_txt"
                                [(ngModel)]="chargeModel.code_txt">
                        </div>
                        <div class="col-md-4">
                            <label><input type="checkbox" formControlName="active_ind"
                                    [(ngModel)]="chargeModel.active_ind" class="mx-2">Active</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Name:</label>
                            <input type="text" class="form-control" formControlName="name_txt"
                                [(ngModel)]="chargeModel.name_txt">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Description:</label>
                            <textarea class="form-control" formControlName="description_txt" [(ngModel)]="chargeModel.description_txt"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <label>Charge:</label>
                            <input type="text" class="form-control" formControlName="charge_amt"
                                [(ngModel)]="chargeModel.charge_amt">
                        </div>
                    </div>
                    <div>
                        <label><input type="checkbox" formControlName="taxable_ind"
                                [(ngModel)]="chargeModel.taxable_ind" class="mx-2">Taxable</label>
                    </div>
                    <div class="pt-2 col-md-12 text-center">
                        <button class="btn btn-primary px-4" (click)="edit()" *ngIf="!showButton"
                            [disabled]="!enablEditButton">Edit</button>
                        <button class="btn btn-primary px-4 mx-2" [disabled]="chargeFormGroup.invalid"
                            *ngIf="showButton" (click)="save()">Save</button>
                        <button class="btn btn-primary px-4" (click)="cancel()" *ngIf="showButton">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
