<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px;">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" (click)="cancelExam()">
                <!-- routerLink="/patient/clinical/exams" [queryParams]="{patientId: patientDetail.patientID}" -->
                <!-- <img src="../../../../assets/img/close.png" style="height: 30px;width: 30px;"> -->
                Cancel Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="saveExam(false)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="saveExam(true)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save & Close
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" [disabled]="examId == ''" [useExistingCss]="true"
                (click)="populatePrintValues()" printSectionId="print-section" ngxPrint>
                <!-- <img src="../../../../assets/img/print_printer.png" style="height: 30px;width: 30px;"><br> -->
                Print
            </button> &nbsp;&nbsp;
            <hr>
        </div>
    </div>
</div>
<div *ngIf="showComponent" class="mt-5 pt-2">
    <div id="content">
        <!-- <ngx-loader [show]="show" [fullScreen] = "fullScreen" [template]="template"> Loading... </ngx-loader> -->
        <!-- <div class="titlebox">Exam</div>
        <br /> -->
        <div class="row pt-5">
            <div class="col-md-1">
                <label><b>Exam Date:</b></label>
            </div>
            <div class="col-md-3">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                    (ngModelChange)="exam.exam_Date = $event" [ngModel]="exam.exam_Date | date:'MM-dd-yyyy'"
                    class="form-control">
            </div>
            <div class="col-md-1">
                <label><b>Exam Office:</b></label>
            </div>
            <div class="col-md-3">
                <div class="form-row">
                    <ng-select class="col-md-10" [items]="office" bindLabel="name_txt" bindValue="office_id"
                        [(ngModel)]="exam.office_ID">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editOffice('General')"><img
                            src="../../../../assets/img/pencil-png.png" height="10px"></a>
                </div>
            </div>
            <div class="col-md-1">
                <label><b>Visit Type:</b></label>
            </div>
            <div class="col-md-3">
                <div class="form-row">
                    <ng-select (keyup)="keyFunc($event,exam,'visit_type')" addTagText="Add New" [addTag]="CreateNew" class="col-md-10"
                        [items]="examVisitTypeLookup"
                        (keydown.tab)="saveDropdown(exam.visit_type,'Visit Type',examVisitTypeLookup,'examvisittype',exam,'visit_type',dbEnum.QE_DEV_SQL_DB)"
                        [(ngModel)]="exam.visit_type" bindLabel="name_txt" bindValue="name_txt">
                    </ng-select>
                    <a href="javascript:void(0)"
                        (click)="editLookup(examVisitTypeLookup,examVisitTypeLookup,dbEnum.QE_DEV_SQL_DB)"><img
                            src="../../../../assets/img/pencil-png.png" height="10px"></a>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-1">
                <label><b>CC:</b></label>
            </div>
            <div class="col-md-11">
                <textarea [(ngModel)]="exam.cheif_Complaint" class="form-control" style="height: 50%;"
                    rows="3"></textarea>
            </div>
        </div>
        <div id="content">
            <div class="titlebox"><b>History of Present Illness</b></div>
            <br />
            <div class="row">
                <div class="col-md-1">
                    <label><b>Location:</b></label>
                </div>
                <div class="col-md-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phLocation')" addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                            [items]="locationLookUp"
                            (keydown.tab)="saveDropdown(exam.phLocation,'Location',locationLookUp,'Location',exam,'phLocation')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phLocation">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(locationLookUp,locationCopyLookUp,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
                <div class="col-md-1">
                    <label><b>Quality:</b></label>
                </div>
                <div class="col-md-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phQuality')" addTagText="Add Item" [addTag]="CreateNew" class="col-md-9"
                            [items]="qualityLookup"
                            (keydown.tab)="saveDropdown(exam.phQuality,'Quality',qualityLookup,'Quality',exam,'phQuality')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phQuality">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(qualityLookup,qualityCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
                <div class="col-md-1">
                    <label><b>Severity:</b></label>
                </div>
                <div class="col-md-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phSeverity')" addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                            [items]="severityLookup"
                            (keydown.tab)="saveDropdown(exam.phSeverity,'Severity',severityLookup,'Severity',exam,'phSeverity')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phSeverity">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(severityLookup,severityCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1">
                    <label><b>Duration:</b></label>
                </div>
                <div class="col-md-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phDuration')" addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                            [items]="durationLookup"
                            (keydown.tab)="saveDropdown(exam.phDuration,'Duration',durationLookup,'Duration',exam,'phDuration')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phDuration">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(durationLookup,durationCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
                <div class="col-md-1">
                    <label><b>Timing:</b></label>
                </div>
                <div class="col-md-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phTiming')" addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                            [items]="timingLookup"
                            (keydown.tab)="saveDropdown(exam.phTiming,'Timing',timingLookup,'Timing',exam,'phTiming')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phTiming">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(timingLookup,timingCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-1">
                    <label><b>Context:</b></label>
                </div>
                <div class="col-md-11">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phContext')" addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                            [items]="contextLookup"
                            (keydown.tab)="saveDropdown(exam.phContext,'Context',contextLookup,'context',exam,'phContext',dbEnum.QE_DEV_SQL_DB)"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phContext">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(contextLookup,contextCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label><b>Modifying Factors:</b></label>
                </div>
                <div class="col-md-10">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phModifyingFactors')" addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                            [items]="factorLookup"
                            (keydown.tab)="saveDropdown(exam.phModifyingFactors,'Modifying Factors',factorLookup,'modifyingfactors',exam,'phModifyingFactors',dbEnum.QE_DEV_SQL_DB)"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phModifyingFactors">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(factorLookup,factorCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label><b>Associated Signs & Symptoms:</b></label>
                </div>
                <div class="col-md-10">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phSignsAndSymptoms')" addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                            [items]="symptomsLook"
                            (keydown.tab)="saveDropdown(exam.phSignsAndSymptoms,'Associated Signs & Symptoms',symptomsLook,'signssymptoms',exam,'phSignsAndSymptoms',dbEnum.QE_DEV_SQL_DB)"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phSignsAndSymptoms">
                        </ng-select>
                        <a href="javascript:void(0)"
                            (click)="editLookup(symptomsLook,symptomsCopyLook,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <label><b>Medical Hx and ROS From:</b></label>
                </div>
                <div class="col-md-2">
                    <input (ngModelChange)="exam.medHXandROS = $event" [ngModel]="exam.medHXandROS | date:'MM-dd-yyyy'"
                        type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                </div>
                <div class="col-md-5">

                    <!-- <div class="col-md-2">
                        <label><input [(ngModel)]="exam.pptOriented" type="checkbox" class="mx-2"> <b>PPT
                                Oriented</b></label>
                    </div> -->
                    <div class="col-md-2">
                        <label><input [(ngModel)]="exam.omR_Exam.pptOriented" type="checkbox" class="mx-2"> <b>PPT
                                Oriented</b></label>
                    </div>
                </div>
                <br>
                <div class="row" style="margin-left:0px; width: 100%;">
                    <div class="col-md-1">
                        <label><b>Head/Face:</b></label>
                    </div>
                    <div class="col-md-1">
                        <input class="mx-1" [(ngModel)]="exam.omR_Exam.headFaceNormal" type="checkbox">
                        <label><b>Normal</b></label>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-2">
                                <input [(ngModel)]="exam.omR_Exam.eomNormal" autocomplete="off" type="checkbox">
                                <label><b>Other:</b></label>
                            </div>

                            <div class="col-md-10">
                                <div class="form-row">
                                    <!-- <div class="col-md-9"> -->
                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'headFaceOther')" addTagText="Add New" [addTag]="CreateNew"
                                        class="col-md-8" [items]="headFace"
                                        (keydown.tab)="saveDropdown(exam.omR_Exam.headFaceOther,'Other',headFace,'HeadFace',exam.omR_Exam,'headFaceOther')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="exam.omR_Exam.headFaceOther">
                                    </ng-select>
                                    <!-- </div>
                                    <div class="col-md-1"> -->
                                    <a href="javascript:void(0)"
                                        (click)="editLookup(headFace,headFaceCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-2"></div> -->
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-4">
                                <label><b>Psych:Mood/Affect:</b></label>
                            </div>
                            <div class="col-md-8">
                                <div class="form-row">
                                    <ng-select (keyup)="keyFunc($event,exam,'psychMood')" addTagText="Add New" [addTag]="CreateNew"
                                        class="col-md-9" [items]="psychLookup"
                                        (keydown.tab)="saveDropdown(exam.psychMood,'Psych:Mood/Affect',psychLookup,'PhychMoodAffect',exam,'psychMood')"
                                        bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.psychMood">
                                    </ng-select>
                                    <a href="javascript:void(0)"
                                        (click)="editLookup(psychLookup,psychLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-md-4" style="padding-right: 0;">
                    <div id="content" style="height: 208px !important;padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>Unaided VA's</b></div>
                        <div class="row col-12" style="padding: 0;margin: 0;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width: 14%;"></td>
                                    <td style="text-align:center;"><b>Far</b></td>
                                    <td></td>
                                    <td style="text-align:center;"><b>Near</b></td>
                                </tr>
                                <tr>
                                    <td style="width: 14%;">
                                        <label><b>OD/20</b></label>
                                    </td>
                                    <td>
                                        <div class="col-md-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedODFar')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedODFar,'OD/20',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedODFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedODFar">
                                                </ng-select>
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <label><b>20/</b></label>
                                    </td>
                                    <td>
                                        <div class="col-md-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedODNear')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedODNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedODNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedODNear">
                                                </ng-select>
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 14%;">
                                        <label><b>OS/20</b></label>
                                    </td>
                                    <td>
                                        <div class="col-md-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedOSFar')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedOSFar,'OS/20',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedOSFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedOSFar">
                                                </ng-select>
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <label><b>20/</b></label>
                                    </td>
                                    <td>
                                        <div class="col-md-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedOSNear')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedOSNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedOSNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedOSNear">
                                                </ng-select>
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 14%;">
                                        <label><b>OU/20</b></label>
                                    </td>
                                    <td>
                                        <div class="col-md-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'vaouFar')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.vaouFar,'OU/20',farNearLookup,'VisualAcuities',exam.omR_Exam,'vaouFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.vaouFar">
                                                </ng-select>
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <label><b>20/</b> </label>
                                    </td>
                                    <td>
                                        <div class="col-md-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'vaouNear')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.vaouNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'vaouNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.vaouNear">
                                                </ng-select>
                                                <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <br>
                    </div>
                </div>
                <div class="col-md-6">
                    <div id="content" style="height:208px !important;padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>Current Rx</b></div>
                        <br>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label><b>Date:</b></label>
                                    </div>
                                    <div class="col-md-10">
                                        <input (ngModelChange)="exam.omR_Exam.curRXDate = $event"
                                            [ngModel]="exam.omR_Exam.curRXDate | date:'MM-dd-yyyy'" type="text"
                                            [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label><b>OD:</b></label>
                                    </div>
                                    <div class="col-md-10">
                                        <input [(ngModel)]="exam.omR_Exam.curRXODSphere" type="text"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label><b>OS:</b></label>
                                    </div>
                                    <div class="col-md-10">
                                        <input [(ngModel)]="exam.omR_Exam.curRXOSSphere" type="text"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <label><b>Add:</b></label>
                                    </div>
                                    <div class="col-md-10">
                                        <input [(ngModel)]="exam.omR_Exam.curRXAdd" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7" style="padding-left: 5px;">
                                <table style="width:100%">
                                    <tr>
                                        <td style="width: 14%;"></td>
                                        <td style="text-align:center;"><b>Far</b></td>
                                        <td></td>
                                        <td style="text-align:center;"><b>Near</b></td>
                                    </tr>
                                    <tr>
                                        <td style="width: 14%;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <div class="col-md-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXODFar')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXODFar,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXODFar')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXODFar">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                        (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <div class="col-md-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXODNear')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXODNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXODNear')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXODNear">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                        (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 14%;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <div class="col-md-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXOSFar')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOSFar,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOSFar')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOSFar">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                        (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <div class="col-md-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXOSNear')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOSNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOSNear')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOSNear">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                        (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 14%;">
                                            <label><b>OU 20/</b></label>
                                        </td>
                                        <td>
                                            <div class="col-md-12 p-0">
                                                <div class="form-row">
                                                    <ng-select class="auto-grow" (keyup)="keyFunc($event,exam.omR_Exam,'curRXOUFar')"
                                                        addTagText="Add New" [addTag]="CreateNew" class="col-md-10"
                                                        [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOUFar,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOUFar')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOUFar">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                        (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <div class="col-md-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXOUNear')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-md-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOUNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOUNear')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOUNear">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important;"
                                                        (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-2">
                    <div id="content"
                        style="padding-left:2px !important; padding-right: 2px !important;height: 208px !important;">
                        <div class="titlebox"><b>Accomodation</b></div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row">
                                <div class="col-md-4">
                                    <label><b>Amp</b></label>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'amp')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-md-8" style="border-radius: 10px;"
                                            [items]="ampLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.amp,'Amp',ampLookup,'amp',exam.omR_Exam,'amp')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.amp"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>

                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(ampLookup,ampLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                <div class="col-md-2 pl-0">
                    <div id="content"
                        style="padding-left:2px !important; padding-right: 2px !important;height: 208px !important;">
                        <div class="titlebox"><b>Accomodation</b></div>
                        <div class="col-md-12 mt-5 pl-1 pr-1">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <label><b>Amp</b></label>
                                </div>
                                <div class="col-md-9 pl-0 pr-0">
                                    <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'amp')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-md-10" style="border-radius: 10px;"
                                            [items]="ampLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.amp,'Amp',ampLookup,'amp',exam.omR_Exam,'amp')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.amp"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>

                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(ampLookup,ampLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pl-1 pr-1">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <label><b>PRA</b></label>
                                </div>
                                <div class="col-md-9 pl-0 pr-0">
                                    <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'pra')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-md-10" style="border-radius: 10px;"
                                            [items]="praLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.pra,'PRA',praLookup,'pra',exam.omR_Exam,'pra')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.pra"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(praLookup,praLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pl-1 pr-1">
                            <div class="form-row">
                                <div class="col-md-3">
                                    <label><b>NRA</b></label>
                                </div>
                                <div class="col-md-9 pl-0 pr-0">
                                    <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nra')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-md-10" style="border-radius: 10px;"
                                            [items]="nraLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nra,'NRA',nraLookup,'nra',exam.omR_Exam,'nra')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.nra"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(nraLookup,nraLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <diV class="row pt-4 m-1">
                <div id="content" class="mr-3" style="width: 18.42%;">
                    <div class="titlebox"><b>Pupils</b></div>
                    <br />
                    <label><input [(ngModel)]="exam.omR_Exam.pupilsPERRLA" type="checkbox"
                            class="mx-2"><b>PERRLA</b></label>
                    <div class="form-row">
                        <div class="col-md-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <div class="col-md-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'pupils')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-md-12" [items]="pupils"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.pupils,'Pupils',pupils,'Pupils',exam.omR_Exam,'pupils')"
                                bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.pupils">
                            </ng-select>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)"
                                (click)="editLookup(pupils,pupilsCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </div>
                <div id="content" class="mr-3" style="width: 18.42%;">
                    <div class="titlebox"><b>Cover Test</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.coverOrtho_DNN"
                            class="mx-2"><b>Ortho@D&N</b></label>
                    <div class="form-row">
                        <div class="col-md-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <div class="col-md-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'coverOther')" class="col-md-12" [items]="coverTest"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.coverOther,'Cover Test',coverTest,'CoverTest',exam.omR_Exam,'coverOther')"
                                bindLabel="name_txt" bindValue="name_txt" autoClearSearch="true"
                                [(ngModel)]="exam.omR_Exam.coverOther">
                            </ng-select>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)"
                                (click)="editLookup(coverTest,coverTestCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </div>
                <div id="content" class="mr-3" style="width: 18.42%;">
                    <div class="titlebox"><b>NPC</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.npcttn" class="mx-2"><b>TTN</b></label>
                    <div class="form-row">
                        <div class="col-md-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <div class="col-md-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'npcOther')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-md-12" [items]="npcLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.npcOther,'NPC',npcLookup,'NPC',exam.omR_Exam,'npcOther')"
                                bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.npcOther">
                            </ng-select>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)"
                                (click)="editLookup(npcLookup,npcCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </div>
                <div id="content" class="mr-3" style="width: 18.42%;">
                    <div class="titlebox"><b>EOM</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.eomNormal"
                            class="mx-2"><b>Normal</b></label>
                    <div class="form-row">
                        <div class="col-md-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <div class="col-md-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'eomOther')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-md-12" [items]="eomLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.eomOther,'EOM',eomLookup,'EOM',exam.omR_Exam,'eomOther')"
                                bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.eomOther">
                            </ng-select>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)"
                                (click)="editLookup(eomLookup,eomLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </div>
                <div id="content" class="mr-3" style="width: 18.42%;">
                    <div class="titlebox"><b>Confrontations</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.fieldScreenConfrontationWNL"
                            class="mx-2"><b>Normal</b></label>
                    <div class="form-row">
                        <div class="col-md-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <div class="col-md-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'fieldScreenConfrontationOther')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-md-12" [items]="confrontationsLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.fieldScreenConfrontationOther,'Confrontations',confrontationsLookup,'Confrontations',exam.omR_Exam,'fieldScreenConfrontationOther')"
                                bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="exam.omR_Exam.fieldScreenConfrontationOther">
                            </ng-select>
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)"
                                (click)="editLookup(confrontationsLookup,confrontationsCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                </div>
            </diV>
            <div class="row pt-4">
                <div class="col-md-4">
                    <div id="content" style="padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>RET or AR</b></div>
                        <div class="row col-12" style="margin: 0;padding-left: 0px;padding-right: 5px;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:10%">
                                        <label><b>OD</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <input type="text" [(ngModel)]="exam.omR_Exam.retarodSphere"
                                            class="form-control">
                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'retarodToggle')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.retarodToggle,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'retarodToggle')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.retarodToggle">
                                        </ng-select>
                                    </td>
                                    <td style="width:10%">
                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%">
                                        <label><b>OS</b></label>
                                    </td>
                                    <td style="width:40%">
                                        <input [(ngModel)]="exam.omR_Exam.retarosSphere" type="text"
                                            class="form-control">
                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:30%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'retarosToggle')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.retarosToggle,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'retarosToggle')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.retarosToggle">
                                        </ng-select>
                                    </td>
                                    <td style="width:10%">
                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%">

                                    </td>
                                    <td style="width:40%">

                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>OU 20/</b></label>
                                    </td>
                                    <td style="width:20%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'retarouva')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.retarouva,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'retarouva')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.retarouva">
                                        </ng-select>
                                    </td>
                                    <td style="width:10%">
                                        <a href="javascript:void(0)" style="font-size: 16px !important;"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" style="padding-left: 0;padding-right: 0;">
                    <div id="content" style="padding-left: 10px !important;padding-right: 0;">
                        <div class="titlebox"><b>Dist Subj</b></div>
                        <div class="row col-12" style="padding: 0; margin-left: -7px;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:5%">
                                        <label><b>OD</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <input [(ngModel)]="exam.omR_Exam.distSubjectODSphere" (input)="setValue()"
                                            type="text" class="form-control col-md-12" style="padding: 5px;">
                                    </td>
                                    <td style="width:5%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:40%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'distSubjectOD20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.distSubjectOD20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'distSubjectOD20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.distSubjectOD20">
                                        </ng-select>
                                    </td>
                                    <td style="width:10%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:5%">
                                        <label><b>OS</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <input type="text" [(ngModel)]="exam.omR_Exam.distSubjectOSSphere"
                                            (input)="setValue()" class="form-control" style="padding: 5px;">
                                    </td>
                                    <td style="width:5%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:30%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'distSubjectOS20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.distSubjectOS20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'distSubjectOS20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.distSubjectOS20">
                                        </ng-select>
                                    </td>
                                    <td style="width:5%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%">

                                    </td>
                                    <td style="width:40%">
                                        <div class="col-md-8">
                                            <button class="btn btn-primary" style="margin-left:-15px ;"
                                                (click)="copyDist()"><b>Copy Rx</b></button>
                                        </div>
                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>OU 20/</b></label>
                                    </td>
                                    <td style="width:20%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'distSubjectOU20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.distSubjectOU20,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'distSubjectOU20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.distSubjectOU20">
                                        </ng-select>
                                    </td>
                                    <td style="width:10%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">

                    <div id="content" style="padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>Near Subj</b></div>
                        <div class="row col-12" style="padding: 0;margin: 0px;">
                            <table style="width: 100%">
                                <tr>
                                    <td style="width: 7%">
                                        <label><b>OD</b></label>
                                    </td>
                                    <td style="width: 38%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOD')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12 p-0" [items]="NearSubjLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOD,'OD',NearSubjLookup,'nearsubj',exam.omR_Exam,'nearSubjectOD',dbEnum.QE_DEV_SQL_DB)"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOD">
                                        </ng-select>
                                    </td>
                                    <td style="width: 7%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(NearSubjLookup,NearSubjLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                    <td style="width: 5%">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width: 35%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOD20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12 p-0" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOD20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'nearSubjectOD20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOD20">
                                        </ng-select>
                                    </td>
                                    <td style="width: 5%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 7%">
                                        <label><b>OS</b></label>
                                    </td>
                                    <td style="width: 38%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOS')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12 p-0" [items]="NearSubjLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOS,'OS',NearSubjLookup,'NearSubj',exam.omR_Exam,'nearSubjectOS',dbEnum.QE_DEV_SQL_DB)"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOS">
                                        </ng-select>
                                    </td>
                                    <td style="width: 7%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(NearSubjLookup,NearSubjLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                    <td style="width: 5%">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width: 35%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOS20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12 p-0" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOS20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'nearSubjectOS20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOS20">
                                        </ng-select>
                                    </td>
                                    <td style="width: 5%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 7%">

                                    </td>
                                    <td style="width: 38%">
                                        <button class="btn btn-primary" (click)="copyNear()"><b>Copy Rx</b></button>
                                    </td>
                                    <td style="width: 7%">

                                    </td>
                                    <td style="width: 5%">
                                        <label><b>OU 20/</b></label>
                                    </td>
                                    <td style="width: 35%">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOU20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-md-12 p-0" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOU20,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'nearSubjectOU20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOU20">
                                        </ng-select>
                                    </td>
                                    <td style="width: 5%">
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="content pt-5"
                style="border:solid 1px black !important; padding-top: 0px !important;"> <!--padding-left: 25px !important;padding-right: 25px !important;-->
                <div class="row">
                    <div class="col-md-3 p-1">
                        <div class="text-center">
                            <b class="px-4">External(Biomicroscopy)</b>
                        </div>
                        <div class="col-12 text-center">
                            <label class="justify-content-center"><b>Angle Est(Grade)</b></label>
                        </div>

                        <div>
                            <div class="form-row">
                                <div class="col-md-3">

                                </div>
                                <div class="col-md-1">
                                    <label><b>OD</b></label>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'angleEstOD')" addTagText="Add New"
                                            [addTag]="CreateNew" class="col-md-10" [items]="angleEstLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.angleEstOD,'OD',angleEstLookup,'AngleEst',exam.omR_Exam,'angleEstOD')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.angleEstOD">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(angleEstLookup,angleEstCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="form-row">
                                <div class="col-md-3">

                                </div>
                                <div class="col-md-1">
                                    <label><b>OS</b></label>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'angleEstOS')" addTagText="Add New"
                                            [addTag]="CreateNew" class="col-md-10" [items]="angleEstLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.angleEstOS,'OS',angleEstLookup,'AngleEst',exam.omR_Exam,'angleEstOS')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.angleEstOS">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(angleEstLookup,angleEstCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="form-row">
                                <div class="col-md-3">

                                </div>
                                <div class="col-md-6">
                                    <table class="">
                                        <tr>
                                            <th><b style="margin-right:15px;">OD</b></th>
                                            <th class="mx-2"><b></b></th>
                                            <th class="mx-5 text-center"><b>OS</b></th>
                                        </tr>
                                        <tbody>

                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.tearsOD" type="checkbox"></td>
                                                <td class="text-center px-3"><label><b>Tears</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.tearsOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.lidsLashesOD" type="checkbox">
                                                </td>
                                                <td class="text-center px-2"><label><b>Lids/Lashes</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.lidsLashesOS"
                                                        type="checkbox">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.corneaOD" type="checkbox"></td>
                                                <td class="text-center px-2"><label><b>Cornea</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.corneaOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.conjOD" type="checkbox"></td>
                                                <td class="text-center px-2"><label><b>Conj</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.conjOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.scleraOD" type="checkbox"></td>
                                                <td class="text-center px-2"><label><b>Sclera</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.scleraOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.antChmbOD" type="checkbox"></td>
                                                <td class="text-center px-2"><label><b>AntChmb</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.antChmbOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.lensOD" type="checkbox"></td>
                                                <td class="text-center px-2"><label><b>Lens</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.lensOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.irisOD" type="checkbox"></td>
                                                <td class="text-center px-2"><label><b>Iris</b></label></td>
                                                <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.irisOS"
                                                        type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><small><b style="font-size: 10px;">*Checked is Normal</b></small>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-md-3">

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center" style="margin-left: 20px;">
                                <label><b>OD Notes</b></label>
                                <textarea type="text" [(ngModel)]="exam.omR_Exam.externalBiomicroscopyDesc"
                                    class="form-control" rows="4" cols="50"></textarea>
                                <br>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 p-1">
                        <div class="row">
                            <div class="col-md-3">
                                <label><b>Ext.OD</b></label>
                                <textarea [(ngModel)]="exam.omR_Exam.odEyeDisc" class="form-control"
                                    rows="5"></textarea>
                            </div>
                            <div class="col-md-6 mt-3 pt-2 text-center eyeDiv">
                                <app-canvas [width]="260" (onDraw)="setEXTImage($event)" [height]="135"
                                    [imageSRC]="imageEXTSRC" [imageHeight]="135" [imageWidth]="260"
                                    [defaultImage]="defaultImageEXTSRC">
                                </app-canvas>
                            </div>
                            <!-- <div class="col-6 pt-4 text-center eyeDiv">
                                <app-canvas [width]="290" (onDraw)="setEXTImage($event)" [height]="115"
                                    [imageSRC]="imageEXTSRC" [imageHeight]="115" [imageWidth]="288"
                                    [defaultImage]="defaultImageEXTSRC">
                                </app-canvas>
                            </div> -->
                            <!-- <app-canvas [width]="300" (onDraw)="setEXTImage($event)" [height]="130"
                                    [imageSRC]="imageEXTSRC" [imageHeight]="130" [imageWidth]="300"
                                    [defaultImage]="defaultImageEXTSRC">
                                </app-canvas> -->
                            <div class="col-md-3">
                                <label><b>Ext.OS</b></label>
                                <textarea [(ngModel)]="exam.omR_Exam.osEyeDisc" class="form-control"
                                    rows="5"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <label><b>Int.OD</b></label>
                                <textarea [(ngModel)]="exam.omR_Exam.odEyeDisc2" class="form-control"
                                    rows="5"></textarea>
                            </div>
                            <div class="col-md-6 mt-3 pt-2 text-center eyeDiv">
                                <app-canvas [width]="260" (onDraw)="setINTImage($event)" [height]="135"
                                    [imageSRC]="imageINTSRC" [imageHeight]="135" [imageWidth]="260"
                                    [defaultImage]="defaultImageINTSRC">
                                </app-canvas>
                            </div>
                            <!-- <div class="col-md-6 mt-4 pt-3 text-center eyeDiv">
                                <app-canvas [width]="290" (onDraw)="setINTImage($event)" [height]="115"
                                    [imageSRC]="imageINTSRC" [imageHeight]="115" [imageWidth]="288"
                                    [defaultImage]="defaultImageINTSRC">
                                </app-canvas>
                            </div> -->
                            <!-- <app-canvas [width]="300" (onDraw)="setINTImage($event)" [height]="130"
                                    [imageSRC]="imageINTSRC" [imageHeight]="130" [imageWidth]="300"
                                    [defaultImage]="defaultImageINTSRC">
                                </app-canvas> -->
                            <div class="col-md-3">
                                <label><b>Int.OS</b></label>
                                <textarea [(ngModel)]="exam.omR_Exam.osEyeDisc2" class="form-control"
                                    rows="5"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <b>Gonioscopy</b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 text-center">
                                <div class="row">
                                    <div class="col-md-6">

                                    </div>
                                    <div class="col-md-6">
                                        <b>OD</b>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 text-center">
                                <div class="row">
                                    <div class="col-md-6">
                                        <b>OS</b>
                                    </div>
                                    <div class="col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-row">
                                    <div class="col-md-6">

                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioODTop"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/forwardslash.png"
                                            style="width: 100% !important;" />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">

                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: 0px !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioODLeft"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/crossslash.png" style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: 0px !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioODRight"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-6">

                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/forwardslash.png"
                                            style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioODBottom"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-row">
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioOSTop"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/forwardslash.png"
                                            style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-6">

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: auto !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioOSLeft"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/crossslash.png" style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: auto !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioOSRight"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-6">

                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/forwardslash.png"
                                            style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-2 text-center"
                                        style="padding: 0px !important; margin: auto !important;">
                                        <textarea [(ngModel)]="exam.omR_Exam.gonioOSBottom"
                                            style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                    </div>
                                    <div class="col-md-2" style="padding: 0px !important; margin: 0px !important;">
                                        <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                    </div>
                                    <div class="col-md-6">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mx-3">
                            <b>Internal(Ophthalmoscopy)</b>
                        </div>
                        <div class="col-12 text-center">
                            <label class="justify-content-center"><b></b></label>
                        </div>

                        <div class=" col-12 mx-3 pl-0 pr-0 text-center">
                            <div class="form-row" style="margin-bottom:10px !important;">
                                <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'internalOphtmalmoscopy')" addTagText="Add New"
                                    [addTag]="CreateNew" class="col-md-10" [items]="internalLookup"
                                    (keydown.tab)="saveDropdown(exam.omR_Exam.internalOphtmalmoscopy,'Internal(Ophthalmoscopy)',internalLookup,'io',exam.omR_Exam,'internalOphtmalmoscopy')"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="exam.omR_Exam.internalOphtmalmoscopy">
                                </ng-select>
                                <a href="javascript:void(0)"
                                    (click)="editLookup(internalLookup,internalCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="col-md-1">

                            </div>
                            <div class="col-md-10">
                                <table class="" style="width: 100%;">
                                    <tr>
                                        <th class="text-center"><b style="margin-left: -5px;">OD</b></th>
                                        <th class="px-2"><b></b></th>
                                        <th><b style="margin-left:10px;">OS</b></th>
                                    </tr>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="form-row p-0"
                                                    style="width: 120px;margin-left: -22px;margin-right: -40px;">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'cdod')"
                                                        addTagText="Add New" [addTag]="CreateNew"
                                                        style="font-size: 12px !important" class="col-md-10 p-0"
                                                        [items]="internalODOS"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.cdod,'OD',internalODOS,'internalodos',exam.omR_Exam,'cdod')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.cdod">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important; padding: 2px;"
                                                        (click)="editLookup(internalLookup,internalODOSCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </td>
                                            <th class="text-center"><label><b>C/D</b></label></th>
                                            <td>
                                                <div class="form-row p-0" style="width: 120px;margin-left: -25px;">
                                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'cdos')"
                                                        addTagText="Add New" [addTag]="CreateNew"
                                                        style="font-size: 12px !important" class="col-md-10 p-0"
                                                        [items]="internalODOS"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.cdos,'OS',internalODOS,'internalodos',exam.omR_Exam,'cdos')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.cdos">
                                                    </ng-select>
                                                    <a href="javascript:void(0)" style="font-size: 16px !important; padding: 2px;"
                                                        (click)="editLookup(internalLookup,internalODOSCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                            src="../../../../assets/img/pencil-png.png"
                                                            height="10px"></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.discMarginsOD"></td>
                                            <td class="text-center px-2"><label><b>Disc Margins</b></label></td>
                                            <td><input class="mx-4" type="checkbox"
                                                    [(ngModel)]="exam.omR_Exam.discMarginsOS"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.maculaOD">
                                            </td>
                                            <td class="text-center px-2"><label><b>Macula</b></label></td>
                                            <td> <input class="mx-4" type="checkbox"
                                                    [(ngModel)]="exam.omR_Exam.maculaOS">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.vesselsOD"></td>
                                            <td class="text-center px-2"><label><b>Vessels</b></label></td>
                                            <td><input class="mx-4" type="checkbox"
                                                    [(ngModel)]="exam.omR_Exam.vesselsOS"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.nflod"></td>
                                            <td class="text-center px-2"><label><b>NFL</b></label></td>
                                            <td><input class="mx-4" type="checkbox" [(ngModel)]="exam.omR_Exam.nflos">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.vitreousOD"></td>
                                            <td class="text-center px-2"><label><b>Vitreous</b></label></td>
                                            <td><input class="mx-4" type="checkbox"
                                                    [(ngModel)]="exam.omR_Exam.vitreousOS"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.periphRetOD"></td>
                                            <td class="text-center px-2"><label><b>Periph Ret</b></label></td>
                                            <td><input class="mx-4" type="checkbox"
                                                    [(ngModel)]="exam.omR_Exam.periphRetOS"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td class="text-center px-2"><small><b style="font-size: 10px;">*Checked is Normal</b></small>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="col-md-2">

                            </div> -->
                        </div><br>
                        <div class="row">
                            <div class="col-md-12 text-center pt-5"  style="margin-left: -10px;">
                                <label><b>OS Notes</b></label>
                                <textarea type="text" [(ngModel)]="exam.omR_Exam.internalOphtmalmoscopyDesc"
                                    class="form-control" rows="4" cols="50"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div class="row py-4">
                            <div class="col-md-10">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div id="content" style="padding-left: 5px !important;padding-right: 5px;">
                                            <div class="titlebox"><b>Tonometry</b></div>
                                            <div class="row col-12 m-0 p-0">
                                                <div class="col-md-4">
                                                    <div class="form-row">
                                                        <div class="col-md-4 p-0">
                                                            <label><b>Time</b></label>
                                                        </div>
                                                        <div class="col-md-8">
                                                            <input type="text" [(ngModel)]="exam.omR_Exam.tonometryTime"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 pr-0">
                                                    <div class="form-row">
                                                        <div class="col-md-2">
                                                            <label><b>OD</b></label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tonometryOD')"
                                                                (keypress)="numberOnly($event)" addTagText="Add New"
                                                                [addTag]="CreateNew" class="auto-grow"
                                                                class="col-md-12 p-1" ng-maxlength="15"
                                                                style="border-radius: 10px;" [items]="tonometryosod"
                                                                (keydown.tab)="saveDropdown(exam.omR_Exam.tonometryOD,'OD',tonometryosod,'tonometryosod',exam.omR_Exam,'tonometryOD',dbEnum.QE_DEV_SQL_DB)"
                                                                bindLabel="name_txt" bindValue="name_txt"
                                                                [(ngModel)]="exam.omR_Exam.tonometryOD"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <a href="javascript:void(0)"
                                                                style="font-size: 16px !important;"
                                                                (click)="editLookup(tonometryosod,tonometryosodCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                    src="../../../../assets/img/pencil-png.png"
                                                                    height="10px"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4" style="padding-right: 8px;">
                                                    <div class="form-row">
                                                        <div class="col-md-2">
                                                            <label><b>OS</b></label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tonometryOS')"
                                                                addTagText="Add New" [addTag]="CreateNew"
                                                                (keypress)="numberOnly($event)" class="auto-grow"
                                                                class="col-md-12 p-1" style="border-radius: 10px;"
                                                                [items]="tonometryosod"
                                                                (keydown.tab)="saveDropdown(exam.omR_Exam.tonometryOS,'OS',tonometryosod,'tonometryosod',exam.omR_Exam,'tonometryOS',dbEnum.QE_DEV_SQL_DB)"
                                                                bindLabel="name_txt" bindValue="name_txt"
                                                                [(ngModel)]="exam.omR_Exam.tonometryOS"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <a href="javascript:void(0)"
                                                                style="font-size: 16px !important;"
                                                                (click)="editLookup(tonometryosod,tonometryosodCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                    src="../../../../assets/img/pencil-png.png"
                                                                    height="10px"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12" style="padding-left: 10px;">
                                                <div class="form-row">
                                                    <div class="col-md-1">
                                                        <label><b>Test</b></label>
                                                    </div>
                                                    <div class="col-md-10" style="padding-left: 20px;">
                                                        <div class="form-row">
                                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tonometry')"
                                                                addTagText="Add New" [addTag]="CreateNew"
                                                                class="col-md-10" [items]="tonometry"
                                                                (keydown.tab)="saveDropdown(exam.omR_Exam.tonometry,'Test',tonometry,'Tonometry',exam.omR_Exam,'tonometry')"
                                                                bindLabel="name_txt" bindValue="name_txt"
                                                                [(ngModel)]="exam.omR_Exam.tonometry">
                                                            </ng-select>
                                                            <a href="javascript:void(0)"
                                                                (click)="editLookup(tonometry,tonometryCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                    src="../../../../assets/img/pencil-png.png"
                                                                    height="10px"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div id="content">
                                            <div class="titlebox"><b>KER</b></div>
                                            <br>
                                            <div class="row">
                                                <div class="col-md-1">
                                                    <label><b>OD</b></label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD1" type="text"
                                                        class="form-control">
                                                </div>
                                                <div class="col-md-1">
                                                    <label><b>@</b></label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD1Axis" type="text"
                                                        class="form-control">
                                                </div>
                                                <div class="col-md-1">
                                                    <label>
                                                        <b>
                                                            <>
                                                        </b>
                                                    </label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD2" type="text"
                                                        class="form-control">
                                                </div>
                                                <div class="col-md-1">
                                                    <label><b>@</b></label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD2Axis" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-1">
                                                    <label><b>OS</b></label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoS1" type="text"
                                                        class="form-control">
                                                </div>
                                                <div class="col-md-1">
                                                    <label><b>@</b></label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoS1Axis" type="text"
                                                        class="form-control">
                                                </div>
                                                <div class="col-md-1">
                                                    <label>
                                                        <b>
                                                            <>
                                                        </b>
                                                    </label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoS2" type="text"
                                                        class="form-control">
                                                </div>
                                                <div class="col-md-1">
                                                    <label><b>@</b></label>
                                                </div>
                                                <div class="col-md-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoS2Axis" type="text"
                                                        class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 pt-4 p-0">
                                    <div id="content">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="titlebox"></div>
                                                <b>Assessment:</b>
                                                <button class="btn btn-primary mx-2 px-4"
                                                    (click)="addAssessment()"><b>Add</b></button>
                                            </div>
                                            <div class="col-4"> <b>Plan:</b> </div>
                                        </div>
                                        <div class="row pt-2"
                                            *ngFor="let assesment of exam.exam_Assessments;let i = index;">
                                            <div class="col-md-6 pt-2">
                                                <div class="form-row">
                                                    <div class="col-md-1">
                                                        <label class="pt-1"><b>#</b>{{i+1}}</label>
                                                    </div>
                                                    <div class="col-md-11">
                                                        <div class="form-row">
                                                            <div class="col-md-10">
                                                                <div class="form-row">
                                                                    <ng-select [clearable]="true"
                                                                        (keyup)="keyFunc($event,assesment,'assessment')" addTagText="Add New"
                                                                        [addTag]="CreateNew" class="col-md-10"
                                                                        [items]="assessment"
                                                                        (keydown.tab)="saveDropdown(assesment.assessment,'#',assessment,'assessment',assesment,'assessment',dbEnum.QE_DEV_SQL_DB)"
                                                                        bindLabel="name_txt" bindValue="name_txt"
                                                                        [(ngModel)]="assesment.assessment">
                                                                    </ng-select>
                                                                    <a href="javascript:void(0)" class="col-md-2"
                                                                        (click)="editLookup(assessment,assessmentCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                            src="../../../../assets/img/pencil-png.png"
                                                                            height="10px"></a>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button class="btn btn-primary col-md-12"
                                                                    (click)="addChildAssessment(assesment)"><b>Add</b></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="pt-2"
                                                    *ngFor="let assesmentPlan of assesment.exam_Assessment_Plans;">
                                                    <div class="form-row">
                                                        <div class="col-md-1">
                                                            <label class="pt-1"><b>#</b>{{i+1}}</label>
                                                        </div>
                                                        <div class="col-md-11">
                                                            <div class="form-row col-md-12">
                                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,assesmentPlan,'assessment_Plan')"
                                                                    addTagText="Add New" [addTag]="CreateNew"
                                                                    class="col-md-9" [items]="plan"
                                                                    (keydown.tab)="saveDropdown(assesmentPlan.assessment_Plan,'plan',plan,'plan',assesmentPlan,'assessment_Plan',dbEnum.QE_DEV_SQL_DB)"
                                                                    bindLabel="name_txt" bindValue="name_txt"
                                                                    [(ngModel)]="assesmentPlan.assessment_Plan">
                                                                </ng-select>
                                                                <a href="javascript:void(0)"
                                                                    (click)="editLookup(plan,planCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                        src="../../../../assets/img/pencil-png.png"
                                                                        height="10px"></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <label><b>Other Assessment:</b></label>
                                                <textarea [(ngModel)]="exam.omR_Exam.otherAssessment"
                                                    class="form-control"></textarea>
                                            </div>
                                            <div class="col-md-6">
                                                <label><b>Other Plan:</b></label>
                                                <textarea [(ngModel)]="exam.omR_Exam.otherPlan"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div id="content" style="padding-left: 5px !important;padding-right: 5px;">
                                    <div class="titlebox"><b>DPA</b></div>
                                    <div class="col-12 p-0">
                                        <table style="width: 100%">
                                            <tr>
                                                <td style="width: 20%">
                                                    <label><b>Time</b></label>
                                                </td>
                                                <td>
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.dpaTime"
                                                        class="form-control">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 20%">
                                                    <label><b>Tropic</b></label>
                                                </td>
                                                <td>
                                                    <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tropic')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-10"
                                                            [items]="tropicLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.tropic,'Tropic',tropicLookup,'Tropic',exam.omR_Exam,'tropic')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.tropic">
                                                        </ng-select>
                                                        <a href="javascript:void(0)"
                                                            (click)="editLookup(tropicLookup,tropicLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                src="../../../../assets/img/pencil-png.png"
                                                                height="10px"></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 20%">
                                                    <label><b>Phenyl</b></label>
                                                </td>
                                                <td>
                                                    <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'phenyl')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-10"
                                                            [items]="phenylLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.phenyl,'Phenyl',phenylLookup,'Phenyl',exam.omR_Exam,'phenyl')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.phenyl">
                                                        </ng-select>
                                                        <a href="javascript:void(0)"
                                                            (click)="editLookup(phenylLookup,phenylLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                src="../../../../assets/img/pencil-png.png"
                                                                height="10px"></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 20%">
                                                    <label><b>Pared</b></label>
                                                </td>
                                                <td>
                                                    <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'pared')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-10"
                                                            [items]="ParedLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.pared,'Pared',ParedLookup,'Pared',exam.omR_Exam,'pared')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.pared">
                                                        </ng-select>
                                                        <a href="javascript:void(0)"
                                                            (click)="editLookup(ParedLookup,ParedLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                src="../../../../assets/img/pencil-png.png"
                                                                height="10px"></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="width: 20%">
                                                    <label><b>Cyclo</b></label>
                                                </td>
                                                <td>
                                                    <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'cyclo')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-10"
                                                            [items]="cycloLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.cyclo,'Cyclo',cycloLookup,'Cyclo',exam.omR_Exam,'cyclo')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.cyclo">
                                                        </ng-select>
                                                        <a href="javascript:void(0)"
                                                            (click)="editLookup(cycloLookup,cycloLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                                src="../../../../assets/img/pencil-png.png"
                                                                height="10px"></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="mr-2 p-0">
                                        <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.fluora"
                                                class="mx-2"><b>Fluora</b></label>
                                    </div>
                                    <div class="mr-2 p-0">
                                        <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.propara"
                                                class="mx-2"><b>Propara</b></label>
                                    </div>
                                    <div class="mr-2 p-0">
                                        <table style="width: 100%">
                                            <tr>
                                                <td style="width:45%">
                                                    <label><input type="checkbox"
                                                            [(ngModel)]="exam.omR_Exam.dpaOtherCheckBox"
                                                            class="mx-2"><b>Other </b></label>
                                                </td>
                                                <td>
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.dpaOtherTextBox"
                                                        class="form-control">
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <ng-select [clearable]="true" [(ngModel)]="exam.provider_ID"
                                    placeholder="--- Select ---" #myUserSelect
                                    (change)="onExamSelectUser(exam.provider_ID)">
                                    <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                        {{userInfo.display_txt}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-12" style="height: 120px !important;">
                                <img [src]="examimgURL" height="120px">
                            </div>
                            <div class="col-md-12">
                                <!-- <label><b>Name :</b> {{examuser.first_name_txt + ' ' + examuser.last_name_txt}}</label> -->
                                <label><b>Doctor Name :</b> {{examuser.display_txt}}</label>
                            </div>
                            <div class="col-md-12">
                                <label><b>License :</b> {{examuser.license_txt}}</label>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div> <!--   id="tcpnprint" -->
                <div id="content" class="mt-4" #progressNotePanel>
                    <div class="titlebox"><b>Trial Contacts/Progress Notes</b></div>
                    <br>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-row">
                                <div class="col-md-2">
                                    <label><b>Date:</b></label>
                                </div>
                                <div class="col-md-10">
                                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                        (ngModelChange)="omr_ProgressNotes.progressDate=$event"
                                        [ngModel]="omr_ProgressNotes.progressDate | date: 'MM-dd-yyyy'"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-9">
                            <div class="form-row">
                                <div class="col-md-2">
                                    <label><b>Patient Comment:</b></label>
                                </div>
                                <div class="col-md-10">
                                    <input type="text" [(ngModel)]="omr_ProgressNotes.patientComments"
                                        style="background-color: blue !important; color: white !important;"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <table class="table" style="font-size: 14px !important; width: 150% !important; ">
                                <tr>
                                    <th style="width:3%"></th>
                                    <th class="text-left" style="width:13%">Power</th>
                                    <th class="text-center" style="width:12%">BC</th>
                                    <th class="text-center" style="width:12%">DIAM</th>
                                    <th class="text-left" style="width:28%">Brand</th>
                                    <th class="text-center" style="width:3%">VA:</th>
                                    <th class="text-center" style="width:15%">Far</th>
                                    <th class="text-center" style="width:15%">Near</th>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td><b>OD:</b></td>
                                        <td><input type="text" [(ngModel)]="omr_ProgressNotes.odPower"
                                                class="form-control">
                                        </td>
                                        <td style="padding-left: 0;">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odbc')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                                                    [items]="bcLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odbc,'Trial Contacts/Progress Notes',bcLookup,'bc',omr_ProgressNotes,'odbc',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    (change)="omr_ProgressNotes.odbc = $event.name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odbc">
                                                </ng-select>
                                                <a class="col-1" href="javascript:void(0)"
                                                    (click)="editLookup(bcLookup,bcLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td style="padding-left: 0; padding-right:0;">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'oddiam')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-11 pr-0"
                                                    [items]="diamLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.oddiam,'Trial Contacts/Progress Notes',diamLookup,'diam',omr_ProgressNotes,'oddiam',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    (change)="omr_ProgressNotes.oddiam = $event.name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.oddiam">
                                                </ng-select>
                                                <a class="col-1" href="javascript:void(0)"
                                                    (click)="editLookup(diamLookup,diamLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odBrand')"
                                                    (clear)="clearBrand('od')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    style="width: 90px !important;" [items]="brandLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odBrand,'Trial Contacts/Progress Notes',brandLookup,'brand',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odBrand"
                                                    (change)="selectBrand($event, 'od')">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editBrand(brandLookup,brandLookupCopy,dbEnum.QE_DEV_SQL_DB, 'add-brand')"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td><b>20/</b></td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaodFar')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaodFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaodFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaodFar">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>

                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaodNear')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaodNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaodNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaodNear">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OS:</b></td>
                                        <td><input type="text" [(ngModel)]="omr_ProgressNotes.osPower"
                                                class="form-control">
                                        </td>
                                        <td style="padding-left:0;">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osbc')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                                                    [items]="bcLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osbc,'Trial Contacts/Progress Notes',bcLookup,'bc',omr_ProgressNotes,'osbc',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    (change)="omr_ProgressNotes.osbc = $event.name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osbc">
                                                </ng-select>
                                                <a class="col-1" href="javascript:void(0)"
                                                    (click)="editLookup(bcLookup,bcLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td style="padding-left:0;padding-right:0;">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osdiam')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-11 pr-0"
                                                    [items]="diamLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osdiam,'Trial Contacts/Progress Notes',diamLookup,'diam',omr_ProgressNotes,'osdiam',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    (change)="omr_ProgressNotes.osdiam = $event.name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osdiam">
                                                </ng-select>
                                                <a class="col-1" href="javascript:void(0)"
                                                    (click)="editLookup(diamLookup,diamLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osBrand')"
                                                    (clear)="clearBrand('os')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="brandLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osBrand,'Trial Contacts/Progress Notes',brandLookup,'brand',omr_ProgressNotes,'osBrand',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osBrand"
                                                    (change)="selectBrand($event, 'os')">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                (click)="editBrand(brandLookup,brandLookupCopy,dbEnum.QE_DEV_SQL_DB, 'add-brand')"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td><b>20/</b></td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaosFar')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaosFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaosFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaosFar">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaosNear')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaosNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaosNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaosNear">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OS:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>20</b></td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaouFar')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaouFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaouFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaouFar">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaouNear')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaouNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaouNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaouNear">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary" (click)="copyDistObject()"><b>Copy Dist Subj</b></button>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <div class="col-12">
                                <div class="form-row">
                                    <label><b>Slit Lamp Exam:</b></label>
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'slitLampExaminationSelect')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-md-10" [items]="slitLampExamLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.slitLampExaminationSelect,'Slit Lamp Exam',slitLampExamLookup,'slitlampexam',omr_ProgressNotes,'slitLampExaminationSelect',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.slitLampExaminationSelect"
                                        (change)="slitLampOtherEnableDisable()">
                                    </ng-select>
                                    <a href="javascript:void(0)"
                                        (click)="editLookup(slitLampExamLookup,slitLampExamLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                            <div class="col-12">
                                <label><b>Other:</b></label>
                                <input type="text" [disabled]="slitLampOtherDisable"
                                    [(ngModel)]="omr_ProgressNotes.slitLampExamination" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-5 p-0">
                            <table class="table" style="font-size: 14px !important;">
                                <tr>
                                    <th style="width: 5%"></th>
                                    <th style="width: 40%">Over-refraction</th>
                                    <th class="text-center pl-0" style="width:5%">VA:</th>
                                    <th class="text-center pl-0" style="width:20%">Far</th>
                                    <th class="text-center pl-0" style="width:20%">Near</th>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td style="width: 3%"><b>OD:</b></td>
                                        <td style="width: 40%"><input type="text"
                                                [(ngModel)]="omr_ProgressNotes.overOD2" class="form-control">
                                        </td>
                                        <td class="pl-0"><b>20/</b></td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odvaFar2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-10" style="padding-left: 2px; padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'odvaFar2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odvaFar2">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odvaNear2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-10" style="padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'odvaNear2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odvaNear2">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OS:</b></td>
                                        <td><input [(ngModel)]="omr_ProgressNotes.overOS2" type="text"
                                                class="form-control">
                                        </td>
                                        <td class="pl-0"><b>20/</b></td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osvaFar2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-10" style="padding-left: 2px; padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'osvaFar2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osvaFar2">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osvaNear2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-10" style="padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'osvaNear2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osvaNear2">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OU:</b></td>
                                        <td><input [(ngModel)]="omr_ProgressNotes.overOU2" type="text"
                                                class="form-control">
                                        </td>
                                        <td class="pl-0"><b>20/</b></td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'ouvaFar2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-10" style="padding-left: 2px; padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.ouvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'ouvaFar2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.ouvaFar2">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'ouvaNear2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-md-10" style="padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.ouvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'ouvaNear2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.ouvaNear2">
                                                </ng-select>
                                                <a href="javascript:void(0)"
                                                    (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12">
                        <label><b>Assessment:</b></label>
                        <label style="margin-left:350px;"><b>Plan:</b></label>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                    <label class="col-md-1 col-form-label"><b>#1</b></label>
                                    <div class="form-row col-md-11">
                                        <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment1')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                                            [items]="tcAssessment"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment1,'#1',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment1')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.assessment1">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(tcAssessment,tcAssessmentCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6 p-0">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label><b>Plan For#</b></label>
                                    </div>

                                    <select [(ngModel)]="omr_ProgressNotes.planNumber1"
                                        class="form-control col-md-1 p-0">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan1')" addTagText="Add New"
                                                [addTag]="CreateNew" class="col-md-10" [items]="tcplanLookup"
                                                (keydown.tab)="saveDropdown(omr_ProgressNotes.plan1,'#1',tcplanLookup,'tcplan',omr_ProgressNotes,'plan1')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_ProgressNotes.plan1">
                                            </ng-select>
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(tcplanLookup,tcplanLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 p-0">
                                <div class="row">
                                    <label><input [(ngModel)]="omr_ProgressNotes.gaveCLRX" type="checkbox"
                                            (change)="checkBox($event)" class="mx-2"><b>Gave CLRX</b></label>
                                </div>

                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-md-3">
                                <div class="row">
                                    <label class="col-md-1 col-form-label"><b>#2</b></label>
                                    <div class="form-row col-md-11">
                                        <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                                            [items]="tcAssessment"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment2,'#2',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.assessment2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(tcAssessment,tcAssessmentCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6 p-0">
                                <div class="row">
                                    <div class="col-md-2 p-0"></div>
                                    <select [(ngModel)]="omr_ProgressNotes.planNumber2"
                                        class="form-control col-md-1 p-0">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <div class="col-6">
                                        <div class="row">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan2')" addTagText="Add New"
                                                [addTag]="CreateNew" class="col-md-10" [items]="tcplanLookup"
                                                (keydown.tab)="saveDropdown(omr_ProgressNotes.plan2,'#2',tcplanLookup,'tcplan',omr_ProgressNotes,'plan2')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_ProgressNotes.plan2">
                                            </ng-select>
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(tcplanLookup,tcplanLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 p-0">
                                <div class="row">
                                    <label class="pt-1"><input [(ngModel)]="omr_ProgressNotes.cleanQHS" type="checkbox"
                                            class="mx-2"><b>Clean QHS with</b></label>
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'cleanQHSText')" addTagText="Add New"
                                        [addTag]="CreateNew" [items]="cleanQHSLookup" class="col-md-4 p-0"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.cleanQHSText,'Clean QHS with',cleanQHSLookup,'CleanQHS',omr_ProgressNotes,'cleanQHSText')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.cleanQHSText">
                                    </ng-select>
                                    <a href="javascript:void(0)"
                                        (click)="editLookup(cleanQHSLookup,cleanQHSCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                            src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-md-3">
                                <div class="row">
                                    <label class="col-md-1 col-form-label"><b>#3</b></label>
                                    <div class="form-row col-md-11">
                                        <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment3')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-md-11"
                                            [items]="tcAssessment"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment3,'#3',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment3')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.assessment3">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(tcAssessment,tcAssessmentCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-6 p-0">
                                <div class="row">
                                    <div class="col-md-2 p-0">
                                    </div>

                                    <select [(ngModel)]="omr_ProgressNotes.planNumber3"
                                        class="form-control col-md-1 p-0">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan3')" addTagText="Add New"
                                                [addTag]="CreateNew" class="col-md-10" [items]="tcplanLookup"
                                                (keydown.tab)="saveDropdown(omr_ProgressNotes.plan3,'#3',tcplanLookup,'tcplan',omr_ProgressNotes,'plan3')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_ProgressNotes.plan3">
                                            </ng-select>
                                            <a href="javascript:void(0)"
                                                (click)="editLookup(tcplanLookup,tcplanLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 p-0">
                                <div class="row">
                                    <label><input [(ngModel)]="omr_ProgressNotes.orderContacts" type="checkbox"
                                            (change)="checkBox($event)" class="mx-2"><b>Order Contacts</b></label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label><b>Other Assessment:</b></label>
                            <textarea [(ngModel)]="omr_ProgressNotes.otherAssessment" class="form-control"></textarea>
                        </div>
                        <div class="col-md-4">
                            <label><b>Other Plan:</b></label>
                            <textarea [(ngModel)]="omr_ProgressNotes.otherPlan" class="form-control"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="col-md-12">
                                <ng-select [clearable]="true" placeholder="--- Select ---" #myUserSelect
                                    (change)="onSelectUser(omr_ProgressNotes.provider_ID)"
                                    [(ngModel)]="omr_ProgressNotes.provider_ID">
                                    <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                        {{userInfo.display_txt}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-md-12" style="height: 120px !important;">
                                <img [src]="imgURL" height="120px">
                            </div>
                            <div class="col-md-12">
                                <!-- <label><b>Name :</b> {{user.first_name_txt + ' ' + user.last_name_txt}}</label> -->
                                <label><b>Doctor Name :</b> {{user.display_txt}}</label>
                            </div>
                            <div class="col-md-12">
                                <label><b>License :</b> {{user.license_txt}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-md-12 text-center">
                            <table>
                                <tr>
                                    <td>
                                        <button class="btn btn-primary" (click)="saveProgressNote()"
                                            [style.display]="!editProgressNote ? 'block': 'none'">Save TC/PN</button>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary"
                                            [style.display]="editProgressNote ? 'block': 'none'"
                                            (click)="UpdateProgressNote()" [disabled]="exam.exam_ID == ''">Update
                                            TC/PN</button>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary"
                                            [style.display]="editProgressNote ? 'block': 'none'"
                                            (click)="deleteProgressNote()" [disabled]="exam.exam_ID == ''">Delete
                                            TC/PN</button>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary"
                                            [style.display]="editProgressNote ? 'block': 'none'"
                                            (click)="printProgressNote()" [disabled]="exam.exam_ID == ''" [useExistingCss]="true"
                                            printSectionId="tcpnprint" ngxPrint>Print TC/PN</button>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary"
                                            [style.display]="editProgressNote ? 'block': 'none'"
                                            (click)="cancelProgressNote()" [disabled]="exam.exam_ID == ''">Cancel TC/PN</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content" class="mt-4">
                <div class="titlebox"><b>Trial Contacts/Progress Notes</b></div>
                <div class="pt-2">
                    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" (rowDoubleClicked)="setProgressData($event)"
                        style="height: 500px; width: 100%;" class="ag-theme-alpine" [rowData]="rowNote"
                        [columnDefs]="columnNote">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ********************************************* -->
<!-- *****************TC/PN Print Related Items********* -->
<!-- ********************************************* -->
<div *ngIf="showComponent" id="tcpnprint" hidden class="mt-5 pt-2">
    <!-- <div class="container"> -->
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officename"> {{officename}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info">NPI # {{office_npi_txt}}</span>
        </div>

        <!-- <div class="col-12 text-center doc-info">
            <span class="doc-info">NPI # {{License}}</span>
        </div> -->
        <br>
        <h4 class="text-center"><b>Trial Contacts/Progress Notes</b></h4><br>
        <div class="row col-12">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0">
                        <label class="patient-info">Patient Name:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <div class="patient-reg-info">{{patientDetail.firstName}} {{patientDetail.lastName}}</div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0">
                        <label class="patient-info">Birthdate:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <div class="patient-reg-info">{{patientDetail.dateOfBirth | date:'MM-dd-yyyy'}}</div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <div class="patient-reg-info">{{currentDate | date:'MM-dd-yyyy'}}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date of Service:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <div class="patient-reg-info">{{exam.exam_Date | date:'MM-dd-yyyy'}}</div>
                    </div>
                </div>

            </div>
        </div>
        <br>
        <!-- <b><hr></b> -->
        <div class="col-12" style="border: 1px solid black;"></div>

        <div id="content" class="mt-4" #progressNotePanel>
            <div class="titlebox"><b>Trial Contacts/Progress Notes</b></div>
            <br>
            <div class="row">
                <div class="col-3">
                    <div class="form-row">
                        <div class="col-2">
                            <label><b>Date:</b></label>
                        </div>
                        <div class="col-10">
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                (ngModelChange)="omr_ProgressNotes.progressDate=$event"
                                [ngModel]="omr_ProgressNotes.progressDate | date: 'MM-dd-yyyy'"
                                class="form-control">
                        </div>
                    </div>
                </div>
                <div class="col-9">
                    <div class="form-row">
                        <div class="col-3">
                            <label><b>Patient Comment:</b></label>
                        </div>
                        <div class="col-9">
                            <input type="text" [(ngModel)]="omr_ProgressNotes.patientComments"
                                style="background-color: blue !important; color: white !important;"
                                class="form-control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <table class="table" style="font-size: 14px !important; width: 150% !important; ">
                        <tr>
                            <th style="width:5%"></th>
                            <th class="text-left" style="width:13%">Power</th>
                            <th class="text-center" style="width:12%">BC</th>
                            <th class="text-center" style="width:12%">DIAM</th>
                            <th class="text-left" style="width:28%">Brand</th>
                            <th class="text-center" style="width:5%">VA:</th>
                            <th class="text-center" style="width:13%">Far</th>
                            <th class="text-center" style="width:13%">Near</th>
                        </tr>
                        <tbody>
                            <tr>
                                <td class="border-right px-2"><b>OD:</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.odPower}}
                                    <!-- <input type="text" [(ngModel)]="omr_ProgressNotes.odPower"
                                        class="form-control"> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.odbc}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odbc')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                        [items]="bcLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.odbc,'Trial Contacts/Progress Notes',bcLookup,'bc',omr_ProgressNotes,'odbc',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.odbc">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.oddiam}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'oddiam')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                        [items]="diamLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.oddiam,'Trial Contacts/Progress Notes',diamLookup,'diam',omr_ProgressNotes,'oddiam',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.oddiam">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.odBrand}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odBrand')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                        [items]="brandLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.odBrand,'Trial Contacts/Progress Notes',brandLookup,'brand',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.odBrand">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2"><b>20/</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.vaodFar}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaodFar')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                        [items]="farNearLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.vaodFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaodFar')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.vaodFar">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.vaodNear}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaodNear')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                        [items]="farNearLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.vaodNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaodNear')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.vaodNear">
                                    </ng-select> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="border-right px-2"><b>OS:</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.osPower}}
                                    <!-- <input type="text" [(ngModel)]="omr_ProgressNotes.osPower"
                                        class="form-control"> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.osbc}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osbc')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="bcLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.osbc,'Trial Contacts/Progress Notes',bcLookup,'bc',omr_ProgressNotes,'osbc',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.osbc">
                                    </ng-select>  -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.osdiam}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osdiam')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="diamLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.osdiam,'Trial Contacts/Progress Notes',diamLookup,'diam',omr_ProgressNotes,'osdiam',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.osdiam">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.osBrand}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osBrand')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="brandLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.osBrand,'Trial Contacts/Progress Notes',brandLookup,'brand',omr_ProgressNotes,'osBrand',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.osBrand">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2"><b>20/</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.vaosFar}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaosFar')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="farNearLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.vaosFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaosFar')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.vaosFar">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.vaosNear}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaosNear')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="farNearLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.vaosNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaosNear')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.vaosNear">
                                    </ng-select> -->
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="border-right px-2"><b>OS:</b></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="border-right px-2"></td>
                                <td class="border-right px-2"><b>20</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.vaouFar}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaouFar')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="farNearLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.vaouFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaouFar')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.vaouFar">
                                    </ng-select> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.vaouNear}}
                                    <!-- <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaouNear')"
                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                        [items]="farNearLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.vaouNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaouNear')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.vaouNear">
                                    </ng-select> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-7">
                    <div class="col-12">
                        <div class="form-row">
                            <label><b>Slit Lamp Exam:</b></label>
                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'slitLampExaminationSelect')" addTagText="Add New"
                                [addTag]="CreateNew" class="col-10" [items]="slitLampExamLookup"
                                (keydown.tab)="saveDropdown(omr_ProgressNotes.slitLampExaminationSelect,'Slit Lamp Exam',slitLampExamLookup,'slitlampexam',omr_ProgressNotes,'slitLampExaminationSelect',dbEnum.QE_DEV_SQL_DB)"
                                bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="omr_ProgressNotes.slitLampExaminationSelect"
                                (change)="slitLampOtherEnableDisable()">
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-12">
                        <label><b>Other:</b></label>
                        <input type="text" [disabled]="slitLampOtherDisable"
                            [(ngModel)]="omr_ProgressNotes.slitLampExamination" class="form-control">
                    </div>
                </div>
                <div class="col-5">
                    <table class="table" style="font-size: 14px !important;">
                        <tr>
                            <th style="width: 10%"></th>
                            <th style="width: 40%">Over-refraction</th>
                            <th class="text-center" style="width:10%">VA:</th>
                            <th class="text-center" style="width:15%">Far</th>
                            <th class="text-center" style="width:15%">Near</th>
                        </tr>
                        <tbody>
                            <tr>
                                <td class="border-right px-2"><b>OD:</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.overOD2}}
                                    <!-- <input type="text"
                                        [(ngModel)]="omr_ProgressNotes.overOD2" class="form-control"> -->
                                </td>
                                <td class="border-right px-2"><b>20/</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.odvaFar2}}
                                    <!-- <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odvaFar2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                            [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.odvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'odvaFar2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.odvaFar2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.odvaNear2}}
                                    <!-- <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odvaNear2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                            [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.odvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'odvaNear2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.odvaNear2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div> -->
                                </td>
                            </tr>
                            <tr>
                                <td class="border-right px-2"><b>OS:</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.overOS2}}
                                    <!-- <input [(ngModel)]="omr_ProgressNotes.overOS2" type="text"
                                        class="form-control"> -->
                                </td>
                                <td class="border-right px-2"><b>20/</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.osvaFar2}}
                                    <!-- <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osvaFar2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                            [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.osvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'osvaFar2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.osvaFar2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.osvaNear2}}
                                    <!-- <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osvaNear2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                            [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.osvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'osvaNear2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.osvaNear2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div> -->
                                </td>
                            </tr>
                            <tr class="border-bottom">
                                <td class="border-right px-2"><b>OU:</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.overOU2}}
                                    <!-- <input [(ngModel)]="omr_ProgressNotes.overOU2" type="text"
                                        class="form-control"> -->
                                </td>
                                <td class="border-right px-2"><b>20/</b></td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.ouvaFar2}}
                                    <!-- <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'ouvaFar2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                            [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.ouvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'ouvaFar2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.ouvaFar2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div> -->
                                </td>
                                <td class="border-right px-2">
                                    {{omr_ProgressNotes.ouvaNear2}}
                                    <!-- <div class="form-row">
                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'ouvaNear2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                            [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.ouvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'ouvaNear2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.ouvaNear2">
                                        </ng-select>
                                        <a href="javascript:void(0)"
                                            (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                                src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </div> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12">
                <label><b>Assessment:</b></label>
                <label style="margin-left:350px;"><b>Plan:</b></label>
                <div class="row">
                    <div class="col-3">
                        <div class="row">
                            <label class="col-1 col-form-label"><b>#1</b></label>
                            <div class="form-row col-11">
                                <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment1')"
                                    addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                    [items]="tcAssessment"
                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment1,'#1',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment1')"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_ProgressNotes.assessment1">
                                </ng-select>
                            </div>

                        </div>
                    </div>
                    <div class="col-6 p-0">
                        <div class="row">
                            <div class="col-2">
                                <label><b>Plan For#</b></label>
                            </div>

                            <div class="col-1">
                                <label class="form-control pr-3" style="width: 100%; text-align: left;">{{omr_ProgressNotes.planNumber1}}</label>
                                <!-- <select [(ngModel)]="omr_ProgressNotes.planNumber1" class="form-control p-0">
                                    <option [value]=""></option>
                                    <option [value]="1">1</option>
                                    <option [value]="2">2</option>
                                    <option [value]="3">3</option>
                                    <option [value]="4">4</option>
                                    <option [value]="5">5</option>
                                </select> -->
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan1')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-12" [items]="tcplanLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.plan1,'#1',tcplanLookup,'tcplan',omr_ProgressNotes,'plan1')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.plan1">
                                    </ng-select>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-0">
                        <div class="row">
                            <label><input [(ngModel)]="omr_ProgressNotes.gaveCLRX" type="checkbox"
                                    (change)="checkBox($event)" class="mx-2"><b>Gave CLRX</b></label>
                        </div>

                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-3">
                        <div class="row">
                            <label class="col-1 col-form-label"><b>#2</b></label>
                            <div class="form-row col-11">
                                <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment2')"
                                    addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                    [items]="tcAssessment"
                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment2,'#2',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment2')"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_ProgressNotes.assessment2">
                                </ng-select>
                            </div>

                        </div>
                    </div>
                    <div class="col-6 p-0">
                        <div class="row">
                            <div class="col-2 p-0"></div>
                            <div class="col-1">
                                <label class="form-control pr-3" style="width: 100%; text-align: left;">{{omr_ProgressNotes.planNumber2}}</label>
                                <!-- <select [(ngModel)]="omr_ProgressNotes.planNumber2" class="form-control p-0">
                                    <option [value]=""></option>
                                    <option [value]="1">1</option>
                                    <option [value]="2">2</option>
                                    <option [value]="3">3</option>
                                    <option [value]="4">4</option>
                                    <option [value]="5">5</option>
                                </select> -->
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan2')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-12" [items]="tcplanLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.plan2,'#2',tcplanLookup,'tcplan',omr_ProgressNotes,'plan2')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.plan2">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-0">
                        <div class="row">
                            <label class="pt-1"><input [(ngModel)]="omr_ProgressNotes.cleanQHS" type="checkbox"
                                    class="mx-2"><b>Clean QHS with</b></label>
                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'cleanQHSText')" addTagText="Add New"
                                [addTag]="CreateNew" [items]="cleanQHSLookup" class="col-4 px-1"
                                (keydown.tab)="saveDropdown(omr_ProgressNotes.cleanQHSText,'Clean QHS with',cleanQHSLookup,'CleanQHS',omr_ProgressNotes,'cleanQHSText')"
                                bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="omr_ProgressNotes.cleanQHSText">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-3">
                        <div class="row">
                            <label class="col-1 col-form-label"><b>#3</b></label>
                            <div class="form-row col-11">
                                <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment3')"
                                    addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                    [items]="tcAssessment"
                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment3,'#3',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment3')"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="omr_ProgressNotes.assessment3">
                                </ng-select>
                            </div>

                        </div>
                    </div>
                    <div class="col-6 p-0">
                        <div class="row">
                            <div class="col-2 p-0">
                            </div>

                            <div class="col-1">
                                <label class="form-control pr-3" style="width: 100%; text-align: left;">{{omr_ProgressNotes.planNumber3}}</label>
                                <!-- <select [(ngModel)]="omr_ProgressNotes.planNumber3" class="form-control p-0">
                                    <option [value]=""></option>
                                    <option [value]="1">1</option>
                                    <option [value]="2">2</option>
                                    <option [value]="3">3</option>
                                    <option [value]="4">4</option>
                                    <option [value]="5">5</option>
                                </select> -->
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan3')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-12" [items]="tcplanLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.plan3,'#3',tcplanLookup,'tcplan',omr_ProgressNotes,'plan3')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.plan3">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 p-0">
                        <div class="row">
                            <label><input [(ngModel)]="omr_ProgressNotes.orderContacts" type="checkbox"
                                    (change)="checkBox($event)" class="mx-2"><b>Order Contacts</b></label>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <label><b>Other Assessment:</b></label>
                    <textarea [(ngModel)]="omr_ProgressNotes.otherAssessment" class="form-control"></textarea>
                </div>
                <div class="col-4">
                    <label><b>Other Plan:</b></label>
                    <textarea [(ngModel)]="omr_ProgressNotes.otherPlan" class="form-control"></textarea>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-8">
                    <div class="col-12">
                        <ng-select [clearable]="true" placeholder="--- Select ---" #myUserSelect
                            (change)="onSelectUser(omr_ProgressNotes.provider_ID)"
                            [(ngModel)]="omr_ProgressNotes.provider_ID">
                            <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                {{userInfo.display_txt}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-12" style="height: 120px !important;">
                        <img [src]="imgURL" height="120px">
                    </div>
                    <div class="col-12">
                        <!-- <label><b>Name :</b> {{user.first_name_txt + ' ' + user.last_name_txt}}</label> -->
                        <label><b>Doctor Name :</b> {{user.display_txt}}</label>
                    </div>
                    <div class="col-12">
                        <label><b>License :</b> {{user.license_txt}}</label>
                    </div>
                </div>
            </div>
        </div>
</div>
<!-- ********************************************* -->
<!-- *****************End TC/PN Print Related Items********* -->
<!-- ********************************************* -->


<!-- ********************************************* -->
<!-- *****************Print Related Items********* -->
<!-- ********************************************* -->
<div *ngIf="showComponent" id="print-section" hidden class="mt-5 pt-2">
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officename"> {{officename}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info">NPI # {{office_npi_txt}}</span>
    </div>

    <!-- <div class="col-12 text-center doc-info">
        <span class="doc-info">NPI # {{License}}</span>
    </div> -->
    <br>
    <h4 class="text-center"><b>Exam</b></h4><br>

    <div class="row col-12">
        <div class="col-6">

            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Patient Name:</label>
                </div>
                <div class="col-5 pl-0">
                    <div class="patient-reg-info">{{patientDetail.firstName}} {{patientDetail.lastName}}</div>
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Birthdate:</label>
                </div>
                <div class="col-5 pl-0">
                    <div class="patient-reg-info">{{patientDetail.dateOfBirth | date:'MM-dd-yyyy'}}</div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-3 pr-0 pl-0">
                    <label class="patient-info">Date:</label>
                </div>
                <div class="col-7 pl-0">
                    <div class="patient-reg-info">{{currentDate | date:'MM-dd-yyyy'}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-3 pr-0 pl-0">
                    <label class="patient-info">Date of Service:</label>
                </div>
                <div class="col-7 pl-0">
                    <div class="patient-reg-info">{{exam.exam_Date | date:'MM-dd-yyyy'}}</div>
                </div>
            </div>

        </div>
    </div>
    <div class="col-12" style="border: 1px solid black;"></div>

    <div>
        <div class="row pt-5">
            <div class="col-2 pr-0">
                <label><b>Exam Date:</b></label>
            </div>
            <div class="col-2 pl-0">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                    (ngModelChange)="exam.exam_Date = $event" [ngModel]="exam.exam_Date | date:'MM-dd-yyyy'"
                    class="form-control">
            </div>
            <div class="col-1 px-0">
                <label><b>Exam Office:</b></label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <ng-select class="col-12" [items]="office" bindLabel="name_txt" bindValue="office_id"
                        [(ngModel)]="exam.office_ID">
                    </ng-select>
                </div>
            </div>
            <div class="col-1 px-0">
                <label><b>Visit Type:</b></label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <ng-select (keyup)="keyFunc($event,exam,'visit_type')" addTagText="Add New" [addTag]="CreateNew" class="col-12"
                        [items]="examVisitTypeLookup"
                        (keydown.tab)="saveDropdown(exam.visit_type,'Visit Type',examVisitTypeLookup,'examvisittype',exam,'visit_type',dbEnum.QE_DEV_SQL_DB)"
                        [(ngModel)]="exam.visit_type" bindLabel="name_txt" bindValue="name_txt">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-1">
                <label><b>CC:</b></label>
            </div>
            <div class="col-11">
                <textarea [(ngModel)]="exam.cheif_Complaint" class="form-control" style="height: 50%;"
                    rows="3"></textarea>
            </div>
        </div>
        <div id="content" style="padding-left: 10px !important;">
            <div class="titlebox"><b>History of Present Illness</b></div>
            <br />
            <div class="row mb-2">
                <div class="col-1">
                    <label><b>Location:</b></label>
                </div>
                <div class="col-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phLocation')" addTagText="Add New" [addTag]="CreateNew" class="col-9"
                            [items]="locationLookUp"
                            (keydown.tab)="saveDropdown(exam.phLocation,'Location',locationLookUp,'Location',exam,'phLocation')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phLocation">
                        </ng-select>
                    </div>
                </div>
                <div class="col-1">
                    <label><b>Quality:</b></label>
                </div>
                <div class="col-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phQuality')" addTagText="Add Item" [addTag]="CreateNew" class="col-9"
                            [items]="qualityLookup"
                            (keydown.tab)="saveDropdown(exam.phQuality,'Quality',qualityLookup,'Quality',exam,'phQuality')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phQuality">
                        </ng-select>
                    </div>
                </div>
                <div class="col-1">
                    <label><b>Severity:</b></label>
                </div>
                <div class="col-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phSeverity')" addTagText="Add New" [addTag]="CreateNew" class="col-9"
                            [items]="severityLookup"
                            (keydown.tab)="saveDropdown(exam.phSeverity,'Severity',severityLookup,'Severity',exam,'phSeverity')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phSeverity">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-1">
                    <label><b>Duration:</b></label>
                </div>
                <div class="col-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phDuration')" addTagText="Add New" [addTag]="CreateNew" class="col-9"
                            [items]="durationLookup"
                            (keydown.tab)="saveDropdown(exam.phDuration,'Duration',durationLookup,'Duration',exam,'phDuration')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phDuration">
                        </ng-select>
                    </div>
                </div>
                <div class="col-1">
                    <label><b>Timing:</b></label>
                </div>
                <div class="col-3">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phTiming')" addTagText="Add New" [addTag]="CreateNew" class="col-9"
                            [items]="timingLookup"
                            (keydown.tab)="saveDropdown(exam.phTiming,'Timing',timingLookup,'Timing',exam,'phTiming')"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phTiming">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-1">
                    <label><b>Context:</b></label>
                </div>
                <div class="col-11">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phContext')" addTagText="Add New" [addTag]="CreateNew" class="col-11"
                            [items]="contextLookup"
                            (keydown.tab)="saveDropdown(exam.phContext,'Context',contextLookup,'context',exam,'phContext',dbEnum.QE_DEV_SQL_DB)"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phContext">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3">
                    <label><b>Modifying Factors:</b></label>
                </div>
                <div class="col-9">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phModifyingFactors')" addTagText="Add New" [addTag]="CreateNew" class="col-11"
                            [items]="factorLookup"
                            (keydown.tab)="saveDropdown(exam.phModifyingFactors,'Modifying Factors',factorLookup,'modifyingfactors',exam,'phModifyingFactors',dbEnum.QE_DEV_SQL_DB)"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phModifyingFactors">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3">
                    <label><b>Associated Signs & Symptoms:</b></label>
                </div>
                <div class="col-9">
                    <div class="form-row">
                        <ng-select (keyup)="keyFunc($event,exam,'phSignsAndSymptoms')" addTagText="Add New" [addTag]="CreateNew" class="col-11"
                            [items]="symptomsLook"
                            (keydown.tab)="saveDropdown(exam.phSignsAndSymptoms,'Associated Signs & Symptoms',symptomsLook,'signssymptoms',exam,'phSignsAndSymptoms',dbEnum.QE_DEV_SQL_DB)"
                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.phSignsAndSymptoms">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <label><b>Medical Hx and ROS From:</b></label>
                </div>
                <div class="col-6">
                    <input (ngModelChange)="exam.medHXandROS = $event" [ngModel]="exam.medHXandROS | date:'MM-dd-yyyy'"
                        type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                </div>
                <div class="col-3">
                    <div class="col-10">
                        <label><input [(ngModel)]="exam.omR_Exam.pptOriented" type="checkbox" class="mx-2"> <b>PPT
                                Oriented</b></label>
                    </div>
                </div>
                <br><br>
                <div class="row" style="margin-left:0px; width: 100%;">
                    <div class="col-1">
                        <label><b>Head/Face:</b></label>
                    </div>
                    <div class="col-2">
                        <input class="mx-1" [(ngModel)]="exam.omR_Exam.headFaceNormal" type="checkbox">
                        <label><b>Normal</b></label>
                    </div>
                    <div class="col-5">
                        <div class="row">
                            <div class="col-3">
                                <input class="mx-1" [(ngModel)]="exam.omR_Exam.eomNormal" autocomplete="off" type="checkbox">
                                <label><b>Other:</b></label>
                            </div>

                            <div class="col-9">
                                <div class="form-row">
                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'headFaceOther')" addTagText="Add New" [addTag]="CreateNew"
                                        class="col-8" [items]="headFace"
                                        (keydown.tab)="saveDropdown(exam.omR_Exam.headFaceOther,'Other',headFace,'HeadFace',exam.omR_Exam,'headFaceOther')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="exam.omR_Exam.headFaceOther">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-3">
                                <label><b>Psych:Mood/Affect:</b></label>
                            </div>
                            <div class="col-9">
                                <div class="form-row">
                                    <ng-select (keyup)="keyFunc($event,exam,'psychMood')" addTagText="Add New" [addTag]="CreateNew"
                                        class="col-9" [items]="psychLookup"
                                        (keydown.tab)="saveDropdown(exam.psychMood,'Psych:Mood/Affect',psychLookup,'PhychMoodAffect',exam,'psychMood')"
                                        bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.psychMood">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-4" style="padding-right: 0;">
                    <div id="content" style="height: 208px !important;padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>Unaided VA's</b></div>
                        <div class="row col-12" style="padding: 0;margin: 0;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width: 14%;"></td>
                                    <td style="text-align:center;"><b>Far</b></td>
                                    <td></td>
                                    <td style="text-align:center;"><b>Near</b></td>
                                </tr>
                                <tr>
                                    <td style="width: 14%;">
                                        <label><b>OD/20</b></label>
                                    </td>
                                    <td>
                                        <input type="text" [ngModel]="exam.omR_Exam.unaidedODFar" class="form-control">
                                        <!-- <div class="col-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedODFar')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedODFar,'OD/20',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedODFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedODFar">
                                                </ng-select>
                                            </div>
                                        </div> -->
                                    </td>
                                    <td style="width: 6%;padding-left: 5px;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td>
                                        <input type="text" [ngModel]="exam.omR_Exam.unaidedODNear" class="form-control" style="width: 99%;">
                                        <!-- <div class="col-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedODNear')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedODNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedODNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedODNear">
                                                </ng-select>
                                            </div>
                                        </div> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 14%;">
                                        <label><b>OS/20</b></label>
                                    </td>
                                    <td>
                                        <input type="text" [ngModel]="exam.omR_Exam.unaidedOSFar" class="form-control">
                                        <!-- <div class="col-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedOSFar')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedOSFar,'OS/20',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedOSFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedOSFar">
                                                </ng-select>
                                            </div>
                                        </div> -->
                                    </td>
                                    <td style="width: 6%;padding-left: 5px;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td>
                                        <input type="text" [ngModel]="exam.omR_Exam.unaidedOSNear" class="form-control" style="width: 99%;">
                                        <!-- <div class="col-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'unaidedOSNear')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.unaidedOSNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'unaidedOSNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.unaidedOSNear">
                                                </ng-select>
                                            </div>
                                        </div> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 14%;">
                                        <label><b>OU/20</b></label>
                                    </td>
                                    <td>
                                        <input type="text" [ngModel]="exam.omR_Exam.vaouFar" class="form-control">
                                        <!-- <div class="col-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'vaouFar')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.vaouFar,'OU/20',farNearLookup,'VisualAcuities',exam.omR_Exam,'vaouFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.vaouFar">
                                                </ng-select>
                                            </div>
                                        </div> -->
                                    </td>
                                    <td style="width: 6%;padding-left: 5px;">
                                        <label><b>20/</b> </label>
                                    </td>
                                    <td>
                                        <input type="text" [ngModel]="exam.omR_Exam.vaouNear" class="form-control" style="width: 99%;">
                                        <!-- <div class="col-12 p-0">
                                            <div class="form-row">
                                                <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'vaouNear')" addTagText="Add New"
                                                    [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.vaouNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'vaouNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.vaouNear">
                                                </ng-select>
                                            </div>
                                        </div> -->
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <br>
                    </div>
                </div>
                <div class="col-6">
                    <div id="content" style="height:208px !important;padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>Current Rx</b></div>
                        <br>
                        <div class="row">
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-2">
                                        <label><b>Date:</b></label>
                                    </div>
                                    <div class="col-10">
                                        <input (ngModelChange)="exam.omR_Exam.curRXDate = $event"
                                            [ngModel]="exam.omR_Exam.curRXDate | date:'MM-dd-yyyy'" type="text"
                                            [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <label><b>OD:</b></label>
                                    </div>
                                    <div class="col-10">
                                        <input [(ngModel)]="exam.omR_Exam.curRXODSphere" type="text"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <label><b>OS:</b></label>
                                    </div>
                                    <div class="col-10">
                                        <input [(ngModel)]="exam.omR_Exam.curRXOSSphere" type="text"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <label><b>Add:</b></label>
                                    </div>
                                    <div class="col-10">
                                        <input [(ngModel)]="exam.omR_Exam.curRXAdd" type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-7" style="padding-left: 5px;">
                                <table style="width:100%">
                                    <tr>
                                        <td style="width: 14%;"></td>
                                        <td style="text-align:center;"><b>Far</b></td>
                                        <td></td>
                                        <td style="text-align:center;"><b>Near</b></td>
                                    </tr>
                                    <tr>
                                        <td style="width: 14%;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <input type="text" [(ngModel)]="exam.omR_Exam.curRXODFar" class="form-control">
                                            <!-- <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXODFar')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXODFar,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXODFar')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXODFar">
                                                    </ng-select>
                                                </div>
                                            </div> -->
                                        </td>
                                        <td style="width: 6%;padding-left: 5px;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <input type="text" [(ngModel)]="exam.omR_Exam.curRXODNear" class="form-control" style="width: 99%;">
                                            <!-- <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXODNear')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXODNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXODNear')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXODNear">
                                                    </ng-select>
                                                </div>
                                            </div> -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 14%;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <input type="text" [(ngModel)]="exam.omR_Exam.curRXOSFar" class="form-control">
                                            <!-- <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXOSFar')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOSFar,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOSFar')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOSFar">
                                                    </ng-select>
                                                </div>
                                            </div> -->
                                        </td>
                                        <td style="width: 6%;padding-left: 5px;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <input type="text" [(ngModel)]="exam.omR_Exam.curRXOSNear" class="form-control" style="width: 99%;">
                                            <!-- <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXOSNear')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOSNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOSNear')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOSNear">
                                                    </ng-select>
                                                </div>
                                            </div> -->
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 14%;">
                                            <label><b>OU 20/</b></label>
                                        </td>
                                        <td>
                                            <input type="text" [(ngModel)]="exam.omR_Exam.curRXOUFar" class="form-control">
                                            <!-- <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <ng-select class="auto-grow" (keyup)="keyFunc($event,exam.omR_Exam,'curRXOUFar')"
                                                        addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                                        [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOUFar,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOUFar')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOUFar">
                                                    </ng-select>
                                                </div>
                                            </div> -->
                                        </td>
                                        <td style="width: 6%;padding-left: 5px;">
                                            <label><b>20/</b></label>
                                        </td>
                                        <td>
                                            <input type="text" [(ngModel)]="exam.omR_Exam.curRXOUNear" class="form-control" style="width: 99%;">
                                            <!-- <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'curRXOUNear')" addTagText="Add New"
                                                        [addTag]="CreateNew" class="col-10" [items]="farNearLookup"
                                                        (keydown.tab)="saveDropdown(exam.omR_Exam.curRXOUNear,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'curRXOUNear')"
                                                        bindLabel="name_txt" bindValue="name_txt"
                                                        [(ngModel)]="exam.omR_Exam.curRXOUNear">
                                                    </ng-select>
                                                </div>
                                            </div> -->
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 pl-0">
                    <div id="content"
                        style="padding-left:2px !important; padding-right: 2px !important;height: 208px !important;">
                        <div class="titlebox pl-0"><b>Accomodation</b></div>
                        <div class="col-12 mt-5 pl-1 pr-1">
                            <div class="form-row">
                                <div class="col-3">
                                    <label><b>Amp</b></label>
                                </div>
                                <div class="col-9 pl-0 pr-0">
                                    <!-- <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'amp')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-11" style="border-radius: 10px;"
                                            [items]="ampLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.amp,'Amp',ampLookup,'amp',exam.omR_Exam,'amp')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.amp"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                    </div> -->
                                    <input type="text" [(ngModel)]="exam.omR_Exam.amp" class="form-control" style="width: 99%;">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pl-1 pr-1">
                            <div class="form-row">
                                <div class="col-3">
                                    <label><b>PRA</b></label>
                                </div>
                                <div class="col-9 pl-0 pr-0">
                                    <!-- <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'pra')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-11" style="border-radius: 10px;"
                                            [items]="praLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.pra,'PRA',praLookup,'pra',exam.omR_Exam,'pra')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.pra"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                    </div> -->
                                    <input type="text" [(ngModel)]="exam.omR_Exam.pra" class="form-control" style="width: 99%;">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pl-1 pr-1">
                            <div class="form-row">
                                <div class="col-3">
                                    <label><b>NRA</b></label>
                                </div>
                                <div class="col-9 pl-0 pr-0">
                                    <!-- <div class="form-row">
                                        <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nra')" addTagText="Add New" [addTag]="CreateNew"
                                            class="auto-grow" class="col-11" style="border-radius: 10px;"
                                            [items]="nraLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nra,'NRA',nraLookup,'nra',exam.omR_Exam,'nra')"
                                            bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.nra"
                                            [ngModelOptions]="{standalone: true}">
                                        </ng-select>
                                    </div> -->
                                    <input type="text" [(ngModel)]="exam.omR_Exam.nra" class="form-control" style="width: 99%;">
                                </div>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
            <diV class="form-row pt-4 m-1">
                <div id="content" class="mr-2" style="width: 20%; padding-left: 5px !important;">
                    <div class="titlebox"><b>Pupils</b></div>
                    <br />
                    <label><input [(ngModel)]="exam.omR_Exam.pupilsPERRLA" type="checkbox"
                            class="mx-2"><b>PERRLA</b></label>
                    <div class="form-row">
                        <div class="col-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <!-- <div class="col-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'pupils')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-12" [items]="pupils"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.pupils,'Pupils',pupils,'Pupils',exam.omR_Exam,'pupils')"
                                bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.pupils">
                            </ng-select>
                        </div> -->
                        <input type="text" [(ngModel)]="exam.omR_Exam.pupils" class="form-control ml-1">
                    </div>
                </div>
                <div id="content" class="mr-2" style="width: 19%; padding-left: 5px !important;">
                    <div class="titlebox"><b>Cover Test</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.coverOrtho_DNN"
                            class="mx-2"><b>Ortho@D&N</b></label>
                    <div class="form-row">
                        <div class="col-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <!-- <div class="col-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'coverOther')" class="col-12" [items]="coverTest"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.coverOther,'Cover Test',coverTest,'CoverTest',exam.omR_Exam,'coverOther')"
                                bindLabel="name_txt" bindValue="name_txt" autoClearSearch="true"
                                [(ngModel)]="exam.omR_Exam.coverOther">
                            </ng-select>
                        </div>-->
                        <input type="text" [(ngModel)]="exam.omR_Exam.coverOther" class="form-control ml-1">
                    </div>
                </div>
                <div id="content" class="mr-2" style="width: 19%; padding-left: 5px !important;">
                    <div class="titlebox"><b>NPC</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.npcttn" class="mx-2"><b>TTN</b></label>
                    <div class="form-row">
                        <div class="col-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <!-- <div class="col-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'npcOther')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-12" [items]="npcLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.npcOther,'NPC',npcLookup,'NPC',exam.omR_Exam,'npcOther')"
                                bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.npcOther">
                            </ng-select>
                        </div>-->
                        <input type="text" [(ngModel)]="exam.omR_Exam.npcOther" class="form-control ml-1">
                    </div>
                </div>
                <div id="content" class="mr-2" style="width: 19%; padding-left: 5px !important;">
                    <div class="titlebox"><b>EOM</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.eomNormal"
                            class="mx-2"><b>Normal</b></label>
                    <div class="form-row">
                        <div class="col-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <!-- <div class="col-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'eomOther')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-12" [items]="eomLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.eomOther,'EOM',eomLookup,'EOM',exam.omR_Exam,'eomOther')"
                                bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="exam.omR_Exam.eomOther">
                            </ng-select>
                        </div>-->
                        <input type="text" [(ngModel)]="exam.omR_Exam.eomOther" class="form-control ml-1">
                    </div>
                </div>
                <div id="content" style="width: 19%; padding-left: 5px !important;">
                    <div class="titlebox"><b>Confrontations</b></div>
                    <br />
                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.fieldScreenConfrontationWNL"
                            class="mx-2"><b>Normal</b></label>
                    <div class="form-row">
                        <div class="col-2">
                            <label class="col-form-label"><b>Other:</b></label>
                        </div>
                        <!-- <div class="col-8">
                            <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'fieldScreenConfrontationOther')" addTagText="Add New" [addTag]="CreateNew"
                                class="col-12" [items]="confrontationsLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.fieldScreenConfrontationOther,'Confrontations',confrontationsLookup,'Confrontations',exam.omR_Exam,'fieldScreenConfrontationOther')"
                                bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="exam.omR_Exam.fieldScreenConfrontationOther">
                            </ng-select>
                        </div>-->
                        <input type="text" [(ngModel)]="exam.omR_Exam.fieldScreenConfrontationOther" class="form-control ml-1">
                    </div>
                </div>
            </diV>
            <div class="row pt-4">
                <div class="col-4">
                    <div id="content" style="padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>RET or AR</b></div>
                        <div class="row col-12" style="margin: 0;padding-left: 0px;padding-right: 5px;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:10%">
                                        <label><b>OD</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <input type="text" [(ngModel)]="exam.omR_Exam.retarodSphere"
                                            class="form-control">
                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'retarodToggle')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.retarodToggle,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'retarodToggle')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.retarodToggle">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.retarodToggle" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%">
                                        <label><b>OS</b></label>
                                    </td>
                                    <td style="width:40%">
                                        <input [(ngModel)]="exam.omR_Exam.retarosSphere" type="text"
                                            class="form-control">
                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:30%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'retarosToggle')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.retarosToggle,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'retarosToggle')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.retarosToggle">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.retarosToggle" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%">

                                    </td>
                                    <td style="width:40%">

                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>OU 20/</b></label>
                                    </td>
                                    <td style="width:20%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'retarouva')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.retarouva,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'retarouva')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.retarouva">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.retarouva" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-4" style="padding-left: 0;padding-right: 0;">
                    <div id="content" style="padding-left: 10px !important;padding-right: 0;">
                        <div class="titlebox"><b>Dist Subj</b></div>
                        <div class="row col-12" style="padding: 0; margin-left: -7px;">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:5%">
                                        <label><b>OD</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <input [(ngModel)]="exam.omR_Exam.distSubjectODSphere" (input)="setValue()"
                                            type="text" class="form-control col-12" style="padding: 5px;">
                                    </td>
                                    <td style="width:5%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:40%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'distSubjectOD20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.distSubjectOD20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'distSubjectOD20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.distSubjectOD20">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.distSubjectOD20" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:5%">
                                        <label><b>OS</b></label>
                                    </td>
                                    <td style="width:50%">
                                        <input type="text" [(ngModel)]="exam.omR_Exam.distSubjectOSSphere"
                                            (input)="setValue()" class="form-control" style="padding: 5px;">
                                    </td>
                                    <td style="width:5%; text-align: right;">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width:30%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'distSubjectOS20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.distSubjectOS20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'distSubjectOS20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.distSubjectOS20">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.distSubjectOS20" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:10%">

                                    </td>
                                    <td style="width:40%">
                                    </td>
                                    <td style="width:10%; text-align: right;">
                                        <label><b>OU 20/</b></label>
                                    </td>
                                    <td style="width:20%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'distSubjectOU20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12" style="padding-left: 2px;padding-right: 1px;" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.distSubjectOU20,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'distSubjectOU20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.distSubjectOU20">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.distSubjectOU20" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-4">

                    <div id="content" style="padding-left: 5px !important;padding-right: 0;">
                        <div class="titlebox"><b>Near Subj</b></div>
                        <div class="row col-12" style="padding: 0;margin: 0px;">
                            <table style="width: 100%">
                                <tr>
                                    <td style="width: 7%">
                                        <label><b>OD</b></label>
                                    </td>
                                    <td style="width: 38%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOD')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12 p-0" [items]="NearSubjLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOD,'OD',NearSubjLookup,'nearsubj',exam.omR_Exam,'nearSubjectOD',dbEnum.QE_DEV_SQL_DB)"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOD">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.nearSubjectOD" class="form-control" style="width: 99%;">
                                    </td>
                                    <td style="width: 5%">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width: 35%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOD20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12 p-0" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOD20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'nearSubjectOD20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOD20">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.nearSubjectOD20" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 7%">
                                        <label><b>OS</b></label>
                                    </td>
                                    <td style="width: 38%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOS')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12 p-0" [items]="NearSubjLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOS,'OS',NearSubjLookup,'NearSubj',exam.omR_Exam,'nearSubjectOS',dbEnum.QE_DEV_SQL_DB)"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOS">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.nearSubjectOS" class="form-control" style="width: 99%;">
                                    </td>
                                    <td style="width: 5%">
                                        <label><b>20/</b></label>
                                    </td>
                                    <td style="width: 35%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOS20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12 p-0" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOS20,'20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'nearSubjectOS20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOS20">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.nearSubjectOS20" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 7%">

                                    </td>
                                    <td style="width: 38%">
                                    </td>
                                    <td style="width: 5%">
                                        <label><b>OU 20/</b></label>
                                    </td>
                                    <td style="width: 35%">
                                        <!-- <ng-select (keyup)="keyFunc($event,exam.omR_Exam,'nearSubjectOU20')" addTagText="Add New" [addTag]="CreateNew"
                                            class="col-12 p-0" [items]="farNearLookup"
                                            (keydown.tab)="saveDropdown(exam.omR_Exam.nearSubjectOU20,'OU 20/',farNearLookup,'VisualAcuities',exam.omR_Exam,'nearSubjectOU20')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="exam.omR_Exam.nearSubjectOU20">
                                        </ng-select> -->
                                        <input type="text" [(ngModel)]="exam.omR_Exam.nearSubjectOU20" class="form-control" style="width: 99%;">
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="content pt-5"
            style="border:solid 1px black !important; padding-top: 0px !important; page-break-before: always;"> <!--padding-left: 25px !important;padding-right: 25px !important;-->
            <div class="row">
                <div class="col-3 p-1">
                    <div class="text-center">
                        <b class="px-4">External(Biomicroscopy)</b>
                    </div>
                    <div class="col-12 text-center">
                        <label class="justify-content-center"><b>Angle Est(Grade)</b></label>
                    </div>

                    <div>
                        <div class="form-row">
                            <div class="col-3">

                            </div>
                            <div class="col-1">
                                <label><b>OD</b></label>
                            </div>
                            <div class="col-7">
                                <!-- <div class="form-row">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'angleEstOD')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-10" [items]="angleEstLookup"
                                        (keydown.tab)="saveDropdown(exam.omR_Exam.angleEstOD,'OD',angleEstLookup,'AngleEst',exam.omR_Exam,'angleEstOD')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="exam.omR_Exam.angleEstOD">
                                    </ng-select>
                                </div> -->
                                <input type="text" [(ngModel)]="exam.omR_Exam.angleEstOD" class="form-control" style="width: 99%;">
                            </div>
                            <!-- <div class="col-3">
                            </div> -->
                        </div>
                    </div>
                    <div>
                        <div class="form-row">
                            <div class="col-3">

                            </div>
                            <div class="col-1">
                                <label><b>OS</b></label>
                            </div>
                            <div class="col-7">
                                <!-- <div class="form-row">
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'angleEstOS')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-10" [items]="angleEstLookup"
                                        (keydown.tab)="saveDropdown(exam.omR_Exam.angleEstOS,'OS',angleEstLookup,'AngleEst',exam.omR_Exam,'angleEstOS')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="exam.omR_Exam.angleEstOS">
                                    </ng-select>
                                </div> -->
                                <input type="text" [(ngModel)]="exam.omR_Exam.angleEstOS" class="form-control" style="width: 99%;">
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="form-row">
                            <div class="col-3">

                            </div>
                            <div class="col-6">
                                <table class="">
                                    <tr>
                                        <th><b style="margin-right:15px;">OD</b></th>
                                        <th class="mx-2"><b></b></th>
                                        <th class="mx-5 text-center"><b>OS</b></th>
                                    </tr>
                                    <tbody>

                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.tearsOD" type="checkbox"></td>
                                            <td class="text-center px-3"><label><b>Tears</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.tearsOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.lidsLashesOD" type="checkbox">
                                            </td>
                                            <td class="text-center px-2"><label><b>Lids/Lashes</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.lidsLashesOS"
                                                    type="checkbox">
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.corneaOD" type="checkbox"></td>
                                            <td class="text-center px-2"><label><b>Cornea</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.corneaOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.conjOD" type="checkbox"></td>
                                            <td class="text-center px-2"><label><b>Conj</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.conjOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.scleraOD" type="checkbox"></td>
                                            <td class="text-center px-2"><label><b>Sclera</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.scleraOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.antChmbOD" type="checkbox"></td>
                                            <td class="text-center px-2"><label><b>AntChmb</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.antChmbOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.lensOD" type="checkbox"></td>
                                            <td class="text-center px-2"><label><b>Lens</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.lensOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-center pr-3"><input [(ngModel)]="exam.omR_Exam.irisOD" type="checkbox"></td>
                                            <td class="text-center px-2"><label><b>Iris</b></label></td>
                                            <td><input class="mx-3" [(ngModel)]="exam.omR_Exam.irisOS"
                                                    type="checkbox"></td>
                                        </tr>
                                        <tr>
                                            <!-- <td></td> -->
                                            <td colspan="3" class="text-center"><small><b style="font-size: 10px;">*Checked is Normal</b></small>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-3">

                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 text-center" style="margin-left: 20px;">
                            <label><b>OD Notes</b></label>
                            <textarea type="text" [(ngModel)]="exam.omR_Exam.externalBiomicroscopyDesc"
                                class="form-control" rows="4" cols="50"></textarea>
                            <br>
                        </div>
                    </div> -->
                </div>
                <div class="col-6 p-1">
                    <div class="row">
                        <div class="col-6">
                            <label><b>Ext.OD</b></label>
                            <textarea [(ngModel)]="exam.omR_Exam.odEyeDisc" class="form-control"
                                rows="5"></textarea>
                        </div>
                        <!-- <div class="col-6 pt-4 text-center eyeDiv">
                            <app-canvas [width]="290" (onDraw)="setEXTImage($event)" [height]="115"
                                [imageSRC]="imageEXTSRC" [imageHeight]="115" [imageWidth]="288"
                                [defaultImage]="defaultImageEXTSRC">
                            </app-canvas>
                        </div> -->
                        <div class="col-6">
                            <label><b>Ext.OS</b></label>
                            <textarea [(ngModel)]="exam.omR_Exam.osEyeDisc" class="form-control"
                                rows="5"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <label><b>Int.OD</b></label>
                            <textarea [(ngModel)]="exam.omR_Exam.odEyeDisc2" class="form-control"
                                rows="5"></textarea>
                        </div>
                        <!-- <div class="col-6 pt-4 text-center eyeDiv">
                            <app-canvas [width]="290" (onDraw)="setINTImage($event)" [height]="115"
                                [imageSRC]="imageINTSRC" [imageHeight]="115" [imageWidth]="290"
                                [defaultImage]="defaultImageINTSRC">
                            </app-canvas>
                        </div> -->
                        <div class="col-6">
                            <label><b>Int.OS</b></label>
                            <textarea [(ngModel)]="exam.omR_Exam.osEyeDisc2" class="form-control"
                                rows="5"></textarea>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 text-center">
                            <b>Gonioscopy</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-center">
                            <div class="row">
                                <div class="col-6">

                                </div>
                                <div class="col-6">
                                    <b>OD</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 text-center">
                            <div class="row">
                                <div class="col-6">
                                    <b>OS</b>
                                </div>
                                <div class="col-6">

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-row">
                                <div class="col-6">

                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioODTop"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/forwardslash.png"
                                        style="width: 100% !important;" />
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6">

                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: 0px !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioODLeft"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/crossslash.png" style="width: 100% !important;" />
                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: 0px !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioODRight"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-6">

                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/forwardslash.png"
                                        style="width: 100% !important;" />
                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioODBottom"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-row">
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioOSTop"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/forwardslash.png"
                                        style="width: 100% !important;" />
                                </div>
                                <div class="col-6">

                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: auto !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioOSLeft"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/crossslash.png" style="width: 100% !important;" />
                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: auto !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioOSRight"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-6">

                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/forwardslash.png"
                                        style="width: 100% !important;" />
                                </div>
                                <div class="col-2 text-center"
                                    style="padding: 0px !important; margin: auto !important;">
                                    <textarea [(ngModel)]="exam.omR_Exam.gonioOSBottom"
                                        style="width: 47px !important; height: 50px !important; font-size: 14px !important; overflow: hide !important;"></textarea>
                                </div>
                                <div class="col-2" style="padding: 0px !important; margin: 0px !important;">
                                    <img src="../../../assets/img/backslash.png" style="width: 100% !important;" />
                                </div>
                                <div class="col-6">

                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-3">
                    <div class="mx-3">
                        <b>Internal(Ophthalmoscopy)</b>
                    </div>
                    <div class="col-12 text-center">
                        <label class="justify-content-center"><b></b></label>
                    </div>

                    <div class=" col-12 mx-3 pl-0 pr-0 text-center">
                        <div class="form-row" style="margin-bottom:10px !important;">
                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'internalOphtmalmoscopy')" addTagText="Add New"
                                [addTag]="CreateNew" class="col-10" [items]="internalLookup"
                                (keydown.tab)="saveDropdown(exam.omR_Exam.internalOphtmalmoscopy,'Internal(Ophthalmoscopy)',internalLookup,'io',exam.omR_Exam,'internalOphtmalmoscopy')"
                                bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="exam.omR_Exam.internalOphtmalmoscopy">
                            </ng-select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="col-1">

                        </div>
                        <div class="col-10">
                            <table class="" style="width: 100%;">
                                <tr>
                                    <th class="text-center"><b style="margin-left: -5px;">OD</b></th>
                                    <th class="px-2"><b></b></th>
                                    <th><b style="margin-left:10px;">OS</b></th>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-row p-0"
                                                style="width: 120px;margin-left: -22px;margin-right: -40px;">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'cdod')"
                                                    addTagText="Add New" [addTag]="CreateNew"
                                                    style="font-size: 12px !important" class="col-10 p-0"
                                                    [items]="internalODOS"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.cdod,'OD',internalODOS,'internalodos',exam.omR_Exam,'cdod')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.cdod">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <th class="text-center"><label><b>C/D</b></label></th>
                                        <td>
                                            <div class="form-row p-0" style="width: 120px;margin-left: -25px;">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'cdos')"
                                                    addTagText="Add New" [addTag]="CreateNew"
                                                    style="font-size: 12px !important" class="col-10 p-0"
                                                    [items]="internalODOS"
                                                    (keydown.tab)="saveDropdown(exam.omR_Exam.cdos,'OS',internalODOS,'internalodos',exam.omR_Exam,'cdos')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="exam.omR_Exam.cdos">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.discMarginsOD"></td>
                                        <td class="text-center px-2"><label><b>Disc Margins</b></label></td>
                                        <td><input class="mx-4" type="checkbox"
                                                [(ngModel)]="exam.omR_Exam.discMarginsOS"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.maculaOD">
                                        </td>
                                        <td class="text-center px-2"><label><b>Macula</b></label></td>
                                        <td> <input class="mx-4" type="checkbox"
                                                [(ngModel)]="exam.omR_Exam.maculaOS">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.vesselsOD"></td>
                                        <td class="text-center px-2"><label><b>Vessels</b></label></td>
                                        <td><input class="mx-4" type="checkbox"
                                                [(ngModel)]="exam.omR_Exam.vesselsOS"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.nflod"></td>
                                        <td class="text-center px-2"><label><b>NFL</b></label></td>
                                        <td><input class="mx-4" type="checkbox" [(ngModel)]="exam.omR_Exam.nflos">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.vitreousOD"></td>
                                        <td class="text-center px-2"><label><b>Vitreous</b></label></td>
                                        <td><input class="mx-4" type="checkbox"
                                                [(ngModel)]="exam.omR_Exam.vitreousOS"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center"><input type="checkbox" [(ngModel)]="exam.omR_Exam.periphRetOD"></td>
                                        <td class="text-center px-2"><label><b>Periph Ret</b></label></td>
                                        <td><input class="mx-4" type="checkbox"
                                                [(ngModel)]="exam.omR_Exam.periphRetOS"></td>
                                    </tr>
                                    <tr>
                                        <!-- <td></td> -->
                                        <td colspan="3" class="text-center"><small><b style="font-size: 10px;">*Checked is Normal</b></small>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <div class="col-2">

                        </div> -->
                    </div><br>
                    <!-- <div class="row">
                        <div class="col-12 text-center pt-5"  style="margin-left: -10px;">
                            <label><b>OS Notes</b></label>
                            <textarea type="text" [(ngModel)]="exam.omR_Exam.internalOphtmalmoscopyDesc"
                                class="form-control" rows="4" cols="50"></textarea>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-3 text-center">
                    <label><b>OD Notes</b></label>
                        <textarea type="text" [(ngModel)]="exam.omR_Exam.externalBiomicroscopyDesc"
                            class="form-control ml-3 mb-3" rows="4" cols="50"></textarea>
                </div>
                <div class="col-6">

                </div>
                <div class="col-3 text-center">
                    <label><b>OS Notes</b></label>
                    <textarea type="text" [(ngModel)]="exam.omR_Exam.internalOphtmalmoscopyDesc"
                        class="form-control" rows="4" cols="50" style="width: 95%;"></textarea>
                </div>
            </div>
        </div>
            <table>
                <tr>
                    <td>
                        <div class="row py-4">
                            <div class="col-10">
                                <div class="row">
                                    <div class="col-6">
                                        <div id="content" style="padding-left: 5px !important;padding-right: 5px;">
                                            <div class="titlebox"><b>Tonometry</b></div>
                                            <div class="row col-12 m-0 p-0">
                                                <div class="col-4 pl-1">
                                                    <div class="form-row">
                                                        <!-- <div class="col-4 p-0">
                                                            <label><b>Time</b></label>
                                                        </div>
                                                        <div class="col-8">
                                                            <input type="text" [(ngModel)]="exam.omR_Exam.tonometryTime"
                                                                class="form-control">
                                                        </div> -->
                                                        <label class="col-4 p-0"><b>Time</b></label>
                                                        <input type="text" [(ngModel)]="exam.omR_Exam.tonometryTime"
                                                                class="form-control col-8">
                                                    </div>
                                                </div>
                                                <div class="col-4 pl-1">
                                                    <div class="form-row">
                                                        <!-- <div class="col-2">
                                                            <label><b>OD</b></label>
                                                        </div>
                                                        <div class="col-9">
                                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tonometryOD')"
                                                                (keypress)="numberOnly($event)" addTagText="Add New"
                                                                [addTag]="CreateNew" class="auto-grow"
                                                                class="col-12 p-1" ng-maxlength="15"
                                                                style="border-radius: 10px;" [items]="tonometryosod"
                                                                (keydown.tab)="saveDropdown(exam.omR_Exam.tonometryOD,'OD',tonometryosod,'tonometryosod',exam.omR_Exam,'tonometryOD',dbEnum.QE_DEV_SQL_DB)"
                                                                bindLabel="name_txt" bindValue="name_txt"
                                                                [(ngModel)]="exam.omR_Exam.tonometryOD"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                        </div>-->
                                                        <label class="col-2 p-0"><b>OD</b></label>
                                                        <input type="text" [(ngModel)]="exam.omR_Exam.tonometryOD"
                                                                class="form-control col-9">
                                                    </div>
                                                </div>
                                                <div class="col-4 pl-1" style="padding-right: 8px;">
                                                    <div class="form-row">
                                                        <!-- <div class="col-2">
                                                            <label><b>OS</b></label>
                                                        </div>
                                                        <div class="col-9">
                                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tonometryOS')"
                                                                addTagText="Add New" [addTag]="CreateNew"
                                                                (keypress)="numberOnly($event)" class="auto-grow"
                                                                class="col-12 p-1" style="border-radius: 10px;"
                                                                [items]="tonometryosod"
                                                                (keydown.tab)="saveDropdown(exam.omR_Exam.tonometryOS,'OS',tonometryosod,'tonometryosod',exam.omR_Exam,'tonometryOS',dbEnum.QE_DEV_SQL_DB)"
                                                                bindLabel="name_txt" bindValue="name_txt"
                                                                [(ngModel)]="exam.omR_Exam.tonometryOS"
                                                                [ngModelOptions]="{standalone: true}">
                                                            </ng-select>
                                                        </div>-->
                                                        <label class="col-2 p-0"><b>OS</b></label>
                                                        <input type="text" [(ngModel)]="exam.omR_Exam.tonometryOS"
                                                                class="form-control col-9">
                                                    </div>
                                                </div>
                                            </div><br>
                                            <div class="col-12 p-0">
                                                <div class="form-row">
                                                    <div class="col-1">
                                                        <label><b>Test</b></label>
                                                    </div>
                                                    <div class="col-10" style="padding-left: 20px;">
                                                        <div class="form-row">
                                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tonometry')"
                                                                addTagText="Add New" [addTag]="CreateNew"
                                                                class="col-10" [items]="tonometry"
                                                                (keydown.tab)="saveDropdown(exam.omR_Exam.tonometry,'Test',tonometry,'Tonometry',exam.omR_Exam,'tonometry')"
                                                                bindLabel="name_txt" bindValue="name_txt"
                                                                [(ngModel)]="exam.omR_Exam.tonometry">
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 pl-0">
                                        <div id="content" style="padding-left: 5px !important;">
                                            <div class="titlebox"><b>KER</b></div>
                                            <br>
                                            <div class="row">
                                                <div class="col-1">
                                                    <label><b>OD</b></label>
                                                </div>
                                                <!-- <div class="col-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD1" type="text"
                                                        class="form-control">
                                                </div>
                                                <label class="col-1"><b>OD</b></label>-->
                                                <input [(ngModel)]="exam.omR_Exam.keraoD1" type="text"
                                                        class="form-control col-2 mx-2 pl-1 pr-0">
                                                <label><b>@</b></label>
                                                <!-- <div class="col-1">
                                                    <label><b>@</b></label>
                                                </div> -->
                                                <!-- <div class="col-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD1Axis" type="text"
                                                        class="form-control">
                                                </div> -->
                                                <input [(ngModel)]="exam.omR_Exam.keraoD1Axis" type="text"
                                                        class="form-control col-2 mx-2 pl-1 pr-0">
                                                <label><b><></b></label>
                                                <!-- <div class="col-1">
                                                    <label>
                                                        <b>
                                                            <>
                                                        </b>
                                                    </label>
                                                </div> -->
                                                <!-- <div class="col-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD2" type="text"
                                                        class="form-control">
                                                </div> -->
                                                <input [(ngModel)]="exam.omR_Exam.keraoD2" type="text"
                                                        class="form-control col-2 mx-2 pl-1 pr-0">
                                                <label><b>@</b></label>
                                                <!-- <div class="col-1">
                                                    <label><b>@</b></label>
                                                </div> -->
                                                <input [(ngModel)]="exam.omR_Exam.keraoD2Axis" type="text"
                                                        class="form-control col-2 ml-2 pl-1 pr-0">
                                                <!-- <div class="col-2">
                                                    <input [(ngModel)]="exam.omR_Exam.keraoD2Axis" type="text"
                                                        class="form-control">
                                                </div> -->
                                            </div>
                                            <div class="row">
                                                <div class="col-1">
                                                    <label><b>OS</b></label>
                                                </div>
                                                <input [(ngModel)]="exam.omR_Exam.keraoS1" type="text"
                                                        class="form-control col-2 mx-2 pl-1 pr-0">
                                                <label><b>@</b></label>
                                                <input [(ngModel)]="exam.omR_Exam.keraoS1Axis" type="text"
                                                        class="form-control col-2 mx-2 pl-1 pr-0">
                                                <label><b><></b></label>
                                                <input [(ngModel)]="exam.omR_Exam.keraoS2" type="text"
                                                        class="form-control col-2 mx-2 pl-1 pr-0">
                                                <label><b>@</b></label>
                                                <input [(ngModel)]="exam.omR_Exam.keraoS2Axis" type="text"
                                                        class="form-control col-2 ml-2 pl-1 pr-0">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 pt-4 p-0">
                                    <div id="content">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="titlebox"></div>
                                                <b>Assessment:</b>
                                                <!-- <button class="btn btn-primary mx-2 px-4"
                                                    (click)="addAssessment()"><b>Add</b></button> -->
                                            </div>
                                            <div class="col-4"> <b>Plan:</b> </div>
                                        </div>
                                        <div class="row pt-2"
                                            *ngFor="let assesment of exam.exam_Assessments;let i = index;">
                                            <div class="col-6 pt-2">
                                                <div class="form-row">
                                                    <div class="col-1">
                                                        <label class="pt-1"><b>#</b>{{i+1}}</label>
                                                    </div>
                                                    <div class="col-11">
                                                        <div class="form-row">
                                                            <div class="col-10">
                                                                <div class="form-row">
                                                                    <ng-select [clearable]="true"
                                                                        (keyup)="keyFunc($event,assesment,'assessment')" addTagText="Add New"
                                                                        [addTag]="CreateNew" class="col-10"
                                                                        [items]="assessment"
                                                                        (keydown.tab)="saveDropdown(assesment.assessment,'#',assessment,'assessment',assesment,'assessment',dbEnum.QE_DEV_SQL_DB)"
                                                                        bindLabel="name_txt" bindValue="name_txt"
                                                                        [(ngModel)]="assesment.assessment">
                                                                    </ng-select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="pt-2"
                                                    *ngFor="let assesmentPlan of assesment.exam_Assessment_Plans;">
                                                    <div class="form-row">
                                                        <div class="col-1">
                                                            <label class="pt-1"><b>#</b>{{i+1}}</label>
                                                        </div>
                                                        <div class="col-11">
                                                            <div class="form-row col-12">
                                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,assesmentPlan,'assessment_Plan')"
                                                                    addTagText="Add New" [addTag]="CreateNew"
                                                                    class="col-9" [items]="plan"
                                                                    (keydown.tab)="saveDropdown(assesmentPlan.assessment_Plan,'plan',plan,'plan',assesmentPlan,'assessment_Plan',dbEnum.QE_DEV_SQL_DB)"
                                                                    bindLabel="name_txt" bindValue="name_txt"
                                                                    [(ngModel)]="assesmentPlan.assessment_Plan">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <label><b>Other Assessment:</b></label>
                                                <textarea [(ngModel)]="exam.omR_Exam.otherAssessment"
                                                    class="form-control"></textarea>
                                            </div>
                                            <div class="col-6">
                                                <label><b>Other Plan:</b></label>
                                                <textarea [(ngModel)]="exam.omR_Exam.otherPlan"
                                                    class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div id="content" style="padding-left: 5px !important;padding-right: 5px;">
                                    <div class="titlebox"><b>DPA</b></div>
                                    <div class="col-12 p-0">
                                        <table style="width: 100%">
                                            <tr>
                                                <td style="width: 30%">
                                                    <label><b>Time</b></label>
                                                </td>
                                                <td>
                                                    <!-- {{exam.omR_Exam.dpaTime}} -->
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.dpaTime"
                                                        class="form-control">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 30%">
                                                    <label><b>Tropic</b></label>
                                                </td>
                                                <td>
                                                    <!-- {{exam.omR_Exam.tropic}} -->
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.tropic"
                                                        class="form-control">
                                                    <!-- <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'tropic')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-12"
                                                            [items]="tropicLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.tropic,'Tropic',tropicLookup,'Tropic',exam.omR_Exam,'tropic')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.tropic">
                                                        </ng-select>
                                                    </div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 30%">
                                                    <label><b>Phenyl</b></label>
                                                </td>
                                                <td>
                                                    <!-- {{exam.omR_Exam.phenyl}} -->
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.phenyl"
                                                        class="form-control">
                                                    <!-- <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'phenyl')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                                            [items]="phenylLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.phenyl,'Phenyl',phenylLookup,'Phenyl',exam.omR_Exam,'phenyl')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.phenyl">
                                                        </ng-select>
                                                    </div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 30%">
                                                    <label><b>Pared</b></label>
                                                </td>
                                                <td>
                                                    <!-- {{exam.omR_Exam.pared}} -->
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.pared"
                                                        class="form-control">
                                                    <!-- <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'pared')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                                            [items]="ParedLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.pared,'Pared',ParedLookup,'Pared',exam.omR_Exam,'pared')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.pared">
                                                        </ng-select>
                                                    </div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="width: 30%">
                                                    <label><b>Cyclo</b></label>
                                                </td>
                                                <td>
                                                    <!-- {{exam.omR_Exam.cyclo}} -->
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.cyclo"
                                                        class="form-control">
                                                    <!-- <div class="form-row">
                                                        <ng-select [clearable]="true" (keyup)="keyFunc($event,exam.omR_Exam,'cyclo')"
                                                            addTagText="Add New" [addTag]="CreateNew" class="col-10"
                                                            [items]="cycloLookup"
                                                            (keydown.tab)="saveDropdown(exam.omR_Exam.cyclo,'Cyclo',cycloLookup,'Cyclo',exam.omR_Exam,'cyclo')"
                                                            bindLabel="name_txt" bindValue="name_txt"
                                                            [(ngModel)]="exam.omR_Exam.cyclo">
                                                        </ng-select>
                                                    </div> -->
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.fluora"
                                                        class="mx-2"><b>Fluora</b></label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.propara"
                                                        class="mx-2"><b>Propara</b></label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <label><input type="checkbox"
                                                        [(ngModel)]="exam.omR_Exam.dpaOtherCheckBox"
                                                        class="mx-2"><b>Other </b></label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.dpaOtherTextBox"
                                                        class="form-control">
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <!-- <div class="mr-2 p-0">
                                        <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.fluora"
                                                class="mx-2"><b>Fluora</b></label>
                                    </div>
                                    <div class="mr-2 p-0">
                                        <label><input type="checkbox" [(ngModel)]="exam.omR_Exam.propara"
                                                class="mx-2"><b>Propara</b></label>
                                    </div>
                                    <div class="mr-2 p-0">
                                        <table style="width: 100%">
                                            <tr>
                                                <td style="width:45%">
                                                    <label><input type="checkbox"
                                                            [(ngModel)]="exam.omR_Exam.dpaOtherCheckBox"
                                                            class="mx-2"><b>Other </b></label>
                                                </td>
                                                <td>
                                                    <input type="text" [(ngModel)]="exam.omR_Exam.dpaOtherTextBox"
                                                        class="form-control">
                                                </td>
                                            </tr>
                                        </table>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <ng-select [clearable]="true" [(ngModel)]="exam.provider_ID"
                                    placeholder="--- Select ---" #myUserSelect
                                    (change)="onExamSelectUser(exam.provider_ID)">
                                    <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                        {{userInfo.display_txt}}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-4" style="height: 120px !important;">
                                <img [src]="examimgURL" height="120px">
                            </div>
                            <div class="col-4">
                                <!-- <label><b>Name :</b> {{examuser.first_name_txt + ' ' + examuser.last_name_txt}}</label> -->
                                <label><b>Doctor Name :</b> {{examuser.display_txt}}</label>
                            </div>
                            <div class="col-4">
                                <label><b>License #:</b> {{examuser.license_txt}}</label>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <!-- <div>
                <div id="content" class="mt-4" #progressNotePanel>
                    <div class="titlebox"><b>Trial Contacts/Progress Notes</b></div>
                    <br>
                    <div class="row">
                        <div class="col-3">
                            <div class="form-row">
                                <div class="col-2">
                                    <label><b>Date:</b></label>
                                </div>
                                <div class="col-10">
                                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                        (ngModelChange)="omr_ProgressNotes.progressDate=$event"
                                        [ngModel]="omr_ProgressNotes.progressDate | date: 'MM-dd-yyyy'"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="form-row">
                                <div class="col-2">
                                    <label><b>Patient Comment:</b></label>
                                </div>
                                <div class="col-10">
                                    <input type="text" [(ngModel)]="omr_ProgressNotes.patientComments"
                                        style="background-color: blue !important; color: white !important;"
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <table class="table" style="font-size: 14px !important; width: 150% !important; ">
                                <tr>
                                    <th style="width:3%"></th>
                                    <th class="text-left" style="width:13%">Power</th>
                                    <th class="text-center" style="width:12%">BC</th>
                                    <th class="text-center" style="width:12%">DIAM</th>
                                    <th class="text-left" style="width:28%">Brand</th>
                                    <th class="text-center" style="width:3%">VA:</th>
                                    <th class="text-center" style="width:15%">Far</th>
                                    <th class="text-center" style="width:15%">Near</th>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td><b>OD:</b></td>
                                        <td><input type="text" [(ngModel)]="omr_ProgressNotes.odPower"
                                                class="form-control">
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odbc')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="bcLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odbc,'Trial Contacts/Progress Notes',bcLookup,'bc',omr_ProgressNotes,'odbc',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odbc">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'oddiam')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="diamLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.oddiam,'Trial Contacts/Progress Notes',diamLookup,'diam',omr_ProgressNotes,'oddiam',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.oddiam">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odBrand')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    style="width: 90px !important;" [items]="brandLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odBrand,'Trial Contacts/Progress Notes',brandLookup,'brand',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odBrand">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td><b>20/</b></td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaodFar')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaodFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaodFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaodFar">
                                                </ng-select>
                                            </div>

                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaodNear')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaodNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaodNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaodNear">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OS:</b></td>
                                        <td><input type="text" [(ngModel)]="omr_ProgressNotes.osPower"
                                                class="form-control">
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osbc')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="bcLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osbc,'Trial Contacts/Progress Notes',bcLookup,'bc',omr_ProgressNotes,'osbc',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osbc">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osdiam')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="diamLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osdiam,'Trial Contacts/Progress Notes',diamLookup,'diam',omr_ProgressNotes,'osdiam',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osdiam">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osBrand')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="brandLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osBrand,'Trial Contacts/Progress Notes',brandLookup,'brand',omr_ProgressNotes,'osBrand',dbEnum.QE_DEV_SQL_DB)"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osBrand">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td><b>20/</b></td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaosFar')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaosFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaosFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaosFar">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaosNear')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaosNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaosNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaosNear">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OS:</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>20</b></td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaouFar')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaouFar,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaouFar')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaouFar">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'vaouNear')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-9"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.vaouNear,'Trial Contacts/Progress Notes',farNearLookup,'VisualAcuities',omr_ProgressNotes,'vaouNear')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.vaouNear">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-7">
                            <div class="col-12">
                                <div class="form-row">
                                    <label><b>Slit Lamp Exam:</b></label>
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'slitLampExaminationSelect')" addTagText="Add New"
                                        [addTag]="CreateNew" class="col-10" [items]="slitLampExamLookup"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.slitLampExaminationSelect,'Slit Lamp Exam',slitLampExamLookup,'slitlampexam',omr_ProgressNotes,'slitLampExaminationSelect',dbEnum.QE_DEV_SQL_DB)"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.slitLampExaminationSelect"
                                        (change)="slitLampOtherEnableDisable()">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <label><b>Other:</b></label>
                                <input type="text" [disabled]="slitLampOtherDisable"
                                    [(ngModel)]="omr_ProgressNotes.slitLampExamination" class="form-control">
                            </div>
                        </div>
                        <div class="col-5 p-0">
                            <table class="table" style="font-size: 14px !important;">
                                <tr>
                                    <th style="width: 5%"></th>
                                    <th style="width: 40%">Over-refraction</th>
                                    <th class="text-center pl-0" style="width:5%">VA:</th>
                                    <th class="text-center pl-0" style="width:20%">Far</th>
                                    <th class="text-center pl-0" style="width:20%">Near</th>
                                </tr>
                                <tbody>
                                    <tr>
                                        <td style="width: 3%"><b>OD:</b></td>
                                        <td style="width: 40%"><input type="text"
                                                [(ngModel)]="omr_ProgressNotes.overOD2" class="form-control">
                                        </td>
                                        <td class="pl-0"><b>20/</b></td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odvaFar2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-10" style="padding-left: 2px; padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'odvaFar2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odvaFar2">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'odvaNear2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-10" style="padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.odvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'odvaNear2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.odvaNear2">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OS:</b></td>
                                        <td><input [(ngModel)]="omr_ProgressNotes.overOS2" type="text"
                                                class="form-control">
                                        </td>
                                        <td class="pl-0"><b>20/</b></td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osvaFar2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-10" style="padding-left: 2px; padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'osvaFar2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osvaFar2">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'osvaNear2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-10" style="padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.osvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'osvaNear2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.osvaNear2">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>OU:</b></td>
                                        <td><input [(ngModel)]="omr_ProgressNotes.overOU2" type="text"
                                                class="form-control">
                                        </td>
                                        <td class="pl-0"><b>20/</b></td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'ouvaFar2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-10" style="padding-left: 2px; padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.ouvaFar2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'ouvaFar2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.ouvaFar2">
                                                </ng-select>
                                            </div>
                                        </td>
                                        <td class="pl-0 pr-0">
                                            <div class="form-row">
                                                <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'ouvaNear2')"
                                                    addTagText="Add New" [addTag]="CreateNew" class="col-10" style="padding-right: 2px;"
                                                    [items]="farNearLookup"
                                                    (keydown.tab)="saveDropdown(omr_ProgressNotes.ouvaNear2,'20/',farNearLookup,'VisualAcuities',omr_ProgressNotes,'ouvaNear2')"
                                                    bindLabel="name_txt" bindValue="name_txt"
                                                    [(ngModel)]="omr_ProgressNotes.ouvaNear2">
                                                </ng-select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12">
                        <label><b>Assessment:</b></label>
                        <label style="margin-left:350px;"><b>Plan:</b></label>
                        <div class="row">
                            <div class="col-3">
                                <div class="row">
                                    <label class="col-1 col-form-label"><b>#1</b></label>
                                    <div class="form-row col-11">
                                        <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment1')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-11"
                                            [items]="tcAssessment"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment1,'#1',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment1')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.assessment1">
                                        </ng-select>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 p-0">
                                <div class="row">
                                    <div class="col-2">
                                        <label><b>Plan For#</b></label>
                                    </div>

                                    <select [(ngModel)]="omr_ProgressNotes.planNumber1"
                                        class="form-control col-1 p-0">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <div class="col-6">
                                        <div class="row">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan1')" addTagText="Add New"
                                                [addTag]="CreateNew" class="col-10" [items]="tcplanLookup"
                                                (keydown.tab)="saveDropdown(omr_ProgressNotes.plan1,'#1',tcplanLookup,'tcplan',omr_ProgressNotes,'plan1')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_ProgressNotes.plan1">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-0">
                                <div class="row">
                                    <label><input [(ngModel)]="omr_ProgressNotes.gaveCLRX" type="checkbox"
                                            (change)="checkBox($event)" class="mx-2"><b>Gave CLRX</b></label>
                                </div>

                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-3">
                                <div class="row">
                                    <label class="col-1 col-form-label"><b>#2</b></label>
                                    <div class="form-row col-11">
                                        <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment2')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-11"
                                            [items]="tcAssessment"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment2,'#2',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment2')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.assessment2">
                                        </ng-select>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 p-0">
                                <div class="row">
                                    <div class="col-2 p-0"></div>
                                    <select [(ngModel)]="omr_ProgressNotes.planNumber2"
                                        class="form-control col-1 p-0">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <div class="col-6">
                                        <div class="row">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan2')" addTagText="Add New"
                                                [addTag]="CreateNew" class="col-10" [items]="tcplanLookup"
                                                (keydown.tab)="saveDropdown(omr_ProgressNotes.plan2,'#2',tcplanLookup,'tcplan',omr_ProgressNotes,'plan2')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_ProgressNotes.plan2">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-0">
                                <div class="row">
                                    <label class="pt-1"><input [(ngModel)]="omr_ProgressNotes.cleanQHS" type="checkbox"
                                            class="mx-2"><b>Clean QHS with</b></label>
                                    <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'cleanQHSText')" addTagText="Add New"
                                        [addTag]="CreateNew" [items]="cleanQHSLookup" class="col-4 p-0"
                                        (keydown.tab)="saveDropdown(omr_ProgressNotes.cleanQHSText,'Clean QHS with',cleanQHSLookup,'CleanQHS',omr_ProgressNotes,'cleanQHSText')"
                                        bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="omr_ProgressNotes.cleanQHSText">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-3">
                                <div class="row">
                                    <label class="col-1 col-form-label"><b>#3</b></label>
                                    <div class="form-row col-11">
                                        <ng-select class="auto-grow" [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'assessment3')"
                                            addTagText="Add New" [addTag]="CreateNew" class="col-11"
                                            [items]="tcAssessment"
                                            (keydown.tab)="saveDropdown(omr_ProgressNotes.assessment3,'#3',tcAssessment,'TCAssessment',omr_ProgressNotes,'assessment3')"
                                            bindLabel="name_txt" bindValue="name_txt"
                                            [(ngModel)]="omr_ProgressNotes.assessment3">
                                        </ng-select>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6 p-0">
                                <div class="row">
                                    <div class="col-2 p-0">
                                    </div>

                                    <select [(ngModel)]="omr_ProgressNotes.planNumber3"
                                        class="form-control col-1 p-0">
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                    </select>
                                    <div class="col-6">
                                        <div class="row">
                                            <ng-select [clearable]="true" (keyup)="keyFunc($event,omr_ProgressNotes,'plan3')" addTagText="Add New"
                                                [addTag]="CreateNew" class="col-10" [items]="tcplanLookup"
                                                (keydown.tab)="saveDropdown(omr_ProgressNotes.plan3,'#3',tcplanLookup,'tcplan',omr_ProgressNotes,'plan3')"
                                                bindLabel="name_txt" bindValue="name_txt"
                                                [(ngModel)]="omr_ProgressNotes.plan3">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 p-0">
                                <div class="row">
                                    <label><input [(ngModel)]="omr_ProgressNotes.orderContacts" type="checkbox"
                                            (change)="checkBox($event)" class="mx-2"><b>Order Contacts</b></label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <label><b>Other Assessment:</b></label>
                            <textarea [(ngModel)]="omr_ProgressNotes.otherAssessment" class="form-control"></textarea>
                        </div>
                        <div class="col-4">
                            <label><b>Other Plan:</b></label>
                            <textarea [(ngModel)]="omr_ProgressNotes.otherPlan" class="form-control"></textarea>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-8">
                            <div class="col-12">
                                <ng-select [clearable]="true" placeholder="--- Select ---" #myUserSelect
                                    (change)="onSelectUser(omr_ProgressNotes.provider_ID)"
                                    [(ngModel)]="omr_ProgressNotes.provider_ID">
                                    <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                        {{userInfo.display_txt}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="col-12" style="height: 120px !important;">
                                <img [src]="imgURL" height="120px">
                            </div>
                            <div class="col-12">
                                <label><b>Doctor Name :</b> {{user.display_txt}}</label>
                            </div>
                            <div class="col-md-12">
                                <label><b>License :</b> {{user.license_txt}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content" class="mt-4">
                <div class="titlebox"><b>Trial Contacts/Progress Notes</b></div>
                <div class="pt-2">
                    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" (rowDoubleClicked)="setProgressData($event)"
                        style="height: 500px; width: 100%;" class="ag-theme-alpine" [rowData]="rowNote"
                        [columnDefs]="columnNote">
                    </ag-grid-angular>
                </div>
            </div> -->
        </div>
    </div>
</div>
