import { PatientInsuranceModel } from './../model/patientInsuranceModel';
import { AdvancePatientSearchModel } from './../model/AdvancePatientSearchModel';
import { environment } from './../../environments/environment';
import { PatientModel } from 'src/app/model/patientModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http:HttpClient) { }
  getPatientList(query:string,rowSize?:number,pageSize?:number):Observable<PatientModel[]>
    {
      return this.http.get<PatientModel[]>(environment.server+'api/dashboard/getallpatient?query='+query+'&&rowSize='+rowSize+'&&pageSize='+pageSize);
    }
    getPatientListSearch(Firstname:string,Lastname:string):Observable<PatientModel>
    {      
      return this.http.get<PatientModel>(environment.server+'api/dashboard/getallpatientlist?FirstName='+Firstname+'&&LastName='+Lastname);
    }
    getallPatientList(query:string):Observable<PatientModel[]>
    {
      return this.http.get<PatientModel[]>(environment.server+'api/dashboard/getallpatient?query='+query);
    }
  getPatientByPatientId(patientId:number):Observable<PatientModel>
  {
    return this.http.get<PatientModel>(environment.server+'api/patient/getbyid?id='+patientId);
  }
  GetNewPatientAccountNumber(practiceId: string):Observable<string>
  {
    return this.http.get<string>(environment.server+'api/patient/getpatientnumber?id='+practiceId);
  }
  getPatientPatientId(patientId:string):Observable<PatientModel>
  {
    return this.http.get<PatientModel>(environment.server+'api/patient/getbypatientid?id='+patientId);
  }

  addPatient(patientModel:PatientModel, practiceId: string):Observable<PatientModel>
  {
    let patient = {
      patientModel : patientModel,
      practiceId : practiceId
    }
    return this.http.post<PatientModel>(environment.server+'api/Patient/add',patient);
  }
  // addPatient(patientModel:PatientModel):Observable<PatientModel>
  // {    
  //   return this.http.post<PatientModel>(environment.server+'api/Patient/add',patientModel);
  // }

  updatePatient(patientModel:PatientModel):Observable<PatientModel>
  {
    return this.http.post<PatientModel>(environment.server+'api/Patient/update',patientModel);
  }

  advancePatientSearch(advancePatientSearchModel : AdvancePatientSearchModel):Observable<PatientModel>{
    return this.http.post<PatientModel>(environment.server+'api/Patient/advancepatientsearch',advancePatientSearchModel);
  }

  getPatientInsurance():Observable<PatientInsuranceModel>{
    return this.http.get<PatientInsuranceModel>(environment.server+'api/Patient/getallpatientinsurance');
  }

}
