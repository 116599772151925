import { AdministrationModule } from './../../components/administration/administration.module';
import { ComponentModalComponent } from './../component-modal/component-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { EditLookupComponent } from './../edit-lookup/edit-lookup.component';
import { ModalComponent } from './../modal/modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupModalComponent } from '../lookup-modal/lookup-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { PatientModule } from 'src/app/components/patient/patient.module';
import { InactivityModalComponent } from './../inactivity-modal/inactivity-modal.component';

@NgModule({
  imports: [
    CommonModule,FormsModule,AgGridModule.withComponents([]),NgSelectModule,AdministrationModule,PatientModule
  ],
  declarations: [ModalComponent,LookupModalComponent,EditLookupComponent,ComponentModalComponent, InactivityModalComponent],
  exports:[ModalComponent,LookupModalComponent,EditLookupComponent,ComponentModalComponent, InactivityModalComponent]
})
export class SharedModule { }
