import { PatientCareTeamModel } from "./patientCareTeamModel";
import { PatientIdentifierModel } from "./patientIdentifierModel";
import { PatientInsuranceModel } from "./patientInsuranceModel";
import { PatientRaceModel } from "./patientRaceModel";

export class PatientModel {
    patientID: number = 0;
    lastName: string = '';
    firstName: string = '';
    middleInitial: string = '';
    address: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    dateOfBirth!: Date;
    sex: string = '';
    dateOfEntry: Date = new Date();
    homePhone: string = '';
    workPhone: string = '';
    cellPhone: string = '';
    employer: string = '';
    occupation: string = '';
    insurance: string = '';
    insuranceIDNumber: string = '';
    creationDate: Date = new Date();
    lastUpdateDate: Date = new Date();
    patientAcctNo: string = '';
    address2: string = '';
    isArchived: boolean = false;
    visionInsurance: string = '';
    visionInsuranceId: string = '';
    insuredName: string = '';
    insuredBirthdate!: Date;
    insuredSSN: string = '';
    relationshipToInsured: string = '';
    referedBy: string = '';
    referedByName: string = '';
    responsibleName: string = '';
    responsibleAddress: string = ''
    responsibleBirthdate!: Date;
    responsiblePhone: string = '';
    race: string = '';
    marriageStatus: number = 0;
    employed: boolean = false;
    studentStatus: number = 0;
    emailaddress: string = '';
    doctorReferred: boolean = false;
    referringDoctor: string = '';
    spouseName: string = '';
    spouseEmployer: string = '';
    emergencyContactName: string = '';
    emergencyContactRelation: string = '';
    emergencyContactHomePhone: string = '';
    emergencyContactWorkPhone: string = '';
    socialSecurityNum: string = '';
    selfPay: boolean = false;
    ethnicity: string = '';
    language: string = '';
    lastUpdatedBy: number = 0;
    patient_id: string = '';
    coPay: number = 0.0;
    defaultDoctorID: string = '';
    defaultOfficeID: string = '';
    do_not_bill_ind: boolean = false;
    template_ind: boolean = false;
    template_name_txt: string = '';
    referring_provider_id: string = '';
    contact_method_txt: string = '';
    responsibleCity: string = '';
    responsibleState: string = '';
    responsibleZip: string = '';
    addressType: string = '';
    country: string = '';
    emailOptout: boolean = false;
    biller_review_before_bill_patient_ind: boolean = false;
    send_hcfa_ind: boolean = false;
    reminders_contact_txt: string = '';
    notifications_contact_txt: string = '';
    marketing_contact_txt: string = '';
    cellPhoneProvider: string = '';
    responsibleCountry: string = '';
    spouseMaidenLastName: string = '';
    spouseMaidenFirstName: string = '';
    responsibleRelationship: string = '';
    patientCareTeams: PatientCareTeamModel[] = [];
    patientIdentifiers: PatientIdentifierModel[] = [];
    patientInsurances: PatientInsuranceModel[] = [];
    patientRaces: PatientRaceModel[] = [];
    responsibleParty: number = 0;
}
export class PatientSearchModel{
    firstName: string = '';
    lastName: string = '';
    middleInitial: string = '';
    patientAcctNo: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    dateOfBirth: string = '';
    socialSecurityNum: string = '';
    cellPhone: string = '';
}