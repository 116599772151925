<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div id="content">
        <div class="row">
            <div class="col-md-3">
                <label>Selected Item:</label>
                <!-- <button class="btn btn-primary float-right" (click)="addNew()">Add New</button> -->
                <br>
                <div class="mt-2">
                    <input type="text" class="form-control" placeholder="Search here..." (input)="findItem($event)">
                </div>
                <div id="content"
                    style="height: 423px; width: 100%; margin-top: 5px;overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black;padding: 0 !important;"
                    [ngClass]="{'disableDiv': showButton}"> <!-- width: 250px; -->
                    <div class="titlebox"></div>
                    <div class="scroll">
                        <div class="row mx-2">
                            <div class="fixTableHead" style="width: 100%;">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Brand Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let brand of brandList">
                                            <td (click)="setForm(brand)">
                                                <label style="cursor: pointer;" class="text-center">
                                                    {{brand.name_txt}}
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 pl-0 pt-2">
                <label>Item Information:</label>
                <div id="content">
                    <div class="titlebox"></div>
                    <form [formGroup]="brandFormGroup">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Brand Name:</label>                                
                                <input type="text" class="form-control" formControlName="name_txt" [attr.disabled]="true" readonly
                                [(ngModel)]="brandModel.name_txt">
                            </div>
                            <div class="col-md-1 mt-4 p-1">
                                <a href="javascript:void(0)"
                                                    (click)="editLookup(brandList,brand,dbEnum.QE_DEV_SQL_DB)"><img
                                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                            
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <label>Diam:</label>
                                <ng-select [clearable]="true" formControlName="diameter_txt"
                                    [items]="diamLookup" [(ngModel)]="ItemContact.diameter_txt"                                               
                                    bindLabel="name_txt" bindValue="name_txt">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <label>BC:</label>                                
                                <ng-select [clearable]="true" formControlName="basecurve_txt"
                                    [items]="bcLookup" [(ngModel)]="ItemContact.basecurve_txt"                                           
                                    bindLabel="name_txt" bindValue="name_txt">
                                </ng-select>
                            </div>
                        </div>
                        <div class="pt-2 col-md-12 text-center">
                            <button class="btn btn-primary px-4" (click)="edit()" *ngIf="!showButton"
                                [disabled]="!enablEditButton">Edit</button>
                            <button class="btn btn-primary px-4 mx-2" [disabled]="brandFormGroup.invalid"
                                *ngIf="showButton" (click)="save()">Save</button>
                            <button class="btn btn-primary px-4" (click)="cancel()" *ngIf="showButton">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <!-- <button type="button" class="btn btn-default" (click)="save()">Save</button> -->
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>
