import { PatientReviewModel } from "./PatientReviewModel";

export class ImmunizationModel{
public immunization_id : string = '';
public original_id : string = '';
public vaccine_name_txt : string = '';
public CVX_code_txt : string = '';
public administered_amount_txt : string = '';
public administered_units_txt : string = '';
public vaccine_lot_number_txt : string = '';
public manufacturer_name_txt : string = '';
public manufacturer_code_txt : string = '';
public immunized_dt : string = '';
public created_dt : Date = new Date();
public record_type_txt : string = '';
public record_id : string = '';
public patient_id : string = '';
public nk1_local_number : string = '';
public pd1_protect_indicator : string = '';
public pid_identifier_txt : string = '';
public pid_race_id : string = '';
public msh_application_acknowledgment_type : string = '';
public nk1_rel_phone_usecode : string = '';
public pid_telecomm_use_code_txt : string = '';
public msh_accept_acknowledgment_type_txt : string = '';
public msh_message_structure_txt : string = '';
public msh_sending_facility_id : string = '';
public pid_assigning_authority_txt : string = '';
public msh_message_control_id : string = '';
public pid_race_code_system_txt : string = '';
public pid_ssn_identifier_type_code : string = '';
public msh_receiving_facility_id : string = '';
public pid_ethnic_code_sys_txt : string = '';
public pid_ssn_namespace_id : string = '';
public pid_registry_status_eff_dt : Date = new Date();
public msh_version_id : string = '';
public nk1_relationship_txt : string = '';
public nk1_set_id : string = '';
public pid_address_type_txt : string = '';
public pid_immunization_registry_status : string = '';
public msh_message_typecode_txt : string = '';
public msh_sending_application_id : string = '';
public nk1_relationship_id : string = '';
public pid_ethnic_id : string = '';
public pid_patient_set_id : string = '';
public msh_message_dt : Date = new Date();
public msh_processing_id : string = '';
public nk1_address_type_txt : string = '';
public nk1_codesystem_txt : string = '';
public nk1_name_type_code_txt : string = '';
public pd1_protect_date : Date = new Date();
public pid_name_of_code_sys_txt : string = '';
public pid_publicity_code_eff_dt : Date = new Date();
public pid_identity_type_id : string = '';
public pid_name_type_code_txt : string = '';
public msh_message_triggerevent_txt : string = '';
public nk1_areacode : string = '';
public nk1_phone_telcom_equip : string = '';
public pid_telecom_equip_type_txt : string = '';
public pid_text_txt : string = '';
public patientReview : PatientReviewModel = new PatientReviewModel();
}
