<div class="header-row fixed-top" style="text-align:center !important">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <h3 style="font-weight: bold;">User Information</h3>
            <hr>
        </div>
    </div>
</div>
<form>
    <fieldset>
        <br />
        <div class="row">
            <div class="col-md-3">
                <label>Selected User:</label>
                <button class="btn btn-primary float-right" (click)="addNew()">Add New</button>
                <div>
                    <input type="text" placeholder="Search here..." (input)="SearchUser($event)" class="form-control">
                </div>
                <div id="content" style="overflow: scroll;max-height: 500px;">
                    <div>
                        <div class="row mx-2" *ngFor="let user of userList">
                            <div class="col-md-12" [ngStyle]="{'background-color': selectedUser == user.user_id ? '#5A5AE5' : 'white'}">
                                <label style="cursor: pointer;" (click)="setForm(user)" class="text-center">{{user.display_txt}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label><input type="checkbox" #showInactiveCheckbox id="showInactiveCheckbox" ([ngModel])="showActive" (change)="filterUser(showInactiveCheckbox.checked)" class="mx-2">Show Inactive</label>
                </div>
            </div>
            <div class="col-md-9 pl-0 pt-2">
                <div id="content">
                    <div class="titlebox"></div>
                    <form [formGroup]="userFormGroup">
                        <div class="row pt-2 mx-1">
                            <label class="mx-1">Last Login:</label>
                            <label class="mx-2">{{userModel.last_login_dt| date:'shortDate'}}</label>
                            <label class="mx-2"><input type="checkbox" formControlName="active_ind"
                                    [(ngModel)]="userModel.active_ind" class="mx-2">Active</label>
                            <label class="mx-2"><input type="checkbox" formControlName="locked_ind"
                                    [(ngModel)]="userModel.locked_ind" class="mx-2">Locked</label>
                            <label class="mx-2">User ID:</label>
                            <input type="text" formControlName="qeUserID" [(ngModel)]="userModel.qeUserID" class="col-md-2 form-control">
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-1 justify-content-right">
                                <label>Username:</label>
                            </div>
                            <div class="col-md-4 col-sm-8">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input formControlName="username_txt" [(ngModel)]="userModel.username_txt" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-1 justify-content-right">
                                <label>Password:</label>
                            </div>
                            <div class="col-md-4 col-sm-8">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input formControlName="password_txt" [(ngModel)]="userModel.password_txt" type="password" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1 justify-content-right">
                                <label>Display Name:</label>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input formControlName="display_txt" [(ngModel)]="userModel.display_txt" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-1 justify-content-right">
                                <label>Email:</label>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input formControlName="email_address_txt" [(ngModel)]="userModel.email_address_txt" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1 justify-content-right">
                                <label>First Name:</label>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input formControlName="first_name_txt" [(ngModel)]="userModel.first_name_txt" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-md-1 justify-content-right">
                                <label>Last Name:</label>
                            </div>
                            <div class="col-md-4 col-sm-8">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input formControlName="last_name_txt" [(ngModel)]="userModel.last_name_txt" type="text" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div>
                            <label><input type="checkbox" [disabled]="is_can_sign" [ngModelOptions]="{standalone: true}" [(ngModel)]="userModel.can_sign"
                                    class="mx-2">Check here to create Rendering
                                Provider</label>
                        </div>
                        <div class="row"  [hidden]="!is_can_sign">
                            <div class="col-md-4">
                                <input type="file" accept="image/*" (change)="onFileChanged($event)" hidden #file>
                                <button (click)="file.click()" [disabled]="(!userModel.can_sign && !imgURL) || !isShowButton" class="btn btn-primary p-2">Upload
                                    Signature</button>
                            </div>
                            <div class="col-md-8">
                                <img [src]="imgURL" height="100" *ngIf="imgURL">
                            </div>
                        </div>
                    </form>

                </div>
                <div class="text-center pt-2">
                    <button class="btn btn-primary" *ngIf="!isShowButton" (click)="edit()">Edit</button>
                    <button class="btn btn-primary mx-2" *ngIf="isShowButton" (click)="save()">Save</button>
                    <button class="btn btn-primary" *ngIf="isShowButton" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </fieldset>
</form>
