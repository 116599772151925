<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="showComponent">
    <div class="row">
    <div class="col-md-4 col-sm-4">
        <label>Exam:</label>
    </div>
        <div class="col-md-8 col-sm-8">
        <ng-select [clearable]="true" [items]="examModel" bindLabel="cheif_Complaint" bindValue="exam_ID">
        </ng-select>
    </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-4">
            <label>Generic Name:</label>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="genericLookup" 
            (keyup)="keyFunc($event,rxList[0],'generic_name_txt')"
            (keydown.tab)="saveDropdown(rxList[0].generic_name_txt,'Generic Name',genericLookup,'medicationname',rxList[0],'generic_name_txt')" bindLabel="name_txt" bindValue="name_txt" 
            [(ngModel)]="rxList[0].generic_name_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(genericLookup,genericLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
          </div>
        <div class="col-md-4 col-sm-4">
            <label>Product:</label>
        </div>
         <div class="col-md-6 col-sm-6">
            <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="productLookup" 
            (keyup)="keyFunc($event,rxList[0],'product_txt')"
            (keydown.tab)="saveDropdown(rxList[0].product_txt,'Product',productLookup,'rxproduct',rxList[0],'product_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].product_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(productLookup,productLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
         </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-4">
            <label>Strength:</label>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew"  [items]="strengthLookup" 
            (keyup)="keyFunc($event,rxList[0],'strength_txt')"
            (keydown.tab)="saveDropdown(rxList[0].strength_txt,'Strength',strengthLookup,'rxstrength',rxList[0],'strength_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].strength_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(strengthLookup,strengthLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
        <div class="col-md-4 col-sm-4">
            <label>Brand:</label>
        </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="brandLookup" 
            (keyup)="keyFunc($event,rxList[0],'brand_name_txt')"
            (keydown.tab)="saveDropdown(rxList[0].brand_name_txt,'Brand',brandLookup,'medicationname',rxList[0],'brand_name_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].brand_name_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(brandLookup,brandLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-4">
            <label>Route:</label>
        </div>
           <div class="col-md-6 col-sm-6">
            <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew"  [items]="rxRouteLookup" 
            (keyup)="keyFunc($event,rxList[0],'route_txt')"
            (keydown.tab)="saveDropdown(rxList[0].route_txt,'Route',rxRouteLookup,'rxroute',rxList[0],'route_txt',dbEnum.QE_Dev_Config)" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].route_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(rxRouteLookup,rxRouteLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
        <div class="col-md-4 col-sm-4">
            <label>Dose:</label>
        </div>
            <div class="col=md-6 col-sm-6">
                <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="doseLookup" 
            (keyup)="keyFunc($event,rxList[0],'dose_txt')"
            (keydown.tab)="saveDropdown(rxList[0].dose_txt,'Dose',doseLookup,'dose',rxList[0],'dose_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].dose_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(doseLookup,doseLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-4">
            <label>Refill:</label>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="refilLookup" 
            (keyup)="keyFunc($event,rxList[0],'refill_times_txt')"
            (keydown.tab)="saveDropdown(rxList[0].refill_times_txt,'Refill',refilLookup,'refill',rxList[0],'refill_times_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].refill_times_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(refilLookup,refilLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
        <div class="col-md-4 col-sm-4">
            <label>Frequency:</label>
        </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="rxfrequency" 
            (keyup)="keyFunc($event,rxList[0],'frequency_txt')"
            (keydown.tab)="saveDropdown(rxList[0].frequency_txt,'Frequency',rxfrequency,'rxfrequency',rxList[0],'frequency_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].frequency_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(rxfrequency,rxfrequencyCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-4">
            <label>#:</label>
        </div>
        <div class="col-md-6 col-sm-6">
            <div class="form-row">
            <ng-select [clearable]="true" addTagText="Add Item" [addTag]="CreateNew" [items]="rxNumberLookup" 
            (keyup)="keyFunc($event,rxList[0],'detail_txt')"
            (keydown.tab)="saveDropdown(rxList[0].detail_txt,'#',rxNumberLookup,'rxnumber',rxList[0],'detail_txt')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="rxList[0].detail_txt">
            </ng-select>
            <a href="javascript:void(0)" (click)="editLookup(rxNumberLookup,rxNumberLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
        </div>
        
    </div>
</div>
<div class="modal-footer">
<label class="mx-2">FQ:<input [(ngModel)]="rxList[0].fq" type="checkbox" class="mx-2"></label>
<button type="button" class="btn btn-default" (click)="save()">Save</button>
<button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>