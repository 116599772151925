import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LookupModel } from './../model/lookupModel';
import { InsuranceModel } from './../model/insuranceModel';
import { ChargeModel } from './../model/chargeModel';
import { OfficeModel } from './../model/officeModel';
import { ProviderModel } from './../model/providerModel';
import { IdentifierModel } from './../model/identiferModel';
import { Observable, ObservableInput } from 'rxjs';
import { PatientIdentifierModel } from './../model/patientIdentifierModel';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Identifiers } from '@angular/compiler';
import { ProviderInsuranceModel } from '../model/providerInsuranceModel';
import { LookupModalComponent } from '../shared/lookup-modal/lookup-modal.component';
import { ItemModel } from '../model/ItemModel';
import { ProcedureModel } from '../model/ProcedureModel';
import { Item_ContactsDataModel } from '../model/Item_ContactsDataModel';

@Injectable({
  providedIn: 'root'
})
export class LookupService {
  bsModalRef !: BsModalRef;
  constructor(private http: HttpClient, private modalService: BsModalService) { }

  getIdentifierLookup(): Observable<PatientIdentifierModel> {
    return this.http.get<PatientIdentifierModel>(environment.server + 'api/identifier/getall')
  }
  insertIdentifier(identifierModel: IdentifierModel): Observable<IdentifierModel> {
    return this.http.post<IdentifierModel>(environment.server + 'api/identifier/add', identifierModel);
  }
  updateIdentifier(identifierModel: IdentifierModel): Observable<IdentifierModel> {
    return this.http.post<IdentifierModel>(environment.server + 'api/identifier/update', identifierModel);
  }

  getProvider(): Observable<ProviderModel> {
    return this.http.get<ProviderModel>(environment.server + 'api/provider/getall');
  }

  getProviderByPractice(practiceId: string): Observable<ProviderModel> {
    return this.http.get<ProviderModel>(environment.server + 'api/provider/getall?practiceId=' + practiceId);
  }

  getRenderingProvider(practiceId: string): Observable<ProviderModel> {
    return this.http.get<ProviderModel>(environment.server + 'api/provider/getrenderingall?practiceId=' + practiceId);
  }

  getReferingProvider(practiceId: string): Observable<ProviderModel> {
    return this.http.get<ProviderModel>(environment.server + 'api/provider/getreferingall?practiceId=' + practiceId);
  }

  getByProviderName(provider: string): Observable<ProviderModel> {
    return this.http.get<ProviderModel>(environment.server + 'api/provider/getall');
  }

  insertProvider(providerModel: ProviderModel): Observable<ProviderModel> {
    return this.http.post<ProviderModel>(environment.server + 'api/provider/add', providerModel);
  }

  insertProviderfromUser(providerModel: ProviderModel): Observable<ProviderModel> {
    return this.http.post<ProviderModel>(environment.server + 'api/provider/addfromuser', providerModel);
  }

  editProvider(providerModel: ProviderModel): Observable<ProviderModel> {
    return this.http.post<ProviderModel>(environment.server + 'api/provider/update', providerModel);
  }

  getOffice(): Observable<OfficeModel> {
    return this.http.get<OfficeModel>(environment.server + 'api/office/getall');
  }

  getOfficeWithInactive(): Observable<OfficeModel> {
    return this.http.get<OfficeModel>(environment.server + 'api/office/getallwithinactive');
  }

  saveOffice(officeModel: OfficeModel) {
    return this.http.post<OfficeModel>(environment.server + 'api/office/add', officeModel);
  }

  updateOffice(officeModel: OfficeModel) {
    return this.http.post<OfficeModel>(environment.server + 'api/office/update', officeModel);
  }

  getInsuarance(): Observable<InsuranceModel> {
    return this.http.get<InsuranceModel>(environment.server + 'api/insurance/getall');
  }

  getByInsuarance(insurace: string): Observable<InsuranceModel> {
    return this.http.get<InsuranceModel>(environment.server + 'api/insurance/getall');
  }

  insertInsuarance(insuranceModel: InsuranceModel): Observable<InsuranceModel> {
    return this.http.post<InsuranceModel>(environment.server + 'api/insurance/add', insuranceModel);
  }

  editInsuarance(insuranceModel: InsuranceModel): Observable<InsuranceModel> {
    return this.http.post<InsuranceModel>(environment.server + 'api/insurance/update', insuranceModel);
  }

  insertCharge(charge : ChargeModel): Observable<ChargeModel> {
    return this.http.post<ChargeModel>(environment.server + 'api/charge/add',charge);
  }

  updateCharge(charge : ChargeModel): Observable<ChargeModel> {
    return this.http.post<ChargeModel>(environment.server + 'api/charge/update',charge);
  }

  getCharge(): Observable<ChargeModel[]> {
    return this.http.get<ChargeModel[]>(environment.server + 'api/charge/getall');
  }
  insertProcedure(charge : ProcedureModel): Observable<ProcedureModel> {
    return this.http.post<ProcedureModel>(environment.server + 'api/procedure/add',charge);
  }

  updateProcedure(charge : ProcedureModel): Observable<ProcedureModel> {
    return this.http.post<ProcedureModel>(environment.server + 'api/procedure/update',charge);
  }
  getProcedure(): Observable<ProcedureModel[]> {
    return this.http.get<ProcedureModel[]>(environment.server + 'api/procedure/getall');
  }
  getItems(): Observable<ItemModel[]> {
    return this.http.get<ItemModel[]>(environment.server + 'api/item/getall');
  }
  getItemsWithContact(): Observable<[ItemModel[], Item_ContactsDataModel[]]> {
    return this.http.get<[ItemModel[], Item_ContactsDataModel[]]>(environment.server + 'api/item/getallwithcontacts');
  }
  insertItem(item : ItemModel): Observable<ItemModel> {
    return this.http.post<ItemModel>(environment.server + 'api/item/add',item);
  }
  updateItem(item : ItemModel): Observable<ItemModel> {
    return this.http.post<ItemModel>(environment.server + 'api/item/update',item);
  }
  updateItemsWithContact(item : Item_ContactsDataModel): Observable<ItemModel> {
    return this.http.post<ItemModel>(environment.server + 'api/item/updatewithcontact',item);
  }
  getAllGlobalLookup(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(environment.server + 'api/lookup/getall');
  }
  getGlobalLookup(groupText: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(environment.server + 'api/lookup/GetByGroupName?txt=' + groupText);
  }

  getGlobalLookupForData(groupText: string): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(environment.server + 'api/lookup/GetByGroupNameForData?txt=' + groupText);
  }

  getAllLookupData(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(environment.server + 'api/lookup/getallForData');
  }

  getAllGlobalLookupData(): Observable<LookupModel[]> {
    return this.http.get<LookupModel[]>(environment.server + 'api/lookup/getallGlobalForData');
  }

  insertLookup(lookupModel: LookupModel) {
    return this.http.post<LookupModel[]>(environment.server + 'api/lookup/addLookup', lookupModel);
  }

  editLookup(lookupModel: LookupModel) {
    return this.http.post<LookupModel[]>(environment.server + 'api/lookup/updateLookup', lookupModel);
  }

  insertConfigLookup(lookupModel: LookupModel) {
    return this.http.post<LookupModel[]>(environment.server + 'api/lookup/add', lookupModel);
  }

  updateConfigLookup(lookupModel: LookupModel) {
    return this.http.post<LookupModel[]>(environment.server + 'api/lookup/update', lookupModel);
  }

  updateLookup(itemCopy: any, dbType: number,item?:any) : Observable<any>{
    this.bsModalRef = new BsModalRef;
    const initialState = {
      item: itemCopy,
      title: 'Edit Lookup',
      dbType: dbType
    };
    this.bsModalRef = this.modalService.show(LookupModalComponent, { initialState });
    // this.bsModalRef.setClass('modal-lg')
    this.bsModalRef.content.closeBtnName = 'Close';
    return this.bsModalRef.content.event;
  }

}