export class PracticeModel {
    Id: number = 0;
    PracticeId: string = '';
    name_txt: string = '';
    value_txt: string = '';
    created_by: string = '';
    created_dt: Date = new Date();
    updated_by: string = '';
    zip: string = '';
    updated_dt!: Date;
    logo_img: any;
}