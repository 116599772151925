import { PatientInsuranceModel } from './patientInsuranceModel';
import { PatientIdentifierModel } from "./patientIdentifierModel";

export class AdvancePatientSearchModel{
    public ExamFromDate !: Date;
    public ExamToDate !: Date;
    public LastSeen : string = '';
    public BirthFromDate !: Date;
    public BirthToDate !: Date;
    public Employer : string = '';
    public Ethnicity : string = '';
    public PreferredLang : string = '';
    public Race : string = '';
    public ContactMethod : string = '';
    public Zipcode : string = '';
    public Areacode : string = '';
    public Appointment : string = '';
    public ExamNotification : string = '';
    public Marketing : string = '';
    public FromAge : string = '';
    public ToAge : string = '';
    public Sex : string = '';
    public Doctor : string = '';
    public Assessment : string = '';
    public Office : string = '';
    public Plan : string = '';
    public Immunization : string = '';
    public IsShowInActive : boolean = false;
    public time_zone: number = 0;
    public Identifier : PatientIdentifierModel[] = [];
    public PatientInsurance : PatientInsuranceModel[] = [];
}