import { Binary } from "@angular/compiler";
import { Byte } from "@angular/compiler/src/util";

export class AttachmentModel{
public attachment_id : string = '';
public type_txt : string = '';
public patient_id : string = '';
public description_txt : string = '';
public path_resource_txt : string = '';
public file_dat : any;
public active_ind : boolean = false;
public sharing_attachment_id : string = '';
public record_id : string = '';
public record_type_txt : string = '';
public created_dt : Date = new Date();
public created_by : string = '';
public updated_dt : Date = new Date();
public updated_by : string = '';
public hash_txt : string = '';
}