import { OMR_PrescriptionsModel } from './../../../model/OMR_PrescriptionsModel';
import { GlobalService } from './../../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from 'src/app/services/lookup.service';
import { UserService } from './../../../services/user.service';
import { OMR_SpectacleOrderFormModel } from './../../../model/OMR_SpectacleOrderFormModel';
import { ActivatedRoute } from '@angular/router';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OMR_ContactRXModel } from 'src/app/model/OMR_ContactRXModel';
import { ExamModel } from 'src/app/model/ExamModel';
import { PatientModel } from 'src/app/model/patientModel';
import { ManufacturerService } from 'src/app/services/Manufacturer.service';
import { ManufacturerModel } from 'src/app/model/ManufacturerModel';
import { UserModel } from 'src/app/model/userModel';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { LookupModel } from 'src/app/model/lookupModel';
import { DatePipe,ViewportScroller } from '@angular/common';
import { addYears, toDate } from 'date-fns';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ComponentModalComponent } from 'src/app/shared/component-modal/component-modal.component';
import { OfficeModel } from 'src/app/model/officeModel';
import { isNumber } from 'lodash';
import * as moment from 'moment';
import { ItemModel } from 'src/app/model/ItemModel';
import { Item_ContactsDataModel } from 'src/app/model/Item_ContactsDataModel';
import { Console } from 'console';
import { promise } from 'protractor';
import { resolve } from 'dns';


@Component({
  selector: 'app-spectacle',
  templateUrl: './spectacle.component.html',
  styleUrls: ['./spectacle.component.scss']

})
export class SpectacleComponent implements OnInit, OnDestroy {
  // omr_ContactRxModel : OMR_ContactRXModel = new OMR_ContactRXModel();
  omr_SpectacleOrderFormModel: OMR_SpectacleOrderFormModel = new OMR_SpectacleOrderFormModel()
  examId: string = '';
  examModel: ExamModel = new ExamModel();
  examModelCopy: ExamModel = new ExamModel();
  patientDetail = new PatientModel();
  patientModel !: PatientModel;
  patientId: string = '';
  patient_Id!: number;
  userList: UserModel[] = [];
  user: any = new UserModel();
  License:string='';
  user1: any = new UserModel();
  imgURL: any;
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  needToFind: any;
  baseCurveLookup: LookupModel[] = [];
  baseCurveCopyLookup: LookupModel[] = [];
  productModel: ItemModel = new ItemModel();
  ProductList: ItemModel[] = [];
  ItemContacts: Item_ContactsDataModel[] = [];
  diamLookup: LookupModel[] = [];
  bcLookup:LookupModel[]=[];
  bcLookupCopy:LookupModel[]=[];
  diamCopyLookup: LookupModel[] = [];
  brandLookup: LookupModel[] = [];
  brandopyLookup: LookupModel[] = [];
  manufacture: ManufacturerModel[] = [];
  manufactureCopyLookup: LookupModel[] = [];
  _6PackLookup: LookupModel[] = [];
  _6PackCopyLookup: LookupModel[] = [];
  cleaningSolutionLookup: LookupModel[] = [];
  cleaningSolutionCopyLookup: LookupModel[] = [];
  RemarksCopyLookup: LookupModel[] = [];
  strengthLookup: LookupModel[] = [];
  strengthLookupCopy: LookupModel[] = [];
  age: number = 0;
  bsModalRef !: BsModalRef;
  showButton = false;
  office: OfficeModel[] = [];
  officename:string='';
  officeaddress:string='';
  officeCitySZ:string='';
  officephone:string='';
  officeemail: string = '';
  office_npi_txt: string = '';
  office_license_txt: string = '';
  check_value: string = '';
  gaveCLRX: boolean = false;
  currentDate: Date = new Date();


  constructor(private _clinicalService: ClinicalService,
    private activateRoute: ActivatedRoute, private _userService: UserService,
    private _lookupService: LookupService, private toaster: ToastrService,
    private globalService: GlobalService, public datepipe: DatePipe, private _manufactureService: ManufacturerService, private modalService: BsModalService,private viewportScroller: ViewportScroller) {
      this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['examId']) {
        this.examId = params['examId'];
        if (params['patientId']) {
          this.patientId = params['patientId']
        }
        if (params['patient_Id']) {
          this.patient_Id = parseInt(params['patient_Id'])
        }
        if (params['examId']) {
          this.examId = params['examId']
        }
      }
    });
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
    this.calculateAge(this.patientModel.dateOfBirth);
  }

  getOffice() {
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;
      this.office = this.office.filter(x => x.office_id == this.patientModel.defaultOfficeID);
      // if (this.office[0]?.name_txt != null) {
      //   this.officename = this.office[0]?.name_txt
      // }
      // if (this.office[0]?.address_1_txt != null && this.office[0].address_2_txt) {
      //   this.officeaddress = this.office[0]?.address_1_txt + " " + this.office[0]?.address_2_txt;
      // }
      // if (this.office[0]?.phone_1_txt != null) {
      //   this.officephone = this.office[0]?.phone_1_txt;
      // }
      if (this.office[0]?.name_txt != null) {
        this.officename = this.office[0]?.name_txt
      }
      // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
      //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
      // }
      if (this.office[0]?.address_1_txt) {
        this.officeaddress = `${this.office[0]?.address_1_txt}`;
      }
      if(this.office[0]?.address_2_txt){
        this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
      }
      if(this.office[0]?.city_txt){
        this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
      }
      if (this.office[0]?.phone_1_txt) {
        this.officephone = this.office[0]?.phone_1_txt;
      }
      if(this.office[0]?.npi_txt){
        this.office_npi_txt = this.office[0]?.npi_txt;
      }
      if(this.office[0]?.email_address_txt){
        this.officeemail = this.office[0]?.email_address_txt;
      }
      if(this.office[0].license_txt){
        this.office_license_txt = this.office[0].license_txt;
      }
    }, (error) => {
      this.toaster.error(error.error);
    });
  }



  ngOnInit() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    this.getManufacture();
    this.sleep(3000);
    this.getProducts();
    if (this.examId != '') {
      this.getContactRx();
    }
    else {
      this.examModel.omR_Prescriptions.fullTime1 = true;
      this.examModel.omR_Prescriptions.singleVision1 = true;
      this.examModel.omR_Prescriptions.uvCoat1 = true;
      this.examModel.omR_Prescriptions.scratchResistant1 = true;
      this.examModel.omR_Prescriptions.antiReflective1 = true;
      this.examModel.omR_Prescriptions.asphericPoly1 = true;
      this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));

      this.getUser();
    }
    if (this.patient_Id || this.patientId != '') {
      this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
      // this.isLoad = true;
      // this.ngAfterViewInit();
    }

    // var startDate = this.examModel.omR_ContactRX.expirationDate;
    // var numOfYears = 1;
    // var expireDate = new Date(startDate);
    // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
    // this.examModel.omR_ContactRX.expirationDate = expireDate;

    // this.getUser();
    this.getAllLookup();
    this.getConfigLookup();
    this.getOffice();
  }
  sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  getProducts() {
    debugger;
    this._lookupService.getItemsWithContact().subscribe((res: any) => {
      this.ProductList = res.item1;
      this.ItemContacts = res.item2;
    });
  }
  copyvalue(value: string) {
    this.examModel.omR_Prescriptions.prismTop = value;
  }
  copyRX(){
    this.examModel.omR_Prescriptions.sphereTop = this.examModel.omR_Prescriptions.sphereOD;
    this.examModel.omR_Prescriptions.cylinderTop = this.examModel.omR_Prescriptions.cylinderOD;
    this.examModel.omR_Prescriptions.axisTop = this.examModel.omR_Prescriptions.axisOD;
    this.examModel.omR_Prescriptions.prismTop = this.examModel.omR_Prescriptions.prismOD;
    this.examModel.omR_Prescriptions.addTop = this.examModel.omR_Prescriptions.addOD;

    this.examModel.omR_Prescriptions.sphereBottom = this.examModel.omR_Prescriptions.sphereOS;
    this.examModel.omR_Prescriptions.cylinderBottom = this.examModel.omR_Prescriptions.cylinderOS;
    this.examModel.omR_Prescriptions.axisBottom = this.examModel.omR_Prescriptions.axisOS;
    this.examModel.omR_Prescriptions.prismBottom = this.examModel.omR_Prescriptions.prismOS;
    this.examModel.omR_Prescriptions.addBottom = this.examModel.omR_Prescriptions.addOS;

    this.examModel.omR_Prescriptions.fullTime2 = this.examModel.omR_Prescriptions.fullTime1;
    this.examModel.omR_Prescriptions.distOnly2 = this.examModel.omR_Prescriptions.distOnly1;
    this.examModel.omR_Prescriptions.interOnly2 = this.examModel.omR_Prescriptions.interOnly1;
    this.examModel.omR_Prescriptions.readOnly2 = this.examModel.omR_Prescriptions.readOnly1;
    this.examModel.omR_Prescriptions.asNeeded2 = this.examModel.omR_Prescriptions.asNeeded1;

    this.examModel.omR_Prescriptions.singleVision2 = this.examModel.omR_Prescriptions.singleVision1;
    this.examModel.omR_Prescriptions.biFocal2 = this.examModel.omR_Prescriptions.biFocal1;
    this.examModel.omR_Prescriptions.triFocal2 = this.examModel.omR_Prescriptions.triFocal1;
    this.examModel.omR_Prescriptions.progressive2 = this.examModel.omR_Prescriptions.progressive1;

    this.examModel.omR_Prescriptions.uvCoat2 = this.examModel.omR_Prescriptions.uvCoat1;
    this.examModel.omR_Prescriptions.tint2 = this.examModel.omR_Prescriptions.tint1;
    this.examModel.omR_Prescriptions.safety2 = this.examModel.omR_Prescriptions.safety1;
    this.examModel.omR_Prescriptions.antiReflective2 = this.examModel.omR_Prescriptions.antiReflective1;
    this.examModel.omR_Prescriptions.cR392 = this.examModel.omR_Prescriptions.cR391;
    this.examModel.omR_Prescriptions.scratchResistant2 = this.examModel.omR_Prescriptions.scratchResistant1;
    this.examModel.omR_Prescriptions.photocromatic2 = this.examModel.omR_Prescriptions.photocromatic1;
    this.examModel.omR_Prescriptions.polycarbonate2 = this.examModel.omR_Prescriptions.polycarbonate1;
    this.examModel.omR_Prescriptions.asphericPoly2 = this.examModel.omR_Prescriptions.asphericPoly1;
    this.examModel.omR_Prescriptions.other2 = this.examModel.omR_Prescriptions.other1;

    this.examModel.omR_Prescriptions.secondaryDistPD = this.examModel.omR_Prescriptions.primaryDistPD;
    this.examModel.omR_Prescriptions.secondaryNearPD = this.examModel.omR_Prescriptions.primaryNearPD;
  }
  calculateAge(birthdate: Date) {
    let timeDiff = Math.abs(Date.now() - (new Date(birthdate)).getTime());
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
  }
  getConfigLookup() {

    // this._lookupService.getGlobalLookup('diam').subscribe(res => {
    //   this.diamLookup = res.filter(x => x.active_ind == true);
    //   this.diamLookup = this.diamLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });

    //   this.diamCopyLookup = res
    // })

    // this._lookupService.getGlobalLookup('bc').subscribe(res => {
    //   this.bcLookup = res.filter(x => x.active_ind == true);
    //   this.bcLookup = this.bcLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.bcLookupCopy = res
    // })

    // this._lookupService.getGlobalLookup('brand').subscribe(res => {
    //   this.brandLookup = res.filter(x => x.active_ind == true);
    //   this.brandLookup = this.brandLookup.sort((a, b) => {
    //     if (a.name_txt < b.name_txt) {
    //       return -1
    //     }
    //     if (a.name_txt > b.name_txt) {
    //       return 1
    //     }
    //     return 0
    //   });
    //   this.brandopyLookup = res
    // })

  }
  changeDate($event: any){

    this.examModel.exam_Date = $event;
    //var startDate = this.examModel.exam_Date;
    // var numOfYears = 1;
    // var expireDate = new Date(startDate);
    // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
    // this.examModel.omR_Prescriptions.expirationDate = expireDate;
    // this.examModel.omR_ContactRX.expirationDate = expireDate;

    var date = this.examModel.exam_Date;
    var targetTime = new Date(date);
    targetTime.setFullYear(targetTime.getFullYear() + 1);
    var timeZoneFromDB = -(new Date().getTimezoneOffset() / 60); //time zone value from database
    //get the timezone offset from local time in minutes
    var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
    //convert the offset to milliseconds, add to targetTime, and make a new Date
    var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
    this.examModel.omR_Prescriptions.expirationDate = offsetTime;
    this.examModel.omR_ContactRX.expirationDate = offsetTime;
  }


  getContactRx() {
    debugger;
    this._clinicalService.getByExamId(this.examId).subscribe(res => {

      this.examModel = res;
      this.examModel.omR_Prescriptions.fullTime1 = true;
      this.examModel.omR_Prescriptions.singleVision1 = true;
      this.examModel.omR_Prescriptions.uvCoat1 = true;
      this.examModel.omR_Prescriptions.scratchResistant1 = true;
      this.examModel.omR_Prescriptions.antiReflective1 = true;
      this.examModel.omR_Prescriptions.asphericPoly1 = true;
      this.getUser();
      this.examModel.omR_ProgressNotes=this.examModel.omR_ProgressNotes.sort((a, b) => {
        if (a.creationDate < b.creationDate) {
          return -1
        }
        if (a.creationDate > b.creationDate) {
          return 1
        }
        return 0
      });


      var expDate = new Date(0);
      // var ctExpirationDate = new Date(this.examModel.omR_ContactRX.expirationDate).toDateString();
      var ctExpirationDate = new Date(this.examModel.omR_ContactRX.expirationDate);
      // if(ctExpirationDate == expDate.toDateString()){
      if(ctExpirationDate.getFullYear() <= 1970){
        // var startDate = this.examModel.exam_Date;
        // var numOfYears = 1;
        // var expireDate = new Date(startDate);
        // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
        // this.examModel.omR_ContactRX.expirationDate = expireDate;
        var date = this.examModel.exam_Date;
        var targetTime = new Date(date);
        targetTime.setFullYear(targetTime.getFullYear() + 1);
        var timeZoneFromDB = -(new Date().getTimezoneOffset() / 60); //time zone value from database
        //get the timezone offset from local time in minutes
        var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
        //convert the offset to milliseconds, add to targetTime, and make a new Date
        var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
        this.examModel.omR_ContactRX.expirationDate = offsetTime;
      }
      if(this.examModel.omR_ProgressNotes.length > 0){
        this.gaveCLRX = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].gaveCLRX;

        this.examModel.omR_ContactRX.odPowerT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].odPower;
        this.examModel.omR_ContactRX.odDiameterT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].oddiam;
        this.examModel.omR_ContactRX.odBaseCurveT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].odbc;
        if(this.examModel.omR_ContactRX.odBrandT != this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].odBrand){
          this.examModel.omR_ContactRX.odManufacturerT = "";
        }
        this.examModel.omR_ContactRX.odBrandT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].odBrand;
        if(!this.examModel.omR_ContactRX.odManufacturerT){
          setTimeout(() =>{
            this.examModel.omR_ContactRX.odManufacturerT = this.selectBrandManufacture(this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].odBrand);
          }, 2000);
        }

        this.examModel.omR_ContactRX.osPowerT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].osPower;
        this.examModel.omR_ContactRX.osDiameterT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].osdiam;
        this.examModel.omR_ContactRX.osBaseCurveT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].osbc;
        if(this.examModel.omR_ContactRX.osBrandT != this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].osBrand){
          this.examModel.omR_ContactRX.osManufacturerT = "";
        }
        this.examModel.omR_ContactRX.osBrandT = this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].osBrand;
        if(!this.examModel.omR_ContactRX.osManufacturerT){
          setTimeout(() =>{
            this.examModel.omR_ContactRX.osManufacturerT = this.selectBrandManufacture(this.examModel.omR_ProgressNotes[this.examModel.omR_ProgressNotes.length-1].osBrand);
          }, 2000);

        }
      }
      this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
      this.getSpectacle();
    })
  }
  selectBrandManufacture(brand: string): string {
    var item = this.ProductList.filter((x) => x.brand !== null && x.brand.trim().toLocaleLowerCase() == brand.trim().toLocaleLowerCase());
    if (item.length > 0) {
      if(item[0].manufacturer_id){
        var manufactureItem = this.manufacture.filter(x => x.manufacturer_id == item[0].manufacturer_id);
        if(manufactureItem.length > 0){
          return manufactureItem[0].name_txt;
        }
      }
    }
    return "";
  }
  getManufacture() {
    this._manufactureService.getAll().subscribe(res => {
      this.manufacture = res;
      this.manufacture = res.filter(x => x.active_ind == true);
    })
  }

  getSpectacle() {

    this._clinicalService.getSpectacleByExamId(this.examId).subscribe(res => {
      this.omr_SpectacleOrderFormModel = res;
      var Arraycheck: string | any[]=[];

      var spExpDate = new Date(0);
      //var spExpirationDate = new Date(this.examModel.omR_Prescriptions.expirationDate).toDateString();
      var spExpirationDate = new Date(this.examModel.omR_Prescriptions.expirationDate);
      //if(spExpirationDate == spExpDate.toDateString()){
      if(spExpirationDate.getFullYear() <= 1970){
        // var startDate = this.examModel.exam_Date;
        // var numOfYears = 1;
        // var expireDate = new Date(startDate);
        // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
        // this.examModel.omR_Prescriptions.expirationDate = expireDate;
        var date = this.examModel.exam_Date;
        var targetTime = new Date(date);
        targetTime.setFullYear(targetTime.getFullYear() + 1);
        var timeZoneFromDB = -(new Date().getTimezoneOffset() / 60); //time zone value from database
        //get the timezone offset from local time in minutes
        var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
        //convert the offset to milliseconds, add to targetTime, and make a new Date
        var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);
        this.examModel.omR_Prescriptions.expirationDate = offsetTime;
      }
      if (this.examModel.omR_Exam.distSubjectODSphere != "") {
        var distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.trim();
        var ODSphereArray = distSubjectODSphere.match(/[^\d()]+|[\d.]+/g);
        var odArray = [];
        if(ODSphereArray){
          var sign = '';
          for(let od of ODSphereArray){
            if(!isNaN(Number(od))){
              if(sign != ''){
                od = `${sign}${od}`;
              }
              odArray.push(od);
              sign = '';
            }else{
              if(od == "x" || od == "*"){
                sign = '';
              }else{
                sign = od;
              }
            }
          }
          this.examModel.omR_Prescriptions.sphereOD = odArray[0];
          this.examModel.omR_Prescriptions.cylinderOD = odArray[1];
          this.examModel.omR_Prescriptions.axisOD = odArray[2];
        }


        // var firstchar = distSubjectODSphere.charAt(0);
        // if (firstchar == '+' || firstchar == '-') {
        //   distSubjectODSphere = distSubjectODSphere.substring(1);
        //    Arraycheck = distSubjectODSphere.split('-')
        // }
        // // this.examModel.omR_Exam.distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.replace('x', '*');
        // // this.examModel.omR_Exam.distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.replace('x', '*');
        // // this.examModel.omR_Exam.distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.replace('+', '*');
        // // this.examModel.omR_Exam.distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.replace('+', '*');
        // // this.examModel.omR_Exam.distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.replace('-', '*');
        // // this.examModel.omR_Exam.distSubjectODSphere = this.examModel.omR_Exam.distSubjectODSphere.replace('-', '*');

        // distSubjectODSphere = distSubjectODSphere.replace('x', '*');
        // distSubjectODSphere = distSubjectODSphere.replace('x', '*');
        // distSubjectODSphere = distSubjectODSphere.replace('+', '*');
        // distSubjectODSphere = distSubjectODSphere.replace('+', '*');
        // distSubjectODSphere = distSubjectODSphere.replace('-', '*');
        // distSubjectODSphere = distSubjectODSphere.replace('-', '*');

        // var Array1 = distSubjectODSphere.split('*')
        // if (Array1.length > 2) {
        //   if (Arraycheck.length ==3) {
        //     this.examModel.omR_Prescriptions.sphereOD = '-'+Array1[0];
        //     this.examModel.omR_Prescriptions.cylinderOD = '-'+Array1[1];
        //     this.examModel.omR_Prescriptions.axisOD = '-'+Array1[2];
        //   }
        //   else{
        //     this.examModel.omR_Prescriptions.sphereOD = Array1[0];
        //     this.examModel.omR_Prescriptions.cylinderOD = Array1[1];
        //     this.examModel.omR_Prescriptions.axisOD = Array1[2];
        //   }

        //   if (Arraycheck.length ==2) {
        //     if (Arraycheck[0].indexOf("+") !== -1 || Arraycheck[0].indexOf("*") !== -1  ) {
        //       this.examModel.omR_Prescriptions.sphereOD = Array1[0];
        //       this.examModel.omR_Prescriptions.cylinderOD = Array1[1];
        //       this.examModel.omR_Prescriptions.axisOD = '-'+Array1[2];
        //     }
        //     else{
        //       this.examModel.omR_Prescriptions.sphereOD = Array1[0];
        //       this.examModel.omR_Prescriptions.cylinderOD = '-'+Array1[1];
        //       this.examModel.omR_Prescriptions.axisOD = Array1[2];
        //     }
        //   }
        // }
        // else if (Array1.length > 1) {
        //   this.examModel.omR_Prescriptions.sphereOD = Array1[0];
        //   this.examModel.omR_Prescriptions.cylinderOD = Array1[1];
        // }
        // else {
        //   this.examModel.omR_Prescriptions.sphereOD = Array1[0];
        // }

        // if (firstchar == '+' || firstchar == '-') {
        //   this.examModel.omR_Prescriptions.sphereOD = firstchar + ' ' + Array1[0];
        // }

        if (this.examModel.omR_Prescriptions.addOD == '') {
          this.examModel.omR_Prescriptions.addOD = this.examModel.omR_Exam.nearSubjectOD;
        }
      }

      if (this.examModel.omR_Exam.distSubjectOSSphere != "") {
        var distSubjectOSSphere = this.examModel.omR_Exam.distSubjectOSSphere.trim();
        var OSSphereArray = distSubjectOSSphere.match(/[^\d()]+|[\d.]+/g);
        var osArray = [];
        if(OSSphereArray){
          var sign = '';

          for(let os of OSSphereArray){
            if(!isNaN(Number(os))){
              if(sign != ''){
                os = `${sign}${os}`;
              }
              osArray.push(os);
              sign = '';
            }else{
              if(os == "x" || os == "*"){
                sign = '';
              }else{
                sign = os;
              }
            }
          }
          this.examModel.omR_Prescriptions.sphereOS = osArray[0];
          this.examModel.omR_Prescriptions.cylinderOS = osArray[1];
          this.examModel.omR_Prescriptions.axisOS = osArray[2];
        }
        // var firstchar = distSubjectOSSphere.charAt(0);
        // if (firstchar == '+' || firstchar == '-') {
        //   distSubjectOSSphere = distSubjectOSSphere.substring(1);
        //   Arraycheck = distSubjectOSSphere.split('-')
        // }
        // distSubjectOSSphere = distSubjectOSSphere.replace('x', '*');
        // distSubjectOSSphere = distSubjectOSSphere.replace('x', '*');
        // distSubjectOSSphere = distSubjectOSSphere.replace('+', '*');
        // distSubjectOSSphere = distSubjectOSSphere.replace('+', '*');
        // distSubjectOSSphere = distSubjectOSSphere.replace('-', '*');
        // distSubjectOSSphere = distSubjectOSSphere.replace('-', '*');

        // var Array1 = distSubjectOSSphere.split('*')
        // if (Array1.length > 2) {

        //   if (Arraycheck.length ==3) {
        //     this.examModel.omR_Prescriptions.sphereOS = '-'+Array1[0];
        //     this.examModel.omR_Prescriptions.cylinderOS = '-'+Array1[1];
        //     this.examModel.omR_Prescriptions.axisOS = '-'+Array1[2];
        //   }
        //   else{
        //     this.examModel.omR_Prescriptions.sphereOS = Array1[0];
        //     this.examModel.omR_Prescriptions.cylinderOS = Array1[1];
        //     this.examModel.omR_Prescriptions.axisOS = Array1[2];
        //   }

        //   if (Arraycheck.length ==2) {
        //     if (Arraycheck[0].indexOf("+") !== -1 || Arraycheck[0].indexOf("*") !== -1  ) {
        //       this.examModel.omR_Prescriptions.sphereOS = Array1[0];
        //       this.examModel.omR_Prescriptions.cylinderOS = Array1[1];
        //       this.examModel.omR_Prescriptions.axisOS = '-'+Array1[2];
        //     }
        //     else{
        //       this.examModel.omR_Prescriptions.sphereOS = Array1[0];
        //       this.examModel.omR_Prescriptions.cylinderOS = '-'+Array1[1];
        //       this.examModel.omR_Prescriptions.axisOS = Array1[2];
        //     }
        //   }

        // }
        // else if (Array1.length > 1) {
        //   this.examModel.omR_Prescriptions.sphereOS = Array1[0];
        //   this.examModel.omR_Prescriptions.cylinderOS = Array1[1];
        // }
        // else {
        //   this.examModel.omR_Prescriptions.sphereOS = Array1[0];
        // }

        // if (firstchar == '+' || firstchar == '-') {
        //   this.examModel.omR_Prescriptions.sphereOS = firstchar + ' ' + Array1[0];
        // }

        if (this.examModel.omR_Prescriptions.addOS == '') {
          this.examModel.omR_Prescriptions.addOS = this.examModel.omR_Exam.nearSubjectOS;
        }
      }
      this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
     })
  }
  saveExam() {
    this._clinicalService.saveExam(this.examModel).subscribe(res => {
      this.examModel = res;
      this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
      this.omr_SpectacleOrderFormModel.exam_ID = this.examModel.exam_ID;
      this.toaster.success('Record Saved Succesfully.');
      //this.saveSpectacle();
    },
      (error) => {
        this.toaster.error(error.error);
      });
  }

  updateExam() {
    this._clinicalService.updateExam(this.examModel).subscribe(res => {
      this.examModel = res;
      this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
      this.omr_SpectacleOrderFormModel.exam_ID = this.examModel.exam_ID;
      this.toaster.success('Record Saved Succesfully.');
      //this.updateSpectacle();
    },
      (error) => {
        this.toaster.error(error.error);
      });
  }

  save(isNavigate: boolean) {
    //this.examModel.omR_ContactRX.doctorsSignature = this.imgURL;
    this.examModel.omR_ContactRX.doctorName = this.user.first_name_txt + ' ' + this.user.last_name_txt;
    this.examModel.omR_ContactRX.license = this.user.license_txt;
    this.examModel.omR_ContactRX.doctorID = this.user.qeUserID;
    if (this.examModel.exam_ID == '') {
      debugger;
      this.examModel.omR_ContactOrderForm.odPower = this.examModel.omR_ContactRX.odPowerT;
      this.examModel.omR_ContactOrderForm.odDiam = this.examModel.omR_ContactRX.odDiameterT;
      this.examModel.omR_ContactOrderForm.odbc = this.examModel.omR_ContactRX.odBaseCurveT;
      this.examModel.omR_ContactOrderForm.odLens = this.examModel.omR_ContactRX.odBrandT;
      this.examModel.omR_ContactOrderForm.odManufacturer = this.examModel.omR_ContactRX.odManufacturerT;

      this.examModel.omR_ContactOrderForm.osPower = this.examModel.omR_ContactRX.osPowerT;
      this.examModel.omR_ContactOrderForm.osDiam = this.examModel.omR_ContactRX.osDiameterT;
      this.examModel.omR_ContactOrderForm.osbc = this.examModel.omR_ContactRX.osBaseCurveT;
      this.examModel.omR_ContactOrderForm.osLens = this.examModel.omR_ContactRX.osBrandT;
      this.examModel.omR_ContactOrderForm.osManufacturer = this.examModel.omR_ContactRX.osManufacturerT;

      this.saveExam();
      this.saveSpectacle();
    }
    else {
      debugger;
      this.examModel.omR_ContactOrderForm.odPower = this.examModel.omR_ContactRX.odPowerT;
      this.examModel.omR_ContactOrderForm.odDiam = this.examModel.omR_ContactRX.odDiameterT;
      this.examModel.omR_ContactOrderForm.odbc = this.examModel.omR_ContactRX.odBaseCurveT;
      this.examModel.omR_ContactOrderForm.odLens = this.examModel.omR_ContactRX.odBrandT;
      this.examModel.omR_ContactOrderForm.odManufacturer = this.examModel.omR_ContactRX.odManufacturerT;

      this.examModel.omR_ContactOrderForm.osPower = this.examModel.omR_ContactRX.osPowerT;
      this.examModel.omR_ContactOrderForm.osDiam = this.examModel.omR_ContactRX.osDiameterT;
      this.examModel.omR_ContactOrderForm.osbc = this.examModel.omR_ContactRX.osBaseCurveT;
      this.examModel.omR_ContactOrderForm.osLens = this.examModel.omR_ContactRX.osBrandT;
      this.examModel.omR_ContactOrderForm.osManufacturer = this.examModel.omR_ContactRX.osManufacturerT;

      this.updateExam();
      this.updateSpectacle();
    }
    if (isNavigate) {
      this._clinicalService.navigateToClinic();
    }
  }
  saveAndCloseExam(){
    this._clinicalService.saveExam(this.examModel).subscribe(res => {
      this.examModel = res;
      this.omr_SpectacleOrderFormModel.exam_ID = this.examModel.exam_ID;
    });
  }
  updateAndCloseExam(){
    this._clinicalService.updateExam(this.examModel).subscribe(res => {
      this.examModel = res;
      this.omr_SpectacleOrderFormModel.exam_ID = this.examModel.exam_ID;

    });
  }
  saveAndClose(){
    this.examModel.omR_ContactRX.doctorName = this.user.first_name_txt + ' ' + this.user.last_name_txt;
    this.examModel.omR_ContactRX.license = this.user.license_txt;
    this.examModel.omR_ContactRX.doctorID = this.user.qeUserID;
    if (this.examModel.exam_ID == '') {
      this.saveAndCloseExam();
      this.saveSpectacle();
    }
    else {
      this.updateAndCloseExam();
      this.updateSpectacle();
    }
  }

  saveSpectacle() {
    this._clinicalService.saveSpectacle(this.omr_SpectacleOrderFormModel).subscribe(res => {
      this.omr_SpectacleOrderFormModel = res;
    });
  }

  updateSpectacle() {
    this._clinicalService.updateSpectacle(this.omr_SpectacleOrderFormModel).subscribe(res => {
      this.omr_SpectacleOrderFormModel = res;
    });
  }

  copyPower() {
    this.examModel.omR_ContactRX.odPowerB = this.examModel.omR_ContactRX.odPowerT;
    this.examModel.omR_ContactRX.osPowerB = this.examModel.omR_ContactRX.osPowerT;
  }

  getUser() {
    debugger;
    let loadingUser = new UserModel();
    loadingUser.display_txt = "Loading Records...";
    this.userList.push(loadingUser);
    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    this.user1 = this.userList.filter(x => x.user_id == this.examModel.provider_ID);
    this.user = this.user1[0];
    if (this.user1[0]?.npi_txt != null) {
      this.License = this.user1[0]?.npi_txt
    }
    // if (this.user1[0]?.email_address_txt) {
    //   this.officeemail = this.user1[0]?.email_address_txt;
    // }
    // if (this.examModel.omR_ContactRX.doctorID==0) {
    //   this.onSelectUser(this.examModel.provider_ID);
    // }
    // else{
    //   this.onSelectUser(this.examModel.omR_ContactRX.doctorID);
    // }
    this.onSelectUser(this.examModel.omR_Prescriptions.provider_ID);

    // let practiceId = localStorage.getItem('practice')?.toString();
    // this._lookupService.getRenderingProvider(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
    //   this.userList = res;
    //   this.user1 = this.userList.filter(x => x.user_id == this.examModel.provider_ID);
    //   if (this.user1[0]?.license_txt) {
    //     this.License = this.user1[0]?.license_txt
    //   }
    //   if (this.examModel.omR_ContactRX.doctorID==0) {
    //    this.onSelectUser(this.examModel.provider_ID);
    //   }
    //   else{
    //     this.onSelectUser(this.examModel.omR_ContactRX.doctorID);
    //   }

    // }, (error) => {
    //   this.toaster.error(error.error);
    // });
  }

  loadDoctor(id: number) {
    debugger;
    this.user = this.userList.find(a => a.qeUserID == id);
    if (this.user) {
      this.imgURL = this.user?.signature_img ? 'data:image/png;base64,' + this.user.signature_img : '';
    } else {
      this.imgURL = '';
      this.user = new UserModel();
    }
    return true;
  }

  onSelectUser(id: any) {
    debugger;
    this.user = this.userList.find(a => a.user_id == id);
    if(!this.user){
      this.user = this.userList.find(a => a.qeUserID == id);
    }
    if (this.user) {
      if (this.user.npi_txt != null) {
        this.License = this.user.npi_txt
      }
      this.imgURL = this.user?.signature_img ? 'data:image/png;base64,' + this.user.signature_img : '';
    } else {
      this.imgURL = '';
      this.user = new UserModel();
    }
    return true;
  }

  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.baseCurveLookup = res.filter(x => x.group_txt.toLowerCase() == 'bc'.toLowerCase() && x.active_ind == true);
      this.baseCurveLookup = this.baseCurveLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.baseCurveCopyLookup = res.filter(x => x.group_txt.toLowerCase() == 'bc'.toLowerCase() );

      this.diamLookup = res.filter(x => x.group_txt.toLowerCase() == 'diam'.toLowerCase() && x.active_ind == true);
      this.diamLookup = this.diamLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.diamCopyLookup = res.filter(x => x.group_txt.toLowerCase() == 'diam'.toLowerCase());

      this.bcLookup = res.filter(x => x.group_txt.toLowerCase() == 'bc'.toLowerCase() && x.active_ind == true);
      this.bcLookup = this.bcLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.bcLookupCopy =  res.filter(x => x.group_txt.toLowerCase() == 'bc'.toLowerCase());

      this.brandLookup = res.filter(x => x.group_txt.toLowerCase() == 'brand'.toLowerCase() && x.active_ind == true);
      this.brandLookup = this.brandLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.brandopyLookup = res.filter(x => x.group_txt.toLowerCase() == 'brand'.toLowerCase());

      this._6PackCopyLookup = res.filter(x => x.group_txt.toLowerCase() == '6pack'.toLowerCase());
      this._6PackLookup = res.filter(x => x.group_txt.toLowerCase() == '6pack'.toLowerCase() && x.active_ind == true);
      this._6PackLookup = this._6PackLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.cleaningSolutionLookup = res.filter(x => x.group_txt.toLowerCase() == 'cleaningsolution'.toLowerCase() && x.active_ind == true);
      this.cleaningSolutionLookup = this.cleaningSolutionLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.cleaningSolutionLookup = res.filter(x => x.group_txt.toLowerCase() == 'cleaningsolution'.toLowerCase() && x.active_ind == true);
      this.cleaningSolutionLookup = this.cleaningSolutionLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.RemarksCopyLookup = res.filter(x => x.group_txt.toLowerCase() == 'Remarks'.toLowerCase() && x.active_ind == true);
      this.RemarksCopyLookup = this.RemarksCopyLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });

    })
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getAllLookup();
      this.getConfigLookup();
    })
  }

  editManufacture() {
    const initialState = {
      componentName: 'Manufacture',
      title: 'ManufactureWindow'
    };
    this.bsModalRef = this.modalService.show(ComponentModalComponent, { initialState });
    this.bsModalRef.setClass('modal-xl')
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getManufacture()
    })
  }
  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }
  CreateNew(city:string){

  }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any,name_txt: string, item: LookupModel[], group_txt: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {

    //if(this.check_value == ""){
    this.check_value = val;
    //}
    if (this.check_value != '') {
      var index = item.findIndex(
        (x) =>
          x.name_txt.trim().toLowerCase() ==
          this.check_value.trim().toLowerCase()
      );
      // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
      // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      if (index < 0) {
        var lookupModel = new LookupModel();
        if (item[0]?.group_txt) {
          lookupModel.group_txt = item[0]?.group_txt;
          lookupModel.sub_group_txt = item[0]?.sub_group_txt;
        } else {
          lookupModel.group_txt = group_txt!;
          lookupModel.sub_group_txt = 'All';
        }
        lookupModel.active_ind = true;
        lookupModel.name_txt = val;

        if (this.check_value != lookupModel.name_txt) {
          lookupModel.name_txt = this.check_value;
        }
        if (dbType != 1) {
          var content = 'Would you like to save this ' + name_txt + ' list?';
          this.globalService
            .modal(content, 'New Item?', 'OK', 'Cancel')
            .subscribe((res) => {
              if (res.res) {
                // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
                if (dbType == DbEnum.QE_DEV_SQL_DB) {
                  this._lookupService
                    .insertLookup(lookupModel)
                    .subscribe((res) => {
                      if(lookupModel.group_txt == "brand"){
                        this.mapProduct(lookupModel);
                        this._lookupService.insertItem(this.productModel).subscribe(res => {
                          console.log(res);
                        });
                      }
                      this.getAllLookup();
                      this.toaster.success('Record Saved Successfully.');
                      item1[property]= val;//this.check_value;
                    });
                }
                // else {
                //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
                //     this.getConfigLookup();
                //     this.toaster.success('Record Saved Successfully.');
                //     item1[property]=this.check_value;
                //     this.getConfigLookup();
                //   })
                // }
              }
            });
        }
        item1[property] = val;
      } else {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }
  mapProduct(brandModel: LookupModel){
    this.productModel.active_ind = true;
    this.productModel.name_txt = brandModel.name_txt;
    this.productModel.description_txt = brandModel.name_txt;
    this.productModel.brand = brandModel.name_txt;
    this.productModel.type_id = 1;
  }
  ngOnDestroy(): void {

    var result = this.globalService.isFormEdited(this.examModel, this.examModelCopy);
    if(!result){
      this.saveAndClose();
    }
  }

}
