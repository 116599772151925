import { ExamModel } from "./ExamModel";

export class OMR_ContactOrderFormModel {
    public exam_ID : string = '';
    public date : Date = new Date();
    public odLens : string = ''; 
    public odManufacturer : string = '';
    public odAccount : string = '';
    public odPhone : string = '';
    public odPower : string = '';
    public odbc : string = '';
    public odDiam : string = '';
    public osLens : string = '';
    public osManufacturer : string = '';
    public osAccount : string = '';
    public osPhone : string = '';
    public osPower : string = '';
    public osbc : string = '';
    public osDiam : string = '';
    public trials : boolean = false;
    public supply : boolean = false;
    public amountCLS : string = '';
    public orderedBy : string = '';
    public ref : string = '';
    public dateOrdered !: Date;
    public comment1 : string = '';
    public receivedBy : string = '';
    public dateReceived !: Date;
    public comment2 : string = '';
    public checkedOk : boolean = false;
    public patientOwes : string = '';
    public patientCalled : boolean = false;
    public dispensedBy : string = '';
    public comment3 : string = '';
    public dateDispensed !: Date;
    public amountDispense : string = '';
    public timeReceived : string = '';
    public timeOrdered : string = '';
    public notification_ind : boolean = false;
    // public exam : ExamModel = new ExamModel();
}