import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from './../../../services/user.service';
import { OMR_FeeStatementPaymentModel } from './../../../model/omr_FeeStatementPaymentModel';
import { LookupModel } from './../../../model/lookupModel';
import { GlobalService } from './../../../services/global.service';
import { InsuranceModel } from './../../../model/insuranceModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { ChargeModel } from './../../../model/chargeModel';
import { OMR_FeeStatementDiagnosisModel } from './../../../model/omr_FeeStatementDiagnosisModel';
import { OMR_FeeStatementServiceModel } from './../../../model/omr_FeeStatementServiceModel';
import { OMR_FeeStatementModel } from './../../../model/omr_FeeStatementModel';
import { OfficeModel } from './../../../model/officeModel';
import { LookupService } from './../../../services/lookup.service';
import { Component, OnInit } from '@angular/core';
import { ProviderModel } from 'src/app/model/providerModel';
import { AddChargesComponent } from '../add-charges/add-charges.component';
import { AddProductComponent } from '../add-product/add-product.component';
import { OMR_FeeStatementItemModel } from 'src/app/model/omr_FeeStatementItemModel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';
import { Omr_FeeStatementservice } from 'src/app/services/omr_FeeStatement.service';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PatientModel } from 'src/app/model/patientModel';
import { DatePipe, DecimalPipe,ViewportScroller } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { UserModel } from 'src/app/model/userModel';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ComponentModalComponent } from 'src/app/shared/component-modal/component-modal.component';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import * as moment from 'moment';
import { EMPTY, pairs } from 'rxjs';
import { parseInt } from 'lodash';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { ExamModel } from 'src/app/model/ExamModel';
import { threadId } from 'worker_threads';
import { unchangedTextChangeRange } from 'typescript';
import { ItemModel } from 'src/app/model/ItemModel';
import { ProcedureModel } from 'src/app/model/ProcedureModel';
import { Exam_AssessmentsModel } from 'src/app/model/Exam_AssessmentsModel';

@Component({
  selector: 'app-misc-free-statement',
  templateUrl: './misc-free-statement.component.html',
  styleUrls: ['./misc-free-statement.component.scss'],
  providers: [DatePipe],
})
export class MiscFreeStatementComponent implements OnInit {
  rowInsurance: InsuranceModel[] = [];
  insuranceData: InsuranceModel[] = [];
  date: Date = new Date();
  providerList: ProviderModel[] = [];
  provider: ProviderModel[] = [];
  office: OfficeModel[] = [];
  officelist: OfficeModel[] = [];
  omr_FeeStatementModel: OMR_FeeStatementModel = new OMR_FeeStatementModel();
  omr_FeeStatementModel1: OMR_FeeStatementModel = new OMR_FeeStatementModel();
  omr_FeeStatementModelCopy: OMR_FeeStatementModel = new OMR_FeeStatementModel();
  // serviceCharge: ChargeModel[] = [];
  serviceCharge: ProcedureModel[] = [];
  //ProductCharge: ChargeModel[] = [];
  ProductCharge: ItemModel[] = [];
  ProductList: ItemModel[] = [];
  //chargeslist: ChargeModel[] = [];
  chargeslist: ProcedureModel[] = [];
  RemarksCopyLookup: LookupModel[] = [];
  showInsuarance: boolean = false;
  object: Array<any> = [];
  object1: Array<any> = [];
  providerString: string = '';
  BalanceValue: string = '';
  //showInsurance: boolean = false;
  showTest: boolean = false;
  totalServiceUnitAmount: number = 0.0;
  totalServiceAmountWithQuantity: number = 0.0;
  totalServiceDiscount: number = 0.0;
  totalServiceSubtotal: number = 0.0;
  totalServicePatPaid: number = 0.0;
  totalServiceInsPaid: number = 0.0;
  totalServiceBalance: number = 0.0;
  totalServiceTax: number = 0.0;
  totalProductUnitAmount: number = 0.0;
  totalProductAmountWithQuantity: number = 0.0;
  totalProductDiscount: number = 0.0;
  totalProductSubtotal: number = 0.0;
  totalProductPatPaid: number = 0.0;
  totalProductInsPaid: number = 0.0;
  totalProductBalance: number = 0.0;
  Fee_Amt_product: number = 0.0;
  Fee_Amt_Service: number = 0.0;
  totalProductTax: number = 0.0;
  totalUnitAmount: number = 0.0;
  totalDiscount: number = 0.0;
  totalSubTotal: number = 0.0;
  totalPatpaid: number = 0.0;
  totalInsPaid: number = 0.0;
  totalBalance: number = 0.0;
  totalTax: number = 0.0;
  fee_statement_id: string = '';
  columnInsurance: any = [];
  columnCharge: any = [];
  columnPayment: any = [];
  rowPayment: OMR_FeeStatementPaymentModel[] = [];
  rowStyle: any;
  paymentTypeLookup: LookupModel[] = [];
  assessmentLookup: LookupModel[] = [];
  dxNumLookup: LookupModel[] = [];
  patientDetail: PatientModel = new PatientModel();
  groupedData: any = [];
  isExamTab: boolean = false;
  buttonsave: string = '';
  buttoncancle: string = '';
  cancleRouterlink: string = '';
  usersList: UserModel[] = [];
  userList: UserModel[] = [];
  user: any = new UserModel();
  user1: any = new UserModel();
  examModel: ExamModel = new ExamModel();
  imgURL: any;
  bsModalRef!: BsModalRef;
  officename: string = '';
  License: string = '';
  officeaddress: string = '';
  officeCitySZ: string = '';
  officephone: string = '';
  officeemail: string = '';
  office_npi_txt: string = '';
  office_license_txt: string = '';
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  paymentTypeCopyLookup: LookupModel[] = [];
  assessmentCopyLookup: LookupModel[] = [];
  dxNumCopyLookup: LookupModel[] = [];
  patientId: string = '';
  examId: string = '';
  Provider: string = '';
  public grid!: GridOptions;
  check_value: string = '';
  currentDate: Date = new Date();
  exam_Assessments: Exam_AssessmentsModel[] = [];

  constructor(
    public modalService: NgbModal,
    private _modalService: BsModalService,
    private _omr_FeeStatementservice: Omr_FeeStatementservice,
    private route: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    public globalService: GlobalService,
    private datePipe: DatePipe,
    private _clinicalService: ClinicalService,
    private _userService: UserService,
    private _lookupService: LookupService,
    private viewportScroller: ViewportScroller
  ) {
    this.route.queryParams.subscribe((params: any) => {

      if (params['feestatementId']) {
        this.fee_statement_id = params['feestatementId'];
      }
      if (params['isExamTab']) {
        this.isExamTab = params['isExamTab'];
      }
      if (params['examId']) {
        this.examId = params['examId'];
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.globalService.changeCommonLayout(true);
    }, 100);
  }

  ngOnInit() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    this.viewportScroller.scrollToPosition([0, 0]);
    this.bsConfig = Object.assign(
      {},
      { containerClass: 'theme-dark-blue', showWeekNumbers: false }
    );

    this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
    if (this.patientDetail.patientInsurances.length > 0) {
      debugger;
      this.omr_FeeStatementModel.insurance_id = this.patientDetail.patientInsurances[0].insurance_id;
      this.omr_FeeStatementModel.insurance_display_txt = this.patientDetail.patientInsurances[0].insuranceName;
    }
    this.patientId = this.patientDetail.patient_id;

    this.omr_FeeStatementModel.office_id = this.patientDetail.defaultOfficeID;
    this.date = new Date();
    this.columnInsurance = [
      {
        headerName: 'Name',
        field: 'display_txt',
        sortable: true,
        resizable: true,
        width: 100,
      },
      {
        headerName: 'Address',
        field: 'address1_txt',
        sortable: true,
        resizable: true,
        width: 200,
        cellRenderer: (params: any) => {
          if (
            params.data.address1_txt != null &&
            params.data.address2_txt != null
          ) {
            return params.data.address1_txt + ' - ' + params.data.address2_txt;
          } else if (
            params.data.address1_txt != null &&
            params.data.address2_txt == null
          ) {
            return params.data.address1_txt;
          } else if (
            params.data.address1_txt == null &&
            params.data.address2_txt != null
          ) {
            return params.data.address2_txt;
          } else {
            return '';
          }
        },
        // valueGetter: function (params: any) {
        //   return params.data.address1_txt + " - " + params.data.address2_txt
        // }
      },
    ];

    this.columnCharge = [
      {
        headerName: 'Code',
        field: 'code_txt',
        sortable: true,
        resizable: true,
        width: 100,
      },
      {
        headerName: 'Name',
        field: 'name_txt',
        sortable: true,
        resizable: true,
        width: 200,
      },
    ];

    this.columnPayment = [
      {
        headerName: 'Payment Date',
        field: 'payment_dt',
        sortable: true,
        resizable: true,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY');
        },
      },
      {
        headerName: 'Payment Type',
        field: 'payment_type_txt',
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Payment Description',
        field: 'description_txt',
        sortable: true,
        resizable: true,
        width: 400,
      },
      {
        headerName: 'Amount',
        field: 'payment_amt',
        sortable: true,
        resizable: true,
        valueFormatter: function (params: any) {
          return `$${Number(params.data.payment_amt).toFixed(2)}`;
        },
      },
    ];

    // this.omr_FeeStatementModel.OMR_FeeStatementDiagnosis.push(new OMR_FeeStatementDiagnosisModel());
    this.getData();
    this.object = [
      {
        key: 1,
        value: 1,
      },
      {
        key: 2,
        value: 2,
      },
      {
        key: 3,
        value: 3,
      },
      {
        key: 4,
        value: 4,
      },
      {
        key: 5,
        value: 5,
      },
      {
        key: 6,
        value: 6,
      },
    ];
    this.object1 = this.object;
    this.getExtraTest();
    debugger;
    this.isExamTab
      ? this.getMiscFeeStatementByID()
      : this.getMiscFeeStatement();
    this.omr_FeeStatementModel.type_txt = this.isExamTab ? 'Exam' : 'Misc';
    this.buttonsave = this.isExamTab ? 'Save Exam' : ' Save Fee Statement';



    // this.omr_FeeStatementModel.dos_dt = this.isExamTab
    //   ? this.omr_FeeStatementModel.dos_dt
    //   : new Date().toDateString(); //this.patientDetail.dateOfEntry;
    this.omr_FeeStatementModel.dos_dt = new Date().toDateString();
    this.buttoncancle = this.isExamTab
      ? 'Cancel Exam'
      : ' Cancel Fee Statement';
    this.cancleRouterlink = this.isExamTab
      ? '/patient/add-exam'
      : '/patient/create';
  }

  goProducts() {

    this.isExamTab
      ? this.router.navigate(['/patient/add-exam'], {
          queryParams: {
            patientId: this.patientDetail.patientID,
            examId: this.examId,
          },
        })
      : this.router.navigate(['/patient/create'], {
          queryParams: { patientId: this.patientDetail.patientID },
        });
  }

  getExtraTest() {

    if (this.isExamTab) {
      this._clinicalService.getByExamId(this.examId).subscribe((res) => {
        this.examModel = res;
        if (this.omr_FeeStatementModel.dos_dt == new Date().toDateString()) {
          this.omr_FeeStatementModel.dos_dt = this.examModel.exam_Date.toString();
        }
        if (!this.omr_FeeStatementModel.signing_provider_id) {
          this.omr_FeeStatementModel.signing_provider_id =
            this.examModel.provider_ID;
          this.onSelectUser(this.examModel.provider_ID);
        }
        this.getUser();
      });
    }else{
      if (!this.omr_FeeStatementModel.signing_provider_id) {
        this.omr_FeeStatementModel.signing_provider_id =
          this.examModel.provider_ID;
        this.onSelectUser(this.examModel.provider_ID);
      }
    }
  }
  getData() {
    this.getInsurance();
    this.getOffice();
    this.getProvider();
    this.getCharge('');
    this.getGlobalLookup();
    if (!this.isExamTab) {
      this.getUser();
    }
    this.getAllLookup();
  }

  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.RemarksCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'Messages'.toLowerCase() && x.active_ind == true);
      // this.manufactureLookup = res.filter(x => x.group_txt.toLowerCase() == 'bc'.toLowerCase());
      // this.manufactureCopyLookup = res.filter(x => x.group_txt.toLowerCase() == 'bc'.toLowerCase());
      // this.showComponent = true;
      debugger;
      var paymentTypeLookup = res.filter((x) => x.group_txt.toLowerCase() == 'feepaymenttype'.toLowerCase() && x.active_ind == true);
      var combinepaymentTypeLookups = this.paymentTypeLookup.concat(paymentTypeLookup);
      this.paymentTypeLookup = combinepaymentTypeLookups;
      this.paymentTypeLookup = this.compare(this.paymentTypeLookup);
      this.paymentTypeCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'feepaymenttype'.toLowerCase());

    });
  }
  compare(array: LookupModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }
  getProvider() {
    // let practiceId = localStorage.getItem('practice')?.toString();
    //   practiceId = practiceId != undefined ? practiceId : "";
    // this._lookupService.getRenderingProvider(practiceId).subscribe((res: any) => {
    //   // this.provider = [];
    //   this.providerList = res;
    // })
    //this._lookupService.getProvider().subscribe((res: any) => {
    //   this.provider = Object.assign([], res);
    //   this.findProviders();
    // })
    //-----------------------------------------------
    // let practiceId = localStorage.getItem('practice')?.toString();
    // practiceId = practiceId != undefined ? practiceId : "";
    // this._lookupService.getRenderingProvider(practiceId).subscribe((res: any) => {
    //   this.provider = Object.assign([], res);
    //   this.findProviders();
    // })
    let res = JSON.parse(localStorage.getItem('Provider')!);
    this.provider = Object.assign([], res);
    this.usersList = Object.assign([], res);
    this.userList = Object.assign([], res);
    this.findProviders();
  }
  findProviders() {
    if (this.providerString == '') {
      this.providerList = this.provider;
      this.omr_FeeStatementModel.provider_id =
        this.patientDetail.referring_provider_id;
    } else {
      this.providerList = this.provider.filter((x) =>
        x.display_txt.toLowerCase().includes(this.providerString.toLowerCase())
      );
    }
  }

  getGlobalLookup() {
    this._lookupService.getGlobalLookup('feepaymenttype').subscribe((res: LookupModel[]) => {
        this.paymentTypeLookup = res.filter((x) => x.active_ind == true);
        //this.paymentTypeLookup = res;
        //this.paymentTypeCopyLookup = res;
      });
    this._lookupService
      .getGlobalLookup('assessment')
      .subscribe((res: LookupModel[]) => {
        this.assessmentLookup = res.filter((x) => x.active_ind == true);
        this.assessmentLookup = res;
        this.assessmentCopyLookup = res;
      });
    this._lookupService
      .getGlobalLookup('dxnum')
      .subscribe((res: LookupModel[]) => {
        this.dxNumLookup = res.filter(
          (x) => x.active_ind == true && isNaN(+x.name_txt) == true
        );
        //this.dxNumLookup = res;
        this.dxNumCopyLookup = res;
      });
    this._lookupService
      .getGlobalLookup('Messages')
      .subscribe((res: LookupModel[]) => {
        this.RemarksCopyLookup = res.filter((x) => x.active_ind == true);
        this.RemarksCopyLookup = res;
      });
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  CreateNew(city: string) {}

  saveDropdown(val: any,name_txt: string,item: LookupModel[],group_txt: string,item1?: any,property?: any,dbType: number = DbEnum.QE_DEV_SQL_DB) {
    debugger;
    //if(this.check_value == ""){
    this.check_value = val;
    //}
    if (this.check_value != '') {
      var index = item.findIndex((x) => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
      // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
      // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      if (index < 0) {
        var lookupModel = new LookupModel();
        if (item[0]?.group_txt) {
          lookupModel.group_txt = item[0]?.group_txt;
          lookupModel.sub_group_txt = item[0]?.sub_group_txt;
        } else {
          lookupModel.group_txt = group_txt!;
          lookupModel.sub_group_txt = 'All';
        }
        var sort_val = item.reduce((x, y) => x.sort_val > y.sort_val? x : y).sort_val;
        lookupModel.sort_val = Number(sort_val) + 1;
        lookupModel.active_ind = true;
        lookupModel.name_txt = val;

        if (this.check_value != lookupModel.name_txt) {
          lookupModel.name_txt = this.check_value;
        }
        if (dbType != 1) {
          var content = 'Would you like to save this ' + name_txt + ' list?';
          this.globalService
            .modal(content, 'New Item?', 'OK', 'Cancel')
            .subscribe((res) => {
              if (res.res) {
                // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
                if (dbType == DbEnum.QE_DEV_SQL_DB) {
                  this._lookupService
                    .insertLookup(lookupModel)
                    .subscribe((res) => {
                      this.getAllLookup();
                      this.toaster.success('Record Saved Successfully.');
                      item1[property] = val; //this.check_value;
                    });
                }
                // else {
                //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
                //     this.toaster.success('Record Saved Successfully.');
                //     item1[property]=this.check_value;
                //     this.getGlobalLookup();
                //   })
                // }
              }
            });
        }
        item1[property] = val;
      } else {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService
      .updateLookup(itemCopy, dbType)
      .subscribe((res: LookupModel[]) => {
        this.getGlobalLookup();
        this.getAllLookup();
        // this.sortArray(item)
      });
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  getInsurance() {
    debugger;
    this._lookupService.getInsuarance().subscribe((res: any) => {
      this.insuranceData = res;
      this.rowInsurance = res;
      if(this.patientDetail.patientInsurances.length > 0){
        let insRow = this.rowInsurance.filter((x) => x.display_txt == this.patientDetail.patientInsurances[0].insuranceName);
        this.omr_FeeStatementModel.insurance_id = insRow[0]?.insurance_id;
        this.omr_FeeStatementModel.insurance_display_txt = insRow[0]?.display_txt;
      }
    });
  }

  getOffice() {
    this._lookupService.getOffice().subscribe((res: any) => {
        this.office = res;
        this.office = this.office.filter((x) => x.office_id == this.patientDetail.defaultOfficeID);
        this.officelist = this.office;
        // if (this.officelist[0]?.name_txt != null) {
        //   this.officename = this.officelist[0]?.name_txt
        // }
        // if (this.officelist[0]?.address_1_txt != null && this.officelist[0].address_2_txt) {
        //   this.officeaddress = this.officelist[0]?.address_1_txt + " " + this.officelist[0]?.address_2_txt;
        // }
        // if (this.officelist[0]?.phone_1_txt != null) {
        //   this.officephone = this.officelist[0]?.phone_1_txt;
        // }
        if (this.office[0]?.name_txt != null) {
          this.officename = this.office[0]?.name_txt;
        }
        // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
        //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
        // }
        if (this.office[0]?.address_1_txt) {
          this.officeaddress = `${this.office[0]?.address_1_txt}`;
        }
        if(this.office[0]?.address_2_txt){
          this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
        }
        if (this.office[0]?.city_txt) {
          this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
        }
        if (this.office[0]?.phone_1_txt) {
          this.officephone = this.office[0]?.phone_1_txt;
        }
        if(this.office[0]?.npi_txt){
          this.office_npi_txt = this.office[0]?.npi_txt;
        }
        if(this.office[0]?.email_address_txt){
          this.officeemail = this.office[0]?.email_address_txt;
        }
        if(this.office[0].license_txt){
          this.office_license_txt = this.office[0].license_txt;
        }
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }

  getCharge(event: any) {

    // this._lookupService.getCharge().subscribe((res: any) => {
    //   this.serviceCharge = res;
    //   //this.ProductCharge = res;
    //   this.chargeslist = res;
    // })
    this._lookupService.getProcedure().subscribe((res: ProcedureModel[]) => {
      let services = res.filter((x) => x.active_ind == true);
      this.serviceCharge = services;
      //this.ProductCharge = res;
      this.chargeslist = services;
    });
    this._lookupService.getItems().subscribe((res: ItemModel[]) => {
      //this.serviceCharge = res;
      let products = res.filter((x) => x.active_ind == true);
      this.ProductCharge = products;
      this.ProductList = products;
      //this.chargeslist = res;
    });
  }

  dropDownGridSearch(searchVal: any) {
    return this.chargeslist.filter((item) => {
      if (item.code_txt.toLocaleLowerCase().indexOf(searchVal) !== -1) {
        return item;
      }
      if (item.name_txt.toLocaleLowerCase().indexOf(searchVal) !== -1) {
        return item;
      }
      return;
    });
  }
  dropDownProductGridSearch(searchVal: any) {
    return this.ProductList.filter((item) => {
      if (item.code_txt.toLocaleLowerCase().indexOf(searchVal) !== -1) {
        return item;
      }
      if (item.name_txt.toLocaleLowerCase().indexOf(searchVal) !== -1) {
        return item;
      }
      return;
    });
  }

  Servicetransform(event: any) {
    let searchVal = event.target.value.toLocaleLowerCase();
    this.serviceCharge = this.dropDownGridSearch(searchVal);
    if (this.serviceCharge.length == 0) {
      this.serviceCharge = this.chargeslist;
    }
  }
  Producttransform(event: any) {
    let searchVal = event.target.value.toLocaleLowerCase();
    //this.ProductCharge = this.dropDownGridSearch(searchVal);
    this.ProductCharge = this.dropDownProductGridSearch(searchVal);
    ////this.ProductCharge= this.chargeslist.filter(it => it.name_txt.toLocaleLowerCase().indexOf(event.target.value.toLocaleLowerCase())!== -1);
    if (this.ProductCharge.length==0) {
      this.ProductCharge =  this.ProductList ;
    }
  }

  addService() {
    debugger;
    var omr_FeeStatementServiceModel = new OMR_FeeStatementServiceModel();
    // omr_FeeStatementServiceModel.dos_dt = this.datePipe.transform( omr_FeeStatementServiceModel.dos_dt, 'yyyy-MM-dd');
    omr_FeeStatementServiceModel.dos_dt = new Date().toDateString();
    this.omr_FeeStatementModel.omR_FeeStatementService.push(omr_FeeStatementServiceModel);
  }

  deleteService(index: number) {
    debugger;
    this.omr_FeeStatementModel.omR_FeeStatementService.splice(index, 1);
    this.addPaymentAndChargebacks();
    this.subtractServiceTotal(index);
  }

  addPayForService(i: number) {
    var omr_FeeStatementPaymentModel = new OMR_FeeStatementPaymentModel();
    omr_FeeStatementPaymentModel.payment_dt = new Date().toDateString();
    this.omr_FeeStatementModel.omR_FeeStatementService[i].omR_FeeStatementPayment.push(omr_FeeStatementPaymentModel);
  }

  deletePayForService(i: number, index: number) {
    this.subtractPayForService(i, index);
    this.omr_FeeStatementModel.omR_FeeStatementService[i].omR_FeeStatementPayment.splice(index, 1);
    this.makeTotalForService(i);
    this.addPaymentAndChargebacks();
  }

  addDiagnosis() {
    this.omr_FeeStatementModel.omR_FeeStatementDiagnosis.push(
      new OMR_FeeStatementDiagnosisModel()
    );
  }

  deleteDiagnosis(index: number) {
    return this.omr_FeeStatementModel.omR_FeeStatementDiagnosis.splice(index,1);
  }

  addProduct(type: string) {
    var omr_FreeStatementItemModel = new OMR_FeeStatementItemModel();
    if (type == 'sold') {
      omr_FreeStatementItemModel.status_txt = 'Sold';
    } else {
      omr_FreeStatementItemModel.status_txt = 'Return';
    }
    omr_FreeStatementItemModel.dos_dt = new Date().toDateString();
    this.omr_FeeStatementModel.omR_FeeStatementItem.push(omr_FreeStatementItemModel);
  }

  deleteProduct(index: number) {
    this.omr_FeeStatementModel.omR_FeeStatementItem.splice(index, 1);
    this.addPaymentAndChargebacks();
    this.subtractProductTotal(index);
  }

  addPayForProduct(i: number) {
    var omr_FeeStatementPaymentModel = new OMR_FeeStatementPaymentModel();
    omr_FeeStatementPaymentModel.payment_dt = new Date().toDateString();
    this.omr_FeeStatementModel.omR_FeeStatementItem[i].omR_FeeStatementPayment.push(omr_FeeStatementPaymentModel);
  }

  deletePayForProduct(i: number, index: number) {
    this.subtractPayForProduct(i, index);
    this.omr_FeeStatementModel.omR_FeeStatementItem[i].omR_FeeStatementPayment.splice(index, 1);
    this.makeTotalForProduct(i);
    this.addPaymentAndChargebacks();
  }

  hideDropdown(value: string) {
    if (value == 'insurance') {
      this.showInsuarance = !this.showInsuarance;
    }
  }
  hideInsuranceDropdownOnBlur(value: string) {
    if (value == 'insurance') {
      this.showInsuarance = false;
    }
  }
  filterProvider() {}

  saveProvider() {}

  openProvider(value: any) {
    this.modalService.open(value);
    this.showInsuarance = false;
  }

  hideDropdownList(item: any, index: any) {
    let isShow = !item.isShow;
    // this.omr_FeeStatementModel.omR_FeeStatementService[index].OMR_FeeStatementPayment.forEach(element => {
    //   element.isShow = false;
    // });
    item.isShow = isShow;
    this.object1 = this.object;
  }
  hideDropdownListOnBlur() {
    this.showInsuarance = false;
    this.omr_FeeStatementModel.omR_FeeStatementService.forEach((element) => {
      element.isShow = false;
    });
    this.omr_FeeStatementModel.omR_FeeStatementItem.forEach((element) => {
      element.isShow = false;
    });
  }

  filter(item: any = '') {
    this.object1 =
      item == '' ? this.object : this.object.filter((t) => t.key == item);
  }

  setDropdown(
    item: any,
    outerIndex: number,
    innerIndex: number,
    selector: string,
    value: any
  ) {
    if (selector == 'productPay') {
      this.omr_FeeStatementModel.omR_FeeStatementItem[
        outerIndex
      ].omR_FeeStatementPayment[innerIndex].order_val = item.value;
    }
    if (selector == 'servicePay') {
      this.omr_FeeStatementModel.omR_FeeStatementService[
        outerIndex
      ].omR_FeeStatementPayment[innerIndex].payment_type_txt = item.value;
    }
    value.isShow = !value.isShow;
  }

  makeTotalForService(index: number, type?: string) {

    this.omr_FeeStatementModel.omR_FeeStatementService[index].ins_total = 0;
    this.omr_FeeStatementModel.omR_FeeStatementService[index].pat_total = 0;
    if (type == 'amt') {
      this.omr_FeeStatementModel.omR_FeeStatementService[
        index
      ].discount_per_amt = Math.round(
        (this.omr_FeeStatementModel.omR_FeeStatementService[index]
          .discount_amt *
          100) /
          (this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_amt *
            this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_cnt)
      );
    }
    if (type == 'per') {
      this.omr_FeeStatementModel.omR_FeeStatementService[index].discount_amt =
        (this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_amt *
          this.omr_FeeStatementModel.omR_FeeStatementService[index]
            .discount_per_amt) /
        100;
    }

    if(Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].tax_rate_amt) > 0){
      var taxAmount = ((this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_amt - this.omr_FeeStatementModel.omR_FeeStatementService[index].discount_amt) * this.omr_FeeStatementModel.omR_FeeStatementService[index].tax_rate_amt / 100).toFixed(2);
      this.omr_FeeStatementModel.omR_FeeStatementService[index].tax_amt = Number(taxAmount) * (this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_cnt);
    }

    this.omr_FeeStatementModel.omR_FeeStatementService[index].order_val =
      this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_amt *
        this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_cnt -
      this.omr_FeeStatementModel.omR_FeeStatementService[index].discount_amt +
      Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].tax_amt);

    this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt =
      this.omr_FeeStatementModel.omR_FeeStatementService[index].order_val;
    for (
      let i = 0.0;
      i <
      this.omr_FeeStatementModel.omR_FeeStatementService[index]
        .omR_FeeStatementPayment.length;
      i++
    ) {
      var paymentType =
        this.omr_FeeStatementModel.omR_FeeStatementService[
          index
        ].omR_FeeStatementPayment[i].payment_type_txt.toLocaleLowerCase();

      // this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt.replace('(', '');
      // this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt.replace(')', '');

      var free_value =
        Number(
          this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt
        ) -
        Number(
          this.omr_FeeStatementModel.omR_FeeStatementService[index]
            .omR_FeeStatementPayment[i].payment_amt
        );
      this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt =
        Number(Number(free_value).toFixed(2));

      if (paymentType.toLocaleLowerCase() != 'insurance chargeback') {
        if (paymentType.toLocaleLowerCase() != 'insurance payment') {
          this.omr_FeeStatementModel.omR_FeeStatementService[index].pat_total +=
            Number(
              this.omr_FeeStatementModel.omR_FeeStatementService[index]
                .omR_FeeStatementPayment[i].payment_amt
            );
        }
        if (paymentType == 'insurance payment') {
          this.omr_FeeStatementModel.omR_FeeStatementService[index].ins_total +=
            Number(
              this.omr_FeeStatementModel.omR_FeeStatementService[index]
                .omR_FeeStatementPayment[i].payment_amt
            );
          //this.omr_FeeStatementModel.omR_FeeStatementService[index].order_val - parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)
        }
      }

      // else {
      //   this.omr_FeeStatementModel.omR_FeeStatementService[index].pat_total =
      //     this.omr_FeeStatementModel.omR_FeeStatementService[index].order_val - parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)
      // }

      // if (Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) < 0) {
      //   this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}`
      //   this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}` + ')'
      //   this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.BalanceValue;

      //   //  parseInput(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)=parseInt(this.BalanceValue);
      //   // this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = parseInt('('+this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt+')');

      // }
      // else {
      //   this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}`
      // }
    }
    this.totalForService();
  }

  totalForService() {
    this.totalServiceUnitAmount = 0.0;
    this.totalServiceAmountWithQuantity = 0.0;
    this.totalServiceDiscount = 0.0;
    this.totalServiceSubtotal = 0.0;
    this.totalServicePatPaid = 0.0;
    this.totalServiceBalance = 0.0;
    this.totalServiceTax = 0.0;
    this.Fee_Amt_Service = 0.0;
    this.totalServiceInsPaid = 0.0;

    if (this.omr_FeeStatementModel.fee_statement_id != null) {
      for (let i = 0.0; i < this.omr_FeeStatementModel.omR_FeeStatementService.length; i++) {
        this.totalServiceAmountWithQuantity = this.totalServiceAmountWithQuantity + (Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].unit_amt) * Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].unit_cnt));
        this.totalServiceUnitAmount = this.totalServiceUnitAmount + Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].unit_amt);
        this.totalServiceDiscount = this.totalServiceDiscount + Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].discount_amt);
        this.totalServiceSubtotal =
          this.totalServiceSubtotal +
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementService[i].order_val
          );
        this.totalServicePatPaid =
          this.totalServicePatPaid +
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementService[i].pat_total
          );
        this.totalServiceInsPaid += Number(
          this.omr_FeeStatementModel.omR_FeeStatementService[i].ins_total
        );
        // if (this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt.toString().includes("(")) {
        //   this.Fee_Amt_Service = Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt.replace('(', ''));
        //   // this.Fee_Amt_Service = parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt.replace(')', ''));
        // }
        // else{
        //   this.Fee_Amt_Service = Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt);
        // }
        this.Fee_Amt_Service = Number(
          this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt
        );
        this.totalServiceBalance =
          this.totalServiceBalance + this.Fee_Amt_Service;
        // this.totalServiceBalance = this.totalServiceBalance + Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt);
        this.totalServiceTax =
          this.totalServiceTax +
          Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].tax_amt);
        this.totalInsPaid += this.totalServiceInsPaid; //= this.totalInsPaid + Number(this.omr_FeeStatementModel.omR_FeeStatementService[i].ins_total);
      }
      this.globalTotal();
    }
  }

  subtractServiceTotal(index: number) {
    this.totalServiceAmountWithQuantity = this.totalServiceAmountWithQuantity - (Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_amt) * Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_cnt));
    this.totalServiceUnitAmount = this.totalServiceUnitAmount - Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].unit_amt);
    this.totalServiceDiscount = this.totalServiceDiscount - Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].discount_amt);
    this.totalServiceSubtotal =
      this.totalServiceSubtotal -
      Number(
        this.omr_FeeStatementModel.omR_FeeStatementService[index].order_val
      );
    this.totalServicePatPaid =
      this.totalServicePatPaid -
      Number(
        this.omr_FeeStatementModel.omR_FeeStatementService[index].pat_total
      );
    this.totalServiceBalance =
      this.totalServiceBalance -
      Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt);
    this.totalServiceTax =
      this.totalServiceTax -
      Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].tax_amt);
    this.totalInsPaid =
      this.totalInsPaid -
      Number(
        this.omr_FeeStatementModel.omR_FeeStatementService[index].ins_total
      );
    this.globalTotal();
  }

  subtractPayForService(i: number, index: number) {
    var paymentType =
      this.omr_FeeStatementModel.omR_FeeStatementService[
        i
      ].omR_FeeStatementPayment[index].payment_type_txt.toLocaleLowerCase();
    if (paymentType.toLocaleLowerCase() != 'insurance chargeback') {
      if (paymentType.toLocaleLowerCase() != 'insurance payment') {
        this.omr_FeeStatementModel.omR_FeeStatementService[i].pat_total -=
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementService[i]
              .omR_FeeStatementPayment[index].payment_amt
          );
        this.totalServicePatPaid =
          this.totalServicePatPaid -
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementService[i]
              .omR_FeeStatementPayment[index].payment_amt
          );
      }

      if (paymentType == 'insurance payment') {
        this.omr_FeeStatementModel.omR_FeeStatementService[i].ins_total -=
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementService[i]
              .omR_FeeStatementPayment[index].payment_amt
          );
        this.totalServiceInsPaid =
          this.totalServiceInsPaid -
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementService[i]
              .omR_FeeStatementPayment[index].payment_amt
          );
      }
    }
    this.globalTotal();
  }

  makeTotalForProduct(index: number, type?: string) {
    this.omr_FeeStatementModel.omR_FeeStatementItem[index].ins_total = 0;
    this.omr_FeeStatementModel.omR_FeeStatementItem[index].pat_total = 0;
    if (type == 'amt') {
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].discount_per_amt =
        Math.round(
          (this.omr_FeeStatementModel.omR_FeeStatementItem[index].discount_amt *
            100) /
            (this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_amt *
              this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_cnt)
        );
    }
    if (type == 'per') {
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].discount_amt =
        (this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_amt *
          this.omr_FeeStatementModel.omR_FeeStatementItem[index]
            .discount_per_amt) /
        100;
    }
    if(Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].tax_rate_amt) > 0){
      var taxAmount = ((this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_amt - this.omr_FeeStatementModel.omR_FeeStatementItem[index].discount_amt) * this.omr_FeeStatementModel.omR_FeeStatementItem[index].tax_rate_amt / 100).toFixed(2);
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].tax_amt = Number(taxAmount) * (this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_cnt);
    }
    // this.omr_FeeStatementModel.omR_FeeStatementItem[index].tax_amt = Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].tax_rate_amt) * this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_cnt;

    this.omr_FeeStatementModel.omR_FeeStatementItem[index].order_val =
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_amt *
        this.omr_FeeStatementModel.omR_FeeStatementItem[index].unit_cnt -
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].discount_amt +
      Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].tax_amt);

    this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt =
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].order_val;
    for (let i = 0.0; i < this.omr_FeeStatementModel.omR_FeeStatementItem[index].omR_FeeStatementPayment.length; i++)
    {
      var paymentType = this.omr_FeeStatementModel.omR_FeeStatementItem[index].omR_FeeStatementPayment[i].payment_type_txt.toLocaleLowerCase();

      // this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.replace('(', '');
      // this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.replace(')', '');

      var free_value =
        Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) -
        Number(
          this.omr_FeeStatementModel.omR_FeeStatementItem[index]
            .omR_FeeStatementPayment[i].payment_amt
        );
      this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = Number(
        Number(free_value).toFixed(2)
      );

      if (paymentType.toLocaleLowerCase() != 'insurance chargeback') {
        if (paymentType.toLocaleLowerCase() != 'insurance payment') {
          this.omr_FeeStatementModel.omR_FeeStatementItem[index].pat_total +=
            Number(
              this.omr_FeeStatementModel.omR_FeeStatementItem[index]
                .omR_FeeStatementPayment[i].payment_amt
            );
        }

        if (paymentType == 'insurance payment') {
          this.omr_FeeStatementModel.omR_FeeStatementItem[index].ins_total +=
            Number(
              this.omr_FeeStatementModel.omR_FeeStatementItem[index]
                .omR_FeeStatementPayment[i].payment_amt
            );
          //this.omr_FeeStatementModel.omR_FeeStatementService[index].order_val - parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)
        }
      }
      // if (this.omr_FeeStatementModel.omR_FeeStatementItem[index].omR_FeeStatementPayment[i].payment_type_txt == "Insurance Payment") {
      //   this.omr_FeeStatementModel.omR_FeeStatementItem[index].ins_total =
      //     this.omr_FeeStatementModel.omR_FeeStatementItem[index].order_val - Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt);
      // }
      // else {
      //   this.omr_FeeStatementModel.omR_FeeStatementItem[index].pat_total =
      //     this.omr_FeeStatementModel.omR_FeeStatementItem[index].order_val - Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt);
      // }
      // if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) < 0) {
      //   this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}`
      //   this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}` + ')'
      //   this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.BalanceValue;

      //   //  parseInput(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt)=parseInt(this.BalanceValue);
      //   // this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = parseInt('('+this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt+')');

      // }
      // else {
      //   this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}`
      // }
    }
    this.totalForProduct();
  }

  subtractProductTotal(i: number) {
    this.totalProductAmountWithQuantity = this.totalProductAmountWithQuantity - (Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].unit_amt) * Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].unit_cnt));
    this.totalProductUnitAmount = this.totalProductUnitAmount - Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].unit_amt);
    this.totalProductDiscount =
      this.totalProductDiscount -
      Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].discount_amt);
    this.totalProductSubtotal =
      this.totalProductSubtotal -
      Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].order_val);
    this.totalProductPatPaid =
      this.totalProductPatPaid -
      Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].pat_total);
    this.totalProductBalance =
      this.totalProductBalance -
      Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].fee_amt);
    this.totalProductTax =
      this.totalProductTax -
      Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].tax_amt);
    this.globalTotal();
  }

  subtractPayForProduct(i: number, index: number) {
    var paymentType =
      this.omr_FeeStatementModel.omR_FeeStatementItem[
        i
      ].omR_FeeStatementPayment[index].payment_type_txt.toLocaleLowerCase();
    if (paymentType.toLocaleLowerCase() != 'insurance chargeback') {
      if (paymentType.toLocaleLowerCase() != 'insurance payment') {
        this.omr_FeeStatementModel.omR_FeeStatementItem[i].pat_total -= Number(
          this.omr_FeeStatementModel.omR_FeeStatementItem[i]
            .omR_FeeStatementPayment[index].payment_amt
        );
        this.totalProductPatPaid =
          this.totalProductPatPaid -
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementItem[i]
              .omR_FeeStatementPayment[index].payment_amt
          );
      }

      if (paymentType == 'insurance payment') {
        this.omr_FeeStatementModel.omR_FeeStatementItem[i].ins_total -= Number(
          this.omr_FeeStatementModel.omR_FeeStatementItem[i]
            .omR_FeeStatementPayment[index].payment_amt
        );
        this.totalProductInsPaid =
          this.totalProductInsPaid -
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementItem[i]
              .omR_FeeStatementPayment[index].payment_amt
          );
      }
    }
    this.globalTotal();
  }

  totalForProduct() {
    this.totalProductAmountWithQuantity = 0.0;
    this.totalProductUnitAmount = 0.0;
    this.totalProductDiscount = 0.0;
    this.totalProductSubtotal = 0.0;
    this.totalProductPatPaid = 0.0;
    this.totalProductBalance = 0.0;
    this.totalProductTax = 0.0;
    this.Fee_Amt_product = 0.0;
    this.totalProductInsPaid = 0.0;
    if (this.omr_FeeStatementModel.fee_statement_id != undefined) {
      for (
        let i = 0.0;
        i < this.omr_FeeStatementModel.omR_FeeStatementItem.length;
        i++
      ) {
        this.totalProductAmountWithQuantity = this.totalProductAmountWithQuantity + (Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].unit_amt) * Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].unit_cnt));
        this.totalProductUnitAmount = this.totalProductUnitAmount + Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].unit_amt);
        this.totalProductDiscount =
          this.totalProductDiscount +
          Number(
            this.omr_FeeStatementModel.omR_FeeStatementItem[i].discount_amt
          );
        this.totalProductSubtotal =
          this.totalProductSubtotal +
          Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].order_val);
        this.totalProductPatPaid =
          this.totalProductPatPaid +
          Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].pat_total);
        this.totalProductInsPaid += Number(
          this.omr_FeeStatementModel.omR_FeeStatementItem[i].ins_total
        );
        // if (this.omr_FeeStatementModel.omR_FeeStatementItem[i].fee_amt.toString().includes("(")) {
        //   this.Fee_Amt_product = parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[i].fee_amt.replace('(', ''));
        //   // this.Fee_Amt_Service = parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[i].fee_amt.replace(')', ''));
        // }
        // else{
        //   this.Fee_Amt_product =parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[i].fee_amt);
        // }
        this.Fee_Amt_product = Number(
          this.omr_FeeStatementModel.omR_FeeStatementItem[i].fee_amt
        );
        this.totalProductBalance =
          this.totalProductBalance + this.Fee_Amt_product;
        //this.totalProductBalance = this.totalProductBalance + Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].fee_amt);
        this.totalProductTax =
          this.totalProductTax +
          Number(this.omr_FeeStatementModel.omR_FeeStatementItem[i].tax_amt);
        this.totalInsPaid += this.totalProductInsPaid;
      }
      this.globalTotal();
    }
  }

  globalTotal() {
    // this.totalUnitAmount = this.totalProductUnitAmount + this.totalServiceUnitAmount;
    this.totalUnitAmount = this.totalProductAmountWithQuantity + this.totalServiceAmountWithQuantity;
    this.totalDiscount = this.totalServiceDiscount + this.totalProductDiscount;
    this.totalSubTotal = this.totalServiceSubtotal + this.totalProductSubtotal;
    this.totalPatpaid = this.totalServicePatPaid + this.totalProductPatPaid;
    this.totalInsPaid = this.totalServiceInsPaid + this.totalProductInsPaid;
    this.totalBalance = this.totalServiceBalance + this.totalProductBalance;
    this.totalTax = this.totalServiceTax + this.totalProductTax;
  }

  save() {
    debugger;
    this.omr_FeeStatementModel.omR_FeeStatementPayment = this.rowPayment;
    // for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementService.length; index++) {
    //   if (Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) != 0) {
    //     if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) > 1) {

    //     }
    //     else {
    //       this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt.replace('(', '');
    //       this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt.replace(')', '');
    //     }
    //   }

    // }

    // for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementItem.length; index++) {

    //   if (Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) != 0) {
    //     if (this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.toString().includes("(")) {
    //       if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) > 1) {

    //       }
    //       else {
    //         this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.replace('(', '');
    //         this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.replace(')', '');

    //       }

    //     }
    //   }
    // }
    if(!this.isExamTab){
      this.omr_FeeStatementModel.omR_FeeStatementDiagnosis = [];
      this.exam_Assessments.map((ele) => {
        const feeStatementDiagnosis = new OMR_FeeStatementDiagnosisModel();
        feeStatementDiagnosis.fee_statement_id = ele.exam_Assessment_ID,
        feeStatementDiagnosis.fee_statement_diagnosis_id = ele.exam_progress_note_id,
        feeStatementDiagnosis.dx_number_txt = ele.ccda_code,
        feeStatementDiagnosis.assessment = ele.assessment,
        feeStatementDiagnosis.created_dt = ele.created_dt.toDateString(),
        feeStatementDiagnosis.created_by = this.user.user_id,
        feeStatementDiagnosis.updated_dt = ele.updated_dt.toDateString(),
        feeStatementDiagnosis.updated_by = this.user.user_id

        this.omr_FeeStatementModel.omR_FeeStatementDiagnosis.push(feeStatementDiagnosis);
      });
    }

    this.omr_FeeStatementModel.patient_id = this.patientId;
    //this.omr_FeeStatementModel.dos_dt = this.omr_FeeStatementModel.dos_dt.toDateString();
    this.isExamTab
      ? (this.omr_FeeStatementModel.exam_id = this.examId)
      : this.isExamTab;
    if (
      this.omr_FeeStatementModel.fee_statement_id == undefined ||
      this.omr_FeeStatementModel.fee_statement_id == ''
    ) {
      this._omr_FeeStatementservice
        .insertOMR_Fee_Statement(this.omr_FeeStatementModel)
        .subscribe(async(res) => {
            this.fee_statement_id = res.fee_statement_id;
            this.toaster.success('Record Saved Succesfully.');
            await this.delay(1000);

            // this.router.navigate(['/patient/misc-free-statement'], { queryParams: { fee_statement_id: this.fee_statement_id } });
            //this.isExamTab ? this.getMiscFeeStatementByID() : this.getMiscFeeStatement();
            this.isExamTab ? location.reload(): this.router.navigate(['/patient/misc-free-statement'], {
                  queryParams: {
                    patientId: this.patientDetail.patientID,
                    feestatementId: this.fee_statement_id,
                  },
                });
          },
          (error) => {
            -this.toaster.error(error.error);
          }
        );
    } else {
      this._omr_FeeStatementservice
        .updateOMR_Fee_Statement(this.omr_FeeStatementModel)
        .subscribe(async(res) => {
          this.fee_statement_id = res.fee_statement_id;
          this.toaster.success('Record Saved Succesfully.');
            await this.delay(1000);

            // for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementService.length; index++) {
            //   if (Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) < 0) {
            //     this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}`
            //     this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}` + ')'
            //     this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.BalanceValue;
            //   }
            // }
            // for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementItem.length; index++) {
            //   if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) < 0) {
            //     this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}`
            //     this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}` + ')'
            //     this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.BalanceValue;
            //   }
            // }
            //this.isExamTab ? this.getMiscFeeStatementByID() : this.getMiscFeeStatement();
            this.isExamTab ? location.reload(): this.router.navigate(['/patient/misc-free-statement'], {
              queryParams: {
                patientId: this.patientDetail.patientID,
                feestatementId: this.fee_statement_id,
              },
            });
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
      // this.toaster.success('Record Saved Succesfully.');
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  saveClose() {
    this.omr_FeeStatementModel.omR_FeeStatementPayment = this.rowPayment;
    // for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementService.length; index++) {

    //     if (Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) != 0) {
    //       if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)> 1) {

    //       }
    //       else{
    //         this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt.replace('(', '');
    //         this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt.replace(')', '');
    //       }
    //     }
    //   }

    // for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementItem.length; index++) {

    //     if (Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) != 0) {
    //       if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt)>1) {

    //       }
    //       else{
    //         this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.replace('(', '');
    //         this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt.replace(')', '');
    //       }
    //     }
    //   }
    if(!this.isExamTab){
      this.omr_FeeStatementModel.omR_FeeStatementDiagnosis = [];
      this.exam_Assessments.map((ele) => {
        const feeStatementDiagnosis = new OMR_FeeStatementDiagnosisModel();
        feeStatementDiagnosis.fee_statement_id = ele.exam_Assessment_ID,
        feeStatementDiagnosis.fee_statement_diagnosis_id = ele.exam_progress_note_id,
        feeStatementDiagnosis.dx_number_txt = ele.ccda_code,
        feeStatementDiagnosis.assessment = ele.assessment,
        feeStatementDiagnosis.created_dt = ele.created_dt.toDateString(),
        feeStatementDiagnosis.created_by = this.user.user_id,
        feeStatementDiagnosis.updated_dt = ele.updated_dt.toDateString(),
        feeStatementDiagnosis.updated_by = this.user.user_id

        this.omr_FeeStatementModel.omR_FeeStatementDiagnosis.push(feeStatementDiagnosis);
      });
    }

    this.omr_FeeStatementModel.patient_id = this.patientId;
    //this.omr_FeeStatementModel.dos_dt = this.omr_FeeStatementModel.dos_dt.toDateString();
    // this.isExamTab ? (this.omr_FeeStatementModel.exam_id = this.examId) : this.isExamTab;
    debugger;
    this.isExamTab && (this.omr_FeeStatementModel.exam_id = this.examId);
    if (this.omr_FeeStatementModel.fee_statement_id == undefined || this.omr_FeeStatementModel.fee_statement_id == '') {
      this._omr_FeeStatementservice.insertOMR_Fee_Statement(this.omr_FeeStatementModel).subscribe((res) => {
            this.toaster.success('Record Saved Succesfully.');
            this.gotoBackLocation(this.isExamTab);
            // if(!this.isExamTab){
            //   this.router.navigate(['/patient/patientAccount'], {
            //     queryParams: { patientId: this.patientDetail.patientID },
            //   });
            //   return;
            // }
            // this.router.navigate(['/dashboard']);
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
    } else {
      this._omr_FeeStatementservice.updateOMR_Fee_Statement(this.omr_FeeStatementModel)
        .subscribe((res) => {
            this.toaster.success('Record Saved Succesfully.');
            this.gotoBackLocation(this.isExamTab);
            // if(!this.isExamTab){
            //   this.router.navigate(['/patient/patientAccount'], {
            //     queryParams: { patientId: this.patientDetail.patientID },
            //   });
            //   return;
            // }
            // this.router.navigate(['/dashboard']);
          },
          (error) => {
            this.toaster.error(error.error);
          }
        );
    }
  }

  gotoBackLocation(isExamTab: boolean){
    var backLocation = localStorage.getItem("backLocation");
    if(backLocation){
        this.router.navigate([backLocation], {
          queryParams: { patientId: this.patientDetail.patientID },
        });
        return;
    }else{
      if(!isExamTab){
        this.router.navigate(['/patient/patientAccount'], {
          queryParams: { patientId: this.patientDetail.patientID },
        });
        return;
      }else{
        this.router.navigate(['/patient/create'], {
          queryParams: { patientId: this.patientDetail.patientID },
        });
        return;
      }
    }
  }

  insuranceRowClicked(event: any) {
    this.omr_FeeStatementModel.insurance_id =
      event.api.getSelectedRows()[0].insurance_id;
    this.omr_FeeStatementModel.insurance_display_txt =
      event.api.getSelectedRows()[0].display_txt;
    this.showInsuarance = false;
  }

  chargeRowClicked(event: any, item: any, index: any) {

    item.code_txt = event.api.getSelectedRows()[0].code_txt;
    item.service_name_txt = event.api.getSelectedRows()[0].name_txt;
    item.unit_amt = event.api.getSelectedRows()[0].charge_amt;
    var isTaxable = event.api.getSelectedRows()[0].taxable_ind;
    if(isTaxable){
      var office = this.office.filter((x) => x.office_id == this.omr_FeeStatementModel.office_id)[0];
      var taxAmount = ((item.unit_amt - item.discount_amt) * office.tax_rate_amt / 100).toFixed(2);
      item.tax_rate_amt = office.tax_rate_amt;
      item.tax_amt = taxAmount;
    } else {
      item.tax_rate_amt = 0.0;
      item.tax_amt = 0.0;
    }
    item.isShow = false;
    this.makeTotalForService(index);
    this.totalForService();
  }

  chargeRowClickedForProduct(event: any, item: any, index: any) {
    item.code_txt = event.api.getSelectedRows()[0].code_txt;
    item.product_name_txt = event.api.getSelectedRows()[0].name_txt;
    item.unit_amt =
      event.api.getSelectedRows()[0].selected_price_txt == 'Reg. Price'
        ? event.api.getSelectedRows()[0].reg_price_amt
        : event.api.getSelectedRows()[0].sales_price_amt;
    var isTaxable = event.api.getSelectedRows()[0].taxable_ind;
    if(isTaxable){
      var office = this.office.filter((x) => x.office_id == this.omr_FeeStatementModel.office_id)[0];
      var taxAmount = (item.unit_amt * office.tax_rate_amt / 100).toFixed(2);
      item.tax_rate_amt = office.tax_rate_amt;
      item.tax_amt = taxAmount;
    } else {
      item.tax_rate_amt = 0.0;
      item.tax_amt = 0.0;
    }

    item.isShow = false;
    this.makeTotalForProduct(index);
    this.totalForProduct();
  }

  // createPaymentAndCharge(type: string, outerIndex: number, innerIndex: number) {
  //   var omr_FeeStatementPaymentModel = new OMR_FeeStatementPaymentModel()
  //   // if(type=='service'){
  //   //   omr_FeeStatementPaymentModel.payment_dt=this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_dt;
  //   //   omr_FeeStatementPaymentModel.description_txt=this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].description_txt
  //   //   omr_FeeStatementPaymentModel.payment_type_txt=this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_type_txt
  //   //   omr_FeeStatementPaymentModel.payment_amt=this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_amt
  //   // }
  //   var index = this.rowPayment.findIndex(x => x.payment_type_txt == this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_type_txt &&
  //     x.payment_dt == this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_dt)

  //   if (index > -1) {
  //     omr_FeeStatementPaymentModel.payment_dt = this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_dt;
  //     omr_FeeStatementPaymentModel.description_txt = this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].description_txt
  //     omr_FeeStatementPaymentModel.payment_type_txt = this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_type_txt
  //     omr_FeeStatementPaymentModel.payment_amt = this.omr_FeeStatementModel.omR_FeeStatementService[outerIndex].OMR_FeeStatementPayment[innerIndex].payment_amt
  //     this.rowPayment.push(omr_FeeStatementPaymentModel);
  //   }

  //   this.totalForService();
  //   this.totalForProduct();

  // }

  addPaymentAndChargebacks() {
    this.rowPayment = [];
    var rowPaymentTemp: OMR_FeeStatementPaymentModel[] = [];
    for (
      let i = 0.0;
      i < this.omr_FeeStatementModel.omR_FeeStatementService.length;
      i++
    ) {
      for (
        let j = 0.0;
        j <
        this.omr_FeeStatementModel.omR_FeeStatementService[i]
          .omR_FeeStatementPayment.length;
        j++
      ) {
        rowPaymentTemp.push(
          this.omr_FeeStatementModel.omR_FeeStatementService[i]
            .omR_FeeStatementPayment[j]
        );
      }
    }
    for (
      let i = 0.0;
      i < this.omr_FeeStatementModel.omR_FeeStatementItem.length;
      i++
    ) {
      for (
        let j = 0.0;
        j <
        this.omr_FeeStatementModel.omR_FeeStatementItem[i]
          .omR_FeeStatementPayment.length;
        j++
      ) {
        rowPaymentTemp.push(
          this.omr_FeeStatementModel.omR_FeeStatementItem[i]
            .omR_FeeStatementPayment[j]
        );
      }
    }

    // let groupes = rowPaymentTemp.reduce((group, rowPayment) => {
    //   const date = rowPayment.payment_dt.toString().split('T')[0];
    //   if(!group[date]){

    //   }
    //   return group;
    // }, {})
    let grouped: { [key: string]: any } = {};
    rowPaymentTemp.forEach((row) => {
      const date = moment(row.payment_dt).format('MM/DD/YYYY');
      if (grouped[date]) {
        grouped[date].push(row);
      } else {
        grouped[date] = [row];
      }
    });

    //let grouped = _.groupBy(rowPaymentTemp, 'payment_dt');
    const keys = Object.keys(grouped);

    keys.forEach((element) => {
      if (grouped[element].length > 1) {
        grouped[element].forEach((payment: OMR_FeeStatementPaymentModel) => {
          let res = grouped[element].filter(
            (a: OMR_FeeStatementPaymentModel) =>
              a.payment_type_txt == payment.payment_type_txt
          );
          if (res.length > 1) {
            let isexist = this.rowPayment.filter(
              (a) =>
                moment(a.payment_dt).format('MM/DD/YYYY') ==
                  moment(payment.payment_dt).format('MM/DD/YYYY') &&
                a.payment_type_txt == payment.payment_type_txt
            ).length;
            if (isexist == 0) {
              let omr_FeeStatementPaymentModel =
                new OMR_FeeStatementPaymentModel();
              omr_FeeStatementPaymentModel.payment_dt = payment.payment_dt;
              omr_FeeStatementPaymentModel.description_txt = res
                .map((a: OMR_FeeStatementPaymentModel) => a.description_txt)
                .join();
              omr_FeeStatementPaymentModel.payment_type_txt =
                payment.payment_type_txt;
              omr_FeeStatementPaymentModel.payment_amt = res
                .map((a: OMR_FeeStatementPaymentModel) => a.payment_amt)
                .reduce((acc: number, cur: any) => acc + Number(cur), 0)
                .toString();
              this.rowPayment.push(omr_FeeStatementPaymentModel);
            }
          } else {
            let omr_FeeStatementPaymentModel =
              new OMR_FeeStatementPaymentModel();
            omr_FeeStatementPaymentModel.payment_dt = payment.payment_dt;
            omr_FeeStatementPaymentModel.description_txt =
              payment.description_txt;
            omr_FeeStatementPaymentModel.payment_type_txt =
              payment.payment_type_txt;
            omr_FeeStatementPaymentModel.payment_amt = payment.payment_amt;
            this.rowPayment.push(omr_FeeStatementPaymentModel);
          }
        });
      } else {
        let omr_FeeStatementPaymentModel = new OMR_FeeStatementPaymentModel();
        omr_FeeStatementPaymentModel.payment_dt =
          grouped[element][0].payment_dt;
        omr_FeeStatementPaymentModel.description_txt =
          grouped[element][0].description_txt;
        omr_FeeStatementPaymentModel.payment_type_txt =
          grouped[element][0].payment_type_txt;
        omr_FeeStatementPaymentModel.payment_amt =
          grouped[element][0].payment_amt;
        this.rowPayment.push(omr_FeeStatementPaymentModel);
      }
    });

    this.totalForService();
    this.totalForProduct();
  }

  getMiscFeeStatement() {
    debugger;
    if (this.fee_statement_id) {
      // this._omr_FeeStatementservice.getAll(this.patientId).subscribe((res: any) => {
      this._omr_FeeStatementservice
        .getMiscFeeStatement(this.patientId, this.fee_statement_id)
        .subscribe(
          (res: any) => {
            //res = JSON.parse(res);
            //res = res as OMR_FeeStatementModel[];
            this.omr_FeeStatementModel1 = Object.assign({}, res[0]);
            if (this.omr_FeeStatementModel1.fee_statement_id != undefined) {
              this.omr_FeeStatementModel = Object.assign({}, res[0]);
              // this.rowPayment = [];
              // this.rowPayment = this.omr_FeeStatementModel.omR_FeeStatementPayment;
              this.addPaymentAndChargebacks();
              this.onSelectUser(this.omr_FeeStatementModel.signing_provider_id);

              this.totalForProduct();
              this.totalForService();

              this.omr_FeeStatementModel.omR_FeeStatementDiagnosis.map((ele) => {
                var exam_AssessmentsModel = new Exam_AssessmentsModel();
                exam_AssessmentsModel.exam_Assessment_ID = ele.fee_statement_id;
                exam_AssessmentsModel.exam_progress_note_id = ele.fee_statement_diagnosis_id;
                exam_AssessmentsModel.ccda_code = (ele.dx_number_txt.toLowerCase().charCodeAt(0) - 97 + 1).toString();
                exam_AssessmentsModel.assessment = ele.assessment;
                this.exam_Assessments.push(exam_AssessmentsModel);

              });

              // if (this.omr_FeeStatementModel.omR_FeeStatementService.length != 0) {
              //   for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementService.length; index++) {
              //     if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) < 0) {
              //       this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}`
              //       this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}` + ')'
              //       this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.BalanceValue;
              //     }
              //   }
              // }
              // if (this.omr_FeeStatementModel.omR_FeeStatementItem.length != 0) {
              //   for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementItem.length  ; index++) {
              //     if (parseInt(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) < 0) {
              //       this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}`
              //       this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}` + ')'
              //       this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.BalanceValue;

              //       //  parseInput(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)=parseInt(this.BalanceValue);
              //       // this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = parseInt('('+this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt+')');

              //     }
              //   }
              // }

              this.usersList = this.usersList.filter(
                (x) => x.user_id == this.omr_FeeStatementModel.provider_id
              );
              this.providerString = this.usersList[0]?.display_txt;
              this.officelist = this.office.filter(
                (x) => x.office_id == this.omr_FeeStatementModel.office_id
              );
              this.officename = this.officelist[0]?.name_txt;
              if (this.omr_FeeStatementModel.insurance_id == '00000000-0000-0000-0000-000000000000') {
                debugger;
                if(this.patientDetail.patientInsurances.length > 0){
                  this.insuranceData = this.insuranceData.filter((x) => x.display_txt == this.patientDetail.patientInsurances[0].insuranceName);
                  this.omr_FeeStatementModel.insurance_id = this.insuranceData[0]?.insurance_id;
                  this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
                }
              } else {
                let insuranceData = this.insuranceData.filter((x) => x.insurance_id.toLowerCase() == this.omr_FeeStatementModel.insurance_id);
                if (insuranceData.length > 0) {
                  this.insuranceData = insuranceData;
                  this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
                } else {
                  debugger;
                  if(this.patientDetail.patientInsurances.length > 0){
                    this.insuranceData = this.insuranceData.filter((x) => x.display_txt == this.patientDetail.patientInsurances[0].insuranceName);
                    this.omr_FeeStatementModel.insurance_id = this.insuranceData[0]?.insurance_id;
                    this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
                  }
                }
              }
            } else {
              if (!this.omr_FeeStatementModel.signing_provider_id) {
                this.omr_FeeStatementModel.signing_provider_id =
                  this.examModel.provider_ID;
                this.onSelectUser(this.examModel.provider_ID);
              }
              // this.addProduct('sold');
              // this.addService();
            }
          },
          (error) => {
            this.toaster.error('Error while getting MiscFeeStatement.');
          }
        );
    }
  }

  getMiscFeeStatementByID() {
    debugger;
    this._omr_FeeStatementservice
      .getByID(this.examId, this.patientId)
      .subscribe(
        (res: any) => {
          //res = JSON.parse(res);
          //res = res as OMR_FeeStatementModel[];
          this.omr_FeeStatementModel1 = Object.assign({}, res[0]);
          if (this.omr_FeeStatementModel1.fee_statement_id != undefined) {
            this.omr_FeeStatementModel = Object.assign({}, res[0]);
            // this.rowPayment = [];
            // this.rowPayment = this.omr_FeeStatementModel.omR_FeeStatementPayment;
            this.addPaymentAndChargebacks();
            this.onSelectUser(this.omr_FeeStatementModel.signing_provider_id);

            this.totalForProduct();
            this.totalForService();

            // if (this.omr_FeeStatementModel.omR_FeeStatementService.length != 0) {
            //   for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementService.length; index++) {
            //     if (Number(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt) < 0) {
            //       this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}`
            //       this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt}` + ')'
            //       this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = this.BalanceValue;
            //     }
            //   }
            // }
            // if (this.omr_FeeStatementModel.omR_FeeStatementItem.length != 0) {
            //   for (let index = 0; index < this.omr_FeeStatementModel.omR_FeeStatementItem.length  ; index++) {
            //     if (Number(this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt) < 0) {
            //       this.BalanceValue = `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}`
            //       this.BalanceValue = '(' + `${this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt}` + ')'
            //       this.omr_FeeStatementModel.omR_FeeStatementItem[index].fee_amt = this.BalanceValue;

            //       //  parseInput(this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt)=parseInt(this.BalanceValue);
            //       // this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt = parseInt('('+this.omr_FeeStatementModel.omR_FeeStatementService[index].fee_amt+')');

            //     }
            //   }
            // }

            this.usersList = this.usersList.filter(
              (x) => x.user_id == this.omr_FeeStatementModel.provider_id
            );
            this.providerString = this.usersList[0]?.display_txt;
            this.officelist = this.office.filter(
              (x) => x.office_id == this.omr_FeeStatementModel.office_id
            );
            this.officename = this.officelist[0]?.name_txt;
            // this.insuranceData = this.insuranceData.filter(x => x.insurance_id.toLowerCase() == this.omr_FeeStatementModel.insurance_id);
            // this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
            if (
              this.omr_FeeStatementModel.insurance_id ==
              '00000000-0000-0000-0000-000000000000'
            ) {
              debugger;
              if(this.patientDetail.patientInsurances.length > 0){
                this.insuranceData = this.insuranceData.filter((x) => x.display_txt == this.patientDetail.patientInsurances[0].insuranceName);
                this.omr_FeeStatementModel.insurance_id = this.insuranceData[0]?.insurance_id;
                this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
              }
            } else {
              let insuranceData = this.insuranceData.filter((x) => x.insurance_id.toLowerCase() == this.omr_FeeStatementModel.insurance_id);
              if (insuranceData.length > 0) {
                this.insuranceData = insuranceData;
                this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
              } else {
                debugger;
                if(this.patientDetail.patientInsurances.length > 0){
                  this.insuranceData = this.insuranceData.filter((x) => x.display_txt == this.patientDetail.patientInsurances[0].insuranceName);
                  this.omr_FeeStatementModel.insurance_id = this.insuranceData[0]?.insurance_id;
                  this.omr_FeeStatementModel.insurance_display_txt = this.insuranceData[0]?.display_txt;
                }
              }
            }
          } else {
            if (!this.omr_FeeStatementModel.signing_provider_id) {
              this.omr_FeeStatementModel.signing_provider_id =
                this.examModel.provider_ID;
              this.onSelectUser(this.examModel.provider_ID);
            }
            // this.addProduct('sold');
            // this.addService();
          }
        },
        (error) => {
          this.toaster.error('Error while getting MiscFeeStatement.');
        }
      );
  }

  getUser() {
    debugger;
    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    if(!this.examModel.provider_ID){
      this.examModel.provider_ID = this.patientDetail.referring_provider_id;
    }
    this.user1 = this.userList.find((a) => a.user_id == this.examModel.provider_ID);
    if (this.user1?.npi_txt) {
      this.License = this.user1?.npi_txt;
    }
    // if (this.user1?.email_address_txt) {
    //   this.officeemail = this.user1?.email_address_txt;
    // }
    if (!this.omr_FeeStatementModel.signing_provider_id) {
      this.onSelectUser(this.examModel.provider_ID);
    } else {
      this.onSelectUser(this.omr_FeeStatementModel.signing_provider_id);
    }
    //this.getOffice();

    // let practiceId = localStorage.getItem('practice')?.toString();
    // this._lookupService.getRenderingProvider(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
    //   this.userList = res;
    //   // this.user1 = this.userList.filter(x => x.user_id == this.patientDetail.referring_provider_id);
    //   this.user1 = this.userList.filter(x => x.user_id == this.examModel.provider_ID);
    //   if (this.user1[0]?.license_txt) {
    //     this.License = this.user1[0]?.license_txt
    //   }

    //   if (this.omr_FeeStatementModel.signing_provider_id==null) {
    //      this.onSelectUser(this.examModel.provider_ID);
    //    }
    //    else{
    //      this.onSelectUser(this.omr_FeeStatementModel.signing_provider_id);
    //    }
    //   this.getOffice();
    // }, (error) => {
    //   this.toaster.error(error.error);
    // });
  }
  addAssessment() {
    debugger;
    var length = this.exam_Assessments.length;
    var exam_AssessmentsModel = new Exam_AssessmentsModel();
    exam_AssessmentsModel.ccda_code = String.fromCharCode(length + 65);
    this.exam_Assessments.push(exam_AssessmentsModel);
  }

  onSelectUser(id: any) {
    debugger;
    this.user = this.userList.find((a) => a.user_id == id);
    if (this.user) {
      if (this.user.npi_txt) {
        this.License = this.user.npi_txt
      }
      this.imgURL = this.user?.signature_img
        ? 'data:image/png;base64,' + this.user.signature_img
        : '';
    } else {
      this.imgURL = '';
      this.user = new UserModel();
    }
    return true;
  }

  searchInsurance(event?: any) {
    this.rowInsurance = this.insuranceData.filter(
      (it) =>
        it.display_txt
          .toLocaleLowerCase()
          .indexOf(event.target.value.toLocaleLowerCase()) !== -1
    );
    if (this.rowInsurance.length == 0) {
      this.rowInsurance = this.insuranceData;
    }
  }

  onEdit(componentName: string, title: string) {

    const initialState = {
      componentName: componentName,
      title: title
    };

    this.bsModalRef = this._modalService.show(AddProductComponent, {
      initialState
    });
    // this.bsModalRef.setClass('modal-lg')
    this.bsModalRef.setClass('modal-xl');
    this.bsModalRef.onHide.subscribe((res: any) => {
      if (componentName == 'add-product') {
        this.getCharge('');
      }
    });
  }

  editOffice(componentName: string, title: string, isProductModel: boolean) {

    this.hideDropdownListOnBlur();
    const initialState = {
      componentName: componentName,
      title: title,
      isProductModel: isProductModel
    };

    this.bsModalRef = this._modalService.show(ComponentModalComponent, {
      initialState,
    });
    //this.bsModalRef.setClass('modal-dialog modal-dialog-centered modal-xl');
    this.bsModalRef.setClass('modal-xl');
    this.bsModalRef.onHide.subscribe((res: any) => {

      if (componentName == 'Insurance') {
        this.getInsurance();
      } else if (componentName == 'Provider') {
        this.getProvider();
      } else if (componentName == 'General') {
        this.getOffice();
      } else if (componentName == 'add-charges') {
        this.getCharge('');
      }
      else if (componentName == 'add-product') {
        this.getCharge('');
      }
    });
  }
  rewriteObject(objValue: any[]): OMR_FeeStatementModel[] {
    let objectValue: OMR_FeeStatementModel[] = [];
    objValue.forEach((element) => {
      let elem: OMR_FeeStatementModel = new OMR_FeeStatementModel();
      elem.fee_statement_id = element.fee_statement_id;
      elem.patient_id = element.patient_id;
      elem.exam_id = element.exam_id;
      elem.attachment_id = element.attachment_id;
      elem.provider_id = element.provider_id;
      elem.office_id = element.office_id;
      elem.insurance_id = element.insurance_id;
      elem.insurance_display_txt = '';

      objectValue.push(elem);
    });
    return objectValue;
  }
  // getAllLookup() {
  //   this._lookupService
  //     .getAllLookupData()
  //     .subscribe((res: Array<LookupModel>) => {
  //       this.bcLookup = res.filter((x) => x.group_txt.toLowerCase() == 'bc'.toLowerCase() && x.active_ind == true);
  //       this.bcLookup = this.compare(this.bcLookup);

  //       this.diamLookup = res.filter((x) => x.group_txt.toLowerCase() == 'diam'.toLowerCase() && x.active_ind == true);
  //       this.diamLookup = this.compare(this.diamLookup);
  //     });
  // }
}
