export class OMR_SpectacleOrderFormModel {
    public exam_ID: string = '';
    public patient_ID: string = '';
    public accountName: string = '';
    public address: string = '';
    public shiptoAcct: string = '';
    public billtoAcct: string = '';
    public patientName: string = '';
    public uc: boolean = false;
    public safefy: boolean = false;
    public date: Date = new Date();
    public city: string = '';
    public sate: string = '';
    public zip: string = '';
    public phone: string = '';
    public lensStyleSV: boolean = false;
    public lensStyleSVAsph: boolean = false;
    public lensStyleProgressive: boolean = false;
    public lensStyleProgressiveText: string = '';
    public lensStyleFT28: boolean = false;
    public lensStyleFT35: boolean = false;
    public lensStyle7x28: boolean = false;
    public lensStyle8x35: boolean = false;
    public otherStyle: boolean = false;
    public otherStyleText: string = '';
    public sphereOD: string = '';
    public sphereOS: string = '';
    public cylinderOD: string = '';
    public cylinderOS: string = '';
    public axisOD: string = '';
    public axisOS: string = '';
    public inOD: string = '';
    public inOS: string = '';
    public outOD: string = '';
    public outOS: string = '';
    public distPDOD: string = '';
    public distPDOS: string = '';
    public nearPDOD: string = '';
    public nearPDOS: string = '';
    public upOD: string = '';
    public upOS: string = '';
    public downOD: string = '';
    public downOS: string = '';
    public addOD: string = '';
    public addOS: string = '';
    public segHtOD: string = '';
    public segHtOS: string = '';
    public oChtOD: string = '';
    public oChtOS: string = '';
    public bcod: string = '';
    public BCOS: string = '';
    public labUseqOD: string = '';
    public labUseqOS: string = '';
    public invoiceOD: string = '';
    public invoiceOS: string = '';
    public origInvOD: string = '';
    public origInvOS: string = '';
    public ckOutStampOD: string = '';
    public ckOutStampOS: string = '';
    public Polycarbon10CT: boolean = false;
    public Polycarbon15CT: boolean = false;
    public plasticCR39: boolean = false;
    public plastic156: boolean = false;
    public plastic160: boolean = false;
    public plastic166: boolean = false;
    public plastic167: boolean = false;
    public plastic174: boolean = false;
    public glassClear: boolean = false;
    public glassPDX: boolean = false;
    public glassPGX: boolean = false;
    public otherMaterial: string = '';
    public materialSatin: boolean = false;
    public materialHighLuster: boolean = false;
    public transitionsGray: boolean = false;
    public transitionsBrown: boolean = false;
    public polarizedGray1: boolean = false;
    public polarizedGray2: boolean = false;
    public polarizedGray3: boolean = false;
    public polarizedBrown1: boolean = false;
    public polarizedBrown2: boolean = false;
    public polarizedBrown3: boolean = false;
    public materialOtherColor: string = '';
    public frameTypeMetal: boolean = false;
    public frameType2VL: boolean = false;
    public frmTypeGroovedRimless: boolean = false;
    public frmTypeDrillRimless: boolean = false;
    public frameTypeOther: string = '';
    public nameandModelSKU: string = '';
    public manufacturer: string = '';
    public frameInfoColor: string = '';
    public frameEnclosedFront: boolean = false;
    public frameSupplyFrame: boolean = false;
    public frameEnclosedComplete: boolean = false;
    public frameEnclosedPattern: boolean = false;
    public eyeSize: string = '';
    public eyeSizeDbl: string = '';
    public eyeSizeED: string = '';
    public eyeSizeBBox: string = '';
    public templeLength: string = '';
    public templeUSize: string = '';
    public sideShieldsPerm: boolean = false;
    public sideShieldsDet: boolean = false;
    public tintSolid: string = '';
    public tintGradient: string = '';
    public tintUV400: boolean = false;
    public tintMultiGradient: string = '';
    public colorBox: string = '';
    public otherInstructions: string = '';
    public treatmentSolid: boolean = false;
    public treatmentGradient: boolean = false;
    public treatmentFlash: boolean = false;
    public treatmentDblGrad: boolean = false;
    public colorBox2: string = '';
    public otherInstructions2: string = '';
    public antiReflectionTreatment: string = '';
    public sidedSRCProtection: string = '';
    public factorySRC: string = '';
    public lensTreatmentOther: string = '';
    public specialInstructions: string = '';
    public datetoLab: Date = new Date();
    public dateChecked: Date = new Date();
    public rxJobCorrect: boolean = false;
    public dateDispensed: Date = new Date();
    public createdDate: Date = new Date();
    public lastUpdatedDate: Date = new Date();
    public factorySRCCheckBox: boolean = false;
    public prizmOD: string = '';
    public prizmOS: string = '';
    public baseOD: string = '';
    public baseOS: string = '';
    public decDistOD: string = '';
    public decDistOS: string = '';
    public insetOD: string = '';
    public insetOS: string = '';
    public totalDFCOD: string = '';
    public totalDFCOS: string = '';
    public stock: boolean = false;
    public notification_ind: boolean = false;
}