import { ExamModel } from 'src/app/model/ExamModel';
import { Exam_Assessment_PlansModel } from "./Exam_Assessment_PlansModel";

export class Exam_AssessmentsModel{
    public exam_Assessment_ID : string = '';
    public exam_ID : string = '';
    public assessment : string = '';
    public created_dt : Date = new Date();
    public created_by : string = '';
    public updated_dt : Date = new Date();
    public updated_by : string = '';
    public patientID : number = 0;
    public patient_id : string = '';
    public type_txt : string = '';
    public status_txt : string = '';
    public number_txt : string = '';
    public color_txt : string = '';
    public exam_progress_note_id : string = '';
    public start_dt : Date = new Date();
    public end_dt : Date = new Date();
    public source_txt : string = '';
    public ccda_code : string = '';
    public reconciled_txt : string = '';
    public index : string = '';
    public exam_Assessment_Plans : Exam_Assessment_PlansModel[] = [];
    public exam : ExamModel = new ExamModel();
}