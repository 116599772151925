import { PatientModel } from 'src/app/model/patientModel';
import { ExamModel } from './../../../../model/ExamModel';
import { Component, OnInit } from '@angular/core';
import { OMR_ContactOrderFormModel } from 'src/app/model/OMR_ContactOrderFormModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { ActivatedRoute, Router, RouterLinkActive } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-pre-contactorder',
  templateUrl: './pre-contactorder.component.html',
  styleUrls: ['./pre-contactorder.component.scss']
})
export class PreContactorderComponent implements OnInit {

  columnPatientContactOrder:any;
  rowPatientContactOrder:ExamModel[] = [];
  patintModel : PatientModel = new PatientModel();
  patientId : string = '';
  examId:string='';
  constructor(private _clinicalService:ClinicalService,private router: ActivatedRoute,private routers: Router) {
    this.patintModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patintModel.patient_id
    this.router.queryParams.subscribe((params: any) => {           
      if (params['examId']) {
        this.examId = params['examId']
      }     
    });
  }

  ngOnInit() {
    this.columnPatientContactOrder = [
      {
        headerName: 'Date',
        field: 'exam_Date', sortable: true, resizable: true, rowDrag: true, width: 150,
        cellRenderer: (data:any) => {
          return moment(data.value).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Chief Complaint',
        field: 'cheif_Complaint', sortable: true, filter: true, resizable: true, width: 900
      },
    ];
    // this.rowPatientContactOrder = [];
    this.getExam();
  }

  getExam(){
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.rowPatientContactOrder = res;
    })
  }

  navigate(event:any){    
    this.routers.navigate(["/patient/contact-order"],{ queryParams: { patientId:this.patintModel.patientID,examId : event.data.exam_ID } });
  }

}
