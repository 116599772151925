import { PatientReviewModel } from "./PatientReviewModel";

export class RXModel{
public rx_id : string = '';
public original_rx_id : string = '';
public rx_norm_code_txt : string = '';
public product_txt : string = '';
public generic_name_txt : string = '';
public brand_name_txt : string = '';
public strength_txt : string = '';
public dose_txt : string = '';
public route_txt : string = '';
public frequency_txt : string = '';
public refill_times_txt : string = '';
public date_started_dt : string = '';
public detail_txt : string = '';
public record_id : string = '';
public record_type : string = '';
public patient_id : string = '';
public created_dt : Date = new Date()
public created_by : string = '';
public updated_dt : Date = new Date()
public updated_by : string = '';
public print_ind : boolean = true;
public status_txt : string = '';
public number_val : number = 0;
public fill_instruction_txt : string = '';
public e_rx : boolean = false;
public source_txt : string = '';
public reconciled_txt : string = '';
public vocabulary_txt : string = '';
public fq : boolean = false;
public patient_history_id:string='';
public patientReview : PatientReviewModel = new PatientReviewModel();
}
