import { PatientModel } from './../../../model/patientModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { OMR_HCFAFormModel } from './../../../model/OMR_HCFAFormModel';
import { Component, OnInit } from '@angular/core';
import { OnReturnDirective } from 'src/app/core/directives/OnReturn.directive';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ExamModel } from 'src/app/model/ExamModel';
import { LookupModel } from 'src/app/model/lookupModel';
import { LookupService } from 'src/app/services/lookup.service';
import { debug } from 'console';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Omr_FeeStatementservice } from 'src/app/services/omr_FeeStatement.service';
import { OMR_FeeStatementModel } from 'src/app/model/omr_FeeStatementModel';
import { GlobalService } from './../../../services/global.service';
import { PatientService } from './../../../services/patient.service';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { InsuranceModel } from 'src/app/model/insuranceModel';
import { OfficeModel } from 'src/app/model/officeModel';
import { UserModel } from 'src/app/model/userModel';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-hcfa',
  templateUrl: './hcfa.component.html',
  styleUrls: ['./hcfa.component.scss'],
  providers: [OnReturnDirective]
})
export class HcfaComponent implements OnInit {

  office: OfficeModel[] = [];
  officename: string = '';
  officeaddress: string = '';
  officeCitySZ: string = '';
  officephone: string = '';
  userList: UserModel[] = [];
  i:number=0;
  user1: any = new UserModel();
  patientDetail: PatientModel = new PatientModel();
  user: any = new UserModel();
  omr_HCFAFormModel: OMR_HCFAFormModel = new OMR_HCFAFormModel();
  patientModel !: PatientModel;
  patientId: string = '';
  examId: string = '';
  Texilookup: LookupModel[] = [];
  License:string='';
  Texilookupcopy:LookupModel[] = [];
  ServiceInfoAlookup: LookupModel[] = [];
  ServiceInfoAlookupcopy: LookupModel[] = [];

  rowPatient: InsuranceModel[] = [];
  ServiceInfoBlookup: LookupModel[] = [];
  ServiceInfoBlookupcopy: LookupModel[] = [];

  Renderinglookup: LookupModel[] = [];
  Renderinglookupcopy: LookupModel[] = [];

  exam: ExamModel = new ExamModel();
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  omr_FeeStatementModel: OMR_FeeStatementModel = new OMR_FeeStatementModel()
  examDate31: Date = new Date();
  day: string = '';
  month: string = '';
  year: string = '';
  Insureday: string = '';
  Insuremonth: string = '';
  Insureyear: string = '';
  PREGNANCYday: string = '';
  PREGNANCYmonth: string = '';
  PREGNANCYyear: string = '';

  workdatefromday: string = '';
  workdatefrommonth: string = '';
  workdatefromyear: string = '';
  workdatetoday: string = '';
  workdatetomonth: string = '';
  workdatetoyear: string = '';

  hospitalworkdatefromday: string = '';
  hospitalworkdatefrommonth: string = '';
  hospitalworkdatefromyear: string = '';
  hospitalworkdatetoday: string = '';
  hospitalworkdatetomonth: string = '';
  hospitalworkdatetoyear: string = '';

  otherinsuredday: string = '';
  otherinsuredmonth: string = '';
  otherinsuredyear: string = '';
  DayA:string='';
  MonthA:string='';
  YearA:string='';
  DayATo:string='';
  MonthATo:string='';
  YearATo:string='';

  DayB:string='';
  MonthB:string='';
  YearB:string='';
  DayBTo:string='';
  MonthBTo:string='';
  YearBTo:string='';

  DayC:string='';
  MonthC:string='';
  YearC:string='';
  DayCTo:string='';
  MonthCTo:string='';
  YearCTo:string='';

  DayD:string='';
  MonthD:string='';
  YearD:string='';
  DayDTo:string='';
  MonthDTo:string='';
  YearDTo:string='';

  DayE:string='';
  MonthE:string='';
  YearE:string='';
  DayETo:string='';
  MonthETo:string='';
  YearETo:string='';

  DayF:string='';
  MonthF:string='';
  YearF:string='';
  DayFTo:string='';
  MonthFTo:string='';
  YearFTo:string='';

  provider_phone_area = '';
  provider_phone_number = '';
  isHcfaExist: boolean = false;

  check_value: string = '';
  currentDate: Date = new Date();
  totalChargeF: string = '';
  totalChargeS: string = '';


  constructor(private toaster: ToastrService, private _patientService: PatientService, private _clinicalService: ClinicalService, private globalService: GlobalService, private _lookupService: LookupService, private activateRoute: ActivatedRoute, private _omr_FeeStatementservice: Omr_FeeStatementservice,private viewportScroller: ViewportScroller) {
    this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['examId']) {
        this.examId = params['examId'];
      }
    });
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
  }

  ngOnInit() {

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.omr_HCFAFormModel.patient_ID = this.patientId;
    this.omr_HCFAFormModel.icD1 = '0';
    this.omr_HCFAFormModel.isbenefitplanno = true;
    this.totalChargeF = '0.00';
    this.totalChargeS = '0.00';
    // this.omr_HCFAFormModel.icD2='0';
    this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', adaptivePosition: true, showWeekNumbers: false });
    if (this.examId != '') {
      this.getHCFA();
      // this.loadExamData();
      // this.getallPatient(this.patientModel.patientInsurances[0].insuranceName)
    }else{
      this.getAllLookup();
      //this.getOffice();
      this.getUser();
    }

  }

  getOffice() {
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;
      this.office = this.office.filter(x => x.office_id == this.patientModel.defaultOfficeID);
      if (this.office[0]?.name_txt) {
        this.officename = this.office[0]?.name_txt
      }

      //if (this.office[0]?.address_1_txt != null && this.office[0].address_2_txt) {
        //this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt} ${this.office[0]?.city_txt} ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;

        // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
        //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
        // }
        if (this.office[0]?.address_1_txt) {
          this.officeaddress = `${this.office[0]?.address_1_txt}`;
        }
        if(this.office[0]?.address_2_txt){
          this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
        }
        if(this.office[0]?.city_txt){
          this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
        }
        if (this.office[0]?.phone_1_txt) {
          this.officephone = this.office[0]?.phone_1_txt;
          if(!this.isHcfaExist){
            let ph = this.officephone.replace(/[^+\d]+/g, "");
            this.provider_phone_area = `(${ph.slice(0, 3)})`;
            let val = ph.slice(3);
            this.provider_phone_number = val.substring(0, 3) + "-" + val.substring(3);
          }
        }
        if(!this.isHcfaExist){
          this.omr_HCFAFormModel.serviceinfo = `${this.officename}\r\n${this.officeaddress}\r\n${this.officeCitySZ}\r\n${this.officephone}`;
          this.omr_HCFAFormModel.providerinfo = `${this.officename}\r\n${this.officeaddress}\r\n${this.officeCitySZ}\r\n${this.officephone}`;
        }
      //}

    }, (error) => {
      this.toaster.error(error.error);
    });
  }
  onValueChange(event: Event): void {
    //console.log(event.target);
    const value = (event.target as any).value;
    this.omr_HCFAFormModel.serviceinfo = value;
  }
  onProviderValueChange(event: Event): void {
    //console.log(event.target);
    const value = (event.target as any).value;
    this.omr_HCFAFormModel.providerinfo = value;
  }
  keytab(event:any){
    let nextInput = event.srcElement.nextElementSibling; // get the sibling element

    var target = event.target || event.srcElement;
    var id = target.value.length;
    console.log(id.maxlength); // prints undefined

    if(nextInput == null)  // check the maxLength from here
        return;
    else
    if (id>=2) {
      nextInput.focus();   // focus if not null
    }

}

  getUser() {

    let provider_id = this.omr_FeeStatementModel.provider_id;
    let practiceId = localStorage.getItem('practice')?.toString();
    this._lookupService.getRenderingProvider(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
      this.userList = res;
      this.user1 = this.userList.filter(x => x.user_id == this.exam.provider_ID);
      this.user = this.user1[0];
      if (this.user1[0]?.license_txt) {
        this.License = this.user1[0]?.license_txt
      }
      var npi = this.user?.npi_txt;
      if(!this.isHcfaExist){
        this.setNPIValues(this.isHcfaExist, npi);
        // if (npi) {
        //   switch(feeServices){
        //     case 1:
        //       this.omr_HCFAFormModel.renderingnpI1 = npi;
        //       break;
        //     case 2:
        //       this.omr_HCFAFormModel.renderingnpI1 = npi;
        //       this.omr_HCFAFormModel.renderingnpI2 = npi;
        //       break;
        //     case 3:
        //       this.omr_HCFAFormModel.renderingnpI1 = npi;
        //       this.omr_HCFAFormModel.renderingnpI2 = npi;
        //       this.omr_HCFAFormModel.renderingnpI3 = npi;
        //       break;
        //     case 4:
        //       this.omr_HCFAFormModel.renderingnpI1 = npi;
        //       this.omr_HCFAFormModel.renderingnpI2 = npi;
        //       this.omr_HCFAFormModel.renderingnpI3 = npi;
        //       this.omr_HCFAFormModel.renderingnpI4 = npi;
        //       break;
        //     case 5:
        //       this.omr_HCFAFormModel.renderingnpI1 = npi;
        //       this.omr_HCFAFormModel.renderingnpI2 = npi;
        //       this.omr_HCFAFormModel.renderingnpI3 = npi;
        //       this.omr_HCFAFormModel.renderingnpI4 = npi;
        //       this.omr_HCFAFormModel.renderingnpI5 = npi;
        //       break;
        //     case 6:
        //       this.omr_HCFAFormModel.renderingnpI1 = npi;
        //       this.omr_HCFAFormModel.renderingnpI2 = npi;
        //       this.omr_HCFAFormModel.renderingnpI3 = npi;
        //       this.omr_HCFAFormModel.renderingnpI4 = npi;
        //       this.omr_HCFAFormModel.renderingnpI5 = npi;
        //       this.omr_HCFAFormModel.renderingnpI6 = npi;
        //       break;
        //     default:
        //       this.omr_HCFAFormModel.renderingnpI1 = '';
        //       this.omr_HCFAFormModel.renderingnpI2 = '';
        //       this.omr_HCFAFormModel.renderingnpI3 = '';
        //       this.omr_HCFAFormModel.renderingnpI4 = '';
        //       this.omr_HCFAFormModel.renderingnpI5 = '';
        //       this.omr_HCFAFormModel.renderingnpI6 = '';
        //       break;
        //   }
        // }
        this.omr_HCFAFormModel.federaltaxnumber = this.user1[0]?.federal_tax_id_type_txt;
        this.omr_HCFAFormModel.eincheck = true;
        this.omr_HCFAFormModel.acceptassignmentyes = true;
        // this.omr_HCFAFormModel.serviceinfoa = npi;
        // this.omr_HCFAFormModel.providerinfoa = npi;
      }


      this.getOffice();
    }, (error) => {
      this.toaster.error(error.error);
    });
  }
  setNPIValues(isHcfaExist: boolean, npi: string){
    var feeServices = 0;
    if(this.omr_FeeStatementModel.omR_FeeStatementService){
      feeServices = this.omr_FeeStatementModel.omR_FeeStatementService.length;
    }
    if(!isHcfaExist){
      if (npi) {
        switch(feeServices){
          case 1:
            this.omr_HCFAFormModel.renderingnpI1 = npi;
            break;
          case 2:
            this.omr_HCFAFormModel.renderingnpI1 = npi;
            this.omr_HCFAFormModel.renderingnpI2 = npi;
            break;
          case 3:
            this.omr_HCFAFormModel.renderingnpI1 = npi;
            this.omr_HCFAFormModel.renderingnpI2 = npi;
            this.omr_HCFAFormModel.renderingnpI3 = npi;
            break;
          case 4:
            this.omr_HCFAFormModel.renderingnpI1 = npi;
            this.omr_HCFAFormModel.renderingnpI2 = npi;
            this.omr_HCFAFormModel.renderingnpI3 = npi;
            this.omr_HCFAFormModel.renderingnpI4 = npi;
            break;
          case 5:
            this.omr_HCFAFormModel.renderingnpI1 = npi;
            this.omr_HCFAFormModel.renderingnpI2 = npi;
            this.omr_HCFAFormModel.renderingnpI3 = npi;
            this.omr_HCFAFormModel.renderingnpI4 = npi;
            this.omr_HCFAFormModel.renderingnpI5 = npi;
            break;
          case 6:
            this.omr_HCFAFormModel.renderingnpI1 = npi;
            this.omr_HCFAFormModel.renderingnpI2 = npi;
            this.omr_HCFAFormModel.renderingnpI3 = npi;
            this.omr_HCFAFormModel.renderingnpI4 = npi;
            this.omr_HCFAFormModel.renderingnpI5 = npi;
            this.omr_HCFAFormModel.renderingnpI6 = npi;
            break;
          default:
            this.omr_HCFAFormModel.renderingnpI1 = '';
            this.omr_HCFAFormModel.renderingnpI2 = '';
            this.omr_HCFAFormModel.renderingnpI3 = '';
            this.omr_HCFAFormModel.renderingnpI4 = '';
            this.omr_HCFAFormModel.renderingnpI5 = '';
            this.omr_HCFAFormModel.renderingnpI6 = '';
            break;
        }
      }
    }
  }


  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.Texilookup = res.filter(x => x.group_txt.toLowerCase() == 'rxnumbera'.toLowerCase() && x.active_ind == true);
      this.Texilookup = this.Texilookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.Texilookupcopy = res.filter(x => x.group_txt.toLowerCase() == 'rxnumbera'.toLowerCase());
      this.ServiceInfoAlookup = res.filter(x => x.group_txt.toLowerCase() == 'ServiceInfoA'.toLowerCase() && x.active_ind == true);
      this.ServiceInfoAlookup = this.ServiceInfoAlookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.ServiceInfoAlookupcopy = res.filter(x => x.group_txt.toLowerCase() == 'ServiceInfoA'.toLowerCase());

      this.ServiceInfoBlookup = res.filter(x => x.group_txt.toLowerCase() == 'ServiceInfoB'.toLowerCase() && x.active_ind == true);
      this.ServiceInfoBlookup = this.ServiceInfoBlookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.ServiceInfoBlookupcopy = res.filter(x => x.group_txt.toLowerCase() == 'ServiceInfoB'.toLowerCase() );

      this.Renderinglookup = res.filter(x => x.group_txt.toLowerCase() == 'Rendering'.toLowerCase() && x.active_ind == true);
      this.Renderinglookup = this.Renderinglookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.Renderinglookupcopy = res.filter(x => x.group_txt.toLowerCase() == 'Rendering'.toLowerCase());

    })
  }

  getallPatient(patientQuery: string) {

    this._lookupService.getInsuarance().subscribe((res: any) => {
      this.rowPatient = res;
      this.rowPatient = res.filter((x: { display_txt: string; }) => x.display_txt == this.patientModel.patientInsurances[0].insuranceName);
      this.defaultValues();
    },
      (error) => {
        this.toaster.error(error.error);
      });
  }
  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }
  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }
  CreateNew(city:string){

  }

  saveDropdown(val: any,name_txt: string, item: LookupModel[], group_txt?: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {
    //if(this.check_value == ""){
    this.check_value = val;
    //}
    if (this.check_value != '') {
      var index = item.findIndex(
        (x) =>
          x.name_txt.trim().toLowerCase() ==
          this.check_value.trim().toLowerCase()
      );
      // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
      // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      if (index < 0) {
        var lookupModel = new LookupModel();
        if (item[0]?.group_txt) {
          lookupModel.group_txt = item[0]?.group_txt;
          lookupModel.sub_group_txt = item[0]?.sub_group_txt;
        } else {
          lookupModel.group_txt = group_txt!;
          lookupModel.sub_group_txt = 'All';
        }
        lookupModel.active_ind = true;
        lookupModel.name_txt = val;

        if (this.check_value != lookupModel.name_txt) {
          lookupModel.name_txt = this.check_value;
        }
        if (dbType != 1) {
          var content = 'Would you like to save this ' + name_txt + ' list?';
          this.globalService
            .modal(content, 'New Item?', 'OK', 'Cancel')
            .subscribe((res) => {
              if (res.res) {
                if (dbType == DbEnum.QE_DEV_SQL_DB) {
                  this._lookupService
                    .insertLookup(lookupModel)
                    .subscribe((res) => {
                      this.getAllLookup();
                      this.toaster.success('Record Saved Successfully.');
                      item1[property] = val; //this.check_value;
                    });
                }
                // else {
                //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
                //     this.toaster.success('Record Saved Successfully.');
                //     item1[property]=this.check_value;
                //     this.getAllLookup();
                //   })
                // }
              }
            });
        }
        item1[property] = val;
      } else {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getAllLookup();
    })
  }

  loadExamData() {

    if (this.examId == '') {
      this.exam = new ExamModel();
    }
    else {
      this._clinicalService.getByExamId(this.examId).subscribe(res => {
        this.exam = Object.assign({}, res);
        if (this.omr_HCFAFormModel.datesigneD1 == undefined || this.omr_HCFAFormModel.datesigneD1 == null) {
          this.omr_HCFAFormModel.datesigneD1 = moment(this.exam?.exam_Date).format("MM-DD-YYYY");
          this.omr_HCFAFormModel.datesigneD3 = moment(this.exam?.exam_Date).format("MM-DD-YYYY");
        }
        this.omr_HCFAFormModel.datesigneD3 = moment(this.exam?.exam_Date).format("MM-DD-YYYY");
        this.exam.omR_Exam.distSubjectODSphere = this._clinicalService.distSubjOD != '' ? '+' + this._clinicalService.distSubjOD : '';
        this.exam.omR_Exam.distSubjectOSSphere = this._clinicalService.distSubjOS != '' ? '+' + this._clinicalService.distSubjOS : '';
        this.exam.omR_Exam.nearSubjectOD = this._clinicalService.nearSubjOD;
        this.exam.omR_Exam.nearSubjectOS = this._clinicalService.nearSubjOS;
        if (this.exam.exam_Assessments != null || this.exam.exam_Assessments != "" || this.exam.exam_Assessments != undefined) {
          var assCount = this.exam.exam_Assessments.length;
          switch (assCount) {
            case 1:
              this.omr_HCFAFormModel.relatE1= this.GetNumberFromAssessment(this.exam.exam_Assessments[0].assessment);
              break;
            case 2:
              this.omr_HCFAFormModel.relatE1= this.GetNumberFromAssessment(this.exam.exam_Assessments[0].assessment);
              this.omr_HCFAFormModel.relatE2= this.GetNumberFromAssessment(this.exam.exam_Assessments[1].assessment);
              break;
            default:
              this.omr_HCFAFormModel.relatE1= this.GetNumberFromAssessment(this.exam.exam_Assessments[0].assessment);
              this.omr_HCFAFormModel.relatE2= this.GetNumberFromAssessment(this.exam.exam_Assessments[1].assessment);
              this.omr_HCFAFormModel.relatE3= this.GetNumberFromAssessment(this.exam.exam_Assessments[2].assessment);
              break;
          }
        }
      });
    }
  }

  defaultValues() {
    if (this.patientModel.middleInitial != null) {
      this.omr_HCFAFormModel.patientname = `${this.patientModel.lastName}, ${this.patientModel.firstName}, ${this.patientModel.middleInitial}`;
    } else {
      this.omr_HCFAFormModel.patientname = `${this.patientModel.lastName}, ${this.patientModel.firstName}`;
    }

    this.omr_HCFAFormModel.patientaddress = this.patientModel?.address;
    this.omr_HCFAFormModel.patientbirthdate = moment(
      this.patientModel?.dateOfBirth
    ).format('DD/MM/YYYY');
    if (this.omr_HCFAFormModel.patientbirthdate != null) {
      var a = this.omr_HCFAFormModel.patientbirthdate.split('/');
      this.day = a[0];
      this.month = a[1];
      this.year = a[2];
    }

    this.omr_HCFAFormModel.programname =
      this.patientModel.patientInsurances[0].insuranceName;

    this.omr_HCFAFormModel.city = this.patientModel?.city;
    this.omr_HCFAFormModel.state = this.patientModel?.state;
    this.omr_HCFAFormModel.zip = this.patientModel?.zip;
    this.omr_HCFAFormModel.telephone = this.patientModel?.homePhone;
    this.omr_HCFAFormModel.icD1 = '0';

    // this.omr_HCFAFormModel.relationshipself = this.patientModel?.relationshipToInsured == '' ? true : false;
    // this.omr_HCFAFormModel.relationshipspouse = this.patientModel?.relationshipToInsured == '' ? true : false;
    // this.omr_HCFAFormModel.relationshipchild = this.patientModel?.relationshipToInsured == '' ? true : false;
    // this.omr_HCFAFormModel.relationshipother = this.patientModel?.relationshipToInsured == '' ? true : false;

    this.omr_HCFAFormModel.employmentno = true;
    this.omr_HCFAFormModel.autoaccidentnot = true;
    this.omr_HCFAFormModel.otheraccidentnot = true;

    this.omr_HCFAFormModel.sexm =
      this.patientModel?.sex.toUpperCase() == 'M' ? true : false;
    this.omr_HCFAFormModel.sexf =
      this.patientModel?.sex.toUpperCase() == 'F' ? true : false;

    this.omr_HCFAFormModel.patientaccountno = this.patientModel?.patientAcctNo;
    this.omr_HCFAFormModel.datesigneD1 = new Date().toDateString();

    this.omr_HCFAFormModel.outsidelabno = true;
    //var rowPatient = this.patientDetail.patientInsurances.length

    if (this.rowPatient != null) {
      var row = this.patientDetail.patientInsurances;
      for (var i = 0; i < row.length; i++) {
        if (
          row[i].insuranceOrder === 0 &&
          row[i].insuredRelation.trim() != 'Other'
        ) {
          this.omr_HCFAFormModel.insurednumber = row[i].memberID;
          //this.omr_HCFAFormModel.insurednumber = this.rowPatient[0].insurance_id;
          // this.omr_HCFAFormModel.insuredaddress = `${this.rowPatient[0]?.address1_txt} ${this.rowPatient[0]?.address2_txt}`;
          this.omr_HCFAFormModel.insuredaddress = this.omr_HCFAFormModel.patientaddress;
          this.omr_HCFAFormModel.citY2 = this.omr_HCFAFormModel.city;//this.rowPatient[0]?.city_txt;
          this.omr_HCFAFormModel.statE2 = this.omr_HCFAFormModel.state;//this.rowPatient[0]?.state_txt;
          this.omr_HCFAFormModel.ziP2 = this.omr_HCFAFormModel.zip;//this.rowPatient[0]?.zip_txt;
          this.omr_HCFAFormModel.telephonE2 = this.omr_HCFAFormModel.telephone;//this.rowPatient[0]?.phone_txt;
          this.omr_HCFAFormModel.insuredname = row[i].insuredName;
          this.omr_HCFAFormModel.relationshipself =
            row[i].insuredRelation.trim() === 'Self' ? true : false;
          this.omr_HCFAFormModel.relationshipspouse =
            row[i].insuredRelation.trim() === 'Spouse' ? true : false;
          this.omr_HCFAFormModel.relationshipchild =
            row[i].insuredRelation.trim() === 'Child' ? true : false;
          this.omr_HCFAFormModel.relationshipother =
            row[i].insuredRelation.trim() === 'Other' ? true : false;
          this.omr_HCFAFormModel.insuredpolicygroup = row[i].groupID;
          var dob = moment(row[i].insuredDOB).format('MM/DD/YYYY');
          var dobObj = dob.split('/');
          this.Insuremonth = dobObj[0];
          this.Insureday = dobObj[1];
          this.Insureyear = dobObj[2];
          this.omr_HCFAFormModel.othersexm =
            this.patientModel?.patientInsurances[0].insuredGender.toUpperCase() ==
            'M'
              ? true
              : false;
          this.omr_HCFAFormModel.othersexf =
            this.patientModel?.patientInsurances[0].insuredGender.toUpperCase() ==
            'F'
              ? true
              : false;
        } else {
          this.omr_HCFAFormModel.relationshipself = false;
          this.omr_HCFAFormModel.relationshipspouse = false;
          this.omr_HCFAFormModel.relationshipchild = false;
          this.omr_HCFAFormModel.relationshipother = true;
          this.omr_HCFAFormModel.otherinsuredname = row[i].insuredName;
          this.omr_HCFAFormModel.otherinsuredgroup = row[i].groupID;
          this.omr_HCFAFormModel.programnamE2 = row[i].insuranceName;
        }

        break;
      }

      // if (this.rowPatient[0].dateOfBirth!=null) {
      //   this.omr_HCFAFormModel.otherinsureddateofbirth = moment(this.rowPatient[0].dateOfBirth).format("DD/MM/YYYY");
      //   var aa = this.omr_HCFAFormModel.otherinsureddateofbirth.split("/");
      // this.Insureday = aa[0];
      // this.Insuremonth = aa[1];
      // this.Insureyear = aa[2];
      // }

      // if (this.patientModel.patientInsurances[0].insuranceName!=null && this.rowPatient[0].middleInitial!=null) {
      //   this.omr_HCFAFormModel.insuredname=this.rowPatient[0].lastName+ ' ' + this.rowPatient[0].firstName+ ' ' +this.rowPatient[0].middleInitial;
      // }
      // else{
      //   this.omr_HCFAFormModel.insuredname=this.rowPatient[0].lastName+ ' ' + this.rowPatient[0].firstName;
      // }
    }
  }
  GetNumberFromAssessment(code: string){
    var num = code.match(/\d+/g);
    if(num && num?.length > 1){
      return `${num[0]}.${num[1]}`;
    }

    return num?  num[0] : '';
  }

  chkCheckbox(type: string ,value:string) {
    if (value=="PATIENT") {
      if (type == 'M') {
        this.omr_HCFAFormModel.sexf = false;
      }
      else {
        this.omr_HCFAFormModel.sexm = false;
      }
    }
    if (value=="INSURED") {
      if (type == 'M') {
        this.omr_HCFAFormModel.othersexf = false;
      }
      else {
        this.omr_HCFAFormModel.othersexm = false;
      }
    }
  }
  copyvalue(event: any) {
    this.omr_HCFAFormModel.ziP2 = event;
  }


  saveHCFA() {

    this.omr_HCFAFormModel.exam_ID=this.exam.exam_ID;
    this._clinicalService.saveHCFA(this.omr_HCFAFormModel).subscribe(res => {
      this.omr_HCFAFormModel = res;
      this.toaster.success('Record Saved Successfully.');
    })
  }

  updateHCFA() {
    this._clinicalService.updateHCFA(this.omr_HCFAFormModel).subscribe(res => {
      this.omr_HCFAFormModel = res;
      this.toaster.success('Record Updated Successfully.');
    })
  }

  getHCFA() {

    this._clinicalService.getHCFA(this.examId, this.patientId).subscribe((res: any) => {

      if (res) {
        this.isHcfaExist = true;
        this.omr_HCFAFormModel = res;

        // this.omr_HCFAFormModel.chargesfirst = Number(this.omr_HCFAFormModel.chargesfirst);
        // this.omr_HCFAFormModel.chargessecond = Number(this.omr_HCFAFormModel.chargessecond);
        if (this.omr_HCFAFormModel.patientbirthdate != null) {
          var p = this.omr_HCFAFormModel.patientbirthdate.split("T");
          p = p[0].split("-");
          this.day = p[2];
          this.month = p[1];
          this.year = p[0];
        }
        if (this.omr_HCFAFormModel.insuranceDate != null) {
          var a = this.omr_HCFAFormModel.insuranceDate.split("T");
           a = a[0].split("-");
          this.Insureday = a[2];
          this.Insuremonth = a[1];
          this.Insureyear = a[0];
        }
        if (this.omr_HCFAFormModel.dateofcurrent != null) {
          var a = this.omr_HCFAFormModel.dateofcurrent.split("T");
           a = a[0].split("-");
          this.PREGNANCYday = a[2];
          this.PREGNANCYmonth = a[1];
          this.PREGNANCYyear = a[0];
        }
        if (this.omr_HCFAFormModel.otherinsureddateofbirth != null) {
          var a = this.omr_HCFAFormModel.otherinsureddateofbirth.split("T");
           a = a[0].split("-");
          this.otherinsuredday = a[2];
          this.otherinsuredmonth = a[1];
          this.otherinsuredyear = a[0];
        }
        if (this.omr_HCFAFormModel.workdatefrom != null) {
          var a = this.omr_HCFAFormModel.workdatefrom.split("T");
           a = a[0].split("-");
          this.workdatefromday = a[2];
          this.workdatefrommonth = a[1];
          this.workdatefromyear = a[0];
        }
        if (this.omr_HCFAFormModel.workdateto != null) {
          var a = this.omr_HCFAFormModel.workdateto.split("T");
           a = a[0].split("-");
          this.workdatetoday = a[2];
          this.workdatetomonth = a[1];
          this.workdatetoyear = a[0];
        }

        if (this.omr_HCFAFormModel.workdatefroM2 != null) {
          var a = this.omr_HCFAFormModel.workdatefroM2.split("T");
           a = a[0].split("-");
          this.hospitalworkdatefromday = a[2];
          this.hospitalworkdatefrommonth = a[1];
          this.hospitalworkdatefromyear = a[0];
        }
        if (this.omr_HCFAFormModel.workdatetO2 != null) {
          var a = this.omr_HCFAFormModel.workdatetO2.split("T");
           a = a[0].split("-");
          this.hospitalworkdatetoday = a[2];
          this.hospitalworkdatetomonth = a[1];
          this.hospitalworkdatetoyear = a[0];
        }

        if (this.omr_HCFAFormModel.dateofservicefroM1 != null && this.omr_HCFAFormModel.dateofservicefroM1 != '--') {

          var a = this.omr_HCFAFormModel.dateofservicefroM1.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthA = a[0];
          this.DayA = a[1];
          this.YearA = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicetO1 != null && this.omr_HCFAFormModel.dateofservicetO1 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicetO1.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthATo = a[0];
          this.DayATo = a[1];
          this.YearATo = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicefroM2 != null && this.omr_HCFAFormModel.dateofservicefroM2 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicefroM2.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthB = a[0];
          this.DayB = a[1];
          this.YearB = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicetO2 != null && this.omr_HCFAFormModel.dateofservicetO2 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicetO2.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthBTo = a[0];
          this.DayBTo = a[1];
          this.YearBTo = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicefroM3 != null && this.omr_HCFAFormModel.dateofservicefroM3 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicefroM3.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthC = a[0];
          this.DayC = a[1];
          this.YearC = a[2];
        }

        if (this.omr_HCFAFormModel.dateofservicetO3 != null && this.omr_HCFAFormModel.dateofservicetO3 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicetO3.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthCTo = a[0];
          this.DayCTo = a[1];
          this.YearCTo = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicefroM4 != null && this.omr_HCFAFormModel.dateofservicefroM4 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicefroM4.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthD = a[0];
          this.DayD = a[1];
          this.YearD = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicetO4 != null && this.omr_HCFAFormModel.dateofservicetO4 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicetO4.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthDTo = a[0];
          this.DayDTo = a[1];
          this.YearDTo = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicefroM5 != null && this.omr_HCFAFormModel.dateofservicefroM5 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicefroM5.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthE = a[0];
          this.DayE = a[1];
          this.YearE = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicetO5 != null && this.omr_HCFAFormModel.dateofservicetO5 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicetO5.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthETo = a[0];
          this.DayETo = a[1];
          this.YearETo = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicefroM6 != null && this.omr_HCFAFormModel.dateofservicefroM6 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicefroM6.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthF = a[0];
          this.DayF = a[1];
          this.YearF = a[2];
        }
        if (this.omr_HCFAFormModel.dateofservicetO6 != null && this.omr_HCFAFormModel.dateofservicetO6 != '--') {
          var a = this.omr_HCFAFormModel.dateofservicetO6.split("T");
          let a1 = moment(a[0], 'DD-MM-YYYY').format('MM-DD-YYYY');
          if(a1 == 'Invalid date'){
            a1 = moment(a[0]).format('MM-DD-YYYY');
          }
          a = a1.split("-");
          this.MonthFTo = a[0];
          this.DayFTo = a[1];
          this.YearFTo = a[2];
        }
        if(this.omr_HCFAFormModel.phoneno){
          let ph = this.omr_HCFAFormModel.phoneno.replace(/[^+\d]+/g, "");
          this.provider_phone_area = `(${ph.slice(0, 3)})`;
          let val = ph.slice(3);
          this.provider_phone_number = val.substring(0, 3) + "-" + val.substring(3);
        }

      } else {
        //this.defaultValues();
        this.isHcfaExist = false;
        this.loadExamData();
        this.getallPatient(this.patientModel.patientInsurances[0].insuranceName);
        this.Updatefees();
      }
      this.getAllLookup();
      //this.getOffice();
      this.getUser();
    }, (error) => {
      console.log(error);
      if (error.status == 404) {
        this.defaultValues();
      } else {
        this.toaster.error('Error occurs while getting HCFA.');
      }
    });

  }
  onFocusA()
  {
    if (this.MonthATo=="" ) {
      this.MonthATo=this.MonthA;
    }
    if (this.YearATo=="" ) {
      this.YearATo=this.YearA;
    }
    if (this.DayATo=="" ) {
      this.DayATo=this.DayA;
    }
  }

  onFocusB()
  {
    if (this.MonthBTo=="" ) {
      this.MonthBTo=this.MonthB;
    }
    if (this.YearBTo=="" ) {
      this.YearBTo=this.YearB;
    }
    if (this.DayBTo=="" ) {
      this.DayBTo=this.DayB;
    }
  }

  onFocusC()
  {
    if (this.MonthCTo=="" ) {
      this.MonthCTo=this.MonthC;
    }
    if (this.YearCTo=="" ) {
      this.YearCTo=this.YearC;
    }
    if (this.DayCTo=="" ) {
      this.DayCTo=this.DayC;
    }
  }

  onFocusD()
  {
    if (this.MonthDTo=="" ) {
      this.MonthDTo=this.MonthD;
    }
    if (this.YearDTo=="" ) {
      this.YearDTo=this.YearD;
    }
    if (this.DayDTo=="" ) {
      this.DayDTo=this.DayD;
    }

  }

  onFocusE()
  {
    if (this.MonthETo=="" ) {
      this.MonthETo=this.MonthE;
    }
    if (this.YearETo=="" ) {
      this.YearETo=this.YearE;
    }
    if (this.DayETo=="" ) {
      this.DayETo=this.DayE;
    }
  }

  onFocusF()
  {
    if (this.MonthFTo=="" ) {
      this.MonthFTo=this.MonthF;
    }
    if (this.YearFTo=="" ) {
      this.YearFTo=this.YearF;
    }
    if (this.DayFTo=="" ) {
      this.DayFTo=this.DayF;
    }
  }

  save(isNavigate: boolean) {

    this.omr_HCFAFormModel.patientbirthdate = this.month+"-"+this.day+"-"+this.year;
    this.omr_HCFAFormModel.insuranceDate=this.Insuremonth+"-"+this.Insureday+"-"+this.Insureyear;
    this.omr_HCFAFormModel.dateofcurrent=this.PREGNANCYday+"-"+this.PREGNANCYday+"-"+this.PREGNANCYyear;
    this.omr_HCFAFormModel.otherinsureddateofbirth=this.otherinsuredmonth+"-"+this.otherinsuredday+"-"+this.otherinsuredyear;
    this.omr_HCFAFormModel.workdatefrom=this.workdatefrommonth+"-"+this.workdatefromday+"-"+this.workdatefromyear;
    this.omr_HCFAFormModel.workdateto=this.workdatetomonth+"-"+this.workdatetoday+"-"+this.workdatetoyear;
    this.omr_HCFAFormModel.workdatefroM2=this.hospitalworkdatefrommonth+"-"+this.hospitalworkdatefromday+"-"+this.hospitalworkdatefromyear;
    this.omr_HCFAFormModel.workdatetO2=this.hospitalworkdatetomonth+"-"+this.hospitalworkdatetoday+"-"+this.hospitalworkdatetoyear;
    this.omr_HCFAFormModel.dateofservicefroM1=this.MonthA+"-"+this.DayA+"-"+this.YearA;
    this.omr_HCFAFormModel.dateofservicetO1=this.MonthATo+"-"+this.DayATo+"-"+this.YearATo;
    this.omr_HCFAFormModel.dateofservicefroM2=this.MonthB+"-"+this.DayB+"-"+this.YearB;
    this.omr_HCFAFormModel.dateofservicetO2=this.MonthBTo+"-"+this.DayBTo+"-"+this.YearBTo;
    this.omr_HCFAFormModel.dateofservicefroM3=this.MonthC+"-"+this.DayC+"-"+this.YearC;
    this.omr_HCFAFormModel.dateofservicetO3=this.MonthCTo+"-"+this.DayCTo+"-"+this.YearCTo;
    this.omr_HCFAFormModel.dateofservicefroM4=this.MonthD+"-"+this.DayD+"-"+this.YearD;
    this.omr_HCFAFormModel.dateofservicetO4=this.MonthDTo+"-"+this.DayDTo+"-"+this.YearDTo;
    this.omr_HCFAFormModel.dateofservicefroM5=this.MonthE+"-"+this.DayE+"-"+this.YearE;
    this.omr_HCFAFormModel.dateofservicetO5=this.MonthETo+"-"+this.DayETo+"-"+this.YearETo;
    this.omr_HCFAFormModel.dateofservicefroM6=this.MonthF+"-"+this.DayF+"-"+this.YearF;
    this.omr_HCFAFormModel.dateofservicetO6=this.MonthFTo+"-"+this.DayFTo+"-"+this.YearFTo;
    this.omr_HCFAFormModel.phoneno = `${this.provider_phone_area} ${this.provider_phone_number}`;
    this.omr_HCFAFormModel.datesigneD3 = moment(this.omr_HCFAFormModel.datesigneD3).format("MM-DD-YYYY");

    if (this.omr_HCFAFormModel.exam_ID == '') {
      this.saveHCFA();
    }
    else {
      this.updateHCFA();
    }
    if (isNavigate) {
      this._clinicalService.navigateToClinic();
    }
  }

  Updatefees()
  {

    this._omr_FeeStatementservice.getByID(this.examId,this.patientId).subscribe((res: any) => {

      this.omr_FeeStatementModel = Object.assign({}, res[0]);
      this.omr_HCFAFormModel.amountpaidfirst = '0';
    this.i=0;
      if (this.omr_FeeStatementModel.omR_FeeStatementService) {
        if (this.omr_FeeStatementModel.omR_FeeStatementService.length>0) {

        this.omr_FeeStatementModel.omR_FeeStatementService.forEach(element => {
          this.i=this.i+1;
          if (this.i==1) {
            var dosDt = moment(element.dos_dt).format('MM/DD/YYYY');
            var a = dosDt.split("/");
            this.MonthA = a[0];
            this.DayA = a[1];
            this.YearA = a[2];

            this.MonthATo = a[0];
            this.DayATo = a[1];
            this.YearATo = a[2];
            this.omr_HCFAFormModel.placeservicE1 = '11';
            this.omr_HCFAFormModel.cpthcpcS1=element.code_txt;
            this.omr_HCFAFormModel.diagnosispointeR1 = element.dx_number_txt == '1' ? 'A' : element.dx_number_txt.toUpperCase();
            this.omr_HCFAFormModel.unitS1='1';
            let t1 = Number(element.unit_amt)*Number(element.unit_cnt);
            this.omr_HCFAFormModel.chargesfirsT1 = Math.floor(t1);
            this.omr_HCFAFormModel.chargesseconD1 = Number((t1 - Math.floor(t1)).toFixed(2).split('.')[1])
            this.omr_HCFAFormModel.amountpaidfirst = Number(+this.omr_HCFAFormModel.amountpaidfirst + element.pat_total).toFixed();
          }
          else if (this.i==2) {
            var dosDt = moment(element.dos_dt).format('MM/DD/YYYY');
            var b = dosDt.split("/");
            this.MonthB = b[0];
            this.DayB = b[1];
            this.YearB = b[2];

            this.MonthBTo = b[0];
            this.DayBTo = b[1];
            this.YearBTo = b[2];
            this.omr_HCFAFormModel.placeservicE2 = '11';
            this.omr_HCFAFormModel.cpthcpcS2=element.code_txt;
            this.omr_HCFAFormModel.diagnosispointeR2 = (element.dx_number_txt == '1' || element.dx_number_txt == '2') ? 'B' : element.dx_number_txt.toUpperCase();
            this.omr_HCFAFormModel.unitS2='1';
            //this.omr_HCFAFormModel.chargesfirsT2=Number(element.unit_amt)*Number(element.unit_cnt);
            let t2 = Number(element.unit_amt)*Number(element.unit_cnt);
            this.omr_HCFAFormModel.chargesfirsT2 = Math.floor(t2);
            this.omr_HCFAFormModel.chargesseconD2 = Number((t2 - Math.floor(t2)).toFixed(2).split('.')[1])

            //this.omr_HCFAFormModel.amountpaidfirst += element.pat_total;
            this.omr_HCFAFormModel.amountpaidfirst = Number(+this.omr_HCFAFormModel.amountpaidfirst + element.pat_total).toFixed();
          }
          else if (this.i==3) {
            var dosDt = moment(element.dos_dt).format('MM/DD/YYYY');
            var c = dosDt.split("/");
            this.MonthC = c[0];
            this.DayC = c[1];
            this.YearC = c[2];

            this.MonthCTo = c[0];
            this.DayCTo = c[1];
            this.YearCTo = c[2];
            this.omr_HCFAFormModel.placeservicE3 = '11';
            this.omr_HCFAFormModel.cpthcpcS3=element.code_txt;
            this.omr_HCFAFormModel.diagnosispointeR3 = element.dx_number_txt == '3' ? 'C' : element.dx_number_txt.toUpperCase();
            this.omr_HCFAFormModel.unitS3='1';
            //this.omr_HCFAFormModel.chargesfirsT3=Number(element.unit_amt)*Number(element.unit_cnt);
            let t3 = Number(element.unit_amt)*Number(element.unit_cnt);
            this.omr_HCFAFormModel.chargesfirsT3 = Math.floor(t3);
            this.omr_HCFAFormModel.chargesseconD3 = Number((t3 - Math.floor(t3)).toFixed(2).split('.')[1])
            //this.omr_HCFAFormModel.amountpaidfirst += element.pat_total;
            this.omr_HCFAFormModel.amountpaidfirst = Number(+this.omr_HCFAFormModel.amountpaidfirst + element.pat_total).toFixed();
          }
          else if (this.i==4) {
            var dosDt = moment(element.dos_dt).format('MM/DD/YYYY');
            var d = dosDt.split("/");
            this.MonthD = d[0];
            this.DayD = d[1];
            this.YearD = d[2];

            this.MonthDTo = d[0];
            this.DayDTo = d[1];
            this.YearDTo = d[2];
            this.omr_HCFAFormModel.placeservicE4 = '11';
            this.omr_HCFAFormModel.cpthcpcS4=element.code_txt;
            this.omr_HCFAFormModel.diagnosispointeR4 = element.dx_number_txt == '4' ? 'D' : element.dx_number_txt.toUpperCase();
            this.omr_HCFAFormModel.unitS4='1';
            //this.omr_HCFAFormModel.chargesfirsT4=Number(element.unit_amt)*Number(element.unit_cnt);
            let t4 = Number(element.unit_amt)*Number(element.unit_cnt);
            this.omr_HCFAFormModel.chargesfirsT4 = Math.floor(t4);
            this.omr_HCFAFormModel.chargesseconD4 = Number((t4 - Math.floor(t4)).toFixed(2).split('.')[1])
            //this.omr_HCFAFormModel.amountpaidfirst += element.pat_total;
            this.omr_HCFAFormModel.amountpaidfirst = Number(+this.omr_HCFAFormModel.amountpaidfirst + element.pat_total).toFixed();
          }
          else if (this.i==5) {
            var dosDt = moment(element.dos_dt).format('MM/DD/YYYY');
            var e = dosDt.split("/");
            this.MonthE = e[0];
            this.DayE = e[1];
            this.YearE = e[2];

            this.MonthETo = e[0];
            this.DayETo = e[1];
            this.YearETo = e[2];
            this.omr_HCFAFormModel.placeservicE5 = '11';
            this.omr_HCFAFormModel.cpthcpcS5=element.code_txt;
            this.omr_HCFAFormModel.diagnosispointeR5 = element.dx_number_txt == '5' ? 'E' : element.dx_number_txt.toUpperCase();
            this.omr_HCFAFormModel.unitS5='1';
            //this.omr_HCFAFormModel.chargesfirsT5=Number(element.unit_amt)*Number(element.unit_cnt);
            let t5 = Number(element.unit_amt)*Number(element.unit_cnt);
            this.omr_HCFAFormModel.chargesfirsT5 = Math.floor(t5);
            this.omr_HCFAFormModel.chargesseconD5 = Number((t5 - Math.floor(t5)).toFixed(2).split('.')[1])
            //this.omr_HCFAFormModel.amountpaidfirst += element.pat_total;
            this.omr_HCFAFormModel.amountpaidfirst = Number(+this.omr_HCFAFormModel.amountpaidfirst + element.pat_total).toFixed();
          }
          else if (this.i==6) {
            var dosDt = moment(element.dos_dt).format('MM/DD/YYYY');
            var f = dosDt.split("/");
            this.MonthF = f[0];
            this.DayF = f[1];
            this.YearF = f[2];

            this.MonthFTo = f[0];
            this.DayFTo = f[1];
            this.YearFTo = f[2];
            this.omr_HCFAFormModel.placeservicE6 = '11';
            this.omr_HCFAFormModel.cpthcpcS6=element.code_txt;
            this.omr_HCFAFormModel.diagnosispointeR6 = element.dx_number_txt == '6' ? 'F' : element.dx_number_txt.toUpperCase();
            this.omr_HCFAFormModel.unitS6='1';
            //this.omr_HCFAFormModel.chargesfirsT6=Number(element.unit_amt)*Number(element.unit_cnt);
            let t6 = Number(element.unit_amt)*Number(element.unit_cnt);
            this.omr_HCFAFormModel.chargesfirsT6 = Math.floor(t6);
            this.omr_HCFAFormModel.chargesseconD6 = Number((t6 - Math.floor(t6)).toFixed(2).split('.')[1])
            //this.omr_HCFAFormModel.amountpaidfirst += element.pat_total;
            this.omr_HCFAFormModel.amountpaidfirst = Number(+this.omr_HCFAFormModel.amountpaidfirst + element.pat_total).toFixed();
          }
        });

        if(this.user.npi_txt){
          this.setNPIValues(false, this.user.npi_txt);
        }
      }
    }
      // if (this.omr_FeeStatementModel.omR_FeeStatementItem.length>0) {

      //   this.omr_FeeStatementModel.omR_FeeStatementItem.forEach(element => {
      //     this.i=this.i+1;
      //     if (this.i==1) {
      //       this.omr_HCFAFormModel.cpthcpcS1=element.code_txt;
      //       this.omr_HCFAFormModel.diagnosispointeR1=element.dx_number_txt;
      //       this.omr_HCFAFormModel.unitS1='1';
      //       this.omr_HCFAFormModel.chargesfirsT1=Number(element.unit_amt)*Number(element.unit_cnt);
      //              }
      //     else if (this.i==2) {
      //       this.omr_HCFAFormModel.cpthcpcS2=element.code_txt;
      //       this.omr_HCFAFormModel.diagnosispointeR2=element.dx_number_txt;
      //       this.omr_HCFAFormModel.unitS2='1';
      //       this.omr_HCFAFormModel.chargesfirsT2=Number(element.unit_amt)*Number(element.unit_cnt);
      //     }
      //     else if (this.i==3) {
      //       this.omr_HCFAFormModel.cpthcpcS3=element.code_txt;
      //       this.omr_HCFAFormModel.diagnosispointeR3=element.dx_number_txt;
      //       this.omr_HCFAFormModel.unitS3='1';
      //       this.omr_HCFAFormModel.chargesfirsT3=Number(element.unit_amt)*Number(element.unit_cnt);
      //     }
      //     else if (this.i==4) {
      //       this.omr_HCFAFormModel.cpthcpcS4=element.code_txt;
      //       this.omr_HCFAFormModel.diagnosispointeR4=element.dx_number_txt;
      //       this.omr_HCFAFormModel.unitS4='1';
      //       this.omr_HCFAFormModel.chargesfirsT4=Number(element.unit_amt)*Number(element.unit_cnt);
      //     }
      //     else if (this.i==5) {
      //       this.omr_HCFAFormModel.cpthcpcS5=element.code_txt;
      //       this.omr_HCFAFormModel.diagnosispointeR5=element.dx_number_txt;
      //       this.omr_HCFAFormModel.unitS5='1';
      //       this.omr_HCFAFormModel.chargesfirsT5=Number(element.unit_amt)*Number(element.unit_cnt);
      //     }
      //     else if (this.i==6) {
      //       this.omr_HCFAFormModel.cpthcpcS6=element.code_txt;
      //       this.omr_HCFAFormModel.diagnosispointeR6=element.dx_number_txt;
      //       this.omr_HCFAFormModel.unitS6='1';
      //       this.omr_HCFAFormModel.chargesfirsT6=Number(element.unit_amt)*Number(element.unit_cnt);
      //     }
      //   });
      // }
      this.onChargeAdded();

    }, (error) => {
      this.toaster.error('Error while getting MiscFeeStatement.');
    });

  }

  onChargeAdded() {

    this.omr_HCFAFormModel.totalchargefirst = '0';//parseInt(this.omr_HCFAFormModel.chargesfirsT1.toString()) + parseInt(this.omr_HCFAFormModel.chargesfirsT2.toString()) + parseInt(this.omr_HCFAFormModel.chargesfirsT3.toString()) + parseInt(this.omr_HCFAFormModel.chargesfirsT4.toString()) + parseInt(this.omr_HCFAFormModel.chargesfirsT5.toString()) + parseInt(this.omr_HCFAFormModel.chargesfirsT6.toString());
    this.omr_HCFAFormModel.totalchargesecond = '00'; //parseInt(this.omr_HCFAFormModel.chargesseconD1.toString()) + parseInt(this.omr_HCFAFormModel.chargesseconD2.toString()) + parseInt(this.omr_HCFAFormModel.chargesseconD3.toString()) + parseInt(this.omr_HCFAFormModel.chargesseconD4.toString()) + parseInt(this.omr_HCFAFormModel.chargesseconD5.toString()) + parseInt(this.omr_HCFAFormModel.chargesseconD6.toString());
    if (this.omr_HCFAFormModel.chargesfirsT1){
      this.omr_HCFAFormModel.totalchargefirst = Number(+this.omr_HCFAFormModel.totalchargefirst + +this.omr_HCFAFormModel.chargesfirsT1).toFixed();
    }
    if (this.omr_HCFAFormModel.chargesfirsT2){
      this.omr_HCFAFormModel.totalchargefirst = Number(+this.omr_HCFAFormModel.totalchargefirst + +this.omr_HCFAFormModel.chargesfirsT2).toFixed();
    }
    if (this.omr_HCFAFormModel.chargesfirsT3){
      this.omr_HCFAFormModel.totalchargefirst = Number(+this.omr_HCFAFormModel.totalchargefirst + +this.omr_HCFAFormModel.chargesfirsT3).toFixed();
    }
    if (this.omr_HCFAFormModel.chargesfirsT4){
      this.omr_HCFAFormModel.totalchargefirst = Number(+this.omr_HCFAFormModel.totalchargefirst + +this.omr_HCFAFormModel.chargesfirsT4).toFixed();
    }
    if (this.omr_HCFAFormModel.chargesfirsT5){
      this.omr_HCFAFormModel.totalchargefirst = Number(+this.omr_HCFAFormModel.totalchargefirst + +this.omr_HCFAFormModel.chargesfirsT5).toFixed();
    }
    if (this.omr_HCFAFormModel.chargesfirsT6){
      this.omr_HCFAFormModel.totalchargefirst = Number(+this.omr_HCFAFormModel.totalchargefirst + +this.omr_HCFAFormModel.chargesfirsT6).toFixed();
    }

    //*********************** */

    if(this.omr_HCFAFormModel.chargesseconD1){
      this.omr_HCFAFormModel.totalchargesecond = Number(+this.omr_HCFAFormModel.totalchargesecond + +this.omr_HCFAFormModel.chargesseconD1).toFixed(2);
    }
    if(this.omr_HCFAFormModel.chargesseconD2){
      this.omr_HCFAFormModel.totalchargesecond = Number(+this.omr_HCFAFormModel.totalchargesecond + +this.omr_HCFAFormModel.chargesseconD2).toFixed(2);
    }
    if(this.omr_HCFAFormModel.chargesseconD3){
      this.omr_HCFAFormModel.totalchargesecond = Number(+this.omr_HCFAFormModel.totalchargesecond + +this.omr_HCFAFormModel.chargesseconD3).toFixed(2);
    }
    if(this.omr_HCFAFormModel.chargesseconD4){
      this.omr_HCFAFormModel.totalchargesecond = Number(+this.omr_HCFAFormModel.totalchargesecond + +this.omr_HCFAFormModel.chargesseconD4).toFixed(2);
    }
    if(this.omr_HCFAFormModel.chargesseconD5){
      this.omr_HCFAFormModel.totalchargesecond = Number(+this.omr_HCFAFormModel.totalchargesecond + +this.omr_HCFAFormModel.chargesseconD5).toFixed(2);
    }
    if(this.omr_HCFAFormModel.chargesseconD6){
      this.omr_HCFAFormModel.totalchargesecond = Number(+this.omr_HCFAFormModel.totalchargesecond + +this.omr_HCFAFormModel.chargesseconD6).toFixed(2);
    }

  }
}
