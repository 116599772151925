import { PatientModel } from 'src/app/model/patientModel';
import { ExamModel } from 'src/app/model/ExamModel';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OMR_EXAMModel } from 'src/app/model/OMR_EXAMModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-specrx',
  templateUrl: './pre-specrx.component.html',
  styleUrls: ['./pre-specrx.component.scss']
})
export class PreSpecrxComponent implements OnInit {

  columnSpecRx:any;
  rowSpecRx:ExamModel[] = [];
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  constructor(private _clinicalService : ClinicalService,private routers:Router) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
   }

  ngOnInit() {
    this.columnSpecRx = [
      {
        headerName: 'Exam Date',
        field: 'exam_Date', sortable: true, resizable: true, rowDrag: true, filter: true, floatingFilter: false,width:150
        ,cellRenderer: (data:any) => {
          return moment(data.value).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Chief Complaint',
        field: 'cheif_Complaint', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
      },
      {
        headerName: 'OD',
        //field: 'omR_Prescriptions.addOD', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
        field: 'omR_Exam.curRXODSphere', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
      },
      {
        headerName: 'OS',
        // field: 'omR_Prescriptions.addOS', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
        field: 'omR_Exam.curRXOSSphere', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
      },
      {
        headerName: 'Add OD',
        // field: 'omR_Exam.curRXODSphere', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
        field: 'omR_Prescriptions.addOD', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
      },
      {
        headerName: 'Add OS',
        // field: 'omR_Exam.curRXOSSphere', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
        field: 'omR_Prescriptions.addOS', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:250
      }
    ];
    // this.rowSpecRx = [];
    this.getExam();
  }

  getExam(){    
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.rowSpecRx = res;
    });
  }

  navigate(event:any){
    this.routers.navigate(["/patient/spectacle"],{ queryParams: { patientId:this.patientModel.patientID,examId : event.data.exam_ID } });
  }  

}
