import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InsuranceModel } from 'src/app/model/insuranceModel';
import { LookupModel } from 'src/app/model/lookupModel';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.scss']
})
export class InsurancesComponent implements OnInit {

  insuranceFormGroup!: FormGroup; 
  showButton = false;
  enablEditButton = false;
  showActive: boolean = false;
  constructor(private formBuilder : FormBuilder,private _lookupService:LookupService,private toaster: ToastrService) { }
  insuranceModel : InsuranceModel = new InsuranceModel()
  insuranceList : InsuranceModel[] = [];
  insurance : InsuranceModel[] = [];
  insuranceString : string = '';
  states : LookupModel[] = [];
  selectedInsurance: string = "";
  ngOnInit() {
    this.createFormGroup();
    this.getAllInsurance();
    this.insuranceFormGroup.disable();
  }

  createFormGroup()
  {
    this.insuranceFormGroup = this.formBuilder.group({
      insurance_id:[''],
      display_txt : ['',Validators.required],
      address1_txt:[''],
      address2_txt:[],
      city_txt:[],
      state_txt:[],
      zip_txt:[],
      type_val:[],
      payer_name_txt:[],
      claim_type_txt:[],
      modify_charge_pct:[],
      active_ind:[],
      created_dt:[],
      created_by:[],
      updated_dt:[],
      updated_by:[],
      phone_txt:[],
      fax_txt:[],
      payer_id_txt:[],
      rep_phone_txt:[],
      password_txt:[],
      rep_name_txt:[],
      rep_email_txt:['',Validators.email],
      rep_website_txt:[],
      user_id_txt:[]
    })
  }

  create()
  {
    this.showButton = true;
  }

  cancel()
  {
    this.showButton = false;
    if(this.insuranceModel.insurance_id==''){
      this.insuranceFormGroup.reset();
    }
    this.insuranceFormGroup.disable();
  }

  edit()
  {
    this.showButton = true;
    this.insuranceFormGroup.enable(); 
  }

  getAllInsurance(){
    this._lookupService.getInsuarance().subscribe(res=>{
      this.insurance = Object.assign([],res)
      //this.findInsurance();
      this.insuranceList = this.insurance;
      this.filterInsurance(this.showActive);
      this.insuranceList = this.compare(this.insuranceList);
      this.getAllLookup();
    })
  }

  setForm(insurance:InsuranceModel){
    this.insuranceModel = Object.assign({},insurance)
    this.enablEditButton = true;
    this.selectedInsurance= this.insuranceModel.insurance_id;
  }

  addNew(){
    this.insuranceFormGroup.enable();
    this.insuranceFormGroup.reset();
    this.insuranceModel = new InsuranceModel();
    this.showButton = true;
  }

  save(){
    if(this.insuranceModel.insurance_id== ''){
      if (this.insuranceFormGroup.valid) {
        this._lookupService.insertInsuarance(this.insuranceModel).subscribe(res=>{
          this.getAllInsurance();
          this.showButton = false;
          this.enablEditButton = false;
          
          this.toaster.success('Record Saved Successfully.');
        }, (error) => {
            this.toaster.error(error.error);
          });
      }
      
    }
    else{
      if (this.insuranceFormGroup.valid) {
        this._lookupService.editInsuarance(this.insuranceModel).subscribe(res=>{
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
          this.getAllInsurance();
        }, (error) => {
            this.toaster.error(error.error);
          });   
          this.insuranceFormGroup.disable();
          this.showButton = false;     
      }      
    }    
  }

  findInsurance(event: any){
    this.insuranceString = event.target.value;
    if (this.insuranceString == '') {
      if (this.showActive){
        this.insuranceList = this.insurance;
      }else{
        this.filterInsurance(false);
      }
    }
    else{
      if (this.showActive){
        this.insuranceList = this.insurance.filter(x=>x.display_txt.toLowerCase().includes(this.insuranceString.toLowerCase()))
      }else{
        this.insuranceList = this.insurance.filter(x=>x.display_txt.toLowerCase().includes(this.insuranceString.toLowerCase()) && x.active_ind == true)
      }    
    }
    this.insuranceList = this.compare(this.insuranceList);
    // if(this.insuranceString == ''){
    //   this.insuranceList = this.insurance;
    // }
    // else{
    //   this.insuranceList = this.insurance.filter(x=>x.display_txt.toLowerCase().includes(this.insuranceString));
    // }
  }

  getAllLookup(){
    this._lookupService.getGlobalLookup('states').subscribe((res:LookupModel[])=>{
      this.states = res;
    })
  }
  filterInsurance(active:boolean){    
    this.showActive = active;    
    if(!this.showActive)
    {
      this.insuranceList = this.insurance.filter(x => x.active_ind == true);
    } else {
      this.insuranceList = this.insurance.filter(x => x.active_ind == false);
    }
    this.insuranceList = this.compare(this.insuranceList);
  }
  compare(array: InsuranceModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.display_txt)) && !isNaN(Number(b.display_txt))){      
          if (Number(a.display_txt) > Number(b.display_txt)){
            return 1;
          }else if(Number(a.display_txt) < Number(b.display_txt)){
            return -1;
          }else{
            return 0;
          }
          
      }else{         
        if (a.display_txt.toLowerCase() > b.display_txt.toLowerCase()){
            return 1;
        }else if(a.display_txt.toLowerCase() < b.display_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }      
      }    
    })
    return array;
  }
}
