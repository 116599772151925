<div id="content" style="height: 50%;">
    <div class="titlebox">Diagnosis/Problem List History</div>
    <br>
    <br>
    <div class="row">
        <div class="col-md-4">
            <button class="btn btn-primary px-4" (click)="addDiagnosis()">
                Add
            </button> &nbsp;
            <button class="btn btn-primary px-4" (click)="save()">
                Save
            </button> &nbsp;
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <div class="row mx-5">
                <label class="pt-1 mx-1">Status:</label>
                <select class="form-control col-md-6" [(ngModel)]="filterString" (change)="filter()">
                    <option value="All">-All-</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Active">Active</option>
                    <option value="Resolved">Resolved</option>
                </select>
            </div>
        </div>
    </div>
    <div class="pt-2">
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 500px; width: 100%;" [rowSelection]="'multiple'" class="ag-theme-alpine" [rowDragManaged]="true" [rowData]="rowDiagnosis" [columnDefs]="columnDiagnosis">
        </ag-grid-angular>
    </div>
</div>