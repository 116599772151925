import { LoginService } from './services/login.service';
import { AuthGuard } from './core/guard/auth.guard';
import { LoginComponent } from './shared/login/login.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginModule } from './shared/login/login.module';
import { LookupModalComponent } from './shared/lookup-modal/lookup-modal.component';
import { EditLookupComponent } from './shared/edit-lookup/edit-lookup.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'customer',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./components/customer/customer.module').then(m => m.CustomerModule),
    canActivate: [AuthGuard]
  },
  {
    path:'Lookup',
    component: EditLookupComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () =>
      import('./shared/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'dashboard',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'patient',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./components/patient/patient.module').then(m => m.PatientModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'administration',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./components/administration/administration.module').then(m => m.AdministrationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'scheduling',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./components/scheduling/scheduling.module').then(m => m.SchedulingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./components/report/report.module').then(m => m.ReportModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
