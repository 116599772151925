<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
          <button class="button" style="height: 68px;" (click)="goProducts()" routerLinkActive="active">
                <!-- <img src="../../../../assets/img/close.png"
                    style="height: 30px;width: 30px;border-radius: 30px;"> -->
                    {{buttoncancle}}

            </button>&nbsp;
            <button class="button" style="height: 68px;"(click)="save()">
                <!-- <img src="../../../../assets/img/save.ico"
                    style="height: 30px;width: 30px;border-radius: 30px;"> -->
                   {{buttonsave}}

            </button>&nbsp;
            <button class="button" style="height: 68px;" (click)="saveClose()">
                <!-- <img src="../../../../assets/img/save.ico"
                    style="height: 30px;width: 30px;border-radius: 30px;"> -->
                    Save & Close
            </button>&nbsp;&nbsp;
            <button class="button" style="height: 68px;"  [useExistingCss]="true" printSectionId="print-section"
            ngxPrint>
            <!-- <img src="../../../../assets/img/print_printer.png" style="height: 30px;width: 30px;"> -->
            Print
        </button> &nbsp;&nbsp;
            <hr>
        </div>
    </div>
</div>

    <fieldset class="misc" style="height: auto; margin-top: 55px;" style="background-color: white;padding-top: 100px;">
        <div class="container" >
            <div class="form-group" >
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-1 text-right">
                                <label><b>Date of Service:</b></label>
                            </div>
                            <div class="col-md-3">
                                <input class="form-control" (ngModelChange)="omr_FeeStatementModel.dos_dt = $event.toDateString('MM/dd/yyyy')" [value]="omr_FeeStatementModel.dos_dt | date:'MM/dd/yyyy'" [ngModel]="omr_FeeStatementModel.dos_dt | date:'MM/dd/yyyy'" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker">
                            </div>
                            <div class="col-md-1 text-right">
                                <label><b>Provider:</b></label>
                            </div>
                            <div class="col-md-4">
                                <table style="width:100%">
                                    <tr>
                                        <td>
                                            <ng-select [items]="providerList" class="col-md-12" bindLabel="display_txt" bindValue="user_id" [(ngModel)]="omr_FeeStatementModel.provider_id">
                                            </ng-select>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)" (click)="editOffice('Provider','Edit Providers', false)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                    <div class="form-group">
                        <div class= "row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-1">
                                        <label><b>Insurance:</b></label>
                                    </div>
                                    <div class="col-md-3">
                                        <table style="width:100%">
                                            <tr>
                                                <td>
                                                    <input type="search" hidden [(ngModel)]="omr_FeeStatementModel.insurance_id">
                                                    <input type="search" class="form-control col-md-10" (input)="searchInsurance($event)" (click)="hideDropdown('insurance')" [(ngModel)]="omr_FeeStatementModel.insurance_display_txt">
                                                </td>
                                                <td>
                                                    <a href="javascript:void(0)" (click)="editOffice('Insurance','Edit Insurances', false)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                                </td>
                                            </tr>
                                        </table>
                                        <div *ngIf="showInsuarance">
                                            <div>
                                                <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="width: 90%;" [rowStyle]="rowStyle" rowSelection="single" (rowClicked)="insuranceRowClicked($event)" domLayout="autoHeight" class="ag-theme-alpine dropdown" [rowData]="rowInsurance" [rowDragManaged]="true" [columnDefs]="columnInsurance">
                                                </ag-grid-angular>
                                                <!-- <span style="cursor: pointer;" class="dropdown" (click)="openProvider(provider)">edit list</span> -->
                                            </div>

                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-1 text-right">
                                        <label><b>Office:</b></label>
                                    </div>
                                    <div class="col-md-4">
                                        <table style="width:100%">
                                            <tr>
                                                <td>
                                                    <ng-select [items]="office" class="col-md-10" bindLabel="name_txt" bindValue="office_id" [(ngModel)]="omr_FeeStatementModel.office_id">
                                                    </ng-select>
                                                </td>
                                                <td>
                                                    <a href="javascript:void(0)" (click)="editOffice('General','Edit Facilities', false)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>

        <ng-template #provider let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Provider</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-md-10">
                    <div class="form-group">
                        <label>Type:</label>
                        <select class="form-control">
                            <option value="PT">PT Req</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-10">
                    <div class="form-group">
                        <label>Note:</label>
                        <textarea class="form-control">
            </textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-6" style="margin: 10px;">
                        <div class="form-group">
                            <label>
                                <input type="checkbox" style="margin: 3px;">Active
                            </label>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6" style="margin-right: 2px;">
                        <div class="form-group">
                            <label>Expiration Date:</label>
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="saveProvider()">Save</button>
                <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
            </div>
        </ng-template>
        <div id="content" style="height: 100%;">
            <div class="titlebox"><b>Services</b></div>
            <div class="service">
                <table width="100%">
                    <tr style="margin:0px; padding: 0px; font-weight: bold;">
                      <td style="width:100%">
                        <table cellpadding="0" cellspacing="5" style="width:100%">
                          <tr>
                            <td style="width:5%; text-align: center;">
                              <p>DX#</p>
                            </td>
                            <td style="width:10%; text-align: center;">
                                <p>Date of Service:</p>
                            </td>
                            <td style="width:8%; text-align: center;">
                                <p>Code:</p>
                            </td>
                            <td style="width:17%; text-align: center;">
                                <p>Service:</p>
                            </td>
                            <td style="width:8%; text-align: center;">
                                <p>Unit Amt:</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Qty:</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Disc&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>&nbsp;&nbsp; %</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Tax:</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Subtotal:</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Pat Paid:</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Ins Paid:</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                                <p>Balance</p>
                            </td>
                            <td style="width:5%; text-align: center;">
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr class="service changeColor" *ngFor="let service of omr_FeeStatementModel.omR_FeeStatementService;let i=index" style="background-color: lightgrey; border:solid 1px black;border-radius: 2px;">
                      <td width="width:100%">
                        <table cellpadding="0" cellspacing="5" style="width:100%">
                          <tr>
                            <td style="width:5%">
                              <ng-select style="width:100%"  [items]="dxNumLookup"  bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="service.dx_number_txt">
                              </ng-select>
                            </td>
                            <td  style="width:8%">
                                  <input style="width:100%" (ngModelChange)="service.dos_dt = $event.toDateString('MM/dd/yyyy')" [ngModel]="service.dos_dt | date:'MM/dd/yyyy'" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker">
                                  <!-- <input style="width:100%" (ngModelChange)="assignDate($event, service, 'dos_dt')" [ngModel]="service.dos_dt | date:'MM/dd/yyyy'" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"> -->
                            </td>
                            <td style="width:10%">
                              <input style="width:100%" (input)="Servicetransform($event)" type="text" [(ngModel)]="service.code_txt" (click)="hideDropdownList(service,i)">
                              <div *ngIf="service.isShow">
                                <div style="width: 25%; height: 30%;overflow: auto;" class="ag-theme-alpine dropdown">
                                    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="width: 100%; height: 100%;" rowSelection="single" (rowClicked)="chargeRowClicked($event,service,i)" [rowData]="serviceCharge" [rowDragManaged]="true" [columnDefs]="columnCharge">
                                    </ag-grid-angular>
                                    <!-- <span style="cursor: pointer;" class="dropdown" (click)="openProvider(provider)">edit list</span> -->
                                </div>
                                <div>
                                </div>
                              </div>
                            </td>
                            <td style="width:17%"><label style="margin-left: 5px;"><a href="javascript:void(0)" (click)="editOffice('add-charges','Edit charges', false)"><img src="../../../../assets/img/pencil-png.png" height="12px"></a>{{service.service_name_txt}}</label></td>
                            <td style="width:8%"><input style="width:100%" type="number" appTwoDigitDecimaNumber [(ngModel)]="service.unit_amt" step=".02" (blur)="totalForService()" (input)="makeTotalForService(i)"></td>
                            <td style="width:5%"><input style="width:100%" style="width:100%" class="unit_cnt" type="number" appTwoDigitDecimaNumber step=".02" [(ngModel)]="service.unit_cnt" (input)="makeTotalForService(i)"></td>
                            <td style="width:5%"><input style="width:100%" style="width:100%" type="number" appTwoDigitDecimaNumber step=".02" [(ngModel)]="service.discount_amt" (blur)="totalForService()" (input)="makeTotalForService(i,'amt')"></td>
                            <td style="width:5%"><input style="width:100%" type="number" min="0" appTwoDigitDecimaNumber step=".02" [(ngModel)]="service.discount_per_amt" (input)="makeTotalForService(i,'per')"></td>
                            <td style="width:5%"><input style="width:100%" type="number" disabled appTwoDigitDecimaNumber step=".02" [(ngModel)]="service.tax_amt" (input)="makeTotalForService(i)">
                            </td>
                            <td style="width:5%"><input style="width:100%" type="number" disabled appTwoDigitDecimaNumber [(ngModel)]="service.order_val" (input)="makeTotalForService(i)"></td>
                            <td style="width:5%"><input style="width:100%" class="pat_amt" type="number" appTwoDigitDecimaNumber [(ngModel)]="service.pat_total" step=".02"></td>
                            <td style="width:5%"><input style="width:100%" class="ins_amt" type="number" appTwoDigitDecimaNumber [(ngModel)]="service.ins_total" step=".02" (input)="makeTotalForService(i)"></td>
                            <td style="width:5%"><input style="width:100%" class="fee_amt" type="number" step=".02" appTwoDigitDecimaNumber disabled [(ngModel)]="service.fee_amt" (input)="makeTotalForService(i)">
                            </td>
                            <td style="width:5%"><i class="fa fa-close" style="font-size: 24px; color: red;" (click)="deleteService(i)"></i>
                            </td>
                          </tr>
                          <tr>
                            <td style="vertical-align: top !important; margin: 0%; width: 5%">
                              <button class="btn btn-primary ml-3" style="margin-left: 0% !important;" (click)="addPayForService(i)">Add Pay</button>
                            </td>
                            <td colspan="13" width="90%">
                              <table cellpadding="0" cellspacing="0" style="width:100%">
                                <tr *ngFor="let pay of service.omR_FeeStatementPayment;let j=index">
                                  <td style="width:10%">
                                    <input (change)="addPaymentAndChargebacks()" style="width: 90%;" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="pay.payment_dt = $event.toDateString('MM/dd/yyyy')" [ngModel]="pay.payment_dt | date:'MM-dd-yyyy'">
                                  </td>
                                  <td style="width:20%">
                                   <!-- <div class="row grid-model" style="padding: 0;"> -->
                                     <div class="form-row" style="padding: 0;">  <!-- style="margin-left: 10px;" -->
                                       <div class="col-10" style="padding: 0;">
                                        <ng-select  addTagText="Add Item" [addTag]="CreateNew" class="serviceCode"  style="margin-top: 2px;" [items]="paymentTypeLookup" (change)="addPaymentAndChargebacks()"
                                        (keyup)="keyFunc($event,pay,'payment_type_txt')"
                                        (keydown.tab)="saveDropdown(pay.payment_type_txt,'Code',paymentTypeLookup,'feepaymenttype',pay,'payment_type_txt',dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt"
                                        [(ngModel)]="pay.payment_type_txt">
                                        </ng-select>
                                       </div>
                                       <div class="col-2">
                                        <a href="javascript:void(0)" (click)="editLookup(paymentTypeLookup,paymentTypeCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" style="margin-right: -10px;" height="10px"></a>
                                       </div>
                                     </div>
                                   <!-- </div> -->

                                  </td>
                                  <td style="width:45%">
                                    <input type="text" style="width:100%" (blur)="addPaymentAndChargebacks()" class="" [(ngModel)]="pay.description_txt">
                                  </td>
                                  <td colspan="8" style="width:5%">
                                    <input type="number" class="" (blur)="addPaymentAndChargebacks()" appTwoDigitDecimaNumber [(ngModel)]="pay.payment_amt" (input)="makeTotalForService(i)">
                                  </td>
                                  <td style="width:5%">
                                    <i class="fa fa-close" style="font-size:24px;color:red" (click)="deletePayForService(i,j)"></i>
                                  </td>
                              </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                </table>
                <button class="btn btn-primary mt-2" (click)="addService()">Add Service</button>
                <table width="100%" style="border:solid">
                  <tr style="font-size: 12px !important; font-weight: bold !important;">
                      <td style="width:5%;text-align: center;">Totals:</td>
                      <td style="width:10%;text-align: center;"></td>
                      <td style="width:8%;text-align: center;"></td>
                      <td style="width:17%;text-align: center;"></td>
                      <td style="width:13%;text-align: center;font-size: 14px; font-weight: bold;" class="st_unit_amt">${{totalServiceUnitAmount | number:'0.02'}}</td>
                      <td style="width:5%;text-align: center;"></td>
                      <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;" class="st_discount">${{totalServiceDiscount | number:'0.02'}}</td>
                      <td style="width:5%;text-align: center;"></td>
                      <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;" class="st_tax">${{totalServiceTax | number:'0.02'}}</td>
                      <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;" class="st_sub_total">${{totalServiceSubtotal | number:'0.02'}}</td>
                      <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;" class="st_pat_paid">${{totalServicePatPaid | number:'0.02'}}</td>
                      <td style="width:7%;text-align: center;font-size: 14px; font-weight: bold;" class="st_ins_paid">${{totalServiceInsPaid | number:'0.02'}}</td>
                      <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;" class="st_bal">${{totalServiceBalance | number:'1.2-2'}}</td>
                      <td></td>
                  </tr>
                </table>
            </div>
        </div>
        <div id="content" style="height: 100%;">
            <div class="titlebox"><b>Products</b></div>
            <br />
            <table width="100%">
              <tr style="margin:0px; padding: 0px; font-weight: bold;">
                <td style="width:100%">
                  <table cellpadding="0" cellspacing="5" style="width:100%">
                    <tr>
                      <td style="width:5%; text-align: center;">
                        <p>DX#</p>
                      </td>
                      <td style="width:10%; text-align: center;">
                        <p>Date of Service:</p>
                      </td>
                      <td style="width:8%; text-align: center;">
                        <p>Code:</p>
                      </td>
                      <td style="width:17%; text-align: center;">
                        <p>Product:</p>
                      </td>
                      <td style="width:8%; text-align: center;">
                        <p>Unit Amt:</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Qty:</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Disc&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p> &nbsp;&nbsp; %</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Tax:</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Subtotal:</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Pat Paid:</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Ins Paid:</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Balance</p>
                      </td>
                      <td style="width:5%; text-align: center;">
                        <p>Status</p>
                      </td>
                      <td style="width:5%">
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr class="service changeColor" *ngFor="let product of omr_FeeStatementModel.omR_FeeStatementItem;let i=index">
                <td width="width:100%">
                  <table cellpadding="0" cellspacing="5" style="width:100%">
                    <tr>
                      <td style="width:5%">
                        <ng-select  style="width:100%" [items]="dxNumLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="product.dx_number_txt">
                        </ng-select>
                      </td>
                      <td  style="width:8%">
                        <input style="width:100%"  (ngModelChange)="product.dos_dt = $event.toDateString('MM/dd/yyyy')" [ngModel]="product.dos_dt | date:'MM-dd-yyyy'" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker">
                      </td>
                      <td style="width:10%">
                        <input style="width:100%" (input)="Producttransform($event)" type="search" [(ngModel)]="product.code_txt" (click)="hideDropdownList(product,i)">
                        <div *ngIf="product.isShow">
                          <div style="width: 25% ;height: 30%;overflow: auto;" class="ag-theme-alpine dropdown">
                              <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="width: 100%; height: 100%;" [rowStyle]="rowStyle" rowSelection="single" (rowClicked)="chargeRowClickedForProduct($event,product,i)" [rowData]="ProductCharge" [rowDragManaged]="true" [columnDefs]="columnCharge">
                              </ag-grid-angular>
                              <!-- <span style="cursor: pointer;" class="dropdown" (click)="openProvider(provider)">edit list</span> -->
                          </div>
                          <div>
                          </div>
                        </div>

                      </td>
                      <td style="width:17%"><label style="margin-left: 5px;"><a href="javascript:void(0)" (click)="editOffice('add-charges','Edit Product', true)"><img src="../../../../assets/img/pencil-png.png" height="12px"></a>{{product.product_name_txt}}</label></td>
                      <td style="width:8%"><input style="width:100%" type="number" [(ngModel)]="product.unit_amt" appTwoDigitDecimaNumber step=".01" (blur)="totalForProduct()" (input)="makeTotalForProduct(i)"></td>
                      <td style="width:5%"><input style="width:100%" type="number" [(ngModel)]="product.unit_cnt" appTwoDigitDecimaNumber step=".01" (input)="makeTotalForProduct(i)"></td>
                      <td style="width:5%"><input style="width:100%" type="number" [(ngModel)]="product.discount_amt" appTwoDigitDecimaNumber step=".01" (blur)="totalForProduct()" (input)="makeTotalForProduct(i,'amt')"></td>
                      <td style="width:5%"><input style="width:100%" type="number" min="0" [(ngModel)]="product.discount_per_amt" appTwoDigitDecimaNumber step=".01" (input)="makeTotalForProduct(i,'per')"></td>
                      <td style="width:5%"><input style="width:100%;padding:0;" type="number" disabled [(ngModel)]="product.tax_amt" appTwoDigitDecimaNumber step=".01" (input)="makeTotalForProduct(i)"></td>
                      <!-- <td style="width:5%" disabled><label> {{product.tax_amt | number:'0.02'}}</label></td> -->
                      <td style="width:5%"><input style="width:100%;padding:0;" type="number" disabled appTwoDigitDecimaNumber [(ngModel)]="product.order_val" (input)="makeTotalForProduct(i)"></td>
                      <td style="width:5%"><input style="width:100%" type="number" [(ngModel)]="product.pat_total" appTwoDigitDecimaNumber step=".01"></td>
                      <td style="width:5%"><input style="width:100%" type="number" [(ngModel)]="product.ins_total" appTwoDigitDecimaNumber step=".01" (input)="makeTotalForProduct(i)"></td>
                      <td style="width:5%"><input style="width:100%;padding:0;" type="number" step=".02" disabled [(ngModel)]="product.fee_amt" appTwoDigitDecimaNumber (input)="makeTotalForProduct(i)"></td>
                      <td style="width:5%">
                        <select disabled class="ml-2" [(ngModel)]="product.status_txt" style="border-radius: 5px;">
                          <option value="Sold">Sold</option>
                          <option value="Return">Return</option>
                        </select>
                      </td>
                      <td><i class="fa fa-close ml-3" style="font-size: 24px; color: red;" (click)="deleteProduct(i)"></i></td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top !important; width: 5%">
                        <button class="btn btn-primary" style="margin-left: 0% !important;" (click)="addPayForProduct(i)">Add Pay</button>
                      </td>
                      <td colspan="13" width="90%">
                        <table cellpadding="0" cellspacing="0" style="width:100%">
                          <tr *ngFor="let pay of product.omR_FeeStatementPayment;let j=index">
                            <td style="width:10%">
                              <input (ngModelChange)="pay.payment_dt = $event.toDateString('MM/dd/yyyy')" style="width: 90%;" [ngModel]="pay.payment_dt | date:'MM-dd-yyyy'" (change)="addPaymentAndChargebacks()" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker">
                            </td>
                            <td style="width:20%">
                              <!-- <div class="row"> -->
                                <div class="form-row" style="padding: 0;">
                                  <div class="col-10" style="padding: 0;">
                                   <ng-select  addTagText="Add Item" [addTag]="CreateNew"  style="margin-top: 2px;" [items]="paymentTypeLookup"
                                   (change)="addPaymentAndChargebacks()"
                                   (keyup)="keyFunc($event,pay,'payment_type_txt')"
                                   (keydown.tab)="saveDropdown(pay.payment_type_txt,'Products',paymentTypeLookup,'feepaymenttype',pay,'payment_type_txt',dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt"
                                   [(ngModel)]="pay.payment_type_txt">
                                   </ng-select>
                                  </div>
                                  <div class="col-2">
                                   <a href="javascript:void(0)" (click)="editLookup(paymentTypeLookup,paymentTypeCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" style="margin-right: -10px;" height="10px"></a>
                                  </div>
                                </div>
                              <!-- </div> -->
                              <!-- <ng-select  [items]="paymentTypeLookup" (change)="addPaymentAndChargebacks()" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="pay.payment_type_txt">
                              </ng-select> -->
                            </td>
                            <td style="width:45%">
                              <input type="text" [(ngModel)]="pay.description_txt" style="width:100%"  class=""  (blur)="addPaymentAndChargebacks()">
                            </td>
                            <td colspan="8" style="width:5%">
                              <input type="number" (blur)="addPaymentAndChargebacks()" appTwoDigitDecimaNumber [(ngModel)]="pay.payment_amt" (input)="makeTotalForProduct(i)">
                            </td>
                            <td style="width:5%">
                              <i class="fa fa-close" style="font-size:24px;color:red" (click)="deletePayForProduct(i,j)"></i>
                            </td>
                        </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
          </table>
          <button class="btn btn-primary mx-3 mt-2" style="margin-left: 0% !important;" (click)="addProduct('sold')">Add Product</button> &nbsp;&nbsp;
          <button class="btn btn-primary mt-2" (click)="addProduct('return')">Return Product</button>
          <table width="100%" style="border:solid">
            <tr style="font-size: 12px !important; font-weight: bold !important;">
                <td style="width:5%;text-align: center;">Totals:</td>
                <td style="width:10%;text-align: center;"></td>
                <td style="width:8%;text-align: center;"></td>
                <td style="width:17%;text-align: center;"></td>
                <td style="width:10%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductUnitAmount | number:'0.02'}}</td>
                <td style="width:5%;text-align: center;"></td>
                <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductDiscount | number:'0.02'}}</td>
                <td style="width:5%;text-align: center;"></td>
                <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductTax | number:'0.02'}}</td>
                <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">{{totalProductSubtotal | number:'0.02'}}</td>
                <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductPatPaid | number:'0.02'}}</td>
                <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductInsPaid | number:'0.02'}}</td>
                <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductBalance | number:'0.02'}}</td>
                <td></td>
            </tr>
          </table>
        </div>
        <div id="content" style="height: 100%;">
            <div class="titlebox"><b>Payments/Chargebacks</b></div>
            <div class="service">
                <div style="width: auto; height: 100%">
                    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" class="ag-theme-alpine" style="height: 400px;" [rowDragManaged]="true" [columnDefs]="columnPayment" [rowData]="rowPayment">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
        <div id="content" style="height: 100%;">
            <div class="titlebox"><b>Diagnosis</b></div>
            <div class="service" *ngIf="isExamTab">
                <div class="row">
                    <div class="col-md-1 col-sm-2">
                        <b>DX#</b>
                    <!-- &nbsp; <button class="btn btn-primary mt-2" (click)="addDiagnosis()">Add Diagnosis</button> -->
                    </div>
                </div>
                <div>
                  <div class="row pt-2" *ngFor="let assesment of examModel.exam_Assessments;let i = index;">
                    <div class="col-md-6 pt-2">
                        <div class="form-row">
                            <div class="col-md-1">
                                <label class="pt-1"><b>#</b>{{(i+1 + 9).toString(36).toUpperCase()}}</label>
                            </div>
                            <div class="col-md-11">
                                <div class="form-row">
                                    <div class="col-md-10">
                                        <div class="form-row">
                                            <ng-select  class="col-md-10" [items]="assessmentLookup"  bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="assesment.assessment">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="service" *ngIf="!isExamTab">
              <div class="row">
                <div class="col-6">
                  <div class="titlebox"></div>
                  <b>Assessment:</b>
                  <button class="btn btn-primary mx-2 px-4" (click)="addAssessment()"><b>Add</b></button>
                </div>
              </div>
              <div class="row pt-2" *ngFor="let assesment of exam_Assessments;let i = index;">
                <div class="col-md-6 pt-2">
                  <div class="form-row">
                    <div class="col-md-1">
                      <label class="pt-1"><b>#</b>{{(i+1 + 9).toString(36).toUpperCase()}}</label>
                    </div>
                    <div class="col-md-11">
                      <div class="form-row">
                        <div class="col-md-10">
                          <div class="form-row">
                            <ng-select [clearable]="true" (keyup)="keyFunc($event,assesment,'assessment')" addTagText="Add New"
                              [addTag]="CreateNew" class="col-md-10" [items]="assessmentLookup"
                              bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="assesment.assessment">
                            </ng-select>
                            <!-- (keydown.tab)="saveDropdown(assesment.assessment,'#',assessment,'assessment',assesment,'assessment',dbEnum.QE_DEV_SQL_DB)" -->
                            <!-- <a href="javascript:void(0)" class="col-md-2"
                              (click)="editLookup(assessment,assessmentCopy,dbEnum.QE_DEV_SQL_DB)"><img
                                src="../../../../assets/img/pencil-png.png" height="10px"></a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div class="row mx-2">
            <div class="col-md-1 col-sm-4">
                <div class="form-group">
                    <label>Messages:</label>

                </div>
            </div>


            <div class="col-md-5 col-sm-8">
              <table style="width:100%">
                <tr>
                    <td style="width:95%">
                      <ng-select addTagText="Add Item" [addTag]="CreateNew"  style="height:70% !important;"
                      [(ngModel)]="omr_FeeStatementModel.notes_txt"  [items]="RemarksCopyLookup"
                      (keyup)="keyFunc($event,omr_FeeStatementModel,'notes_txt')"
                      (keydown.tab)="saveDropdown(omr_FeeStatementModel.notes_txt,'Messages',RemarksCopyLookup,'Messages',omr_FeeStatementModel,'notes_txtt')" bindLabel="name_txt"  bindValue="name_txt">
                      </ng-select>
                    </td>
                    <td style="width:5%">
                      <a href="javascript:void(0)" (click)="editLookup(RemarksCopyLookup,RemarksCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </td>
                </tr>
            </table>
              </div>

            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-8">
                        <div class="col-md-12">
                            <ng-select [(ngModel)]="omr_FeeStatementModel.signing_provider_id" placeholder="--- Select ---" #myUserSelect (change)="onSelectUser(omr_FeeStatementModel.signing_provider_id)">
                                <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                                    {{userInfo.display_txt}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="col-md-12">
                          Doctor Name : <label>{{user.display_txt}}</label>
                        </div>

                        <div class="col-md-12">
                            <img [src]="imgURL" height="120px" *ngIf="imgURL">
                        </div>
                        <div class="col-md-1 col-sm-4">
                            License : <label>{{user.license_txt}}</label>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label style="margin-left: 9px;">Total Charges:</label> ${{totalUnitAmount | number:'0.02'}}
                        </div>
                        <div>
                            <label>Total Discounts:</label> ${{totalDiscount | number:'0.02'}}
                        </div>
                        <div>
                            <label style="margin-left: 26px;">Total Taxes:</label> ${{totalTax | number:'0.02'}}
                        </div>
                        <div>
                            <label>Total Payments:</label> ${{(totalPatpaid + this.totalInsPaid) | number:'0.02'}}
                        </div>
                        <div>
                            <label style="margin-left: 47px;">Balance:</label> ${{totalBalance | number:'0.02'}}
                        </div>
                        <div>
                            <label><input type="radio" [(ngModel)]="omr_FeeStatementModel.responsible_txt" [checked]="omr_FeeStatementModel.responsible_txt == 'Patient'" class="mx-2" [value]="'Patient'"> Patient</label>
                            <label><input type="radio" [(ngModel)]="omr_FeeStatementModel.responsible_txt" [checked]="omr_FeeStatementModel.responsible_txt == 'Insurance'" class="mx-2" [value]="'Insurance'">Insurance</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
              <p style="font-size: 9px;">
                CPT® copyright 2023 American Medical Association. All rights reserved.
              </p>
            </div>
        </div>
    </fieldset>


    <fieldset class="misc" id="print-section" hidden style="height: 500px !important;">
      <!-- <div class="container" > -->
        <div class="col-12 text-center">
          <span class="doc-info" *ngIf="officename"> {{officename}}</span>
      </div>
      <div class="col-12 text-center">
          <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
      </div>
      <div class="col-12 text-center">
          <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
      </div>
      <div class="col-12 text-center">
          <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
      </div>
      <div class="col-12 text-center">
          <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
      </div>
      <div class="col-12 text-center">
          <span class="doc-info">NPI # {{office_npi_txt}}</span>
      </div>

      <!-- <div class="col-12 text-center doc-info">
          <span class="doc-info">NPI # {{License}}</span>
      </div> -->
        <br>
        <h4 class="text-center"><b>Fee Statement</b></h4><br>

      <div class="row col-12">
        <div class="col-6">
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pr-0">
                    <label class="patient-info">Date:</label>
                </div>
                <div class="col-5 pl-0">
                    <div class="patient-reg-info">{{currentDate | date}}</div>
                </div>
            </div>
            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pr-0">
                    <label class="patient-info">Patient Name:</label>
                </div>
                <div class="col-5 pl-0">
                    <div class="patient-reg-info">{{patientDetail.firstName}} {{patientDetail.lastName}}</div>
                </div>
            </div>

            <div class="row">
              <div class="col-4">
              </div>
              <div class="col-3 pr-0">
                    <label class="patient-info">Birthdate:</label>
                </div>
                <div class="col-5 pl-0">
                    <div class="patient-reg-info">{{patientDetail.dateOfBirth | date:'MM-dd-yyyy'}}</div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
              <div class="col-2">
              </div>
              <div class="col-3 pr-0 pl-0">
                    <label class="patient-info">Date of Service:</label>
                </div>
                <div class="col-7 pl-0">
                    <div class="patient-reg-info">{{examModel.exam_Date | date:'MM-dd-yyyy'}}</div>
                </div>
            </div>
            <div class="row">
              <div class="col-2">
              </div>
              <div class="col-3 pr-0 pl-0">
                    <label class="patient-info">Insurance:</label>
                </div>
                <div class="col-7 pl-0">
                    <div class="patient-reg-info">{{omr_FeeStatementModel.insurance_display_txt}}</div>
                </div>
            </div>
            <div class="row">
              <div class="col-2">
              </div>
              <div class="col-3 pr-0 pl-0">
                    <label class="patient-info">Tax ID:</label>
                </div>
                <div class="col-7 pl-0">
                    <div class="patient-reg-info">{{user1?.federal_tax_id_type_txt}}</div>
                </div>
            </div>
        </div>
    </div>

      <!-- </div> -->
      <div class="col-12" style="border: 1px solid black;"></div>
      <ng-template #provider let-modal>
          <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Provider</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="col-10">
                  <div class="form-group">
                      <label>Type:</label>
                      <select class="formcontrol form-control">
                          <option value="PT">PT Req</option>
                      </select>
                  </div>
              </div>
              <div class="col-10">
                  <div class="form-group">
                      <label>Note:</label>
                      <textarea class="formcontrol form-control">
          </textarea>
                  </div>
              </div>
              <div class="row">
                  <div class="col-4 col-sm-6" style="margin: 10px;">
                      <div class="form-group">
                          <label>
                              <input type="checkbox" style="margin: 3px;">Active
                          </label>
                      </div>
                  </div>
                  <div class="col-6 col-sm-6" style="margin-right: 2px;">
                      <div class="form-group">
                          <label>Expiration Date:</label>
                          <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="formcontrol form-control">
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-outline-dark" (click)="saveProvider()">Save</button>
              <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Close</button>
          </div>
      </ng-template>

      <div id="content">
        <div class="titlebox"><b>Clinical Services</b></div><br>
        <table border="1" style="width:100%;">
          <thead>
          <tr>
            <th class="heading-background">DX#</th>
            <th class="heading-background">Date of Service:</th>
            <th class="heading-background">Code:</th>
            <th class="heading-background">Service:</th>
            <th class="heading-background">Unit Amt:</th>
            <th class="heading-background">Qty:</th>
            <th class="heading-background">Disc&nbsp;&nbsp;/</th>
            <th class="heading-background">%</th>
            <th class="heading-background">Tax:</th>
            <th class="heading-background">Subtotal:</th>
            <th class="heading-background">Pat Paid:</th>
            <th class="heading-background">Ins Paid:</th>
            <th class="heading-background">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr class="service changeColor" *ngFor="let service of omr_FeeStatementModel.omR_FeeStatementService;let i=index">
            <td>{{service.dx_number_txt}}</td>
            <td>{{service.dos_dt | date:'MM-dd-yyyy'}}</td>
            <td>{{service.code_txt}}</td>
            <td>{{service.service_name_txt}}</td>
            <td>${{service.unit_amt | number:'0.02'}}</td>
            <td>{{service.unit_cnt}}</td>
            <td>${{service.discount_amt | number:'0.02'}}</td>
            <td>{{service.discount_per_amt}}</td>
            <td>${{service.tax_amt | number:'0.02'}}</td>
            <td>${{service.order_val | number:'0.02'}}</td>
            <td>${{service.pat_total | number:'0.02'}}</td>
            <td>${{service.ins_total | number:'0.02'}}</td>
            <td>${{service.fee_amt | number:'0.02'}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="service changeColor" style="border: none;border-bottom: 1px solid;border-left: 1px solid;border-right: 1px solid;">
            <td class=" font-weight-bold">Totals:</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="st_unit_amt font-weight-bold">${{totalServiceUnitAmount | number:'0.02'}}</td>
            <td></td>
            <td class="st_discount font-weight-bold">${{totalServiceDiscount | number:'0.02'}}</td>
            <td></td>
            <td class="st_tax font-weight-bold">${{totalServiceTax | number:'0.02'}}</td>
            <td class="st_sub_total font-weight-bold">${{totalServiceSubtotal | number:'0.02'}}</td>
            <td class="st_pat_paid font-weight-bold">${{totalServicePatPaid | number:'0.02'}}</td>
            <td class="st_ins_paid font-weight-bold">${{totalServiceInsPaid | number:'0.02'}}</td>
            <td class="st_bal font-weight-bold">${{totalServiceBalance | number:'1.2-2'}}</td>
          </tr>
        </tfoot>
        </table>
        <!-- <table width="100%" style="border: 1px solid; border-top: none;">
          <tr style="font-size: 12px !important; font-weight: bold !important;">
              <td style="width:5%;">Totals:</td>
              <td style="width:10%;text-align: center;"></td>
              <td style="width:8%;text-align: center;"></td>
              <td style="width:15%;text-align: center;"></td>
              <td style="width:13%;font-size: 14px; font-weight: bold;" class="st_unit_amt">${{totalServiceUnitAmount | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;"></td>
              <td style="width:5%;font-size: 14px; font-weight: bold;" class="st_discount">${{totalServiceDiscount | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;"></td>
              <td style="width:5%;font-size: 14px; font-weight: bold;" class="st_tax">${{totalServiceTax | number:'0.02'}}</td>
              <td style="width:5%;font-size: 14px; font-weight: bold;" class="st_sub_total">${{totalServiceSubtotal | number:'0.02'}}</td>
              <td style="width:5%;font-size: 14px; font-weight: bold;" class="st_pat_paid">${{totalServicePatPaid | number:'0.02'}}</td>
              <td style="width:7%;font-size: 14px; font-weight: bold;" class="st_ins_paid">${{totalServiceInsPaid | number:'0.02'}}</td>
              <td style="width:5%;font-size: 14px; font-weight: bold;" class="st_bal">${{totalServiceBalance | number:'1.2-2'}}</td>
          </tr>
        </table> -->
      </div>
<b><hr></b>
      <div id="content">
        <div class="titlebox"><b>Optical Products/Services</b></div><br>
        <table border="1" style="width:100%;">
          <thead>
            <tr>
              <th class="heading-background">DX#</th>
              <th class="heading-background">Date of Service:</th>
              <th class="heading-background">Code:</th>
              <th class="heading-background">Product:</th>
              <th class="heading-background">Unit Amt:</th>
              <th class="heading-background">Qty:</th>
              <th class="heading-background">Disc&nbsp;&nbsp;/</th>
              <th class="heading-background">%</th>
              <th class="heading-background">Tax:</th>
              <th class="heading-background">Subtotal:</th>
              <th class="heading-background">Pat Paid:</th>
              <th class="heading-background">Ins Paid:</th>
              <th class="heading-background">Balance</th>
              <th class="heading-background">Status</th>
            </tr>
        </thead>
        <tbody>
          <tr class="product" *ngFor="let product of omr_FeeStatementModel.omR_FeeStatementItem;let i=index">
            <td>{{product.dx_number_txt}}</td>
            <td>{{product.dos_dt | date:'MM-dd-yyyy'}}</td>
            <td>{{product.code_txt}}</td>
            <td>{{product.product_name_txt}}</td>
            <td>${{product.unit_amt | number:'0.02'}}</td>
            <td>{{product.unit_cnt}}</td>
            <td>${{product.discount_amt | number:'0.02'}}</td>
            <td>{{product.discount_per_amt}}</td>
            <td>${{product.tax_amt | number:'0.02'}}</td>
            <td>${{product.order_val | number:'0.02'}}</td>
            <td>${{product.pat_total | number:'0.02'}}</td>
            <td>${{product.ins_total | number:'0.02'}}</td>
            <td>${{product.fee_amt | number:'0.02'}}</td>
            <td>{{product.status_txt}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="service changeColor" style="border: none;border-bottom: 1px solid;border-left: 1px solid;border-right: 1px solid;">
            <td class="font-weight-bold">Totals:</td>
            <td></td>
            <td></td>
            <td></td>
            <td class="font-weight-bold">${{totalProductUnitAmount | number:'0.02'}}</td>
            <td></td>
            <td class="font-weight-bold">${{totalProductDiscount | number:'0.02'}}</td>
            <td></td>
            <td class="font-weight-bold">${{totalProductTax | number:'0.02'}}</td>
            <td class="font-weight-bold">${{totalProductSubtotal | number:'0.02'}}</td>
            <td class="font-weight-bold">${{totalProductPatPaid | number:'0.02'}}</td>
            <td class="font-weight-bold">${{totalProductInsPaid | number:'0.02'}}</td>
            <td class="font-weight-bold">${{totalProductBalance | number:'0.02'}}</td>
            <td></td>
          </tr>
        </tfoot>

        </table>
        <!-- <table width="100%" style="border:solid">
          <tr style="font-size: 12px !important; font-weight: bold !important;">
              <td style="width:5%;text-align: center;">Totals:</td>
              <td style="width:10%;text-align: center;"></td>
              <td style="width:8%;text-align: center;"></td>
              <td style="width:17%;text-align: center;"></td>
              <td style="width:10%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductUnitAmount | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;"></td>
              <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductDiscount | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;"></td>
              <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductTax | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">{{totalProductSubtotal | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductPatPaid | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductInsPaid | number:'0.02'}}</td>
              <td style="width:5%;text-align: center;font-size: 14px; font-weight: bold;">${{totalProductBalance | number:'0.02'}}</td>
          </tr>
        </table> -->
      </div>
      <b><hr></b>
      <div id="content">
        <div class="titlebox"><b>Payments/Chargebacks</b></div><br>
        <table border="1" style="width:100%;">
          <tr>
            <th class="heading-background">Payment Date</th>
            <th class="heading-background">Payment Via</th>
            <th class="heading-background">Description</th>
            <th class="heading-background">Amount</th>
          </tr>
          <!-- <tr class="Payment" *ngFor="let Payment of omr_FeeStatementModel.omR_FeeStatementPayment; let i=index"> -->
          <tr class="Payment" *ngFor="let Payment of rowPayment;">
            <td>{{Payment.payment_dt| date:'MM-dd-yyyy'}}</td>
            <td>{{Payment.payment_type_txt}}
            </td>
            <td>{{Payment.description_txt}}</td>
            <td>${{Payment.payment_amt | number:'0.02'}}</td>
          </tr>

        </table>
      </div>
      <b><hr></b>

      <div id="content">
          <div class="titlebox"><b>Diagnosis</b></div><br>
          <div class="service" *ngIf="isExamTab">

            <table border="1" style="width:100%;">
              <tr>
                <th class="heading-background">DX#</th>
                <th class="heading-background">Assessment List</th>
              </tr>
              <tr class="assesment" *ngFor="let assesment of examModel.exam_Assessments; let i=index">
                <td>
                  <b>#</b>{{(i+1 + 9).toString(36).toUpperCase()}}
                </td>
                <td>{{assesment.assessment}}</td>
              </tr>

            </table>

          </div>
          <table border="1" style="width:100%;">
           <tr>
            <th style="width:10% ;" class="heading-background"><label>Message</label></th>
            <th>{{omr_FeeStatementModel.notes_txt}}</th>
          </tr>
          </table>
      </div>
      <b><hr></b>
      <div id="content">
        <div class="titlebox"><b>Totals</b></div>
        <table style="width:100%;">
          <tr>
            <th><u>Total Fees:</u>></th>
            <th><u>Total Discount:</u>></th>
            <th><u>Tax:</u>></th>
            <th><u>Sub Total:</u>></th>
            <th border="1" ><u>Amt Pd by Pat:</u>></th>
            <th border="1" ><u>Amt Pd by Ins:</u>></th>
            <th border="1" ><u>Account Balance:</u>></th>
          </tr>
          <tr>
            <td>${{totalUnitAmount | number:'0.02'}}</td>
            <td>${{totalDiscount | number:'0.02'}}</td>
            <td>${{totalTax | number:'0.02'}}</td>
            <td>${{totalSubTotal | number:'0.02'}}</td>
            <td>${{totalPatpaid | number:'0.02'}}</td>
            <td>${{totalInsPaid | number:'0.02'}}</td>
            <td>${{totalBalance | number:'0.02'}}</td>
          </tr>
        </table>
      </div>
      <b><hr></b>
      <div class="row col-12">
        <div class="col-6">
          <div class="row">
            <div class="col-6"><b>Doctor's Signature:</b></div>
            <!-- <div class="col-8"> <img [src]="imgURL" height="120px" *ngIf="imgURL"></div> -->
            <!-- <div class="col-2"><b>Doctor Name :</b></div>
              <div class="col-4"> {{user.display_txt}}</div>         -->
          </div>
          <div class="row">
            <div class="col-8"> <img [src]="imgURL" height="120px" *ngIf="imgURL"></div>
            <!-- <div class="col-2"><b>Doctor Name :</b></div>
              <div class="col-4"> {{user.display_txt}}</div>         -->
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-4"><b>Doctor Name :</b></div>
            <div class="col-8"> {{user.display_txt}}</div>
            <!-- <div class="col-2"><b>License :</b></div>
              <div class="col-4">{{License}}</div> -->
          </div>
          <div class="row">
            <!-- <div class="col-2"><b>Doctor Name :</b></div>
              <div class="col-4"> {{user.display_txt}}</div>       -->
            <div class="col-4"><b>License #:</b></div>
            <div class="col-8">{{user.license_txt}}</div>
          </div>
        </div>
      </div>
  </fieldset>
