import { AuthenticateRequestModel } from './../model/authenticateRequestModel';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  login(authenticateRequestModel : AuthenticateRequestModel): Observable<any> {
    return this.http.post<any>(environment.server + 'api/user/authenticate', authenticateRequestModel);
  }
  authenticateUserName(authenticateRequestModel : AuthenticateRequestModel): Observable<any> {
    return this.http.post<any>(environment.server + 'api/user/authenticateusername', authenticateRequestModel);
  }
  getToken() {
    return localStorage.getItem('token')
  }
}
