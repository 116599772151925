<div class="modal-content" [ngStyle]="{'height': Heigth, 'width': width}">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
            <span aria-hidden="true" style="font-size:40px !important;">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="componentName == 'General'">
            <app-general [isManageFacilities]="true"></app-general>
        </div>
        <div *ngIf="componentName == 'Provider'">
            <app-providers></app-providers>
        </div>
        <div *ngIf="componentName == 'Insurance'">
            <app-insurances style="height:100%;"></app-insurances>
        </div>
        <div *ngIf="componentName == 'Manufacture'">
            <app-manufacture></app-manufacture>
        </div>
        <div *ngIf="componentName == 'add-charges'" class="col-md-12">
            <app-add-charges [isProductModel]="isProductModel"></app-add-charges>
        </div>
        <div *ngIf="componentName == 'add-product'" class="col-md-12">
            <app-add-product></app-add-product>
        </div>
        <div *ngIf="componentName == 'add-identifier'">
            <app-add-identifier></app-add-identifier>
        </div>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary px-4 mx-2" *ngIf="isShowSaveButton" (click)="save()">Save</button> -->
        <button type="button" class="btn btn-primary px-4 " (click)="close()">Close</button>
    </div>
</div>
