import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ManufacturerModel } from 'src/app/model/ManufacturerModel';
import { ManufacturerService } from 'src/app/services/Manufacturer.service';

@Component({
  selector: 'app-manufacture',
  templateUrl: './manufacture.component.html',
  styleUrls: ['./manufacture.component.scss']
})
export class ManufactureComponent implements OnInit {

  manufactureFormGroup!: FormGroup;
  showButton = false;
  enablEditButton = false;
  constructor(private formBuilder: FormBuilder, private toaster: ToastrService, private _manufactureService: ManufacturerService) { }
  manufactureModel: ManufacturerModel = new ManufacturerModel();
  manufactureList: ManufacturerModel[] = [];
  manufacture: ManufacturerModel[] = [];
  showInactive : boolean = false;
  setManufacture:string='';

  type = [{
    "name": "CONTACT"
  }, {
    "name": "SPECTACLE"
  }, {
    "name": "OTHER"
  }, {
    "name": "ALL"
  }]
  ngOnInit() {
    this.createFormGroup();
    this.getAllManufacture();
    this.manufactureFormGroup.disable();
  }

  createFormGroup() {
    this.manufactureFormGroup = this.formBuilder.group({
      manufacturer_id: [''],
      name_txt: ['', Validators.required],
      type_txt: [''],
      account_txt: [''],
      phone_txt: [''],
      active_ind: [false],
      created_dt: [new Date()],
      created_by: [''],
      updated_dt: [new Date()],
      updated_by: [''],
      address_txt: ['']
    })
  }

  create() {
    this.showButton = true;
  }

  cancel() {
    this.showButton = false;
    if (this.manufactureModel.manufacturer_id == '') {
      this.manufactureFormGroup.reset();
    }
    this.manufactureFormGroup.disable();
  }

  edit() {
    this.showButton = true;
    this.manufactureFormGroup.enable();
  }

  getAllManufacture() {
    this._manufactureService.getAll().subscribe(res=>{
      this.manufacture = res;
      this.filterManufacture(false);
      this.manufactureList = this.compare(this.manufactureList);
    })
  }

  setForm(manufacture: ManufacturerModel) {
    this.manufactureModel = Object.assign({}, manufacture)
    this.manufactureModel.type_txt=this.manufactureModel.type_txt;
    this.enablEditButton = true;
    this.setManufacture=this.manufactureModel.manufacturer_id;
  }

  addNew() {
    this.setManufacture = '';
    this.manufactureFormGroup.enable();
    this.manufactureFormGroup.reset();
    this.manufactureModel = new ManufacturerModel();
    this.showButton = true;
  }

  save() {
    if (this.manufactureModel.manufacturer_id == '') {
      if (this.manufactureFormGroup.valid) {
        this._manufactureService.insertManufacturer(this.manufactureModel).subscribe(res => {
          this.getAllManufacture();
          this.showButton = false;
          this.enablEditButton = false;
          this.manufactureFormGroup.disable()
        }, (error) => {
          this.toaster.error(error.error);
        });
      }

    }
    else {
      if (this.manufactureFormGroup.valid) {
        this._manufactureService.updateManufacturer(this.manufactureModel).subscribe(res => {
          this.getAllManufacture();
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
      }
      this.showButton = false;
      }
  }

  filter(){
    if(this.showInactive){
      this.manufactureList = this.manufacture.filter(x=>x.active_ind == !this.showInactive);
    }
    else{
      this.manufactureList = this.manufacture;
    }
  }

  filterManufacture(active:boolean){
    this.showInactive = active;
    if(!this.showInactive)
    {
      this.manufactureList = this.manufacture.filter(x => x.active_ind == true);
    } else {
      this.manufactureList = this.manufacture.filter(x => x.active_ind == false);
    }
    this.manufactureList = this.compare(this.manufactureList);
  }

  compare(array: ManufacturerModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }

}
