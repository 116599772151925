<!-- <div>
  <form [formGroup]="providersFormGroup">
    <div>
      <input type="text" formControlName="id" hidden>
    </div>
    <div class="row">
      <div class="col-md-10 col-sm-10">
        <div class="form-group">
          <label>Display Name:</label>
          <input type="text" formControlName="displayName" class="form-control">
        </div>
      </div>
      <div class="col-md-2 col-sm-2">
        <div class="form-group">
          <div> </div>
          <input type="checkbox" formControlName="status">
          <label>Active</label>
        </div>
      </div>
    </div>
  </form>
</div> -->
<div class="row" style="height:700px;">
  <div class="col-md-4">
    <!-- <label>Selected Manufacturer:</label>
        <button class="btn btn-primary" (click)="addNew()">Add New</button>
        <br> -->
    <div class="col-md-12 pb-1">
      <div class="row">
        <div class="col-md-3 p-0">
          <label style="font-size: 16px !important">Selected Manufacturer:</label>
        </div>
        <div class="col-md-9 justify-content-right">
          <button class="btn btn-primary mx-2" style="margin-right:-15px !important;" (click)="addNew()">Add
            New</button>
        </div>
      </div>
    </div>
    <div id="content" [ngClass]="{'disableDiv': showButton}"
      style="height: 580px; overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black;">
      <div class="titlebox pt-1"></div>
      <!-- style="height:580px; width: 200px;"-->
      <!-- <div class="scroll">  -->
      <div class="row mx-2" *ngFor="let manufacture of manufactureList">
        <div [ngStyle]="{'background-color': setManufacture == manufacture.manufacturer_id ? '#a9d2fd' : 'white'}"
          class="col-md-12">
          <label style="cursor: pointer;" class="text-center"
            (click)="setForm(manufacture)">{{manufacture.name_txt}}</label>
        </div>
      </div>
      <!-- </div> -->

      <!-- <table class="table" *ngFor="let provider of providerList">
              <tr>
                  <td style="cursor: pointer;" class="text-center" (click)="setForm(provider)">{{provider.display_txt}}</td>
              </tr>
          </table> -->
    </div>
    <div>
      <label><input type="checkbox" class="mx-2" #showInactiveCheckbox id="showInactiveCheckbox"
          (change)="filterManufacture(showInactiveCheckbox.checked)" [(ngModel)]="showInactive">Show Inactive</label>
    </div>
    <!-- <div class="form-row pt-4">
          <label class="pt-1">Provider Type:</label>
          <select class="form-control col-md-6 mx-2">
          <option>All</option>
          <option>Billing</option>
          <option>Rendering</option>
          <option>Referring</option>
        </select>
      </div> -->
  </div>
  <div class="col-md-8 pl-0 pt-2">
    <div>
      <label
        style="font-size: 20px !important; text-align:center !important; margin-left: 200px !important;">Manufacturer
        Information:</label>
    </div>
    <!-- <label>Manufacturer Information:</label> -->
    <div id="content" style="height: 580px;">
      <div class="titlebox"></div>
      <form [formGroup]="manufactureFormGroup">
        <div class="form-group">
          <label>Type:</label>
          <ng-select [items]="type" bindLabel="name" formControlName="type_txt" [(ngModel)]="manufactureModel.type_txt"
            bindValue="name">
          </ng-select>
        </div>
        <div class="form-group">
          <label>Name:</label>
          <input type="text" class="form-control" formControlName="name_txt" [(ngModel)]="manufactureModel.name_txt">
        </div>
        <div class="form-group">
          <label>Account #:</label>
          <input type="text" class="form-control" formControlName="account_txt"
            [(ngModel)]="manufactureModel.account_txt">
        </div>
        <div class="form-group">
          <label>Phone:</label>
          <input type="text" class="form-control" formControlName="phone_txt" [(ngModel)]="manufactureModel.phone_txt">
        </div>
        <div>
          <label>Active:<input type="checkbox" formControlName="active_ind"
              [(ngModel)]="manufactureModel.active_ind"></label>
        </div>
        <div class="form-group">
          <label>Note:</label>
          <textarea class="form-control" formControlName="address_txt" [(ngModel)]="manufactureModel.address_txt">
        </textarea>
        </div>
        <div class="pt-2 col-md-12 text-center">
          <button class="btn btn-primary px-4" (click)="edit()" *ngIf="!showButton"
            [disabled]="!enablEditButton">Edit</button>
          <button class="btn btn-primary px-4 mx-2" [disabled]="manufactureFormGroup.invalid" *ngIf="showButton"
            (click)="save()">Save</button>
          <button class="btn btn-primary px-4" (click)="cancel()" *ngIf="showButton">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
