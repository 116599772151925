import { stringify } from '@angular/compiler/src/util';
export class OMR_FeeStatementPaymentModel {
    public fee_statement_payment_id: string = '';
    public fee_statement_item_id: string = '';
    public fee_statement_service_id: string = '';
    public fee_statement_id: string = '';
    public patient_id: string = '';
    public payment_dt: string = '';
    public payment_type_txt: string = '';
    public payment_method_txt: string = '';
    public description_txt: string = '';
    public payment_amt: string = '';
    public created_dt: string = '';
    public created_by: string = '';
    public updated_dt: string = '';
    public updated_by: string = '';
    public order_val: Number = 0;
    public isShow : boolean = false;
 }
