<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
    <div class="form-group">
        <label>Name/Code</label>
        <input class="form-control" [(ngModel)]="lookupModel.name_txt">
    </div>
    <div class="form-group">
        <label>Group</label>
        <input class="form-control" [(ngModel)]="lookupModel.group_txt" disabled>
    </div>
    <div class="form-group">
        <label>Sub Group</label>
        <!-- <select [(ngModel)]="lookupModel.sub_group_txt" class="form-control">
          <option>ALL</option>
        </select> -->
        <ng-select [clearable]="true" [items]="subGroupArray" bindLabel="sub_group_txt" bindValue="sub_group_txt" [(ngModel)]="lookupModel.sub_group_txt">
        </ng-select>
    </div>
    <div class="form-group">
        <label>Description</label>
        <textarea class="form-control" [(ngModel)]="lookupModel.description_txt"></textarea>
    </div>
    <div>
        <label>Active<input type="checkbox" class="mx-2" [(ngModel)]="lookupModel.active_ind"></label>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="save()">Ok</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
</div>