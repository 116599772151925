import { element } from 'protractor';
import { EditLookupComponent } from './../edit-lookup/edit-lookup.component';
import { LookupModel } from './../../model/lookupModel';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CellClickedEvent, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-lookup-modal',
  templateUrl: './lookup-modal.component.html',
  styleUrls: ['./lookup-modal.component.scss']
})
export class LookupModalComponent implements OnInit,OnDestroy {
  componentName: string = '';
  title: string = '';
  item: LookupModel[] = [];
  resource : LookupModel[] = [];
  mainItem: LookupModel[] = [];
  columnLookup: any;
  dbType: number = 1;
  public event: EventEmitter<any> = new EventEmitter();
  lookupGridApi !: GridApi;
  isShowInactive: boolean = true;
  bsModalRef1!: BsModalRef;
  subGroupArray = [];
  isEdited: boolean = false;
  constructor(private router: Router,public bsModalRef: BsModalRef, private modalService: BsModalService) { }

  ngOnInit() {    
    
    this.columnLookup = [
      {
        headerName: 'Name',
        field: 'name_txt', sortable: true, resizable: true, rowDrag: true
      },
      {
        headerName: 'Active',
        field: 'active_ind', sortable: true, filter: true, resizable: true,
        cellRenderer: (params: any) => {
          return `<input type='checkbox' ${params.value ? 'checked' : ''} disabled />`;
        }
      },
      {
        headerName: '',
        field: '', sortable: true, filter: true, resizable: true,
        onCellClicked: this.onEdit.bind(this),
        cellRenderer: (params: any) => {
          return '<a href="javascript:void(0)">Edit</a>';
        }
      },
      {
        headerName: '',
        field: '', sortable: true, filter: true, resizable: true,
        onCellClicked: this.onFirst.bind(this),
        cellRenderer: (params: any) => {
          return '<a href="javascript:void(0)">First</a>';
        }
      },
      {
        headerName: '',
        field: '', sortable: true, filter: true, resizable: true,
        onCellClicked: this.onUp.bind(this),
        cellRenderer: (params: any) => {
          return '<a href="javascript:void(0)">Up</a>';
        }
      },
      {
        headerName: '',
        field: '', sortable: true, filter: true, resizable: true,
        onCellClicked: this.onDown.bind(this),
        cellRenderer: (params: any) => {
          return '<a href="javascript:void(0)">Down</a>';
        }
      },
      {
        headerName: '',
        field: '', sortable: true, filter: true, resizable: true,
        onCellClicked: this.onLast.bind(this),
        cellRenderer: (params: any) => {
          return '<a href="javascript:void(0)">Last</a>';
        }
      },
    ];

    this.item.forEach((element, index) => {
      element.index = index + 1;
    })

    this.subGroupArray = Object.assign([],[...new Map(this.item.map(item =>
      [item['sub_group_txt'], item])).values()])
    this.filter();

  }
   Addlookup()    {
    
    const initialState = {     
      title: 'Add Type',
      group_txt:"note_type",
      dbType: this.dbType,
      subGroupArray : this.subGroupArray
    };

    
    this.bsModalRef1 = this.modalService.show(EditLookupComponent, { initialState });
    // this.bsModalRef.setClass('modal-lg')
    this.bsModalRef1.content.closeBtnName = 'Cancel';    
      // this.lookupGridApi.setRowData(this.lookup)
    
  }
 // {
  //   // localStorage.clear()
  //   this.bsModalRef1 = this.modalService.show(EditLookupComponent);
  //   // this.bsModalRef.setClass('modal-lg')
  //   this.bsModalRef1.content.closeBtnName = 'Cancel';
     
  // }
  

  filter(){
    this.mainItem.length = 0;

    // if(this.isShowInactive){
    //   this.resource = this.item.filter(x => x.active_ind == true )
    //   this.item.forEach(element=>{       
    //     this.mainItem.push(element)
    //   })
    // }
    // else{   
    //   this.resource = this.item.filter((x=>x.active_ind != this.isShowInactive));
    //   this.resource.forEach(element=>{       
    //     this.mainItem.push(element)
    //   })
     
    // }

    if (this.isShowInactive) {
      // this.mainItem = Object.assign([], this.item);
      this.item.forEach(element=>{         
        this.mainItem.push(element)
      })
    }
    else {
      // this.item.filter(x => x.active_ind == true).forEach(element=>{
      //   this.mainItem.push(element)
      
      // })     
        this.item = this.item.filter(x => x.active_ind == true )
        this.item.forEach(element=>{       
          this.mainItem.push(element)
        })
    } 
  }

  save() {    
    
    this.triggerEvent(this.mainItem);
    
    // this.toaster.success('Record Saved Succesfully.');
  }

  onFirst(params:any) {
    if (this.mainItem.length > 1) {
      this.mainItem.forEach(element => {
        if (element.index < params.data.index) {
          element.index = element.index + 1
        }
        else if (element.index == params.data.index) {
          element.index = 1
        }
      })
      this.sortArray();
    }
  }

  onLast(params:any) {
    if (this.mainItem.length > 1) {
      this.mainItem.forEach(element => {
        if (element.index == params.data.index) {
          element.index = this.mainItem.length
        }
        else if (element.index > params.data.index) {
          element.index = element.index - 1
        }
      });
      this.sortArray()
    }
  }

  onUp(params:any){

  }

  onDown(params:any){
    
  }

  sortArray() {
    this.mainItem.sort((a, b) => {
      if (a.index < b.index) {
        return -1
      }
      if (a.index > b.index) {
        return 1
      }
      return 0
    })
    this.lookupGridApi.setRowData(this.mainItem)
    // this.filter();
  }

  onEdit(lookup: any) {
    
    const initialState = {
      lookupModel: lookup.data,
      title: 'Lookup',
      dbType: this.dbType,     
      subGroupArray : this.subGroupArray
    };

    
    this.bsModalRef1 = this.modalService.show(EditLookupComponent, { initialState });
    // this.bsModalRef.setClass('modal-lg')
    this.bsModalRef1.content.closeBtnName = 'Cancel';
    this.bsModalRef1.content.event.subscribe((res: any) => {
      this.item[lookup.rowIndex] = Object.assign({}, res);
      this.isEdited = true;
      this.lookupGridApi.setRowData(this.item);
      this.filter();      
    })
  }
 
  triggerEvent(lookup: any) {
        
    this.event.emit(lookup);
    this.bsModalRef.hide();
  }

  onGridReady(params: any) {
    this.lookupGridApi = params.api;
    this.filter()
  }

  ngOnDestroy(){
    if(this.isEdited){
      this.save();
    }    
    // this.event.unsubscribe()
    this.bsModalRef.content.event.unsubscribe();
  }

}
