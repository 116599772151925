import { LookupService } from './../../../services/lookup.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ProviderModel } from 'src/app/model/providerModel';
import { ToastrService } from 'ngx-toastr';
import { LookupModel } from 'src/app/model/lookupModel';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit {

  providersFormGroup!: FormGroup; 
  showButton = false;
  enablEditButton = false;
  showActive: boolean = false;
  constructor(private formBuilder : FormBuilder,private _lookupService:LookupService,private toaster: ToastrService) { }
  providerModel : ProviderModel = new ProviderModel()
  providerList : ProviderModel[] = [];
  provider : ProviderModel[] = [];
  providerString:string='';
  stateLookup: LookupModel[] = [];
  selectedProvider: string = "";

  ngOnInit() {
    this.createFormGroup();
    this.getAllProvider();    
    this.providersFormGroup.disable();    
    this.getLookup();
  }

  createFormGroup()
  {
    this.providersFormGroup = this.formBuilder.group({
      provider_id:[0],
      display_txt : ['',Validators.required],
      last_name_txt:['',Validators.required],
      first_name_txt:[],
      active_ind:[],
      address_1_txt:[],
      address_2_txt:[],
      city_txt:[],
      state_txt:[],
      zip_txt:[],
      npi_txt:[],
      secondary_id_txt:[],
      secondary_id_type_txt:[],
      federal_tax_id_type_txt:[],
      federal_tax_id_txt:[],
      taxonomy_cd_txt:[],
      default_billing_provider_id:[],
      billing_provider_ind:[],
      rendering_provider_ind:[],
      referring_provider_ind:[],
      phone_txt:[],
      note_txt:[],
      created_dt:[],
      created_by:[],
      updated_dt:[],
      updated_by:[],
      fax_txt:[],
      practice_name_txt:[],
      email_address_txt:['',Validators.email],
      signature_dat:[],
      meaningful_phase_ind:[],
      provider_type:[],
      direct_address:[],
      direct_password_txt:[]
    })
  }

  create()
  {
    this.showButton = true;
  }

  cancel()
  {
    this.showButton = false;
    if(this.providerModel.provider_id == ''){
      this.providersFormGroup.reset();
    }    
    this.providersFormGroup.disable();
  }

  edit()
  {
    this.showButton = true;
    this.providersFormGroup.enable(); 
  }

  getAllProvider(){
    let practiceId = localStorage.getItem('practice')?.toString();
      practiceId = practiceId != undefined ? practiceId : "";
    this._lookupService.getProvider().subscribe((res: any) => {
      this.provider = Object.assign([],res);
      //this.findProviders();
      this.providerList = this.provider;
      this.filterProvider(false);
      this.providerList = this.compare(this.providerList);
    })
  }

  setForm(provider:ProviderModel){
    
    this.providerModel = Object.assign({},provider)
    this.providersFormGroup.controls['state_txt'].setValue(this.providerModel.state_txt);
    this.enablEditButton = true;
    this.selectedProvider = this.providerModel.provider_id;
  }

  addNew(){
    this.providersFormGroup.enable();
    this.providersFormGroup.reset();
    this.providerModel = new ProviderModel();
    this.providerModel.active_ind=true;
    this.showButton = true;
  }

  save(){
    if(this.providerModel.provider_id== ''){
      if (this.providersFormGroup.valid) {
        this._lookupService.insertProvider(this.providerModel).subscribe(res=>{
          this.getAllProvider();
          this.showButton = false;
          this.enablEditButton = false;        
          this.providersFormGroup.disable()
          window.location.reload()
        }, (error) => {
            this.toaster.error(error.error);
          });
      }
     
    }
    else{
      if (this.providersFormGroup.valid) {
        this._lookupService.editProvider(this.providerModel).subscribe(res=>{
          this.getAllProvider();
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
          window.location.reload()
        }, (error) => {
            this.toaster.error(error.error);
          });
          this.showButton = false;
      }
      
    }
   
  }

  findProviders(event: any){       
    this.providerString = event.target.value;
    if(this.providerString == ''){
      if (this.showActive){
        this.providerList = this.provider;
      }else{
        this.filterProvider(false);
      }      
    }
    else{
      if (this.showActive){
        this.providerList = this.provider.filter(x=>x.display_txt.toLowerCase().includes(this.providerString.toLowerCase()))
      }else{
        this.providerList = this.provider.filter(x=>x.display_txt.toLowerCase().includes(this.providerString.toLowerCase()) && x.active_ind == true)
      }
    }
    this.providerList = this.compare(this.providerList);
  }

  getLookup(){
    this._lookupService.getGlobalLookup('states').subscribe((res: any) => {
      this.stateLookup = res;
    }, (error) => {
      this.toaster.error(error.error);
    });
  }
  filterProvider(active:boolean){
    this.showActive = active;    
    if(!this.showActive)
    {
      this.providerList = this.provider.filter(x => x.active_ind == true);
    } else {
      this.providerList = this.provider.filter(x => x.active_ind == false);
    }
    this.providerList = this.compare(this.providerList);    
  }

  compare(array: ProviderModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.display_txt)) && !isNaN(Number(b.display_txt))){      
          if (Number(a.display_txt) > Number(b.display_txt)){
            return 1;
          }else if(Number(a.display_txt) < Number(b.display_txt)){
            return -1;
          }else{
            return 0;
          }
          
      }else{         
        if (a.display_txt.toLowerCase() > b.display_txt.toLowerCase()){
            return 1;
        }else if(a.display_txt.toLowerCase() < b.display_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }      
      }    
    })
    return array;
  }

}
