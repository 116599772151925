
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayFilter',
    pure: false
})
export class ArrayFiltrerPipe implements PipeTransform {
    transform(items: any[], filter: any,property:string): any {
        if (!items || !filter || items.length == 0) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(x=>x[property].includes(filter));

    }
}