import { ROModel } from './ROModel';
import {  FamilyHistoryAdditionalModel } from './FamilyHistoryAdditionalModel';
import { FamilyHistoryModel } from './FamilyHistoryModel';
import { AllergyModel } from './AllergyModel';
import { RXModel } from './RXModel';
import { ImmunizationModel } from './ImmunizationModel';
export class PatientReviewModel{

public doctorReferred : boolean = false;
public referringDoctor : string = '';
public spouseName : string = '';
public spouseEmployer : string = '';
public emergencyContactName : string = '';
public emergencyContactRelation : string = '';
public emergencyContactHomePhone : string = '';
public emergencyContactWorkPhone : string = '';
public primaryInsuredDOB : Date = new Date();
public primaryInsuredGroup : string = '';
public primaryInsuredSSN : string = '';
public primaryInsuredName : string = '';
public primaryInsuredEmployer : string = '';
public secondaryInsurance : string = '';
public secondaryInsuranceID : string = '';
public secondaryInsuredGroup : string = '';
public secondaryInsuredName : string = '';
public secondaryInsuredDOB : Date = new Date();
public secondaryInsuredRelation : string = '';
public selfPay : boolean = false;
public socialSecurityNum : string = '';
public reasonForVisit : string = '';
public dateOfLastExam : string = '';
public lastExaminer : string = '';
public currentlyWear : number = 0;
public interestedInContacts : boolean = false;
public contactBrand : string = '';
public areContactsComfortable? : boolean = undefined;
public ageOfGlasses : string = '';
public ageOfContacts : string = '';
public medicalDoctorName : string = '';
public medicalDoctorPhone : string = '';
public currentlyTakingMedications : boolean = false;
public allergicToMedications : boolean = false;
public surgeriesAndHospitalizations : string = '';
public hadCrossedEyes : boolean = false;
public hadLazyEye : boolean = false;
public hadDroopingEyelid : boolean = false;
public hadProminentEyes : boolean = false;
public hadGlaucoma : boolean = false;
public hadRetinalDisease : boolean = false;
public hadCataracts : boolean = false;
public hadEyeInfections : boolean = false;
public hadEyeInjury : boolean = false;
public hadDoubleVision : boolean = false;
public hadEyePain : boolean = false;
public hadItching : boolean = false;
public hadTearing : boolean = false;
public hadBurning : boolean = false;
public hadRedness : boolean = false;
public hadFloaters : boolean = false;
public hadFlashes : boolean = false;
public hadHalos : boolean = false;
public isPregnantOrNursing : boolean = false;
public height : string = '';
public weight : string = '';
public interestedInLaserVision : boolean = false;
public interestedInDryEyeTreatment : boolean = false;
public preferDiscussWithDoctor : boolean = false;
public usesTobacco : boolean = false;
public tobaccoTypeAmount : string = '';
public smokes : boolean = false;
public smokeTypeAmount : string = '';
public usesIllegalDrugs : boolean = false;
public illegalDrugsTypeAmount : string = '';
public gonorrhea : boolean = false;
public hepatitis : boolean = false;
public hiv : boolean = false;
public syphilis : boolean = false;
public signed : boolean = false;
public signedValue : string = '';
public signedName : string = '';
public signedDate : Date = new Date();
public doctorReviewed : boolean = false;
public doctorReviewedValue : string = '';
public doctorReviewedDate : Date = new Date();
public doctorID  !: number;
public feverWeight : number = 2;
public integumetary : number = 2;
public allergiesHayFever : number = 2;
public sinusCongestion : number = 2;
public runnyNose : number = 2;
public postNasalDrip : number = 2;
public chronicCough : number = 2;
public dryMouth : number = 2;
public headaches : number = 2;
public migraines : number = 2;
public seizures : number = 2;
public lossOfVision : number = 2;
public blurredVision : number = 2;
public distortedVision : number = 2;
public lossOfSideVision : number = 2;
public doubleVision : number = 2;
public dryness : number = 2;
public mucousDischarge : number = 2;
public redness : number = 2;
public sandyFeeling : number = 2;
public burning : number = 2;
public foreignBodySens : number = 2;
public lightSensitive : number = 2;
public eyePainSoreness : number = 2;
public chronicEyeInfection : number = 2;
public styesChalazia : number = 2;
public flashesFloaters : number = 2;
public tiredEye : number = 2;
public asthma : number = 2;
public chronicBronchitis : number = 2;
public emphysema : number = 2;
public diabetes : number = 2;
public heartPain : number = 2;
public highBloodPressure : number = 2;
public vascularDisease : number = 2;
public itching : number = 2;
public gastrointestinalDiarrhea : number = 2;
public constipation : number = 2;
public excessTearingWatery : number = 2;
public genitourinaryDiarrhea : number = 2;
public bonesJointsMuscles : number = 2;
public rheumatoidArthritis : number = 2;
public musclePain : number = 2;
public jointPain : number = 2;
public anemia : number = 2;
public thyroidOtherGlands : number = 2;
public psychiatric : number = 2;
public immunologic : number = 2;
public explinationOfConditions : string = '';
public systemReviewQuestion : string = '';
public blindnessInFamily : number = 2;
public blindnessRelation : string = '';
public cataractInFamily : number = 2;
public cataractRelation : string = '';
public glaucomaInFamily : number = 2;
public glaucomaRelation : string = '';
public lazyCrossedEyesInFamily : number = 2;
public lazyCrossedEyesRelation : string = '';
public macularDegenerationInFamily : number = 2;
public macularDegenerationRelation : string = '';
public retinalDetachmentInFamily : number = 2;
public retinalDetachmentRelation : string = '';
public retinalDiseaseInFamily : number = 2;
public retinalDiseaseRelation : string = '';
public arthritisInFamily : number = 2;
public arthritisRelation : string = '';
public cancerInFamily : number = 2;
public cancerRelation : string = '';
public diabetesInFamily : number = 2;
public diabetesRelation : string = '';
public heartDiseaseInFamily : number = 2;
public heartDiseaseRelation : string = '';
public highBloodPressureInFamily : number = 2;
public highBloodPressureRelation : string = '';
public kidneyDiseaseInFamily : number = 2;
public kidneyDiseaseRelation : string = '';
public lupusInFamily : number = 2;
public lupusRelation : string = '';
public thyroidDiseaseInFamily: number = 2;
public thyroidDiseaseRelation : string = '';
public otherInFamily : number = 2;
public otherDescription : string = '';
public otherRelation : string = '';
public created_dt : Date = new Date();
public visionInsuredGroup : string = '';
public patient_id : string = '';
public patient_review_id : string = '';
public provider_id : string = '';
public medReconciliation : boolean = false;
public highCholesterol : number = 2;
public addPsychiatric : number = 2;
public adhd : number = 2;
public anxiety : number = 2;
public socialHistory_Other : string = '';
public isArchived : Date = new Date();
public created_by : string = '';
public updated_dt : Date = new Date();
public updated_by : string = '';
public careReconciliation : boolean = false;
public start_dt : Date = new Date();
public weightLoss : number = 2;
public socialHistoryInfection : string = '';
// public patientReview : PatientReviewModel = new PatientReviewModel();
public familyHistories : FamilyHistoryModel[] = [];
public familyHistoryAdditionals : FamilyHistoryAdditionalModel[] = [];
public ros : ROModel[] = [];
public immunization :ImmunizationModel[]=[];
public allergy:AllergyModel[]=[];
public medicalrx:RXModel[]=[];
}
