import { ExamModel } from './ExamModel';
export class OMR_ContactRXModel{
public exam_ID : string = '';
public patient_ID : string = '';
public expirationDate : Date = new Date(0);
public odPowerT : string = '';
public osPowerT : string = '';
public odBaseCurveT : string = '';
public osBaseCurveT : string = '';
public odDiameterT : string = '';
public osDiameterT : string = '';
public odBrandT : string = '';
public osBrandT : string = '';
public odManufacturerT : string = '';
public osManufacturerT : string = '';
public wearTypeDWT : boolean = false;
public wearTypeEWT : boolean = false;
public wearTypeCONVT : boolean = false;
public goodForT : string = '';
public cleaningSolnT : string = '';
public replaceWeekT : string = '';
public replaceMonthT : string = '';
public replaceDailyT : boolean = false;
public odPowerB : string = '';
public osPowerB : string = '';
public odBaseCurveB : string = '';
public osBaseCurveB : string = '';
public odDiameterB : string = '';
public osDiameterB : string = '';
public odBrandB : string = '';
public osBrandB : string = '';
public odManufacturerB : string = '';
public osManufacturerB : string = '';
public wearTypeDWB : boolean = false;
public wearTypeEWB : boolean = false;
public wearTypeCONVB : boolean = false;
public goodForB : string = '';
public cleaningSolnB : string = '';
public replaceWeekB : string = '';
public replaceMonthB : string = '';
public replaceDailyB : boolean = false;
public remarks : string = '';
public doctorsSignature     : string = '';
public license : string = '';
public doctorName : string = '';
public creationDate : Date = new Date();
public lastUpdateDate : Date = new Date();
public brand1 : string = '';
public brand2 : string = '';
public brand3 : string = '';
public brand4 : string = '';
public manufacturer1 : string = '';
public manufacturer2 : string = '';
public manufacturer3 : string = '';
public manufacturer4 : string = '';
public doctorID : number = 0;
public packForB : string = '';
public packForT : string = '';
// public exam : ExamModel = new ExamModel();
}