<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div id="content" class="scroll">
        <div class="titlebox"></div>
        <table class="table table-bordered" style="height:auto; width: auto;">
            <tr>
                <th>Brand Name:</th>
                <th>Generic Name:</th>
                <th>Strength:</th>
                <th>Dose:</th>
                <th>Route:</th>
                <th>Frequency:</th>
                <th>#:</th>
                <th>Refill:</th>              
                <th>Started Date:</th>                
                <th>Status:</th>
                <th></th>
            </tr>
            <tr *ngFor="let item of rxList;let i = index">
                <th>                   
                    <ng-select [clearable]="true" [items]="BrandLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.brand_name_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="genericLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.generic_name_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="strengthLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.strength_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="doseLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.dose_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="rxRouteLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.route_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="rxfrequency" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.frequency_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="rxNumberLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.detail_txt">
                    </ng-select>
                </th>
                <th>                   
                    <ng-select [clearable]="true" [items]="refilLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="item.refill_times_txt">
                    </ng-select>
                </th>
                <th>
                    <div class="form-row" style="margin-top: -8px; margin-left: 0px !important;">
                        <input (ngModelChange)="item.date_started_dt = $event" [ngModel]="item.date_started_dt | date:'MM-dd-yyyy'" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control">
                    </div>
                </th>
                <th>
                    <ng-select  [clearable]="true"  [items]="statusLookup" bindLabel="key"  bindValue="value" [(ngModel)]="item.status_txt">
                    </ng-select>
                </th>
                <th><button class="btn btn-danger" (click)="removeAllergy(i)">Remove</button></th>
            </tr>
        </table>
    </div>
    <div class="pt-2">
        <button class="btn btn-primary px-4" (click)="addAllergy()">Add</button>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="save()">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>