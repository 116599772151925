import { GlobalService } from './../../../services/global.service';
import { LookupService } from 'src/app/services/lookup.service';
import { ExamModel } from 'src/app/model/ExamModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OMR_ExtraTestsModel } from 'src/app/model/OMR_ExtraTestsModel';
import { PatientModel } from 'src/app/model/patientModel';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LookupModel } from 'src/app/model/lookupModel';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { OfficeModel } from 'src/app/model/officeModel';
import { UserModel } from 'src/app/model/userModel';
import { ViewportScroller } from '@angular/common';
import { PrintHeaderModel } from 'src/app/model/PrintHeaderModel';

@Component({
  selector: 'app-extra-tests',
  templateUrl: './extra-tests.component.html',
  styleUrls: ['./extra-tests.component.scss'],
})
export class ExtraTestsComponent implements OnInit, OnDestroy {
  patientModel!: PatientModel;
  examModel: ExamModel = new ExamModel();
  examModelCopy: ExamModel = new ExamModel();
  examId: string = '';
  pupilsLookup: LookupModel[] = [];
  pupilsCopyLookup: LookupModel[] = [];
  otherLookup: LookupModel[] = [];
  otherCopyLookup: LookupModel[] = [];
  farNearLookup: LookupModel[] = [];
  farNearCopyLookup: LookupModel[] = [];
  segtLookup: LookupModel[] = [];
  segtCopyLookup: LookupModel[] = [];
  patientDetail = new PatientModel();
  patientId: string = '';
  patient_Id!: number;
  License: string = '';
  user1: any = new UserModel();
  office: OfficeModel[] = [];
  officename: string = '';
  officeaddress: string = '';
  officeCitySZ:string='';
  officephone: string = '';
  officeemail: string = '';
  office_npi_txt: string = '';
  userList: UserModel[] = [];
  user: any = new UserModel();
  check_value: string = '';
  currentDate: Date = new Date();
  printHeader: PrintHeaderModel = new PrintHeaderModel();

  constructor(
    private _clinicalService: ClinicalService,
    private activateRoute: ActivatedRoute,
    private toaster: ToastrService,
    private _lookupService: LookupService,
    private globalService: GlobalService,
    private viewportScroller: ViewportScroller
  ) {
    this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['examId']) {
        this.examId = params['examId'];
      }
      if (params['patient_Id']) {
        this.patient_Id = parseInt(params['patient_Id']);
      }
    });
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.patientId = this.patientModel.patient_id;
    this.examModel.patient_ID = this.patientId;
    ``;
    this.examModel.omR_ExtraTests.patient_ID = this.patientId;
    this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
  }

  ngOnInit() {

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    this.viewportScroller.scrollToPosition([0, 0]);
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    this.viewportScroller.scrollToPosition([0, 0]);
    this.getConfigAllLookup();
    this.getAllLookup();
    if (this.patient_Id || this.patientId != '') {
      this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
    }
    if (this.examId != '') {
      this.getExtraTest();
      this.getOffice();
      //this.getUser();
    }
  }
  getOffice() {
    this._lookupService.getOffice().subscribe(
      (res: any) => {

        this.office = res;
        this.office = this.office.filter(
          (x) => x.office_id == this.patientModel.defaultOfficeID
        );
        if (this.office[0]?.name_txt) {
          this.officename = this.office[0]?.name_txt
        }
        // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
        //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
        // }
        if (this.office[0]?.address_1_txt) {
          this.officeaddress = `${this.office[0]?.address_1_txt}`;
        }
        if(this.office[0]?.address_2_txt){
          this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
        }
        if(this.office[0]?.city_txt){
          this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
        }
        if (this.office[0]?.phone_1_txt) {
          this.officephone = this.office[0]?.phone_1_txt;
        }
        if(this.office[0]?.npi_txt){
          this.office_npi_txt = this.office[0]?.npi_txt;
        }
        if(this.office[0]?.email_address_txt){
          this.officeemail = this.office[0]?.email_address_txt;
        }
      },
      (error) => {
        this.toaster.error(error.error);
      }
    );
  }

  getUser() {

    let loadingUser = new UserModel();
    loadingUser.display_txt = 'Loading Records...';
    this.userList.push(loadingUser);
    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    this.user1 = this.userList.filter(x => x.user_id == this.examModel.provider_ID);
    this.user = this.user1[0];
    if (this.user?.npi_txt != null) {
      this.License = this.user?.npi_txt
    }
    // if (this.user?.email_address_txt) {
    //   this.officeemail = this.user?.email_address_txt;
    // }
    // if (this.examModel.omR_ContactRX.doctorID==0) {
    //   this.onSelectUser(this.examModel.provider_ID);
    // }
    // else{
    //   this.onSelectUser(this.examModel.omR_ContactRX.doctorID);
    // }
    this.printHeader = {
      officeName: this.officename,
      officeAddress: this.officeaddress,
      officeCitySZ: this.officeCitySZ,
      officePhone: this.officephone,
      //officeemail: this.officeemail,
      license: this.License,
      NPI: this.office_npi_txt,
      heading: 'Extra Tests/Notes',
      examDate: this.examModel.exam_Date,
      firstName: this.patientModel.firstName,
      lastName: this.patientModel.lastName,
      serviceDate: this.patientModel.dateOfBirth,
      birthDate: this.patientModel.dateOfBirth,
    };




    // let practiceId = localStorage.getItem('practice')?.toString();
    // this._lookupService
    //   .getRenderingProvider(practiceId != undefined ? practiceId : '')
    //   .subscribe(
    //     (res: any) => {

    //       this.userList = res;
    //       this.user1 = this.userList.filter((x) => x.user_id == this.examModel.provider_ID);
    //       this.user = this.user1[0];
    //       if (this.user1[0]?.license_txt != null) {
    //         this.License = this.user1[0]?.license_txt;
    //       }
    //       this.printHeader = {
    //         officeName: this.officename,
    //         officeAddress: this.officeaddress,
    //         officeCitySZ: this.officeCitySZ,
    //         officePhone: this.officephone,
    //         license: this.License,
    //         NPI: this.user.npi_txt,
    //         heading: 'Extra Tests/Notes',
    //         examDate: this.examModel.exam_Date,
    //         firstName: this.patientModel.firstName,
    //         lastName: this.patientModel.lastName,
    //         serviceDate: this.patientModel.dateOfBirth,
    //         birthDate: this.patientModel.dateOfBirth,
    //       };
    //     },
    //     (error) => {
    //       this.toaster.error(error.error);
    //     }
    //   );
  }
  onSelectUser(id: any) {
    this.user = this.userList.find(a => a.user_id == id);
    if(!this.user){
      this.user = this.userList.find(a => a.qeUserID == id);
    }
    return true;
  }
  getExtraTest() {
    this._clinicalService.getByExamId(this.examId).subscribe((res) => {
      if(res){
        res.omR_ExtraTests.bmi = (res.omR_ExtraTests.bmi == '0') ? '' : res.omR_ExtraTests.bmi
      }
      this.examModel = res;
      this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
      this.getUser();
    });
  }

  saveExtraTest() {
    this._clinicalService.saveExam(this.examModel).subscribe(
      (res) => {
        this.toaster.success('Record Saved Successfully.');
        this.examModel = res;
        this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }

  updateExtraTest() {

    this._clinicalService.updateExam(this.examModel).subscribe(
      (res) => {
        this.toaster.success('Record Saved Successfully.');
        this.examModel = res;
        this.examModelCopy = JSON.parse(JSON.stringify(this.examModel));
      },
      (error) => {
        this.toaster.error(error.error.message);
      }
    );
  }
  saveandCloseExtraTest() {
    this._clinicalService.saveExam(this.examModel).subscribe(
      (res) => {
        this.examModel = res;
      }
    );
  }

  updateandCloseExtraTest() {
    this._clinicalService.updateExam(this.examModel).subscribe(
      (res) => {
        this.examModel = res;
      }
    );
  }


  save(isNavigate: boolean) {
    if (this.examModel.exam_ID == '') {
      this.saveExtraTest();
    } else {
      this.updateExtraTest();
    }
    if (isNavigate) {
      this._clinicalService.navigateToClinic();
    }
  }
  saveAndClose() {
    if (this.examModel.exam_ID == '') {
      this.saveandCloseExtraTest();
    } else {
      this.updateandCloseExtraTest();
    }
  }
  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {

      this.otherLookup = res.filter((x) => x.group_txt.toLowerCase() == 'extratestsnotesother'.toLowerCase() && x.active_ind == true);
        this.otherLookup = this.otherLookup.sort((a, b) => {
          if (a.name_txt < b.name_txt) {
            return -1;
          }
          if (a.name_txt > b.name_txt) {
            return 1;
          }
          return 0;
        });
      this.otherCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'extratestsnotesother'.toLowerCase());

      this.farNearLookup = res.filter((x) => x.group_txt.toLowerCase() == 'VisualAcuities'.toLowerCase() && x.active_ind == true);
      this.farNearLookup = this.farNearLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1;
        }
        if (a.name_txt > b.name_txt) {
          return 1;
        }
        return 0;
      });
      this.farNearCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'VisualAcuities'.toLowerCase());

      this.pupilsLookup = res.filter((x) => x.group_txt.toLowerCase() == 'pupildist'.toLowerCase() && x.active_ind == true);
      this.pupilsLookup = this.pupilsLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1;
        }
        if (a.name_txt > b.name_txt) {
          return 1;
        }
        return 0;
      });
      this.pupilsCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'pupildist'.toLowerCase());

      this.segtLookup = res.filter((x) => x.group_txt.toLowerCase() == 'seght'.toLowerCase() && x.active_ind == true);
      this.segtLookup = this.segtLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1;
        }
        if (a.name_txt > b.name_txt) {
          return 1;
        }
        return 0;
      });
      this.segtCopyLookup = res.filter((x) => x.group_txt.toLowerCase() == 'seght'.toLowerCase());

    });
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService
      .updateLookup(itemCopy, dbType)
      .subscribe((res: LookupModel[]) => {
        if (dbType == this.dbEnum.QE_DEV_SQL_DB) {
          this.getAllLookup();
        } else {
          this.getConfigAllLookup();
        }
        // this.sortArray(item)
      });
  }

  getConfigAllLookup() {
    // this._lookupService
    //   .getGlobalLookup('extratestsnotesother')
    //   .subscribe((res) => {
        // this.otherLookup = res.filter((x) => x.active_ind == true);
        // this.otherLookup = this.otherLookup.sort((a, b) => {
        //   if (a.name_txt < b.name_txt) {
        //     return -1;
        //   }
        //   if (a.name_txt > b.name_txt) {
        //     return 1;
        //   }
        //   return 0;
        // });
        // this.otherCopyLookup = res;
        // this._lookupService.getGlobalLookup('pupildist').subscribe((res) => {
        //   this.pupilsLookup = res.filter((x) => x.active_ind == true);
        //   this.pupilsLookup = this.pupilsLookup.sort((a, b) => {
        //     if (a.name_txt < b.name_txt) {
        //       return -1;
        //     }
        //     if (a.name_txt > b.name_txt) {
        //       return 1;
        //     }
        //     return 0;
        //   });
        //   this.pupilsCopyLookup = res;
        // });
        // this._lookupService.getGlobalLookup('seght').subscribe((res) => {
        //   this.segtLookup = res.filter((x) => x.active_ind == true);
        //   this.segtLookup = this.segtLookup.sort((a, b) => {
        //     if (a.name_txt < b.name_txt) {
        //       return -1;
        //     }
        //     if (a.name_txt > b.name_txt) {
        //       return 1;
        //     }
        //     return 0;
        //   });
        //   this.segtCopyLookup = res;
        // });
      //});
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   if (event.code!='Enter') {
  //     this.check_value = event.target.value;
  //     //console.log(event);
  //    }
  // }

  CreateNew(city: string) {}

  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(
    val: any,
    name_txt: string,
    item: LookupModel[],
    group_txt?: string,
    item1?: any,
    property?: any,
    dbType: number = DbEnum.QE_DEV_SQL_DB
  ) {
    //if(this.check_value == ""){
    this.check_value = val;
    //}
    if (this.check_value != '') {
      var index = item.findIndex(
        (x) =>
          x.name_txt.trim().toLowerCase() ==
          this.check_value.trim().toLowerCase()
      );
      // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
      // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      if (index < 0) {
        var lookupModel = new LookupModel();
        if (item[0]?.group_txt) {
          lookupModel.group_txt = item[0]?.group_txt;
          lookupModel.sub_group_txt = item[0]?.sub_group_txt;
        } else {
          lookupModel.group_txt = group_txt!;
          lookupModel.sub_group_txt = 'All';
        }
        lookupModel.active_ind = true;
        lookupModel.name_txt = val;

        if (this.check_value != lookupModel.name_txt) {
          lookupModel.name_txt = this.check_value;
        }
        if (dbType != 1) {
          var content = 'Would you like to save this ' + name_txt + ' list?';
          this.globalService
            .modal(content, 'New Item?', 'OK', 'Cancel')
            .subscribe((res) => {
              if (res.res) {
                // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
                if (dbType == DbEnum.QE_DEV_SQL_DB) {
                  this._lookupService
                    .insertLookup(lookupModel)
                    .subscribe((res) => {
                      this.getAllLookup();
                      this.toaster.success('Record Saved Successfully.');
                      item1[property]= val;//this.check_value;

                    });
                }
                // else {
                //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
                //     this.toaster.success('Record Saved Successfully.');
                //     item1[property]=this.check_value;
                //     this.getConfigAllLookup();
                //   })
                // }
              }
            });
        }
        item1[property] = val;
      } else {
        item1[property] = item[index]?.name_txt;
      }
    }
    this.check_value = '';
  }
  ngOnDestroy(): void {

    var result = this.globalService.isFormEdited(this.examModel, this.examModelCopy);
    if(!result){
      this.saveAndClose();
    }
  }
}
