<div id="content" style="height: 50%;">
    <div class="titlebox">Documents</div>
    <br>
    <div class="row">
        <div class="pt-2 col-md-5">
            <button class="btn btn-primary px-4" (click)="addAttachment()">
                Add
            </button> &nbsp;
            <button class="btn btn-primary px-4" (click)="save()" style="display: none;">
                Save Attachments
            </button> &nbsp;
            <button class="btn btn-primary px-4" (click)="deleteAttachments()">
                Delete
            </button> &nbsp;
            <!-- <div class="col-md-1"></div> -->
        </div>
        <div class="pt-2 p-0 mx-4 form-row col-md-6 float-left m-0">
            <label class="pt-1">Attachment Types:&nbsp;</label>          
                
                <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" class="col-md-6" [items]="attachmentLookup"  
                (keyup)="keyFunc($event,filterString)"
                (keydown.tab)="saveDropdown(filterString,'Attachment Type',attachmentLookup,'attachmenttype','Patient',dbEnum.QE_Dev_Config,'filterString')" bindLabel="name_txt" bindValue="name_txt" (change)='filterAttachment(filterString)' 
                [(ngModel)]="filterString">
                </ng-select>
                <a href="javascript:void(0)" (click)="editLookup(attachmentLookup,attachmentLookup,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" style="margin-right: -10px;" height="10px"></a>
                
        </div>
    </div>

    <div class="pt-2">
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 500px; width: 100%;" (gridReady)="onGridReady($event)" class="ag-theme-alpine" (rowDoubleClicked)="downloadFile($event)" (selectionChanged)="getId()" [rowSelection]="'multiple'" [rowData]="rowDocuments" [columnDefs]="columnDocuments">
        </ag-grid-angular>
    </div>
</div>