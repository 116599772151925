<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="showComponent">
    <div class="row">
        <div class="col-md-3 col-sm-4">
        <label>Exam:</label>
        </div>
        <div class="col-md-5 col-sm-8">
        <!-- <select class="form-control" [(ngModel)]="exam_AssessmentsModel[0].exam_ID">
      <option *ngFor="let exam of examModel" [value]="exam.exam_ID">{{exam.cheif_Complaint}}</option>
    </select> -->
        <ng-select [clearable]="true"  [items]="examModel" bindLabel="cheif_Complaint" bindValue="exam_ID" [(ngModel)]="exam_AssessmentsModel[0].exam_ID">
        </ng-select>
        </div>
    </div>
    <div class="row">
        
            <div class="col-md-3 col-sm-4">
                <label>Type:</label>
            </div>
            <div class="col-md-5 col-sm-8">
            <!-- <select class="form-control" [(ngModel)]="exam_AssessmentsModel[0].type_txt">
        <option *ngFor="let lookup of type" [value]="lookup.name_txt">{{lookup.name_txt}}</option>
      </select> -->
      <div class="form-row">
        <div class="col-11">
          <ng-select [clearable]="true" [items]="type" addTagText="Add New" [addTag]="CreateNew" bindLabel="name_txt" bindValue="name_txt" 
          (keyup)="keyFunc($event,exam_AssessmentsModel[0],'type_txt')"
          (keydown.tab)="saveDropdown(exam_AssessmentsModel[0].type_txt,'type',type,'assessmenttype','All',exam_AssessmentsModel,'type_txt')" 
          [(ngModel)]="exam_AssessmentsModel[0].type_txt">
          </ng-select>
        </div>
        <div class="col-1">
          <a href="javascript:void(0)" (click)="editLookup(type,typeCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>
       
       
      </div>
           
            </div>
    </div>
    <div class="row">    
        
            <div class="col-md-3 col-sm-4">
            <label>Assessment:</label>
            </div>
            <div class="col-md-7 col-sm-8">
            <!-- <select class="form-control" [(ngModel)]="exam_AssessmentsModel[0].assessment">
              <option *ngFor="let lookup of type" [value]="lookup.name_txt">{{lookup.name_txt}}</option>
      </select> -->
      <div class="form-row">
        <div class="col-11">
          <ng-select [clearable]="true" [items]="assessment" addTagText="Add New" [addTag]="CreateNew" bindLabel="name_txt" bindValue="name_txt" 
          (keyup)="keyFunc($event,exam_AssessmentsModel[0],'assessment')"
          (keydown.tab)="saveDropdown(exam_AssessmentsModel[0].assessment,'assessment',assessment,'assessment','All',exam_AssessmentsModel,'assessment')"  
          [(ngModel)]="exam_AssessmentsModel[0].assessment">
          </ng-select>
        </div>
        <div class="col-1">
          <a href="javascript:void(0)" (click)="editLookup(assessment,assessmentCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
        </div>        
      </div>
          
             </div>
        
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="save()">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>