<div class="modal-header">
    <h4 class="modal-title pull-left">PatientInsurance Window</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-4 col-sm-6">
            <div class="form-group">

                <label class="mr-2">Type:</label>
                <div class="row">
                    <div class="col-md-6">
                        <select style="border-radius: 5px;" disabled class="form-control" [(ngModel)]="item.insuranceType">
                            <option value="1">Medical</option>
                            <option value="2">Vision</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <select style="border-radius: 5px;" disabled [(ngModel)]="item.insuranceOrder" class="form-control">
                        <option value="0">Primary</option>
                        <option value="1">Secondary</option>
                        <option value="2">Teriatry</option>
                    </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-6">
            <div class="form-row">
                <label class="mr-2">Relation:</label>
                <input class="form-control" disabled [(ngModel)]="item.insuredRelation">
            </div>
        </div>
        <div class="col-md-2 col-sm-6">
            <div class="form-group">
                <label class="chkbox mt-2">
                    <input type="checkbox" disabled [(ngModel)]="item.current_ind"
                         class="m-2">
                    Current
                    <span class="chkmark"></span>
                </label>
            </div>
        </div>
    </div>
    <div class="row" style="padding-bottom: 20px;">
        <div class="col-md-4 col-sm-4">
            <label>Insurance:</label>
            <!-- <button class="button">
                <img src="../../../../assets/img/search.ico" style="height:210% ;height:70%">
            </button> -->
            <input type="search" class="form-control" disabled [(ngModel)]="item.insuranceName">
        </div>
        <div class="col-md-4 col-sm-4">
            <label>Group:</label>
            <input type="text" class="form-control" disabled [(ngModel)]="item.groupID">
        </div>
        <div class="col-md-4 col-sm-4">
            <label>Member ID:</label>
            <input type="text" class="form-control" disabled [(ngModel)]="item.memberID">
        </div>
    </div>
    <!-- <div id="content" style="padding-bottom: 30px;" disabled *ngIf="item.insuredRelation!='Self' && item.insuredRelation">
        <div class="titlebox">Insured</div>
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label>Name:</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="item.insuredName">
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="form-group">
                    <label>Employer:</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="item.insuredEmployer">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <label>Sex:</label>
                <select class="form-control" disabled [(ngModel)]="item.insuredGender">
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
            </div>
            <div class="col-md-4 col-sm-4">
                <label>DOB:</label>
                <input type="text" disabled class="form-control" [ngModel]="item.insuredDOB | date:'MM-dd-yyyy'">
            </div>
            <div class="col-md-4 col-sm-4">
                <label>SSN:</label>
                <input type="text" disabled class="form-control" [(ngModel)]="item.insuredSSN">
            </div>
        </div>
    </div> -->
    <div class="row" style="padding-top:10px;">
        <div class="col-md-6 col-sm-6">
            <div class="form-row">
                <label class="mr-2">Insurance Card:</label>
                <ng-select [clearable]="true" style="border-radius: 5px;" [(ngModel)]="item.insuredCard" class="col-md-8" [disabled]="true">

                    <!-- <ng-option value="0">Insurance Card</ng-option> -->
                </ng-select>
            </div>
        </div>
        <div class="col-md-2 col-sm-3">
            <button class="btn btn-primary" style="padding: 5px 35px;">New</button>
        </div>
        <div class="col-md-2 col-sm-3">
            <button class="btn btn-primary" style="padding: 5px 35px;">View</button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <label>Display Name:</label>
            <input type="text" hidden [(ngModel)]="item.insuranceModel.insurance_id" class="form-control">
            <input type="text" disabled [(ngModel)]="item.insuranceModel.display_txt" class="form-control">
        </div>
        <div class="col-md-7">
            <!-- <div class="row">
                <div class="col-md-6">
                    <label>Type:</label>
                    <select class="form-control" disabled [(ngModel)]="item.insuranceModel.type_val">
                      <option [value]="'Medical'">Medical</option>
                      <option [value]="'Vision'">Vision</option>
                  </select>
                </div>
                <div class="col-md-6">
                    <input type="checkbox" disabled [(ngModel)]="item.insuranceModel.active_ind">
                    <label class="mx-2">Active</label>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <label>Address 1:</label>
            <input type="text" disabled [(ngModel)]="item.insuranceModel.address1_txt" class="form-control">
        </div>
        <div class="col-md-7">
            <label>Address 2:</label>
            <input type="text" disabled [(ngModel)]="item.insuranceModel.address2_txt" class="form-control">
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <label>City:</label>
            <input type="text" disabled [(ngModel)]="item.insuranceModel.city_txt" class="form-control">
        </div>
        <div class="col-md-7">
            <div class="row">
                <div class="col-md-8">
                    <label>State:</label>
                    <input type="text" class="form-control" disabled [(ngModel)]="item.insuranceModel.state_txt">
                </div>
                <div class="col-md-4">
                    <label>Zip:</label>
                    <input type="text" disabled [(ngModel)]="item.insuranceModel.zip_txt" class="form-control">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5">
            <label>Phone:</label>
            <input type="text" disabled [(ngModel)]="item.insuranceModel.phone_txt" class="form-control">
        </div>
        <div class="col-md-7">
            <div class="row">
                <div class="col-md-8">
                    <label>Fax:</label>
                    <input type="text" disabled [(ngModel)]="item.insuranceModel.fax_txt" class="form-control">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Close</button>
</div>