import { LookupService } from './../../services/lookup.service';
import { ToastrService } from 'ngx-toastr';
import { LookupModel } from './../../model/lookupModel';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';

@Component({
  selector: 'app-edit-lookup',
  templateUrl: './edit-lookup.component.html',
  styleUrls: ['./edit-lookup.component.scss']
})
export class EditLookupComponent implements OnInit,OnDestroy {
  title : string = '';
  lookupModel : LookupModel = new LookupModel();
  dbType : number = 1;
  subGroupArray = []
  group_txt="note_type"
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef : BsModalRef,private toastr : ToastrService,private _lookupService : LookupService) { }

  ngOnInit() {
    
  }

  save(){
        
    if(this.lookupModel.lookup_id=="")
    {
      this._lookupService.insertLookup(this.lookupModel).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.triggerEvent(this.lookupModel)
      })
    }
    if (this.dbType == DbEnum.QE_DEV_SQL_DB) {
      this._lookupService.editLookup(this.lookupModel).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.triggerEvent(this.lookupModel)
      })
    }
    else {
      this._lookupService.updateConfigLookup(this.lookupModel).subscribe(res => {
        this.toastr.success('Record Saved Successfully.');
        this.triggerEvent(this.lookupModel)
      })
      
    }
    
  }

  triggerEvent(item:any){
    
    this.event.emit(item);
    this.bsModalRef.hide();

  }

  ngOnDestroy(){
    this.event.unsubscribe()
    this.bsModalRef.content.event.unsubscribe();
  }


}
