import { PatientHistoryComponent } from './patient-history/patient-history.component';
import { SpectacleComponent } from './spectacle/spectacle.component';
import { ContactOrderComponent } from './contact-order/contact-order.component';
import { HcfaComponent } from './hcfa/hcfa.component';
import { CpoeComponent } from './cpoe/cpoe.component';
import { ExtraTestsComponent } from './extra-tests/extra-tests.component';
import { AllergyComponent } from './clinical/allergy/allergy.component';
import { MedRxComponent } from './clinical/MedRx/MedRx.component';
import { DiagnosisComponent } from './clinical/diagnosis/diagnosis.component';
import { AddExamComponent } from './add-exam/add-exam.component';
import { PreContactrxComponent } from './clinical/pre-contactrx/pre-contactrx.component';
import { PreSpecrxComponent } from './clinical/pre-specrx/pre-specrx.component';
import { PreIopComponent } from './clinical/pre-iop/pre-iop.component';
import { PreSpecorderComponent } from './clinical/pre-specorder/pre-specorder.component';
import { DocumentsComponent } from './clinical/documents/documents.component';
import { AppointmentsComponent } from './clinical/appointments/appointments.component';
import { ClinicalComponent } from './clinical/clinical.component';
import { PrescribeNowComponent } from './prescribe-now/prescribe-now.component';
import { MiscFreeStatementComponent } from './misc-free-statement/misc-free-statement.component';
import { AdvancePatientSearchComponent } from './advance-patient-search/advance-patient-search.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CreatePatientComponent } from './create-patient/create-patient.component';
import { ViewPatientComponent } from './view-patient/view-patient.component';
import { ExamsComponent } from './clinical/exams/exams.component';
import { PreContactorderComponent } from './clinical/pre-contactorder/pre-contactorder.component';
import { PatientAccountComponent } from './patient-account/patient-account.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'view',
    pathMatch: 'full'
  },
  { path: 'view', component: ViewPatientComponent },
  { path: 'create', component: CreatePatientComponent },
  { path: 'patient-search', component: AdvancePatientSearchComponent },
  { path: 'misc-free-statement', component: MiscFreeStatementComponent },
  { path: 'e-prescribe-now', component: PrescribeNowComponent },
  { path: 'clinical', component: ClinicalComponent,
    children: [
    {path:'exams',component:ExamsComponent},
    {path:'appointment',component:AppointmentsComponent},
    {path:'document',component:DocumentsComponent},
    {path:'pre-specorder',component:PreSpecorderComponent},
    {path:'pre-contactorders',component:PreContactorderComponent},
    {path:'pre-iop',component:PreIopComponent},
    {path:'pre-specrx',component:PreSpecrxComponent},
    {path:'pre-contactrx',component:PreContactrxComponent},
    {path:'notes',component:PreContactrxComponent},
    {path:'diagnosis',component:DiagnosisComponent},
    {path:'medRx',component:MedRxComponent},
    {path:'allergy',component:AllergyComponent}
    ]
  },
  { path: 'add-exam', component: AddExamComponent },
  { path: 'extra-test', component: ExtraTestsComponent },
  { path: 'cpoe', component: CpoeComponent },
  { path: 'hcfa', component: HcfaComponent },
  { path: 'contact-order', component: ContactOrderComponent },
  { path: 'spectacle', component: SpectacleComponent },
  { path: 'patient-history', component: PatientHistoryComponent },
  { path: 'patientAccount', component: PatientAccountComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PatientRoutingModule { }
