<div id="content">
  <div class="titlebox">Medication Orders</div>  
  <div class="row px-3 mt-2">
      <button class="btn btn-primary px-4" (click)="addMedicationHistory()">
    Edit
  </button> &nbsp;
      <button class="btn btn-primary px-4">
    Save
  </button> &nbsp;
      <!-- <div class="col-md-1"></div> -->
      <label class="pt-1 mx-1 ml-5">Source:</label>
      <select class="form-control mx-1 col-md-2">
    <option>sdsf</option>
  </select>
      <label class="pt-1  mx-1">Reconciliation:</label>
      <select class="form-control mx-1 col-md-2">
    <option>sdsf</option>
  </select>
      <label class="pt-1  mx-1">Status:</label>
      <select class="form-control col-md-2 mx-1">
    <option value="All">-All-</option>
    <option value="Active">Active</option>
    <option value="Inactive">Inactive</option>
    <option value="Discontinued">Discontinued</option>
    <option value="Administered">Administered</option>
  </select>
  </div>
  <div class="pt-2">
    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 250px; width: 100%;" (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onMedGridReady($event)" class="ag-theme-alpine" [rowData]="rowMedicalHistory" [columnDefs]="columnMedicalHistory">
    </ag-grid-angular>

      <!-- <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 250px; width: 100%;" class="ag-theme-alpine" [rowData]="rowMedicalHistory" [columnDefs]="columnMedicalHistory">
      </ag-grid-angular> -->
  </div>
</div>
<br>
<div id="content">
  <div class="titlebox">Medical Rx History</div>  
  <div class="d-flex  mt-2">
      <button class="btn btn-primary px-4" (click)="addMedRx()">
    Add
  </button> &nbsp;
      <button class="btn btn-primary px-4" (click)="save()">
    Save
  </button> &nbsp;
      <div class="col-md-5"></div>
      <label class="pt-1">Status:</label>
      <select class="form-control col-md-2 mx-1" [(ngModel)]="rxFilterString" (change)="filterRx()">
    <option value="All">-All-</option>
    <option value="Active">Active</option>
    <option value="Inactive">Inactive</option>
    <option value="Discontinued">Discontinued</option>
    <option value="Administered">Administered</option>
  </select>
  </div>
  <div class="pt-2">
      <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 250px; width: 100%;" (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onMedGridReady($event)" class="ag-theme-alpine" [rowData]="rowMedicalRxHistory" [columnDefs]="columnMedicalRxHistory">
      </ag-grid-angular>
  </div>
</div>