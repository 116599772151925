import { AgGridModule } from 'ag-grid-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { GeneralComponent } from './general/general.component';
import { InsurancesComponent } from './insurances/insurances.component';
import { ProvidersComponent } from './providers/providers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AdministrationRouting } from './administration.routing';
import { NgxPrintModule } from 'ngx-print';
import { AdminPhoneMask } from '../../core/directives/adminPhone.directive';
import { ProductManufacturerComponent } from './product-manufacturer/product-manufacturer.component';
import { PatientModule } from '../patient/patient.module';
import { ProductsComponent } from './products/products.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    AdministrationRouting,
    PatientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgSelectModule,
    AgGridModule.withComponents([])
  ],
  declarations: [ProvidersComponent,InsurancesComponent,GeneralComponent,UserComponent,AdminComponent,AdminPhoneMask, ProductManufacturerComponent, ProductsComponent],
  exports:[ProvidersComponent,InsurancesComponent,GeneralComponent,UserComponent,AdminComponent],
  providers: [DatePipe, BsModalRef]
})
export class AdministrationModule { }
