export class OfficeModel {
    public office_id: string = '';
    public name_txt: string = '';
    public address_1_txt: string = '';
    public address_2_txt: string = '';
    public city_txt: string = '';
    public state_txt: string = '';
    public zip_txt: string = '';
    public phone_1_txt: string = '';
    public license_txt: string = '';
    public ein_txt: string = '';
    public upin_txt: string = '';
    public chart_no_prefix_txt: string = '';
    public chart_no_end_val: number = 0;
    public chart_no_start_val: number = 0;
    public ship_to_txt: string = '';
    public bill_to_txt: string = '';
    public place_of_service_code_txt: string = '';
    public active_ind: boolean = false;
    public created_dt: Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public default_ind: boolean = false;
    public host_txt: string = '';
    public tax_rate_amt: number = 0;
    public email_address_txt: string = '';
    public npi_txt: string = '';
}