import { Item_ContactsDataModel } from "./Item_ContactsDataModel";

export class ItemModel{
    public item_id: string = '';
    public parent_item_id: string = '';
    public type_id: number = 0;
    public name_txt: string = '';
    public description_txt: string = '';
    public active_ind:boolean = false;
    public UPC_txt: string = '';
    public FPC_txt: string = '';
    public vendor_id: string = '';
    public manufacturer_id: string = '';
    public brand: string = '';
    public collection: string = '';
    public cost_amt: number = 0;
    public reg_price_amt: number = 0;
    public wholesale_amt: number = 0;
    public price_amt: number = 0;
    public sales_price_amt: number = 0;
    public asset_acct: string = '';
    public reorder_point: number = 0;
    public inventory_ind:boolean = false;
    public monitor_ind:boolean = false;
    public created_dt: Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public taxable_ind:boolean = false;
    public code_txt: string = '';
    public selected_price_txt: string = '';
    public price_calc1_amt: number = 0;
    public price_calc2_amt: number = 0;
    public price_calc3_amt: number = 0;
    public sales_price_calc1_amt: number = 0;
    public inventory_onhand_cnt: number = 0;
    public inventory_total_val: number = 0;
    public inventory_physical_cnt: number = 0;
    public cogs_acct: string = '';
    public income_acct: string = '';
    public avg_cost: number = 0;
    public cogs_acct1: string = '';
    public cogs_acct2: string = '';
    public income_acct1: string = '';
    public income_acct2: string = '';
    public asset_acct1: string = '';
    public asset_acct2: string = '';
    public item_ContactsData: Item_ContactsDataModel = new Item_ContactsDataModel();

}
