import { ToastrService } from 'ngx-toastr';
import { FormControl,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LookupService } from 'src/app/services/lookup.service';
import { PracticeService } from 'src/app/services/practice.service';
import { OfficeModel } from './../../../model/officeModel';
import { Component, Input, OnInit } from '@angular/core';
import { LookupModel } from 'src/app/model/lookupModel';
import { PracticeModel } from 'src/app/model/practiceModel';
import { PatientRaceModel } from 'src/app/model/patientRaceModel';
import { PercentPipe } from '@angular/common';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  officeModel: OfficeModel = new OfficeModel();
  officeList: OfficeModel[] = [];
  officeFormGroup !: FormGroup;
  showButton: boolean = false;
  enablEditButton: boolean = false;
  isShowSaveButton: boolean = false;
  states: LookupModel[] = [];
  showDropdown: boolean = false;
  @Input() isManageFacilities: boolean = false;
  rowPlace: LookupModel[] = [];
  placeData: LookupModel[] = [];
  practiceSettings: PracticeModel[] = [];
  columnPlace: any;
  prefix: string = '';
  suffix: string = '';
  inactivity: string = '';
  maxlogin: number = 10;
  internval: number = 10;
  lockaccountnumber: boolean = false;
  upcomingappointmen: number = 0;
  showInactiveCheckbox: boolean = false;
  selectoffice_id:string='';
  imgURL: any;
  logo_img: any;
  phoneVal = '';
  constructor(private _lookupService: LookupService, private formBuilder: FormBuilder, private toaster: ToastrService, private _practiceService: PracticeService) {

  }

  ngOnInit() {
    this.createFormGroup();
    this.getOfficeList();
    this.columnPlace = [
      {
        headerName: 'Name',
        field: 'name_txt', sortable: true, resizable: true, width: 100
      },
      {
        headerName: 'Description',
        field: 'description_txt', sortable: true, resizable: true, width: 200,
      }
    ];
    this.GetAllPractice();
    this.lockaccountnumber=true;
    this.officeFormGroup.disable();
  }


  createFormGroup() {
    this.officeFormGroup = this.formBuilder.group({
      office_id: '',
      // name_txt: new FormControl([Validators.required,Validators.maxLength(16), Validators.minLength(16)]),
      name_txt: new FormControl([Validators.required,Validators.maxLength(16)]),
      address_1_txt: [''],
      address_2_txt: [''],
      city_txt: [''],
      state_txt: [''],
      zip_txt: [''],
      ein_txt: [''],
      phone_1_txt: new FormControl([Validators.maxLength(14), Validators.minLength(14)]),
      license_txt: [''],
      upin_txt: [''],
      chart_no_prefix_txt: [''],
      chart_no_end_val: [0],
      chart_no_start_val: [0],
      ship_to_txt: [''],
      bill_to_txt: [''],
      place_of_service_code_txt: [''],
      active_ind: [false],
      created_dt: [],
      created_by: [''],
      updated_dt: [],
      updated_by: [''],
      default_ind: [false],
      host_txt: [''],
      tax_rate_amt: [0],
      email_address_txt:['',Validators.email],
      npi_txt:['']
    })
  }

  showInactive() {

  }

  getOfficeList() {
    this._lookupService.getOffice().subscribe((res: any) => {
      this.officeList = res;
      this.officeList = this.compare(this.officeList);
      this.getAllLookup();
      this.getPlaceOfService();
    })
  }

  getOfficeListWithInactive() {
    this._lookupService.getOfficeWithInactive().subscribe((res: any) => {
      this.officeList = res;
      this.officeList = this.compare(this.officeList);
      this.getAllLookup();
      this.getPlaceOfService();
    })
  }

  create() {
    this.showButton = true;
  }

  cancel() {
    this.showButton = false;
    if (this.officeModel.office_id == '') {
      this.officeFormGroup.reset();
    }
    this.officeFormGroup.disable();
  }

  edit() {
    this.showButton = true;
    this.officeFormGroup.enable();
  }

  setForm(office: OfficeModel) {
    this.officeModel = Object.assign({}, office)
    this.enablEditButton = true;
    this.selectoffice_id= this.officeModel.office_id;
    this.phoneVal = this.officeModel.phone_1_txt;
  }
  updatePhone(event: any){
    debugger;
    if(this.phoneVal != event){
      this.phoneVal = event;
      this.officeFormGroup.get('phone_1_txt')?.patchValue(event);
    }
  }

  addNew() {
    if (this.officeFormGroup.status=="DISABLED") {
      this.officeFormGroup.enable();
    }

    this.officeFormGroup.reset();
    this.officeModel = new OfficeModel();
    this.showButton = true;
  }
  save() {
    debugger;
    if (this.officeModel.office_id == '') {
      if (this.officeFormGroup.valid) {
        this.officeModel.phone_1_txt = this.phoneVal;
        this._lookupService.saveOffice(this.officeModel).subscribe(res => {
          this.getOfficeList();
          this.showButton = false;
          this.enablEditButton = false;
          this.officeFormGroup.disable()
          this.toaster.success('Record Added Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
      }

    }
    else {
      if (this.officeFormGroup.valid) {
        this.officeModel.phone_1_txt = this.phoneVal;
        this._lookupService.updateOffice(this.officeModel).subscribe(res => {
          this.getOfficeList();
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
      }
      this.showButton = false;
    }

  }

  saveManagePractice() {
    this.isShowSaveButton = false;
    let practiceId = localStorage.getItem('practice')?.toString();

    this.practiceSettings = [];

    var practiceModelPrefix : PracticeModel = new PracticeModel();
    practiceModelPrefix.Id = 0;
    practiceModelPrefix.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelPrefix.name_txt = 'prefix';
    practiceModelPrefix.value_txt = this.prefix;
    practiceModelPrefix.logo_img = null;
    this.practiceSettings.push(practiceModelPrefix);

    var practiceModelSuffix : PracticeModel = new PracticeModel();
    practiceModelSuffix.Id = 0;
    practiceModelSuffix.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelSuffix.name_txt = 'suffix';
    practiceModelSuffix.value_txt = this.suffix;
    practiceModelSuffix.logo_img = null;
    this.practiceSettings.push(practiceModelSuffix);

    var practiceModelInactivity : PracticeModel = new PracticeModel();
    practiceModelInactivity.Id = 0;
    practiceModelInactivity.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelInactivity.name_txt = 'inactivity';
    practiceModelInactivity.value_txt = this.inactivity;
    practiceModelInactivity.logo_img = null;
    this.practiceSettings.push(practiceModelInactivity);

    var practiceModelMaxlogin : PracticeModel = new PracticeModel();
    practiceModelMaxlogin.Id = 0;
    practiceModelMaxlogin.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelMaxlogin.name_txt = 'maxlogin';
    practiceModelMaxlogin.value_txt = this.maxlogin.toString();
    practiceModelMaxlogin.logo_img = null;
    this.practiceSettings.push(practiceModelMaxlogin);

    var practiceModelLogoImage : PracticeModel = new PracticeModel();
    practiceModelLogoImage.Id = 0;
    practiceModelLogoImage.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelLogoImage.name_txt = 'logoimage';
    practiceModelLogoImage.value_txt = 'logoimage';
    practiceModelLogoImage.logo_img = this.logo_img;
    localStorage.setItem('logoimage', this.imgURL);
    this.practiceSettings.push(practiceModelLogoImage);

    var practiceModelInternval : PracticeModel = new PracticeModel();
    practiceModelInternval.Id = 0;
    practiceModelInternval.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelInternval.name_txt = 'internval';
    practiceModelInternval.value_txt = this.internval.toString();
    practiceModelInternval.logo_img = null;
    localStorage.setItem('hourSegments', this.internval.toString());
    this.practiceSettings.push(practiceModelInternval);

    var practiceModelLockaccountnumber : PracticeModel = new PracticeModel();
    practiceModelLockaccountnumber.Id = 0;
    practiceModelLockaccountnumber.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelLockaccountnumber.name_txt = 'lockaccountnumber';
    practiceModelLockaccountnumber.value_txt = this.lockaccountnumber.toString();
    practiceModelLockaccountnumber.logo_img = null;
    localStorage.setItem('islockaccountnumber', this.lockaccountnumber.toString());
    this.practiceSettings.push(practiceModelLockaccountnumber);

    var practiceModelUpcomingappointmen : PracticeModel = new PracticeModel();
    practiceModelUpcomingappointmen.Id = 0;
    practiceModelUpcomingappointmen.PracticeId = practiceId != undefined ? practiceId : "";
    practiceModelUpcomingappointmen.name_txt = 'upcomingappointmen';
    practiceModelUpcomingappointmen.value_txt = this.upcomingappointmen.toString();
    practiceModelUpcomingappointmen.logo_img = null;
    localStorage.setItem('upcomingappointments', this.upcomingappointmen.toString());

    // save array to db
    this.practiceSettings.push(practiceModelUpcomingappointmen);
    this.practiceSettings.forEach((practiceSetting, ind) => {
      practiceSetting.PracticeId = practiceId != undefined ? practiceId : "";
      this._practiceService.Save(practiceSetting).subscribe((res: string) => {
        debugger;
        if(this.practiceSettings.length-1 == ind){
          window.location.reload();
        }
      })
    });
  }

  showInactiveClick() {
    if(!this.showInactiveCheckbox)
    {
      this.getOfficeListWithInactive();
    }
    else{
      this.getOfficeList();
    }
    this.officeList = this.compare(this.officeList);
  }

  getAllLookup() {
    this._lookupService.getGlobalLookup('states').subscribe((res: LookupModel[]) => {
      this.states = res.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
    })
  }

  getPlaceOfService() {
    this._lookupService.getGlobalLookup('placeofservice').subscribe((res: LookupModel[]) => {
      this.placeData = res;
      this.rowPlace = res;
    })
  }

  placeRowClicked(event: any) {
    this.officeModel.place_of_service_code_txt = event.api.getSelectedRows()[0].name_txt;
    this.showDropdown = false;
  }

  serachPlace(event?: any) {
    if (event.target.value || event.target.value != '') {
      this.rowPlace = this.placeData.filter(x => x.description_txt.toLowerCase().includes(event.target.value));
    }
    else {
      this.rowPlace = this.placeData
    }
  }
  onFileChanged(event: any) {
    debugger;
    let reader = new FileReader();
    var element = event.target.files[0];
    reader.onload = (e: any) => {
      this.imgURL = e.target.result;
    }
    reader.readAsDataURL(element);
    this.logo_img = (element);
  }

  GetAllPractice()
  {

    var practiceId = localStorage.getItem('practice')?.toString();
    this._practiceService.GetAll(practiceId != undefined ? practiceId : "").subscribe((res: PracticeModel[]) => {
      this.practiceSettings = res;
      this.practiceSettings.forEach(practice => {
        if(practice.name_txt === "prefix")
        {
          this.prefix = practice.value_txt;
        }
        else if(practice.name_txt === "suffix")
        {
          this.suffix = practice.value_txt;
        }
        else if(practice.name_txt == "inactivity")
        {
          this.inactivity = practice.value_txt;
        }
        else if(practice.name_txt == "maxlogin")
        {
          this.maxlogin = parseInt(practice.value_txt);
        }
        else if(practice.name_txt == "logoimage")
        {
          this.imgURL = practice.logo_img ? 'data:image/png;base64,' + practice.logo_img : '';
          this.logo_img = null;
          localStorage.setItem('logoimage', this.imgURL);
        }
        else if(practice.name_txt == "internval")
        {
          this.internval = parseInt(practice.value_txt);
        }
        else if(practice.name_txt == "lockaccountnumber")
        {
          this.lockaccountnumber = practice.value_txt.toLowerCase() == "true";
        }
        else if(practice.name_txt == "upcomingappointmen")
        {
          this.upcomingappointmen = parseInt(practice.value_txt);
        }
      });
    })
  }
  updateTax(event: any){

    this.officeModel.tax_rate_amt = Number((Number(event.target.value) * 100).toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}));
  }

  compare(array: OfficeModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }
}
