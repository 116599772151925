import { OMR_FeeStatementServiceModel } from './omr_FeeStatementServiceModel';
import { OMR_FeeStatementPaymentModel } from './omr_FeeStatementPaymentModel';
import { OMR_FeeStatementItemModel } from './omr_FeeStatementItemModel';
import { OMR_FeeStatementDiagnosisModel } from './omr_FeeStatementDiagnosisModel';
export class OMR_FeeStatementModel {
    public fee_statement_id: string = '';
    public patient_id: string = '';
    public exam_id: string = '';
    public attachment_id: string = '';
    public provider_id: string = '';
    public office_id: string = '';
    public insurance_id: string = '';
    public insurance_display_txt:string='';
    public dos_dt: string = '';
    public status_txt: string = '';
    public type_txt: string = '';
    public notes_txt: string = '';
    public signing_provider_id: string = '';
    public created_dt: string = '';
    public created_by: string = '';
    public updated_dt: string = '';
    public updated_by: string = '';
    public IsArchived: boolean = false;
    public responsible_txt: string = '';
    public omR_FeeStatementDiagnosis: OMR_FeeStatementDiagnosisModel[] = [];
    public omR_FeeStatementItem: OMR_FeeStatementItemModel[] = [];
    public omR_FeeStatementPayment: OMR_FeeStatementPaymentModel[] = [];
    public omR_FeeStatementService: OMR_FeeStatementServiceModel[] = [];
}
