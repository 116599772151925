export const environment = {
  production: false,
  server: "http://localhost:24297/",
  domain:"http://localhost:24297/"
};
// export const environment = {
//   production: true,
//   server: "https://quikeyesapiprod.azurewebsites.net/",
//   domain:"https://quikeyesapiprod.azurewebsites.net/"
// };
