import { PatientReviewModel } from './../model/PatientReviewModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientHistoryService {

constructor(private http:HttpClient) { }

getPatientHistory(patient_Id:string) : Observable<PatientReviewModel[]>{
  return this.http.get<PatientReviewModel[]>(environment.server+'api/patientReview/getall?patientId='+patient_Id);
}

insertPatientHistory(patientReviewModel : PatientReviewModel): Observable<PatientReviewModel>{
  return this.http.post<PatientReviewModel>(environment.server+'api/patientReview/add',patientReviewModel);
} 

updatePatientHistory(patientReviewModel : PatientReviewModel): Observable<PatientReviewModel>{
  return this.http.post<PatientReviewModel>(environment.server+'api/patientReview/update',patientReviewModel);
}

deletePatientHistory(id:string): Observable<PatientReviewModel>{
  return this.http.delete<PatientReviewModel>(environment.server+'api/patientReview/delete?id'+id);
}

}
