import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AllergyModel } from 'src/app/model/AllergyModel';
import { PatientModel } from 'src/app/model/patientModel';
import { RXModel } from 'src/app/model/RXModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { LookupModel } from 'src/app/model/lookupModel';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-add-medication-history',
  templateUrl: './add-medication-history.component.html',
  styleUrls: ['./add-medication-history.component.scss']
})
export class AddMedicationHistoryComponent implements OnInit {

  title: string = '';
  list : any;
  closeBtnName : string = '';
  allergy : RXModel[] = [];
  rxList:RXModel[]=[];  
  medicationList: RXModel[] = [];
  
  patientDetails: PatientModel = new PatientModel();
  allergyList: AllergyModel[] = [];
 
  // familyHistoryAddition: FamilyHistoryAdditionalModel[] = [];
  
  relationLookup:LookupModel[]=[];
  someObject: { [key: string]: any } = {}
  needToFind: any;
  columnPatientHistory: any;
  smokingStatusDropDown: LookupModel[] = []; 
  imgURL: any;
  imageEXTSRC: any;
  imageINTSRC: any;
  vaccineLookUp: LookupModel[] = [];
  vaccineLookUpCopy: LookupModel[] = [];
  socialHistoryLookup: LookupModel[] = [];  
  showComponent: boolean = false;
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  genericLookup: LookupModel[] = [];
  genericLookupCopy: LookupModel[] = [];
  BrandLookup: LookupModel[] = [];
  phrxdetailLookUp: LookupModel[] = [];
  phrxdetailCopyLookUp: LookupModel[] = [];
  phrxtypeLookUp: LookupModel[] = [];
  phrxtypeCopyLookUp: LookupModel[] = [];
  medication: LookupModel[] = [];
  medicationCopy: LookupModel[] = [];
  reaction: LookupModel[] = [];
  reactionCopy: LookupModel[] = [];
  type: LookupModel[] = [];
  typeCopy: LookupModel[] = [];
  rosConstitutionalLookUp: LookupModel[] = [];
  rosConstitutionalCopyLookUp: LookupModel[] = [];
  rosSkinLookUp: LookupModel[] = [];
  rosSkinCopyLookUp: LookupModel[] = [];
  rosNeurologicalLookUp: LookupModel[] = [];
  rosNeurologicalCopyLookUp: LookupModel[] = [];
  rosEyesLookUp: LookupModel[] = [];
  rosEyesCopyLookUp: LookupModel[] = [];
  rosPsychiatricLookUp: LookupModel[] = [];
  rosPsychiatricCopyLookUp: LookupModel[] = [];
  rosAllergicLookUp: LookupModel[] = [];
  rosAllergicCopyLookUp: LookupModel[] = [];
  rosEarsNoseMouthThroatLookUp: LookupModel[] = [];
  rosEarsNoseMouthThroatCopyLookUp: LookupModel[] = [];
  rosRespiratoryLookUp: LookupModel[] = [];
  rosRespiratoryCopyLookUp: LookupModel[] = [];
  rosCardiovascularLookUp: LookupModel[] = [];
  rosCardiovascularCopyLookUp: LookupModel[] = [];
  rosGastrointestinalLookUp: LookupModel[] = [];
  rosGastrointestinalCopyLookUp: LookupModel[] = [];
  rosGenitourinaryLookUp: LookupModel[] = [];
  rosGenitourinaryCopyLookUp: LookupModel[] = [];
  rosBonesLookUp: LookupModel[] = [];
  rosBonesCopyLookUp: LookupModel[] = [];
  rosLymphaticLookUp: LookupModel[] = [];
  rosLymphaticCopyLookUp: LookupModel[] = [];
  rosEndocrineLookUp: LookupModel[] = [];
  rosEndocrineCopyLookUp: LookupModel[] = [];  
  strengthLookup:LookupModel[]=[];
  strengthLookupCopy:LookupModel[]=[];
  doseLookup:LookupModel[]=[];
  doseLookupCopy:LookupModel[]=[];
  rxRouteLookup:LookupModel[]=[];
  rxRouteLookupCopy:LookupModel[]=[];
  rxfrequency: LookupModel[] = [];
  rxfrequencyCopy: LookupModel[] = [];
  rxNumberLookup:LookupModel[]=[];
  rxNumberLookupCopy:LookupModel[]=[];
  refilLookup:LookupModel[]=[];
  refilLookupCopy:LookupModel[]=[];
  statusLookup: any =
    [{
      "value": "All",
      "key": "-All-"
    },
    {
      "value": "Active",
      "key": "Active"
    },
    {
      "value": "InActive",
      "key": "InActive"
    },
    {
      "value": "Discontinued",
      "key": "Discontinued"
    },
    {
      "value": "Administered",
      "key": "Administered"
    }]



  constructor(public bsModalRef: BsModalRef,private _clinicalService:ClinicalService, private _lookupService: LookupService,private toaster: ToastrService) { 
    this.patientDetails = JSON.parse(localStorage.getItem('ActivePatient')!);
  }

  ngOnInit() {
    this.allergy = Object.assign([],this.list);
    // this.allergy.forEach(element=>{
    //   element.status_txt = 'All';
    // })
    this.getRx()
    this.getdataAllLookup();
  }

  save(){
    this._clinicalService.saveRx(this.rxList).subscribe(res => {
      this.toaster.success('Record Saved Successfully.');
      this.bsModalRef.hide();

    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  getRx() {
    this._clinicalService.getRx( this.patientDetails.patient_id).subscribe((res: any) => {
      this.rxList = res;
      // this.rowMedicalRxHistory = this.rxList;
     
    })
  }

  addAllergy(){
    var allergyModel = new RXModel();
    allergyModel.status_txt = 'All';
    allergyModel.patient_id = this.patientDetails.patient_id;
    this.rxList.push(allergyModel);
  }

  removeAllergy(index:number){
    this.rxList.splice(index,1);
  }

  getdataAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: Array<LookupModel>) => {
      // res = res.filter(x=>x.active_ind == true);
      this.vaccineLookUp = res.filter(x => x.group_txt.toLowerCase() == 'vaccinename'.toLowerCase());
      this.vaccineLookUpCopy = Object.assign([], this.vaccineLookUp);
      this.socialHistoryLookup = res.filter(x => x.group_txt.toLowerCase() == 'socialhistoryother'.toLowerCase());
      this.genericLookup = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname');
      this.BrandLookup = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'brandname');
      this.genericLookupCopy = res.filter(x => x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname');
      this.phrxdetailLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxdetail'.toLowerCase());
      this.phrxdetailCopyLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxdetail'.toLowerCase());
      this.phrxtypeLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxtype'.toLowerCase());
      this.phrxtypeCopyLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phrxtype'.toLowerCase());
      this.phrxtypeLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phallergyname'.toLowerCase());
      this.phrxtypeCopyLookUp = res.filter(x => x.group_txt.toLowerCase() == 'phallergyname'.toLowerCase());
      this.strengthLookup =  res.filter(x => x.group_txt.toLowerCase() == 'rxstrength'.toLowerCase());
      this.strengthLookupCopy =  res.filter(x => x.group_txt.toLowerCase() == 'rxstrength'.toLowerCase());
      this.doseLookup =  res.filter(x => x.group_txt.toLowerCase() == 'dose'.toLowerCase());
      this.doseLookupCopy =  res.filter(x => x.group_txt.toLowerCase() == 'dose'.toLowerCase());
      this.rxRouteLookup =res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase());
      this.rxRouteLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase());
      this.rxfrequency = res.filter(x => x.group_txt.toLowerCase() == 'rxfrequency'.toLowerCase());
      this.rxfrequencyCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxfrequency'.toLowerCase());
      this.rxNumberLookup =res.filter(x => x.group_txt.toLowerCase() == 'rxnumber'.toLowerCase());
      this.rxNumberLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxnumber'.toLowerCase());
      this.refilLookup = res.filter(x => x.group_txt.toLowerCase() == 'refill'.toLowerCase());
      this.refilLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'refill'.toLowerCase());      
      this.showComponent = true;
    })
  }
  

}
