import { GlobalService } from './../../../services/global.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { PatientModel } from 'src/app/model/patientModel';
import { saveAs } from 'file-saver';
import { ViewportScroller } from '@angular/common';
import { OfficeModel } from 'src/app/model/officeModel';
import { LookupService } from 'src/app/services/lookup.service';
import { UserModel } from 'src/app/model/userModel';
@Component({
  selector: 'app-prescribe-now',
  templateUrl: './prescribe-now.component.html',
  styleUrls: ['./prescribe-now.component.scss']
})
export class PrescribeNowComponent implements OnInit {
  patientId: number = 0
  patientData = new PatientModel();
  age: number = 0;
  office: OfficeModel[] = [];
  officename: string = '';
  officeaddress: string = '';
  officeCitySZ:string='';
  officephone: string = '';
  officeemail: string = '';
  office_npi_txt: string = '';
  userList: UserModel[] = [];
  user: any = new UserModel();
  License:string='';
  constructor(private route: ActivatedRoute, private _patientService: PatientService, private toaster: ToastrService, private globalService: GlobalService, private _lookupService: LookupService, private viewportScroller: ViewportScroller) {

    this.route.queryParams.subscribe((params: any) => {
      this.patientId = Number(params['patientId']);
    });
  }

  ngOnInit() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.getPatientData();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.globalService.changeCommonLayout(true);
    }, 100);
  }
  getOffice() {
    debugger;
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;
      this.office = this.office.filter(x => x.office_id == this.patientData.defaultOfficeID);
      if (this.office[0]?.name_txt != null) {
        this.officename = this.office[0]?.name_txt
      }
      // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
      //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
      // }
      if (this.office[0]?.address_1_txt) {
        this.officeaddress = `${this.office[0]?.address_1_txt}`;
      }
      if(this.office[0]?.address_2_txt){
        this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
      }
      if(this.office[0]?.city_txt){
        this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
      }
      if (this.office[0]?.phone_1_txt) {
        this.officephone = this.office[0]?.phone_1_txt;
      }
      if(this.office[0]?.npi_txt){
        this.office_npi_txt = this.office[0]?.npi_txt;
      }
      if(this.office[0]?.email_address_txt){
        this.officeemail = this.office[0]?.email_address_txt;
      }

    }, (error) => {
      this.toaster.error(error.error);
    });
  }
  getUser() {
    debugger;
    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    var user1 = this.userList.filter(x => x.user_id == this.patientData.referring_provider_id);
    this.user = user1[0];
    if (this.user?.npi_txt) {
      this.License = this.user?.npi_txt
    }
    // if (this.user?.email_address_txt) {
    //   this.officeemail = this.user?.email_address_txt;
    // }
  }

  getPatientData() {
    this._patientService.getPatientByPatientId(this.patientId).subscribe((res: any) => {
      this.patientData = Object.assign({}, res);
      this.getUser();
      this.getOffice();
      this.calculateAge(this.patientData.dateOfBirth);
    }, (error) => {
      this.toaster.error(error.error);
    }
    );
  }

  calculateAge(birthdate: Date) {
    let timeDiff = Math.abs(Date.now() - birthdate.getTime());
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
  }
  exportPatients()
  {
    this._patientService.getPatientList('').subscribe((res: PatientModel[]) => {
      let csvArray ='';
      res.forEach(element => {
        csvArray = csvArray + '\r\n';

        csvArray = csvArray + (element.patientAcctNo == null ? '' : element.patientAcctNo)
        + ',' + (element.firstName == null ? '' : element.firstName)
        + ',' + (element.middleInitial == null ? '' : element.middleInitial)
        + ',' + (element.lastName == null ? '' : element.lastName)
        + ',' + (element.dateOfBirth == null ? '' : element.dateOfBirth)
        + ',' + (element.sex == null ? '' : element.sex)
        + ',' + (element.address == null ? '' : element.address)
        + ',' + (element.address2 == null ? '' : element.address2)
        + ',' + (element.city == null ? '' : element.city)
        + ',' + (element.zip == null ? '' : element.zip)
        + ',' + (element.emailaddress == null ? '' : element.emailaddress)
        + ',' + (element.homePhone == null ? '' : element.homePhone)
        + ',' + (element.cellPhone == null ? '' : element.cellPhone);
      });
      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, "myFile.csv");
    });
  }
}
