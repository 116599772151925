<div id="content" *ngIf="!isForUnavailability">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="col-md-2">
                <label>Subject:</label>
            </div>
            <div class="col-md-8">
                <label class="pl-4">{{list.subject_txt}}</label><label *ngIf="list.reason_txt"> - {{list.reason_txt}}</label>
            </div>
        </div>
        <div class="form-row pt-1">
            <div class="col-md-2 col-sm-4">
            <label>Patient:</label>
            </div>
            <div class="col-md-8 col-sm-8">
                <input (keyup)="onKeySearch($event)" type="text" [(ngModel)]="list.subject_txt" class="form-control col-md-8">
                <div *ngIf="isShowPatientDetail" class="dropdown w-100 h-30">
                        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" rowSelection="single" class="ag-theme-alpine" [rowData]="rowPatient" [columnDefs]="columnPatient" (rowClicked)="changeSelection($event)" style="cursor: pointer;height: 200px;">
                        </ag-grid-angular>
                </div>
            </div>
        </div>
        <div class="pt-1">
            <div class="form-row">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <button class="btn btn-primary" (click)="addPatient()">Add New Patient</button>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-2">
                    <label>Phone:</label>
                </div>
                <div class="col-md-8">
                    <label class="pl-4">{{patientModel.homePhone}}</label>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-2">
                    <label>DOB:</label>
                </div>
                <div class="col-md-8">
                    <label class="pl-4">{{patientModel.dateOfBirth| date:'shortDate'}}</label>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-2">
                    <label>Email:</label>
                </div>
                <div class="col-md-8">
                    <label class="pl-4">{{patientModel.emailaddress}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Reason:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" class="col-md-8" bindLabel="name_txt" [items]="reasonLookup"
                    (keyup)="keyFunc($event,list,'reason_txt')"
                    (keydown.tab)="saveDropdown(list.reason_txt,'Reason',reasonLookup,'appointmentreason',list,'reason_txt')" bindValue="name_txt"
                    [(ngModel)]="list.reason_txt">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(reasonLookup,reasonLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
            <label>Start Date:</label>
            </div>
            <div class="col-md-8">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="list.original_starttime_dt = $event" [ngModel]="list.original_starttime_dt | date:'MM-dd-yyyy'" class="form-control col-md-8">
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-2">
                <label>Start Time:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [items]="hourList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="hour" class="col-md-3">
                    </ng-select>
                    <ng-select [items]="minuteList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="minute" class="col-md-3">
                    </ng-select>
                    <ng-select [items]="timeList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="time" class="col-md-2 pl-0 pr-0">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Duration:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [items]="durationList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="list.duration_val" class="col-md-6">
                      <option></option>
                    </ng-select>
                    <label class="pt-1">Minutes</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Resource:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [items]="resourceList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="list.resource_ids" class="col-md-8">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 col-sm-4">
                <label>Status:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select class="col-md-8" [clearable]="true" addTagText="Add New" [addTag]="CreateNew" bindLabel="name_txt" [items]="statusLookup"
                    (keyup)="keyFunc($event,list,'status_txt')"
                    (keydown.tab)="saveDropdown(list.status_txt,'Status',statusLookup,'appointmentstatus',list,'status_txt')" bindValue="name_txt"
                    [(ngModel)]="list.status_txt">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(statusLookup,statusLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 col-sm-4">
                <label>Note:</label>
            </div>
            <div class="col-md-8">
                <textarea [(ngModel)]="list.note_txt" class="form-control col-md-8"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="save()">Save</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
    </div>
</div>
<div id="content" *ngIf="isForUnavailability">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{title}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-row">
            <div class="col-md-2">
                <label>Subject:</label>
            </div>
            <div class="col-md-8">
                <label class="pl-4">{{list.resource_ids}}</label><label *ngIf="list.reason_txt"> - {{list.reason_txt}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Reason:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" class="col-md-8" bindLabel="name_txt" [items]="unavailablereasonLookup"
                    (keyup)="keyFunc($event,list,'reason_txt')"
                    (keydown.tab)="saveDropdown(list.reason_txt,'Reason',unavailablereasonLookup,'appointmentreason',list,'reason_txt')" bindValue="name_txt"
                    [(ngModel)]="list.reason_txt">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(unavailablereasonLookup,unavailablereasonLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 6px;">
            <div class="col-md-2">
                <label>Start Date:</label>
            </div>
            <div class="col-md-8">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="list.original_starttime_dt = $event" [ngModel]="list.original_starttime_dt | date:'MM-dd-yyyy'" class="form-control col-md-8">
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Start Time:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [items]="hourList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="hour" class="col-md-3">
                    </ng-select>
                    <ng-select [items]="minuteList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="minute" class="col-md-3">
                    </ng-select>
                    <ng-select [items]="timeList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="time" class="col-md-2 p-0">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 6px;">
            <div class="col-md-2">
            <label>End Date:</label>
            </div>
            <div class="col-md-8">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="list.original_endtime_dt = $event" [ngModel]="list.original_endtime_dt | date:'MM-dd-yyyy'" class="form-control col-md-8">
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>End Time:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [items]="hourList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="endHour" class="col-md-3">
                    </ng-select>
                    <ng-select [items]="minuteList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="endMinute" class="col-md-3">
                    </ng-select>
                    <ng-select [items]="timeList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="endTime" class="col-md-2 p-0">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <label>Resource:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select [items]="resourceList" [clearable]="true" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="list.resource_ids" class="col-md-8">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 col-sm-4">
                <label>Status:</label>
            </div>
            <div class="col-md-8">
                <div class="form-row">
                    <ng-select class="col-md-8" [clearable]="true" addTagText="Add New" [addTag]="CreateNew" bindLabel="name_txt" [items]="statusLookup"
                    (keyup)="keyFunc($event,list,'status_txt')"
                    (keydown.tab)="saveDropdown(list.status_txt,'Status',statusLookup,'appointmentstatus',list,'status_txt')" bindValue="name_txt"
                    [(ngModel)]="list.status_txt">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(statusLookup,statusLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2 col-sm-4">
                <label>Note:</label>
            </div>
            <div class="col-md-8">
                <textarea [(ngModel)]="list.note_txt" class="form-control col-md-8"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="saveUnavailibility()">Save</button>
        <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
    </div>
</div>
