import { UserService } from './../../../services/user.service';
import { UserModel } from 'src/app/model/userModel';
import { LookupModel } from 'src/app/model/lookupModel';
import { LookupService } from 'src/app/services/lookup.service';
import { ClinicalService } from 'src/app/services/clinical.service';
import { OMR_EXAMModel } from './../../../model/OMR_EXAMModel';
import { Exam_MedRXModel } from './../../../model/Exam_MedRXModel';
import { OfficeModel } from './../../../model/officeModel';
import { RXModel } from './../../../model/RXModel';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PatientModel } from 'src/app/model/patientModel';
import { GlobalService } from 'src/app/services/global.service';
import { PatientService } from 'src/app/services/patient.service';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { ExamModel } from 'src/app/model/ExamModel';
import { saveAs } from 'file-saver';
import { parse } from 'path';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-cpoe',
  templateUrl: './cpoe.component.html',
  styleUrls: ['./cpoe.component.scss']
})
export class CpoeComponent implements OnInit {

  rowMedicalHistory: RXModel[] = [];
  columnMedicalHistory: any;
  columnMedRx: any;
  patientId: string = '';
  patientData = new PatientModel();
  age: number = 0;
  rxModel: RXModel[] = [];
  newRXModel: RXModel[] = [];
  patientDetail: PatientModel = new PatientModel();
  user1: any = new UserModel();
  License:string='';
  examId: string = '';
  exam_MedRXModel: Exam_MedRXModel = new Exam_MedRXModel();
  productLookup: LookupModel[] = [];
  genericLookup: LookupModel[] = [];
  brandLookup: LookupModel[] = [];
  strengthLookup: LookupModel[] = [];
  doseLookup: LookupModel[] = [];
  rxfrequency: LookupModel[] = [];
  routeLookup: LookupModel[] = [];
  refilLookup: LookupModel[] = [];
  farNearLookup: LookupModel[] = [];
  userList: UserModel[] = [];
  user: any = new UserModel();
  userdata: any = new UserModel();
  imgURL: any;
  Userid: number = 0;
  bsConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  productLookupCopy: LookupModel[] = [];
  genericLookupCopy: LookupModel[] = [];
  brandLookupCopy: LookupModel[] = [];
  strengthLookupCopy: LookupModel[] = [];
  doseLookupCopy: LookupModel[] = [];
  rxfrequencyCopy: LookupModel[] = [];
  refilLookupCopy: LookupModel[] = [];
  farNearLookupCopy: LookupModel[] = [];
  routeLookupCopy: LookupModel[] = [];
  rxNumberLookup: LookupModel[] = [];
  rxNumberLookupCopy: LookupModel[] = [];
  examModel : ExamModel = new ExamModel();
  userSelected: number = 0;
  office: OfficeModel[] = [];
  rowStyle: any;
  rowPatient : PatientModel[] = [];
  check_value: string = '';
  officename:string='';
  officeaddress:string='';
  officephone:string='';
  officeCitySZ:string='';
  officeemail: string = '';
  office_npi_txt: string = '';
  currentDate: Date = new Date();
  rx_provider_id: string = '';
  allLookupData: LookupModel[] = [];

  constructor(private _userService: UserService, private activateRoute: ActivatedRoute, private _clinicalService: ClinicalService, private _patientService: PatientService, private toaster: ToastrService, private globalService: GlobalService, private _lookupService: LookupService,private viewportScroller: ViewportScroller) {
    this.activateRoute.queryParams.subscribe((params: any) => {
      if (params['examId']) {
        this.examId = params['examId'];
      }
    });
    this.patientData = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientData.patient_id;
    this.allLookupData = JSON.parse(localStorage.getItem('AllLookupData')!);
  }

  ngOnInit() {

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

    this.viewportScroller.scrollToPosition([0, 0]);
    this.rowStyle = { height: '40px !important' };
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    // this.getUser();
    this.getExam();
    //this.getMedRx();
    // this.getUser();
    //this.getOffice();
    this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.columnMedicalHistory = [
      {
        headerName: 'Date Started',
        field: 'date_started_dt', sortable: true, resizable: true, rowDrag: false, filter: true, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        },
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Product',
        field: 'product_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'RxNorm',
        field: 'rx_norm_code_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Generic Name',
        field: 'generic_name_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Brand',
        field: 'brand_name_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Strength',
        field: 'strength_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Dose',
        field: 'dose_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Frequency',
        field: 'frequency_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: '#',
        field: 'rx_norm_code_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Refill',
        field: 'refill_times_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Status',
        field: 'status_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellStyle: this.rowStyle
      },
      {
        headerName: 'Date Modified',
        field: 'updated_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      }
    ];

    // if (this.exam_MedRXModel.doctorID=='0') {
    //  var display_text="Loading...";
    //  this.exam_MedRXModel.doctorID=(display_text);
    // }
    this.getRx();
    this.getAllLookup();

    // var startDate = this.exam_MedRXModel.expirationDate;
    // var numOfYears = 1;
    // var expireDate = new Date(startDate);
    // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);
    // this.exam_MedRXModel.expirationDate = expireDate;
  }

  getOffice() {
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;

      this.office = this.office.filter(x => x.office_id == this.patientData.defaultOfficeID);
      if (this.office[0]?.name_txt != null) {
        this.officename = this.office[0]?.name_txt
      }
      // if (this.office[0]?.address_1_txt || this.office[0].address_2_txt) {
      //   this.officeaddress = `${this.office[0]?.address_1_txt} ${this.office[0]?.address_2_txt}`;
      // }
      if (this.office[0]?.address_1_txt) {
        this.officeaddress = `${this.office[0]?.address_1_txt}`;
      }
      if(this.office[0]?.address_2_txt){
        this.officeaddress = `${this.officeaddress} ${this.office[0]?.address_2_txt}`;
      }
      if(this.office[0]?.city_txt){
        this.officeCitySZ = `${this.office[0]?.city_txt}, ${this.office[0]?.state_txt} ${this.office[0]?.zip_txt}`;
      }
      if (this.office[0]?.phone_1_txt) {
        this.officephone = this.office[0]?.phone_1_txt;
      }
      if(this.office[0]?.npi_txt){
        this.office_npi_txt = this.office[0]?.npi_txt;
      }
      if(this.office[0]?.email_address_txt){
        this.officeemail = this.office[0]?.email_address_txt;
      }
    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  getOfficeNamefromId(id: string): string
  {
    let office = this.office.find(a => a.office_id == id);
    if(office)
    {
      return office.name_txt;
    }
    return "";
  }

  getUser() {
    debugger;
    this.userList = JSON.parse(localStorage.getItem('Provider')!);
    if(!this.examModel.provider_ID){
      this.examModel.provider_ID = this.patientDetail.referring_provider_id;
    }
    this.userdata = this.userList.find(a => a.user_id == this.examModel.provider_ID);
    this.user = this.userdata;
    if (this.userdata?.npi_txt != null) {
      this.License = this.userdata?.npi_txt
    }
    // if (this.userdata?.email_address_txt) {
    //   this.officeemail = this.userdata?.email_address_txt;
    // }
    // if (this.exam_MedRXModel.doctorID=='0') {
    //   if(this.userdata){
    //     this.onSelectUser(this.userdata.qeUserID);
    //     this.exam_MedRXModel.doctorID=this.userdata.qeUserID;
    //   }else{
    //     this.onSelectUser(0);
    //     this.exam_MedRXModel.doctorID= "0";
    //   }
    // }
    // else{
    //   this.onSelectUser(this.exam_MedRXModel.doctorID);
    // }
    // if (this.exam_MedRXModel.doctorID == '0') {
    //   this.onSelectUser(this.examModel.provider_ID);
    // }
    // else{
    //   if(this.exam_MedRXModel.doctorID){
    //     this.onSelectUser(this.exam_MedRXModel.doctorID);
    //   }else{
    //     this.onSelectUser(this.examModel.provider_ID);
    //   }

    // }
    if(this.examModel.rx_provider_id == "00000000-0000-0000-0000-000000000000"){
      this.examModel.rx_provider_id = '';
    }
    this.onSelectUser(this.examModel.rx_provider_id);
    this.getOffice();
  }

  // onSelectUser(id: any) {

  //   this.user = this.userList.find(a => a.qeUserID == id);
  //   if (this.user) {
  //     this.Userid = this.user.qeUserID;
  //     this.imgURL = this.user?.signature_img ? 'data:image/png;base64,' + this.user.signature_img : '';
  //   } else {
  //     this.Userid = 0;
  //     this.imgURL = '';
  //     this.user = new UserModel();
  //   }
  //   return true;
  // }
  onSelectUser(id: any) {
    debugger;
    this.user = this.userList.find(a => a.user_id == id);
    if(!this.user){
      this.user = this.userList.find(a => a.qeUserID == id);
    }
    if (this.user) {
      if (this.user.npi_txt) {
        this.License = this.user.npi_txt
      }
      this.Userid = this.user.qeUserID;
      this.imgURL = this.user?.signature_img ? 'data:image/png;base64,' + this.user.signature_img : '';
    } else {
      this.Userid = 0;
      this.imgURL = '';
      this.user = new UserModel();
    }
    return true;
  }

  onLoadUser(id: any)
  {
    this.user = this.userList.find(a => a.user_id == id);
    if(!this.user){
      this.user = this.userList.find(a => a.qeUserID == Number(id));
    }
    if (this.user)
    {
      this.userSelected = this.user.qeUserID;
      this.exam_MedRXModel.doctorID = this.user.qeUserID;
      //this.exam_MedRXModel.doctorID = this.user.user_id;
      this.imgURL = this.user?.signature_img ? 'data:image/png;base64,' + this.user.signature_img : '';
    }
    else
    {
      this.imgURL = '';
      this.user = new UserModel();
    }
  }

  getMedRx()
  {
    this._clinicalService.getByMedRxexamID(this.patientData.patientID,this.examId).subscribe((res: any) => {
      debugger;
      if(res == null)
      {
        //this.exam_MedRXModel = new Exam_MedRXModel();
        // var date  = this.exam_MedRXModel.expirationDate.setFullYear(this.exam_MedRXModel.expirationDate .getFullYear() + 1);
        // var d = new Date();
        // var year = d.getFullYear() + 1;
        // var month = d.getMonth();
        // var day = d.getDate();
        // this.exam_MedRXModel.expirationDate = new Date(month, day);
        //this.exam_MedRXModel.expirationDate=new Date(date);

        // var startDate = this.examModel.exam_Date;
        // var numOfYears = 1;
        // var expireDate = new Date(startDate);
        // expireDate.setFullYear(expireDate.getFullYear() + numOfYears);

        // var dat = new Date(startDate);
        // var year = dat.getUTCFullYear() + 1
        // var month = dat.getUTCMonth() + 1;
        // var day = dat.getUTCDate();
        // var expireDate = new Date(`${month}/${day}/${year}`);

        // var date = this.examModel.exam_Date;
        // var targetTime = new Date(date);
        // targetTime.setFullYear(targetTime.getFullYear() + 1);
        // var timeZoneFromDB = -(new Date().getTimezoneOffset() / 60); //time zone value from database
        // //get the timezone offset from local time in minutes
        // var tzDifference = timeZoneFromDB * 60 + targetTime.getTimezoneOffset();
        // //convert the offset to milliseconds, add to targetTime, and make a new Date
        // var offsetTime = new Date(targetTime.getTime() + tzDifference * 60 * 1000);

        // this.exam_MedRXModel.expirationDate = offsetTime;
        this.exam_MedRXModel.expirationDate = this.examModel.omR_ContactRX.expirationDate;
      }
      else
      {
        this.exam_MedRXModel = res;
        // var d = new Date();
        // var year = d.getFullYear();
        // var month = d.getMonth();
        // var day = d.getDate();
        // this.exam_MedRXModel.expirationDate = new Date(year + 1, month, day);
        this.exam_MedRXModel.expirationDate = res.expirationDate;
        this.onLoadUser(this.exam_MedRXModel.doctorID);
        //this.onSelectUser(this.exam_MedRXModel.doctorID);
        // if (this.exam_MedRXModel.doctorID == '0') {
        //   this.onSelectUser(this.examModel.provider_ID);
        // }
        // else{
        //   this.onSelectUser(this.exam_MedRXModel.doctorID);
        // }
        this.onSelectUser(this.examModel.rx_provider_id);
      }
      this.getUser();
    })
  }

  getExam()
  {

    this._clinicalService.getByExamId(this.examId).subscribe(res=>{
      this.examModel = res;
      this.rx_provider_id = res.rx_provider_id;
      //this.getUser();
      this.getMedRx();
    })
  }

  // getPatientData() {
  //   this._patientService.getPatientByPatientId(this.patientId).subscribe((res: any) => {
  //     this.patientData = Object.assign({}, res);
  //     this.calculateAge(this.patientData.dateOfBirth);
  //   }, (error) => {
  //     this.toaster.error(error.error);
  //   }
  //   );
  // }

  calculateAge(birthdate: Date) {
    let timeDiff = Math.abs(Date.now() - birthdate.getTime());
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
  }

  addRow() {

    var rxModel = new RXModel()
    rxModel.patient_id = this.patientId;
    rxModel.number_val = this.rxModel.length + 1;
    // rxModel.original_rx_id = this.examModel.exam_ID;
    rxModel.record_id = this.examModel.exam_ID;
    rxModel.record_type = "Exam";
    this.rxModel.push(rxModel);
    //this.rxModel = Object.assign([], this.newRXModel);
  }

  removeRow(index: number) {

    this.newRXModel.splice(index, 1);
    this.rxModel = Object.assign([], this.newRXModel);
  }

  saveExamMedRx() {
    debugger;
    this.exam_MedRXModel.exam_MedRX_ID = this.examModel.exam_ID;
    this.exam_MedRXModel.PatientID = this.patientData.patientID;

    // this._clinicalService.saveMedRx(this.exam_MedRXModel).subscribe((res: any) => {
    //   this.exam_MedRXModel = res;
    //   //this.rxModel[0].original_rx_id=this.examModel.exam_ID;
    //   this.rxModel[0].record_id = this.examModel.exam_ID;
    //   this.rxModel[0].record_type = "Exam";
    //   this.rxModel[0].date_started_dt = this.examModel.exam_Date;
    //   this.saveRx();
    // });
    if(this.rx_provider_id == this.examModel.rx_provider_id){
      this.rxModel.forEach(element => {
        // element.original_rx_id = this.examModel.exam_ID;
        // element.date_started_dt = this.examModel.exam_Date;
        element.record_id = this.examModel.exam_ID;
        element.record_type = "Exam";
        element.date_started_dt = typeof this.examModel.exam_Date == 'string' ? this.examModel.exam_Date : this.examModel.exam_Date.toDateString();
      });
      this.saveRx();
    }else{
      this._clinicalService.PatchExam(this.examModel).subscribe((res: any) => {
        debugger;
        this.examModel = res;
        //this.rxModel[0].original_rx_id=this.examModel.exam_ID;
        // this.rxModel[0].record_id = this.examModel.exam_ID;
        // this.rxModel[0].record_type = "Exam";
        // this.rxModel[0].date_started_dt = this.examModel.exam_Date;
        this.rxModel.forEach(element => {
          // element.original_rx_id = this.examModel.exam_ID;
          // element.date_started_dt = this.examModel.exam_Date;
          element.record_id = this.examModel.exam_ID;
          element.record_type = "Exam";
          element.date_started_dt = typeof this.examModel.exam_Date == 'string' ? this.examModel.exam_Date : this.examModel.exam_Date.toDateString();
        });
        this.saveRx();
      });
    }

  }

  updateExamMedRx() {
    debugger;
    // this.exam_MedRXModel.exam_MedRX_ID = this.examModel.exam_ID;
    // this.exam_MedRXModel.PatientID = this.patientData.patientID;
    // this._clinicalService.updateMedRx(this.exam_MedRXModel).subscribe((res: any) => {
    //   this.exam_MedRXModel = res;
    //   this.rxModel.forEach(element => {
    //     element.original_rx_id = this.examModel.exam_ID;
    //     element.date_started_dt = this.examModel.exam_Date;
    //   });
    //   this.saveRx();
    // });
    this.exam_MedRXModel.exam_MedRX_ID = this.examModel.exam_ID;
    this.exam_MedRXModel.PatientID = this.patientData.patientID;
    if(this.rx_provider_id == this.examModel.rx_provider_id){
      this.rxModel.forEach(element => {
        // element.original_rx_id = this.examModel.exam_ID;
        // element.date_started_dt = this.examModel.exam_Date;
        element.record_id = this.examModel.exam_ID;
        element.record_type = "Exam";
        element.date_started_dt = typeof this.examModel.exam_Date == 'string' ? this.examModel.exam_Date : this.examModel.exam_Date.toDateString();
      });
      this.saveRx();
    }else{
      this._clinicalService.PatchExam(this.examModel).subscribe((res: any) => {
        debugger;
        this.examModel = res;
        //this.rxModel[0].original_rx_id=this.examModel.exam_ID;
        // this.rxModel[0].record_id = this.examModel.exam_ID;
        // this.rxModel[0].record_type = "Exam";
        // this.rxModel[0].date_started_dt = this.examModel.exam_Date;
        this.rxModel.forEach(element => {
          // element.original_rx_id = this.examModel.exam_ID;
          // element.date_started_dt = this.examModel.exam_Date;
          element.record_id = this.examModel.exam_ID;
          element.record_type = "Exam";
          element.date_started_dt = typeof this.examModel.exam_Date == 'string' ? this.examModel.exam_Date : this.examModel.exam_Date.toDateString();
        });
        this.saveRx();
      });
    }

    // this._clinicalService.updateMedRx(this.exam_MedRXModel).subscribe((res: any) => {
    //   this.exam_MedRXModel = res;
    //   this.rxModel.forEach(element => {
    //     element.original_rx_id = this.examModel.exam_ID;
    //     element.date_started_dt = this.examModel.exam_Date;
    //   });
    //   this.saveRx();
    // });
  }

  toggleCheckbox(event: any) {
  }

  save(isNavigate: boolean) {
    this.exam_MedRXModel.doctorID = this.user.qeUserID;
    //this.exam_MedRXModel.doctorsSignature = this.imgURL;
    this.exam_MedRXModel.License = this.user.license_txt;
    this.exam_MedRXModel.DoctorName = this.user.first_name_txt + ' ' + this.user.last_name_txt;
    if (this.exam_MedRXModel.exam_MedRX_ID == "" || this.exam_MedRXModel.exam_MedRX_ID==undefined) {
      this.saveExamMedRx();
    }
    else {
      this.updateExamMedRx();
    }
    if (isNavigate) {
      this._clinicalService.navigateToClinic();
    }
  }

  getRx() {
    this._clinicalService.getdataRx(this.examId,this.patientId).subscribe((res: any) => {

      this.rowMedicalHistory = res;
      this.rxModel = res;
    });
  }

  saveRx() {
    this._clinicalService.saveRx(this.rxModel).subscribe(res => {
      // this.rxModel = [];
      this.getRx();
      this.toaster.success('Record Saved Successfully.');
    });
  }
  compare(array: LookupModel[]) {
    array.sort((a, b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))) {
        if (Number(a.name_txt) > Number(b.name_txt)) {
          return 1;
        } else if (Number(a.name_txt) < Number(b.name_txt)) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()) {
          return 1;
        } else if (a.name_txt.toLowerCase() < b.name_txt.toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }

  getSpecificLookup(txt: string){
    let listData = this.allLookupData.filter(x => x.group_txt.toLowerCase() == txt.toLowerCase() && x.active_ind);
    return listData;
  }
  getConfigLookup(){
    let routeLookup = this.getSpecificLookup('rxroute');
    var combineRouteLookups = this.routeLookup.concat(routeLookup);
    this.routeLookup = combineRouteLookups;
    this.routeLookup = this.compare(this.routeLookup);
  }

  getAllLookup() {

    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.productLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxproduct' .toLowerCase() && x.active_ind == true);
      this.productLookup = this.productLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.productLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxproduct'.toLowerCase() );
      this.genericLookup = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname' && x.active_ind == true);
      this.genericLookup = this.genericLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.genericLookupCopy = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname' );
      this.brandLookup = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'brandname' && x.active_ind == true);
      this.brandLookup = this.brandLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.brandLookupCopy = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'brandname' );
      // this.genericLookup = res.filter(x => x.group_txt.toLowerCase() == 'medicationname'.toLowerCase() && x.sub_group_txt.toLowerCase() == 'genericname'.toLowerCase());
      // this.genericLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'medicationname'.toLowerCase() && x.sub_group_txt.toLowerCase() == 'genericname'.toLowerCase());
      // this.brandLookup = res.filter(x => x.group_txt.toLowerCase() == 'medicationname'.toLowerCase() && x.sub_group_txt.toLowerCase() == 'brandname'.toLowerCase());
      // this.brandLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'medicationname'.toLowerCase() && x.sub_group_txt.toLowerCase() == 'brandname'.toLowerCase());
      this.strengthLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxstrength'.toLowerCase()&& x.active_ind == true);
      this.strengthLookup = this.strengthLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.strengthLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxstrength'.toLowerCase() );
      this.doseLookup = res.filter(x => x.group_txt.toLowerCase() == 'dose'.toLowerCase() && x.active_ind == true);
      this.doseLookup = this.doseLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.doseLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'dose'.toLowerCase() );
      this.rxfrequency = res.filter(x => x.group_txt.toLowerCase() == 'rxfrequency'.toLowerCase() && x.active_ind == true);
      this.rxfrequency = this.rxfrequency.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.rxfrequencyCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxfrequency'.toLowerCase());
      this.refilLookup = res.filter(x => x.group_txt.toLowerCase() == 'refill'.toLowerCase() && x.active_ind == true);
      this.refilLookup = this.refilLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.refilLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'refill'.toLowerCase());
      this.farNearLookup = res.filter(x => x.group_txt.toLowerCase() == 'VisualAcuities'.toLowerCase() && x.active_ind == true);
      this.farNearLookup = this.farNearLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.farNearLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'VisualAcuities'.toLowerCase());
      this.rxNumberLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxnumber'.toLowerCase() && x.active_ind == true);
      this.rxNumberLookup = this.rxNumberLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.rxNumberLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxnumber'.toLowerCase());
      this.routeLookup = res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase()  && x.active_ind == true);
      this.routeLookup = this.routeLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.routeLookupCopy = res.filter(x => x.group_txt.toLowerCase() == 'rxroute'.toLowerCase());
      // this.showComponent = true;
      this.getConfigLookup();
    })
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getAllLookup();
      // this.sortArray(item)
    })
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  CreateNew(city:string){
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any,name_txt: string, item: LookupModel[], group_txt?: string,item1?: any, property?: any, sub_group_txt?: string, dbType: number = DbEnum.QE_DEV_SQL_DB) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item.length > 0) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = (sub_group_txt!);
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }
      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {
            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getAllLookup();
              this.toaster.success('Record Saved Successfully.');
              item1[property]= val;//this.check_value;

            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toaster.success('Record Saved Successfully.');
          //     item1[property]=this.check_value;
          //     this.getAllLookup();
          //   })
          // }
        }
      })
    }
    item1[property] = val;
  } else {
    item1[property] = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }
  exportPatients()
  {
    this._patientService.getPatientList('').subscribe((res: PatientModel[]) => {
      let csvArray ='';
      res.forEach(element => {
        csvArray = csvArray + '\r\n';

        csvArray = csvArray + (element.patientAcctNo == null ? '' : element.patientAcctNo)
        + ',' + (element.firstName == null ? '' : element.firstName)
        + ',' + (element.middleInitial == null ? '' : element.middleInitial)
        + ',' + (element.lastName == null ? '' : element.lastName)
        + ',' + (element.address == null ? '' : element.address)
        + ',' + (element.address2 == null ? '' : element.address2)
        + ',' + (element.city == null ? '' : element.city)
        + ',' + (element.zip == null ? '' : element.zip)
        + ',' + (element.emailaddress == null ? '' : element.emailaddress)
        + ',' + (element.dateOfBirth == null ? '' : element.dateOfBirth);
      });
      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, "myFile.csv");
    });
  }

  DeleteRx(event:RXModel,index:number)
  {

    this.rxModel.splice(index, 1);
    this._clinicalService.deleterx(event.rx_id).subscribe(res => {
      this.getRx();
      this.toaster.success('Record Deleted Successfully.');
    });
  }
}
