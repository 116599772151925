<form>
    <fieldset class="form">
        <div id="content" class="container" style="height: 50%;width: 40% ;padding-bottom: 4px;">
            <div class="titlebox">Office</div>
            <div class="d-flex justify-content-center mt-2">
                <!-- <b>Lafayette Foot and Ankle Clinic</b> -->
                <b>{{officename}}</b>
            </div>
            <div class="d-flex justify-content-center">
                <!-- 2700 S Lafayette St STE 230 -->
                {{officeaddress}}
            </div>
            <div class="d-flex justify-content-center">
                <!-- Ft Wayne, IN 46806-1100 -->
                {{officeCitySZ}}
            </div>
            <div class="d-flex justify-content-center">
                <!-- 1(816) 123-4567 -->
                {{officephone}}
            </div>
            <div class="d-flex justify-content-center">
              NPI # {{office_npi_txt}}
            </div>
            <!-- <div class="d-flex justify-content-center">
                NPI # {{License}}
            </div> -->
        </div>
        <div class="row pr-5 pl-5 pt-5">
            <div class="row col-md-2 col-sm-3">
                <label>Patient Name:</label>
            </div>
            <div class="row col-md-2 col-sm-3">
                <label>{{patientData.firstName}} {{patientData.lastName}}</label>
            </div>
            <div class="row col-md-2 col-sm-3" *ngIf="age>0">
                <label>Age:</label>
            </div>
            <div class="row col-md-2 col-sm-3" *ngIf="age>0">
                <label>{{age}}</label>
            </div>
        </div>
        <div class="row pr-5 pl-5">
            <div class="row col-md-2 col-sm-3">
                <label>Address:</label>
            </div>
            <div class="row col-md-8 col-sm-9">
                <div class="row">
                    <div *ngIf="patientData.address">
                        <label>{{patientData.address}}</label>
                    </div>
                    <div *ngIf="patientData.city">
                        <label>-{{patientData.city}},</label>
                    </div>
                    <div *ngIf="patientData.state">
                        <label>{{patientData.state}}</label>
                    </div>
                    <div *ngIf="patientData.zip">
                        <label>-{{patientData.zip}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pr-5 pl-5">
            <div class="row col-md-2 col-sm-3">
                <label>Date:</label>
            </div>
            <div class="row col-md-6 col-sm-8 ">
                <label>{{patientData.dateOfEntry | date}}</label>
            </div>
        </div>
        <div class="row p-2">
            <div class="col-md-2 col-sm-5">
                <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
                    <a href="https://eprescribe.allscripts.com/Login.aspx" target='_blank' style="color: white;">ePrescribe
                        Now</a>
                </button>
            </div>
            <div class="col-md-4">
                <div id="content" style="height: 100%;width: 90% ;padding-bottom: 4px;">
                    <div class="titlebox">ePrescribe Administration
                    </div>
                    <br>
                    <div class="d-flex justify-content-center p-2">
                        <button (click)="exportPatients()" class="btn btn-primary" style="border-radius: 20px;color: blue;">
                            Export Patients to ePrescribe
                        </button>&nbsp;
                        <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
                            <a href="https://demo.quikeyes.com/Materials/ePrescribeManual.pdf" target='_blank'
                                style="color: white;">ePrescribe User Manual</a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
</form>
