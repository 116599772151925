import { LookupService } from 'src/app/services/lookup.service';
import { GlobalService } from 'src/app/services/global.service';
import { ToastrService } from 'ngx-toastr';
import { AttachmentModel } from './../../../../model/AttachmentModel';
import { PatientModel } from './../../../../model/patientModel';
import { ClinicalService } from './../../../../services/clinical.service';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddAttachmentComponent } from '../add-attachment/add-attachment.component';
import * as moment from 'moment';
import { LookupModel } from 'src/app/model/lookupModel';
import { PatientInsuranceModel } from 'src/app/model/patientInsuranceModel';
import { AnyPtrRecord } from 'dns';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  columnDocuments: any;
  rowDocuments: AttachmentModel[] = [];
  attachmentList : AttachmentModel[] = [];
  examId !: string;
  bsModalRef!: BsModalRef;
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  filterString : string = '';
  attachmentGrid: any;
  deleteAttachmentList : AttachmentModel[] = [];
  attachmentLookup : LookupModel[] = [];
  check_value: string = '';
  base64Image: any;

  constructor(private modalService: BsModalService,private _clinicalService : ClinicalService,
    private toastr : ToastrService,private _lookupService : LookupService,
    private globalService : GlobalService) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.patientId = this.patientModel.patient_id;
   }

  ngOnInit() {    
    this.columnDocuments = [
      {
        headerName: 'Date',
        field: 'created_dt', sortable: true , resizable: true, rowDrag: true, filter: true, floatingFilter: false, width: 150, sort: 'desc',
        cellRenderer: (data:any) => {
          return moment(data.value).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Attachment Type',
        field: 'type_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 300,
        cellEditor: 'agRichSelectCellEditor',                     
            editable: true,
      },
      {
        headerName: 'Description',
        field: 'description_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 300
      },
      {
        headerName: 'Hash',
        field: 'hash_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 300
      }
    ];
    this.getLookup();
    this.getAttachment();
  }

  getId() { 
    this.deleteAttachmentList = this.attachmentGrid.getSelectedRows();
  }

  deleteAttachments() { 
    this._clinicalService.deleteAttachment(this.deleteAttachmentList).subscribe(res=>{
      this.toastr.success('Record Deleted Successfully.');
      this.getAttachment();
    })
  }

  addAttachment(){
    const initialState = {
      title: 'Attachment'
    };
    this.bsModalRef = this.modalService.show(AddAttachmentComponent, {initialState});
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getAttachment();
    })
  }

  getAttachment(){
    this._clinicalService.getAttachment(this.patientId).subscribe((res:any)=>{
      this.attachmentList = res;
      this.filterAttachment(this.filterString);
    })
  }

  filterAttachment(filterString:string){    
    if(filterString == '' || filterString == '-- Select --'){
      this.rowDocuments = Object.assign([],this.attachmentList);
    }
    else{
      this.rowDocuments = this.attachmentList.filter(x=>x.type_txt ==filterString);
    }
  }

  onGridReady(params:any){
    this.attachmentGrid = params.api;
  }

  save(){
    this._clinicalService.updateAttachment(this.rowDocuments).subscribe(res=>{
      this.toastr.success('Record Updated Successfully.');
      this.getAttachment();
    })
  }

  CreateNew(city:string){          
  }

  keyFunc(event: any, item1?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1 = event.target.value;
    }
  }
  saveDropdown(val: any,  name_txt: string, item: LookupModel[], group_txt?: string,sub_group_txt:string = 'All', dbType: number = DbEnum.QE_DEV_SQL_DB,item1?: any) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0)  {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = 'All';
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }
      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {
            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getLookup();
              this.toastr.success('Record Saved Successfully.');
              item1= val;//this.check_value;              
            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toastr.success('Record Saved Successfully.');
          //     item1=this.check_value;
          //     this.getLookup();
          //   })
          // }
        }
      })
    }
    item1 = val;
  } else {
    item1 = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getLookup()
      // this.sortArray(item)
    })
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }

  

  getLookup(){
    this._lookupService.getGlobalLookup('attachmenttype').subscribe((res:LookupModel[])=>{
      this.attachmentLookup = res.filter(x=>x.sub_group_txt == 'Patient'&& x.active_ind == true);
      this.attachmentLookup = this.attachmentLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });

    })
  }

  downloadFile(item:any){
    
    // const file = new Blob([content], {type: 'text/plain'});
    // FileSaver.saveAs(file, "test.txt");
    if(item.data.record_type_txt != "pdf")
    {
      this.base64Image = "data:"+item.data.record_type_txt+";base64," + item.data.file_dat;
      // save image to disk
      var link = document.createElement("a");
      document.body.appendChild(link); // for Firefox
      link.setAttribute("href", this.base64Image);
      link.setAttribute("download", item.data.path_resource_txt);
      link.click(); 
    }
    else
    {
      this.downloadPdf(item.data.file_dat, item.data.path_resource_txt);
    }
  }
  downloadPdf(base64String:string, fileName:string) {
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }
}
