<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" routerLink="/patient/add-exam" [queryParams]="{patientId:patientDetail.patientID,examId : examId}" routerLinkActive="active">
                <!-- <img src="../../../../assets/img/close.png" style="height: 30px;width: 30px;"> -->
                Cancel Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(false)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(true)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save & Close
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" [disabled]="examId == ''" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
                <!-- <img src="../../../../assets/img/print_printer.png" style="height: 30px;width: 30px;"> -->
                Print
            </button> &nbsp;&nbsp;
            <hr>
        </div>
    </div>
</div>
<br>
<fieldset>
    <br>
    <br>

        <div class="field">
            <div class="titlebox"></div>
            <div id="content">
                <div class="titlebox"></div>
                <div class="row">
                    <!-- <div class="row col-md-2">
                        <label style="margin-left:-85px ;">{{patientModel.dateOfBirth | date:'MM-dd-yyyy'}}</label>
                    </div> -->
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">Color Vision</span></b>
                    </div>
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">Stereo/Fusion</span></b>
                    </div>
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">Amsler Grid</span></b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label><input type="checkbox" class="" [(ngModel)]="examModel.omR_ExtraTests.colorVisionOD"> OD Normal</label>
                    </div>
                    <div class="col-md-3">
                        <label><input type="checkbox" class="" [(ngModel)]="examModel.omR_ExtraTests.stereoFusion"> Normal</label>
                    </div>
                    <div class="col-md-3">
                        <label><input type="checkbox" class="" [(ngModel)]="examModel.omR_ExtraTests.amslerGrid"> Normal</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label><input type="checkbox" class="" [(ngModel)]="examModel.omR_ExtraTests.colorVisionOS"> OS Normal</label>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>Other</label>
                            </div>
                            <div class="col-md-10">
                                <div class="form-row">
                                    <ng-select (keyup)="keyFunc($event,examModel.omR_ExtraTests,'stereoFusionOther')" addTagText="Add Item" [addTag]="CreateNew" [items]="otherLookup" class="col-md-8" (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.stereoFusionOther,'Other',otherLookup,'extratestsnotesother',examModel.omR_ExtraTests,'stereoFusionOther', dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.stereoFusionOther">
                                    </ng-select>
                                    <a href="javascript:void(0)" (click)="editLookup(otherLookup,otherCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>Other</label>
                            </div>
                            <div class="col-md-10">
                                <div class="form-row">
                                    <ng-select  addTagText="Add Item" [addTag]="CreateNew"  [items]="otherLookup" class="col-md-8" (keyup)="keyFunc($event,examModel.omR_ExtraTests,'amslerGridOther')" (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.amslerGridOther,'Other',otherLookup,'extratestsnotesother',examModel.omR_ExtraTests,'amslerGridOther',dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.amslerGridOther">
                                    </ng-select>
                                    <a href="javascript:void(0)" (click)="editLookup(otherLookup,otherCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>Other</label>
                            </div>
                            <div class="col-md-10">
                                <div class="form-row">
                                    <ng-select addTagText="Add Item" [addTag]="CreateNew" [items]="otherLookup" class="col-md-8"
                                    (keyup)="keyFunc($event,examModel.omR_ExtraTests,'colorVisionOther')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.colorVisionOther,'Other',otherLookup,'extratestsnotesother',examModel.omR_ExtraTests,'colorVisionOther',dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.colorVisionOther">
                                    </ng-select>
                                    <a href="javascript:void(0)" (click)="editLookup(otherLookup,otherCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content" class="my-4">
                <div class="titlebox"></div>
                <div class="row">
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">Dist Phoria</span></b>
                    </div>
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">Near Phoria</span></b>
                    </div>
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">Pinhole VA</span></b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>LAT</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.distPhoriaLAT" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>LAT</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.nearPhoriaLAT" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>OD/20</label>
                            </div>
                            <div class="col-md-10">
                                <div class="form-row">
                                    <ng-select addTagText="Add Item" [addTag]="CreateNew" [items]="farNearLookup" class="col-md-8"
                                    (keyup)="keyFunc($event,examModel.omR_ExtraTests,'pinholeVAOD')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.pinholeVAOD,'OD/20',farNearLookup,'VisualAcuities',examModel.omR_ExtraTests,'pinholeVAOD')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.pinholeVAOD">
                                    </ng-select>
                                    <a href="javascript:void(0)" (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>VERT</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.distPhoriaVERT" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>VERT</label>
                            </div>
                            <div class="col-md-10">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.nearPhoriaVERT" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-2">
                                <label>OS/20</label>
                            </div>
                            <div class="col-md-10">
                                <div class="form-row">
                                    <ng-select addTagText="Add Item" [addTag]="CreateNew" [items]="farNearLookup" class="col-md-8"
                                    (keyup)="keyFunc($event,examModel.omR_ExtraTests,'pinholeVAOS')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.pinholeVAOS,'OS/20',farNearLookup,'VisualAcuities',examModel.omR_ExtraTests,'pinholeVAOS')" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.pinholeVAOS">
                                    </ng-select>
                                    <a href="javascript:void(0)" (click)="editLookup(farNearLookup,farNearCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content" class="my-4">
                <div class="titlebox"></div>
                <div class="row">
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">BLD Press</span></b>
                    </div>
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important; padding: 10px;">Pupil Dist</span></b>
                    </div>
                    <div class="col-md-3">
                        <b><span style="font-size: 16px !important;">SEG Height</span></b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 form-row">
                        <div class="col-md-5">
                            <input type="text" class="form-control col-md-12" [(ngModel)]="examModel.omR_ExtraTests.bldPressSYS">
                        </div>
                        <div class="col-md-2 text-center">
                            <label class="">/</label>
                        </div>
                        <div class="col-md-5">
                            <input type="text" class="form-control col-md-12" [(ngModel)]="examModel.omR_ExtraTests.bldPressDIAS">
                        </div>
                    </div>
                    <div class="col-md-3 form-row">
                        <div class="col-md-5">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:97%">
                                        <ng-select addTagText="Add Item" [addTag]="CreateNew" class="col-md-12" style="margin-right: 10%;"  [items]="pupilsLookup"
                                        (keyup)="keyFunc($event,examModel.omR_ExtraTests,'pupilDistDIST')"
                                        (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.pupilDistDIST,'Pupil Dist',pupilsLookup,'pupildist',examModel.omR_ExtraTests,'pupilDistDIST', dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.pupilDistDIST">
                                        </ng-select>
                                    </td>
                                    <td style="width:3%">
                                        <a href="javascript:void(0)" (click)="editLookup(pupilsLookup,pupilsCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-2 text-center">
                            <label class="">/</label>
                        </div>
                        <div class="col-md-5">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:97%">
                                        <ng-select addTagText="Add Item" [addTag]="CreateNew" class="col-md-12"  [items]="pupilsLookup"
                                        (keyup)="keyFunc($event,examModel.omR_ExtraTests,'pupilDistNEAR')"
                                        (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.pupilDistNEAR,'Pupil Dist',pupilsLookup,'pupildist',examModel.omR_ExtraTests,'pupilDistNEAR',dbEnum.QE_DEV_SQL_DB)" bindLabel="name_txt" bindValue="name_txt"  [(ngModel)]="examModel.omR_ExtraTests.pupilDistNEAR">
                                        </ng-select>
                                    </td>
                                    <td style="width:3%">
                                        <a href="javascript:void(0)" (click)="editLookup(pupilsLookup,pupilsCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-3 form-row">
                        <div class="col-md-5">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:97%">
                                        <ng-select addTagText="Add Item" [addTag]="CreateNew" class="col-md-12"
                                        (keyup)="keyFunc($event,examModel.omR_ExtraTests,'segHeightOD')"
                                        (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.segHeightOD,'SEG Height',segtLookup,'seght',examModel.omR_ExtraTests,'segHeightOD',dbEnum.QE_DEV_SQL_DB)" [items]="segtLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.segHeightOD">
                                        </ng-select>
                                    </td>
                                    <td style="width:3%">
                                        <a href="javascript:void(0)" (click)="editLookup(segtLookup,segtCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-2 text-center">
                            <label class="">/</label>
                        </div>
                        <div class="col-md-5">
                            <table style="width:100%">
                                <tr>
                                    <td style="width:97%">
                                        <ng-select addTagText="Add Item" [addTag]="CreateNew" class="col-md-12"
                                        (keyup)="keyFunc($event,examModel.omR_ExtraTests,'segHeightOS')"
                                        (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.segHeightOS,'SEG Height',segtLookup,'seght',examModel.omR_ExtraTests, 'segHeightOS',dbEnum.QE_DEV_SQL_DB)" [items]="segtLookup" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="examModel.omR_ExtraTests.segHeightOS">
                                        </ng-select>
                                    </td>
                                    <td style="width:3%">
                                        <a href="javascript:void(0)" (click)="editLookup(segtLookup,segtCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <label class="col-md-5 text-center">SYS</label>
                        <label class="col-md-1 text-center">/</label>
                        <label class="col-md-5 text-center">DIAS</label>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-5 text-center">DIST</label>
                        <label class="col-md-1 text-center">/</label>
                        <label class="col-md-5 text-center">NEAR</label>
                    </div>
                    <div class="col-md-3">
                        <label class="col-md-5 text-center">OD</label>
                        <label class="col-md-1 text-center">/</label>
                        <label class="col-md-5 text-center">OS</label>
                    </div>
                </div>
            </div>
            <div id="content" class="">
                <div class="titlebox"></div>
                <div class="row">
                    <div class="col-md-1">
                        <b><span style="font-size: 16px !important;">Temperature</span></b>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" [(ngModel)]="examModel.omR_ExtraTests.temperature">
                    </div>
                    <div class="col-md-1">
                        <b><span style="font-size: 16px !important;">Pulse</span></b>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control" [(ngModel)]="examModel.omR_ExtraTests.pulse">
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12">
                                <b><span style="font-size: 16px !important;">Patient Last Reported</span></b>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Blood Sugar(mg/dl)</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" type="number" [(ngModel)]="examModel.omR_ExtraTests.bmi">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>HbA1c(%)</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" type="number" [(ngModel)]="examModel.omR_ExtraTests.hbac">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="content" class="my-4">
                <div class="titlebox"></div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-row">
                            <b><span style="font-size: 16px !important;">Aniseikonia</span></b>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>Dist Vert</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.aniseikoniaDistVert" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>Dist Horiz</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.aniseikoniaDistHoriz" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            <b><span style="font-size: 16px !important;">NPA</span></b>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>OD</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.npaod" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>OS</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.npaos" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>OU</label>
                            </div>
                            <div class="col-md-8">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.npaou" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-row">
                            &nbsp;
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>Near Point Working<br>Dist</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.nearPtWorkingDist" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>Dominant Eye</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.dominantEye" class="form-control">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-4">
                                <label>Worth Test</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" [(ngModel)]="examModel.omR_ExtraTests.worthTest" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    <div id="print-section" hidden class="pt-5 mt-2">
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officename"> {{officename}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
        </div>
        <div class="col-12 text-center">
            <span class="doc-info">NPI # {{office_npi_txt}}</span>
        </div>

        <!-- <div class="col-12 text-center doc-info">
            <span class="doc-info">NPI # {{License}}</span>
        </div> -->
        <br>
        <h4 class="text-center"><b>Extra Tests/Notes</b></h4><br>
        <div class="row col-12">
            <div class="col-6">
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0">
                        <label class="patient-info">Patient Name:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <label class="patient-reg-info">{{patientModel.firstName}} {{patientModel.lastName}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-3 pr-0">
                        <label class="patient-info">Birthdate:</label>
                    </div>
                    <div class="col-5 pl-0">
                        <label class="patient-reg-info">{{patientModel.dateOfBirth | date:'MM-dd-yyyy'}}</label>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <label class="patient-reg-info">{{currentDate | date:'MM-dd-yyyy'}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-3 pr-0 pl-0">
                        <label class="patient-info">Date of Service:</label>
                    </div>
                    <div class="col-7 pl-0">
                        <label class="patient-reg-info">{{examModel.exam_Date | date:'MM-dd-yyyy'}}</label>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-12" style="border: 1px solid black;"></div>
        <div class="field">
            <div class="row">
                <div class="col-3">
                    <b><span style="font-size: 16px !important;">Color Vision</span></b>
                </div>
                <div class="col-3">
                    <label><input type="checkbox" class="" [(ngModel)]="examModel.omR_ExtraTests.colorVisionOD"
                            style="margin-right: 30px;"> OD Normal</label><br>
                    <label><input type="checkbox" class="" style="margin-right: 30px;"
                            [(ngModel)]="examModel.omR_ExtraTests.colorVisionOS"> OS Normal</label>
                </div>
                <div class="col-2">
                    <label>Other:</label>
                </div>
                <div class="col-4">
                    <div class="form-row">
                        <input type="text"
                            (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.colorVisionOther,'Other',otherLookup,'extratestsnotesother',examModel.omR_ExtraTests,'amslerGridOther',dbEnum.QE_DEV_SQL_DB)"
                            [(ngModel)]="examModel.omR_ExtraTests.colorVisionOther" class="form-control col-sm-11"
                            style="margin-left: -130px;">

                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-3">
                    <b><span style="font-size: 16px !important;">Stereo/Fusion:</span></b>
                </div>
                <div class="col-3">
                    <label><input type="checkbox" class="" style="margin-right: 30px;"
                            [(ngModel)]="examModel.omR_ExtraTests.stereoFusion"> Normal</label>
                </div>
                <div class="col-2">
                    <label>Other:</label>
                </div>
                <div class="col-4">
                    <div class="form-row">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.stereoFusionOther"
                            class="form-control col-sm-11" style="margin-left: -130px;">
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-3">
                    <b><span style="font-size: 16px !important;">Amsler Grid</span></b>
                </div>
                <div class="col-3">
                    <label><input type="checkbox" class="" style="margin-right: 30px;"
                            [(ngModel)]="examModel.omR_ExtraTests.stereoFusion"> Normal</label>
                </div>
                <div class="col-2">
                    <label>Other:</label>
                </div>
                <div class="col-4">
                    <div class="form-row">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.amslerGridOther"
                            (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.amslerGridOther,'Other',otherLookup,'extratestsnotesother',examModel.omR_ExtraTests,'amslerGridOther',dbEnum.QE_DEV_SQL_DB)"
                            [(ngModel)]="examModel.omR_ExtraTests.amslerGridOther" class="form-control col-11"
                            style="margin-left: -130px;">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-2">
                <b><span style="font-size: 16px !important;">Dist Phoria:</span></b>
            </div>
            <div class="col-1">
                <label>LAT:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.distPhoriaLAT" class="form-control">
                </div>
            </div>
            <div class="col-1">
                <div class="form row">
                    <label> BLD Press:</label>
                </div>
            </div>
            <div class="col-1">
                <label>SYS:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.bldPressSYS" class="form-control">
                </div>
            </div>
        </div>
    <br>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-1">
                <label>VERT:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.distPhoriaVERT" class="form-control">
                </div>
            </div>
            <div class="col-1">
            </div>
            <div class="col-1">
                <label>DIAS:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.bldPressDIAS" class="form-control">
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-2">
                <b><span style="font-size: 16px !important;">Near Phoria</span></b>
            </div>
            <div class="col-1">
                <label>LAT:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.nearPhoriaLAT" class="form-control">
                </div>
            </div>
            <div class="col-1">
                <div class="form-row">
                    <label> Pupil Dist:</label>
                </div>
            </div>
            <div class="col-1">
                <label>DIST:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.pupilDistDIST" class="form-control">
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-1">
                <label>VERT:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.nearPhoriaVERT" class="form-control">
                </div>
            </div>
            <div class="col-1">
            </div>
            <div class="col-1">
                <label>NEAR:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.pupilDistNEAR" class="form-control">
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-2">
                <b><span style="font-size: 16px !important;">Pinhole VA</span></b>
            </div>
            <div class="col-1">
                <label>OD 20/:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.pinholeVAOD" class="form-control">
                </div>
            </div>
            <div class="col-1 px-0">
                <!-- <div class="form-row">
                    <label>SEG Height:</label>
                </div> -->
                <label>SEG Height:</label>
            </div>
            <div class="col-1">
                <label>OD:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" class="form-control"
                        (keydown.tab)="saveDropdown(examModel.omR_ExtraTests.segHeightOD,'SEG Height',segtLookup,'seght',examModel.omR_ExtraTests,'segHeightOD',dbEnum.QE_DEV_SQL_DB)"
                        [(ngModel)]="examModel.omR_ExtraTests.segHeightOD" />
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-1">
                <label>OS 20/:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.pinholeVAOS" class="form-control">
                </div>
            </div>
            <div class="col-1">
            </div>
            <div class="col-1">
                <label>/OS:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.segHeightOS" class="form-control">
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-1">
                <label>Height:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.height" class="form-control">
                </div>
            </div>
            <div class="col-1">
                <label>Temperature:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.temperature" class="form-control">
                </div>
            </div>
            <div class="col-1 px-0">
                <label>Blood Sugar:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.bmi" class="form-control" style="width: 95%;">
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-1">
                <label>Weight:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.weight" class="form-control">
                </div>
            </div>
            <div class="col-1">
                <label>Pulse:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.pulse" class="form-control">
                </div>
            </div>
            <div class="col-1">
                <label>Hba1c:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text" [(ngModel)]="examModel.omR_ExtraTests.hbac" class="form-control" style="width: 95%;">
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-1">
                <label>BMI:</label>
            </div>
            <div class="col-3">
                <div class="form-row">
                    <input type="text"  class="form-control">
                </div>
            </div>
        </div>

        <hr>
        <div class="row">
            <div class="col-4">
                <div class="form-row">
                    <label>Aniseikonia:</label>
                </div>
                <div class="form-row">
                    <div class="col-4">
                        <label>Dist Vert:</label>
                    </div>
                    <div class="col-8">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.aniseikoniaDistVert" class="form-control">
                    </div>
                </div><br>
                <div class="form-row">
                    <div class="col-4">
                        <label>Dist Horiz:</label>
                    </div>
                    <div class="col-8">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.aniseikoniaDistHoriz" class="form-control">
                    </div>
                </div>
            </div><br>
            <div class="col-3">
                <div class="form-row">
                    <label>Dist Phoria:</label>
                </div>
                <div class="form-row">
                    <div class="col-2">
                        <label>OD:</label>
                    </div>
                    <div class="col-10">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.npaod" class="form-control">
                    </div>
                </div><br>
                <div class="form-row">
                    <div class="col-2">
                        <label>OS:</label>
                    </div>
                    <div class="col-10">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.npaos" class="form-control">
                    </div>
                </div><br>
                <div class="form-row">
                    <div class="col-2">
                        <label>OU:</label>
                    </div>
                    <div class="col-10">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.npaou" class="form-control">
                    </div>
                </div>

            </div><br>
            <div class="col-5">
                <div class="form-row">
                    &nbsp;
                </div>
                <div class="form-row">
                    <div class="col-5">
                        <label>Near Point Working Dist:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.nearPtWorkingDist" class="form-control">
                    </div>
                </div><br>
                <div class="form-row">
                    <div class="col-5">
                        <label>Dominant Eye:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.dominantEye" class="form-control">
                    </div>
                </div><br>
                <div class="form-row">
                    <div class="col-5">
                        <label>Worth Test:</label>
                    </div>
                    <div class="col-7">
                        <input type="text" [(ngModel)]="examModel.omR_ExtraTests.worthTest" class="form-control">
                    </div>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-1">
                <label>Notes:</label>
            </div>
            <div class="col-11">
                <input type="textarea" [(ngModel)]="examModel.omR_ExtraTests.worthTest" class="form-control"
                    style="height: 70px;">
            </div>
        </div>
    </div>
    </fieldset>
