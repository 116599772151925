import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgIdleService } from '../../services/idle.service';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss']
})
export class InactivityModalComponent implements OnInit {
  title: string = '';
  content : string = '';
  saveButton : string = '';
  closeBtnName : string = '';
  idleTime: string = '';
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef, private ngIdle: NgIdleService) { }

  ngOnInit(): void {
  }
  continue() {
    
    this.bsModalRef.hide();
    this.triggerEvent();    
  }
  triggerEvent() {
    this.event.emit('continue');
  }
  triggerCloseEvent() {
    this.event.emit('logout');
  }
  close(){    
    this.bsModalRef.hide();
    this.triggerCloseEvent();
  }
  triggerCrossCloseEvent() {
    this.event.emit('close');
  }
  crossClose(){    
    this.bsModalRef.hide();
    this.triggerCrossCloseEvent();
  }
}
