export class InsuranceModel {
    public insurance_id: string = '';
    public display_txt: string = '';
    public address1_txt: string = '';
    public address2_txt: string = '';
    public city_txt: string = '';
    public state_txt: string = '';
    public zip_txt: string = '';
    public type_val: number = 0;
    public payer_name_txt: string = '';
    public claim_type_txt: string = '';
    public modify_charge_pct: number = 0;
    public active_ind: boolean = false;
    public created_dt: Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public phone_txt: string = '';
    public fax_txt: string = '';
    public payer_id_txt: string = '';
    public rep_phone_txt: string = '';
    public password_txt: string = '';
    public rep_name_txt: string = '';
    public rep_email_txt: string = '';
    public rep_website_txt: string = '';
    public user_id_txt: string = '';
}