import { GlobalService } from 'src/app/services/global.service';
import { LookupService } from './../../../../services/lookup.service';
import { LookupModel } from './../../../../model/lookupModel';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AllergyModel } from 'src/app/model/AllergyModel';
import { PatientModel } from 'src/app/model/patientModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { PatientModule } from '../../patient.module';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { resolve } from 'dns';

@Component({
  selector: 'app-add-allergy',
  templateUrl: './add-allergy.component.html',
  styleUrls: ['./add-allergy.component.scss']
})
export class AddAllergyComponent implements OnInit {

  title: string = '';
  list: any;
  closeBtnName: string = '';
  allergy: AllergyModel[] = [];
  patientDetails: PatientModel = new PatientModel();
  medication: LookupModel[] = [];
  medicationCopy: LookupModel[] = [];
  reaction: LookupModel[] = [];
  reactionCopy: LookupModel[] = [];
  Severity:LookupModel[]=[];
  Severitycopy:LookupModel[]=[];
  type: LookupModel[] = [];
  typeCopy: LookupModel[] = [];
  Vocabulary:LookupModel[]=[];
  Vocabularycopy:LookupModel[]=[];
  Codelookup:LookupModel[]=[];
  Codelookupcopy:LookupModel[]=[];
  showComponent: boolean = false;
  check_value: string = '';
  statusLookup: any =
  [{
    "value": "All",
    "key": "-All-"
  },
  {
    "value": "Active",
    "key": "Active"
  },
  {
    "value": "InActive",
    "key": "InActive"
  },
  {
    "value": "Discontinued",
    "key": "Discontinued"
  },
  {
    "value": "Administered",
    "key": "Administered"
  }]
  

  constructor(public bsModalRef: BsModalRef, private _clinicalService: ClinicalService,
    private toaster: ToastrService, private _lookupService: LookupService,
    private globalService: GlobalService) {
    this.patientDetails = JSON.parse(localStorage.getItem('ActivePatient')!);
  }

  ngOnInit() {
    this.getAllLookup();
    this.allergy = Object.assign([], this.list);

    // this.allergy.forEach(element=>{
    //   element.status_txt = 'All';
    // })
  }

  save() {
    this._clinicalService.saveAllergy(this.allergy).subscribe(res => {
      this.toaster.success('Record Saved Successfully.');
      this.bsModalRef.hide();

    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  addAllergy() {
    var allergyModel = new AllergyModel();
    allergyModel.status_txt = 'Active';
    allergyModel.patient_id = this.patientDetails.patient_id;
    this.allergy.push(allergyModel);
  }

  removeAllergy(index: number) {
    this.allergy.splice(index, 1);
  }

  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: LookupModel[]) => {
      this.medication = res.filter(x => x.group_txt == 'medicationname'&& x.active_ind == true);
      this.medication = this.medication.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.medicationCopy = res.filter(x => x.group_txt == 'medicationname');
      this.reaction = res.filter(x => x.group_txt == 'phallergyreaction' && x.active_ind == true);
      this.reaction = this.reaction.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.reactionCopy = res.filter(x => x.group_txt == 'phallergyreaction' );
      this.Severity = res.filter(x => x.group_txt == 'Severity' && x.active_ind == true);
      this.Severity = this.Severity.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.Severitycopy = res.filter(x => x.group_txt == 'Severity' );
      this.Vocabulary = res.filter(x => x.group_txt == 'vocabulary' && x.active_ind == true);
      this.Vocabulary = this.Vocabulary.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.Vocabularycopy=res.filter(x=>x.group_txt=='vocabulary');
      this.Codelookup = res.filter(x => x.group_txt == 'rxnormcode'  );
      this.Codelookup = this.Codelookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.Codelookupcopy = res.filter(x => x.group_txt == 'rxnormcode' && x.active_ind == true);
      
      this._lookupService.getGlobalLookup('phallergytype').subscribe((response: LookupModel[]) => {
        this.type =  res.filter(x =>  x.active_ind == true).sort((a, b) => {
          if (a.name_txt < b.name_txt) {
            return -1
          }
          if (a.name_txt > b.name_txt) {
            return 1
          }
          return 0
        });;
        this.typeCopy = res.filter(x => x.group_txt == 'phallergytype');
        this.showComponent = true;
      })
    })
    
    // this._lookupService.getGlobalLookup('medicationname').subscribe((res:LookupModel[])=>{
    //   this.reaction = res;
    // })
    // this._lookupService.getGlobalLookup('medicationname').subscribe((res:LookupModel[])=>{
    //   this.type = res;
    // })
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getAllLookup()
      // this.sortArray(item)
    })
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }

  CreateNew(city:string){          
  }

  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any, name_txt: string, item: LookupModel[], group_txt?: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = 'All';
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }
      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {
            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getAllLookup();
              this.toaster.success('Record Saved Successfully.');
              item1[property]= val;//this.check_value;
              
            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toaster.success('Record Saved Successfully.');
          //     item1[property]=this.check_value;
          //     this.getAllLookup();
          //   })
          // }
        }
      })
    }
    item1[property] = val;
  } else {
    item1[property] = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }

}
