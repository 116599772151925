import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IdentifierModel } from 'src/app/model/identiferModel';
import { LookupModel } from 'src/app/model/lookupModel';
import { LookupService } from 'src/app/services/lookup.service';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { Item_ContactsDataModel } from 'src/app/model/Item_ContactsDataModel';
import { ItemModel } from 'src/app/model/ItemModel';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.scss']
})
export class AddBrandComponent implements OnInit {
  title: string = '';
  item: LookupModel[] = [];
  diamLookup: LookupModel[] = [];
  diamLookupCopy: LookupModel[] = [];
  bcLookup: LookupModel[] = [];
  bcLookupCopy: LookupModel[] = [];
  productModel: ItemModel = new ItemModel();
  productList: ItemModel[] = [];
  productListCopy: ItemModel[] = [];
  itemContacts: Item_ContactsDataModel[] = [];
  dbType: number = 1;
  brandFormGroup!: FormGroup;
  brandList: LookupModel[] = [];
  brand: LookupModel[] = [];
  brandModel: LookupModel = new LookupModel();
  ItemContact: Item_ContactsDataModel = new Item_ContactsDataModel();
  showButton = false;
  enablEditButton = false;
  showInactive : boolean = false;
  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private toaster: ToastrService, private _lookupService: LookupService) {

  }

  ngOnInit(): void {

    this.createFormGroup();
    this.brand = this.item;
    this.brandList = this.item;
    this.brandList = this.compare(this.brandList);
    this.diamLookup = this.compare(this.diamLookup);
    this.bcLookup = this.compare(this.bcLookup);
    this.productListCopy = this.productList;
    this.brandFormGroup.disable();
  }

  createFormGroup(){
    this.brandFormGroup = this.formBuilder.group({
      name_txt: ['', Validators.required],
      diameter_txt: [''],
      basecurve_txt: ['']
    })
  }

  create() {
    this.showButton = true;
  }
  cancel() {
    this.showButton = false;
    if (this.brandModel.lookup_id == '') {
      this.brandFormGroup.reset();
    }
    this.brandFormGroup.disable();
  }

  setForm(brand: LookupModel) {
    debugger;
    this.brandModel = Object.assign({}, brand)
    var val = brand.name_txt.trim();
    var item = this.productList.filter((x) => x.brand !== null && x.brand.trim().toLocaleLowerCase() == val.toLocaleLowerCase());
    if (item.length > 0) {
      var contact = this.itemContacts.filter((x) => x.item_id == item[0].item_id);
      this.ItemContact.item_id = contact[0].item_id;
      this.ItemContact.diameter_txt = contact[0].diameter_txt;
      this.ItemContact.basecurve_txt = contact[0].basecurve_txt;
    }else{
      this.ItemContact.item_id = '';
      this.ItemContact.diameter_txt = '';
      this.ItemContact.basecurve_txt = '';
    }
    this.enablEditButton = true;
  }
  addNew() {
    this.brandFormGroup.enable();
    this.brandFormGroup.reset();
    this.brandModel = new LookupModel();
    this.showButton = true;
  }
  save() {
    debugger;
    if(this.ItemContact.item_id == ''){
      //this.toaster.info("No brand exist in Items", "Brand Not Exist");
      this.mapProduct(this.brandModel);
      this._lookupService.insertItem(this.productModel).subscribe(res => {
        this.getProducts();
        this.showButton = false;
        this.toaster.success('Record Saved Successfully.');
      }, (error) => {
        this.toaster.error(error.error);
      });
    }else{
      this._lookupService.updateItemsWithContact(this.ItemContact).subscribe(res => {
          this.getProducts();
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
        this.showButton = false;
    }
  }
  mapProduct(brandModel: LookupModel){
    this.productModel.active_ind = true;
    this.productModel.name_txt = brandModel.name_txt;
    this.productModel.description_txt = brandModel.name_txt;
    this.productModel.brand = brandModel.name_txt;
    this.productModel.type_id = 1;
    this.productModel.item_ContactsData = this.ItemContact;
  }
  getProducts() {

    this._lookupService.getItemsWithContact().subscribe((res: any) => {
      this.productList = res.item1;
      this.itemContacts = res.item2;
    });
  }
  filter(active:boolean){
    // this.showInactive = active;
    // if(!this.showInactive)
    // {
    //   this.brandList = this.brand.filter(x => x.active_ind == true);
    // } else {
    //   this.chargeList = this.charge.filter(x => x.active_ind == false);
    // }
    this.brandList = this.compare(this.brandList);
  }
  compare(array: LookupModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.name_txt)) && !isNaN(Number(b.name_txt))){
          if (Number(a.name_txt) > Number(b.name_txt)){
            return 1;
          }else if(Number(a.name_txt) < Number(b.name_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.name_txt.toLowerCase() > b.name_txt.toLowerCase()){
            return 1;
        }else if(a.name_txt.toLowerCase() < b.name_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }
  findItem(event: any){
    var searchString = event.target.value;
    if(searchString == ''){
      this.brandList = this.brand;
      // if (this.showInactive){
      //   this.brandList = this.brand;
      // }else{
      //   this.filter(false);
      // }
    }
    else{
      // if (this.showInactive){
      //   this.chargeList = this.charge.filter((item) => {
      //     if (item.code_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
      //       return item;
      //     }
      //     if (item.name_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
      //       return item;
      //     }
      //     return;
      //   });
      // }else{
      //   this.chargeList = this.charge.filter(x=> (x.code_txt.toLowerCase().includes(searchString.toLowerCase()) || x.name_txt.toLowerCase().includes(searchString.toLowerCase())) && x.active_ind == true)
      // }
      this.brandList = this.brand.filter(x=> x.name_txt.toLowerCase().includes(searchString.toLowerCase()))
    }
    this.brandList = this.compare(this.brandList);
  }
  edit() {
    this.showButton = true;
    this.brandFormGroup.enable();
  }
  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType, item).subscribe((res: LookupModel[]) => {
      if (dbType == this.dbEnum.QE_DEV_SQL_DB) {
        this.getAllLookup()
      }
    })
  }
  getAllLookup() {
    this._lookupService.getAllLookupData().subscribe((res: Array<LookupModel>) => {
      this.brandList = res.filter(x => x.group_txt.toLowerCase() == 'brand'.toLowerCase() && x.active_ind == true);
      this.brandList = this.compare(this.brandList);
      this.brand = res.filter(x => x.group_txt.toLowerCase() == 'brand'.toLowerCase());
    })
  }
  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }
}
