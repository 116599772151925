<form (submit)="onSubmit()" [formGroup]="loginForm" style="padding-top: 50px;">
  <div class="main_login">
    <div class="inner_login">
      <div class="imgcontainer">
        <img src="../../../assets/img/logo.png" width="100%">
      </div>
      <div class="container">
        <label for="Username"><b>Username</b></label>
        <div class="input-icons">
          <i class="fa fa-user icon"></i>
          <input class="input-field" type="text" placeholder="Enter Username" formControlName="userName" name="userName" [(ngModel)]="authenticateRequestModel.userName"
            required>
        </div>
        <label for="password"><b>Password</b></label>
        <div class="input-icons">
          <i class="fa fa-key icon"></i>
          <input class="input-field" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" [(ngModel)]="authenticateRequestModel.password"
            placeholder="Enter Password" name="userName" required>
        </div>
        <div>
          <input type="checkbox" (click)="toggleFieldTextType()">&nbsp; Show Password
        </div>
        <button type="submit" [disabled]=!loginForm.valid>Login</button>
        <div class="row">
          <div class="col-md-12" style="font-size: 9px;text-wrap: pretty; margin-top: 30px;">
            <p>CPT ® copyright 2023 American Medical Association. All rights reserved. Fee schedules, relative value units, conversion factors and/or related components are not assigned by the AMA, are not part of CPT ®, and the AMA is not recommending their use. The AMA does not directly or indirectly practice medicine or dispense medical services. The AMA assumes no liability for data contained or not contained herein. CPT ® is a registered trademark of the American Medical Association.
              <br>
              U.S. Government End Users. CPT® is commercial technical data, which was developed exclusively at private expense by the American Medical Association (AMA), 330 North Wabash Avenue, Chicago, Illinois 60611. Use of CPT® in connection with this product shall not be construed to grant the Federal Government a direct license to use CPT® based on FAR 52.227-14 (Data Rights - General) and DFARS 252.227-7015 (Technical Data - Commercial Items).</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
