import { PatientService } from './../../../services/patient.service';
import { PatientModel } from './../../../model/patientModel';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AppointmentModel } from 'src/app/model/AppointmentModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit {
  title: string = '';
  list: AppointmentModel = new AppointmentModel();
  closeBtnName: string = '';
  patientModel : PatientModel = new PatientModel();
  patinetFormGroup !: FormGroup;
  bsConfig : Partial<BsDatepickerConfig> = new BsDatepickerConfig;

  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef,private _patientService : PatientService,private fb: FormBuilder,private toaster: ToastrService) {
    this.patinetFormGroup = this.fb.group({
      dateOfEntry: [Validators.required],
      lastName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(),
      sex: new FormControl('U'),
      address: new FormControl(''),
      address2: new FormControl(''),
      emailaddress: new FormControl('',Validators.email),
      homePhone: new FormControl(''),
    })
   }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    this.patientModel.sex = 'U';
    // this.patientModel.lastName = '';
    // this.patientModel.firstName = '';
  }

  save(){
    if (this.patinetFormGroup.valid) {
      let practiceId = localStorage.getItem('practice')?.toString();
      practiceId = practiceId != undefined ? practiceId : "";
      this._patientService.addPatient(this.patientModel, practiceId).subscribe(res=>{
        this.triggerEvent(res);
        this.toaster.success('Record Saved Succesfully.');
      },(error)=>{
        this.toaster.error(error.error);
      })
    }
   
  }

  triggerEvent(item:any){
    this.event.emit(item);
    this.bsModalRef.hide();

  }

}
