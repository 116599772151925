import { environment } from './../../environments/environment';
import { OMR_FeeStatementModel } from './../model/omr_FeeStatementModel';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class Omr_FeeStatementservice {

constructor(private http:HttpClient) { }

  getAll(patientId : string):Observable<OMR_FeeStatementModel[]>
  {
    return this.http.get<OMR_FeeStatementModel[]>(environment.server+'api/OMR_FeeStatement/getall?patientId='+patientId);
  }
  getMiscFeeStatement(patientId : string, feeStatementId : string):Observable<OMR_FeeStatementModel[]>
  {
    return this.http.get<OMR_FeeStatementModel[]>(environment.server+'api/OMR_FeeStatement/getmiscfeestatement?patientId='+patientId+'&feeStatementId='+feeStatementId);
  }

  getByID(examId : string,patientId : string): Observable<OMR_FeeStatementModel[]> {
    return this.http.get<OMR_FeeStatementModel[]>(environment.server + 'api/OMR_FeeStatement/getbyid?examId='+examId+'&patientId='+patientId);
  }

  getByFeeStatementId(fee_statement_id:string):Observable<OMR_FeeStatementModel>
  {
    return this.http.get<OMR_FeeStatementModel>(environment.server+'api/OMR_FeeStatement/getbyid?id='+fee_statement_id);
  }

  insertOMR_Fee_Statement(omr_FeeStatementModel:OMR_FeeStatementModel){
    return this.http.post<OMR_FeeStatementModel>(environment.server+'api/OMR_FeeStatement/add',omr_FeeStatementModel);
  }

  updateOMR_Fee_Statement(omr_FeeStatementModel:OMR_FeeStatementModel){
    return this.http.post<OMR_FeeStatementModel>(environment.server+'api/OMR_FeeStatement/update',omr_FeeStatementModel);
  }

}
