import {
  Component, Input, ElementRef, AfterViewInit, ViewChild, OnInit, Output, EventEmitter
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators'

@Component({
  selector: 'app-canvas',
  templateUrl: './Canvas.component.html',
  styleUrls: ['./Canvas.component.scss']
})
export class CanvasComponent implements AfterViewInit {

  @ViewChild('canvas') public canvas !: ElementRef;

  @Input() public width = 400;
  @Input() public height = 400;
  @Input() public imageSRC = '';
  @Input() public imageHeight = 400;
  @Input() public imageWidth = 400;
  @Input() public defaultImage = '';
  @Output() onDraw = new EventEmitter();
  
  private cx !: any;

  public ngAfterViewInit() {
    this.createCanvas();
  }

  private createCanvas() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');

    canvasEl.width = this.width;
    canvasEl.height = this.height;
    
    var background = new Image();
    background.src = this.imageSRC;
    //this.cx.drawImage(background, this.imagewidth, this.imageheight);
    background.onload = () => {
      this.cx.drawImage(background, 0, 0, this.imageWidth, this.imageHeight);
    }

    this.cx.lineWidth = 2;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = '#000';

    this.captureEvents(canvasEl);
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event    
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point    
              pairwise()
            )
        })
      )
      .subscribe((res: any = [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) { return; }

    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
    
    this.onDraw.emit(<HTMLCanvasElement> this.canvas.nativeElement.toDataURL());
  }

  clear() {
    this.cx.clearRect(0, 0, this.width, this.height);
    this.imageSRC = this.defaultImage;
    this.createCanvas();
  }

}
