import { LookupService } from './../../../services/lookup.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from './../../../services/user.service';
import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/model/userModel';
import { ProviderModel } from 'src/app/model/providerModel';
import { LogicalProjectPath } from '@angular/compiler-cli/src/ngtsc/file_system';
import { lookup } from 'dns';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  userList: UserModel[] = [];
  user: UserModel[] = [];
  userModel: UserModel = new UserModel();
  userFormGroup !: FormGroup;
  showActive: boolean = false;
  filterString: string = '';
  imgURL: any;
  isShowButton: boolean = false;
  isChecked: boolean = false;
  selectedUser : string = "";
  is_can_sign: boolean = false;

  constructor(private _userService: UserService, private fb: FormBuilder, private toastr: ToastrService, private _lookupService: LookupService) { }

  ngOnInit() {
    this.userFormGroup = this.fb.group({
      active_ind: false,
      locked_ind: false,
      qeUserID: '',
      username_txt: ['', Validators.required],
      password_txt: ['', Validators.required],
      display_txt: [''],
      email_address_txt: ['', Validators.email],
      first_name_txt: [''],
      last_name_txt: [''],
      filterString: ['']
    });
    this.userFormGroup.disable();
    //this.filterString = '';
    this.getUser();
  }

  getUser() {
    let practiceId = localStorage.getItem('practice')?.toString();
    this._userService.getUserList(practiceId != undefined ? practiceId : "").subscribe((res: any) => {
      debugger;
      this.user = res;
      this.userList = this.user;
      this.filterUser(this.showActive);
      this.userList = this.compare(this.userList);
      // this.userList = this.userList.filter(x=>x.active_ind == true);
    }, (error) => {

    })
  }

  setForm(item: UserModel) {
    // this.userModel = Object.assign({},item)
    this._userService.getByUserId(item.user_id).subscribe((res: any) => {
      this.userModel = Object.assign(res);
      this.is_can_sign = this.userModel.can_sign;
      this.imgURL = res.signature_img ? 'data:image/png;base64,' + res.signature_img : '';
      this.userModel.signature_img = null;
      this.userFormGroup.disable();
      this.isShowButton = false;
      this.selectedUser = this.userModel.user_id;
    })
  }

  save() {
    if (this.userModel.user_id == '') {
      let practiceId = localStorage.getItem('practice')?.toString();
      this.userModel.practice_id = practiceId != undefined ? practiceId : "";
      if (this.userFormGroup.valid) {
        this._userService.addUser(this.userModel).subscribe(res => {
          this.toastr.success('Record Saved Successfully');
          this.saveProvider();
          this.getUser();
          window.location.reload()
        }, (error) => {
          this.toastr.error(error.error);
        })
      }

    }
    else {
      if (this.userFormGroup.valid) {
        this._userService.updateUser(this.userModel).subscribe(res => {
          this.toastr.success('Record Updated Successfully');
          this.saveProvider();
          this.getUser();
          window.location.reload()
        }, (error) => {
          this.toastr.error(error.error);
        })
      }
    }
  }

  saveProvider()
  {
    if(this.userModel.can_sign)
    {
      let providerModel = new ProviderModel();
      providerModel.active_ind = this.userModel.active_ind;
      providerModel.first_name_txt = this.userModel.first_name_txt;
      providerModel.last_name_txt = this.userModel.last_name_txt;
      providerModel.display_txt = this.userModel.display_txt;
      providerModel.email_address_txt = this.userModel.email_address_txt;
      providerModel.rendering_provider_ind = true;

      this._lookupService.insertProviderfromUser(providerModel).subscribe(res => {

      })
    }
  }


  filterUser(active:boolean) {
    debugger;
    this.showActive = active;
    if(!this.showActive)
    {
      this.userList = this.user.filter(x => x.active_ind == true);
    } else {
      this.userList = this.user.filter(x => x.active_ind == false);
    }
    this.userList = this.compare(this.userList);
  }

  onFileChanged(event: any) {
    let reader = new FileReader();
    var element = event.target.files[0];
    reader.onload = (e: any) => {
      this.imgURL = e.target.result;
    }
    reader.readAsDataURL(element);
    this.userModel.signature_img = (element);
  }

  edit(){
    this.isShowButton = true;
    this.userFormGroup.enable();
  }

  addNew(){
    debugger;
    this.userModel = new UserModel();
    this.userModel.active_ind=true;
    this.isShowButton = true;
    this.userModel.can_sign = false;
    this.is_can_sign = false;
    this.imgURL = null;
    this.userFormGroup.enable();
    this.userFormGroup.reset();

  }
  cancel(){
    this.isShowButton = false;
    this.userFormGroup.disable();
  }
  SearchUser(event: any){

    this.filterString = event.target.value;
    if (this.filterString == '') {
      if (this.showActive){
        this.userList = this.user;
      }else{
        this.filterUser(false);
      }
    }
    else{
      if (this.showActive){
        this.userList = this.user.filter(x=>x.display_txt.toLowerCase().includes(this.filterString.toLowerCase()))
      }else{
        this.userList = this.user.filter(x=>x.display_txt.toLowerCase().includes(this.filterString.toLowerCase()) && x.active_ind == true)
      }
    }
    this.userList = this.compare(this.userList);
  }
  compare(array: UserModel[]){
    array.sort((a,b) => {
      if (!isNaN(Number(a.display_txt)) && !isNaN(Number(b.display_txt))){
          if (Number(a.display_txt) > Number(b.display_txt)){
            return 1;
          }else if(Number(a.display_txt) < Number(b.display_txt)){
            return -1;
          }else{
            return 0;
          }

      }else{
        if (a.display_txt.toLowerCase() > b.display_txt.toLowerCase()){
            return 1;
        }else if(a.display_txt.toLowerCase() < b.display_txt.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      }
    })
    return array;
  }

}
