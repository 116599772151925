export class LookupModel {
    public lookup_id: string = '';
    public group_txt: string = '';
    public sub_group_txt: string = '';
    public name_txt: string = '';
    public description_txt: string = '';
    public active_ind: boolean = false;
    public sort_val: number = 0;
    public created_dt: Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public index : number = 0;
    public iseditable: boolean = false;
}