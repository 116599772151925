export class ProviderModel {
    public provider_id: string = '';
    public display_txt: string = '';
    public last_name_txt: string = '';
    public first_name_txt: string = '';
    public active_ind: boolean = false;
    public address_1_txt: string = '';
    public address_2_txt: string = '';
    public city_txt: string = '';
    public state_txt: string = '';
    public zip_txt: string = '';
    public npi_txt: string = '';
    public secondary_id_txt: string = '';
    public secondary_id_type_txt: string = '';
    public federal_tax_id_type_txt: string = '';
    public federal_tax_id_txt: string = '';
    public taxonomy_cd_txt: string = '';
    public default_billing_provider_id: string = '';
    public billing_provider_ind: boolean = false;
    public rendering_provider_ind: boolean = false;
    public referring_provider_ind: boolean = false;
    public phone_txt: string = '';
    public note_txt: string = '';
    public created_dt: Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public fax_txt: string = '';
    public practice_name_txt: string = '';
    public email_address_txt: string = '';
    public signature_dat: string = '';
    public meaningful_phase_ind: number = 0;
    public provider_type: string = '';
    public direct_address: string = '';
    public direct_password_txt: string = '';
}