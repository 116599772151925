import { InsuranceModel } from 'src/app/model/insuranceModel';
import { AttachmentModel } from './AttachmentModel';
export class PatientInsuranceModel {
    patientInsuranceID: string = '';
    patientID: number = 0;
    insuranceType: number = 1;
    insuranceOrder : number = 0;
    insuranceName: string = '';
    status: string = '';
    memberID: string = '';
    groupID: string = '';
    insuredName: string = '';
    insuredRelation!: string;
    insuredEmployer: string = '';
    insuredGender: string = '';
    insuredDOB: Date = new Date();
    insuredSSN: string = '';
    creationDate: Date = new Date();
    createdBy: number = 0;
    lastUpdateDate: Date = new Date();
    lastUpdatedBy: number = 0;
    attachment_id: string = '';
    current_ind: boolean = false;
    insurance_id: string = '';
    isShow: boolean = false;
    insuredCard: string = '';
    insuranceModel : InsuranceModel = new InsuranceModel();
    attachmentModel : AttachmentModel = new AttachmentModel()
}