<form class="form" style="height:101%; width: 100% !important;">
<div  id="content" style="text-align:center !important;margin-right: 5px;">
  <div>
      <div class="col-md-12" style="padding: 0%;">
          <h3><b>Manage Manufacturers</b></h3>

      </div>
  </div>
</div>
<div id="content" style="margin-right: 5px;">
  <app-manufacture></app-manufacture>
</div>
</form>
