import { PatientModel } from 'src/app/model/patientModel';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ExamModel } from 'src/app/model/ExamModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-contactrx',
  templateUrl: './pre-contactrx.component.html',
  styleUrls: ['./pre-contactrx.component.scss']
})
export class PreContactrxComponent implements OnInit {

  columnPatientContactRx: any;
  rowPatientContactRx: ExamModel[] = [];
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  constructor(private _clinicalService : ClinicalService,private routers:Router) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
  }

  ngOnInit() {
    this.columnPatientContactRx = [
      {
        headerName: 'Exam Date',
        field: 'exam_Date', sortable: true, sort: 'desc', resizable: true, rowDrag: true, filter: true, floatingFilter: false, width: 150
        , cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Chief Complaint',
        field: 'cheif_Complaint', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OD Power',
        field: 'omR_ContactRX.odPowerT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OD Base Curve',
        field: 'omR_ContactRX.odBaseCurveT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OD Diam',
        field: 'omR_ContactRX.odDiameterT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OD Brand',
        field: 'omR_ContactRX.odBrandT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OS Power',
        field: 'omR_ContactRX.osPowerT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OS Base Curve',
        field: 'omR_ContactRX.osBaseCurveT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OS Diam',
        field: 'omR_ContactRX.osDiameterT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      },
      {
        headerName: 'OS Brand',
        field: 'omR_ContactRX.osBrandT', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false, width: 250
      }
    ];
    // this.rowPatientContactRx = [];
    this.getExam();
  }

  getExam(){    
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.rowPatientContactRx = res;
    })
  }

  navigate(event:any){
    this.routers.navigate(["/patient/spectacle"],{ queryParams: { patientId:this.patientModel.patientID,examId : event.data.exam_ID } });
  }

}
