import { PracticeNotificationModel } from './../model/practiceNotificationModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

constructor(private http:HttpClient) { }
  getNotification():Observable<PracticeNotificationModel>
  {
    return this.http.get<PracticeNotificationModel>(environment.server+'api/notification/getall');
  }
  getAppointment()
  {
    return this.http.get('')
  }
  saveNotification(practiceNotificationModel:PracticeNotificationModel):Observable<PracticeNotificationModel>
  {
    return this.http.post<PracticeNotificationModel>(environment.server+'api/notification/add',practiceNotificationModel)
  }

  updateNotification(practiceNotificationModel:PracticeNotificationModel):Observable<PracticeNotificationModel>
  {
    return this.http.post<PracticeNotificationModel>(environment.server+'api/notification/update',practiceNotificationModel)
  }

}
