import { PatientModel } from 'src/app/model/patientModel';
import { GlobalService } from './../../services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PatientService } from 'src/app/services/patient.service';
import { ToastrService } from 'ngx-toastr';
import { PracticeService } from 'src/app/services/practice.service';
// import { truncateSync } from 'fs';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.scss']
})
export class CommonLayoutComponent implements OnInit, AfterViewInit {

  title: string = 'Customer';
  isPatientDetail: boolean = false;
  isnewPatient: boolean = false;
  patientId!: number;
  age: number = 0;
  patientDetail = new PatientModel();
  isShowAdministration: boolean = false;
  isShowSubMenuExam: boolean = false;
  isLoad: boolean = false;
  examId: string = '';
  feestatementId: string = '';
  isDisabled: boolean = true;
  isNewExam: boolean = false;
  isMainExam: boolean = false;
  patient_Id : string = '';
  imgURL: any;
  isContactOrder: boolean = false;
  constructor(private router: Router, private globalService: GlobalService, private route: ActivatedRoute, private _patientService: PatientService,
    private toaster: ToastrService, private _practiceService: PracticeService) {
    this.route.queryParams.subscribe((params: any) => {
      this.isDisabled = localStorage.getItem('isContactOrder') === "true" ? true : false;
      if (params['patientId']) {
        this.patientId = parseInt(params['patientId']);
      }
      if (params['examId']) {
        this.examId = params['examId'];
      }
      if (params['patient_Id']) {
        this.patient_Id = params['patient_Id'];
      }
      if (params['feestatementId']) {
        this.feestatementId = params['feestatementId'];
      }else{
        this.feestatementId = "";
      }
    });
  }

  ngOnInit() {
    if(!this.imgURL){
      this.getLogoImage();
    }
    this.isShowAdministration = localStorage.getItem('isShowAdministration') === "true" ? true : false;
    // this.isContactOrder = localStorage.getItem('isContactOrder') === "true" ? true : false;
    this.isnewPatient = false;
    if (this.patientId || this.patient_Id != '') {

      this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);

      if (this.patientDetail) {
        this.isPatientDetail = true;
        this.isLoad = true;
      }
      this.globalService.examChangeSubscriber.subscribe(res => {
          this.examId = res;
          this.isShowSubMenuExam = true;
      });
      if (this.examId != '') {
        this.isShowSubMenuExam = true;
      }
      // this.isLoad = true;
      // this.ngAfterViewInit();
    }
    else if(location.href.includes("patient/create")) {
      this.isnewPatient = true;
      this.isPatientDetail = true;
      this.isLoad = false;
      setTimeout(() =>{
        this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
      }, 13000)
    }
    this.globalService.checkboxChangeSubscriber.subscribe(res => {
      this.isDisabled = res;
    })
  }

  logout() {
    this.globalService.logout();
  }


  calculateAge(birthdate: Date) {
    let timeDiff = Math.abs(Date.now() - birthdate.getTime());
    this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
  }

  ngAfterViewInit() {

    this.route.queryParams.subscribe((params: any) => {
      if (params['patientId']) {
        this.patientId = parseInt(params['patientId']);
      }

      this.isShowSubMenuExam = this.isMainExam = 'examId' in params ? true : false;

      if (params['examId']) {
        this.examId = params['examId'];
        this.isNewExam = false;
      } else {
        this.isNewExam = true;
      }
    });

    this.globalService.subject.subscribe((newTitle: any) => {
      this.isPatientDetail = newTitle;
      if (this.isPatientDetail) {
        this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
        if (this.patientDetail.dateOfBirth)
          this.calculateAge(new Date(this.patientDetail.dateOfBirth));
      }
      this.isLoad = true;
    });
  }
  getLogoImage() {
    debugger;
    let practiceId = localStorage.getItem('practice')?.toString();
    practiceId = practiceId != undefined ? practiceId : "";
    this.imgURL =  localStorage.getItem('logoimage')?.toString();
    if(!this.imgURL){
      this._practiceService.Getbyid(practiceId, "logoimage").subscribe((res) => {
        this.imgURL = res.logo_img ? 'data:image/png;base64,' + res.logo_img : '';
        localStorage.setItem('logoimage', this.imgURL);
      }, (error) => {
        this.toaster.error(error.error);
      });
    }
  }

  showAdministration() {
    this.isShowAdministration = this.isShowAdministration ? false : true;
    localStorage.setItem("isShowAdministration", this.isShowAdministration.toString());
    if (this.isShowAdministration)
      this.router.navigate(['/administration/admin']);
  }
  patientDemographicsClick(){
    GlobalService.componentName = "create";
  }
  patientHistoryClick(){
    GlobalService.componentName = "patient-history";
  }
  clinicalClick(){
    GlobalService.componentName = "clinical";
  }
  extraTestsClick(){
    GlobalService.componentName = "extra-test";
  }
  spectacleClick(){
    GlobalService.componentName = "spectacle";
  }
  medRxClick(){
    GlobalService.componentName = "cpoe";
  }
  contactOrderFormClick(){
    GlobalService.componentName = "contact-order";
  }
  feeStatementClick(){
    GlobalService.componentName = "free-statement";
  }
  hCFAClick(){
    GlobalService.componentName = "hcfa";
  }
  miscFeeStatementClick(){
    GlobalService.componentName = "misc-free-statement";
  }
  patientAccountClick(){
    GlobalService.componentName = "patientAccount";
  }
  ePrescribeClick(){
    GlobalService.componentName = "e-prescribe-now";
  }
  showExam() {
    this.isShowSubMenuExam = this.isShowSubMenuExam ? false : true;
  }
}
