import { ComponentModalComponent } from './../../../shared/component-modal/component-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LookupModel } from './../../../model/lookupModel';
import { PatientInsuranceModel } from './../../../model/patientInsuranceModel';
import { Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { AdvancePatientSearchModel } from './../../../model/AdvancePatientSearchModel';
import { ProviderModel } from './../../../model/providerModel';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from './../../../services/lookup.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IdentifierModel } from 'src/app/model/identiferModel';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { PatientModel } from 'src/app/model/patientModel';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';
import { GlobalService } from 'src/app/services/global.service';
import { OfficeModel } from 'src/app/model/officeModel';
import { InsuranceModel } from 'src/app/model/insuranceModel';

@Component({
  selector: 'app-advance-patient-search',
  templateUrl: './advance-patient-search.component.html',
  styleUrls: ['./advance-patient-search.component.scss']
})
export class AdvancePatientSearchComponent implements OnInit {
  sexLookup: any =
  [{
    "value": "M",
    "key": "Male"
  },
  {
    "value": "F",
    "key": "Female"
  },
  {
    "value": "U",
    "key": "Unknown"
  }]
  showPanelList: string[] = [];
  advancePatientSearch!: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  patientInsuranceDropdownSettings: IDropdownSettings = {};
  patientId: number = 0;
  identifier: Array<IdentifierModel> = new Array<IdentifierModel>();
  patientInsurance: PatientInsuranceModel[] = [];
  rowInsurance: InsuranceModel[] = [];
  advancePatientSearchModel: AdvancePatientSearchModel = new AdvancePatientSearchModel();
  public patientGridOptions!: GridOptions;
  @ViewChild('patientGrid') patientGrid: any;
  patientGridApi!: GridApi;
  patientGridColumnApi!: ColumnApi;
  public rowPatient: PatientModel[] = [];
  public columnPatient: any;
  isShowPatientList: boolean = false;
  raceLookup: LookupModel[] = [];
  raceLookupCopy: LookupModel[] = [];
  ethnicityLookup: LookupModel[] = [];
  ethnicityLookupCopy: LookupModel[] = [];
  languageLookup: LookupModel[] = [];
  languageLookupCopy: LookupModel[] = [];
  contactmethodLookup: LookupModel[] = [];
  contactmethodLookupCopy: LookupModel[] = [];
  office: OfficeModel[] = [];
  plan: LookupModel[] = [];
  planCopy: LookupModel[] = []; 
  assessment: LookupModel[] = [];
  assessmentCopy: LookupModel[] = [];
  providerModel: ProviderModel[] = [];
  referringDoctor: ProviderModel[] = [];
  bsConfig : Partial<BsDatepickerConfig> = new BsDatepickerConfig;
  bsModalRef !: BsModalRef;
  check_value: string = '';

  constructor(private formBuilder: FormBuilder, private router: Router, 
    private _lookupService: LookupService, private toaster: ToastrService,
    private _patientService: PatientService,private globalService : GlobalService,
    private modalService : BsModalService) {
    this.patientGridOptions = {
      unSortIcon: true,
      // suppressCellSelection: true,
      // domLayout: 'autoHeight',
      rowSelection: 'single',
      context: {},
      pagination: false,
      paginationPageSize: 5,
      paginationNumberFormatter: function (params: any) {
        return '[' + params.value.toLocaleString() + ']';
      },
      rowStyle: { cursor: 'pointer' },
      //debounceVerticalScrollbar:true,
      //suppressHorizontalScroll: true,
      // alwaysShowVerticalScroll: true,
      // onGridReady: (params) => {
      //   params.api.sizeColumnsToFit();
      // },
      // onGridSizeChanged: (params) => {
      //   params.api.sizeColumnsToFit();
      // }
    }
  }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: 'theme-dark-blue', showWeekNumbers:false });
    this.createFormGroup();
    this.columnPatient = [
      {
        headerName: 'Last Name',
        field: 'lastName', sortable: true, filter: 'agTextColumnFilter', resizable: true, rowDrag: true, floatingFilter: false,
      },
      { headerName: 'First Name', field: 'firstName', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false },
      { headerName: 'Address', field: 'address', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false },
      { headerName: 'City', field: 'city', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false },
      { headerName: 'State', field: 'state', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false },
      { headerName: 'Zip Code', field: 'zip', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false },
      { headerName: 'Email', field: 'emailaddress', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false },
      { headerName: 'Phone', field: 'homePhone', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false }

    ];
    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "id",
      textField: "name",
      selectAllText: "All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      enableCheckAll: true,
      itemsShowLimit: 1,
      clearSearchFilter: false,
      searchPlaceholderText: 'Search Identifier',
      noDataAvailablePlaceholderText: 'No Identifier Available'
    };
    this.patientInsuranceDropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "insurance_id",
      textField: "display_txt",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
      enableCheckAll: true,
      itemsShowLimit: 1,
      clearSearchFilter: false,
      searchPlaceholderText: 'Search Patient Insurance',
      noDataAvailablePlaceholderText: 'No Patient Insurance Available'
    };
    this.getAllLookup();
    // this.rowPatient.push({lastName:"rutvik",firstName:"maniya"})
  }

  createFormGroup() {
    this.advancePatientSearch = this.formBuilder.group({
      ExamFromDate: new FormControl(),
      ExamToDate: new FormControl(),
      LastSeen: new FormControl(),
      IsShowInActive: new FormControl(),
      BirthFromDate: new FormControl(),
      BirthToDate: new FormControl(),
      // identifiers:new FormControl(),
      Employer: new FormControl(),
      Marketing: new FormControl(),
      Ethnicity: new FormControl(),
      PreferredLang: new FormControl(),
      Race: new FormControl(),
      ContactMethod: new FormControl(),
      Zipcode: new FormControl(),
      Areacode: new FormControl(),
      Appointment: new FormControl(),
      ExamNotification: new FormControl(),
      FromAge: new FormControl(),
      ToAge: new FormControl(),
      Sex: new FormControl(),
      Doctor: new FormControl(),
      Assessment: new FormControl(),
      Office: new FormControl(),
      Plan: new FormControl(),
      Immunization: new FormControl(),
      // received:new FormControl(1),
      // between:new FormControl(),
      // betweenTo:new FormControl(),
      // brand:new FormControl(),
      // generic:new FormControl(),
      // allergy:new FormControl(),
      // subjOd:new FormControl({value:null,disabled:true}),
      // nearSubjOd:new FormControl({value:null,disabled:true}),
      // subjOs:new FormControl({value:null,disabled:true}),
      // nearSubjOs:new FormControl({value:null,disabled:true})
    })
  }

  togglePanels(state: boolean) {
    if (state) {
      this.showPanelList.push('job');
      this.showPanelList.push("JobInfo");
    }
    else {
      this.showPanelList = [];
    }

  }
  toggleSinglePanel(panel: string) {
    const index = this.showPanelList.indexOf(panel, 0);
    if (index > -1) {
      this.showPanelList.splice(index, 1);
    }
    else {
      this.showPanelList.push(panel);
    }
  }

  showPanel(panel: string) {
    return this.showPanelList.indexOf(panel, 0) > -1;
  }

  reset() {
    this.createFormGroup();
    this.advancePatientSearchModel = new AdvancePatientSearchModel()
  }

  insertIdenfiers(event: any) {
    if (this.identifier.findIndex(x => x.name.toLowerCase() == event.target.value.toLowerCase()) == -1) {
      let identifierModel = new IdentifierModel();
      identifierModel.name = event.target.value;
      this._lookupService.insertIdentifier(identifierModel).subscribe((res) => {
        this.getIdentifier();
      }, (error) => {
        this.toaster.error(error.error);
      });
    }
    else {
      this.toaster.error('Identifier Already Exists.');
    }
  }

  getAllLookup() {
    this.getIdentifier();
    this.getPatientInsurance();
    this.getGlobalLookup();
    this.getOffice();
  }

  getIdentifier() {
    this._lookupService.getIdentifierLookup().subscribe((res: any) => {
      this.identifier = res;
    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  getPatientInsurance() {
    
    // this._patientService.getPatientInsurance().subscribe((res: any) => {
    //   this.patientInsurance = res;
    // })
    this._lookupService.getInsuarance().subscribe((res: any) => {
      this.rowInsurance = res;
    })
  }

  onSelectAll(event: any) {
    
  }

  searchPatient() {
    
    this.advancePatientSearchModel.time_zone = -(new Date().getTimezoneOffset() / 60);
    this._patientService.advancePatientSearch(this.advancePatientSearchModel).subscribe((res: any) => {
      this.rowPatient = res;
      this.isShowPatientList = true;
    }, (error) => {

    })
    // this._patientService.getPatientList('',0,0).subscribe((res:any)=>{
    //     this.rowPatient = res;
    //   },(error)=>{

    //   })
  }

  updatePatient(row: any) {
    this.patientId = Number(row.data.patientID);
    this.router.navigate(['/patient/create'], { queryParams: { patientId: this.patientId } });
  }

  hide(check: any, field: string) {
    this.patientGrid.columnApi.setColumnVisible(field, check.target.checked);
  }

  onGridReady(params: any) {
    this.patientGridApi = params.api;
    this.patientGridColumnApi = params.columnApi;
    //this.patientGridApi.setDatasource(this.dataSource)
  }

  exportAsExcel(filename?: string): void {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'PatientData',
      // columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.patientGridApi.exportDataAsCsv(params);
  }

  generateColumnsForExcel(): string[] {
    const keys = this.patientGridColumnApi
      .getAllDisplayedColumns()
      .map((column: any) => column.getColId())

    return keys;
  }

  getGlobalLookup() {
    let loadingProvider = new ProviderModel();
      loadingProvider.active_ind = true;
      loadingProvider.provider_id = '-1';
      loadingProvider.first_name_txt = '';
      loadingProvider.last_name_txt = '';
      loadingProvider.display_txt = 'Loading Records.....';
      this.providerModel.push(loadingProvider);
      this.referringDoctor.push(loadingProvider);
      
    this._lookupService.getGlobalLookup('race').subscribe((res: any) => {      
      
      this.raceLookup = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.raceLookup = this.raceLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.raceLookupCopy = res.filter((x: { group_txt: string; }) => x.group_txt == 'race');
    }, (error) => {
      this.toaster.error(error.error);
    });
    this._lookupService.getGlobalLookup('ethnicity').subscribe((res: any) => {
      this.ethnicityLookup = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.ethnicityLookup = this.ethnicityLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.ethnicityLookupCopy = res.filter((x: { group_txt: string; }) => x.group_txt == 'ethnicity');
    }, (error) => {
      this.toaster.error(error.error);
    });
    this._lookupService.getGlobalLookup('language').subscribe((res: any) => {
      this.languageLookup = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.languageLookup = this.languageLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.languageLookupCopy = res.filter((x: { group_txt: string; }) => x.group_txt == 'language');
    }, (error) => {
      this.toaster.error(error.error);
    });
    this._lookupService.getGlobalLookup('contactmethod').subscribe((res: any) => {
      this.contactmethodLookup = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.contactmethodLookup = this.contactmethodLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.contactmethodLookupCopy = res.filter((x: { group_txt: string; }) => x.group_txt == 'contactmethod');
    }, (error) => {
      this.toaster.error(error.error);
    });
    
    this._lookupService.getGlobalLookup('plan').subscribe((res: any) => {
      this.plan = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.plan = this.plan.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.planCopy = res.filter((x: { group_txt: string; }) => x.group_txt == 'plan');
    }, (error) => {
      this.toaster.error(error.error);
    });
    this._lookupService.getGlobalLookup('assessment').subscribe((res: any) => {
      this.assessment = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.assessment =this.assessment = this.assessment.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.assessmentCopy = res.filter((x: { group_txt: string; }) => x.group_txt == 'assessment');
    }, (error) => {
      this.toaster.error(error.error);
    });    
    let practiceId = localStorage.getItem('practice')?.toString();
      practiceId = practiceId != undefined ? practiceId : "";
    this._lookupService.getRenderingProvider(practiceId).subscribe((res: any) => {
      this.providerModel = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.providerModel = res;
      // this.referringDoctor = this.providerModel.filter(x => x.active_ind == true)
      this.referringDoctor = res.filter((x: { active_ind: boolean; }) => x.active_ind == true);
      this.referringDoctor = res;
    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  getOffice(){
    this._lookupService.getOffice().subscribe((res: any) => {
      this.office = res;
    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getGlobalLookup()
      // this.sortArray(item)
    })
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }

  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any,name_txt:string, item: LookupModel[], group_txt?: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {    
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
      // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
      // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
      if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = 'All';
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }
      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {
            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getAllLookup();
              this.toaster.success('Record Saved Successfully.');
              
            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toaster.success('Record Saved Successfully.');
          //     this.getAllLookup();
          //   })
          // }
        }
      })
    }
    
  } 
  }
  this.check_value = "";
  }

  CreateNew(city:string){          
  }

  editOffice(){
    const initialState = {
      componentName : 'General',
      title: 'Edit Facilities'
    };
    this.bsModalRef = this.modalService.show(ComponentModalComponent, { initialState });
    this.bsModalRef.setClass('modal-xl')
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getOffice();
    })
  }

}
