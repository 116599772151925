<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="patinetFormGroup">
        <div class="row">
            <div class="col-md-6">
                <label>*Date of Entry:</label>
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="dateOfEntry" (ngModelChange)="patientModel.dateOfEntry = $event" [ngModel]="patientModel.dateOfEntry | date:'MM-dd-yyyy'" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>*Last Name:</label>
                <input type="text" formControlName="lastName" [(ngModel)]="patientModel.lastName" class="form-control">
            </div>
            <div class="col-md-6">
                <label>*First Name:</label>
                <input type="text" formControlName="firstName" [(ngModel)]="patientModel.firstName" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Date of Birth:</label>
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="dateOfBirth" [(ngModel)]="patientModel.dateOfBirth" (ngModelChange)="patientModel.dateOfBirth = $event" [ngModel]="patientModel.dateOfBirth | date:'MM-dd-yyyy'"
                    class="form-control">
            </div>
            <div class="col-md-6">
                <label>Sex:</label>
                <!-- <select class="form-control" formControlName="sex" [(ngModel)]="patientModel.sex">
        <option value="U">Unknown</option>
        <option value="M">Male</option>
        <option value="F">Female</option>
      </select> -->
                <ng-select [clearable]="true" formControlName="sex" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="patientModel.sex">
                </ng-select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Address1:</label>
                <input type="text" formControlName="address" [(ngModel)]="patientModel.address" class="form-control">
            </div>
            <div class="col-md-6">
                <label>Address2:</label>
                <input type="text" formControlName="address2" [(ngModel)]="patientModel.address2" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>Email Address:</label>
                <input type="text" formControlName="emailaddress" [(ngModel)]="patientModel.emailaddress" class="form-control">
            </div>
            <div class="col-md-6">
                <label>Home Phone:</label>
                <input type="text" formControlName="homePhone" [(ngModel)]="patientModel.homePhone" class="form-control">
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" [disabled]="patinetFormGroup.invalid" (click)="save()">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>