import { OMR_FeeStatementPaymentModel } from './omr_FeeStatementPaymentModel';
export class OMR_FeeStatementItemModel {
    public fee_statement_item_id: string = '';
    public fee_statement_id: string = '';
    public dx_number_txt: string = '';
    public dos_dt: string = '';
    public code_txt: string = '';
    public product_name_txt: string = '';
    public description_txt: string = '';
    public unit_amt: number = 0;
    public unit_cnt: number = 1;
    public tax_rate_amt: number = 0;
    public tax_amt: number = 0;
    public discount_per_amt: number = 0;
    public discount_amt: number = 0;
    public fee_amt:  number = 0;
    public pat_total: number = 0;
    public ins_total: number = 0;
    public status_txt: string = '';
    public created_dt: string = '';
    public created_by: string = '';
    public updated_dt: string = '';
    public updated_by: string = '';
    public order_val: number = 0;
    public item_id: string = '';
    public isShow:boolean = false;
    public omR_FeeStatementPayment: OMR_FeeStatementPaymentModel[] = [];
}
