import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-modal',
  templateUrl: './component-modal.component.html',
  styleUrls: ['./component-modal.component.scss']
})
export class ComponentModalComponent implements OnInit {
  
  title : string = '';
  componentName : string = '';
  Heigth:string='';
  width:string='';
  isProductModel: boolean = false;
  isShowSaveButton: boolean = true;  

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    
    if (this.componentName=="General") {      
    } 
    else
    {      
      // this.Heigth=window.screen.availHeight+"px";
      // // this.width=window.screen.availWidth-600+"px";
      // this.width=window.screen.availWidth-400+"px";
      this.Heigth = 'max-content';
      this.width = this.isProductModel ? '968px' : '100%';
    }
  }
  close() {
    
    this.bsModalRef.hide();
    if(this.isProductModel){
      window.location.reload();
    }
  }

}

