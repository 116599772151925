<div id="content">
    <div class="titlebox">Exam History</div>
    <br>
    <div class="d-flex pt-3">
        <button class="btn btn-primary px-4" (click)="navigate()">
      New
    </button> &nbsp;
        <button class="btn btn-primary px-4" style="display: none;">
      Print
    </button> &nbsp;
        <!-- <button class="btn btn-primary px-4">
      Declaine Summary
    </button> &nbsp; -->
        <button class="btn btn-primary px-4" (click)="deleteExam()">
      Delete
    </button>
    </div>
    <div class="pt-2 pl-2">
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" #examGrid style="height: 500px; width: 100%;" (rowDoubleClicked)="navigate($event)" class="ag-theme-alpine" [rowData]="rowExam" [columnDefs]="columnExam" [rowSelection]="'multiple'" (gridReady)="onGridReady($event)" (selectionChanged)="getId()">
        </ag-grid-angular>
    </div>
</div>