export class OMR_HCFAFormModel {
    public exam_ID: string = '';
    public patient_ID: string = '';
    public picaleft: string = '';
    public picaright: string = '';
    public medicare: boolean = false;
    public medicaid: boolean = false;
    public tricarechampus: boolean = false;
    public champva: boolean = false;
    public healthplan: boolean = false;
    public fecablclung: boolean = false;
    public other: boolean = false;
    public insurednumber: string = '';
    public patientname: string = '';
    public patientbirthdate: string = '';
    public sexm: boolean = false;
    public sexf: boolean = false;
    public insuredname: string = '';
    public patientaddress: string = '';
    public relationshipself: boolean = false;
    public relationshipspouse: boolean = false;
    public relationshipchild: boolean = false;
    public relationshipother: boolean = false;
    public insuredaddress: string = '';
    public insuranceDate: string = '';    
    public city: string = '';
    public state: string = '';
    public zip: string = '';
    public telephone: string = '';
    public statussingle: boolean = false;
    public statusmarried: boolean = false;
    public statusother: boolean = false;
    public statusemployed: boolean = false;
    public statusfullstudent: boolean = false;
    public statuspartstudent: boolean = false;
    public citY2: string = '';
    public statE2: string = '';
    public ziP2: string = '';
    public telephonE2: string = '';
    public otherinsuredname: string = '';
    public conditionrelated: string = '';
    public insuredpolicygroup: string = '';
    public otherinsuredgroup: string = '';
    public otherinsureddateofbirth: string = '';
    public othersexm: boolean = false;
    public othersexf: boolean = false;
    public emplnameorschoolnamE2: string = '';
    public programname: string = '';
    public employmentyes: boolean = false;
    public employmentno: boolean = false;
    public autoaccidentyes: boolean = false;
    public autoaccidentnot: boolean = false;
    public placestate: string = '';
    public otheraccidentyes: boolean = false;
    public otheraccidentnot: boolean = false;
    public otherinsureddateofbirtH2: string = '';
    public othersexM2: boolean = false;
    public othersexF2: boolean = false;
    public emplnameorschoolname: string = '';
    public programnamE2: string = '';
    public isbenefitplanyes: boolean = false;
    public isbenefitplanno: boolean = false;
    public reservedlocal: string = '';
    public signeD1: string = '';
    public datesigneD1: string = '';
    public signeD2: string = '';
    public dateofcurrent: string = '';
    public firstdate: string = '';
    public workdatefrom: string = '';
    public workdateto: string = '';
    public nameofreferring: string = '';
    public fielD17ASHOT: string = '';
    public fielD17ALONG: string = '';
    public fielD17BNPI: string = '';
    public workdatefroM2: string = '';
    public workdatetO2: string = '';
    public reservedlocaL2: string = '';
    public outsidelabyes: boolean = false;
    public outsidelabno: boolean = false;
    public chargesfirst !: number ;
    public chargessecond !: number ;
    public medicaidcode: string = '';
    public medicaidoroginalno: string = '';
    public priornumber: string = '';
    public relatE1: string = '';
    public relatE2: string = '';
    public relatE3: string = '';
    public relatE4: string = '';
    public relatE5: string = '';
    public dateofservicefroM1: string = '';
    public dateofservicetO1: string = '';
    public placeservicE1: string = '';
    public emG1: string = '';
    public cpthcpcS1: string = '';
    public modifieR1: string = '';
    public diagnosispointeR1: string = '';
    public chargesfirsT1 !: number ;
    public chargesseconD1 !: number ;
    public unitS1: string = '';
    public epsdT1: string = '';
    public quaL1: string = '';
    public renderinG1: string = '';
    public renderingnpI1: string = '';
    public dateofservicefroM2: string = '';
    public dateofservicetO2: string = '';
    public placeservicE2: string = '';
    public emG2: string = '';
    public cpthcpcS2: string = '';
    public modifieR2: string = '';
    public diagnosispointeR2: string = '';
    public chargesfirsT2!: number ;
    public chargesseconD2!: number ;
    public unitS2: string = '';
    public epsdT2: string = '';
    public quaL2: string = '';
    public renderinG2: string = '';
    public renderingnpI2: string = '';
    public dateofservicefroM3: string = '';
    public dateofservicetO3: string = '';
    public placeservicE3: string = '';
    public emG3: string = '';
    public cpthcpcS3: string = '';
    public modifieR3: string = '';
    public diagnosispointeR3: string = '';
    public chargesfirsT3!: number ;
    public chargesseconD3!: number ;
    public unitS3: string = '';
    public epsdT3: string = '';
    public quaL3: string = '';
    public renderinG3: string = '';
    public renderingnpI3: string = '';
    public dateofservicefroM4: string = '';
    public dateofservicetO4: string = '';
    public placeservicE4: string = '';
    public emG4: string = '';
    public cpthcpcS4: string = '';
    public modifieR4: string = '';
    public diagnosispointeR4: string = '';
    public chargesfirsT4!: number ;
    public chargesseconD4!: number ;
    public unitS4: string = '';
    public epsdT4: string = '';
    public quaL4: string = '';
    public renderinG4: string = '';
    public renderingnpI4: string = '';
    public dateofservicefroM5: string = '';
    public dateofservicetO5: string = '';
    public placeservicE5: string = '';
    public emG5: string = '';
    public cpthcpcS5: string = '';
    public modifieR5: string = '';
    public diagnosispointeR5: string = '';
    public chargesfirsT5!: number ;
    public chargesseconD5!: number ;
    public unitS5: string = '';
    public epsdT5: string = '';
    public quaL5: string = '';
    public renderinG5: string = '';
    public renderingnpI5: string = '';
    public dateofservicefroM6: string = '';
    public dateofservicetO6: string = '';
    public placeservicE6: string = '';
    public emG6: string = '';
    public cpthcpcS6: string = '';
    public modifieR6: string = '';
    public diagnosispointeR6: string = '';
    public chargesfirsT6!: number ;
    public chargesseconD6!: number ;
    public unitS6: string = '';
    public epsdT6: string = '';
    public quaL6: string = '';
    public renderinG6: string = '';
    public renderingnpI6: string = '';
    public federaltaxnumber: string = '';
    public ssncheck: boolean = false;
    public eincheck: boolean = false;
    public patientaccountno: string = '';
    public acceptassignmentyes: boolean = false;
    public acceptassignmentno: boolean = false;
    public totalchargefirst: string = '';
    public totalchargesecond: string = '';
    public amountpaidfirst: string = '';
    public amountpaidsecond: string = '';
    public balanceduefirst: number = 0;
    public balanceduesecond: number = 0;
    public signeD3: string = '';
    public datesigneD3: string = '';
    public serviceinfo: string = '';
    public serviceinfoa: string = '';
    public serviceinfob: string = '';
    public phoneno: string = '';
    public providerinfo: string = '';
    public providerinfoa: string = '';
    public providerinfob: string = '';
    public createddate: Date = new Date();
    public LASTUPDATEDATE: Date = new Date();
    public electronicSubmission: string = '';
    public electronicSubmissionStatus: string = '';
    public relatE6: string = '';
    public relatE7: string = '';
    public relatE8: string = '';
    public relatE9: string = '';
    public relatE10: string = '';
    public relatE11: string = '';
    public relatE12: string = '';
    public nameofreferrinG2: string = '';
    public iquaL1: string = '';
    public iquaL2: string = '';
    public iquaL3: string = '';
    public icD1: string = '';
    public icD2: string = '';
    public telephoneArea: string = '';
    public telephoneArea2: string = '';
}
