import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ManufacturerModel } from '../model/ManufacturerModel';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {

  constructor(private http:HttpClient) { }

  getAll():Observable<ManufacturerModel[]>
  {
    return this.http.get<ManufacturerModel[]>(environment.server+'api/manufacturer/getall');
  }

  insertManufacturer(manufacture:ManufacturerModel){
    return this.http.post<ManufacturerModel>(environment.server+'api/manufacturer/add',manufacture);
  }

  updateManufacturer(manufacture:ManufacturerModel){
    return this.http.post<ManufacturerModel>(environment.server+'api/manufacturer/update',manufacture);
  }

}
