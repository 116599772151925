import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ExamModel } from 'src/app/model/ExamModel';
import { OMR_EXAMModel } from 'src/app/model/OMR_EXAMModel';
import { PatientModel } from 'src/app/model/patientModel';
import { ClinicalService } from 'src/app/services/clinical.service';

@Component({
  selector: 'app-pre-iop',
  templateUrl: './pre-iop.component.html',
  styleUrls: ['./pre-iop.component.scss']
})
export class PreIopComponent implements OnInit {

  columnTonometry:any;
  rowTonometry:OMR_EXAMModel[] = [];
  rowExam:ExamModel[] = [];
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  constructor(private _clinicalService : ClinicalService) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
  }
  ngOnInit() {
    this.columnTonometry = [
      {
        headerName: 'Exam Date',
        field: 'exam_Date', sortable: true, resizable: true, rowDrag: true, filter: true, floatingFilter: false,width:150
        , cellRenderer: (data:any) => {
          return moment(data.value).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Chief Complaint',
        field: 'cheif_Complaint', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:200
      },
      {
        headerName: 'OD',
        field: 'omR_Exam.tonometryOD', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:140
      },
      {
        headerName: 'OS',
        field: 'omR_Exam.tonometryOS', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:140
      },
      {
        headerName: 'Test',
        // field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:150
        field: 'omR_Exam.tonometry', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:150
      },
      {
        headerName: 'Time',
        field: 'omR_Exam.tonometryTime', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:150
      },
      {
        headerName: 'OD C/D',
        field: 'omR_Exam.cdod', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:150
      },
      {
        headerName: 'OS C/D',
        field: 'omR_Exam.cdos', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:150
      }
    ];
    this.getExam();
    // this.rowTonometry = [];
  }

  getExam(){    
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.rowTonometry = res;
    })
  }

  navigate(event:any){
    // this._clinicalService.navigateToExam(this.patientId,event);
    if(event){
      this._clinicalService.navigateToExam(this.patientId,event.data);
    }
    else{
      this._clinicalService.navigateToExam(this.patientId,new ExamModel());
    }
  }

}
