import { ExamModel } from './ExamModel';
export class Exam_MedRXModel{


    public exam_MedRX_ID : string = '';
    public PatientID : number = 0;
    public expirationDate : Date = new Date();
    public Notes : string = '';
    public Label : boolean = false;
    public DoctorsSignature : string = '';
    public License : string = '';
    public DoctorName  : string = '';
    public CreationDate : Date = new Date();
    public LastUpdateDate : Date = new Date();
    public doctorID : string = '0';
    public UsedEPrescribe : boolean = false;
    public exam : ExamModel = new ExamModel();
}