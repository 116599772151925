export class PrintHeaderModel {
    public officeName: string = '';
    public officeAddress: string = '';
    public officeCitySZ: string = '';
    public officePhone: string = '';
    public license: string = '';
    public NPI: string = '';
    public heading: string = '';
    public examDate: Date = new Date();
    public firstName: string = '';
    public lastName: string = '';
    public serviceDate: Date = new Date();
    public birthDate:  Date = new Date();    
}