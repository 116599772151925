import { GlobalService } from './../../../../services/global.service';
import { LookupService } from './../../../../services/lookup.service';
import { ToastrService } from 'ngx-toastr';
import { PatientModel } from './../../../../model/patientModel';
import { AttachmentModel } from './../../../../model/AttachmentModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LookupModel } from 'src/app/model/lookupModel';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnInit {
  title: string = '';
  closeBtnName: string = '';
  callingComponent: string = '';
  attachmentModel: AttachmentModel[] = [];
  patientModel: PatientModel = new PatientModel();
  patientId: string = '';
  attachmentLookup: LookupModel[] = [];
  attachmentLookupCopy: LookupModel[] = [];
  check_value: string = '';
  

  constructor(public bsModalRef: BsModalRef, private _clinicalService: ClinicalService, 
    private toastr: ToastrService, private _lookupService: LookupService,
    private globalService : GlobalService) {          
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!);
    if (this.patientModel!=null) {
      this.patientId = this.patientModel.patient_id;
    }
   
  }

  ngOnInit() { 
       
    var attachment = new AttachmentModel();
    attachment.patient_id = this.patientId;
    if(this.callingComponent == 'CreatePatient'){
      attachment.type_txt = "Insurance Card"
    }
    this.attachmentModel.push(attachment);
    this.getLookup();
  } 

  
  save() {
    this._clinicalService.saveAttachment(this.attachmentModel).subscribe(res => {     
      this.toastr.success('Record Saved Successfully.');
      this.bsModalRef.hide();
    }, (error) => {
      this.toastr.error(error.error.message);
    })
  }

  
  getLookup() {
    this._lookupService.getGlobalLookup('attachmenttype').subscribe((res: LookupModel[]) => {
      this.attachmentLookup = res.filter(x => x.sub_group_txt == 'Patient' && x.active_ind == true).sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.attachmentLookupCopy = res.filter(x => x.sub_group_txt == 'Patient' && x.active_ind == true).sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      })
    })
  }

  onFileChanged(event: any) {
    this.attachmentModel[0].path_resource_txt = event.target.files[0].name
    var element = event.target.files[0];
    this.attachmentModel[0].file_dat = (element);
    
  }

  

  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      this.getLookup()
      // this.sortArray(item)
    })
  }
  

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   //console.log(event);
  // }

   CreateNew(city:string){          
  }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }

  saveDropdown(val: any,  name_txt: string, item: LookupModel[], group_txt?: string,sub_group_txt:string = 'All', dbType: number = DbEnum.QE_DEV_SQL_DB,item1?: any, property?: any) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = sub_group_txt; 
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val;

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }

      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {

            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getLookup();
              this.toastr.success('Record Saved Successfully.');
              item1[property]= val;//this.check_value;
              
            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toastr.success('Record Saved Successfully.');
          //     item1[property]=this.check_value;
          //     this.getLookup();
          //   })
          // }
        }
      })
    }
    item1[property] = val;
  } else {
    item1[property] = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }


}
