<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" routerLink="/patient/add-exam" [queryParams]="{patientId:patientData.patientID,examId : examId}">
                <!-- <img src="../../../../assets/img/close.png" style="height: 30px;width: 30px;"> -->
                Cancel Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(false)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save Exam
            </button>&nbsp;&nbsp;
            <button class="button" style="height: 68px;"(click)="save(true)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save & Close
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
              <!-- <img src="../../../../assets/img/print_printer.png" style="height: 30px;width: 30px;"> -->
              Print
          </button> &nbsp;&nbsp;
            <hr>
        </div>
    </div>
</div>
<div class="field mt-7">
    <div class="titlebox"></div>
    <div class="row pr-5 pl-5 pt-5">
        <div class="col-lg-1">
            <label style="font-weight: bold;">Patient Name:</label>
        </div>
        <div class="col-md-2">
            <label>{{patientData.firstName}} {{patientData.lastName}}</label>
        </div>
        <!-- <div class="col-md-1">
            <label>Birthdate:</label>
        </div>
        <div class="col-md-2">
            <label>{{patientData.dateOfBirth | date:'MM-dd-yyyy'}}</label>
        </div> -->
        <div class="col-md-2" *ngIf="age>0">
            <label style="font-weight: bold;">Age:</label>
        </div>
        <div class="col-md-2 col-sm-3" *ngIf="age>0">
            <label>{{age}}</label>
        </div>
    </div>
    <div class="row pr-5 pl-5">
        <div class="col-md-1">
            <label style="font-weight: bold;">Birthdate:</label>
        </div>
        <div class="col-md-3">
            <label>{{patientData.dateOfBirth | date:'MM-dd-yyyy'}}</label>
        </div>
    </div>
    <div class="row pr-5 pl-5">
        <div class="col-md-1">
            <label style="font-weight: bold;">Address:</label>
        </div>
        <div class="row col-md-11">
            <div class="row col-12">
                <div class="col-md-6">
                    <label *ngIf="patientData.address">{{patientData.address}}</label>
                    <label *ngIf="patientData.city">-{{patientData.city}},</label>
                    <label *ngIf="patientData.state">{{patientData.state}}</label>
                    <label *ngIf="patientData.zip">-{{patientData.zip}}</label>
                </div>
                <div class="col-md-4">
                    <div class="form-row">
                        <div class="col-md-5">
                            <label class="mx-3" style="font-weight: bold;">Expiration:</label>
                        </div>
                        <div class="col-md-6">
                            <!-- <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="exam_MedRXModel.expirationDate = $event" [ngModel]="exam_MedRXModel.expirationDate | date:'MM-dd-yyyy'" class="form-control"> -->
                            <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                            (ngModelChange)="exam_MedRXModel.expirationDate = $event"
                            [ngModel]="exam_MedRXModel.expirationDate | date:'MM-dd-yyyy'"
                            class="form-control">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row pr-5 pl-5">
        <div class="col-md-1">
            <label style="font-weight: bold;">Date:</label>
        </div>
        <div class="col-md-3">
            <label>{{examModel.exam_Date | date:'MM-dd-yyyy'}}</label>
        </div>
    </div>
      <br>
    <div id="content">
        <div class="titlebox">MedRx</div>
        <div class="col-12 p-0" >
            <table class="table">
              <tr>
                  <th style="width:6%;"></th>
                  <th style="width:5% !important">Print</th>
                  <th style="width:12%;">Product</th>
                  <th style="width:15%;">Generic Name</th>
                  <th style="width:15%;">Brand Name</th>
                  <th style="width:12%;">Strength</th>
                  <th style="width:10%;">Dose</th>
                  <th style="width:12%;">Route</th>
                  <th style="width:13%;">Frequency</th>
                  <th style="width:9%;">#</th>
                  <th style="width:9%;">Refill</th>
                  <th style="width:10%;">Active</th>
              </tr>

              <tr *ngFor="let rx of rxModel;let i=index">
                <td> <a  href="javascript:void(0)" (click)="DeleteRx(rx,i)">Remove>></a></td>
                  <td>
                      <input type="checkbox" [(ngModel)]="rx.print_ind" class="form-check-input m-3">
                  </td>
                  <td>
                    <div class="form-row">
                      <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="productLookup" class="col-md-10 p-0"
                      (keyup)="keyFunc($event,rx,'product_txt')"
                      (keydown.tab)="saveDropdown(rx.product_txt,'Product',productLookup,'rxproduct',rx,'product_txt')" bindLabel="name_txt" bindValue="name_txt"
                      [(ngModel)]="rx.product_txt">
                      </ng-select>
                      <a href="javascript:void(0)" (click)="editLookup(productLookup,productLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="genericLookup" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'generic_name_txt')"
                          (keydown.tab)="saveDropdown(rx.generic_name_txt,'Generic Name',genericLookup,'medicationname',rx,'generic_name_txt','genericname')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.generic_name_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(genericLookup,genericLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="brandLookup" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'brand_name_txt')"
                          (keydown.tab)="saveDropdown(rx.brand_name_txt,'Brand Name',brandLookup,'medicationname',rx,'brand_name_txt','brandname')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.brand_name_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(brandLookup,brandLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="strengthLookup" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'strength_txt')"
                          (keydown.tab)="saveDropdown(rx.strength_txt,'Strength',strengthLookup,'rxstrength',rx,'strength_txt')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.strength_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(strengthLookup,strengthLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="doseLookup" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'dose_txt')"
                          (keydown.tab)="saveDropdown(rx.dose_txt,'Dose',doseLookup,'dose',rx,'dose_txt')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.dose_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(doseLookup,doseLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="routeLookup"
                          (keyup)="keyFunc($event,rx,'route_txt')"
                          (keydown.tab)="saveDropdown(rx.route_txt,'Route',routeLookup,'route',rx,'route_txt')"  class="col-md-10 p-0" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.route_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(routeLookup,routeLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="rxfrequency" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'frequency_txt')"
                          (keydown.tab)="saveDropdown(rx.frequency_txt,'Frequency',rxfrequency,'rxfrequency',rx,'frequency_txt')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.frequency_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(rxfrequency,rxfrequencyCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="rxNumberLookup" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'rx_norm_code_txt')"
                          (keydown.tab)="saveDropdown(rx.rx_norm_code_txt,'#',rxNumberLookup,'rxnumber',rx,'rx_norm_code_txt')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.rx_norm_code_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(rxNumberLookup,rxNumberLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <div class="form-row">
                          <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="refilLookup" class="col-md-10 p-0"
                          (keyup)="keyFunc($event,rx,'refill_times_txt')"
                          (keydown.tab)="saveDropdown(rx.refill_times_txt,'Refill',refilLookup,'refill',rx,'refill_times_txt')" bindLabel="name_txt" bindValue="name_txt"
                          [(ngModel)]="rx.refill_times_txt">
                          </ng-select>
                          <a href="javascript:void(0)" (click)="editLookup(refilLookup,refilLookupCopy,dbEnum.QE_DEV_SQL_DB)"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                      </div>
                  </td>
                  <td>
                      <!-- <ng-select [clearable]="true" class="col-md-12" [(ngModel)]="rx.status_txt">
                        <option>Active</option>
                        <option>Inactive</option>
                        <option>Discontinued</option>
                      </ng-select> -->
                      <select class="form-control" [(ngModel)]="rx.status_txt">
                      <option>Active</option>
                      <option>Inactive</option>
                      <option>Discontinued</option>
                  </select>
                  </td>
              </tr>
            </table>
        </div>
    </div>
    <div class="py-2">
        <button class="btn btn-primary m-2 px-4" (click)="addRow()">Add</button>
    </div>
    <div class="row py-2">
      <div class="col-md-4">
        <div class="col-md-12">


          <!-- <ng-select [clearable]="true" [(ngModel)]="exam_MedRXModel.doctorID" placeholder="--- Select ---" #myUserSelect (change)="onSelectUser(exam_MedRXModel.doctorID)">
                    <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                        {{userInfo.display_txt}}
                    </ng-option>
                </ng-select> -->

          <ng-select [clearable]="true" placeholder="Select Provider" [(ngModel)]="examModel.rx_provider_id" #myUserSelect
            (change)="onSelectUser(examModel.rx_provider_id)">
            <ng-option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
              {{userInfo.display_txt}}
            </ng-option>
          </ng-select>
          <!-- <div class="col-md-12 p-0">
                  <select class="form-control m-1" #myUserSelect (change)="onSelectUser(myUserSelect.value)"
                      [ngModel]="user.user_id">
                      <option value="">
                          --- Select ---
                      </option>
                      <option *ngFor="let userInfo of userList" [value]="userInfo.user_id">
                          {{userInfo.display_txt}}
                      </option>
                  </select>
              </div> -->

          <div class="col-md-12">
            <img [src]="imgURL" height="120px" *ngIf="imgURL">
          </div>

        </div>

        <div class="col-md-12">
          Doctor Name : <label>{{user.display_txt}}</label>
        </div>
        <div class="col-md-12">
          License : <label>{{user.license_txt}}</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="row py-2">
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
              <a href="https://eprescribe.allscripts.com/Login.aspx" target='_blank' style="color: white;">ePrescribe
                Now</a>
            </button>
          </div>
        </div>
        <br>
        <div class="row py-2">
          <div class="col-md-12 d-flex justify-content-center">
            <div id="content" style="height: 100px;width: 90% ;padding-bottom: 4px;">
              <div class="titlebox">ePrescribe Administration
              </div>
              <br>
              <div class="d-flex justify-content-center p-2">
                <button (click)="exportPatients()" class="btn btn-primary" style="border-radius: 20px;color: blue;">
                  Export Patients to ePrescribe
                </button>&nbsp;
                <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
                  <a href="https://demo.quikeyes.com/Materials/ePrescribeManual.pdf" target='_blank'
                    style="color: white;">ePrescribe User Manual</a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div id="content" class="">
        <div class="titlebox">Medical Rx History/Orders</div>
        <br />
        <div>
          <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height: 500px; width: 100%;" class="ag-theme-alpine" [rowData]="rowMedicalHistory" [columnDefs]="columnMedicalHistory" [rowStyle]="rowStyle">
          </ag-grid-angular>
        </div>
    </div>
</div>

<div style="overflow: hidden;">
  <div id="print-section" hidden>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officename"> {{officename}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
    </div>
    <div class="col-12 text-center">
        <span class="doc-info">NPI # {{office_npi_txt}}</span>
    </div>

    <!-- <div class="col-12 text-center doc-info">
        <span class="doc-info">NPI # {{License}}</span>
    </div> -->
    <br>
    <!-- <div class="text-center heading-info">Medical <b><img src="../../../../assets/img/Rx.png"></b></div> -->
    <h4 class="text-center"><b>Medical <img src="../../../../assets/img/Rx.png"></b></h4><br>
    <!-- <b><hr></b> -->
    <div class="row col-12">
        <div class="col-6">
            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Rx Date:</label>
                </div>
                <div class="col-5 pl-0">
                    <label class="patient-reg-info">{{examModel.exam_Date | date:'MM-dd-yyyy'}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Patient Name:</label>
                </div>
                <div class="col-5 pl-0">
                    <label class="patient-reg-info">{{patientData.firstName}} {{patientData.lastName}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Pt. Address:</label>
                </div>
                <div class="col-5 pl-0">
                    <div>
                        <label class="patient-reg-info" *ngIf="patientData.address">{{patientData.address}}</label>
                    </div>
                    <label class="patient-reg-info" *ngIf="patientData.city">{{patientData.city}},&nbsp;</label>
                    <label class="patient-reg-info" *ngIf="patientData.state">{{patientData.state}}</label>
                    <label class="patient-reg-info" *ngIf="patientData.zip">&nbsp;{{patientData.zip}}</label>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Expiration:</label>
                </div>
                <div class="col-7 pl-0">
                    <label class="patient-reg-info">{{exam_MedRXModel.expirationDate | date:'MM-dd-yyyy'}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Birthdate:</label>
                </div>
                <div class="col-7 pl-0">
                    <label class="patient-reg-info">{{patientData.dateOfBirth | date:'MM-dd-yyyy'}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-3 pr-0">
                    <label class="patient-info">Pt. Phone:</label>
                </div>
                <div class="col-7 pl-0">
                    <label class="patient-reg-info">{{patientData.cellPhone}}</label>
                </div>
            </div>
        </div>
    </div>


    <br>
    <!-- <b><hr style="color: black !important;"></b> -->
    <div class="col-12" style="border: 1px solid black;"></div>
    <!-- <img src="../../../../assets/img/Rx.png"> -->
    <h4 style="margin-top: 5px;"><b><img src="../../../../assets/img/Rx.png"></b></h4>
    <br>
    <table style="width:100%">
        <tr style="line-height: 40px !important;">
          <th class="heading-background">Product</th>
          <th class="heading-background">Generic Name</th>
          <th class="heading-background">Brand Name</th>
          <th class="heading-background">Strength</th>
          <th class="heading-background">Dose</th>
          <th class="heading-background">Route</th>
          <th class="heading-background">Frequency</th>
          <th class="heading-background">#</th>
          <th class="heading-background">Refill</th>
      </tr>
      <tr *ngFor="let rx of rxModel;let i=index" style="border:1px solid gray !important;line-height: 40px !important;">
        <td style="border:1px solid gray !important">
            {{rx.product_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.generic_name_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.brand_name_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.strength_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.dose_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.route_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.frequency_txt}}
        </td>
        <td style="border:1px solid gray !important">
          {{rx.rx_norm_code_txt}}
        </td>
        <td>
          {{rx.refill_times_txt}}
        </td>
      </tr>
    </table>
    <br>
    <br>
    <div class="row">
      <div class="col-6">
        <label style="font-weight: bold;">Signature: </label><img [src]="imgURL" height="120px" *ngIf="imgURL">
      </div>
      <div class="cold-m-6">
        <label style="font-weight: bold;">Doctor Name: </label> {{user.display_txt}}
        <br>
        <label style="font-weight: bold;">License #: </label> {{user.license_txt}}
      </div>
    </div>
  </div>
</div>
