import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ExamModel } from 'src/app/model/ExamModel';
import { PatientModel } from 'src/app/model/patientModel';
import { ClinicalService } from 'src/app/services/clinical.service';

@Component({
  selector: 'app-pre-specorder',
  templateUrl: './pre-specorder.component.html',
  styleUrls: ['./pre-specorder.component.scss']
})
export class PreSpecorderComponent implements OnInit {

  columnSpecOrder: any;
  rowSpecOrder: ExamModel[] = [];
  patientModel : PatientModel = new PatientModel();
  patientId : string = '';
  constructor(private _clinicalService : ClinicalService) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!);
    this.patientId = this.patientModel.patient_id;
  }

  ngOnInit() {
    this.columnSpecOrder = [
      {
        headerName: 'Date',
        field: 'exam_Date', sortable: true, resizable: true, rowDrag: true, width: 150,
        cellRenderer: (data:any) => {
          return moment(data.value).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Chief Complaint',
        field: 'cheif_Complaint', sortable: true, filter: true, resizable: true, width: 900
      },
    ];
    // this.rowSpecOrder = [];
    this.getExam();
  }

  getExam(){
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.rowSpecOrder = res;
    })
  }

}
