<div id="content" style="height: 50%;">
    <div class="titlebox">Appointment</div>
    <br>
    <div class="d-flex pt-2">
        <button class="btn btn-primary px-4" (click)="addAppointment(appointment)">
            <span>New</span>
        </button>
    </div>
    <div class="pt-2">
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" class="ag-theme-alpine" style="height: 500px; width: 100%;"
            (rowDoubleClicked)="addAppointment($event.data)" [rowData]="rowAppointment"
            [columnDefs]="columnAppointment">
        </ag-grid-angular>
    </div>
</div>