import { stringify } from '@angular/compiler/src/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PracticeModel } from '../model/practiceModel';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {

  constructor(private http: HttpClient) { }

  GetAll(id: string): Observable<PracticeModel[]> {
    return this.http.get<PracticeModel[]>(environment.server + 'api/practice/getall?id=' + id);
  }

  Getbyid(Id: string, name_txt: string): Observable<PracticeModel> {
    return this.http.get<PracticeModel>(environment.server + 'api/practice/getbyid?id=' + Id + '&name_txt=' + name_txt);
  }

  Save(practiceModel: PracticeModel): Observable<string> {    
    const formData: FormData = new FormData();
    if (practiceModel.logo_img) {
      let temp = Object.assign(practiceModel.logo_img);
      formData.append('photo', temp, temp.name);
      practiceModel.logo_img = null;
    }
    formData.append('practiceModel', JSON.stringify(practiceModel));
    return this.http.post<string>(environment.server+'api/practice/save',formData);
  }
}