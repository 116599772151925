import { environment } from './../../../environments/environment';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticateRequestModel } from 'src/app/model/authenticateRequestModel';
import { Route } from '@angular/compiler/src/core';
import { UserService } from 'src/app/services/user.service';
import { UserModel } from 'src/app/model/userModel';
import { ProviderModel } from 'src/app/model/providerModel';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  password: string = 'password';
  showPassword = false
  fieldTextType: boolean = false;
  authenticateRequestModel:AuthenticateRequestModel = new AuthenticateRequestModel()
  // repeatFieldTextType: boolean = true;
  loginCount: number = 0;
  userName: string = "";
  userModel: UserModel = new UserModel();

  constructor(private fb: FormBuilder, private _userService: UserService, private _loginService: LoginService, private router: Router, private toaster: ToastrService, private _lookupService: LookupService) {
    this.loginForm = this.fb.group({
      userName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })
  }

  ngOnInit() {
    this.password = 'password'
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  // onClick() {
  //   if (this.password === 'password') {
  //     this.password = 'text';
  //     this.show = true;
  //   } else {
  //     this.password = 'password';
  //     this.show = false;
  //   }
  // }
  lockUserAccount(){
    let userId = localStorage.getItem('id')?.toString();
    userId = userId != undefined ? userId : "";
    this._userService.getByUserId(userId).subscribe((res: any) => {
      this.userModel = Object.assign(res);
      this.userModel.locked_ind = true;
      this._userService.updateUser(this.userModel).subscribe(res => {
        this.saveProvider();
      })
    })
  }
  saveProvider()
  {
    if(this.userModel.can_sign)
    {
      let providerModel = new ProviderModel();
      providerModel.active_ind = this.userModel.active_ind;
      providerModel.first_name_txt = this.userModel.first_name_txt;
      providerModel.last_name_txt = this.userModel.last_name_txt;
      providerModel.display_txt = this.userModel.display_txt;
      providerModel.email_address_txt = this.userModel.email_address_txt;
      providerModel.rendering_provider_ind = true;

      this._lookupService.insertProviderfromUser(providerModel).subscribe(res => {

      })
    }
  }

  onSubmit() {
    if (this.loginForm.controls.userName.invalid) {
      this.toaster.error("UserName is Required.")
    }
    else if (this.loginForm.controls.password.invalid) {
      this.toaster.error("Password is Required.")
    }
    else {
      debugger;
      var domainName = window.location.hostname;
      this.authenticateRequestModel.domainName = domainName;
      this._loginService.login(this.authenticateRequestModel).subscribe((res: any) => {
        debugger;
        this.loginForm.reset();
        localStorage.setItem("firstName",res.firstName);
        localStorage.setItem("lastName",res.lastName);
        localStorage.setItem("id",res.id);
        localStorage.setItem("username",res.username);
        localStorage.setItem("token", res.token);
        localStorage.setItem("practice", res.practiceID);
        var currentUrl = this.router.url;
        currentUrl = currentUrl.replace('login','dashboard')
        window.location.href = currentUrl
        // this.router.navigate(['/dashboard']);
      }, (error: any) => {

        let maxAttempt = localStorage.getItem('maxlogin')?.toString();
        if(maxAttempt){
          var remaining = Number(maxAttempt);
          remaining = remaining - 1;
          if(remaining < 0){
            this.toaster.error("User account is not active or locked");
            this.loginForm.reset();
          }else{
            localStorage.setItem('maxlogin', remaining.toString());
            if(remaining == 0){
              this.lockUserAccount();
            }
            this.toaster.error(error.error.message);
            this.loginForm.reset();
          }

        } else {
          this._loginService.authenticateUserName(this.authenticateRequestModel).subscribe((res: any) => {
            debugger;
            var maxAttempt = Number(res.item1.value_txt);
            maxAttempt = maxAttempt - 1;
            localStorage.setItem('maxlogin', maxAttempt.toString());
            localStorage.setItem("id", res.item2.user_id);

            this.toaster.error(error.error.message);
            this.loginForm.reset();
          }, (error: any) => {
            this.toaster.error(error.error.message);
            this.loginForm.reset();
          })
        }
      })
    }
  }
}
