import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef, ModalBackdropComponent } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, Subject, observable } from 'rxjs';
import { ModalComponent } from '../shared/modal/modal.component';
import { LookupModel } from 'src/app/model/lookupModel';
import { LookupService } from 'src/app/services/lookup.service';
import { ToastrService } from 'ngx-toastr';
import { InactivityModalComponent } from '../shared/inactivity-modal/inactivity-modal.component';
import { NgIdleService } from './idle.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public static bsModalReff : BsModalRef;
  public static componentName: string = '';
  private _isPatientDetail: boolean = false;
  subject = new Subject();
  private readonly examChange : BehaviorSubject<string> = new BehaviorSubject<string>('');
  public examChangeSubscriber : Observable<string>;
  private readonly checkboxChange : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public checkboxChangeSubscriber : Observable<boolean>;
  bsModalRef !: BsModalRef;
  idleTimerLeft: string = '';
  secondTimerLeft: string = '';

  constructor(private modalService : BsModalService, private router: Router, private ngIdle: NgIdleService,
    private _lookupService: LookupService,
    private toaster: ToastrService) {
    this.examChangeSubscriber = this.examChange.asObservable();
    this.checkboxChangeSubscriber = this.checkboxChange.asObservable();
  }

  changeCommonLayout(isPatientDetail: boolean) {
    this._isPatientDetail = isPatientDetail;
    this.subject.next(this._isPatientDetail);
  }

  examBroadcaster(value:string){
    this.examChange.next(value);
  }


  checkBoxBroadcaster(value:boolean){
    this.checkboxChange.next(value);
  }

  modal(content:string,title:string,saveButton:string,closeButton:string):Observable<any>{
    const initialState = {
      title: title,
      content : content,
      saveButton : saveButton
    };
    this.bsModalRef = this.modalService.show(ModalComponent, {initialState});
    // this.bsModalRef.setClass('modal-lg')
    this.bsModalRef.content.closeBtnName = closeButton;
    return this.bsModalRef.content.event
  }
  inactivityModal(content:string,title:string,saveButton:string,closeButton:string):Observable<any>{
    const initialState = {
      title: title,
      content : content,
      saveButton : saveButton
    };
    
    this.bsModalRef = this.modalService.show(InactivityModalComponent, {initialState, backdrop: 'static', keyboard: false, ignoreBackdropClick: true});
    // this.bsModalRef.setClass('modal-lg')
    this.bsModalRef.content.closeBtnName = closeButton;
    GlobalService.bsModalReff = this.bsModalRef; 
    return this.bsModalRef.content.event;
  }
  
  saveGlobalDropdown(val:string, grp:string, callback: () => any) {
    var lookupModel = new LookupModel();
    lookupModel.group_txt = grp;
    lookupModel.sub_group_txt = '';
    lookupModel.active_ind = true;
    lookupModel.name_txt = val;
    var content = 'Would you like to save this ' + lookupModel.group_txt + ' list?'
    this.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
      if (res.res) {
        // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
        this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          this.toaster.success('Record Saved Successfully.');
          callback();
        })
      }
    })
  }

  saveDropdown(val:string, grp:string, callback: () => any) {
    var lookupModel = new LookupModel();
    lookupModel.group_txt = grp;
    lookupModel.sub_group_txt = '';
    lookupModel.active_ind = true;
    lookupModel.name_txt = val;
    var content = 'Would you like to save this ' + lookupModel.group_txt + ' list?'
    this.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
      if (res.res) {
        // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
        this._lookupService.insertLookup(lookupModel).subscribe(res => {
          this.toaster.success('Record Saved Successfully.');
          callback();
        })
      }
    })
  }

  initTimer(firstTimerValue: number, secondTimerValue: number): void {
    // Timer value initialization
    this.ngIdle.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.ngIdle.FINAL_LEVEL_TIMER_VALUE_IN_MIN = secondTimerValue;
    // end
 
    // Watcher on timer
    this.ngIdle.initilizeSessionTimeout();
    this.ngIdle.userIdlenessChecker.subscribe((status: string) => {
      this.initiateFirstTimer(status);
    });
 
    this.ngIdle.secondLevelUserIdleChecker.subscribe((status: string) => {
      this.initiateSecondTimer(status);
    });
   }

   initiateFirstTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;

      case 'STOPPED_TIMER':
        this.showSendTimerDialog();
        break;

      default:
        this.idleTimerLeft = this.formatTimeLeft(Number(status));
        break;
    }
  }
  formatTimeLeft(time: number){
    if (time > 0) {
      let seconds = Math.trunc(time / 1000); 
      let min = 0;
      if (seconds >= 60) {
        min = Math.trunc(seconds / 60);
        //console.log(min);
        seconds -= (min * 60);
      }

      return `${min}:${seconds}`;
    }
    return '';
  }

  initiateSecondTimer = (status: string) => {
    switch (status) {
      case 'INITIATE_SECOND_TIMER':
        break;

      case 'SECOND_TIMER_STARTED':
        break;

      case 'SECOND_TIMER_STOPPED':
        this.logout();
        break;

      default:
        this.secondTimerLeft = status;
        break;
    }
  }
  showSendTimerDialog(): void {
        
    var content = `Due to application inactivity, your session is ending.`;
    this
      .inactivityModal(content, 'Inactivity Alert', 'Continue', 'Logout')
      .subscribe((res) => {
        
        switch (res) {
          case "continue":
            this.continue();
            break;
          case "close":
            this.logout();                  
            break;              
          default:
            this.logout();
            break;
        }
      });
     
  }

  continue() {
        
    // stop second timer and initiate first timer again
    NgIdleService.runSecondTimer = false;
    this.ngIdle.initilizeSessionTimeout();    
  }

  logout(): void {
         
    localStorage.clear();   
    // stop all timer and end the session
    NgIdleService.runTimer = false;
    NgIdleService.runSecondTimer = false;
    GlobalService.componentName = 'login';    
    this.router.navigate(['login']);    
    GlobalService.bsModalReff.hide();
  }
  isFormEdited(data1:any, data2: any){
    //var result = _.isEqual(data1, data2);
    var result = JSON.stringify(data1) === JSON.stringify(data2);
    return result;
  }
}