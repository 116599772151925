import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-provide-resources',
  templateUrl: './provide-resources.component.html',
  styleUrls: ['./provide-resources.component.scss']
})
export class ProvideResourcesComponent implements OnInit {

  title: string = '';
  closeBtnName : string = '';
  columnResources:any;
  rowResources:any;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.columnResources = [
      {
        headerName: 'Date',
        field: 'date', sortable: true, resizable: true, rowDrag: true, filter: true, floatingFilter: false,
        cellRenderer: (data:any) => {
          return moment(data.created_dt).format('MM/DD/YYYY')
      }
      },
      {
        headerName: 'Description',
        field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Source',
        field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'File Name',
        field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      }
    ];
    this.rowResources = [];
  }

  save(){
    
  }

}
