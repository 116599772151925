<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="row">
            <div class="col-ml-4 col-xl-2">
                <label>Patient Name:</label>
            </div>
            <div class="col-ml-4 col-xl-4">
                {{patient.lastName}}, {{patient.firstName}}
            </div>
        </div>
        <div class="row">
            <div class="col-ml-4 col-xl-2">
                <label>Age:</label>
            </div>
            <div class="col-ml-4 col-xl-4">
                {{age}}
            </div>
        </div>
        <div class="row">
            <div class="col-ml-4 col-xl-2">
                <label>Address:</label>
            </div>
            <div class="col-ml-4 col-xl-4">
                <span style="font-size: 20px !important; font-weight: normal;" *ngIf="patient.address">{{patient.address}}</span>
                <span style="font-size: 20px !important; font-weight: normal;" *ngIf="patient.city">, {{patient.city}}</span>
                <span style="font-size: 20px !important; font-weight: normal;" *ngIf="patient.state" >, {{patient.state}}</span> {{patient.zip}}                
            </div>
        </div>
    </div>
</div>
<form style="padding-top: 50px !important;">
    <div class="pt-5">
        <div class="topnav">
            <a class="nav-link" routerLink="/patient/clinical/pre-contactorders" [queryParams]="{patientId:patientId}" style="width: 25%;" routerLinkActive="active">Prev. ContactOrders</a>
            <a class="nav-link" routerLink="/patient/clinical/pre-iop" [queryParams]="{patientId:patientId}" style="width: 25%;" routerLinkActive="active">Prev. IOP's/CD's</a>
            <a class="nav-link" routerLink="/patient/clinical/pre-specrx" [queryParams]="{patientId:patientId}" style="width: 25%;" routerLinkActive="active">Prev. SpecRx</a>
            <a class="nav-link" routerLink="/patient/clinical/pre-contactrx" [queryParams]="{patientId:patientId}" style="width: 25%;" routerLinkActive="active">Prev. ContactRx</a>
        </div>
        <div class="topnav">
            <a class="nav-link" routerLink="/patient/clinical/exams" [queryParams]="{patientId:patientId}" style="width: 16.66%;" routerLinkActive="active">Exams</a>
            <a class="nav-link" routerLink="/patient/clinical/appointment" [queryParams]="{patientId:patientId}" style="width: 16.66%;" routerLinkActive="active">Appointments</a>
            <a class="nav-link" routerLink="/patient/clinical/document" [queryParams]="{patientId:patientId}" style="width: 16.66%;" routerLinkActive="active">Documents</a>
            <a class="nav-link" routerLink="/patient/clinical/diagnosis" [queryParams]="{patientId:patientId}" style="width: 16.66%;" routerLinkActive="active">Diagnosis/Problems</a>
            <a class="nav-link" routerLink="/patient/clinical/medRx" [queryParams]="{patientId:patientId}" style="width: 16.66%;" routerLinkActive="active">MedRx</a>
            <a class="nav-link" routerLink="/patient/clinical/allergy" [queryParams]="{patientId:patientId}" style="width: 16.66%;" routerLinkActive="active">Allergy</a>
        </div>
    </div>
    <fieldset class="pt-4">
        <div>
            <router-outlet></router-outlet>
        </div>
    </fieldset>
</form>
<style type="text/css">
    #content
    {
        border: solid 0px !important;
    }    
</style>