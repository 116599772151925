import { PatientInsuranceModel } from './../../../model/patientInsuranceModel';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-patient-insurance',
  templateUrl: './patient-insurance.component.html',
  styleUrls: ['./patient-insurance.component.scss']
})
export class PatientInsuranceComponent implements OnInit {

  
  item : PatientInsuranceModel = new PatientInsuranceModel();
  constructor(public bsModalRef : BsModalRef) { }

  ngOnInit() {  
    
  }


}
