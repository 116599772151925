import { ExamModel } from 'src/app/model/ExamModel';
export class OMR_ExamADDModel{
public exam_ID : string = '';
public contactsPtComments : string = '';
public contactsDate : Date = new Date();
public contactsODPower : string = '';
public contactsODBC : string = '';
public contactsODDiam : string = '';
public contactsODBrand : string = '';
public contactsODVAFar : string = '';
public contactsODVANear : string = '';
public contactsOSPower : string = '';
public contactsOSBC : string = '';
public contactsOSDiam : string = '';
public contactsOSBrand : string = '';
public contactsOSVAFar : string = '';
public contactsOSVANear : string = '';
public contactsOUVAFar : string = '';
public contactsOUVANear : string = '';
public contactsSlitLampExamSelect : string = '';
public contactsSlitLampExam : string = '';
public contactsAssessmentSelect : string = '';
public contactsAssessment : string = '';
public contactsPlanSelect : string = '';
public contactsPlan : string = '';
public contactsSignature : string = '';
public contactsSignatureCB !: ImageData;
public creationDate : Date = new Date();
public lastUpdateDate : Date = new Date();
public overOD2 : string = '';
public overOS2 : string = '';
public overOU2 : string = '';
public odVAFar2 : string = '';
public osVAFar2 : string = '';
public ouVAFar2 : string = '';
public odVANear2 : string = '';
public osVANear2 : string = '';
public ouVANear2 : string = '';
public gaveCLRX : boolean = false;
public cleanQHS : boolean = false;
public cleanQHSText : string = '';
public orderContacts : boolean = false;
public doctorID : number =0;
public planNumber : string = '';
public otherAssessment : string = '';
public otherPlan : string = '';
public assessment1 : string = '';
public assessment2 : string = '';
public assessment3 : string = '';
public planNumber1 : string = '';
public planNumber2 : string = '';
public planNumber3 : string = '';
public plan1 : string = '';
public plan2 : string = '';
public plan3 : string = '';
public provider_ID : string = '';
// public exam : ExamModel = new ExamModel();
}