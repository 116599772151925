import { PatientService } from './../../../../services/patient.service';
import { PatientModel } from './../../../../model/patientModel';
import { ClinicalService } from './../../../../services/clinical.service';
import { AppointmentModel } from './../../../../model/AppointmentModel';
import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddAppointmentComponent } from '../add-appointment/add-appointment.component';
import * as moment from 'moment';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  columnAppointment:any;
  rowAppointment:AppointmentModel[] = [];
  bsModalRef!: BsModalRef;
  appointment : AppointmentModel = new AppointmentModel();
  patientDetails: PatientModel = new PatientModel();
  
  constructor(private _clinicalService:ClinicalService
    ,private modalService: BsModalService,private _patientService : PatientService) { 
      this.patientDetails = JSON.parse(localStorage.getItem('ActivePatient')!);
    }

  ngOnInit() {
    this.columnAppointment = [
      {
        headerName: 'Date&Time',
        field: 'original_starttime_dt', sortable: true, sort: 'desc',resizable: true, rowDrag: true,width:280,
        cellRenderer: (data:any) => {
          return moment(data.data.original_starttime_dt).format('MM/DD/yyyy hh:mm A');
      }
      },
      {
        headerName: 'Subject',
        field: 'subject_txt',sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,width:300
        ,cellRenderer: (data:any) => {
          return data.data.subject_txt  + ((data.data.reason_txt != '') ? (' - ' + data.data.reason_txt) : '')
      }
      },
    ];
    this.getAppointment();
    // this.rowAppointment = [];
  }

  getAppointment(){
    
    this._clinicalService.getAppointment(this.patientDetails.patient_id).subscribe((res=>{
      this.rowAppointment = Object.assign([],res);
    }));
  }

  addAppointment(item:any) {
    const initialState = {
      list:item,
      title: 'Editing - Patient Appointment'
    };
    this.bsModalRef = this.modalService.show(AddAppointmentComponent, {initialState});
    this.bsModalRef.setClass('modal-lg')
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide.subscribe((res:any)=>{
      this.getAppointment();
    })
  }

  

}
