<div id="content" style="height: 50%;">
    <div class="titlebox">Medical Allergy History</div>
    <br><br>
    <div class="d-flex">
        <button class="btn btn-primary px-4" (click)="edit()">
            Edit
        </button> &nbsp;
        <button class="btn btn-primary px-4" (click)="save()">
            Save
        </button> &nbsp;
        <div class="col-md-2"></div>
        <label class="pt-1">Status:</label>
        <select class="form-control col-md-2 mx-2" [(ngModel)]="filterString" (change)="filterAllergy()">
            <option value="All">-All-</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Discontinued">Discontinued</option>
        </select>
    </div>
    <div class="pt-2">
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="height:300px; width: 100%;" class="ag-theme-alpine" (cellValueChanged)="onCellValueChanged($event)" (gridReady)="onAllergyGridReady($event)" [rowData]="rowAllergy" [columnDefs]="columnAllergy">
        </ag-grid-angular>
    </div>
</div>
