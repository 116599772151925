<!-- <div>
  <form [formGroup]="providersFormGroup">
    <div>
      <input type="text" formControlName="id" hidden>
    </div>
    <div class="row">
      <div class="col-md-10 col-sm-10">
        <div class="form-group">
          <label>Display Name:</label>
          <input type="text" formControlName="displayName" class="form-control">
        </div>
      </div>
      <div class="col-md-2 col-sm-2">
        <div class="form-group">
          <div> </div>
          <input type="checkbox" formControlName="status">
          <label>Active</label>
        </div>
      </div>
    </div>
  </form>
</div> -->


<form class="form" style="height:130%; width: 100% !important;">
    <div id="content" style="text-align: center !important;margin-bottom: -5px;margin-left: -11px;">
        <div>
            <div class="row">
                <div  class="col-md-12" style="padding: 0%;">
                    <b><h3 style="font-weight: bold;">Providers</h3></b>
                </div>
            </div>
        </div>
    </div>

    <fieldset class="form p-1">       
        <div id="content" class="row">
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-4">
                        <label><b>Selected Provider:</b></label>
                    </div>
                    <div class="col-md-8 justify-content-right">
                        <button class="btn btn-primary float-right"  style="margin-right:-50px !important;" (click)="addNew()">Add New</button>
                    </div>
                </div>
                <br>
                <div>
                    <input type="text" class="form-control" placeholder="Search here..." (input)="findProviders($event)">
                </div>
                <div id="content" [ngClass]="{'disableDiv': showButton}" style="height: 400px; overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black;">
                    <div class="titlebox"></div>
                    <div class="row mx-2" *ngFor="let provider of providerList">
                        <div [ngStyle]="{'background-color': selectedProvider == provider.provider_id ? '#5A5AE5' : 'white'}" class="col-md-12">
                            <label style="cursor: pointer;" class="text-center" (click)="setForm(provider)">{{provider.display_txt}}</label>
                        </div>
                    </div>
                    <!-- <table class="table" *ngFor="let provider of providerList">
                        <tr>
                            <td style="cursor: pointer;" class="text-center" (click)="setForm(provider)">{{provider.display_txt}}</td>
                        </tr>
                    </table> -->
                </div>
                <!-- <div class="form-row pt-4">
                    <label class="pt-1">Provider Type:</label>
                    <select class="form-control col-md-6 mx-2">
                    <option>All</option>
                    <option>Billing</option>
                    <option>Rendering</option>
                    <option>Referring</option>
                </select>
                </div> -->
                <div>
                    <label><input type="checkbox" #showInactiveCheckbox id="showInactiveCheckbox" ([ngModel])="showActive" (change)="filterProvider(showInactiveCheckbox.checked)" class="mx-2">Show Inactive</label>
                </div>
            </div>
            <div class="col-md-9">
                <label style="font-size: 20px !important; text-align:center !important; margin-left: 200px !important;"><b>Provider Information:</b></label>
                <div>
                    <div class="titlebox"></div>
                    <form [formGroup]="providersFormGroup">
                        <div class="row">
                            <input type="text" hidden formControlName="provider_id" [(ngModel)]="providerModel.provider_id">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Display Name:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="display_txt" [ngClass]="{'red':providersFormGroup.controls.display_txt.invalid}" [(ngModel)]="providerModel.display_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <input type="checkbox" formControlName="active_ind" [(ngModel)]="providerModel.active_ind" class="mx-1">
                                <label>Active</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Practice:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="practice_name_txt" [(ngModel)]="providerModel.practice_name_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>First Name:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="first_name_txt" [(ngModel)]="providerModel.first_name_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Last Name:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="last_name_txt" [ngClass]="{'red':providersFormGroup.controls.last_name_txt.invalid}" [(ngModel)]="providerModel.last_name_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Address 1:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="100" formControlName="address_1_txt" [(ngModel)]="providerModel.address_1_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Address 2:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="address_2_txt" [(ngModel)]="providerModel.address_2_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>City:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="city_txt" [(ngModel)]="providerModel.city_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <label>State:</label>
                                                    </div>  
                                                    <div class="col-md-6">
                                                         <select   style="width:70px ;" formControlName="state_txt" [(ngModel)]="providerModel.state_txt">
                                                        <option *ngFor="let state of stateLookup" [value]="state.name_txt">{{state.name_txt}}</option>
                                                         </select>
                                                        <!-- <ng-select [clearable]="true"  style="width:70px ;" [items]="stateLookup" bindLabel="name_txt" formControlName="state_txt" bindValue="name_txt" [(ngModel)]="providerModel.state_txt">
                                                        </ng-select> -->
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <label>Zip:</label>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <input type="text" maxlength="12" formControlName="zip_txt" [(ngModel)]="providerModel.zip_txt" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Email:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="email_address_txt" [ngClass]="{'red':providersFormGroup.controls.email_address_txt.invalid}" [(ngModel)]="providerModel.email_address_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Phone:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="phone_txt" [(ngModel)]="providerModel.phone_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>NPI:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="100" formControlName="npi_txt" [(ngModel)]="providerModel.npi_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Fax:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="15" formControlName="fax_txt" [(ngModel)]="providerModel.fax_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>License No.:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="25" formControlName="federal_tax_id_type_txt" [(ngModel)]="providerModel.federal_tax_id_type_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label>Federal Tax ID:</label>
                                    </div>
                                    <div class="col-md-9">
                                        <input type="text" maxlength="50" formControlName="federal_tax_id_txt" [(ngModel)]="providerModel.federal_tax_id_txt" class="form-control">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input type="checkbox" formControlName="rendering_provider_ind" [(ngModel)]="providerModel.rendering_provider_ind" class="mx-2">
                            <label>Is Rendering Provider</label>
                        </div>
                        <div>
                            <input type="checkbox" formControlName="referring_provider_ind" [(ngModel)]="providerModel.referring_provider_ind" class="mx-2">
                            <label>Is Referring Provider</label>
                        </div>
                        <div class="pt-2 col-md-12 text-center">
                            <button class="btn btn-primary px-4" (click)="edit()" *ngIf="!showButton" [disabled]="!enablEditButton">Edit</button>
                            <button class="btn btn-primary px-4 mx-2" *ngIf="showButton" (click)="save()">Save</button>
                            <button class="btn btn-primary px-4" (click)="cancel()" *ngIf="showButton">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </fieldset>
</form>