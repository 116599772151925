import { PatientReviewModel } from "./PatientReviewModel";

export class AllergyModel{
public allergy_id : string = '';
public original_allergy_id : string = '';
public name_txt : string = '';
public reaction_detail_txt : string = '';
public type_txt : string = '';
public created_dt : Date = new Date();
public created_by : string = '';
public updated_dt : Date = new Date();
public updated_by : string = '';
public patient_history_id : string = '';
public patient_id : string = '';
public status_txt : string = '';
public severity_txt : string = '';
public reconciled_txt : string = '';
public vocabulary_txt : string = '';
public source_txt : string = '';
public rx_norm_code_txt : string = '';
public patientReview : PatientReviewModel = new PatientReviewModel();
}