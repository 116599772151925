<div id="content" style="min-height: 500px !important; width:100% !important;">
    <div id="content" *ngIf="!isManageFacilities">
        <div class="row">
            <div class="titlebox">Manage Practice</div>
        </div>
        <div class="row form-row">
            <div class="col-md-2 text-right">
                <label>Patient Account Prefix:</label>
            </div>
            <div class="col-md-2 text-right">
                <input class="form-control" [(ngModel)]="prefix" type="text" [disabled]="!isShowSaveButton">
            </div>
            <div class="col-md-2 text-right">
                <label>Patient Account Suffix:</label>
            </div>
            <div class="col-md-3 text-right">
                <input class="form-control" [(ngModel)]="suffix" type="text" [disabled]="!isShowSaveButton">
            </div>
        </div>
        <div class="row form-row pt-2">
            <div class="col-md-2 text-right">
                <label class="pt-1">InActivity Logout:</label>
            </div>
            <div class="col-md-2">
                <table style="width:100%">
                    <tr>
                        <td style="width:80%">
                            <select class="form-control col-md-8 col-md-12" [(ngModel)]="inactivity" [disabled]="!isShowSaveButton">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                                <option value="60">60</option>
                                <option value="75">75</option>
                                <option value="90">90</option>
                                <option value="105">105</option>
                                <option value="120">120</option>
                            </select>
                        </td>
                        <td>
                            <label class="mx-1 pt-1">minutes</label>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-2 text-right">
                <label>Max. Login Attempts:</label>
            </div>
            <div class="col-md-3">
                <input class="form-control" [(ngModel)]="maxlogin" type="text" [disabled]="!isShowSaveButton">
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-2 text-right">
                <label>Schedule Interval:</label>
            </div>
            <div class="col-md-2">
                <table style="width:100%">
                    <tr>
                        <td style="width:80%">
                            <select class="form-control col-md-12" [(ngModel)]="internval" [disabled]="!isShowSaveButton">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="60">60</option>
                            </select>
                        </td>
                        <td>
                            <label class="mx-1 pt-1">minutes</label>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-2 text-right">
                <label>Logo Image:</label>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="col-md-4">
                        <input type="file" accept="image/*" (change)="onFileChanged($event)" hidden #file>
                        <button (click)="file.click()" class="btn btn-primary p-2" [disabled]="!isShowSaveButton">Upload Logo</button>
                    </div>
                    <div class="col-md-8">
                        <img [src]="imgURL" height="100" *ngIf="imgURL">
                    </div>
                </div>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-2 text-right">
                <label>Lock Account Numbers:&nbsp;&nbsp;<input class="" type="checkbox" [(ngModel)]="lockaccountnumber" [disabled]="!isShowSaveButton"></label>
            </div>
        </div>
        <div class="row form-row">
            <div class="col-md-2 text-right">
                <label class="pt-1">Upcoming Appointments Range:</label>
            </div>
            <div class="col-md-2">
                <table style="width:100%">
                    <tr>
                        <td style="width:80%">
                            <input type="text" class="form-control col-md-12" [(ngModel)]="upcomingappointmen" [disabled]="!isShowSaveButton">
                        </td>
                        <td>
                            <label class="mx-1 pt-1">days</label>
                        </td>
                    </tr>
                </table>
            </div>

        </div>


        <div class="text-center">
            <button class="btn btn-primary m-2" *ngIf="!isShowSaveButton" (click)="isShowSaveButton = true">Edit</button>
            <button class="btn btn-primary m-2" *ngIf="isShowSaveButton" (click)="saveManagePractice()">Save</button>
            <button class="btn btn-primary m-2" *ngIf="isShowSaveButton" (click)="isShowSaveButton = false">Cancel</button>
        </div>
    </div>
    <div id="content" class="mt-4" *ngIf="!isManageFacilities">
        <div class="row">
            <div class="titlebox">Help Manuals</div>
        </div>
        <div>
            <div class="d-flex justify-content-left p-2">
                <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
                    <a href="https://demo.quikeyes.com/Materials/eClaimsManual.pdf" target='_blank'
                        style="color: white;">E-Claims Help Manual</a>
                </button>&nbsp;
                <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
                    <a href="https://demo.quikeyes.com/Materials/QUIKEYES_ONLINE_USERS_MANUAL_09_25_2012.pdf"
                        target='_blank' style="color: white;">Quikeyes Help Manual</a>
                </button> &nbsp;
                <button class="btn btn-primary" style="border-radius: 20px;color: blue;">
                    <a href="https://demo.quikeyes.com/Materials/ePrescribeManual.pdf" target='_blank'
                        style="color: white;">E-Prescribe Help Manual</a>
                </button>
            </div>
        </div>
    </div>
    <div id="content" style="height:570px !important; width:100% !important;" class="mt-4">
        <div class="row">
            <div class="titlebox">Manage Facilities</div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <label style="font-size: 20px !important;" class="mx-2">Selected Facility:</label>
                        </div>
                        <div class="col-md-6 align-items-right">
                            <button class="btn btn-primary"  style="margin-left:40px !important;" (click)="addNew()">Add New</button>
                        </div>
                    </div>
                </div>
                <br>
                <div id="content" class="" style="height: 400px; overflow-y: auto; overflow-x: hidden; border-bottom: solid 1px black;">
                    <div class="titlebox"></div>
                    <div class="row mx-2" *ngFor="let office of officeList" >
                        <div [ngStyle]="{'background-color': selectoffice_id == office.office_id ? '#5A5AE5' : 'white'}" class="col-md-12">
                            <label style="cursor: pointer;" class="text-center" (click)="setForm(office)">{{office.name_txt}}</label>
                        </div>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-md-4">
                        <div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Show Inactive:&nbsp;&nbsp;<input class="" type="checkbox" [(ngModel)]="showInactiveCheckbox" (click)="showInactiveClick()"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8" [formGroup]="officeFormGroup">
                <div class="row">
                    <div>
                        <label style="font-size: 20px !important; text-align:center !important; margin-left: 200px !important;">Facility Information:</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2 justify-content-right">
                        <label>Display Name:</label>

                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control" maxlength="50" formControlName="name_txt" [(ngModel)]="officeModel.name_txt"  >
                        <!-- <input type="text" class="form-control" maxlength="50" formControlName="name_txt" [(ngModel)]="officeModel.name_txt"> -->
                    </div>
                    <div class="col-md-2 justify-content-right">
                        <label></label>
                    </div>
                    <div class="col-md-3">
                        <label><input type="checkbox" formControlName="active_ind" [(ngModel)]="officeModel.active_ind" class="mx-1">Active</label>
                        <label><input type="checkbox" formControlName="default_ind" [(ngModel)]="officeModel.default_ind" class="mx-1">Default</label>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-2 justify-content-right">
                        <label>Address 1:</label>
                    </div>
                    <div class="col-md-3 ">
                        <input type="text"  class="form-control" maxlength="50" formControlName="address_1_txt" [(ngModel)]="officeModel.address_1_txt">
                    </div>
                    <div class="col-md-2 justify-content-right">
                        <label>Address 2:</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text"   class="form-control" maxlength="50" formControlName="address_2_txt" [(ngModel)]="officeModel.address_2_txt">
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-2 justify-content-right">
                        <label>City:</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text"  class="form-control" maxlength="50" formControlName="city_txt" [(ngModel)]="officeModel.city_txt">
                    </div>
                    <div class="col-md-2 justify-content-right">
                        <label>State:</label>
                    </div>
                    <div class="col-md-5">
                      <div class="row">
                        <div class="col-md-5">
                            <select  formControlName="state_txt" [(ngModel)]="officeModel.state_txt" style="border-radius: 0.25rem; border: 1px solid #ced4da;">
                                <option *ngFor="let state of states" [value]="state.name_txt">{{state.name_txt}}</option>
                                 </select>
                            <!-- <ng-select [clearable]="true"   style="width:60px ;" [items]="states" bindLabel="name_txt" formControlName="state_txt" bindValue="name_txt" [(ngModel)]="officeModel.state_txt">
                            </ng-select> -->
                          <!-- <ng-select [items]="states" class="ng-col-8" style="width:70px ;" formControlName="state_txt" bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="officeModel.state_txt">
                          </ng-select> -->
                        </div>
                        <div class="col-md-3">
                          <label>Zip:</label>
                        </div>
                        <div class="col-md-4" style="padding-left: 0;">
                          <input type="text" maxlength="10"  formControlName="zip_txt"  class="form-control" [(ngModel)]="officeModel.zip_txt">
                        </div>
                      </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-2 justify-content-right">
                        <label>Phone:</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" class="form-control"  phone (phoneChange)="updatePhone($event)" minlength=14 maxlength=14 formControlName="phone_1_txt" [(ngModel)]="officeModel.phone_1_txt">
                    </div>
                    <div class="col-md-2 justify-content-right">
                        <label>Place of Service:</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control"  (click)="showDropdown = true" (input)="serachPlace($event)" formControlName="place_of_service_code_txt" [(ngModel)]="officeModel.place_of_service_code_txt">
                        <div *ngIf="showDropdown">
                            <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="width: 90%; height: 250px;" rowSelection="single" (rowClicked)="placeRowClicked($event)" class="ag-theme-alpine dropdown" [rowData]="rowPlace" [rowDragManaged]="true" [columnDefs]="columnPlace">
                            </ag-grid-angular>
                        </div>
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-2 justify-content-right">
                        <label>Email:</label>
                    </div>
                    <div class="col-md-3">
                        <input type="text" maxlength="50" formControlName="email_address_txt" [ngClass]="{'red':officeFormGroup.controls.email_address_txt.invalid}" [(ngModel)]="officeModel.email_address_txt" class="form-control">
                    </div>
                    <div class="col-md-2 justify-content-right">
                        <label>NPI:</label>
                    </div>
                    <div class="col-md-5">
                        <input type="text" maxlength="100" formControlName="npi_txt" [(ngModel)]="officeModel.npi_txt" class="form-control">
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-md-2 justify-content-right">
                        <label>Tax Rate:</label>
                    </div>
                    <div class="col-md-3">
                        <!-- <input type="text"    class="form-control col-md-12" formControlName="tax_rate_amt"    [ngModel]="officeModel.tax_rate_amt  |  number:'1.2-2' " (ngModelChange)="officeModel.tax_rate_amt =$event"> -->
                        <input type="text"  class="form-control col-md-12" formControlName="tax_rate_amt" [ngModel]="officeModel.tax_rate_amt" (blur)="updateTax($event)">
                    </div>
                    <div class="col-md-4">
                        <label class="col-md-4">Format 0.0###(9.75% = 0.0975)</label>
                    </div>
                </div>
                <div class="pt-2 col-md-12 text-center">
                    <button class="btn btn-primary px-4" (click)="edit()" *ngIf="!showButton" [disabled]="!enablEditButton">Edit</button>
                    <button class="btn btn-primary px-4 mx-2" [disabled]="officeFormGroup.invalid" *ngIf="showButton" (click)="save()">Save</button>
                    <button class="btn btn-primary px-4" (click)="cancel()" *ngIf="showButton">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
