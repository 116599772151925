<form [formGroup]="advancePatientSearch">
    <fieldset class="form">
        <h4>Advanced Patient Search</h4>
        <br>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Date Of Exam:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="ExamFromDate" [(ngModel)]="advancePatientSearchModel.ExamFromDate" class="form-control">
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>to</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="ExamToDate" [(ngModel)]="advancePatientSearchModel.ExamToDate" class="form-control">
                </div>
            </div>

            <div class="col-md-5">
                <div class="form-group">
                    <input type="checkbox" formControlName="IsShowInActive" [(ngModel)]="advancePatientSearchModel.IsShowInActive">
                    <label>Show Inactive</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Date of Birth:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="BirthFromDate" class="form-control" [(ngModel)]="advancePatientSearchModel.BirthFromDate">
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>to</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="BirthToDate" class="form-control" [(ngModel)]="advancePatientSearchModel.BirthToDate">
                </div>
            </div>

            <div class="col-md-1">
                <div class="form-group">
                    <label>Identifiers:</label>
                </div>
            </div>
            <div class="col-md-2">
            <div class="form-group">
                <form ngForm="search">
                    <ng-multiselect-dropdown name="identifier" (onSelectAll)="onSelectAll($event)" [settings]="dropdownSettings" [(ngModel)]="advancePatientSearchModel.Identifier" [data]="identifier" (keyup.enter)="insertIdenfiers($event)">
                    </ng-multiselect-dropdown>
                </form>
            </div>
        </div>

            <div class="col-md-1">
                <div class="form-group">
                    <label>Employer:</label>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <input type="text" formControlName="Employer" class="form-control" [(ngModel)]="advancePatientSearchModel.Employer">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-1">
                <label>Ethnicity:</label>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="ethnicityLookup" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'Ethnicity')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.Ethnicity,'Ethnicity',ethnicityLookup,'ethnicity',advancePatientSearchModel,'Ethnicity',dbEnum.QE_Dev_Config)" bindLabel="name_txt"  bindValue="name_txt" 
                    [(ngModel)]="advancePatientSearchModel.Ethnicity">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(ethnicityLookup,ethnicityLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
                </div>
            </div>
             <div class="col-md-2">
                 <label>Preferred Language:</label>
             </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="languageLookup" bindLabel="name_txt" formControlName="PreferredLang" bindValue="name_txt" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'PreferredLang')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.PreferredLang,'Preferred Language',languageLookup,'language',advancePatientSearchModel,'PreferredLang',dbEnum.QE_Dev_Config)" 
                    [(ngModel)]="advancePatientSearchModel.PreferredLang">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(languageLookup,languageLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
                </div>
            </div>
            <div class="col-md-1">
               <label>Race:</label>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="raceLookup" bindLabel="name_txt" formControlName="Race" bindValue="name_txt" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'Race')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.Race,'Race',raceLookup,'race',advancePatientSearchModel,'Race',dbEnum.QE_Dev_Config)" 
                    [(ngModel)]="advancePatientSearchModel.Race">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(raceLookup,raceLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Zip Code:</label>
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="Zipcode" [(ngModel)]="advancePatientSearchModel.Zipcode">
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Area Code:</label>
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="Areacode" [(ngModel)]="advancePatientSearchModel.Areacode">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Appointments:</label>
                </div>
            </div>
            <div class="col-md-2">
             <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="contactmethodLookup" bindLabel="name_txt" formControlName="Appointment" bindValue="name_txt" 
             (keyup)="keyFunc($event,advancePatientSearchModel,'Appointment')"
             (keydown.tab)="saveDropdown(advancePatientSearchModel.Appointment,'Appointments',contactmethodLookup,'contactmethod',dbEnum.QE_Dev_Config)" 
             [(ngModel)]="advancePatientSearchModel.Appointment">
             </ng-select>
              <a href="javascript:void(0)" (click)="editLookup(contactmethodLookup,contactmethodLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
            </div>

            <div class="col-md-2">
             <label>Exam Notifications:</label>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="contactmethodLookup" bindLabel="name_txt" formControlName="ExamNotification" bindValue="name_txt" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'ExamNotification')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.ExamNotification,'Exam Notifications',contactmethodLookup,'contactmethod',advancePatientSearchModel,'ExamNotification',dbEnum.QE_Dev_Config)" 
                    [(ngModel)]="advancePatientSearchModel.ExamNotification">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(contactmethodLookup,contactmethodLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
                </div>
            </div>

            <div class="col-md-1">
                <label>Marketing</label>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="contactmethodLookup" bindLabel="name_txt" formControlName="Marketing" bindValue="name_txt" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'Marketing')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.Marketing,'Marketing',contactmethodLookup,'contactmethod',advancePatientSearchModel,'Marketing',dbEnum.QE_Dev_Config)" 
                    [(ngModel)]="advancePatientSearchModel.Marketing">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(contactmethodLookup,contactmethodLookupCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Age:</label>
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="FromAge" [(ngModel)]="advancePatientSearchModel.FromAge">
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>to</label>
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <input type="text" class="form-control" formControlName="ToAge" [(ngModel)]="advancePatientSearchModel.ToAge">
                </div>
            </div>

            <div class="col-md-1">
                <div class="form-group">
                    <label>Sex:</label>
                    <!-- <select class="form-control" formControlName="Sex" [(ngModel)]="advancePatientSearchModel.Sex">
                        <option>Male</option>
                        <option>Female</option>
                        <option>Unknown</option>
                    </select> -->
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select class="custom" [clearable]="true" [items]="sexLookup" bindLabel="key" formControlName="Sex" bindValue="value" [(ngModel)]="advancePatientSearchModel.Sex">
                    </ng-select>                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Doctor:</label>
                    <!-- <select class="form-control" formControlName="Doctor" [(ngModel)]="advancePatientSearchModel.Doctor">
                        <option>doctor</option>
                    </select> -->
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" [items]="referringDoctor" bindLabel="display_txt" formControlName="Doctor" bindValue="provider_id" [(ngModel)]="advancePatientSearchModel.Doctor">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-2">
                <label>Assessment</label>
              </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="assessment" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'Assessment')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.Assessment,'Assessment',assessment,'assessment',advancePatientSearchModel,'Assessment',dbEnum.QE_Dev_Config)" bindLabel="name_txt" formControlName="Assessment" bindValue="name_txt" 
                    [(ngModel)]="advancePatientSearchModel.Assessment">
                    </ng-select>
                    <a href="javascript:void(0)" (click)="editLookup(assessment,assessmentCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-54px;"></a>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label>Patient Insurance:</label>
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <form ngForm="search">
                                               <!-- [data]="patientInsurance" -->
                        <ng-multiselect-dropdown name="patientInsurance" [settings]="patientInsuranceDropdownSettings" [data]="rowInsurance"  [(ngModel)]="advancePatientSearchModel.PatientInsurance">
                        </ng-multiselect-dropdown>
                    </form>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group">
                    <label>Office:</label>
                    <!-- <select class="form-control" formControlName="Office" [(ngModel)]="advancePatientSearchModel.Office">
                        <option *ngFor="let c of office" value="{{c.name_txt}}">
                            {{c.name_txt}}
                        </option>
                    </select> -->
                    </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <table style="width:100%">
                        <tr>
                            <td style="width:80%">
                                <ng-select [clearable]="true" (keyup)="keyFunc($event)" addTagText="Add New" [addTag]="CreateNew" [items]="office" bindLabel="name_txt" formControlName="Office" bindValue="name_txt" [(ngModel)]="advancePatientSearchModel.Office">
                                </ng-select>
                            </td>
                            <td>
                                <a href="javascript:void(0)" (click)="editOffice()"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Plan:</label>
                 </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="plan" bindLabel="name_txt" formControlName="Plan" bindValue="name_txt" 
                    (keyup)="keyFunc($event,advancePatientSearchModel,'Plan')"
                    (keydown.tab)="saveDropdown(advancePatientSearchModel.Plan,'Plan',plan,'plan',advancePatientSearchModel,'Plan',dbEnum.QE_Dev_Config)" 
                    [(ngModel)]="advancePatientSearchModel.Plan">
                    </ng-select>
                </div>
                <a href="javascript:void(0)" (click)="editLookup(plan,planCopy,dbEnum.QE_Dev_Config)"><img src="../../../../assets/img/pencil-png.png" height="10px" style="margin-left: 146px; margin-top:-87px;"></a>
            </div>

        </div>
        <div class="float-right">
            <button class="btn btn-primary px-2" (click)="reset()">Reset</button> &nbsp;
            <button class="btn btn-primary " (click)="searchPatient()">Search</button>
        </div>
        <!-- <h5><b>Medication:</b></h5>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Brand Name:</label>
                    <select class="form-control" formControlName="brand">
            <option>Brand</option>
          </select>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Generic Name:</label>
                    <select class="form-control" formControlName="generic">
            <option>Generic</option>
          </select>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label>Allergy:</label>
                    <select class="form-control" formControlName="allergy">
            <option>Allergy</option>
          </select>
                </div>
            </div>
        </div> -->
    </fieldset>

</form>
<div *ngIf="isShowPatientList">
    <div class="test-header" style="height: 5%">
        <label><input type="checkbox" checked (change)="hide($event,'lastName')" style="margin: 5px;" />LastName</label>
        <label><input type="checkbox" checked (change)="hide($event,'firstName')"
                style="margin: 5px;" />FirstName</label>
        <label><input type="checkbox" checked (change)="hide($event,'address')" style="margin: 5px;" />Address</label>
        <label><input type="checkbox" checked (change)="hide($event,'city')" style="margin: 5px;" />City</label>
        <!-- <button (click)="autoSizeAll(isAutoSizable)">Auto-Size All</button> -->
    </div>
    <div class="test-header" style="height: 5%">
        <label><input type="checkbox" checked (change)="hide($event,'state')" style="margin: 5px;" />State</label>
        <label><input type="checkbox" checked (change)="hide($event,'zip')" style="margin: 5px;" />Zip Code</label>
        <label><input type="checkbox" checked (change)="hide($event,'emailaddress')"
                style="margin: 5px;" />Email</label>
        <label><input type="checkbox" checked (change)="hide($event,'homePhone')" style="margin: 5px;" />Phone</label>
    </div>
    <button class="btn btn-primary mb-2" (click)="exportAsExcel()">Export</button>
    <div class="">
        <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="width: auto; height: 350px;" class="ag-theme-alpine" [rowData]="rowPatient" [columnDefs]="columnPatient" (rowClicked)="updatePatient($event)" #patientGrid (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
</div>