import { ToastrService } from 'ngx-toastr';
import { GridApi } from 'ag-grid-community';
import { AddMedicationHistoryComponent } from './../add-medication-history/add-medication-history.component';
import { AddMedrxComponent } from './../add-medrx/add-medrx.component';
import { BsModalRef, BsModalService, } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { ClinicalService } from 'src/app/services/clinical.service';
import { RXModel } from 'src/app/model/RXModel';
import * as moment from 'moment';
import { PatientModel } from 'src/app/model/patientModel';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-MedRx',
  templateUrl: './MedRx.component.html',
  styleUrls: ['./MedRx.component.scss']
})
export class MedRxComponent implements OnInit {

  rowMedicalRxHistory: RXModel[] = [];
  AllrowMedicalRxHistory: RXModel[] = [];
  columnMedicalRxHistory: any;
  rowMedicalHistory: any;
  columnMedicalHistory: any;
  bsModalRef !: BsModalRef;
  rxFilterString: string = 'All';
  rxList: RXModel[] = [];
  patientModel: PatientModel = new PatientModel();
  patientId: string = '';
  medGridApi !: GridApi;
  constructor(private modalService: BsModalService, private _clinicalService: ClinicalService,private toaster : ToastrService,private viewportScroller: ViewportScroller) {
    this.patientModel = JSON.parse(localStorage.getItem('ActivePatient')!)
    this.patientId = this.patientModel.patient_id
  }

  ngOnInit() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.columnMedicalRxHistory = [
      {
        headerName: 'Date Started',
        field: 'created_dt', sortable: true, sort: 'desc', resizable: true, rowDrag: true, filter: true, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Product',
        field: 'product_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'RxNorm',
        field: 'rx_norm_code_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,editable : true
      },
      {
        headerName: 'Generic Name',
        field: 'generic_name_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Brand',
        field: 'brand_name_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Strength',
        field: 'strength_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Dose',
        field: 'dose_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Route',
        field: 'route_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Frequency',
        field: 'frequency_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: '#',
        field: 'detail_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Refill',
        field: 'refill_times_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Status',
        field: 'status_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        editable : true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['Active', 'Inactive', 'Discontinued'],
        },
      },
      {
        headerName: 'Date Modification',
        field: 'updated_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'More Info',
        field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: function(params: { value: string; }) {
          return '<a href="https://connect.medlineplus.gov/application?mainSearchCriteria.v.dn=Lasix&mainSearchCriteria.v.cs=2.16.840.1.113883.6.88&mainSearchCriteria.v.c=617314">More Info</a>';
     }
      },
    ];

    this.columnMedicalHistory = [
      {
        headerName: 'Brand Name',
        field: 'brand_name_txt', sortable: true, resizable: true, rowDrag: true, filter: true, floatingFilter: false,
      },
      {
        headerName: 'Date Started',
        field: 'date_started_dt', sortable: true, sort: 'desc', filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Generic Name',
        field: 'generic_name_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Vocabulary',
        field: 'vocabulary_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Code',
        field: 'rx_norm_code_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Detail',
        field: 'detail_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Source',
        field: 'source_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Date Modified',
        field: 'updated_dt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: (data: any) => {
          return moment(data.value).format('MM/DD/YYYY')
        }
      },
      {
        headerName: 'Status',
        field: 'status_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'Reconcilation',
        field: 'reconciled_txt', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false
      },
      {
        headerName: 'More Info',
        field: 'athlete', sortable: true, filter: 'agTextColumnFilter', resizable: true, floatingFilter: false,
        cellRenderer: function(params: { value: string; }) {
          return '<a href="https://connect.medlineplus.gov/application?mainSearchCriteria.v.dn=Lasix&mainSearchCriteria.v.cs=2.16.840.1.113883.6.88&mainSearchCriteria.v.c=617314">More Info</a>';
     }
      },
    ];
    this.rowMedicalHistory = [];
    // this.rowMedicalRxHistory = [];
    this.getRx();
  }

  addMedRx() {
    const initialState = {
      title: 'Exam - MedicalRX',
      patientId: this.patientId
    }
    this.bsModalRef = this.modalService.show(AddMedrxComponent, { initialState });
    // this.bsModalRef.setClass('modal-xl');
    // this.bsModalRef.setClass('center')
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getRx();
    })
  }

  getRx() {
    this._clinicalService.getRx(this.patientId).subscribe((res: any) => {

      this.rxList = res;
      // this.rowMedicalRxHistory = this.rxList;
      this.filterRx();
    })
  }

  filterRx() {
    debugger;
    if (this.rxFilterString != 'All')
    {
      this.rowMedicalRxHistory = this.rxList.filter(x => x.status_txt == this.rxFilterString && x.patient_history_id!='00000000-0000-0000-0000-000000000000');
      this.rowMedicalHistory = this.rxList.filter(x => x.status_txt == this.rxFilterString);
    }
    else
    {
      this.rowMedicalRxHistory = Object.assign([], this.rxList);
      this.rowMedicalRxHistory= this.rowMedicalRxHistory.filter((x: { patient_history_id: string; })=>x.patient_history_id != '00000000-0000-0000-0000-000000000000');
      // this.rowMedicalHistory= Object.assign([], this.rxList);
      this.rowMedicalHistory= this.rxList.filter(x => x.record_type == "Exam");
    }
  }

  addMedicationHistory(){
    const initialState = {
      title: 'Patient Review - Medication'
    }
    this.bsModalRef = this.modalService.show(AddMedicationHistoryComponent, { initialState });
    this.bsModalRef.setClass('modal-xl');
    // this.bsModalRef.setClass('center')
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.onHide.subscribe((res: any) => {
      this.getRx();
    })
  }

  onMedGridReady(params : any){
    this.medGridApi = params.api;
  }

  onCellValueChanged(params : any){
    this.rowMedicalRxHistory[params.rowIndex] = params.data;
  }

  save() {
    this._clinicalService.saveRx(this.rowMedicalRxHistory).subscribe(res => {
      this.toaster.success('Record Saved Successfully.');
      this.getRx();
      // this.bsModalRef.hide();
    }, (error) => {
      this.toaster.error(error.error);
    })
  }

}
