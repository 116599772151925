<form class="form" style="height:101%; width: 100% !important;">
  <div  id="content" style="text-align:center !important;margin-right: 5px;">
    <div>
        <div class="col-md-12" style="padding: 0%;">
            <h3><b>Manage Products</b></h3>

        </div>
    </div>
  </div>
  <div id="content" style="margin-right: 5px;">
    <app-add-charges [isProductModel]="true"></app-add-charges>
  </div>
  </form>
