import { PatientReviewModel } from "./PatientReviewModel";

export class FamilyHistoryModel{
public familyhistory_id : string = '';
public patient_review_id : string = '';
public patient_id : string = '';
public familyhistory_other_txt : string = '';
public otherInFamily : number = 2;
public otherRelation : string = '';
public created_dt : Date = new Date();
public created_by : string = '';
public updated_dt : Date = new Date();
public updated_by : string = '';
public patientReview : PatientReviewModel = new PatientReviewModel();
}