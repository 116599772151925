<div class="modal-header" data-backdrop="static" data-keyboard="false">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="crossClose()">
  <span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body">
    {{content}}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="continue()">{{saveButton}}</button>
    <button type="button" class="btn btn-default" (click)="close()">{{closeBtnName}}</button>
</div>