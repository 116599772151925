import { LookupModel } from './../../../../model/lookupModel';
import { GlobalService } from 'src/app/services/global.service';
import { LookupService } from 'src/app/services/lookup.service';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RXModel } from 'src/app/model/RXModel';
import { ClinicalService } from 'src/app/services/clinical.service';
import { ExamModel } from 'src/app/model/ExamModel';
import { DbEnum } from 'src/app/core/enum/dbEnum.enum';

@Component({
  selector: 'app-add-medrx',
  templateUrl: './add-medrx.component.html',
  styleUrls: ['./add-medrx.component.scss']
})
export class AddMedrxComponent implements OnInit {

  title: string = '';
  closeBtnName: string = '';
  patientId : string = '';
  rxList: RXModel[] = [];
  productLookup: LookupModel[] = [];
  productLookupCopy: LookupModel[] = [];
  genericLookup: LookupModel[] = [];
  genericLookupCopy: LookupModel[] = [];
  brandLookup: LookupModel[] = [];
  brandLookupCopy: LookupModel[] = [];
  strengthLookup: LookupModel[] = [];
  strengthLookupCopy: LookupModel[] = [];
  doseLookup: LookupModel[] = [];
  doseLookupCopy: LookupModel[] = [];
  rxfrequency: LookupModel[] = [];
  rxfrequencyCopy: LookupModel[] = [];
  refilLookup: LookupModel[] = [];
  refilLookupCopy: LookupModel[] = [];
  farNearLookup: LookupModel[] = [];
  farNearLookupCopy: LookupModel[] = [];
  rxNumberLookup : LookupModel[] = [];
  rxNumberLookupCopy : LookupModel[] = [];
  showComponent : boolean = false;
  examModel : ExamModel[] = []
  rxRouteLookup: LookupModel[] = [];
  rxRouteLookupCopy : LookupModel[] = [];
  check_value: string = '';

  constructor(public bsModalRef: BsModalRef, 
    private _clincalService: ClinicalService, private toaster: ToastrService,
    private _lookupService : LookupService,private globalService : GlobalService,
    private _clinicalService : ClinicalService) { }

  ngOnInit() {
    var rxModel = new RXModel();
    rxModel.patient_id = this.patientId;
    this.rxList.push(rxModel);
    this.getAllLookup();
  }

  save() {
    this._clincalService.saveRx(this.rxList).subscribe(res => {
      this.toaster.success('Record Saved Successfully.');
      this.bsModalRef.hide();
    }, (error) => {
      this.toaster.error(error.error);
    })
  }

  getAllLookup(){
    this._lookupService.getAllLookupData().subscribe((res:LookupModel[])=>{
      this.productLookup = res.filter(x=>x.group_txt == 'rxproduct' && x.active_ind == true);
      this.productLookup = this.productLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.productLookupCopy = res.filter(x=>x.group_txt == 'rxproduct');
      this.genericLookup = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'genericname' && x.active_ind == true);
      this.genericLookup = this.genericLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.genericLookupCopy = res.filter(x=>x.group_txt == 'medicationname');
      this.brandLookup = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'brandname'&& x.active_ind == true);
      this.brandLookup = this.brandLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.brandLookupCopy = res.filter(x=>x.group_txt == 'medicationname' && x.sub_group_txt == 'brandname');
      this.strengthLookup = res.filter(x=>x.group_txt == 'rxstrength' && x.active_ind == true);
      this.strengthLookup = this.strengthLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.strengthLookupCopy = res.filter(x=>x.group_txt == 'rxstrength' );
      this.doseLookup = res.filter(x=>x.group_txt == 'dose'&& x.active_ind == true);
      this.doseLookup = this.doseLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.doseLookupCopy = res.filter(x=>x.group_txt == 'dose' );
      this.rxfrequency = res.filter(x=>x.group_txt == 'rxfrequency' && x.active_ind == true);
      this.rxfrequency = this.rxfrequency.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.rxfrequencyCopy = res.filter(x=>x.group_txt == 'rxfrequency' );
      this.refilLookup = res.filter(x=>x.group_txt == 'refill' && x.active_ind == true);
      this.refilLookup = this.refilLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.refilLookupCopy = res.filter(x=>x.group_txt == 'refill' );
      this.farNearLookup = res.filter(x=>x.group_txt == 'VisualAcuities' && x.active_ind == true);
      this.farNearLookup = this.farNearLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.farNearLookupCopy = res.filter(x=>x.group_txt == 'VisualAcuities' );
      this.rxNumberLookup = res.filter(x=>x.group_txt == 'rxnumber' && x.active_ind == true);
      this.rxNumberLookup = this.rxNumberLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.rxNumberLookupCopy = res.filter(x=>x.group_txt == 'rxnumber');
      this.getExam();
      this.showComponent = true;
    })  
    this._lookupService.getAllGlobalLookupData().subscribe((res:LookupModel[])=>{
      this.rxRouteLookup = res.filter(x=>x.group_txt == 'rxroute' && x.active_ind == true);
      this.rxRouteLookup = this.rxRouteLookup.sort((a, b) => {
        if (a.name_txt < b.name_txt) {
          return -1
        }
        if (a.name_txt > b.name_txt) {
          return 1
        }
        return 0
      });
      this.rxRouteLookupCopy = res.filter(x=>x.group_txt == 'rxroute');
    }) 
     
  }
  
  editLookup(item: LookupModel[], itemCopy: LookupModel[], dbType: number) {
    this._lookupService.updateLookup(itemCopy, dbType).subscribe((res: LookupModel[]) => {
      // item.length = 0;
      // res.forEach(element => {
      //   item.push(element);
      // })
      this.getAllLookup()
      // this.sortArray(item)
    })
  }

  public get dbEnum(): typeof DbEnum {
    return DbEnum;
  }

  getExam(){
    this._clinicalService.getExam(this.patientId).subscribe((res:any)=>{
      this.examModel = res;
      
    })
  }

  // keyFunc(event: any) {
  //   this.check_value = event.target.value;
  //   console.log(event);
  // }
  keyFunc(event: any, item1?: any, property?: any) {
    if (event.key != 'Tab') {
      this.check_value = event.target.value;
      item1[property] = event.target.value;
    }
  }
  CreateNew(city:string){          

  }

  saveDropdown(val: any,name_txt: string, item: LookupModel[], group_txt?: string,item1?: any, property?: any, dbType: number = DbEnum.QE_DEV_SQL_DB) {
    //if(this.check_value == ""){
      this.check_value = val;
    //}
    if(this.check_value != ""){
      var index = item.findIndex(x => x.name_txt.trim().toLowerCase() == this.check_value.trim().toLowerCase());
    // var index = item.findIndex(x => x.name_txt.toLowerCase() == event.target.value.toLowerCase());
    // var index1 = item.findIndex(x => x.name_txt.toLowerCase() == this.check_value.toLowerCase());
    if (index < 0) {
      var lookupModel = new LookupModel();
      if (item[0]?.group_txt) {
        lookupModel.group_txt = item[0]?.group_txt;
        lookupModel.sub_group_txt = item[0]?.sub_group_txt;
      }
      else {
        lookupModel.group_txt = (group_txt!);
        lookupModel.sub_group_txt = 'All';
      }
      lookupModel.active_ind = true;
      lookupModel.name_txt = val

      if (this.check_value != lookupModel.name_txt) {
        lookupModel.name_txt = this.check_value;
      }
      if (dbType != 1) {
      var content = 'Would you like to save this ' + name_txt + ' list?'
      this.globalService.modal(content, 'New Item?', 'OK', 'Cancel').subscribe(res => {
        if (res.res) {
          // lookupModel.sort_val = item.reduce((a, b) => Math.max(a.sort_val, b.sort_val))
          if (dbType == DbEnum.QE_DEV_SQL_DB) {
            this._lookupService.insertLookup(lookupModel).subscribe(res => {
              this.getAllLookup();
              this.toaster.success('Record Saved Successfully.');
              item1[property]=val;//this.check_value;
              
            })
          }
          // else {
          //   this._lookupService.insertConfigLookup(lookupModel).subscribe(res => {
          //     this.toaster.success('Record Saved Successfully.');
          //     item1[property]=this.check_value;
          //     // this.getAllLookup();
          //   })
          // }
        }
      })
    }
    item1[property] = val;
  } else {
    item1[property] = item[index]?.name_txt;
  }
  }
  this.check_value = "";
  }

}
