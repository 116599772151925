import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IdentifierModel } from 'src/app/model/identiferModel';
import { LookupService } from 'src/app/services/lookup.service';


@Component({
  selector: 'app-add-identifier',
  templateUrl: './add-identifier.component.html',
  styleUrls: ['./add-identifier.component.scss']
})
export class AddIdentifierComponent implements OnInit {
  title: string = '';
  identifierFormGroup!: FormGroup;
  identifierList: Array<IdentifierModel> = new Array<IdentifierModel>();
  identifier: Array<IdentifierModel> = new Array<IdentifierModel>();
  identifierModel: IdentifierModel = new IdentifierModel();
  showButton = false;
  enablEditButton = false;
  showInactive : boolean = false;
  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private toaster: ToastrService, private _lookupService: LookupService) { 
    
  }

  ngOnInit(): void {
    
    this.createFormGroup();
    this.getIdentifier();
    this.identifierFormGroup.disable();
  }

  createFormGroup(){
    this.identifierFormGroup = this.formBuilder.group({
      name: ['', Validators.required]
    })
  }
  
  create() {
    this.showButton = true;
  }
  cancel() {
    this.showButton = false;
    if (this.identifierModel.id == 0) {
      this.identifierFormGroup.reset();
    }
    this.identifierFormGroup.disable();
  }
  getIdentifier() {
    
    this._lookupService.getIdentifierLookup().subscribe((res: any) => {
      this.identifier = res;
      this.identifierList  = res;
      //this.filter(false);
      this.identifierList = this.compare(this.identifierList);
    }, (error) => {
      this.toaster.error(error.error);
    });
  }

  setForm(identifier: IdentifierModel) {
    this.identifierModel = Object.assign({}, identifier)
    this.enablEditButton = true;
  }
  addNew() {
    this.identifierFormGroup.enable();
    this.identifierFormGroup.reset();
    this.identifierModel = new IdentifierModel();
    this.showButton = true;
  }
  save() {
        
    if (this.identifierModel.id == 0) {
      if (this.identifierFormGroup.valid) {
        this._lookupService.insertIdentifier(this.identifierModel).subscribe(res => {
          this.getIdentifier();
          this.showButton = false;
          this.enablEditButton = false;
          this.toaster.success('Record Saved Successfully.');
          this.identifierFormGroup.disable();
        }, (error) => {
          this.toaster.error(error.error);
        });
      }
     
    }
    else {
      if (this.identifierFormGroup.valid) {
        this._lookupService.updateIdentifier(this.identifierModel).subscribe(res => {
          this.getIdentifier();
          this.showButton = false;
          this.toaster.success('Record Updated Successfully.');
        }, (error) => {
          this.toaster.error(error.error);
        });
        this.showButton = false;
      }      
    }    
  }
  filter(active:boolean){
    // this.showInactive = active;    
    // if(!this.showInactive)
    // {
    //   this.identifierList = this.identifier.filter(x => x.active_ind == true);
    // } else {
    //   this.chargeList = this.charge.filter(x => x.active_ind == false);
    // }
    this.identifierList = this.compare(this.identifierList);
  }
  compare(array: IdentifierModel[]){    
    array.sort((a,b) => {
      if (!isNaN(Number(a.name)) && !isNaN(Number(b.name))){      
          if (Number(a.name) > Number(b.name)){
            return 1;
          }else if(Number(a.name) < Number(b.name)){
            return -1;
          }else{
            return 0;
          }
          
      }else{         
        if (a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
        }else if(a.name.toLowerCase() < b.name.toLowerCase()){
          return -1;
        }else{
          return 0;
        }      
      }    
    })
    return array;
  }
  findIdentifiers(event: any){       
    var searchString = event.target.value;
    if(searchString == ''){
      this.identifierList = this.identifier;
      // if (this.showInactive){
      //   this.identifierList = this.identifier;
      // }else{
      //   this.filter(false);
      // }      
    }
    else{
      // if (this.showInactive){
      //   this.chargeList = this.charge.filter((item) => {
      //     if (item.code_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
      //       return item;
      //     }
      //     if (item.name_txt.toLocaleLowerCase().indexOf(searchString) !== -1) {
      //       return item;
      //     }
      //     return;
      //   });
      // }else{
      //   this.chargeList = this.charge.filter(x=> (x.code_txt.toLowerCase().includes(searchString.toLowerCase()) || x.name_txt.toLowerCase().includes(searchString.toLowerCase())) && x.active_ind == true)
      // }
      this.identifierList = this.identifier.filter(x=> x.name.toLowerCase().includes(searchString.toLowerCase()))
    }
    this.identifierList = this.compare(this.identifierList);
  }
  edit() {
    this.showButton = true;
    this.identifierFormGroup.enable();
  }

}
