import { stringify } from '@angular/compiler/src/util';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserModel } from '../model/userModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getUserList(practiceId: string): Observable<UserModel> {
    return this.http.get<UserModel>(environment.server + 'api/user/getall?practiceId=' + practiceId);
  }

  getByUserId(userId: string) {
    return this.http.get<UserModel>(environment.server + 'api/user/getById?UserId=' + userId);
  }

  addUser(userModel: UserModel): Observable<UserModel> {
    const formData: FormData = new FormData();
    if (userModel.signature_img) {
      let temp = Object.assign(userModel.signature_img);
      formData.append('photo', temp, temp.name);
      userModel.signature_img = null;
    }
    formData.append('userModel', JSON.stringify(userModel));
    return this.http.post<UserModel>(environment.server + 'api/user/add', formData);
  }

  updateUser(userModel: UserModel): Observable<UserModel> {
    debugger;
    const formData: FormData = new FormData();
    if (userModel.signature_img) {
      let temp = Object.assign(userModel.signature_img);
      formData.append('photo', temp, temp.name);
      userModel.signature_img = null;
    }
    formData.append('userModel', JSON.stringify(userModel));
    return this.http.post<UserModel>(environment.server + 'api/user/update', formData);
  }

}
