export class ProcedureModel {
    public procedure_id: string = '';
    public description_txt: string = '';
    public default_place_of_service_txt: string = '';
    public lab_procedure_ind: boolean = false;
    public taxable_ind: boolean = false;
    public charge_amt: number = 0;
    public patient_charge_amt: number = 0;
    public active_ind: boolean = false;
    public code_txt: string = '';
    public global_period_day_cnt: number = 0;
    public version_txt: string = '';
    public practice_specific_ind: boolean = false;
    public commission_pct: number = 0;
    public created_dt: Date = new Date();
    public created_by: string = '';
    public updated_dt: Date = new Date();
    public updated_by: string = '';
    public name_txt: string = '';
}
