<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" routerLink="/patient/add-exam"
                [queryParams]="{patientId:patientDetails.patientID,examId : examId}">
                <!-- <img src="../../../../assets/img/close.png" style="height: 30px;width: 30px;"> -->
                Cancel Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(false)">
                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save Exam
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" (click)="save(true)">


                <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;"> -->
                Save & Close
            </button> &nbsp;&nbsp;
            <button class="button" style="height: 68px;" [disabled]="examId == ''" [useExistingCss]="true"
                printSectionId="print-section" ngxPrint>
                <!-- <img src="../../../../assets/img/print_printer.png" style="height: 30px;width: 30px;"> -->
                Print
            </button> &nbsp;&nbsp;


            <hr>
        </div>
    </div>
</div>
<fieldset>
    <div class="pt-5 mt-2">
        <div class="field">
            <div class="titlebox"></div>
            <div>
                <h5><b>Contact Order Form</b></h5>
                <div>
                    <label>Patient:</label>&nbsp;<label
                        style="font-weight:bold">{{patientDetails.lastName}}, {{patientDetails.firstName}}</label>
                </div>
                <div>
                    <label>Insurance:</label>&nbsp;<label style="font-weight:bold">{{insuranceName}}</label>
                </div>
                <div>
                    <label>Phone:</label>&nbsp;<label style="font-weight:bold">{{patient.homePhone}}</label>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label>Date:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                    (ngModelChange)="examModel.omR_ContactOrderForm.date = $event"
                                    [ngModel]="examModel.omR_ContactOrderForm.date | date:'MM/dd/yyyy'"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.notification_ind"
                            class="mx-2">
                        <label>Generate Notification</label>
                    </div>
                </div>
                <div>
                    <label>OD:</label>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label>Brand:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-11">
                                <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew"
                                    [items]="brandLookup"
                                    (keyup)="keyFunc($event,examModel.omR_ContactOrderForm,'odLens')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ContactOrderForm.odLens,brandLookup,'brand','Brand','All',examModel.omR_ContactOrderForm,'odLens',dbEnum.QE_DEV_SQL_DB)"
                                    addTagText="Add New" [addTag]="CreateNew" bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.odLens">
                                </ng-select>
                            </div>
                            <div class="col-md-1">
                                <a href="javascript:void(0)"
                                    (click)="editLookup(brandLookup,brandCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Manufacturer:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="col-md-11">
                                <div class="form-group input-group-sm">
                                    <ng-select [clearable]="true" [items]="manufacture"
                                        (ngModelChange)="changevalueod($event)" bindLabel="name_txt"
                                        bindValue="name_txt"
                                        [(ngModel)]="examModel.omR_ContactOrderForm.odManufacturer">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <a href="javascript:void(0)" (click)="editManufacture()"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Account#:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odAccount"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Phone:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odPhone"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label>Power:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odPower"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>BC:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odbc" class="form-control">
                    </div>
                    <div class="col-md-1">
                        <label>Diam:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odDiam" class="form-control">
                    </div>
                </div>
                <div>
                    <label>OS:</label>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label>Brand:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew"
                                [items]="brandLookup" class="col-md-10"
                                (keyup)="keyFunc($event,examModel.omR_ContactOrderForm,'osLens')"
                                (keydown.tab)="saveDropdown(examModel.omR_ContactOrderForm.osLens,brandLookup,'brand','Brand','All',examModel.omR_ContactOrderForm,'osLens',dbEnum.QE_DEV_SQL_DB)"
                                addTagText="Add New" [addTag]="CreateNew" bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="examModel.omR_ContactOrderForm.osLens">
                            </ng-select>
                            <a href="javascript:void(0)"
                                (click)="editLookup(brandLookup,brandCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Manufacturer:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <ng-select [clearable]="true" [items]="manufacture" (ngModelChange)="changevalueos($event)"
                                class="col-md-10" bindLabel="name_txt" bindValue="name_txt"
                                [(ngModel)]="examModel.omR_ContactOrderForm.osManufacturer">
                            </ng-select>
                            <a href="javascript:void(0)" (click)="editManufacture()"><img
                                    src="../../../../assets/img/pencil-png.png" height="10px"></a>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Account#:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" class="form-control"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.osAccount">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Phone:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" class="form-control"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.osPhone">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-1">
                        <label>Power:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osPower"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>BC:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osbc"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Diam:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osDiam"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.trials" class="mx-2">
                        <label>Trials</label>
                        <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.supply" class="mx-2">
                        <label>Supply</label>
                    </div>
                    <div class="col-md-1">
                        <label>Amount of CLS:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.amountCLS"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-1">
                        <label>Ordered by:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-11">
                                <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew"
                                    [items]="orderByLookup" class="col-md-12"
                                    (keyup)="keyFunc($event,examModel.omR_ContactOrderForm,'orderedBy')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ContactOrderForm.orderedBy,orderByLookup,'orderedby','Ordered by','All',examModel.omR_ContactOrderForm,'orderedBy',dbEnum.QE_DEV_SQL_DB)"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.orderedBy">
                                </ng-select>
                            </div>
                            <div class="col-md-1">
                                <a href="javascript:void(0)"
                                    (click)="editLookup(orderByLookup,orderByCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Ref#:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.ref" class="form-control">
                    </div>
                    <div class="col-md-1">
                        <label>Time Ordered:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.timeOrdered"
                            class="form-control col-md-6">
                    </div>
                    <div class="col-md-1">
                        <label>Date Ordered:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                            (ngModelChange)="examModel.omR_ContactOrderForm.dateOrdered = $event"
                            [ngModel]="examModel.omR_ContactOrderForm.dateOrdered | date:'MM-dd-yyyy'"
                            class="form-control col-md-12">
                    </div>
                    <div class="col-md-1">
                        <label>Comment:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <textarea class="form-control"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.comment1"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-1">
                        <label>Received by:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-11">
                                <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew"
                                    [items]="receivedByLookup" class="col-md-12"
                                    (keyup)="keyFunc($event,examModel.omR_ContactOrderForm,'receivedBy')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ContactOrderForm.receivedBy,receivedByLookup,'receivedby','Received by','All',examModel.omR_ContactOrderForm,'receivedBy',dbEnum.QE_DEV_SQL_DB)"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.receivedBy">
                                </ng-select>
                            </div>
                            <div class="col-md-1">
                                <a href="javascript:void(0)"
                                    (click)="editLookup(receivedByLookup,receivedByCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Time Received:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.timeReceived"
                                    class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <label>Date Received:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-12">
                                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                                    (ngModelChange)="examModel.omR_ContactOrderForm.dateReceived = $event"
                                    [ngModel]="examModel.omR_ContactOrderForm.dateReceived | date:'MM-dd-yyyy'"
                                    class="form-control col-md-6">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.checkedOk">
                        <label class="mx-2">Checked in OK</label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-1">
                        <label>Patient Owes:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.patientOwes"
                            class="form-control">
                    </div>
                    <div class="col-md-3">
                        <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.patientCalled">
                        <label class="mx-2">Patient Called</label>
                    </div>
                    <div class="col-md-1">
                        <label>Comment:</label>
                    </div>
                    <div class="col-md-2">
                        <textarea class="form-control" [(ngModel)]="examModel.omR_ContactOrderForm.comment2"></textarea>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-1">
                        <label>Dispensed by:</label>
                    </div>
                    <div class="col-md-2">
                        <div class="form-row">
                            <div class="form-group input-group-sm input-group-sm col-md-11">
                                <ng-select [clearable]="true" addTagText="Add New" [addTag]="CreateNew"
                                    [items]="dispensedbyLookup" class="col-md-12"
                                    (keyup)="keyFunc($event,examModel.omR_ContactOrderForm,'dispensedBy')"
                                    (keydown.tab)="saveDropdown(examModel.omR_ContactOrderForm.dispensedBy,dispensedbyLookup,'dispensedby','Dispensed by','All',examModel.omR_ContactOrderForm,'dispensedBy',dbEnum.QE_DEV_SQL_DB)"
                                    bindLabel="name_txt" bindValue="name_txt"
                                    [(ngModel)]="examModel.omR_ContactOrderForm.dispensedBy">
                                </ng-select>
                            </div>
                            <div class="col-md-1">
                                <a href="javascript:void(0)"
                                    (click)="editLookup(dispensedbyLookup,dispensedbyCopyLookup,dbEnum.QE_DEV_SQL_DB)"><img
                                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>Date Dispensed:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                            (ngModelChange)="examModel.omR_ContactOrderForm.dateDispensed = $event"
                            [ngModel]="examModel.omR_ContactOrderForm.dateDispensed | date:'MM-dd-yyyy'"
                            class="form-control col-md-6">
                    </div>
                    <div class="col-md-2">
                        <label>Amount Collected at Dispense:</label>
                    </div>
                    <div class="col-md-2">
                        <input type="text" class="form-control"
                            [(ngModel)]="examModel.omR_ContactOrderForm.amountDispense">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-md-1">
                        <label>Comment:</label>
                    </div>
                    <div class="col-md-2">
                        <textarea class="form-control" [(ngModel)]="examModel.omR_ContactOrderForm.comment3"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="print-section" hidden class="pt-5 mt-2">

        <div class="field">
            <div class="col-12 text-center">
                <span class="doc-info" *ngIf="officename"> {{officename}}</span>
            </div>
            <div class="col-12 text-center">
                <span class="doc-info" *ngIf="officeaddress">{{officeaddress}}</span>
            </div>
            <div class="col-12 text-center">
                <span class="doc-info" *ngIf="officeCitySZ">{{officeCitySZ}}</span>
            </div>
            <div class="col-12 text-center">
                <span class="doc-info" *ngIf="officephone"> {{officephone}}</span>
            </div>
            <div class="col-12 text-center">
                <span class="doc-info" *ngIf="officeemail"> {{officeemail}}</span>
            </div>
            <div class="col-12 text-center">
                <span class="doc-info">NPI # {{office_npi_txt}}</span>
            </div>

            <!-- <div class="col-12 text-center doc-info">
                <span class="doc-info">NPI # {{License}}</span>
            </div> -->
            <br>
            <h4 class="text-center"><b>Contact Order Form</b></h4><br>

            <div class="row col-12">
                <div class="col-6">
                    <div class="row">
                        <div class="col-4">
                        </div>
                        <div class="col-2 pr-0">
                            <label class="patient-info">Patient:</label>
                        </div>
                        <div class="col-6 pl-0">
                            <div class="patient-reg-info">{{patientDetails.firstName}} {{patientDetails.lastName}}-({{patientDetails.dateOfBirth | date:'MM-dd-yyyy'}})</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                        </div>
                        <div class="col-2 pr-0">
                            <label class="patient-info">Phone:</label>
                        </div>
                        <div class="col-6 pl-0">
                            <div class="patient-reg-info">{{patientDetails.cellPhone}}</div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">
                        </div>
                        <div class="col-2 pr-0">
                            <label class="patient-info">Email:</label>
                        </div>
                        <div class="col-6 pl-0">
                            <div class="patient-reg-info">{{patientDetails.emailaddress}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-2">
                        </div>
                        <div class="col-3 pr-0 pl-0">
                            <label class="patient-info">Date of Service:</label>
                        </div>
                        <div class="col-7 pl-0">
                            <div class="patient-reg-info">{{examModel.exam_Date | date:'MM-dd-yyyy'}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                        </div>
                        <div class="col-3 pr-0 pl-0">
                            <label class="patient-info">Insurance:</label>
                        </div>
                        <div class="col-7 pl-0">
                            <div class="patient-reg-info">{{insuranceName}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-2">
                        </div>
                        <div class="col-3 pr-0 pl-0">
                            <label class="patient-info">SS#:</label>
                        </div>
                        <div class="col-7 pl-0">
                            <div class="patient-reg-info">{{socialSecurityNum}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" style="border: 1px solid black;"></div><br>

            <div>
                <label><b>OD:</b></label>
            </div>
            <div class="row">
                <div class="col-1">
                    <label>Power:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odPower"
                        class="formcontrol form-control">
                </div>
                <div class="col-1">
                    <label>Manufacturer:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odManufacturer"
                        class="formcontrol form-control">
                </div>
                <div class="col-1">
                    <label>Account#:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odAccount"
                        class="formcontrol form-control">
                </div>
            </div><br>

            <div class="row">
                <div class="col-1">
                    <label>BC:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odbc"
                        class="formcontrol form-control">
                </div>
                <div class="col-1">
                    <label>Lens:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odLens" class="formcontrol form-control">
                </div>
                <!-- <div class="col-1">
                    <label>Diam:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odDiam"
                        class="formcontrol form-control">
                </div> -->
                <div class="col-1">
                    <label>Phone:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odPhone"
                        class="formcontrol form-control">
                </div>
            </div><br>
            <div class="row">
                <div class="col-1">
                    <label>Diam:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.odDiam"
                        class="formcontrol form-control">
                </div>
            </div>
            <div>
                <label><b>OS:</b></label>
            </div>
            <div class="row">
                <div class="col-1">
                    <label>Power:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osPower"
                        class="formcontrol form-control">
                </div>
                <div class="col-1">
                    <label>Manufacturer:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osManufacturer"
                        class="formcontrol form-control">
                </div>
                <div class="col-1">
                    <label>Account#:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osAccount"
                        class="formcontrol form-control">
                </div>
            </div><br>

            <div class="row">
                <div class="col-1">
                    <label>BC:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osbc"
                        class="formcontrol form-control">
                </div>
                <div class="col-1">
                    <label>Lens:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osLens" class="formcontrol form-control">
                </div>
                <!-- <div class="col-1">
                    <label>Diam:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osDiam"
                        class="formcontrol form-control">
                </div> -->
                <div class="col-1">
                    <label>Phone:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osPhone"
                        class="formcontrol form-control">
                </div>
            </div><br>
            <div class="row">
                <div class="col-1">
                    <label>Diam:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.osDiam"
                        class="formcontrol form-control">
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-4">
                    <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.trials" class="mx-2">
                    <label>Trials</label>
                    <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.supply" class="mx-2">
                    <label>Supply</label>
                </div>
                <div class="col-3"></div>
                <div class="col-2 text-right">
                    <label>Amount of CLS:</label>
                </div>
                <div class="col-3 text-right">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.amountCLS"
                        class="formcontrol form-control">

                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-2">
                    <label>Ordered by:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.orderedBy"
                    class="formcontrol form-control">
                </div>
            <!-- </div>
            <div class="row"> -->
                <div class="col-2">
                    <label>Ref#:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.ref"
                    class="formcontrol form-control">
                </div>
            </div><br>
          <div class="row">
            <div class="col-2">
                    <label>Time Ordered:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.timeOrdered"
                    class="formcontrol form-control">
                </div>
          <!-- </div>
          <div class="row"> -->
            <div class="col-2">
                    <label>Date Ordered:</label>
                </div>
                <div class="col-3">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                    (ngModelChange)="examModel.omR_ContactOrderForm.dateOrdered = $event"
                    [ngModel]="examModel.omR_ContactOrderForm.dateOrdered | date:'MM-dd-yyyy'"
                    class="form-control col-12">
                </div>
          </div><br>
          <div class="row">
            <div class="col-2">
                <label>Comment:</label>
            </div>
            <div class="col-9">
                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.comment1"
                class="formcontrol form-control">
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-2">
                <label>Received by:</label>
            </div>
            <div class="col-3">
                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.receivedBy"
                class="formcontrol form-control">
            </div>
          <!-- </div>
          <div class="row"> -->
            <div class="col-2">
                <label>Time Received:</label>
            </div>
            <div class="col-3">
                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.timeReceived"
                class="formcontrol form-control">
            </div>
          </div><br>
          <div class="row">
            <div class="col-2">
                <label>Date Received:</label>
            </div>
            <div class="col-3">
                <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                            (ngModelChange)="examModel.omR_ContactOrderForm.dateReceived = $event"
                            [ngModel]="examModel.omR_ContactOrderForm.dateReceived | date:'MM-dd-yyyy'"
                            class="form-control col-12">
            </div>
          <!-- </div>
          <div class="row"> -->
            <div class="col-2">
                <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.checkedOk">
                <label class="mx-2">Checked in OK</label>
            </div>
          </div><br>
          <div class="row">
            <div class="col-2">
                <label>Patient Owes:</label>
            </div>
            <div class="col-3">
                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.patientOwes"
                class="formcontrol form-control">
            </div>
          <!-- </div>
          <div class="row"> -->
            <div class="col-3">
                <input type="checkbox" [(ngModel)]="examModel.omR_ContactOrderForm.patientCalled">
                <label class="mx-2">Patient Called</label>
            </div>
          </div><br>
          <div class="row">
            <div class="col-2">
                <label>Comment:</label>
            </div>
            <div class="col-9">
                <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.comment2"
                class="formcontrol form-control">
            </div>
          </div>

            <hr>
            <div class="row">
                <div class="col-2">
                    <label>Dispensed by:</label>
                </div>
                <div class="col-3">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.dispensedBy"
                    class="formcontrol form-control">
                </div>
              <!-- </div>
              <div class="row"> -->
                <div class="col-2">
                    <label>Date Dispensed:</label>
                </div>
                <div class="col-3">
                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker"
                            (ngModelChange)="examModel.omR_ContactOrderForm.dateDispensed = $event"
                            [ngModel]="examModel.omR_ContactOrderForm.dateDispensed | date:'MM-dd-yyyy'"
                            class="form-control">
                </div>
              </div><br>
              <div class="row">
                <div class="col-3">
                    <label>Amt. Collected at Dispense:</label>
                </div>
                <div class="col-6">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.amountDispense"
                    class="formcontrol form-control">
                </div>
              </div><br>
              <div class="row">
                <div class="col-2">
                    <label>Comment:</label>
                </div>
                <div class="col-9">
                    <input type="text" [(ngModel)]="examModel.omR_ContactOrderForm.comment3"
                    class="formcontrol form-control">
                </div>
              </div>
        </div>
    </div>
</fieldset>
