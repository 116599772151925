export class ManufacturerModel{
    public manufacturer_id : string = '';
    public name_txt : string = '';
    public type_txt : string = '';
    public account_txt : string = '';
    public phone_txt : string = '';
    public active_ind : boolean = false;
    public created_dt : Date = new Date();
    public created_by : string = '';
    public updated_dt : Date = new Date();
    public updated_by : string = '';
    public address_txt : string = '';
}