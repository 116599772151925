import { GlobalService } from './services/global.service';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './core/guard/auth.guard';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './core/token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import 'ag-grid-enterprise';
// import { LicenseManager } from 'ag-grid-enterprise';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from './shared/shared/shared.module';
import { CalendarModule } from 'angular-calendar';
import { FlatpickrModule } from 'angularx-flatpickr';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { NgIdleService } from './services/idle.service';
// import {NgIdleModule} from '@ng-idle/core';
// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    AgGridModule.withComponents([]),
    ModalModule.forRoot(),
    NgSelectModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    CalendarModule,
    FlatpickrModule,
    NgbModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({      
      //excludeRegexp: ["/api/attachment/getall"],
      showForeground: true,      
    }),
    //NgIdleKeepaliveModule.forRoot()
  ],
  providers: [AuthGuard, GlobalService, NgIdleService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
