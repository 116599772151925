<div class="header-row fixed-top">
    <div style="background-color:  white;width: 100%; border: 1px solid   white;border-radius: 10px; ">
        <div class="col-md-12" style="padding: 0%;">
            <button class="button" style="height: 68px;" routerLink="/dashboard">                
                   Cancel Patient
                </button>&nbsp;&nbsp;  

                <button class="button" style="height: 68px;" (click)="save()">                
                    Save Patient
                 </button>&nbsp;&nbsp; 
                 <button class="button" style="height: 68px;" (click)="saveClose()">                
                    Save & Close
                 </button>&nbsp;&nbsp;
                
                <!-- <button class="button" style="height: 68px;"  (click)="save()" [disabled]="!addPatientFormGroup.valid">
                    <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;text-align: center;"> -->
                   
                <!-- </button>&nbsp;&nbsp; -->

                <!-- <button class="button" style="height: 68px;"  (click)="saveClose()" [disabled]="!addPatientFormGroup.valid"> -->
                    <!-- <img src="../../../../assets/img/save.ico" style="height: 30px;width: 30px;text-align: center;"> -->
                    <!-- Save & Close
                </button>&nbsp;&nbsp; --> 
           
            
            <hr>
        </div>
    </div>
</div>
<form [formGroup]="addPatientFormGroup" *ngIf="showComponent">
    <fieldset class="form p-1">
        <div class="row ">
            <span><b style="margin: 0px;">Demographics:</b></span>
        </div>
        <div class="row control-row">
            <div class="col-md-1">
                <div class="form-group input-group-sm input-group-sm">
                    <label>*Date of Entry:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm input-group-sm">
                    <input class="form-control " type="text" [bsConfig]="bsConfig" (ngModelChange)="patient.dateOfEntry = $event" bsDatepicker #dp="bsDatepicker" formControlName="dateOfEntry" [ngModel]="patient.dateOfEntry | date:'MM-dd-yyyy'">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Patient Acct.#:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="input" formControlName="patientAcct" [(ngModel)]="patient.patientAcctNo">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group input-group-sm">
                    <input type="checkbox" class="m-2" formControlName="billPatient" [(ngModel)]="patient.do_not_bill_ind">
                    <label>Don't Bill Patient</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>*Last Name:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="lastName" [ngClass]="{'red':addPatientFormGroup.controls.lastName.invalid}" [(ngModel)]="patient.lastName">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>*First Name:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="input" formControlName="firstName" [(ngModel)]="patient.firstName" [ngClass]="{'red':addPatientFormGroup.controls.firstName.invalid}">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>MI:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="middleInitial" [(ngModel)]="patient.middleInitial" maxlength=1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Social Security #:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" maxlength=11 formControlName="socialSecurity" [(ngModel)]="patient.socialSecurityNum">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Identifier(s)</label>
                </div>
            </div>
            <div class="col-md-2">
                <!-- <div class="form-group input-group-sm">
                    <form ngForm="search">
                        <ng-multiselect-dropdown name="identifier" [settings]="dropdownSettings" [data]="identifier" (keyup.enter)="insertIdenfiers($event)" [(ngModel)]="patient.patientIdentifiers" editFieldPosBelow="true">
                        </ng-multiselect-dropdown>
                    </form>
                </div> -->
                <div class="form-row">
                    <div class="col-md-11 form-group input-group-sm">
                        <form ngForm="search">
                            <ng-multiselect-dropdown name="identifier" [settings]="dropdownSettings" [data]="identifier" (keyup.enter)="insertIdenfiers($event)" [(ngModel)]="patient.patientIdentifiers" editFieldPosBelow="true">
                            </ng-multiselect-dropdown>
                        </form>
                    </div>
                    <div class="col-md-1">
                        <a href="javascript:void(0)" (click)="editOffice('add-identifier','Edit Identifier', false, false)" class="editlink"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Date Of Birth:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" formControlName="dateOfBirth" (ngModelChange)="patient.dateOfBirth = $event" [ngModel]="patient.dateOfBirth | date:'MM-dd-yyyy'">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Sex:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select class="custom" [clearable]="true" [items]="sexLookup" bindLabel="key" formControlName="sex" bindValue="value" [(ngModel)]="patient.sex">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Race:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" formControlName="race"  [(ngModel)]="patient.race" (keyup)="keyFunc($event, patient, 'race')" (keydown.tab)="saveDropdown(patient.race, raceLookup, patient, 'race')">
                        <ng-option *ngFor="let r of raceLookup" [value]="r.name_txt">{{r.name_txt}}</ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Ethnicity:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="ethnicityLookup"  bindLabel="name_txt" formControlName="ethnicity" bindValue="name_txt" [(ngModel)]="patient.ethnicity" (keyup)="keyFunc($event, patient, 'ethnicity')" (keydown.tab)="saveDropdown(patient.ethnicity, ethnicityLookup, patient, 'ethnicity')">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Preferred Language:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true"  [items]="languageLookup" bindLabel="name_txt" formControlName="language" bindValue="name_txt" [(ngModel)]="patient.language" (keyup)="keyFunc($event, patient, 'language')" (keydown.tab)="saveDropdown(patient.language, languageLookup, patient, 'language')">
                    </ng-select>
                    <!-- <ng-multiselect-dropdown name="name_txt" [settings]="Languagesdropdown" [data]="languageLookup" (keyup.enter)="insertlanguages($event)" [(ngModel)]="patient.patientIdentifiers" editFieldPosBelow="true">
                    </ng-multiselect-dropdown> -->
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Address1:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="address1" [(ngModel)]="patient.address">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Address2:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="address2" [(ngModel)]="patient.address2">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>City:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="city" [(ngModel)]="patient.city">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>State:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="stateLookup" bindLabel="name_txt" formControlName="state" bindValue="name_txt" [(ngModel)]="patient.state" (keyup)="keyFunc($event, patient, 'state')" (keydown.tab)="saveDropdown(patient.state, stateLookup, patient, 'state')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Zip:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" maxlength="6" formControlName="zip" [(ngModel)]="patient.zip">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Address Type:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="addresstypeLookup" bindLabel="name_txt" formControlName="addressType" bindValue="name_txt" [(ngModel)]="patient.addressType" (keyup)="keyFunc($event, patient, 'addressType')" (keydown.tab)="saveDropdown(patient.addressType, addresstypeLookup, patient, 'addressType')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Country:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select class="ng-select-style" [clearable]="true" [items]="countryLookup"  bindLabel="name_txt" formControlName="country" bindValue="name_txt" [(ngModel)]="patient.country" (keyup)="keyFunc($event, patient, 'country')" (keydown.tab)="saveDropdown(patient.country, countryLookup, patient, 'country')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Home Phone:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input type="text" class="form-control" formControlName="homePhone" [(ngModel)]="patient.homePhone" placeholder="(xxx) xxx-xxxx" phone minlength=14 maxlength=14 [ngClass]="{'red':addPatientFormGroup.controls.homePhone.invalid }">
                </div>
            </div>
            <div class="col-md-2" style="padding-left: 0px !important;">
                <button class="btn btn-primary" style="padding:2px !important; font-size:10px !important" (click)="copyPhone()">Copy Phone</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Cell Phone:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">                    
                    <input type="text" class="form-control" formControlName="cellPhone" [(ngModel)]="patient.cellPhone" placeholder="(xxx) xxx-xxxx" phone minlength=14 maxlength=14 [ngClass]="{'red':addPatientFormGroup.controls.cellPhone.invalid }">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Appointments:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                 <div class="form-group input-group-sm">
                     <ng-select [clearable]="true" [items]="contactmethodLookup"  bindLabel="name_txt" formControlName="responsibleCountry" bindValue="name_txt" [(ngModel)]="patient.responsibleCountry" (keyup)="keyFunc($event, patient, 'responsibleCountry')" (keydown.tab)="saveDropdown(patient.responsibleCountry, contactmethodLookup, patient, 'responsibleCountry')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Exam Notifications:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                 <div class="form-group input-group-sm">
                     <ng-select [clearable]="true" [items]="contactmethodLookup"  bindLabel="name_txt" formControlName="examNotification" bindValue="name_txt" [(ngModel)]="patient.notifications_contact_txt" (keyup)="keyFunc($event, patient, 'notifications_contact_txt')" (keydown.tab)="saveDropdown(patient.notifications_contact_txt, contactmethodLookup, patient, 'notifications_contact_txt')">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Cell Phone Provider:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                 <div class="form-group input-group-sm">
                     <ng-select [clearable]="true" [items]="mobileproviderLookup"  bindLabel="name_txt" formControlName="cellPhoneProvider" bindValue="name_txt" [(ngModel)]="patient.cellPhoneProvider" (keyup)="keyFunc($event, patient, 'cellPhoneProvider')" (keydown.tab)="saveDropdown(patient.cellPhoneProvider, mobileproviderLookup, patient, 'cellPhoneProvider')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Marketing:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                 <div class="form-group input-group-sm">
                     <ng-select [clearable]="true" [items]="contactmethodLookup"  bindLabel="name_txt" formControlName="marketing" bindValue="name_txt" [(ngModel)]="patient.marketing_contact_txt" (keyup)="keyFunc($event, patient, 'marketing_contact_txt')" (keydown.tab)="saveDropdown(patient.marketing_contact_txt, contactmethodLookup, patient, 'marketing_contact_txt')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Other Notifications:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="contactmethodLookup"  bindLabel="name_txt" formControlName="other" bindValue="name_txt" [(ngModel)]="patient.reminders_contact_txt" (keyup)="keyFunc($event, patient, 'reminders_contact_txt')" (keydown.tab)="saveDropdown(patient.reminders_contact_txt, contactmethodLookup, patient, 'reminders_contact_txt')">
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
          
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Email Address:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                 <div class="form-group input-group-sm">
                     <input class="form-control" type="text" formControlName="email" [(ngModel)]="patient.emailaddress" [ngClass]="{'red':addPatientFormGroup.controls.email.invalid}">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Regular Office:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-row">
                    <div class="col-md-11 form-group">
                        <ng-select [clearable]="true" [items]="office" class="col-md-12 ng-select-style" bindLabel="name_txt" formControlName="regularOffice" bindValue="office_id" [(ngModel)]="patient.defaultOfficeID" >
                        </ng-select>
                    </div>
                    <div class="col-md-1">
                        <a href="javascript:void(0)" (click)="editOffice('General','Edit Facilities', false, true)" class="editlink"><img src="../../../../assets/img/pencil-png.png" height="10px"></a>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Regular Provider:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <!-- <ng-select [clearable]="true" [items]="providerModel"  bindLabel="display_txt" formControlName="regularProvider" bindValue="provider_id" [(ngModel)]="patient.referring_provider_id" (keyup.tab)="addLokupItem($event,'states')" placeholder="{{somePlaceholder}}"> -->
                        <ng-select [clearable]="true" [items]="providerModel" [addTag]="true" bindLabel="display_txt" formControlName="regularProvider" bindValue="user_id" [(ngModel)]="patient.referring_provider_id" (keyup.tab)="addLokupItem($event,'states')" ></ng-select>
                    
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-1 p-1">
                <span style="font-size: 20px !important"><b>Employment:</b></span>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input type="checkbox" formControlName="employed" [(ngModel)]="patient.employed" class="m-2" style="font-size: 16px;">
                    <label>Employed</label>
                </div>
            </div>
            <div class="col-md-2" *ngFor="let st of student">
                <div class="form-group input-group-sm" style="font-size: 14px; padding-top: 8px !important">
                    <input type="radio" formContolName="studentStatus"   name="student" [(ngModel)]="patient.studentStatus" [value]="st.value" [checked]="st.value == patient.studentStatus" [ngModelOptions]="{standalone: true}"><label>{{st.key}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Employer/School:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" [(ngModel)]="patient.employer" type="text" formControlName="school">
                 </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Occupation:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.occupation" formControlName="occupation">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Work Phone:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="workPhone" phone maxlength="14" minlength="14" [(ngModel)]="patient.workPhone" [ngClass]="{'red':addPatientFormGroup.controls.workPhone.invalid}">
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-1 p-1">
                <span style="font-size: 20px !important;"><b>Marital Status:</b></span>
            </div>
            &nbsp;&nbsp;
            <div class="col-md-1" *ngFor="let ms of marriedStatus">
                <div class="form-group input-group-sm">
                    <input type="radio" formContolName="marriageStatus" class="m-2" name="marriageStatus" [(ngModel)]="patient.marriageStatus" [value]="ms.value" [ngModelOptions]="{standalone: true}"><span style="font-size: 12px !important;">{{ms.key}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Spouse Name:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="spouseName" [(ngModel)]="patient.spouseName">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Spouse Employer:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="spouseEmployer" [(ngModel)]="patient.spouseEmployer">
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group input-group-sm">
                    <input type="checkbox" formControlName="drReferred" style="margin: 5px;"
                            [(ngModel)]="patient.doctorReferred">
                    <label style="font-weight: 20px;"><b>Doctor Referred</b></label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Referring Doctor:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="referringDoctor" bindLabel="display_txt" formControlName="referingDoctor" bindValue="user_id" [(ngModel)]="patient.referringDoctor">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Referred By:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="refferedBy"  bindLabel="value" formControlName="referedBy" bindValue="value" [(ngModel)]="patient.referedBy">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Name:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" formControlName="referedByName" [(ngModel)]="patient.referedByName">
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12 col-sm-12 p-1">
                <span style="font-size: 20px"><b>Responsible Party:</b></span>
                <label><input type="radio" name="responsibleParty" (click)="onNone()" class="m-2" [(ngModel)]="responsiblePartyNone" [checked]="responsiblePartyNone">None</label>
                <label><input type="radio" name="responsibleParty" (click)="copyData()" [(ngModel)]="responsiblePartyPatient" class="m-2" [checked]="responsiblePartyPatient">Patient</label>                
                <label><input type="radio" name="responsibleParty" (click)="onSpouse()" class="m-2" [(ngModel)]="responsiblePartySpouse" [checked]="responsiblePartySpouse">Spouse</label>
                <label><input type="radio" name="responsibleParty" (click)="onGrandParent()" class="m-2" [(ngModel)]="responsiblePartyGrandparent" [checked]="responsiblePartyGrandparent">Grandparent</label>
                <label><input type="radio" name="responsibleParty" (click)="onparent()" class="m-2" [(ngModel)]="responsiblePartyparent" [checked]="responsiblePartyparent">Parent</label>
                <label><input type="radio" name="responsibleParty" (click)="onGuest()" class="m-2" [(ngModel)]="responsiblePartyGuest" [checked]="responsiblePartyGuest">Guardian</label>
                <label><input type="radio" name="responsibleParty" (click)="onchild()" class="m-2" [(ngModel)]="responsiblePartyChild" [checked]="responsiblePartyChild">Child</label>
                <label><input type="radio" name="responsibleParty" (click)="onother()" class="m-2" [(ngModel)]="responsiblePartyother" [checked]="responsiblePartyother">Other</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Name:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.responsibleName" formControlName="responsibleName">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Birthdate:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" (ngModelChange)="patient.responsibleBirthdate = $event" [ngModel]="patient.responsibleBirthdate | date:'MM-dd-yyyy'" formControlName="responsibleBirthdate">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Address:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.responsibleAddress" formControlName="responsibleAddress">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>City:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.responsibleCity" formControlName="responsibleCity">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>State:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="stateLookup"  bindLabel="name_txt" formControlName="responsibleState" bindValue="name_txt" [(ngModel)]="patient.responsibleState" (keyup)="keyFunc($event, patient, 'responsibleState')" (keydown.tab)="saveDropdown(patient.responsibleState, stateLookup, patient, 'responsibleState')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Zip:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.responsibleZip" formControlName="responsibleZip">
                </div>
            </div>
            <div class="col-md-3">

            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Phone:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input type="text" class="form-control" formControlName="responsiblePhone" placeholder="(xxx) xxx-xxxx" [(ngModel)]="patient.responsiblePhone" phone minlength=14 maxlength=14 [ngClass]="{'red':addPatientFormGroup.controls.responsiblePhone.invalid }">                    
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12 col-sm-12 p-1">
                <span class="font-size: 20px;"><b>Emergency Contact:</b></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Name:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.emergencyContactName" formControlName="emergencyContactName">
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Relation:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <ng-select [clearable]="true" [items]="relationLookup" bindLabel="name_txt" formControlName="emergencyContactRelation" bindValue="name_txt" [(ngModel)]="patient.emergencyContactRelation" (keyup)="keyFunc($event, patient, 'emergencyContactRelation')" (keydown.tab)="saveDropdown(patient.emergencyContactRelation, relationLookup, patient, 'emergencyContactRelation')">
                    </ng-select>
                </div>
            </div>
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Home Phone:</label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.emergencyContactHomePhone" placeholder="(xxx) xxx-xxxx" phone maxlength="14" minlength="14" formControlName="emergencyContactHomePhone" [ngClass]="{'red':addPatientFormGroup.controls.emergencyContactHomePhone.invalid}">
                </div>
            </div>
            <div class="col-md-3"></div>
        </div>
        <div class="row">
            <div class="col-md-1">
                <div class="form-group input-group-sm">
                    <label>Work Phone:</label>
                </div>
            </div>
            <div class="col-md-2 col-sm-8">
                <div class="form-group input-group-sm">
                    <input class="form-control" type="text" [(ngModel)]="patient.emergencyContactWorkPhone" placeholder="(xxx) xxx-xxxx" phone maxlength="14" minlength="14" formControlName="emergencyContactWorkPhone" [ngClass]="{'red':addPatientFormGroup.controls.emergencyContactWorkPhone.invalid}">
                </div>
            </div>
        </div>
        <hr>
        <div class="form-group input-group-sm">
            <div class="col-md-9" style="text-align:center; border-bottom: solid 1px #DEDFE0;">
                <span class="font-size: 18px !important;"><h3 style="font-weight: bold;">Patient Insurance</h3></span>
            </div>
            <div class="col-md-3"></div>
        </div>
        <div>
            <div *ngFor="let item of patient.patientInsurances;let i = index">
                <div style="padding-top: 5px !important;">
                    <div class="row">
                        <div class="col-md-1">
                            <div class="form-group input-group-sm">
                                <label class="mr-2"><span style="font-weight: 16px;">Type:</span></label>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-row form-group input-group-sm">
                                <ng-select class="col-md-6" [clearable]="true" [(ngModel)]="item.insuranceType" placeholder="Select Type" [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="1">Medical</ng-option>
                                    <ng-option [value]="2">Vision</ng-option>
                                </ng-select>
                                <ng-select class="col-md-6" [clearable]="true" [(ngModel)]="item.insuranceOrder" [ngModelOptions]="{standalone: true}">
                                    <ng-option [value]="0">Primary</ng-option>
                                    <ng-option [value]="1">Secondary</ng-option>
                                    <ng-option [value]="2">Tertiary</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group input-group-sm">
                                    <label class="mr-2">Relation:</label>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group-sm">
                                <ng-select [clearable]="true" style="border-radius: 5px;"  placeholder="Select Relation" [(ngModel)]="item.insuredRelation" [ngModelOptions]="{standalone: true}">
                                        <ng-option *ngFor="let r of relationLookup" value="{{r.name_txt}}">
                                            {{r.name_txt}}
                                        </ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-1">
                            <div class="form-group input-group-sm">
                                <label class="chkbox mt-2">
                                    <input type="checkbox" [(ngModel)]="item.current_ind"
                                        [ngModelOptions]="{standalone: true}" class="m-2">
                                    Current
                                    <span class="chkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <button type="button" class="btn btn-danger" style="padding: 2px !important;" (click)="deletePatientInsurance(i)">Remove Plan</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-1">
                            <span class="font-size: 14px !important;"><b>Insurance:</b></span>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group-sm">
                                <input type="search" class="form-control" class="col-md-10" (input)="searchInsurance($event)" (click)="item.isShow=!item.isShow" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.insuranceName">&nbsp;
                                <div *ngIf="item.isShow">
                                    <ag-grid-angular [headerHeight]="35" [rowHeight]="35" style="width: 90%;" [rowStyle]="rowStyle" rowSelection="single" (rowClicked)="insuranceRowClicked($event,item)" domLayout="autoHeight" class="ag-theme-alpine dropdown" [rowData]="rowInsurance" [rowDragManaged]="true" [columnDefs]="columnInsurance">
                                    </ag-grid-angular>
                                </div>
                                <button class="button" (click)="openInsurance(item)">
                                    <img src="../../../../assets/img/search.ico" style="height:210% ;height:70%">
                                </button>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group input-group-sm input-group-sm">
                                <label>Group ID:</label>
                            </div>
                        </div>
                        <div class="col-md-2 col-sm-8">
                            <div class="form-group input-group-sm input-group-sm">
                                <input type="text" class="form-control" [(ngModel)]="item.groupID" [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group input-group-sm input-group-sm">
                                <label>Member ID:</label>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group input-group-sm input-group-sm">
                                <input type="text" class="form-control" [(ngModel)]="item.memberID" [ngModelOptions]="{standalone: true}">
                            </div>
                        </div>
                    </div>
                    <div id="content" *ngIf="item.insuredRelation!='Self' && item.insuredRelation">
                        <div class="titlebox">Insured</div>
                        <br>
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group input-group-sm input-group-sm">
                                    <label>Name:</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input type="text" class="form-control" [(ngModel)]="item.insuredName" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group input-group-sm input-group-sm">
                                    <label>Employer:</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input type="text" class="form-control" [(ngModel)]="item.insuredEmployer" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group input-group-sm input-group-sm">
                                    <label>Sex:</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group input-group-sm input-group-sm">
                                    <ng-select [clearable]="true" [(ngModel)]="item.insuredGender" [ngModelOptions]="{standalone: true}">
                                                <ng-option value="M">Male</ng-option>
                                                <ng-option value="F">Female</ng-option>
                                            </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-1">
                                <div class="form-group input-group-sm input-group-sm">
                                    <label>DOB:</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input type="text" [bsConfig]="bsConfig" bsDatepicker #dp="bsDatepicker" class="form-control" (ngModelChange)="item.insuredDOB = $event" [ngModel]="item.insuredDOB | date:'MM-dd-yyyy'" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group input-group-sm input-group-sm">
                                    <label>SSN:</label>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group input-group-sm input-group-sm">
                                    <input type="text" class="form-control" [(ngModel)]="item.insuredSSN" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding-top:10px;">
                        <div class="col-md-1">
                            <div>
                                <label class="mr-2">Insurance Card:</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <!-- (click)="getAttachment()" -->
                                <ng-select [clearable]="true" [items]="attachmentList" class="col-md-12" (change)="setAttachment($event,item)" bindLabel="description_txt" bindValue="attachment_id" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.attachment_id" > 
                                </ng-select>
                            </div>
                        </div>
                        
                        <div class="col-md-3">
                            <div>
                                <button class="btn btn-primary col-md-3" (click)="addAttachment()" style="padding: 3px">Add Card</button>
                                &nbsp;&nbsp;
                                <button class="btn btn-primary col-md-3" (click)="downloadFile(item)" style="padding: 3px">View Card</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">

                    </div>
                </div>
            </div>
            <br>
        </div>
        <div class="row pt-2">
            <div class="col-md-1">
                <label>
                    <input type="checkbox" class="m-2" formControlName="selfPay" [(ngModel)]="patient.selfPay">
                    Self Pay</label>
            </div>
            <div class="col-md-6">

            </div>
            <div class="col-md-2">
                <button type="button" [disabled]="patientId == 0" class="btn btn-primary" (click)="initialPatientInsurance()" style="padding: 3px">Add Plan</button>
            </div>
        </div>
        <div class="row">

        </div>
    </fieldset>
</form>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Pop Up</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Save</button>
    </div>
</ng-template>
