<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
    <div class="col-md-5 col-sm-4">
        <label><b>Description:</b></label>
    </div>
    <div class="col-md-6 col-sm-8">
        <input [(ngModel)]="attachmentModel[0].description_txt" type="text" class="form-control">
    </div>
    </div>
    <br>
<div class="row">
    <div class="col-md-5 col-sm-4">
        <label ><b>Attachment Type:</b></label>
        <!-- <select class="form-control" [(ngModel)]="attachmentModel[0].type_txt">
            <option *ngFor="let lookup of attachmentLookup" [value]="lookup.name_txt">{{lookup.name_txt}}</option>
        </select> -->
        </div>
    <div class="col-md-6 col-sm-8" >
        <div class="row">
            <div class="col-md-10 col-sm-10">
                <ng-select  style="width:115% !important;" [clearable]="true" addTagText="Add New" [addTag]="CreateNew" [items]="attachmentLookup"
                    (keyup)="keyFunc($event,attachmentModel[0],'type_txt')"
                    (keydown.tab)="saveDropdown(attachmentModel[0].type_txt,'Attachment Type',attachmentLookup,'attachmenttype','Patient',dbEnum.QE_Dev_Config,attachmentModel[0],'type_txt')"
                    bindLabel="name_txt" bindValue="name_txt" [(ngModel)]="attachmentModel[0].type_txt">
                </ng-select>
            </div>
            <div class="col-2">
                <a href="javascript:void(0)"
                    (click)="editLookup(attachmentLookup,attachmentLookup,dbEnum.QE_Dev_Config)"><img
                        src="../../../../assets/img/pencil-png.png" height="10px"></a>
            </div>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-md-5 col-sm-4">
        <label><b>File:</b></label>
        </div>
    <div class="col-md-6 col-sm-8">
        <!-- <input class="form-control" type="file" accept=".pdf,.txt" (change)="onFileChanged($event)" >    -->
        
        <div class="input-group">            
            <div class="custom-file">
              <input class="form-control" type="file" accept=".pdf,.txt,.jpg,.JPEG,.png,.tiff" (change)="onFileChanged($event)"   >
            </div>
          </div>
    </div>
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" [disabled]="attachmentModel[0].description_txt.length==0 && attachmentModel[0].type_txt.length==0" (click)="save()">Save</button>
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">{{closeBtnName}}</button>
</div>
