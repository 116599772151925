import { OMR_PrescriptionsModel } from './OMR_PrescriptionsModel';
import { OMR_ExamADDModel } from './OMR_ExamADDModel';
import { OMR_EXAMModel } from 'src/app/model/OMR_EXAMModel';
import { Exam_AssessmentsModel } from "./Exam_AssessmentsModel";
import { OMR_ContactOrderFormModel } from "./OMR_ContactOrderFormModel";
import { OMR_ContactRXModel } from './OMR_ContactRXModel';
import { OMR_DoctorsStatementModel } from './OMR_DoctorsStatementModel';
import { OMR_ExtraTestsModel } from './OMR_ExtraTestsModel';
import { OMR_ProgressNotesModel } from './OMR_ProgressNotesModel';
import { RXModel } from './RXModel';


export class ExamModel{
public exam_ID : string = '';
public examID : number = 0;
public exam_Type : string = '';
public template_ind : boolean = false;
public template_name_txt : string = '';
public patient_ID : string = '';
public exam_Date : Date = new Date();
public cheif_Complaint : string = '';
public office_ID : string = '';
public provider_ID : string = '';
public isArchived : boolean = false;
public rx_expiration_dt : Date = new Date();
public rx_label_ind : boolean = false;
public rx_reconciliation : boolean = false;
public rx_used_eprescribe_ind : boolean = false;
public rx_provider_id : string = '';
public radiology_provider_id : string = '';
public lab_provider_id : string = '';
public rx_printed : boolean = false;
public primary_insurance_id : string = '';
public secondary_insurance_id : string = '';
public tertiary_insurance_id : string = '';
public immunization_provider_id : string = '';
public phLocation : string = '';
public phQuality : string = '';
public phSeverity : string = '';
public phDuration : string = '';
public phTiming : string = '';
public phContext : string = '';
public phModifyingFactors : string = '';
public phSignsAndSymptoms : string = '';
public medHXandROS : Date = new Date();
public reviewedNoChanges : boolean = false;
public pptOriented : boolean = false;
public psychMood : string = '';
public other_assessments_txt : string = '';
public other_plans_txt : string = '';
public created_dt : Date = new Date();
public created_by : string = '';
public updated_dt : Date = new Date();
public updated_by : string = '';
public visit_type : string = '';
public exam_Assessments : Exam_AssessmentsModel[] = [];
public omR_ContactOrderForm : OMR_ContactOrderFormModel = new OMR_ContactOrderFormModel();
public omR_ContactRX :  OMR_ContactRXModel = new OMR_ContactRXModel();
public omR_DoctorsStatement : OMR_DoctorsStatementModel[] = [];
public omR_Exam : OMR_EXAMModel = new OMR_EXAMModel();
public omR_ExamADD : OMR_ExamADDModel = new OMR_ExamADDModel();
public omR_ExtraTests : OMR_ExtraTestsModel = new OMR_ExtraTestsModel();
public omR_Prescriptions : OMR_PrescriptionsModel = new OMR_PrescriptionsModel();
public omR_ProgressNotes : OMR_ProgressNotesModel[] = [];
//public med_RX : RXModel[] = [];
}
