import { PatientModel } from './../../../model/patientModel';
import { Omr_FeeStatementservice } from 'src/app/services/omr_FeeStatement.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OMR_FeeStatementModel } from 'src/app/model/omr_FeeStatementModel';
import { ColDef } from 'ag-grid-community';
import { CurrencyPipe ,ViewportScroller} from '@angular/common';
import { stringify } from 'querystring';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-patient-account',
  templateUrl: './patient-account.component.html',
  styleUrls: ['./patient-account.component.scss']
})
export class PatientAccountComponent implements OnInit {

  columnAccount: ColDef[] = [];
  rowAccount: OMR_FeeStatementModel[] = [];
  accountGrid: any;
  patientDetail: PatientModel = new PatientModel();
  totalCharges: number = 0;
  totalDiscounts: number = 0;
  totalTaxes: number = 0;
  totalPatientPayments: number = 0;
  totalInsurancePayments: number = 0;
  totalBalance: number = 0;
  totalInsuranceChargeback: number = 0;
  pinnedBottomRowData: any[] = [];
  constructor(private omr_FeeStatementservice: Omr_FeeStatementservice
    , private router: Router,private viewportScroller: ViewportScroller) {
    this.patientDetail = JSON.parse(localStorage.getItem('ActivePatient')!);
  }

  ngOnInit() {
    localStorage.setItem("backLocation", "");
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    this.viewportScroller.scrollToPosition([0, 0]);
    this.columnAccount = [
      {
        headerName: 'Date',
        field: 'dos_dt', sortable: true, resizable: true, rowDrag: true,
        cellRenderer: (data: any) => {

          if (data.value) {
            return moment(data.value).format('MM/DD/YYYY')
          }
          else {
            return '<b>Total</b>'
          }
        }
      },
      {
        headerName: 'Type',
        field: 'type_txt', sortable: true, filter: true, resizable: true
      },
      {
        headerName: 'Charges',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {

          var currency = new CurrencyPipe('en');
          if (data.data.Charges == 0 || data.data.Charges) {
            if(data.data.Charges >= 0){
              return currency.transform(data.data.Charges) + '';
            }
            else{
              return '(' + currency.transform(data.data.Charges * -1) + ')'
            }
          }
          else {
            var charges = 0;

            data.data.omR_FeeStatementService.forEach((omr_FeeStatementService: any) => {
              charges = charges + (omr_FeeStatementService.unit_amt * omr_FeeStatementService.unit_cnt);
            })

            data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem: any) => {
              charges = charges + (omr_FeeStatementItem.unit_amt * omr_FeeStatementItem.unit_cnt);
            });

            return currency.transform(charges) + '';
          }
        }
      },
      {
        headerName: 'Discounts',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {

          var currency = new CurrencyPipe('en');
          if (data.data.Discounts == 0 || data.data.Discounts) {
            return currency.transform(data.data.Discounts) + '';
          }
          else {
            var discount = 0;

            data.data.omR_FeeStatementService.forEach((omr_FeeStatementService: any) => {
              discount = discount + omr_FeeStatementService.discount_amt;
            })

            data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem: any) => {
              discount = discount + omr_FeeStatementItem.discount_amt;
            });

            return currency.transform(discount) + '';
          }
        }
      },
      {
        headerName: 'Taxes',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {

          var taxes = 0;
          var currency = new CurrencyPipe('en');
          if (data.data.Taxes == 0 || data.data.Taxes) {
            return currency.transform(data.data.Taxes) + '';
          }
          else{
            data.data.omR_FeeStatementService.forEach((omr_FeeStatementService: any) => {
            taxes = taxes + omr_FeeStatementService.tax_amt;
          })

          data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem: any) => {
            taxes = taxes + omr_FeeStatementItem.tax_amt;
          });

          return currency.transform(taxes) + '';
          }
        }
      },
      {
        headerName: 'Patient Payments',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {

          var patientPayment = 0;
          var currency = new CurrencyPipe('en');
          if (data.data["Patient Payments"] == 0 || data.data["Patient Payments"]) {
            return currency.transform(data.data["Patient Payments"]) + '';
          }
          else{
            data.data.omR_FeeStatementService.forEach((omr_FeeStatementService: any) => {
            patientPayment = patientPayment + omr_FeeStatementService.pat_total;
          })

          data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem: any) => {
            patientPayment = patientPayment + omr_FeeStatementItem.pat_total;
          });

          return currency.transform(patientPayment) + '';
          }

        }
      },
      {
        headerName: 'Insurance Payments',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {
          debugger;
          var insurancePayment = 0;
          var currency = new CurrencyPipe('en');
          if (data.data["Insurance Payments"] == 0 || data.data["Insurance Payments"]) {
            return currency.transform(data.data["Insurance Payments"]) + '';
          }
          else{
             data.data.omR_FeeStatementService.forEach((omr_FeeStatementService: any) => {
            insurancePayment = insurancePayment + omr_FeeStatementService.ins_total;
          })

          data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem: any) => {
            insurancePayment = insurancePayment + omr_FeeStatementItem.ins_total;
          });

          return currency.transform(insurancePayment) + '';
          }

        }
      },
      {
        headerName: 'Insurance Chargeback',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {
          debugger;
          var insuranceChargeback = 0;
          var currency = new CurrencyPipe('en');
          if (data.data["Insurance Chargeback"] == 0 || data.data["Insurance Chargeback"]) {
            return currency.transform(data.data["Insurance Chargeback"]) + '';
          }
          else{
            data.data.omR_FeeStatementService.forEach((omr_FeeStatementService:any)=>{
              if(omr_FeeStatementService.omR_FeeStatementPayment){
                omr_FeeStatementService.omR_FeeStatementPayment.forEach((ele: any) => {
                  if(ele.payment_type_txt == "Insurance Chargeback"){
                    insuranceChargeback = insuranceChargeback + Number(ele.payment_amt);
                  }
                });
              }
            })

            data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem:any)=>{
              if(omr_FeeStatementItem.omR_FeeStatementPayment){
                omr_FeeStatementItem.omR_FeeStatementPayment.forEach((ele: any) => {
                  if(ele.payment_type_txt == "Insurance Chargeback"){
                    insuranceChargeback = insuranceChargeback + Number(ele.payment_amt);
                  }
                });
              }
            });

            return currency.transform(insuranceChargeback) + '';

          }
        }
      },
      {
        headerName: 'Balance',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {

          var balance = 0;
          var currency = new CurrencyPipe('en');
          if (data.data["Balance"] == 0 || data.data["Balance"]) {
            return currency.transform(data.data["Balance"]) + '';
          }
          else{
             data.data.omR_FeeStatementService.forEach((omr_FeeStatementService: any) => {
            balance = balance + omr_FeeStatementService.fee_amt;
          })

          data.data.omR_FeeStatementItem.forEach((omr_FeeStatementItem: any) => {
            balance = balance + omr_FeeStatementItem.fee_amt;
          });

          return currency.transform(balance) + '';
          }

        }
      },
      {
        headerName: 'Has Return Items',
        field: '', sortable: true, filter: true, resizable: true,
        cellRenderer: (data: any) => {

          if(data.data.omR_FeeStatementItem)
          {
            return data.data.omR_FeeStatementItem.length > 0 ? 'Yes' : 'No';
          }
            else{
              return '';
            }
        }
      },
      {
        headerName: "Fee Statement Id",
        field: 'fee_statement_id', hide: true
      }
    ];
    this.getMiscData()
  }

  navigate(event: any) {

    localStorage.setItem("backLocation", "patient/patientAccount");
    if(event.data.type_txt == 'Exam'){
      this.router.navigate(['/patient/misc-free-statement'], {
        queryParams: {
          patientId: this.patientDetail.patientID,
          examId: event.data.exam_id,
          isExamTab: false
        }
      });
    }else {
      this.router.navigate(['/patient/misc-free-statement'], {
        queryParams: {
          patientId: this.patientDetail.patientID,
          feestatementId: event.data.fee_statement_id
        }
      })
    }

    // this.router.navigate(['/patient/misc-free-statement'], {
    //   queryParams: {
    //     fee_statement_id: event.data.fee_statement_id,
    //     isExamTab: event.data.type_txt == 'Exam' ? true : false
    //   }
    // })
    //http://localhost:4200/patient/misc-free-statement?patientId=1443&examId=cc44abed-8f30-43a9-aa87-5ffb040db2e7&isExamTab=false
    //http://localhost:4200/patient/misc-free-statement?patientId=1443
  }

  onGridReady(params: any) {
    this.accountGrid = params.api;
  }

  getMiscData() {
    this.omr_FeeStatementservice.getAll(this.patientDetail.patient_id).subscribe(res => {
      debugger;
      this.rowAccount = res;
      this.rowAccount.forEach(element => {
        element.omR_FeeStatementService.forEach(omr_FeeStatementService => {
          this.totalBalance = this.totalBalance + Number(omr_FeeStatementService.fee_amt);
          this.totalCharges = this.totalCharges + (omr_FeeStatementService.unit_amt * omr_FeeStatementService.unit_cnt);
          this.totalDiscounts = this.totalDiscounts + omr_FeeStatementService.discount_amt;
          this.totalTaxes = this.totalTaxes + omr_FeeStatementService.tax_amt;
          this.totalPatientPayments = this.totalPatientPayments + omr_FeeStatementService.pat_total;
          this.totalInsurancePayments = this.totalInsurancePayments + omr_FeeStatementService.ins_total;
          if(omr_FeeStatementService.omR_FeeStatementPayment){
            omr_FeeStatementService.omR_FeeStatementPayment.forEach(ele => {
              if(ele.payment_type_txt == "Insurance Chargeback"){
                this.totalInsuranceChargeback = this.totalInsuranceChargeback + Number(ele.payment_amt);
              }
            });
          }
        });
        element.omR_FeeStatementItem.forEach(omr_FeeStatementItem => {
          this.totalBalance = this.totalBalance + Number(omr_FeeStatementItem.fee_amt);
          this.totalCharges = this.totalCharges + (omr_FeeStatementItem.unit_amt * omr_FeeStatementItem.unit_cnt);
          this.totalDiscounts = this.totalDiscounts + omr_FeeStatementItem.discount_amt;
          this.totalTaxes = this.totalTaxes + omr_FeeStatementItem.tax_amt;
          this.totalPatientPayments = this.totalPatientPayments + omr_FeeStatementItem.pat_total;
          this.totalInsurancePayments = this.totalInsurancePayments + omr_FeeStatementItem.ins_total;
          if(omr_FeeStatementItem.omR_FeeStatementPayment){
            omr_FeeStatementItem.omR_FeeStatementPayment.forEach(ele => {
              if(ele.payment_type_txt == "Insurance Chargeback"){
                this.totalInsuranceChargeback = this.totalInsuranceChargeback + Number(ele.payment_amt);
              }
            });
          }
        });
      })
      this.pinnedBottomRowData.push({
        "Date": "", "Type": "",
        "Charges": this.totalCharges,
        "Discounts": this.totalDiscounts,
        "Taxes": this.totalTaxes,
        "Patient Payments": this.totalPatientPayments,
        "Insurance Payments": this.totalInsurancePayments,
        "Insurance Chargeback": this.totalInsuranceChargeback,
        "Balance": this.totalBalance,
        "Has Return Items":""
      })
      setTimeout(() => {
        this.accountGrid.setPinnedBottomRowData(this.pinnedBottomRowData)
      }, 2000);
    })

  }


}
