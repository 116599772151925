import { Byte } from "@angular/compiler/src/util";
import { ExamModel } from "./ExamModel";

export class OMR_EXAMModel {
    public exam_ID: string = '';
    public patient_ID: string = '';
    public examinationDate: Date = new Date();
    public age: number = 0;
    public patientHistory: string = '';
    public phLocation: string = '';
    public phQuality: string = '';
    public phSeverity: string = '';
    public phDuration: string = '';
    public phTiming: string = '';
    public phContext: string = '';
    public phModifyingFactors: string = '';
    public phSignsAndSymptoms: string = '';
    public medHXandROS: Date = new Date();
    public reviewedNoChanges: boolean = true;
    public pptOriented: boolean = true;
    public headFaceNormal: boolean = true;    
    public headFaceOther: string = '';
    public psychMood: string = '';
    public unaidedODFar: string = '';
    public unaidedODNear: string = '';
    public unaidedOSFar: string = '';
    public unaidedOSNear: string = '';
    public vaouFar: string = '';
    public vaouNear: string = '';
    public curRXDate: Date = new Date();
    public curRXODSphere: string = '';
    public curRXODToggle: string = '';
    public curRXODCylindar: string = '';
    public curRXODAxis: string = '';
    public curRXOSSphere: string = '';
    public curRXOSToggle: string = '';
    public curRXOSCylindar: string = '';
    public curRXOSAxis: string = '';
    public curRXODFar: string = '';
    public curRXOSFar: string = '';
    public curRXODNear: string = '';
    public curRXOSNear: string = '';
    public curRXAdd: string = '';
    public curRXOUFar: string = '';
    public curRXOUNear: string = '';
    public pupilsPERRLA: boolean = true;
    public pupils: string = '';
    public coverOrtho_DNN: boolean = true;
    public coverOther: string = '';
    public npcttn: boolean = true;
    public npcOther: string = '';
    public eomNormal: boolean = false;
    public eomOther: string = '';
    public fieldScreenConfrontationWNL: boolean = true;
    public fieldScreenConfrontationOther: string = '';
    public retarodSphere: string = '';
    public retarodToggle: string = '';
    public retarodCylindar: string = '';
    public retarodAxis: string = '';
    public retarosSphere: string = '';
    public retarosToggle: string = '';
    public retarosCylindar: string = '';
    public retarosAxis: string = '';
    public retarouva: string = '';
    public retarosva: string = '';
    public retarodva: string = '';
    public distSubjectODSphere: string = '';
    public distSubjectODToggle: string = '';
    public distSubjectODCylindar: string = '';
    public distSubjectODAxis: string = '';
    public distSubjectOSSphere: string = '';
    public distSubjectOSToggle: string = '';
    public distSubjectOSCylindar: string = '';
    public distSubjectOSAxis: string = '';
    public distSubjectOD20: string = '';
    public distSubjectOS20: string = '';
    public distSubjectOU20: string = '';
    public nearSubjectOD: string = '';
    public nearSubjectOS: string = '';
    public nearSubjectOD20: string = '';
    public nearSubjectOS20: string = '';
    public nearSubjectOU20: string = '';
    public amp: string = '';
    public pra: string = '';
    public nra: string = '';
    public angleEstOD: string = '';
    public angleEstOS: string = '';
    public tearsOD: boolean = true;
    public tearsOS: boolean = true;
    public lidsLashesOD: boolean = true;
    public lidsLashesOS: boolean = true;
    public corneaOD: boolean = true;
    public corneaOS: boolean = true;
    public conjOD: boolean = true;
    public conjOS: boolean = true;
    public scleraOD: boolean = true;
    public scleraOS: boolean = true;
    public antChmbOD: boolean = true;
    public antChmbOS: boolean = true;
    public lensOD: boolean = true;
    public lensOS: boolean = true;
    public irisOD: boolean = true;
    public irisOS: boolean = true;
    public externalBiomicroscopyDesc: string = '';
    public iodo: boolean = true;
    public iobio: boolean = true;
    public iO90: boolean = true;
    public iO78: boolean = true;
    public cdod: string = '';
    public cdos: string = '';
    public discMarginsOD: boolean = true;
    public discMarginsOS: boolean = true;
    public maculaOD: boolean = true;
    public maculaOS: boolean = true;
    public vesselsOD: boolean = true;
    public vesselsOS: boolean = true;
    public periphRetNEOD: boolean = true;
    public periphRetNEOS: boolean = true;
    public nflod: boolean = true;
    public nflos: boolean = true;
    public vitreousOD: boolean = true;
    public vitreousOS: boolean = true;
    public periphRetOD: boolean = true;
    public periphRetOS: boolean = true;
    public internalOphtmalmoscopy: string = '';
    public internalOphtmalmoscopyDesc: string = '';
    public tonometryOD: string = '';
    public tonometryOS: string = '';
    public tonometryTime: string = '';
    public tonometryNAT: boolean = true;
    public tonometryTAP: boolean = true;
    public tonometryWNLBydigOU: boolean = true;
    public keraoD1: string = '';
    public keraoD1Axis: string = '';
    public keraoD2: string = '';
    public keraoD2Axis: string = '';
    public keraoS1: string = '';
    public keraoS1Axis: string = '';
    public keraoS2: string = '';
    public keraoS2Axis: string = '';
    public dpaTime: string = '';
    public tropic: string = '';
    public phenyl: string = '';
    public pared: string = '';
    public cyclo: string = '';
    public fluora: boolean = false;
    public propara: boolean = false;
    public dPAOther: boolean = true;
    public assessmentSelect: string = '';
    public assessment: string = '';
    public planSelect: string = '';
    public plans: string = '';
    public odEyePict: Byte[] = [];
    public odEyePictFile: any;
    public oDEyePictString: string = '';
    // public ODEyePictString: string = '';
    // public odEyePictString: string = '';
    public osEyePict: Byte[] = [];
    public osEyePictFile: any;
    public oSEyePictString: string = '';
    // public OSEyePictString: string = '';
    // public osEyePictString: string = '';
    public odEyePict2: any;
    public osEyePict2: any;
    public odEyeDisc: string = '';
    public osEyeDisc: string = '';
    public odEyeDisc2: string = '';
    public osEyeDisc2: string = '';
    public signature: string = '';
    public signatureCB: boolean = true;
    public planNumber: string = '';
    public pictLargeExt: string = '';
    public pictLargeInt: string = '';
    public pictSmallExt: string = '';
    public pictSmallInt: string = '';
    public xamlLargeExt: string = '';
    public xamlLargeInt: string = '';
    public xamlSmallExt: string = '';
    public xamlSmallInt: string = '';
    public xamlLargeNerve: string = '';
    public procedureTypeExt: string = '';
    public interpretationExt: string = '';
    public reportExt: string = '';
    public signatureExt: string = '';
    public procedureTypeInt: string = '';
    public interpretationInt: string = '';
    public reportInt: string = '';
    public signatureInt: string = '';
    public dpaOtherCheckBox: boolean = false;
    public dpaOtherTextBox: string = '';
    public planForOrdSpecCB: boolean = true;
    public headFaceOtherCB: boolean = true;
    public soctorID: number = 0;
    public otherAssessment: string = '';
    public otherPlan: string = '';
    public gonioODTop: string = '';
    public gonioODLeft: string = '';
    public gonioODBottom: string = '';
    public gonioODRight: string = '';
    public gonioOSTop: string = '';
    public gonioOSLeft: string = '';
    public gonioOSBottom: string = '';
    public gonioOSRight: string = '';
    public gonioODText: string = '';
    public gonioOSText: string = '';
    public tonometry: string = '';
    public io: string = '';
    public status: number = 0;
    public lockedBy: string = '';
    public lockedDate: Date = new Date();
    public isArchived: boolean = true;
    public creationDate: Date = new Date();
    public createdBy: string = '';
    public lastUpdateDate: Date = new Date();
    public lastUpdatedBy: string = '';
    public TCPNotherAssessment: string = '';
    public TCPNotherPlan: string = '';
    // public exam : ExamModel = new ExamModel();
}